import { Text, TextStyle } from 'common/components/Text';
import styles from './AmountWithFiat.module.scss';
import { CurrencyEnum } from 'common/types';
import { FiatAmount } from 'modules/fiat';
import { toFixed, toLimitedPrec } from 'modules/input-amount';

type Props = {
  currency: CurrencyEnum;
  amount: string;
  fiatAmount: string;
  textStyle?: TextStyle;
};

export const AmountWithFiat: React.FC<Props> = ({
  currency,
  amount,
  fiatAmount,
  textStyle = 'xxbold1417',
}) => {
  return (
    <div className={styles.amount}>
      <Text style={textStyle} inlineStyle={{ display: 'block' }}>
        {`${toLimitedPrec(toFixed(amount))} ${currency}`}
      </Text>
      <FiatAmount amount={fiatAmount} />
    </div>
  );
};
