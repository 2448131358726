import { LoaderBox } from 'common/components/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHelpCenterSSOQuery } from './api';
import { useHelpCenterSSOContext } from './context';

const getRedirectUrl = (jwt: string, return_to: string) =>
  `https://montesauri.zendesk.com/access/jwt?jwt=${jwt}&return_to=${return_to}`;

export const HelpCenterSSO: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { returnTo, setReturnTo } = useHelpCenterSSOContext();

  const returnToParam = new URLSearchParams(location.search).get('return_to');

  const { isLoading } = useHelpCenterSSOQuery({
    onError: (err) => {
      if (err.response?.status === 401) {
        if (returnToParam) {
          setReturnTo(returnToParam);
        }
        navigate('/login', { state: { redirectTo: '/help-center-sso' } });
      }
    },
    onSuccess: (response) => {
      // clear the state
      setReturnTo(undefined);
      // redirect back to Help Center
      const { jwt } = response.data;
      window.location.href = getRedirectUrl(
        jwt,
        returnToParam || returnTo || ''
      );
    },
    retry: false,
  });

  if (isLoading) {
    return <LoaderBox />;
  }

  return null;
};
