import { ReactComponent as BtcNetworkInactiveIcon } from 'common/assets/networks/btc-network-inactive.svg';
import { ReactComponent as EthNetworkInactiveIcon } from 'common/assets/networks/eth-network-inactive.svg';
import { ReactComponent as EthNetworkIcon } from 'common/assets/networks/eth-network-24px.svg';
import { ReactComponent as BtcNetworkIcon } from 'common/assets/networks/btc-network-24px.svg';
import { ReactComponent as EthNetworkIconSmall } from 'common/assets/networks/eth-network-19px.svg';
import { ReactComponent as BtcNetworkIconSmall } from 'common/assets/networks/btc-network-19px.svg';
import { ReactComponent as PlaceholderIcon } from 'common/assets/tokens/placeholder.svg';
import { NetworkEnum } from 'common/types';
import styles from './NetworkIcon.module.scss';
import clsx from 'clsx';

type Props = {
  networkName?: NetworkEnum;
  className?: string;
  inactive?: boolean;
  smallIcon?: boolean;
};

const networkIcons = {
  BITCOIN: <BtcNetworkIcon />,
  ETHEREUM: <EthNetworkIcon />,
};

const networkInactiveIcons = {
  BITCOIN: <BtcNetworkInactiveIcon />,
  ETHEREUM: <EthNetworkInactiveIcon />,
};

const networkIconsSmall = {
  ETHEREUM: <EthNetworkIconSmall />,
  BITCOIN: <BtcNetworkIconSmall />,
};

export const NetworkIcon: React.FC<Props> = ({
  networkName,
  className,
  inactive = false,
  smallIcon = false,
}) => {
  return (
    <div className={clsx(styles.iconContainer, className)}>
      {!networkName && <PlaceholderIcon />}
      {inactive && networkName && networkInactiveIcons[networkName]}
      {!inactive && networkName && !smallIcon && networkIcons[networkName]}
      {!inactive && networkName && smallIcon && networkIconsSmall[networkName]}
    </div>
  );
};
