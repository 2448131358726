import clsx from 'clsx';
import { textStyles, textColorStyles } from 'common/components/Text';
import { Link as LibLink, LinkProps } from 'react-router-dom';

type Props = LinkProps & { dataTest?: string };

export const Link: React.FC<Props> = ({ dataTest, className, ...props }) => {
  return (
    <LibLink
      {...props}
      data-test={dataTest}
      className={clsx(
        textStyles.regular1215,
        textColorStyles.primary,
        className
      )}
    />
  );
};
