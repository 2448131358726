import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { Multiapproval } from 'modules/multiapproval/api';
import { Threshold } from 'modules/multiapproval/components/Threshold';
import { useContext } from 'react';
import { Member } from '../api';
import styles from './StepSuccess.module.scss';

type Props = {
  deletedMember: Member;
  prevMultiapproval: Multiapproval;
};

export const StepSuccess: React.FC<Props> = ({
  deletedMember,
  prevMultiapproval,
}) => {
  const formatMessage = useFormatMessage();

  const { onClose } = useContext(ModalContext);

  const { name, surname, is_signer } = deletedMember;

  const nextMultiapproval = {
    signers_count: prevMultiapproval.signers_count - 1,
    threshold: Math.min(
      prevMultiapproval.threshold,
      prevMultiapproval.signers_count - 1
    ),
  };

  return (
    <>
      <ModalHeader
        subtitle={formatMessage(
          'companyMember.removeMember.memberRemovedCanBeInvited',
          { name: `${name} ${surname}` }
        )}
      >
        {formatMessage('companyMember.removeMember.memberRemoved')}
      </ModalHeader>
      <ModalBody>
        {is_signer && (
          <>
            <Text style='xxbold1619' className={styles.textBlock}>
              {formatMessage('common.multiapprovalThreshold')}
            </Text>
            <Text style='light1425' className={styles.textBlock}>
              {formatMessage(
                'companyMember.removeMember.multiapprovalThresholdChangedAfterDeleting'
              )}
            </Text>
            <Text style='xbold1417' className={styles.textBlock}>
              {formatMessage(
                'companyMember.removeMember.newMultiapprovalThreshold'
              )}
            </Text>
            <Threshold
              current={nextMultiapproval.threshold}
              max={nextMultiapproval.signers_count}
              className={styles.nextMultiapproval}
            />
            <div>
              <Text style='light1425'>
                {formatMessage(
                  'companyMember.removeMember.prevMultiapprovalThreshold'
                )}
              </Text>
              <Text style='light1425'>
                {formatMessage('common.threshold.outOf', {
                  current: prevMultiapproval.threshold,
                  max: prevMultiapproval.signers_count,
                })}
              </Text>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('common.button.done')}
            type={'dark'}
            onClick={onClose}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
