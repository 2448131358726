import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useNavigate } from 'react-router-dom';
import { useNotificationsMenuContext } from '../context';

export type Props = { path: string };

export const DetailsButton: React.FC<Props> = ({ path }) => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();
  const { closeNotifications } = useNotificationsMenuContext();

  return (
    <Button
      type='secondarySmall'
      name={formatMessage('common.details')}
      onClick={() => {
        navigate(path);
        closeNotifications();
      }}
    />
  );
};
