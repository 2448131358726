import clsx from 'clsx';
import { Text } from 'common/components/Text';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import styles from './Button.module.scss';
import { NotificationDot } from '../NotificationDot';

export type ButtonType =
  | 'secondary'
  | 'secondarySmall'
  | 'secondaryResponsive'
  | 'dark'
  | 'darkSmall';

export type ButtonProps = {
  className?: string;
  disabled?: boolean;
  hasNotificationDot?: boolean;
  id?: string;
  isLoading?: boolean;
  name: React.ReactNode;
  type: ButtonType;
  onClick?: () => void;
};

export const Button: React.FC<ButtonProps> = ({
  className,
  disabled,
  hasNotificationDot = false,
  id,
  isLoading = false,
  name,
  type,
  onClick,
}) => {
  const buttonStyles = {
    secondary: styles.secondary,
    secondarySmall: clsx(styles.secondary, styles.small),
    secondaryResponsive: clsx(styles.secondary, styles.secondaryResponsive),
    dark: styles.dark,
    darkSmall: clsx(styles.dark, styles.small),
  };

  const loader = (
    <RotatingLoader
      size={16}
      type={type === 'dark' || type === 'darkSmall' ? 'primary' : 'secondary'}
      dataTest='btnLoader'
    />
  );

  return (
    <button
      id={id}
      className={clsx(
        styles.button,
        disabled && styles.disabled,
        buttonStyles[type],
        className
      )}
      onClick={!disabled ? onClick : () => {}}
      data-test='actionBtn'
    >
      {isLoading && loader}
      {typeof name === 'string' ? <Text style='xbold1417'>{name}</Text> : name}
      {hasNotificationDot && <NotificationDot />}
    </button>
  );
};
