import { ReactComponent as WalletIcon } from 'common/assets/icons/wallet-clear-bw.svg';
import { Text } from 'common/components/Text';
import styles from './WalletNameBox.module.scss';

type Props = {
  name: string;
  dataTest?: string;
};

export const WalletNameBox: React.FC<Props> = ({ name, dataTest }) => {
  return (
    <div className={styles.container} data-test={dataTest}>
      <WalletIcon width={14} height={14} />
      <Text style='light1215' colorStyle={'primary'} dataTest='walletName'>
        {name}
      </Text>
    </div>
  );
};
