import { LoaderBox } from 'common/components/loader';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { USER_QUERY_KEY, useUserQuery } from 'modules/user';
import styles from './CompleteKybModal.module.scss';
import { useKyxContext } from './context';
import { useQueryClient } from '@tanstack/react-query';
import { useFormatMessage } from 'modules/messages';
import { textStyles } from 'common/components/Text';

const DEFAULT_KYB_FORM_URL_TEMPLATE =
  'https://online.forms.app/skalinin/company-verification-form-kyb-testing#63be83e67749e441a2f4bf31=XXXXX';

const KYB_FORM_URL_TEMPLATE =
  process.env.REACT_APP_KYB_FORM_URL_TEMPLATE || DEFAULT_KYB_FORM_URL_TEMPLATE;

const HELP_CENTER_EMAIL = 'support@montesauri.zendesk.com';

export const CompleteKybModal: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { isCompleteKybModalVisible, hideCompleteKybModal } = useKyxContext();
  const queryClient = useQueryClient();

  const { data: userData, isLoading: isUserLoading } = useUserQuery();

  const kybFormUrl = KYB_FORM_URL_TEMPLATE.replace(
    'XXXXX',
    userData?.data.email || ''
  );

  return (
    <Modal
      isOpen={isCompleteKybModalVisible}
      onClose={() => {
        queryClient.invalidateQueries(USER_QUERY_KEY);
        hideCompleteKybModal();
      }}
      className={styles.customizedModal}
    >
      {isUserLoading && <LoaderBox />}
      {userData && (
        <>
          <ModalHeader className={styles.customizedModalHeader}>
            {''}
          </ModalHeader>
          <ModalBody>
            <iframe className={styles.iframe} src={kybFormUrl} />
          </ModalBody>
        </>
      )}
      <ModalFooter className={styles.footer}>
        <span
          className={textStyles.light1417}
          dangerouslySetInnerHTML={{
            __html: formatMessage('kyx.needSomeSupportPleaseContact', {
              email: `<span class=${textStyles.xxbold1417}>${HELP_CENTER_EMAIL}</span>`,
            }),
          }}
        />
      </ModalFooter>
    </Modal>
  );
};
