import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { Text } from 'common/components/Text';
import { CryptoToFiatAmount } from 'modules/fiat';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { useIsKyxCompleted } from 'modules/kyx';
import { Wallet, WalletBalance } from '../types';
import styles from './WalletBalanceRow.module.scss';

type Props = {
  balance: WalletBalance;
  wallet: Wallet;
  dataTest?: string;
};

export const WalletBalanceRow: React.FC<Props> = ({
  balance,
  wallet,
  dataTest,
}) => {
  const isKyxCompleted = useIsKyxCompleted();

  return (
    <div className={styles.row} data-test={dataTest}>
      <div className={styles.cellCurrency} data-test='currencyBox'>
        <CurrencyBox currency={balance.currency} network={wallet.network} />
      </div>
      <div className={styles.cellAmount} data-test='assetAmountCell'>
        <Text
          dataTest='assetAmount'
          style='bold1622'
          colorStyle={isKyxCompleted ? 'primary' : 'inactive'}
        >{`${toLimitedPrec(toFixed(balance.amount))} ${
          balance.currency
        }`}</Text>
        <CryptoToFiatAmount
          amount={balance.amount}
          currency={balance.currency}
          textColorStyle={isKyxCompleted ? 'secondary' : 'inactive'}
        />
      </div>
    </div>
  );
};
