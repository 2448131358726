import { AxiosError } from 'axios';
import { useSearchParam } from 'common/hooks';
import { prettifyError } from 'common/utils/prettify-error';
import { StepSetupFailed } from 'modules/create-company/components/StepSetupFailed';
import { StepTermsAndConditions } from 'modules/create-company/components/StepTermsAndConditions';
import { useFormatMessage } from 'modules/messages';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ERR_USER_ALREADY_EXISTS,
  SignupFromInvitationCodeError,
  useGetInviteeDataQuery,
  useSignupFromInvitationCodeMutation,
} from './api';
import { StepUserData, StepPassword, StepSuccess } from './components';

export type Steps =
  | 'userData'
  | 'password'
  | 'success'
  | 'termsAndConditions'
  | 'setupFailed';

export const SignupInvitation = () => {
  const formatMessage = useFormatMessage();

  const navigate = useNavigate();
  const [invitation_code] = useSearchParam('code');

  const signupMutation = useSignupFromInvitationCodeMutation();

  const {
    data: inviteeDataResponse,
    isLoading: isInviteeDataLoading,
    error: inviteeDataError,
  } = useGetInviteeDataQuery(invitation_code, {
    onSuccess: (response) => {
      setName(response.data.name);
      setSurname(response.data.surname);
    },
    retry: false,
  });

  const [step, setStep] = useState<Steps>('userData');

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const [nameError, setNameError] = useState('');
  const [surnameError, setSurnameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatedPasswordError, setRepeatedPasswordError] = useState('');

  const onSubmitStepUserData = () => {
    setStep('password');
  };

  const getErrorText = (error: AxiosError<SignupFromInvitationCodeError>) => {
    if (
      error.response?.data.non_field_errors?.includes(ERR_USER_ALREADY_EXISTS)
    ) {
      return formatMessage('signup.userAlreadyExists');
    }
    return prettifyError(error);
  };

  const onAccept = useCallback(async () => {
    if (signupMutation.isLoading) {
      return;
    }
    const payload = {
      name: name.trim(),
      surname: surname.trim(),
      password,
      invitation_code,
    };
    signupMutation.mutate(payload, {
      onSuccess: () => {
        setStep('success');
      },
    });
  }, [name, surname, password, invitation_code]);

  const onSubmitStepSuccess = () => {
    navigate('/login', { replace: true });
  };

  switch (step) {
    case 'userData':
      return (
        <StepUserData
          email={inviteeDataResponse?.data.email || ''}
          name={name}
          surname={surname}
          company_name={inviteeDataResponse?.data.company_name || ''}
          nameError={nameError}
          surnameError={surnameError}
          apiError={inviteeDataError}
          onSubmit={onSubmitStepUserData}
          onNameChange={(value) => setName(value)}
          onSurnameChange={(value) => setSurname(value)}
          onNameError={(error) => setNameError(error)}
          onSurnameError={(error) => setSurnameError(error)}
          isLoading={isInviteeDataLoading}
        />
      );
    case 'password':
      return (
        <StepPassword
          password={password}
          repeatedPassword={repeatedPassword}
          passwordError={passwordError}
          repeatedPasswordError={repeatedPasswordError}
          onSubmit={() => setStep('termsAndConditions')}
          onPasswordChange={(value) => setPassword(value)}
          onRepeatedPasswordChange={(value) => setRepeatedPassword(value)}
          onPasswordError={(error) => setPasswordError(error)}
          onRepeatedPasswordError={(error) => setRepeatedPasswordError(error)}
          onGoBack={() => {
            setStep('userData');
          }}
        />
      );
    case 'termsAndConditions':
      return (
        <StepTermsAndConditions
          error={
            signupMutation.error
              ? getErrorText(signupMutation.error)
              : undefined
          }
          isLoading={signupMutation.isLoading}
          onAccept={onAccept}
          onDecline={() => {
            setStep('setupFailed');
          }}
        />
      );
    case 'success':
      return <StepSuccess onSubmit={onSubmitStepSuccess} />;
    case 'setupFailed':
      return <StepSetupFailed />;
  }
};
