import { ReactComponent as InternalWalletIcon } from 'common/assets/grey-icons/wallet-arrow-down.svg';
import { Text } from 'common/components/Text';

type InternalProps = { name: string };

export const InternalWallet: React.FC<InternalProps> = ({ name }) => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', gap: '5px' }}>
      <InternalWalletIcon style={{ width: '15px' }} />
      <Text
        inlineStyle={{ overflowWrap: 'break-word', width: 'calc(100% - 20px)' }}
        style='light1215'
        colorStyle='secondary'
      >
        {name}
      </Text>
    </div>
  );
};
