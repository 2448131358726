import { useEffect, useState } from 'react';
import styles from './TransactionFiltersPopupMenu.module.scss';
import { ReactComponent as FilterIcon } from 'common/assets/black-icons/filter.svg';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { TransactionTypeFilter } from './components/TransactionTypeFilter';
import { ReactComponent as CloseIcon } from 'common/assets/black-icons/close.svg';
import { WalletFilter } from './components/WalletFilter';
import { CurrencyFilter } from './components/CurrencyFilter';
import { NetworkFilter } from './components/NetworkFilter';
import { Button } from 'common/components/button';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters/context';
import { DateFilter } from './DateFilter';
import { CategoryFilter } from './CategoryFilter';
import { StatusFilter } from './StatusFilter';

type Props = {};

export const TransactionFiltersPopupMenu: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();
  const [open, setOpen] = useState(false);

  const { filters, setFilters, resetFilters, setRefetchTransactionsFlag } =
    useInfiniteTransactionsContext();

  const [tmpTypes, setTmpTypes] = useState<string[]>();
  const [tmpWallets, setTmpWallets] = useState<number[]>();
  const [tmpCurrencies, setTmpCurrencies] = useState<string[]>();
  const [tmpNetworks, setTmpNetworks] = useState<string[]>();

  useEffect(() => {
    if (open) {
      setTmpTypes(filters.types);
      setTmpWallets(filters.wallets);
      setTmpCurrencies(filters.currencies);
      setTmpNetworks(filters.networks);
      document.body.classList.add(styles.bodyScrollLock);
    } else {
      document.body.classList.remove(styles.bodyScrollLock);
    }
  }, [open]);

  return (
    <div>
      <div
        className={styles.container}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Text style='xxbold1417'>{formatMessage('common.filter')}</Text>
        <FilterIcon />
      </div>
      <div>
        {open && (
          <>
            <div
              className={styles.catchClickContainer}
              onClick={() => setOpen(false)}
            />
            <div className={styles.menuContainer}>
              <div>
                <div className={styles.header}>
                  <Text style='xxbold3048'>
                    {formatMessage('transactions.filters')}
                  </Text>
                  <CloseIcon
                    onClick={() => setOpen(false)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div className={styles.mainButtons}>
                  <DateFilter /> <CategoryFilter /> <StatusFilter />
                </div>
                <TransactionTypeFilter
                  types={tmpTypes}
                  onTypesChange={(value) => setTmpTypes(value)}
                />
                <WalletFilter
                  wallets={tmpWallets}
                  onWalletsChange={(value) => setTmpWallets(value)}
                />
                <CurrencyFilter
                  currencies={tmpCurrencies}
                  onCurrenciesChange={(value) => setTmpCurrencies(value)}
                />
                <NetworkFilter
                  networks={tmpNetworks}
                  onNetworksChange={(value) => setTmpNetworks(value)}
                />
              </div>
              <div className={styles.buttons}>
                <Button
                  type='secondary'
                  name={formatMessage('transactions.resetFilters')}
                  onClick={() => {
                    resetFilters();
                    setRefetchTransactionsFlag(true);
                    setTmpTypes(undefined);
                    setTmpWallets(undefined);
                    setTmpCurrencies(undefined);
                    setTmpNetworks(undefined);
                  }}
                  className={styles.button}
                />
                <Button
                  type='dark'
                  name={formatMessage('transactions.showResults')}
                  onClick={() => {
                    setFilters({
                      ...filters,
                      currencies: tmpCurrencies,
                      networks: tmpNetworks,
                      types: tmpTypes,
                      wallets: tmpWallets,
                    });
                    setRefetchTransactionsFlag(true);
                    setTmpTypes(undefined);
                    setTmpWallets(undefined);
                    setTmpCurrencies(undefined);
                    setTmpNetworks(undefined);
                    setOpen(false);
                  }}
                  className={styles.button}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
