import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './BorrowsHeader.module.scss';

export const BorrowsHeader = () => {
  const formatMessage = useFormatMessage();
  return (
    <Text style='xxbold3048' className={styles.header}>
      {formatMessage('borrow.borrows')}
    </Text>
  );
};
