import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useFormatMessage } from 'modules/messages';
import { AddressWhitelistTable } from './AddressWhitelistTable';
import { CreateWhitelistedAddress } from './create';

export const AddressWhitelist: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  return (
    <Section>
      <SectionHeader
        header={formatMessage('addressWhitelist.title')}
        actions={<CreateWhitelistedAddress />}
      />
      <AddressWhitelistTable />
    </Section>
  );
};
