import { Text } from 'common/components/Text';
import { ReactComponent as EmptyDotIcon } from 'common/assets/black-icons/empty-dot-8px.svg';
import { ReactComponent as FalseIcon } from 'common/assets/black-icons/false-5px.svg';
import {
  TransactionState,
  TransactionSubstate,
} from 'modules/transactions/types';
import { MessageKey, useFormatMessage } from 'modules/messages';

type Props = { substate: TransactionSubstate; state: TransactionState };

type AvailableSubstate =
  | 'PENDING_ADMIN_APPROVAL'
  | 'PENDING_FIREBLOCKS'
  | 'PENDING_ACTIVATION'
  | 'PENDING_EXECUTION'
  | 'UNKNOWN_ERROR'
  | 'REJECTED_BY_ADMIN'
  | 'LOAN_BORROW_STATE_CHANGED'
  | 'LOAN_LEND_STATE_CHANGED'
  | 'LIMIT_PRICE_NOT_MET';

export const substateToTextKey: { [key in AvailableSubstate]: MessageKey } = {
  PENDING_FIREBLOCKS: 'transactions.waitingForExecution',
  PENDING_ACTIVATION: 'transactions.waitingForExecution',
  PENDING_EXECUTION: 'transactions.waitingForExecution',
  PENDING_ADMIN_APPROVAL: 'transactions.waitingForExecution',
  UNKNOWN_ERROR: 'transactions.unknown',
  REJECTED_BY_ADMIN: 'transactions.rejectedByAdmin',
  LOAN_BORROW_STATE_CHANGED: 'transactions.borrowStateChanged',
  LOAN_LEND_STATE_CHANGED: 'transactions.lendStateChanged',
  LIMIT_PRICE_NOT_MET: 'common.limitPriceNotMet',
};

export const StatusReason: React.FC<Props> = ({ substate, state }) => {
  const formatMessage = useFormatMessage();

  const availableSubstate = substate !== 'PENDING_SIGNATURE';

  if (!availableSubstate) {
    return null;
  }

  return (
    <div>
      <Text style='light1215' inlineStyle={{ display: 'block' }}>
        {formatMessage('transactions.status') + ':'}
      </Text>
      <div>
        {state === 'PENDING' ? (
          <>
            <EmptyDotIcon />
            &nbsp;
            <Text style='light1215'>
              {formatMessage('transactions.pending') + ':'}
            </Text>
          </>
        ) : (
          <>
            <FalseIcon />
            &nbsp;
            <Text style='light1215'>
              {formatMessage('common.failed') + ':'}
            </Text>
          </>
        )}
        &nbsp;
        <Text style='light1215'>
          {formatMessage(substateToTextKey[substate])}
        </Text>
      </div>
    </div>
  );
};
