import { ModalLike } from 'common/components/modal';
import { CreateCompany as CreateCompanyFeature } from 'modules/create-company';
import { UnauthLayout } from 'modules/layout';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const CreateCompany: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.createCompany')}</title>
      </Helmet>
      <UnauthLayout>
        <ModalLike>
          <CreateCompanyFeature />
        </ModalLike>
      </UnauthLayout>
    </>
  );
};
