import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'common/components/modal';
import { openSnackbar } from 'common/components/snackbar';
import { prettifyError } from 'common/utils/prettify-error';
import { useFormatMessage } from 'modules/messages';
import { useMultiapprovalQuery } from 'modules/multiapproval/api';
import {
  TransactionActionPayload,
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  useTransactionActionMutation,
} from 'modules/transactions/api';
import { useEffect, useState } from 'react';
import {
  borrowCannotBeRepaidAtThisTime,
  BORROWS_QUERY_KEY,
  BORROW_QUERY_KEY,
  principalInsufficientFundsError,
  transactionAlreadyExists,
  useBorrowRepayMutation,
} from '../api';
import { BorrowDetails } from '../types';
import { StepSuccess } from './components/StepSuccess';
import { StepSummary } from './components/StepSummary';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Steps = 'summary' | 'success' | '2FA' | '2FAcancel';

type Props = {
  open: boolean;
  onClose: () => void;
  id: number;
  borrow?: BorrowDetails;
};

export const BorrowRepay: React.FC<Props> = ({ open, onClose, id, borrow }) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();
  const [error2FA, setError2FA] = useState<string>();
  const [error, setError] = useState<string>();
  const [transactionId, setTransactionId] = useState<number>();
  const [step, setStep] = useState<Steps>('summary');
  const [threshold, setThreshold] = useState(1);

  const { mutate: transactionActionMutation } = useTransactionActionMutation();
  const { mutate: repayMutation, isLoading: isRepayLoading } =
    useBorrowRepayMutation();
  const { data: multiApproval } = useMultiapprovalQuery();

  useEffect(() => {
    setError2FA(undefined);
    setError(undefined);
    setTransactionId(undefined);
    setStep('summary');
    setThreshold(1);
  }, [open]);

  useEffect(() => {
    if (multiApproval?.data.threshold) {
      setThreshold(multiApproval.data.threshold);
    }
  }, [multiApproval?.data.threshold]);

  const invalidateQueries = (borrowId: number) => {
    queryClient.invalidateQueries(BORROW_QUERY_KEY(borrowId));
    queryClient.invalidateQueries(BORROWS_QUERY_KEY);
    queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
    queryClient.invalidateQueries(TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY);
  };

  const repay = () => {
    repayMutation(
      { payload: { id } },
      {
        onSuccess: (res) => {
          setTransactionId(res.data.transaction_id);
          invalidateQueries(id);
          setStep('success');
        },
        onError: (error) => {
          let match = principalInsufficientFundsError(error.response?.data);
          if (match) {
            setError(formatMessage('borrow.notEnoughFundsInPrincipalWallet'));
            return;
          }
          match = borrowCannotBeRepaidAtThisTime(error.response?.data);
          if (match) {
            setError(formatMessage('borrow.borrowCannotBeRepaidAtThisTime'));
            invalidateQueries(id);
            return;
          }
          match = transactionAlreadyExists(error.response?.data);
          if (match) {
            setError(formatMessage('borrow.transactionRepayAlreadyExists'));
            invalidateQueries(id);
            return;
          }
          setError(prettifyError(error));
        },
      }
    );
  };

  const onVerify = (keys: string) => {
    if (transactionId) {
      const payload: TransactionActionPayload = {
        transaction_id: transactionId,
        action: 'APPROVE',
      };

      transactionActionMutation(
        { payload, headers: generateHeader2FA(keys) },
        {
          onSuccess: () => {
            openSnackbar(
              formatMessage('transactions.approved.message'),
              'success'
            );
            queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
            queryClient.invalidateQueries(TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY);
            onClose();
          },
          onError: (err) => {
            setErrorOTP(err, setError2FA, formatMessage);
          },
        }
      );
    }
  };

  return (
    <>
      <Modal isOpen={open} onClose={onClose}>
        {step === 'summary' && (
          <StepSummary
            onClick={repay}
            borrow={borrow}
            error={error}
            isLoadingBtn={isRepayLoading}
            onClose={onClose}
            id={id}
          />
        )}
        {step === 'success' && (
          <StepSuccess
            onClose={onClose}
            onApprove={() => setStep('2FA')}
            threshold={threshold}
            usersAmount={multiApproval?.data.signers_count!}
          />
        )}
        {step === '2FA' && (
          <Module2FA
            onVerify={onVerify}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={isRepayLoading}
            onCancel={() => setStep('2FAcancel')}
            approvalOfTransaction={true}
            title={formatMessage('borrow.approveBorrowRepayment')}
          />
        )}
        {step === '2FAcancel' && (
          <Cancel2FA onClose={onClose} approvalOfTransaction={true} />
        )}
      </Modal>
    </>
  );
};
