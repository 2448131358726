import { Text } from 'common/components/Text';
import styles from './DonationsTable.module.scss';
import { useFormatMessage } from 'modules/messages';
import { useCharityFormDonations } from 'modules/charity-forms/api';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { FullHistoryButton } from './FullHistoryButton';
import { AmountWithFiat } from './AmountWithFiat';
import { LoaderBox } from 'common/components/loader';
import { formatDatetimeWithoutSec } from 'common/utils/utils';
import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { NetworkEnum } from 'common/types';

type SelectedNetworkList = 'ALL' | 'ETHEREUM' | 'BITCOIN';

type Props = { id: number; networks: NetworkEnum[] };

export const DonationsTable: React.FC<Props> = ({ id, networks }) => {
  const formatMessage = useFormatMessage();
  const { data, isLoading } = useCharityFormDonations(id);

  //size of the table depending on the selected networks
  const numberOfDisplayedDonations: {
    readonly [key in SelectedNetworkList]: number;
  } = {
    ALL: 5,
    ETHEREUM: 4,
    BITCOIN: 3,
  };

  const numberOfDonations =
    networks.includes('BITCOIN') && networks.includes('ETHEREUM')
      ? numberOfDisplayedDonations['ALL']
      : networks.includes('ETHEREUM')
      ? numberOfDisplayedDonations['ETHEREUM']
      : networks.includes('BITCOIN')
      ? numberOfDisplayedDonations['BITCOIN']
      : numberOfDisplayedDonations['BITCOIN'];

  const donations = data?.data.slice(0, numberOfDonations);

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          <Text style='xxbold1619' inlineStyle={{ display: 'block' }}>
            {formatMessage('charityForms.donations')}
          </Text>
        </div>
        {!donations?.length && !isLoading && <NothingToDisplay size={200} />}
        {isLoading && <LoaderBox />}
        <div>
          {donations?.map((donation) => (
            <div className={styles.row}>
              <div className={styles.donation}>
                <CurrencyIcon currencyName={donation.currency} />
                <div className={styles.nameBox}>
                  <Text style='light1622' inlineStyle={{ display: 'block' }}>
                    {!donation.name || !donation.surname
                      ? formatMessage('charityForms.anonymous')
                      : `${donation.name} ${donation.surname}.`}
                  </Text>
                  <Text
                    style='light1215'
                    colorStyle='secondary'
                    inlineStyle={{ display: 'block' }}
                  >
                    {formatDatetimeWithoutSec(donation.created_at)}
                  </Text>
                </div>
              </div>
              <AmountWithFiat
                currency={donation.currency}
                amount={donation.amount}
                fiatAmount={donation.amount_fiat}
              />
            </div>
          ))}
        </div>
      </div>
      <FullHistoryButton
        filters={{
          charityForms: [id],
          transactionKinds: ['CHARITY_DONATION'],
        }}
        className={styles.btn}
        disabled={!donations?.length}
      />
    </div>
  );
};
