import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { QRCodeCanvas } from 'third-party/qrcode.react';
import { Text } from 'common/components/Text';
import { Copy } from 'common/components/Copy';
import { Button, BackButton } from 'common/components/button';
import { CODE_2FA_LENGTH, Inputs2FA } from './Inputs2FA';
import styles from './Setup2FA.module.scss';
import { useState } from 'react';

type Props = {
  onVerify: (code: string) => void;
  secretKey: string;
  uri: string;
  error?: string;
  onError: (err: string | undefined) => void;
  isLoading: boolean;
  onGoBack: () => void;
};

export const Setup2FA: React.FC<Props> = ({
  onVerify,
  secretKey,
  uri,
  error,
  onError,
  isLoading,
  onGoBack,
}) => {
  const formatMessage = useFormatMessage();
  const [code, setCode] = useState('0');

  return (
    <>
      <ModalHeader
        subtitle={formatMessage('2FA.typeYourConfigurationKey')}
        showClose={false}
      >
        {formatMessage('2FA.secureYourAccount')}
      </ModalHeader>
      <ModalBody>
        <Copy
          dataTest='configKey'
          data={secretKey}
          snackbarText={formatMessage('2FA.configurationKeyCopied')}
        />
        <div className={styles.margin}>
          <Text style='light1425'>
            {formatMessage('2FA.alternativelyYouCanScanQR')}
          </Text>
        </div>
        <div className={styles.qrCode}>
          <QRCodeCanvas value={uri} size={150} data-test='QRcode' />
        </div>
        <Inputs2FA
          onCodeChange={(newCode: string) => {
            setCode(newCode);
            if (newCode.length === CODE_2FA_LENGTH) {
              onVerify(newCode);
            }
          }}
          error={error}
          onError={(err) => onError(err)}
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} disabled={isLoading} />
          <Button
            name={formatMessage('common.button.verify')}
            type='dark'
            onClick={() => onVerify(code)}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
