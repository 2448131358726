const base64ToBytes = (base64: string) => {
  const binString = window.atob(base64);
  return Uint8Array.from([...binString].map((b) => b.codePointAt(0)!));
};

// decode JS string (UTF-16) from base64
export const base64ToUtf16 = (base64: string) => {
  return new TextDecoder().decode(base64ToBytes(base64));
};

const bytesToBase64 = (bytes: Uint8Array) => {
  const binString = String.fromCodePoint(...bytes);
  return window.btoa(binString);
};

// encode JS string (UTF-16) as base64
export const utf16ToBase64 = (utf16: string) => {
  return bytesToBase64(new TextEncoder().encode(utf16));
};
