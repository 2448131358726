import clsx from 'clsx';
import styles from './FilterText.module.scss';
import { Text } from 'common/components/Text';

type Props = {
  children: React.ReactNode;
  filter?: boolean;
  onFilter?: (value: boolean | undefined) => void;
  className?: string;
};

export const FilterText: React.FC<Props> = ({
  children,
  filter,
  onFilter,
  className,
}) => {
  return (
    <div
      className={clsx(styles.container, className)}
      onClick={() => {
        const next = filter ? undefined : true;
        if (onFilter) onFilter(next);
      }}
    >
      <Text style={filter ? 'xbold1215' : 'light1215'} className={styles.text}>
        {children}
      </Text>
    </div>
  );
};
