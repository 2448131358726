import { Button } from 'common/components/button';
import { Text } from 'common/components/Text';
import { useKyxContext, useCompanyKyxState } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { ZENDESK_LINK } from '../HelpCenterButton';
import { SettingsSection } from '../SettingsSection';
import styles from './KyxSection.module.scss';
import { StateTile } from './components/StateTile';
import { useFeatureFlag } from 'modules/feature-flags';
import { LoaderBox } from 'common/components/loader';

export const KyxSection: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const kybFeatureFlag = useFeatureFlag('KYB');

  const { data, isLoading } = useCompanyKyxState();

  const { showCompleteKybModal, showCompleteKycModal } = useKyxContext();

  if (!data) {
    return null;
  }

  const { kyb, kyc } = data;
  const kybEnabled = kybFeatureFlag.data?.enabled;

  return (
    <SettingsSection
      header={formatMessage('kyx.accountVerification')}
      actions={
        kyc === 'INITIAL' ? (
          <Button
            type='secondarySmall'
            name={formatMessage('kyx.completeKyc')}
            onClick={showCompleteKycModal}
          />
        ) : kyb === 'INITIAL' && kybEnabled ? (
          <Button
            type='secondarySmall'
            name={formatMessage('kyx.completeKyb')}
            onClick={showCompleteKybModal}
          />
        ) : kyb === 'FAILED' && kybEnabled ? (
          <Button
            type='secondarySmall'
            name={formatMessage('zendesk.helpCenter')}
            onClick={() => {
              window.open(ZENDESK_LINK, '_blank');
            }}
          />
        ) : undefined
      }
      content={
        isLoading || kybFeatureFlag.isLoading ? (
          <LoaderBox />
        ) : (
          <>
            <div className={styles.tiles}>
              <StateTile
                arrowhead={true}
                badge={'tick'}
                state={'prev'}
                title={formatMessage('kyx.accountSetup')}
              />
              <StateTile
                arrowhead={true}
                badge={2}
                state={kyc === 'INITIAL' ? 'current' : 'prev'}
                title={formatMessage('kyx.kyc')}
              />
              {kybEnabled && (
                <StateTile
                  arrowhead={true}
                  badge={3}
                  state={
                    kyc === 'INITIAL'
                      ? 'next'
                      : kyb === 'INITIAL' || kyb === 'PENDING'
                      ? 'current'
                      : kyb === 'FAILED'
                      ? 'error'
                      : 'prev'
                  }
                  title={formatMessage('kyx.kyb')}
                />
              )}
              <StateTile
                arrowhead={false}
                badge={'tick'}
                state={
                  kyc === 'COMPLETED' &&
                  (kybEnabled ? kyb === 'COMPLETED' : true)
                    ? 'current'
                    : 'next'
                }
                title={formatMessage('kyx.done')}
              />
            </div>
            {kyc === 'INITIAL' ? (
              <Text style='light1417'>
                {formatMessage(
                  'kyx.dueToSecurityFinishKYCItWillAllowUnlockAllFeatures'
                )}
              </Text>
            ) : kyb === 'INITIAL' && kybEnabled ? (
              <>
                <Text style='light1417' inlineStyle={{ display: 'block' }}>
                  {formatMessage(
                    'kyx.pleaseFinishKybKybIsRequiredToDepositFunds'
                  )}
                </Text>
                <Text style='light1417' inlineStyle={{ display: 'block' }}>
                  {formatMessage('kyx.ifKybSubmittedWaitForStatusChange')}
                </Text>
              </>
            ) : kyb === 'PENDING' && kybEnabled ? (
              <Text style='light1417'>
                {formatMessage('kyx.kybIsPendingWaitForResults')}
              </Text>
            ) : kyb === 'FAILED' && kybEnabled ? (
              <Text colorStyle='error' style='light1417'>
                {formatMessage(
                  'kyx.problemWithKybContactHelpCenterOrWaitForContact'
                )}
              </Text>
            ) : (
              <Text style='light1417'>
                {formatMessage('kyx.yourAccountIsNowFullyVerified')}
              </Text>
            )}
          </>
        )
      }
    />
  );
};
