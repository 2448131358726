import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { Text } from 'common/components/Text';
import { Button } from 'common/components/button';
import { InputPassword } from 'common/components/input/InputPassword';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { openSnackbar } from 'common/components/snackbar';
import { prettifyError } from 'common/utils/prettify-error';
import { generateLimitSendingEmailError } from 'common/utils/utils';
import {
  ERR_EMAIL_INVALID,
  ERR_EMAIL_NOT_VERIFIED,
  ERR_EMAIL_OR_PASSWORD_INCORRECT,
} from 'modules/api';
import { useRegisterEmailRequestMutation } from 'modules/confirm-email';
import { useFormatMessage } from 'modules/messages';
import { RecaptchaInfotext, isRecaptchaError } from 'modules/recaptcha';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ERR_ACCESS_DENIED,
  ERR_LIMIT_SENDING_EMAIL,
  LoginResponseError,
  isCompanySuspendedError,
} from '../api';
import { useLoginLocationState } from '../hooks';
import { ForgotPasswordLink } from './ForgotPasswordLink';
import { NotAUserLink } from './NotAUserLink';
import styles from './StepLogin.module.scss';

type Props = {
  onLogin: (email: string, password: string) => void;
  error: AxiosError<LoginResponseError> | null;
  isLoading: boolean;
};

export const StepLogin: React.FC<Props> = ({ onLogin, isLoading, error }) => {
  const formatMessage = useFormatMessage();

  const navigate = useNavigate();

  const locationState = useLoginLocationState();
  const { changedPassword, emailAlreadyConfirmed, emailConfirmed } =
    locationState;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errorPassword, setErrorPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');

  const login = () => {
    const emailTrimmed = email.trim();

    if (isLoading) {
      return;
    }

    if (emailTrimmed === '') {
      setErrorEmail(formatMessage('common.fieldRequired'));
      return;
    } else {
      setErrorEmail('');
    }

    if (password === '') {
      setErrorPassword(formatMessage('common.fieldRequired'));
      return;
    } else {
      setErrorPassword('');
    }

    // reset state of location to hide info popups, but keep the redirectTo state
    navigate('/login', {
      replace: true,
      state: locationState.redirectTo
        ? { redirectTo: locationState.redirectTo }
        : undefined,
    });

    onLogin(emailTrimmed, password);
  };

  const getErrorText = (error: AxiosError<LoginResponseError>) => {
    if (typeof error.response?.data === 'object') {
      if (
        error.response.data.non_field_errors?.includes(
          ERR_EMAIL_OR_PASSWORD_INCORRECT
        )
      ) {
        return formatMessage('login.error.incorrectEmailOrPassword');
      }
      if (error.response.data.email?.includes(ERR_EMAIL_INVALID)) {
        return formatMessage('login.error.invalidEmail');
      }
      if (error.response.data.non_field_errors?.includes(ERR_ACCESS_DENIED)) {
        return formatMessage('login.error.accessDenied');
      }
    }
    if (isRecaptchaError(error)) {
      return ''; // dont show recaptcha error on Alert, because the snackbar will appear instead
    }
    if (isCompanySuspendedError(error)) {
      return ''; // avoid rapid Alert flesh before redirect to company-suspended page
    }
    return prettifyError(error);
  };

  const isEmailNotVerifiedError = (error: AxiosError<LoginResponseError>) => {
    if (
      typeof error.response?.data === 'object' &&
      error.response.data.non_field_errors?.includes(ERR_EMAIL_NOT_VERIFIED)
    ) {
      return true;
    }
    return false;
  };

  const resendMutation = useRegisterEmailRequestMutation();

  return (
    <>
      <ModalHeader showClose={false}>{formatMessage('login')}</ModalHeader>
      <ModalBody>
        <>
          {error &&
            !error?.response?.data?.status &&
            (isEmailNotVerifiedError(error) ? (
              <Alert
                text={
                  <>
                    <Text style='light1425'>
                      {formatMessage('login.error.emailNotVerified.part1')}
                    </Text>
                    <span
                      className={styles.resendLink}
                      data-test='resendLink'
                      onClick={() => {
                        if (resendMutation.isLoading) {
                          return;
                        }
                        resendMutation.mutate(
                          { email: email.trim() },
                          {
                            onSuccess: () => {
                              openSnackbar(
                                formatMessage(
                                  'confirmEmail.resendLink.success'
                                ),
                                'success'
                              );
                            },
                            onError: (err) => {
                              if (
                                err.response?.data.detail?.startsWith(
                                  ERR_LIMIT_SENDING_EMAIL
                                )
                              ) {
                                openSnackbar(
                                  generateLimitSendingEmailError(
                                    err.response?.data.detail,
                                    formatMessage(
                                      'login.errorLimitSendingEmails'
                                    ),
                                    formatMessage
                                  ),
                                  'error'
                                );
                              }
                            },
                          }
                        );
                      }}
                    >
                      <Text style='light1425'>
                        {formatMessage('login.error.emailNotVerified.part2')}
                      </Text>
                    </span>
                    {resendMutation.isLoading && (
                      <div className={styles.resendLinkLoader}>
                        <RotatingLoader size={12} type='secondary' />
                      </div>
                    )}
                  </>
                }
                severity='warning'
                className={styles.alert}
              />
            ) : (
              <Alert
                text={getErrorText(error)}
                severity={'error'}
                className={styles.alert}
              />
            ))}
          {changedPassword && (
            <Alert
              text={formatMessage('login.success.changedPassword')}
              severity={'success'}
              className={styles.alert}
            />
          )}
          {emailAlreadyConfirmed && (
            <Alert
              text={formatMessage('login.success.emailAlreadyConfirmed')}
              severity={'success'}
              className={styles.alert}
            />
          )}
          {emailConfirmed && (
            <Alert
              text={formatMessage('login.success.emailConfirmed')}
              severity={'success'}
              className={styles.alert}
            />
          )}
          <InputWithHeader
            header={formatMessage('common.email')}
            value={email}
            onChange={(e) => {
              const data = e.target.value;
              setEmail(data);
            }}
            errorText={errorEmail}
            autofocus={true}
            onEnter={() => {
              document.getElementById('Login-input-password')?.focus();
            }}
            dataTest='emailInput'
          />
          <InputPassword
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            header={formatMessage('common.password')}
            errorText={errorPassword}
            id={'Login-input-password'}
            onEnter={login}
            dataTest='passwordInput'
          />
        </>
        <ForgotPasswordLink />
        <RecaptchaInfotext />
      </ModalBody>
      <ModalFooter>
        <ModalActions extraLink={<NotAUserLink />}>
          <Button
            name={formatMessage('login')}
            type='dark'
            onClick={login}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
