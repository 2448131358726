import { PriceChangeTolerance } from 'modules/common-data-fields';
import { FinalPriceChange } from './FinalPriceChange';

export const Tolerance: React.FC<{
  price_tolerance_pct: string;
  final_price_change_pct?: string;
}> = ({ price_tolerance_pct, final_price_change_pct }) => {
  return (
    <>
      {final_price_change_pct ? (
        <FinalPriceChange tolerance={price_tolerance_pct} />
      ) : (
        <PriceChangeTolerance tolerance={price_tolerance_pct} />
      )}
    </>
  );
};
