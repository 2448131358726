import { Alert } from 'common/components/Alert';
import { Button, CancelButton } from 'common/components/button';
import { LoaderBox } from 'common/components/loader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Text } from 'common/components/Text';
import { prettifyError } from 'common/utils/prettify-error';
import { Member, useMembersQuery } from 'modules/company-members/api';
import { useFormatMessage } from 'modules/messages';
import { MembersList } from './MembersList';
import styles from './StepSetSigners.module.scss';

type Props = {
  threshold: number;
  membersCount: number;
  isMutationLoading: boolean;
  onSubmit: () => void;
  selectedMembers: Member[];
  onSelectedMember: (member: Member[]) => void;
  onCancel: () => void;
};

export const StepSetSigners: React.FC<Props> = ({
  membersCount,
  isMutationLoading,
  onSubmit,
  selectedMembers,
  onSelectedMember,
  onCancel,
}) => {
  const formatMessage = useFormatMessage();
  const { isLoading, error } = useMembersQuery();

  return (
    <>
      <ModalHeader
        showClose={true}
        subtitle={formatMessage(
          'multiapproval.chooseMembersWhoWillParticipateInMultiapproval'
        )}
      >
        {formatMessage('multiapproval.firstChooseMultiapprovalParticipants')}
      </ModalHeader>
      <ModalBody>
        {isLoading && <LoaderBox height='400px' />}
        {error && (
          <Alert
            className={styles.error}
            severity='error'
            text={prettifyError(error)}
          />
        )}
        <div className={styles.headerMembers}>
          <Text style='xbold1417'>
            {formatMessage('multiapproval.membersChosen', {
              chosenMembers: selectedMembers.length,
              allMembers: membersCount,
            })}
          </Text>
        </div>
        <MembersList
          selectedMembers={selectedMembers}
          onSelectedMember={onSelectedMember}
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <Button
            type='dark'
            isLoading={isMutationLoading}
            disabled={isMutationLoading}
            name={
              selectedMembers.length === 1
                ? formatMessage('common.button.confirm')
                : formatMessage('multiapproval.nextAdjustMultiapproval')
            }
            onClick={onSubmit}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
