import { useEffect, useState } from 'react';

import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useQueryClient } from '@tanstack/react-query';
import { toDecimal, toFixed } from 'modules/input-amount';
import {
  TransactionActionPayload,
  useTransactionActionMutation,
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { openSnackbar } from 'common/components/snackbar';
import { StepSuccess } from './components/StepSuccess';
import { StepSummary } from './components/StepSummary';
import { StepWithdraw } from './components/StepWithdraw';
import {
  SAVINGS_LIMITS_QUERY_KEY,
  SAVINGS_QUERY_KEY,
  SAVING_QUERY_KEY,
  SavingWithdrawEffectPayload,
  amountExceedsSavingBalance,
  amountMustBeGreaterThanZero,
  amountTooBigDueToPendingTransactions,
  existPendingWithdrawalTransactionsAgainstFullBalance,
  savingHasTopupTransactionInPendingState,
  savingHasWithdrawalTransactionInPendingState,
  savingIsNotActive,
  useWithdrawEffectMutation,
} from 'modules/financial-ops/savings-account/api';
import { Saving } from '../types';
import { CURRENCIES_QUERY_KEY } from 'modules/select-currency-wallet/api';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
  STATUS_INVALID_OTP,
  ALREADY_USED_OTP,
} from 'modules/2FA';
import { prettifyError } from 'common/utils/prettify-error';
import {
  SavingWithdrawContextProvider,
  useSavingWithdrawContext,
} from './context';
import { useCalculateEffect } from './use-calculate-effect';

type WithdrawSteps = 'withdraw' | 'summary' | '2FA' | 'success' | '2FAcancel';

type Props = {
  open: boolean;
  onClose: () => void;
  id: number;
  saving: Saving;
};

const SavingWithdrawWrapped: React.FC<Props> = ({
  open,
  onClose,
  id,
  saving,
}) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();
  const withdrawEffectMutation = useWithdrawEffectMutation(id);
  const context = useSavingWithdrawContext();

  const [step, setStep] = useState<WithdrawSteps>('withdraw');

  const [transactionId, setTransactionId] = useState<number>();
  const [error2FA, setError2FA] = useState<string>();
  const [token, setToken] = useState('');

  const { mutate: transactionActionMutation, isLoading: isLoadingTransaction } =
    useTransactionActionMutation();

  const continueToSummary = () => {
    if (context.amountError || context.walletError) {
      return;
    }

    if (!context.wallet) {
      context.setWalletError('common.walletIsNotSelected');
      return;
    }

    if (toDecimal(context.amount).eq(0)) {
      context.setAmountError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }

    context.setWalletError('');
    context.setAmountError('');
    getInitialToken();
  };

  const approve = (code2fa: string) => {
    if (!transactionId) {
      return;
    }

    const payload: TransactionActionPayload = {
      transaction_id: transactionId,
      action: 'APPROVE',
    };

    transactionActionMutation(
      { payload, headers: generateHeader2FA(code2fa) },
      {
        onSuccess: () => {
          openSnackbar(
            formatMessage('transactions.approved.message'),
            'success'
          );
          queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
          queryClient.invalidateQueries(
            TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
          );
          queryClient.invalidateQueries(SAVINGS_QUERY_KEY);
          queryClient.invalidateQueries(SAVING_QUERY_KEY(id));
          queryClient.invalidateQueries(CURRENCIES_QUERY_KEY);
          queryClient.invalidateQueries(SAVINGS_LIMITS_QUERY_KEY);
          closeModal();
        },
        onError: (err) => {
          if (
            err.response?.data?.status === STATUS_INVALID_OTP ||
            err.response?.data?.status === ALREADY_USED_OTP
          ) {
            setErrorOTP(err, setError2FA, formatMessage);
          } else {
            const text = Object.values(err.response!.data)[0] as string;
            openSnackbar(text, 'error');
          }
        },
      }
    );
  };

  const getInitialToken = () => {
    if (!context.wallet) {
      return;
    }

    const payload: SavingWithdrawEffectPayload = {
      wallet_id: context.wallet.id,
      amount: toFixed(context.amount),
      summary: true,
    };

    withdrawEffectMutation.mutate(payload, {
      onSuccess: (response) => {
        setToken(response.data.token);
        setStep('summary');
      },
      onError: (error) => {
        const amountExceedsSavingBalanceMatch = amountExceedsSavingBalance(
          error.response?.data
        );

        if (amountExceedsSavingBalanceMatch) {
          context.setAmountError(
            formatMessage('saving.withdrawAmountMustBeLessOrEqual', {
              amount: toFixed(amountExceedsSavingBalanceMatch[1]),
              currency: context.currency?.currency,
            })
          );
          return;
        }

        const amountTooBigDueToPendingTransactionsMatch =
          amountTooBigDueToPendingTransactions(error.response?.data);

        if (amountTooBigDueToPendingTransactionsMatch) {
          context.setAmountError(
            formatMessage(
              'saving.dueToPendingWithdrawalTransactionsMaximumAmount',
              {
                amount: toFixed(amountTooBigDueToPendingTransactionsMatch[1]),
                currency: context.currency?.currency,
              }
            )
          );
          return;
        }

        if (amountMustBeGreaterThanZero(error.response?.data)) {
          context.setAmountError(
            formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
          );
          return;
        }

        if (
          existPendingWithdrawalTransactionsAgainstFullBalance(
            error.response?.data
          )
        ) {
          context.setAmountError(
            formatMessage(
              'saving.transactionForClosingSavingsAccountAlreadyInitiated'
            )
          );
          return;
        }

        if (
          savingHasWithdrawalTransactionInPendingState(error.response?.data)
        ) {
          context.setAmountError(
            formatMessage('saving.savingHasWithdrawalTransactionInPendingState')
          );
          return;
        }

        if (savingHasTopupTransactionInPendingState(error.response?.data)) {
          context.setAmountError(
            formatMessage('saving.savingHasTopupTransactionInPendingState')
          );
          return;
        }

        if (savingIsNotActive(error.response?.data)) {
          context.setAmountError(
            formatMessage('saving.savingHasBeenClosedWithdrawIsNotAvailable')
          );
          return;
        }

        context.setAmountError(prettifyError(error));
      },
    });
  };

  const {
    calculateEffect,
    mutation: { isLoading: isLoadingEffect, data: effectData },
  } = useCalculateEffect();

  useEffect(() => {
    if (saving && open) {
      context.setAmount(toFixed(saving.current_max_to_withdraw));
      calculateEffect({ amount: toFixed(saving.current_max_to_withdraw) });
    }
  }, [saving, open]);

  const closeModal = () => {
    onClose();
    context.setWalletId(undefined);
    setStep('withdraw');
    context.setAmount('');
    context.setAmountError('');
    context.setAmountInfo('');
    context.setRemainingFundsInfo('');
  };

  return (
    <>
      <Modal isOpen={open} onClose={closeModal}>
        {step === 'withdraw' && (
          <StepWithdraw
            amount={context.amount}
            errorAmount={context.amountError}
            onSummary={continueToSummary}
            currency={context.currency}
            wallet={context.wallet}
            wallets={context.currency?.wallets}
            errorWallet={context.walletError}
            id={id}
            saving={context.saving}
            newDailyEarn={effectData?.data.interest_daily}
            remainingFunds={
              !toDecimal(saving.current_max_to_withdraw).eq(saving.balance)
                ? toFixed(
                    toDecimal(saving.current_max_to_withdraw).minus(
                      context.amount ?? 0
                    )
                  )
                : effectData?.data.amount_target
            }
            amountInfo={context.amountInfo}
            remainingFundsInfo={context.remainingFundsInfo}
            onCancel={closeModal}
            onAmountChange={(amount) => {
              calculateEffect({
                amount,
              });
            }}
            onWalletChange={(wallet) => {
              calculateEffect({
                wallet: wallet?.id,
              });
            }}
            isLoading={isLoadingEffect}
          />
        )}
        {step === 'summary' && (
          <StepSummary
            amount={context.amount}
            onGoBack={() => {
              setStep('withdraw');
            }}
            currency={context.currency}
            wallet={context.wallet}
            initialNewDailyEarn={
              withdrawEffectMutation.data?.data.interest_daily
            }
            initialRemainingFunds={
              !toDecimal(saving.current_max_to_withdraw).eq(saving.balance)
                ? toFixed(
                    toDecimal(saving.current_max_to_withdraw).minus(
                      context.amount ?? 0
                    )
                  )
                : withdrawEffectMutation.data?.data.amount_target
            }
            interestTotal={saving.interest_total}
            remainingFundsInfo={context.remainingFundsInfo}
            id={id}
            onGoNext={(txId) => {
              setTransactionId(txId);
              setStep('success');
            }}
            initialToken={token}
          />
        )}
        {step === 'success' && (
          <StepSuccess onClose={closeModal} onApprove={() => setStep('2FA')} />
        )}
        {step === '2FA' && (
          <Module2FA
            onVerify={approve}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={isLoadingTransaction}
            onCancel={() => setStep('2FAcancel')}
            approvalOfTransaction={true}
            title={formatMessage('common.approveWithdraw')}
          />
        )}
        {step === '2FAcancel' && (
          <Cancel2FA onClose={closeModal} approvalOfTransaction={true} />
        )}
      </Modal>
    </>
  );
};

export const SavingWithdraw: React.FC<Props> = ({
  open,
  onClose,
  id,
  saving,
}) => {
  return (
    <SavingWithdrawContextProvider saving={saving}>
      <SavingWithdrawWrapped
        open={open}
        onClose={onClose}
        id={id}
        saving={saving}
      />
    </SavingWithdrawContextProvider>
  );
};
