import clsx from 'clsx';
import styles from './OverviewField.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const OverviewField: React.FC<Props> = ({ children, className }) => {
  return <div className={clsx(styles.container, className)}>{children}</div>;
};
