import clsx from 'clsx';
import { CompleteKycModal } from './CompleteKycModal';
import { useKyxContext } from './context';
import { useCompanyKyxState } from './hooks';
import { KyxBar } from './KyxBar';
import { KycFirstLoginModal } from './KycFirstLoginModal';
import styles from './KyxWrapper.module.scss';
import { useFeatureFlag } from 'modules/feature-flags';
import { CompleteKybModal } from './CompleteKybModal';

type Props = {
  children?: React.ReactNode;
};

export const KyxWrapper: React.FC<Props> = ({ children }) => {
  const kybFeatureFlag = useFeatureFlag('KYB');

  const { data, isLoading } = useCompanyKyxState();

  const { isKyxBarAllowed } = useKyxContext();

  const isKyxBarVisible =
    (data?.kyc === 'INITIAL' ||
      (kybFeatureFlag.data?.enabled ? data?.kyb === 'INITIAL' : false)) &&
    !isLoading &&
    isKyxBarAllowed;

  return (
    <>
      <div className={styles.positioningContainer}>
        {isKyxBarVisible && <KyxBar />}
        <div className={clsx(isKyxBarVisible && styles.contentUnderneathBar)}>
          {children}
        </div>
      </div>
      <KycFirstLoginModal />
      <CompleteKycModal />
      <CompleteKybModal />
    </>
  );
};
