import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { post } from 'modules/api';

const KYC_REQUEST_INQUIRY_URL = '/kyc-request';
const KYC_VERIFY_URL = '/kyc-verify';

const KYC_REQUEST_INQUIRY_MUTATION_KEY = [
  'POST',
  KYC_REQUEST_INQUIRY_URL,
] as const;

const KYC_VERIFY_INQUIRY_MUTATION_KEY = ['POST', KYC_VERIFY_URL] as const;

type KycRequestInquiryResponseData = {
  persona_inquiry_id: string;
};

type KycRequestInquiryResponseError = {
  non_field_errors?: string[];
};

type KycVerifyInquiryPayload = {
  persona_inquiry_id: string;
};

type KycVerifyInquiryResponseData = {};

type KycVerifyInquiryResponseError = {
  non_field_errors?: string[];
};

const requestKycInquiry = () =>
  post<KycRequestInquiryResponseData>(KYC_REQUEST_INQUIRY_URL);

const verifuKycInquiry = (payload: KycVerifyInquiryPayload) =>
  post<KycRequestInquiryResponseData, KycVerifyInquiryPayload>(
    KYC_VERIFY_URL,
    payload
  );

export const useKycRequestInquiryMutation = () =>
  useMutation<
    AxiosResponse<KycRequestInquiryResponseData>,
    AxiosError<KycRequestInquiryResponseError>
  >(KYC_REQUEST_INQUIRY_MUTATION_KEY, requestKycInquiry);

export const useKycVerifyMutation = () =>
  useMutation<
    AxiosResponse<KycVerifyInquiryResponseData>,
    AxiosError<KycVerifyInquiryResponseError>,
    KycVerifyInquiryPayload
  >(KYC_VERIFY_INQUIRY_MUTATION_KEY, verifuKycInquiry);

export const ERR_ALREADY_COMPLETED = 'KYC already completed';
export const ERR_VERIFICATION_FAILED = 'KYC verification failed';
