import clsx from 'clsx';
import styles from './Button.module.scss';
import { Text } from 'common/components/Text';
import { theme } from 'common/consts/theme';

type Props = {
  name: React.ReactNode;
  primaryColor: string;
  secondaryColor: string | null;
  onClick: () => void;
};

export const PrimaryButton: React.FC<Props> = ({
  name,
  primaryColor,
  secondaryColor,
  onClick,
}) => {
  return (
    <button
      className={styles.button}
      style={{ background: secondaryColor ?? primaryColor }}
      onClick={onClick}
    >
      <Text
        style='xbold1417'
        inlineStyle={{
          color: secondaryColor ? primaryColor : theme.colors.btnLight,
        }}
      >
        {name}
      </Text>
    </button>
  );
};

export const SecondaryButton: React.FC<Props> = ({
  name,
  primaryColor,
  secondaryColor,
  onClick,
}) => {
  return (
    <button
      className={clsx(styles.button, styles.secondaryButton)}
      onClick={onClick}
    >
      <Text
        style='xbold1417'
        inlineStyle={{
          color: !secondaryColor ? primaryColor : theme.colors.textPrimary,
        }}
      >
        {name}
      </Text>
    </button>
  );
};
