import { ReactComponent as MontesauriBackgroundLogomark } from 'common/assets/logo/montesauri-background-logomark.svg';
import styles from './NotFoundLayout.module.scss';
import { MontesauriLogotype } from './MontesauriLogotype';

type Props = {
  children?: React.ReactNode;
};

export const NotFoundLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      <MontesauriBackgroundLogomark className={styles.backgroundIcon} />
      <div className={styles.topContainer}>
        <MontesauriLogotype className={styles.logotype} path={'/dashboard'} />
      </div>
      <div className={styles.mainContainer}>{children}</div>
    </div>
  );
};
