import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';

type Props = {};

export const ApproveTransactionText: React.FC<Props> = ({}) => {
  const formatMessage = useFormatMessage();

  return (
    <Text style='xbold1417'>
      {formatMessage('common.approveTransactionQuestion')}
    </Text>
  );
};
