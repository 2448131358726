import { Text } from 'common/components/Text';
import { openSnackbar } from 'common/components/snackbar';
import { useFormatMessage } from 'modules/messages';
import { copyToClipboard } from 'common/utils/utils';
import { ReactComponent as Copy } from '../assets/copy.svg';
import styles from './CopyableAddress.module.scss';

type Props = {
  address: string;
  dataTest?: string;
};

export const CopyableAddress: React.FC<Props> = ({ address, dataTest }) => {
  const formatMessage = useFormatMessage();
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    copyToClipboard(address);
    openSnackbar(formatMessage('common.copy.message'), 'success');
  };

  return (
    <div className={styles.container} data-test={dataTest}>
      <Text style='light1215'>{address}</Text>
      <div
        className={styles.copyIcon}
        onClick={handleClick}
        data-test='copyAddressIcon'
      >
        <Copy />
      </div>
    </div>
  );
};
