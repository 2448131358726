import clsx from 'clsx';
import { Text, textStyles } from 'common/components/Text';
import { InputAmount } from 'modules/input-amount';
import { CurrencySelector } from 'modules/currency-selector';
import { useRef } from 'react';
import styles from './AssetAmountInput.module.scss';
import { MaxButton } from './MaxButton';
import { DECIMALS_MAP } from 'common/utils/currency-enum-utils';
import { CryptoToFiatAmount } from 'modules/fiat';
import {
  BaseCurrency,
  CurrencyWallet,
} from 'modules/select-currency-wallet/types';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { InterestRate, InterestRateKind } from 'modules/financial-ops/common';
import { WalletSelector } from './WalletSelector';

type Props = {
  amount: string;
  currency?: BaseCurrency;
  currencies?: BaseCurrency[];
  desktopHeader: string;
  decimals?: number;
  disabled?: boolean;
  error?: string;
  interestRateKind?: InterestRateKind;
  info?: string;
  isLoadingCurrencies: boolean;
  mobileAmountHeader: string;
  mobileAssetHeader: string;
  showApr?: boolean;
  textTooltip?: string;
  wallet?: CurrencyWallet;
  wallets?: CurrencyWallet[];
  walletError?: string;
  onMax?: () => void;
  onAmountChange: (value: string) => void;
  onCurrencyChange?: (currency: BaseCurrency, rate?: InterestRate) => void;
  onWalletChange: (value: CurrencyWallet) => void;
  onError?: (error: string) => void;
  dataTest?: string;
  showFiat?: boolean;
};

export const AssetAmountInput: React.FC<Props> = ({
  amount,
  currency,
  currencies,
  desktopHeader,
  decimals,
  disabled = false,
  error = '',
  interestRateKind,
  info = '',
  isLoadingCurrencies,
  mobileAmountHeader,
  mobileAssetHeader,
  showApr = true,
  textTooltip,
  wallet,
  wallets,
  walletError = '',
  onAmountChange,
  onCurrencyChange,
  onWalletChange,
  onMax,
  onError = () => {},
  dataTest,
  showFiat = true,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.rootContainer} data-test={dataTest}>
      <div className={clsx(styles.mobileCurrencyBox, styles.hideOnDesktop)}>
        <SectionSubheader tooltip={textTooltip}>
          {mobileAssetHeader}
        </SectionSubheader>
        <CurrencySelector
          className={styles.selectBtn}
          disabled={disabled}
          readOnly={!onCurrencyChange}
          currency={currency}
          interestRateKind={interestRateKind}
          showApr={showApr}
          onChange={onCurrencyChange}
          currencies={currencies}
          isLoadingCurrencies={isLoadingCurrencies}
        />

        {currency && (
          <WalletSelector
            wallets={wallets}
            selectedWallet={wallet}
            onSelectWallet={(data) => {
              onWalletChange(data);
            }}
            error={walletError}
            currency={currency}
            className={styles.mobileWalletSelector}
          />
        )}
      </div>
      <div
        className={clsx(
          styles.container,
          currency && styles.containerMargin,
          !currency && styles.hideOnMobile
        )}
      >
        <SectionSubheader
          className={clsx(styles.hideOnDesktop, !currency && styles.hideText)}
        >
          {mobileAmountHeader}
        </SectionSubheader>
        <SectionSubheader className={styles.hideOnMobile} tooltip={textTooltip}>
          {desktopHeader}
        </SectionSubheader>
        <div
          className={clsx(
            styles.inputContainer,
            disabled && styles.disabled,
            error && styles.inputContainerError,
            info && !error && styles.inputContainerInfo,
            !currency && styles.removeBorderOnSmall
          )}
        >
          {currency && onMax && (
            <div className={styles.maxButtonContainer}>
              <MaxButton onClick={onMax} />
            </div>
          )}
          {currency && wallet && (
            <div
              className={styles.amountContainer}
              onClick={() => {
                inputRef.current?.focus();
              }}
            >
              <div className={styles.inputWithCurrency}>
                <InputAmount
                  value={amount}
                  onChange={onAmountChange}
                  onError={onError}
                  decimals={decimals ?? DECIMALS_MAP[currency.currency]}
                  className={clsx(styles.amountInput, textStyles.light1622)}
                  inputRef={inputRef}
                />
                <Text style={'light1622'} className={styles.inputCurrency}>
                  {currency.currency}
                </Text>
              </div>
              {showFiat && (
                <CryptoToFiatAmount
                  amount={amount || '0.00'}
                  currency={currency.currency}
                  className={styles.amountFiat}
                />
              )}
            </div>
          )}
          <CurrencySelector
            className={clsx(styles.hideOnMobile, styles.selectBtn)}
            disabled={disabled}
            readOnly={!onCurrencyChange}
            currency={currency}
            interestRateKind={interestRateKind}
            showApr={showApr}
            onChange={onCurrencyChange}
            currencies={currencies}
            isLoadingCurrencies={isLoadingCurrencies}
          />
        </div>
        {error && (
          <Text style='regular1215' colorStyle='error' dataTest='errorText'>
            {error}
          </Text>
        )}
        {info && !error && (
          <Text style='regular1215' colorStyle='inputInfo'>
            {info}
          </Text>
        )}
      </div>
      <div className={styles.hideOnMobile}>
        <WalletSelector
          wallets={wallets}
          selectedWallet={wallet}
          onSelectWallet={(data) => {
            onWalletChange(data);
          }}
          error={walletError}
          currency={currency}
          className={styles.desktopWalletSelector}
        />
      </div>
    </div>
  );
};
