import { ChangeOwnerSection } from './ChangeOwnerSection';
import { MultiapprovalSection } from './MultiapprovalSection';
import { KyxSection } from './kyx-section';

export const GlobalSettings = () => {
  return (
    <div>
      <KyxSection />
      <MultiapprovalSection />
      <ChangeOwnerSection />
    </div>
  );
};
