import clsx from 'clsx';
import { ReactComponent as ArrowDownIcon } from 'common/assets/black-icons/arrowhead-down-12px-withpadding.svg';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { useState } from 'react';
import { CurrencySelectorModal } from './components/CurrencySelectorModal';
import styles from './CurrencySelector.module.scss';
import { BaseCurrency } from 'modules/select-currency-wallet/types';
import { InterestRate, InterestRateKind } from 'modules/financial-ops/common';

type Props = {
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
  currency?: BaseCurrency;
  interestRateKind?: InterestRateKind;
  showApr?: boolean;
  onChange?: (currency: BaseCurrency, rate?: InterestRate) => void;
  currencies?: BaseCurrency[];
  isLoadingCurrencies?: boolean;
};

export const CurrencySelector: React.FC<Props> = ({
  disabled,
  className,
  readOnly = false,
  currency,
  interestRateKind,
  showApr,
  onChange,
  currencies,
  isLoadingCurrencies,
}) => {
  const [openSelectCurrency, setOpenSelectCurrency] = useState(false);

  return (
    <div
      className={clsx(
        styles.container,
        disabled && styles.disabled,
        readOnly && styles.readOnly,
        className
      )}
      onClick={() => {
        if (disabled) {
          return;
        }
        setOpenSelectCurrency(true);
      }}
    >
      <CurrencyBox
        currency={currency?.currency}
        network={currency?.network}
        isPlaceholder={!currency?.currency}
        dataTest='currencyBtn'
      />
      {!readOnly && (
        <>
          <ArrowDownIcon />
          <CurrencySelectorModal
            onClose={() => {
              setOpenSelectCurrency(false);
            }}
            open={openSelectCurrency}
            currencies={currencies}
            isLoadingCurrencies={isLoadingCurrencies}
            interestRateKind={interestRateKind}
            onChange={onChange}
            showApr={showApr}
          />
        </>
      )}
    </div>
  );
};
