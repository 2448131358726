import { TradeFilterModel, TradeSortModel } from '../hooks';

export const getSortText = (sort: TradeSortModel) => {
  if (sort.executedAt) {
    return sort.executedAt === 'asc' ? 'executed_at' : '-executed_at';
  }

  if (sort.inAmountFiat) {
    return sort.inAmountFiat === 'asc' ? 'in_amount_fiat' : '-in_amount_fiat';
  }

  if (sort.outAmountFiat) {
    return sort.outAmountFiat === 'asc'
      ? 'out_amount_fiat'
      : '-out_amount_fiat';
  }
};

export const getFilterText = (filter: TradeFilterModel) => {
  if (filter.all) {
    return undefined;
  }

  if (filter.completed) {
    return 'COMPLETED';
  }

  if (filter.failed) {
    return 'FAILED';
  }
};
