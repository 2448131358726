import { Text } from 'common/components/Text';
import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useNewCharityFormContext } from '../context';
import {
  CharityFormPublicRead,
  buildCharityFormPreviewPageUrl,
} from 'modules/charity-forms-public';
import { utf16ToBase64 } from 'modules/charity-forms-public/encode-utils';
import { toDecimal } from 'modules/input-amount';
import { differenceInDays } from 'date-fns';
import styles from './CharityFormPreviewButton.module.scss';
import { CharityFormPicture } from 'modules/charity-forms-public/types';
import { theme } from 'common/consts/theme';
import { Tooltip } from 'common/components/Tooltip';

type Props = {};

export const CharityFormPreviewButton: React.FC<Props> = ({}) => {
  const formatMessage = useFormatMessage();

  const context = useNewCharityFormContext();

  const form: CharityFormPublicRead = {
    token: '',
    state: 'ACTIVE',
    name: context.name,
    overview: context.overview,
    description: context.description,
    dark_mode: context.darkMode,
    primary_color: context.primaryColor,
    secondary_color: context.secondaryColor ?? null,
    pictures: (context.files || [])
      .filter((file) => file.kind === 'PHOTO')
      .map(
        (file, idx) =>
          ({
            file: file.url,
            kind: 'CONTENT',
            order: idx + 1,
          } as CharityFormPicture)
      )
      .concat(
        context.logo
          ? [
              {
                file: context.logo.url,
                kind: 'LOGO',
                order: null,
              },
            ]
          : []
      ),
    goal_enabled: context.goalEnabled,
    end_at_enabled: context.endAtEnabled,
    goal_amount_fiat: context.goalEnabled ? context.goalAmount! : null,
    goal_fiat: context.fiatCurrency,
    created_at: new Date().toISOString(),
    end_at: context.endAtEnabled ? context.endAt! : null,
    youtube_link: context.youtubeLink
      ? utf16ToBase64(context.youtubeLink)
      : null,
    youtube_link_order: context.youtubeLinkIndex ?? null,
    total_raised_by_currency: {
      BTC: '0',
      ETH: '0',
      WBTC: '0',
      USDC: '0',
      USDT: '0',
    },
    total_raised_fiat_by_currency: {
      BTC: '0',
      ETH: '0',
      WBTC: '0',
      USDC: '0',
      USDT: '0',
    },
    total_raised_fiat: context.goalEnabled
      ? toDecimal(context.goalAmount).mul(0.75).toString()
      : '1600.00',
    goal_raised_pct: context.goalEnabled ? '75.00' : null,
    days_active: 8,
    days_left: context.endAtEnabled
      ? differenceInDays(new Date(context.endAt!), new Date())
      : null,
    donation_count: 15,
    networks: context.networkList,
  } as CharityFormPublicRead; // TODO: as CharityFormPublicRead should be removed when all data is ready

  const previewUrl = buildCharityFormPreviewPageUrl(
    utf16ToBase64(JSON.stringify(form))
  );

  const isPreviewDisabled = !context.logo;

  return (
    <Tooltip
      title={
        isPreviewDisabled ? formatMessage('charityForms.youMustUploadLogo') : ''
      }
    >
      <span>
        <Button
          disabled={isPreviewDisabled}
          name={
            <div className={styles.previewButton}>
              <Loupe
                color={
                  isPreviewDisabled
                    ? theme.colors.textPrimaryGreyout
                    : theme.colors.textPrimary
                }
              />
              <Text style='xbold1215'>
                {formatMessage('charityForms.preview')}
              </Text>
            </div>
          }
          type={'secondarySmall'}
          onClick={() => {
            const link = document.createElement('a');
            link.href = previewUrl;
            link.target = '_blank';
            link.click();
          }}
        />
      </span>
    </Tooltip>
  );
};

const Loupe: React.FC<{ color?: string }> = ({ color = 'black' }) => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <circle cx='4.83742' cy='5.20656' r='4.33742' stroke={color} />
      <line
        x1='8.555'
        y1='8.40758'
        x2='11.4507'
        y2='10.9256'
        stroke={color}
        stroke-linecap='round'
      />
    </g>
  </svg>
);
