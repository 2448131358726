import { Text } from 'common/components/Text';
import styles from './ShowMoreLess.module.scss';
import { useFormatMessage } from 'modules/messages';
import { ReactComponent as ArrowIcon } from 'common/assets/black-icons/arrowhead-down-15px.svg';
import clsx from 'clsx';

type Props = {
  isMore: boolean;
  onClick: () => void;
};

export const ShowMoreLess: React.FC<Props> = ({ isMore, onClick }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container} onClick={onClick}>
      <Text style='xxbold1417'>
        {isMore
          ? formatMessage('common.showLess')
          : formatMessage('common.showMore')}
      </Text>
      <ArrowIcon
        className={clsx(styles.iconLessMore, isMore && styles.iconReverse)}
      />
    </div>
  );
};
