import { textStyles } from 'common/components/Text';
import styles from './CharityFormDescription.module.scss';
import clsx from 'clsx';
import { useCharityFormPublicContext } from '../context';

export const CharityFormDescription: React.FC<{}> = () => {
  const { form } = useCharityFormPublicContext();

  return (
    <>
      <h2 className={clsx(textStyles.xxbold2233, styles.header)}>
        {form.name}
      </h2>
      <p className={clsx(textStyles.light1827, styles.description)}>
        {form.description}
      </p>
    </>
  );
};
