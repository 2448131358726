import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { Button, CancelButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { Text } from 'common/components/Text';
import { prettifyError } from 'common/utils/prettify-error';
import { formatDate } from 'common/utils/utils';
import { CURRENCY_RATES_QUERY_KEY } from 'modules/fiat';
import {
  TermEnum,
  termToMonths,
  tokenInvalidValue,
  tokenIsRequired,
} from 'modules/financial-ops/common';
import { MaturitySummaryLabel } from 'modules/financial-ops/common/components/MaturitySummaryLabel';
import {
  WalletBox,
  AssetAmountReadOnly,
  TokenExpiryTimer,
  SnapshotAlert,
} from 'modules/financial-ops/common/components';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { useContext, useState } from 'react';
import {
  BorrowsEffectError,
  BorrowsEffectPayload,
  collateralInsufficientFundsError,
  collateralMaxError,
  collateralMinError,
  interestRateDoesNotExist,
  PostBorrowError,
  principalMaxError,
  principalMinError,
  useBorrowMutation,
  useBorrowsEffectMutation,
} from '../api';
import styles from './StepNewBorrowSummary.module.scss';
import { TermsAndConditions } from 'modules/documents';

type Props = {
  collateralCurrency?: Currency;
  collateralWallet?: CurrencyWallet;
  initialCollateralAmount?: string;
  initialInterestAmount?: string;
  initialInterestRatePct?: string;
  initialLtv?: string;
  initialMaturity?: string;
  initialToken: string | undefined;
  interestRateTerm?: TermEnum;
  principalAmount?: string;
  principalCurrency?: Currency;
  principalWallet?: CurrencyWallet;
  automaticTopupChecked: boolean;
  onSuccess: (transactionId: number) => void;
};

export const StepNewBorrowSummary: React.FC<Props> = ({
  collateralCurrency,
  collateralWallet,
  initialCollateralAmount,
  initialInterestAmount,
  initialInterestRatePct,
  initialLtv,
  initialMaturity,
  initialToken,
  interestRateTerm,
  principalAmount,
  principalCurrency,
  principalWallet,
  automaticTopupChecked,
  onSuccess,
}) => {
  const formatMessage = useFormatMessage();

  const [checked, setChecked] = useState(false);
  const [termsAndConditionsError, setTermsAndConditionError] = useState(false);
  const [timeExpired, setTimeExpired] = useState(false);

  const { onClose } = useContext(ModalContext);

  const queryClient = useQueryClient();

  const borrowMutation = useBorrowMutation();
  const borrowsEffectMutation = useBorrowsEffectMutation();

  const getErrorText = (error: AxiosError<PostBorrowError>) => {
    if (!error.response?.data) {
      return prettifyError(error);
    }

    let match: RegExpMatchArray | undefined;
    match = collateralInsufficientFundsError(error.response?.data);
    if (match) {
      return formatMessage('borrow.notEnoughFundsInCollateralWallet', {
        amount: match[1],
        currency: match[2],
      });
    }

    match = principalMinError(error.response?.data);
    if (match) {
      return formatMessage(
        'borrow.currentPrincipalAmountMustBeGreaterOrEqual',
        {
          amount: toFixed(match[1]),
          currency: principalCurrency?.currency,
        }
      );
    }

    match = principalMaxError(error.response?.data);
    if (match) {
      return formatMessage('borrow.currentPrincipalAmountMustBeLessOrEqual', {
        amount: toFixed(match[1]),
        currency: principalCurrency?.currency,
      });
    }

    match = collateralMinError(error.response?.data);
    if (match) {
      return formatMessage(
        'borrow.currentCollateralAmountMustBeGreaterOrEqual',
        {
          amount: toFixed(match[1]),
          currency: collateralCurrency?.currency,
        }
      );
    }

    match = collateralMaxError(error.response?.data);
    if (match) {
      return formatMessage('borrow.currentCollateralAmountMustBeLessOrEqual', {
        amount: toFixed(match[1]),
        currency: collateralCurrency?.currency,
      });
    }

    if (tokenIsRequired(error.response?.data)) {
      return formatMessage('common.tokenIsRequired');
    }

    return prettifyError(error);
  };

  const refetchToken = () => {
    if (
      !collateralCurrency ||
      !collateralWallet ||
      !initialInterestRatePct ||
      !interestRateTerm ||
      !principalCurrency ||
      !principalWallet
    ) {
      return;
    }

    let payload: BorrowsEffectPayload = {
      calculation_base: 'PRINCIPAL',
      term: interestRateTerm,
      collateral_currency: collateralCurrency.currency,
      collateral_wallet_id: collateralWallet?.id,
      principal_currency: principalCurrency.currency,
      principal_wallet_id: principalWallet?.id,
      principal_amount: toFixed(principalAmount),
      auto_topup: automaticTopupChecked,
      summary: true,
    };

    borrowsEffectMutation.mutate(payload, {
      onError: () => {
        queryClient.invalidateQueries(CURRENCY_RATES_QUERY_KEY);
      },
    });
  };

  const getRefetchTokenError = (error: AxiosError<BorrowsEffectError>) => {
    const collateralMinErrorMatch = collateralMinError(error.response?.data);
    if (collateralMinErrorMatch) {
      return formatMessage('borrow.collateralAmountMustGreater', {
        amount: toFixed(collateralMinErrorMatch[1]),
        currency: collateralCurrency?.currency,
      });
    }

    const collateralMaxErrorMatch = collateralMaxError(error.response?.data);
    if (collateralMaxErrorMatch) {
      return formatMessage('borrow.collateralAmountMustLess', {
        amount: toFixed(collateralMaxErrorMatch[1]),
        currency: collateralCurrency?.currency,
      });
    }

    const principalMinErrorMatch = principalMinError(error.response?.data);
    if (principalMinErrorMatch) {
      return formatMessage('borrow.principalAmountMustGreater', {
        amount: toFixed(principalMinErrorMatch[1]),
        currency: principalCurrency?.currency,
      });
    }

    const principalMaxErrorMatch = principalMaxError(error.response?.data);
    if (principalMaxErrorMatch) {
      return formatMessage('borrow.principalAmountMustLess', {
        amount: toFixed(principalMaxErrorMatch[1]),
        currency: principalCurrency?.currency,
      });
    }

    if (interestRateDoesNotExist(error.response?.data)) {
      return formatMessage('finopsCommon.noInterestRateWasFound');
    }

    return prettifyError(error);
  };

  const modalHeader = (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('finopsCommon.reviewConfirmTransaction')}
          </Text>
        }
      >
        {formatMessage('borrow.borrowSummary')}
      </ModalHeader>
    </>
  );

  // use locally refetched token if initial token expired
  const token = borrowsEffectMutation.data?.data.token || initialToken;
  const ltv = borrowsEffectMutation.data?.data.ltv_pct || initialLtv;
  const interestRatePct =
    borrowsEffectMutation.data?.data.rate_pct || initialInterestRatePct;
  const interestAmount =
    borrowsEffectMutation.data?.data.interest || initialInterestAmount;
  const maturity = borrowsEffectMutation.data?.data.end_at || initialMaturity;
  const collateralAmount = borrowsEffectMutation.data?.data
    ? toLimitedPrec(toFixed(borrowsEffectMutation.data?.data.collateral_amount))
    : initialCollateralAmount;

  return (
    <>
      {modalHeader}
      <ModalBody>
        {borrowMutation.isError &&
          !tokenInvalidValue(borrowMutation.error.response?.data) && (
            <Alert
              severity='error'
              text={getErrorText(borrowMutation.error)}
              className={styles.error}
            />
          )}
        {borrowsEffectMutation.error && (
          <Alert
            severity='error'
            text={getRefetchTokenError(borrowsEffectMutation.error)}
            className={styles.error}
          />
        )}
        <SectionSubheader>{formatMessage('common.principal')}</SectionSubheader>
        {principalCurrency && (
          <AssetAmountReadOnly
            amount={toDecimal(principalAmount)}
            currency={principalCurrency}
            className={styles.amountAssetBox}
            dataTest='principalField'
          />
        )}
        {principalCurrency && principalWallet && (
          <WalletBox
            wallet={principalWallet}
            currency={principalCurrency.currency}
            className={styles.walletBox}
          />
        )}
        <SectionSubheader tooltip={formatMessage('borrow.collateralCanVary')}>
          {formatMessage('common.collateral')}
        </SectionSubheader>
        {collateralCurrency && (
          <AssetAmountReadOnly
            amount={toDecimal(collateralAmount)}
            currency={collateralCurrency}
            className={styles.amountAssetBox}
            prefix={'~'}
            dataTest='collateralField'
          />
        )}
        {collateralCurrency && collateralWallet && (
          <WalletBox
            wallet={collateralWallet}
            currency={collateralCurrency.currency}
            className={styles.walletBox}
          />
        )}
        <Summary className={styles.summary}>
          <SummaryLabel>
            {formatMessage('common.totalAmountToRepay')}
          </SummaryLabel>
          <SummaryValue dataTest='amountToRepayInSummary'>
            {`${toLimitedPrec(
              toDecimal(principalAmount)
                .add(toDecimal(interestAmount))
                .toFixed()
            )} ${principalCurrency?.currency || ''}`}
          </SummaryValue>
          <SummaryLabel>{formatMessage('finopsCommon.term')}</SummaryLabel>
          <SummaryValue dataTest='termInSummary'>
            {interestRateTerm
              ? formatMessage('termAndRate.term', {
                  months: termToMonths(interestRateTerm),
                })
              : ''}
          </SummaryValue>
          <SummaryLabel>{formatMessage('common.interestRate')}</SummaryLabel>
          <SummaryValue dataTest='rateInSummary'>
            {interestRatePct ? `${toFixed(interestRatePct)}%` : ''}
          </SummaryValue>
          <SummaryLabel>{formatMessage('borrow.ltvRatio')}</SummaryLabel>
          <SummaryValue dataTest='ltvInSummary'>{`${toFixed(
            ltv
          )}%`}</SummaryValue>
          <MaturitySummaryLabel />
          <SummaryValue dataTest='maturityInSummary'>
            {maturity && formatDate(maturity)}
          </SummaryValue>
        </Summary>
        <TermsAndConditions
          checked={checked}
          error={termsAndConditionsError}
          onToggle={(checked) => {
            setTermsAndConditionError(false);
            setChecked(checked);
          }}
          className={styles.terms}
        />
        {(tokenInvalidValue(borrowMutation.error?.response?.data) ||
          timeExpired) && (
          <SnapshotAlert
            onRefresh={() => {
              refetchToken();
              setTimeExpired(false);
              borrowMutation.reset();
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onClose} />
          <Button
            type='dark'
            name={
              <div className={styles.button}>
                <Text style='xbold1417'>{formatMessage('borrow.borrow')}</Text>
                {!borrowsEffectMutation.error && token && (
                  <TokenExpiryTimer
                    token={token}
                    onTimeExpired={() => setTimeExpired(true)}
                  />
                )}
              </div>
            }
            isLoading={borrowMutation.isLoading}
            disabled={
              borrowMutation.isLoading ||
              timeExpired ||
              !!borrowsEffectMutation.error
            }
            onClick={() => {
              if (borrowMutation.isLoading) {
                return;
              }
              if (!checked) {
                setTermsAndConditionError(true);
                return;
              }
              // There is no message for the user here, because this should be impossible situation.
              // Validation before summary modal is opened should check for amount, currencies, interest rate
              // and wallets and if one is missing, prevent modal from being openend.
              if (
                !collateralCurrency ||
                !collateralWallet ||
                !interestRateTerm ||
                !principalAmount ||
                !principalCurrency ||
                !principalWallet ||
                !token
              ) {
                return;
              }
              borrowMutation.mutate(
                {
                  token,
                },
                {
                  onSuccess: (res) => {
                    queryClient.invalidateQueries(
                      DASHBOARD_TRANSACTIONS_QUERY_KEY
                    );
                    queryClient.invalidateQueries(
                      TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
                    );
                    onSuccess(res.data.transaction_id);
                  },
                  onError: (error) => {
                    // for this particular error invalidate currency rates
                    // (because it only occurs in currency rates changes between effect and borrow create)
                    // to trigger borrow effect is recalculation
                    if (
                      error.response?.data &&
                      collateralInsufficientFundsError(error.response.data)
                    ) {
                      queryClient.invalidateQueries(CURRENCY_RATES_QUERY_KEY);
                    }
                  },
                }
              );
            }}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
