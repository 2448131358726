import { TransactionType } from 'modules/transactions/types';
import { ReactComponent as IncomingIcon } from 'modules/transactions/assets/incoming.svg';
import { ReactComponent as OutcomingIcon } from 'modules/transactions/assets/outcoming.svg';

type Props = { type: TransactionType; className?: string };

export const IconByType: React.FC<Props> = ({ type, className }) => {
  return (
    <span className={className}>
      {type === 'INCOMING' && <IncomingIcon style={{ minWidth: '10px' }} />}
      {type === 'OUTGOING' && <OutcomingIcon style={{ minWidth: '10px' }} />}
    </span>
  );
};
