import clsx from 'clsx';
import styles from './ThemeDot.module.scss';

type Props = {
  color: string;
  isSelected?: boolean;
  onClick: () => void;
};

export const ThemeDot: React.FC<Props> = ({ color, isSelected, onClick }) => {
  return (
    <div
      className={clsx(styles.container, isSelected && styles.isSelected)}
      onClick={onClick}
    >
      <div className={styles.dot} style={{ background: color }} />
    </div>
  );
};
