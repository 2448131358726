import { useFormatMessage } from 'modules/messages';
import { Button, ButtonProps } from './Button';

type Props = Omit<ButtonProps, 'name' | 'type'>;

export const CancelButton: React.FC<Props> = (props) => {
  const formatMessage = useFormatMessage();
  return (
    <Button
      name={formatMessage('common.button.cancel')}
      type='secondary'
      {...props}
    />
  );
};
