import { LoaderBox } from 'common/components/loader';
import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { NavigationHeader } from 'modules/financial-ops/common';
import { useFormatMessage } from 'modules/messages';
import { TradeList } from './TradeList';

export const TradeHistory: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  const tradeFeatureFlag = useRequireFeatureFlag('TRADE');

  if (tradeFeatureFlag.isLoading) {
    return <LoaderBox />;
  }

  if (!tradeFeatureFlag.data?.enabled) {
    return null;
  }

  return (
    <Section>
      <NavigationHeader to='/trades'>
        {formatMessage('common.back')}
      </NavigationHeader>
      <SectionHeader header={formatMessage('trade.tradeHistory')} />
      <TradeList />
    </Section>
  );
};
