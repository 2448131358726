import { Section } from 'common/components/Section';
import { useFormatMessage } from 'modules/messages';
import { SectionHeader } from 'common/components/SectionHeader';
import { Text } from 'common/components/Text';
import styles from './TransactionDetails.module.scss';
import { ReactComponent as ArrowLeftIcon } from 'common/assets/black-icons/arrow-left-small.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailsInformations } from './components/DetailsInformations';
import { DetailsButtons } from './components/DetailsButtons';

type Props = {};

export const TransactionDetails: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Section>
      <SectionHeader
        header={
          <div className={styles.header}>
            <ArrowLeftIcon
              onClick={() => navigate('/transactions')}
              data-test='backArrow'
              style={{ cursor: 'pointer' }}
            />
            <div>
              <Text style='xxbold3644'>
                {formatMessage('transactions.transactionDetails')}
              </Text>
            </div>
          </div>
        }
        actions={<DetailsButtons isSmall={true} transactionId={id} />}
      />
      <DetailsInformations id={id} />
    </Section>
  );
};
