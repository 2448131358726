import clsx from 'clsx';
import styles from './ColorPicker.module.scss';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from 'common/assets/grey-icons/plus.svg';
import { useFormatMessage } from 'modules/messages';
import { Button } from 'common/components/button/Button';
import { ColorPickerWrapped } from 'modules/color-picker';
import { isValidHex } from 'modules/color-picker/utils';

type Props = {
  onClick: (color: string) => void;
};

const DEFAULT_COLOR = '#EDE300';

export const ColorPicker: React.FC<Props> = ({ onClick }) => {
  const formatMessage = useFormatMessage();

  const [hex, setHex] = useState<string>(DEFAULT_COLOR);
  const [open, setOpen] = useState(false);

  const myRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = ({ target }: MouseEvent) => {
    if (myRef && myRef.current && !myRef.current.contains(target as Node)) {
      setOpen(false);
    }
  };

  const handleClickInside = () => {
    setOpen(true);
    if (!isValidHex(hex)) setHex(DEFAULT_COLOR);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div className={styles.container}>
      <div className={styles.addingBox} onClick={handleClickInside}>
        <PlusIcon />
      </div>
      {open && (
        <div className={clsx(styles.modal)} ref={myRef}>
          <ColorPickerWrapped
            color={hex}
            onColorChange={(col) => setHex(col)}
          />
          <div className={styles.buttons}>
            <Button
              name={formatMessage('common.button.cancel')}
              type='secondarySmall'
              onClick={() => {
                setOpen(false);
              }}
            />
            <Button
              name={formatMessage('common.button.add')}
              type='darkSmall'
              disabled={!isValidHex(hex)}
              onClick={() => {
                onClick(hex);
                setOpen(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
