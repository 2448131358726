import styles from './ModalActions.module.scss';

type Props = {
  children?: React.ReactNode;
  extraLink?: React.ReactNode;
};

export const ModalActions: React.FC<Props> = ({ children, extraLink }) => {
  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>{children}</div>
      {extraLink && <div>{extraLink}</div>}
    </div>
  );
};
