import { Text, TextStyle } from 'common/components/Text';
import { CurrencyEnum } from 'common/types';
import { toFixed, toLimitedPrec } from 'modules/input-amount';

type Props = { amount: string; currency: CurrencyEnum; textStyles?: TextStyle };

export const BoldAmount: React.FC<Props> = ({
  amount,
  currency,
  textStyles = 'bold1422',
}) => {
  return (
    <Text style={textStyles}>{`${toLimitedPrec(
      toFixed(amount)
    )} ${currency}`}</Text>
  );
};
