import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { Saving } from '../../types';
import { InterestAccrual } from '../../common/InterestAccrual';
import { DailyEarn } from '../../common';
import { RemainingFunds } from '../../../../common-data-fields/RemainingFunds';
import { toDecimal } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';

type Props = {
  amount?: string;
  currency: Currency;
  wallet?: CurrencyWallet;
  id: number;
  saving: Saving;
  remainingFunds?: string;
  newDailyEarn?: string;
  remainingFundsInfo: string;
  isLoading: boolean;
};

export const WithdrawEffect: React.FC<Props> = ({
  amount,
  currency,
  saving,
  remainingFunds,
  newDailyEarn,
  remainingFundsInfo,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <RemainingFunds
        amount={
          amount === INITIAL_AMOUNT || toDecimal(amount).eq(0)
            ? INITIAL_AMOUNT
            : remainingFunds
        }
        subamount={saving.balance}
        currency={currency.currency}
        remainingFundsInfo={remainingFundsInfo}
        isLoading={isLoading}
        subtitle={formatMessage('saving.currentFundsLocked')}
      />
      <DailyEarn
        amount={
          amount === INITIAL_AMOUNT || toDecimal(amount).eq(0)
            ? INITIAL_AMOUNT
            : newDailyEarn
        }
        subamount={saving.expected_interest_daily}
        currency={currency.currency}
        isLoading={isLoading}
      />
      <InterestAccrual
        tooltipText={formatMessage(
          'saving.interestsAreCalculatedEvery24HoursWithdraw'
        )}
        showDate={!toDecimal(remainingFunds).eq(0)}
      />
    </div>
  );
};
