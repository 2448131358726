import { useFormatMessage } from 'modules/messages';
import { FilterBoxTemplate } from './FilterBoxTemplate';
import styles from './NetworkFilter.module.scss';
import { useGetAvailableNetworks } from 'modules/assets-and-wallets/api';
import { NetworkBadge } from 'common/components/NetworkBadge';
import clsx from 'clsx';
import { toggleElement } from 'common/utils/toggle-element';

export const NetworkFilter: React.FC<{
  networks?: string[];
  onNetworksChange: (value?: string[]) => void;
}> = ({ networks, onNetworksChange }) => {
  const formatMessage = useFormatMessage();

  const { data } = useGetAvailableNetworks();

  return (
    <FilterBoxTemplate
      header={formatMessage('common.network')}
      buttons={
        <>
          {data?.data.map((network, i) => (
            <div
              className={clsx(
                styles.container,
                networks?.includes(network.network) && styles.selectedCurrency
              )}
              key={i}
              onClick={() =>
                onNetworksChange(
                  toggleElement(networks ?? [], network.network).sort()
                )
              }
            >
              <NetworkBadge network={network.network} textStyle='bold1417' />
            </div>
          ))}
        </>
      }
    />
  );
};
