import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { MarkAllAsRead } from './components/MarkAllAsRead';
import { NotificationsTable } from './components/NotificationsTable';
import { useState } from 'react';
import {
  NOTIFICATIONS_PREVIEW_QUERY_KEY,
  UNREAD_COUNT_NOTIFICATIONS_QUERY_KEY,
  useMarkAllAsReadNotificationMutation,
  useNotficationsInfinite,
} from './api';
import { useQueryClient } from '@tanstack/react-query';

export const NotificationsPage: React.FC<{}> = ({}) => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);

  const notificationsQuery = useNotficationsInfinite();

  const markAllAsReadMutation = useMarkAllAsReadNotificationMutation();

  return (
    <Section>
      <SectionHeader
        header={
          <div>
            <Text style='xxbold3644'>
              {formatMessage('common.notifications')}
            </Text>
          </div>
        }
        actions={
          <MarkAllAsRead
            onClick={() => {
              markAllAsReadMutation.mutate(undefined, {
                onSuccess: () => {
                  notificationsQuery.remove();
                  setPage(1);
                  queryClient.invalidateQueries(
                    NOTIFICATIONS_PREVIEW_QUERY_KEY
                  );
                  queryClient.invalidateQueries(
                    UNREAD_COUNT_NOTIFICATIONS_QUERY_KEY
                  );
                },
              });
            }}
          />
        }
      />
      <NotificationsTable
        page={page}
        setPage={setPage}
        notificationsQuery={notificationsQuery}
      />
    </Section>
  );
};
