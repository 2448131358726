import { LoaderBox } from 'common/components/loader';
import { HeaderCell, SortValue } from 'common/components/table-subcomponents';
import {
  SortConfig,
  PopupSorter,
} from 'common/components/table-subcomponents/sort/PopupSorter';
import {
  ascCmpAmounts,
  ascComp,
  descCmpAmounts,
  descComp,
} from 'common/utils/comparators';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { useFiatComparators } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import { NothingToDisplay } from 'modules/nothing-to-display';
import * as R from 'ramda';
import { useState } from 'react';
import { useGetWalletTotalValue } from '../hooks';
import { ViewMode, Wallet } from '../types';
import { ViewModeSwitch } from './ViewModeSwitch';
import { WalletRow } from './WalletRow';
import styles from './Wallets.module.scss';

type Props = {
  wallets: Wallet[];
  isWalletsLoading: boolean;
  viewMode: ViewMode;
  onViewModeChange: (mode: ViewMode) => void;
  dataTest?: string;
};

type SortModel = {
  wallet?: SortValue;
  network?: SortValue;
  value?: SortValue;
};

export const Wallets: React.FC<Props> = ({
  wallets,
  isWalletsLoading,
  viewMode,
  onViewModeChange,
}) => {
  const formatMessage = useFormatMessage();

  const [sort, setSort] = useState<SortModel>({});

  const { ascFiatCmp, descFiatCmp } = useFiatComparators();

  const getWalletTotalValue = useGetWalletTotalValue();

  const getSortedWallets = () => {
    if (sort.wallet) {
      return R.sort(
        (w1, w2) =>
          (sort.wallet === 'asc' ? ascComp : descComp)(w1.name, w2.name),
        wallets
      );
    }
    if (sort.network) {
      return R.sort(
        (w1, w2) =>
          (sort.network === 'asc' ? ascComp : descComp)(
            displayNetwork(w1.network),
            displayNetwork(w2.network)
          ),
        wallets
      );
    }
    if (sort.value) {
      const walletsWithSortedBalances = wallets.map((wallet) => ({
        ...wallet,
        balances: R.sort((b1, b2) => {
          return (sort.value === 'asc' ? ascFiatCmp : descFiatCmp)(b1, b2);
        }, wallet.balances),
      }));
      return R.sort(
        (w1, w2) =>
          (sort.value === 'asc' ? ascCmpAmounts : descCmpAmounts)(
            getWalletTotalValue(w1.id) || '0',
            getWalletTotalValue(w2.id) || '0'
          ),
        walletsWithSortedBalances
      );
    }
    return wallets;
  };

  const headers = [
    {
      sort: sort.wallet,
      onSort: (next: SortValue) => {
        setSort({ wallet: next });
      },
      text: formatMessage('common.wallet'),
      className: styles.headerCellWallet,
    },
    {
      sort: sort.network,
      onSort: (next: SortValue) => {
        setSort({ network: next });
      },
      text: formatMessage('common.network'),

      className: styles.headerCellNetwork,
    },
    {
      sort: sort.value,
      onSort: (next: SortValue) => {
        setSort({ value: next });
      },
      text: formatMessage('common.value'),

      className: styles.headerCellValue,
    },
  ];

  return (
    <>
      {!isWalletsLoading && !!wallets.length && (
        <>
          <div className={styles.headerRow}>
            {headers?.map((item: SortConfig, index) => (
              <HeaderCell
                sort={item.sort}
                onSort={item.onSort}
                key={index}
                className={item.className}
              >
                {item.text}
              </HeaderCell>
            ))}
            <PopupSorter
              className={styles.headerCellSorter}
              config={headers}
              isOnLeft={true}
            />
            <ViewModeSwitch
              className={styles.headerCellSwitch}
              viewMode={viewMode}
              onViewModeChange={onViewModeChange}
            />
          </div>
          {isWalletsLoading && <LoaderBox />}
          {getSortedWallets().map((wallet) => (
            <WalletRow key={wallet.id} wallet={wallet} dataTest='walletRow' />
          ))}
        </>
      )}
      {!isWalletsLoading && !wallets.length && <NothingToDisplay />}
      {isWalletsLoading && <LoaderBox />}
    </>
  );
};
