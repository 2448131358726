import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useContext } from 'react';

type Props = {};

export const StepSuccess: React.FC<Props> = ({}) => {
  const formatMessage = useFormatMessage();

  const { onClose } = useContext(ModalContext);

  return (
    <>
      <ModalHeader
        showClose={false}
        subtitle={
          <div style={{ marginBottom: '60px' }}>
            <Text style='light1425'>
              {formatMessage('wallets.creatingWalletSubtitle')}
            </Text>
          </div>
        }
      >
        {formatMessage('wallets.creatingWallet')}
      </ModalHeader>
      <ModalBody> </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            type='dark'
            onClick={onClose}
            name={formatMessage('common.button.done')}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
