import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { get, LONG_STALE_TIME, post } from 'modules/api';

export const MULTIAPPROVAL_URL = '/multi-approval';

export const POST_MULTIAPPROVAL_QUERY_KEY = [
  'POST',
  MULTIAPPROVAL_URL,
] as const;
export const GET_MULTIAPPROVAL_QUERY_KEY = ['GET', MULTIAPPROVAL_URL] as const;

export type Multiapproval = {
  threshold: number;
  signers_count: number;
};

export type GetMultiapprovalResponseData = Multiapproval;

export const getMultiapproval = () => {
  return get<GetMultiapprovalResponseData>(MULTIAPPROVAL_URL);
};

export const useMultiapprovalQuery = (
  options?: UseQueryOptions<
    AxiosResponse<GetMultiapprovalResponseData>,
    AxiosError<any>
  >
) => {
  const query = useQuery<
    AxiosResponse<GetMultiapprovalResponseData>,
    AxiosError<any>
  >(GET_MULTIAPPROVAL_QUERY_KEY, getMultiapproval, {
    staleTime: LONG_STALE_TIME,
    ...options,
  });
  return query;
};

export type PostMultiapprovalPayload = {
  threshold: number;
  signers: number[];
};

export type PostMultiapprovalRequest = {
  payload: PostMultiapprovalPayload;
  headers?: AxiosRequestHeaders;
};

export type PostMultiapprovalResponseError = {
  status?: string;
  detail?: string[];
  threshold?: string[];
  signers?: string[];
  non_field_errors?: string[];
};

export const postMultiapproval = (
  payload: PostMultiapprovalPayload,
  headers?: AxiosRequestHeaders
) =>
  post<void, PostMultiapprovalPayload>(MULTIAPPROVAL_URL, payload, { headers });

export const useMultiapprovalMutation = () => {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<PostMultiapprovalResponseError>,
    PostMultiapprovalRequest
  >(POST_MULTIAPPROVAL_QUERY_KEY, ({ payload, headers }) =>
    postMultiapproval(payload, headers)
  );
};

export const ERR_THRESHOLD_THE_SAME =
  'New threshold cannot be the same as old threshold.';
export const ERR_MEMBERS_NOT_FOUND = 'Some members were not found.';
