import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { useLendsQuery } from '../../api';

export const ActiveLendsCount: React.FC<{ className?: string }> = ({
  className,
}) => {
  const formatMessage = useFormatMessage();

  const { data: lends = [] } = useLendsQuery();

  const activeLends = lends.filter((lend) => lend.state === 'ACTIVE').length;

  return (
    <Text
      style='xxbold1619'
      inlineStyle={{ display: 'block' }}
      className={className}
    >
      {formatMessage('finopsCommon.activeCount', {
        count: activeLends,
      })}
    </Text>
  );
};
