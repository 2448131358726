import { FormatMessageFn } from 'modules/messages';
import { TransactionKind } from '../types';

export const getShortNameForTransactionType = (
  formatMessage: FormatMessageFn,
  kind: TransactionKind
) => {
  const kindNames: { readonly [key in TransactionKind]: string } = {
    DEPOSIT: formatMessage('deposit'),
    WITHDRAWAL: formatMessage('common.withdraw'),
    LOAN_LEND: formatMessage('lend.lend'),
    LOAN_LEND_TERMINATION: formatMessage('transactions.lendTermination'),
    LOAN_INTEREST: formatMessage('transactions.lendInterestPayout'),
    LOAN_PRINCIPAL_RETURN: formatMessage('transactions.lendPrincipalReturn'),
    LOAN_BORROW: formatMessage('borrow.borrow'),
    LOAN_BORROW_REPAY: formatMessage('borrow.repayment'),
    LOAN_BORROW_LIQUIDATION: formatMessage('borrow.borrowLiquidation'),
    LOAN_BORROW_CANCELLATION: formatMessage(
      'transactions.cancellationTransfer'
    ),
    LOAN_COLLATERAL_TOPUP_AUTO: formatMessage(
      'transactions.marginCallTransfer'
    ),
    LOAN_COLLATERAL_TOPUP: formatMessage('transactions.collateralTopup'),
    LOAN_COLLATERAL_EXCESS_RETURN: formatMessage(
      'borrow.borrowOvercollaterizationTransfer'
    ),
    LOAN_COLLATERAL_FULL_RETURN: formatMessage('transactions.collateralReturn'),
    LOAN_PRINCIPAL_PAYOUT: formatMessage('transactions.borrowPrincipalPayout'),
    SAVING: formatMessage('saving.saving'),
    SAVING_TOPUP: formatMessage('saving.savingTopup'),
    SAVING_WITHDRAWAL: formatMessage('transactions.savingWithdraw'),
    TRADE_OUT: formatMessage('transactions.tradeOut'),
    TRADE_IN: formatMessage('transactions.tradeIn'),
    TRADE_EXCESS_RETURN: formatMessage('transactions.tradeExcessReturn'),
    SAVING_INTEREST: formatMessage('transactions.savingInterestPayout'),
    CHARITY_DONATION: formatMessage('transactions.charityDonation'),
    CHARITY_WITHDRAWAL_IN: formatMessage(
      'transactions.charityWithdrawIncoming'
    ),
    CHARITY_WITHDRAWAL_OUT: formatMessage('transactions.charityWithdraw'),
  };

  return kindNames[kind];
};
