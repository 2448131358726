import clsx from 'clsx';
import styles from './Summary.module.scss';
import { Text, TextStyle } from './Text';

type Props = {
  children: React.ReactNode;
  dataTest?: string;
  className?: string;
};

export const Summary: React.FC<Props> = ({ children, dataTest, className }) => {
  return (
    <div className={clsx(styles.container, className)} data-test={dataTest}>
      {children}
    </div>
  );
};

export const SummaryLabel: React.FC<{
  children: React.ReactNode;
  className?: string;
  textStyle?: TextStyle;
}> = ({ children, className, textStyle = 'light1215' }) => {
  return (
    <Text style={textStyle} className={clsx(styles.summaryLabel, className)}>
      {children}
    </Text>
  );
};

export const SummaryValue: React.FC<{
  children: React.ReactNode;
  className?: string;
  textStyle?: TextStyle;
  dataTest?: string;
}> = ({ children, className, textStyle = 'light1215', dataTest }) => {
  return (
    <Text
      style={textStyle}
      className={clsx(styles.summaryValue, className)}
      dataTest={dataTest}
    >
      {children}
    </Text>
  );
};
