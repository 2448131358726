import { Text } from 'common/components/Text';
import { formatDatetime, formatDatetimeWithoutSec } from 'common/utils/utils';

type Props = { date: string; withoutSec?: boolean };

export const Date: React.FC<Props> = ({ date, withoutSec = false }) => {
  return (
    <Text
      style='light1215'
      colorStyle='secondary'
      inlineStyle={{ display: 'block' }}
    >
      {withoutSec ? formatDatetimeWithoutSec(date) : formatDatetime(date)}
    </Text>
  );
};
