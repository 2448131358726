import { LoaderBox } from 'common/components/loader';
import { useFiatCurrency } from 'modules/fiat';
import {
  OverviewColumn,
  OverviewGrid,
} from 'modules/financial-ops/common/components/overview-components';
import { useSavingsSummaryQuery } from '../api';
import { FundsLocked, InterestsBoxes } from './components';

export const SavingOverview: React.FC<{}> = () => {
  const { isLoading: isLendsSummaryLoading } = useSavingsSummaryQuery();
  const { isLoading: isFiatCurrencyLoading } = useFiatCurrency();

  if (isLendsSummaryLoading || isFiatCurrencyLoading) {
    return <LoaderBox />;
  }

  return (
    <OverviewGrid>
      <OverviewColumn>
        <FundsLocked />
      </OverviewColumn>
      <OverviewColumn>
        <InterestsBoxes />
      </OverviewColumn>
    </OverviewGrid>
  );
};
