import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';

type Props = {
  leftDays: number;
  activeDays: number;
  isFinished: boolean;
  endGoalEnabled: boolean;
};

export const Days: React.FC<Props> = ({
  leftDays,
  activeDays,
  isFinished,
  endGoalEnabled,
}) => {
  const formatMessage = useFormatMessage();

  const getTextAndDays = () => {
    if (endGoalEnabled && !isFinished && leftDays !== undefined) {
      return {
        text: formatMessage('charityForms.untilTheEnd'),
        days: leftDays,
      };
    }

    if (!isFinished && !endGoalEnabled && activeDays !== undefined) {
      return {
        text: formatMessage('charityForms.activeSince'),
        days: activeDays,
      };
    }

    if (isFinished && activeDays !== undefined) {
      return {
        text: formatMessage('charityForms.duration') + ':',
        days: activeDays,
      };
    }

    return {
      text: formatMessage('charityForms.duration') + ':',
      days: activeDays,
    };
  };

  const { text, days } = getTextAndDays();

  return (
    <div>
      <Text
        style='light1215'
        colorStyle='secondary'
        inlineStyle={{ display: 'block', marginBottom: '5px' }}
      >
        {text}
      </Text>
      <Text
        style='xxbold1619'
        colorStyle='primary'
        inlineStyle={{ display: 'block' }}
      >
        {formatMessage('common.days', { days })}
      </Text>
    </div>
  );
};
