import { FilterConfig } from 'common/components/table-subcomponents/filter/PopupFilter';
import { descCmpAmounts, ascCmpAmounts } from 'common/utils/comparators';
import { useFormatMessage } from 'modules/messages';
import { useEffect, useState } from 'react';
import { useInterestRates } from './api';
import { InterestRateKind } from './types';
import { useRedirectToFeatureUnavailable } from 'modules/feature-flags';
import { useSavingsLimitsQuery } from '../savings-account/api';
import { isSavingPossibleForCurrency } from '../savings-account/helpers';

type FilterModel = {
  active?: boolean;
  finished?: boolean;
  all?: boolean;
};

export const useFilters = () => {
  const formatMessage = useFormatMessage();

  const [filter, setFilter] = useState<FilterModel>({ all: true });

  const filterConfig: FilterConfig[] = [
    {
      filter: filter.all,
      onFilter: () => {
        setFilter({ all: true });
      },
      text: formatMessage('finopsCommon.showAll'),
    },
    {
      filter: filter.active,
      onFilter: () => {
        setFilter({ active: true });
      },
      text: formatMessage('finopsCommon.showActive'),
    },
    {
      filter: filter.finished,
      onFilter: () => {
        setFilter({ finished: true });
      },
      text: formatMessage('finopsCommon.showFinished'),
    },
  ];

  return [filter, filterConfig] as const;
};

type SortDirection = 'asc' | 'desc';

export const useSortedInterestRates = ({
  sortDirection,
  kind,
}: {
  sortDirection: SortDirection;
  kind?: InterestRateKind;
}) =>
  useInterestRates(kind!, {
    enabled: !!kind,
    select: (data) =>
      data.sort((item1, item2) =>
        (sortDirection === 'desc' ? descCmpAmounts : ascCmpAmounts)(
          item1.rate_pct,
          item2.rate_pct
        )
      ),
  });

export const useInterestRatesExistForKind = (
  kind: InterestRateKind,
  options?: { redirect?: boolean }
) => {
  const redirectToFeatureUnavailable = useRedirectToFeatureUnavailable();

  const isSaving = kind === 'SAVING';

  const { data: savingLimits, isLoading: isLoadingSavingLimits } =
    useSavingsLimitsQuery({
      enabled: isSaving,
    });

  const query = useInterestRates(kind);

  const interestRatesExist = isSaving
    ? savingLimits?.data &&
      !!query.data?.filter(
        (val) =>
          isSaving &&
          isSavingPossibleForCurrency(val.currency, savingLimits?.data)
      ).length
    : !!query.data?.length;

  useEffect(() => {
    if (
      ((isSaving && savingLimits?.data) || !isSaving) &&
      query.data &&
      !interestRatesExist &&
      options?.redirect
    ) {
      redirectToFeatureUnavailable();
    }
  }, [
    savingLimits?.data,
    query.data,
    kind,
    interestRatesExist,
    options?.redirect,
  ]);

  return {
    ...query,
    isLoading: (isSaving && isLoadingSavingLimits) || query.isLoading,
    data: interestRatesExist,
  };
};
