import { PageLoader } from 'common/components/loader';
import { useParams } from 'react-router-dom';
import { CharityFormPublic } from './CharityFormPublic';
import { useCharityFormPublic, useDonationsPublic } from './api';
import { InappLayout, OrganizerLogo } from './components';

export const CharityFormPublicInapp: React.FC<{}> = () => {
  const { token } = useParams();

  const { data: charityForm, isLoading: isLoadingCharityForm } =
    useCharityFormPublic(token);

  const { data: donations, isLoading: isLoadingDonations } =
    useDonationsPublic(token);

  if (isLoadingCharityForm || isLoadingDonations) {
    return <PageLoader />;
  }

  if (!charityForm || !donations) {
    return null;
  }

  const darkMode = charityForm.dark_mode;
  const logo = charityForm.pictures.find((picture) => picture.kind === 'LOGO');

  return (
    <InappLayout
      darkMode={darkMode}
      logo={<OrganizerLogo url={logo?.file} />}
      form={<CharityFormPublic donations={donations} form={charityForm} />}
    />
  );
};
