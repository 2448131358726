import clsx from 'clsx';
import { LoaderBox } from 'common/components/loader';
import { HeaderCell, SortValue } from 'common/components/table-subcomponents';
import {
  SortConfig,
  PopupSorter,
} from 'common/components/table-subcomponents/sort/PopupSorter';
import { DASHBOARD_ENTRIES_NUMBER } from 'common/consts/consts';
import { ascComp, descComp } from 'common/utils/comparators';
import { useFiatComparators } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import * as R from 'ramda';
import { useState } from 'react';
import { ViewMode, Wallet } from '../types';
import { AssetRow } from './AssetRow';
import styles from './Assets.module.scss';
import { ViewModeSwitch } from './ViewModeSwitch';
import { NothingToDisplay } from 'modules/nothing-to-display';

type Props = {
  wallets: Wallet[];
  isWalletsLoading: boolean;
  viewMode?: ViewMode;
  onViewModeChange?: (mode: ViewMode) => void;
  dataTest?: string;
  enableSort?: boolean;
  shortTable?: boolean;
};

type SortModel = {
  asset?: SortValue;
  wallet?: SortValue;
  value?: SortValue;
};

export const Assets: React.FC<Props> = ({
  wallets,
  isWalletsLoading,
  viewMode,
  onViewModeChange,
  enableSort = true,
  shortTable,
}) => {
  const formatMessage = useFormatMessage();

  const { ascFiatCmp, descFiatCmp } = useFiatComparators();

  const assets = wallets.flatMap((wallet) =>
    wallet.balances.map((balance) => ({
      balance,
      wallet,
    }))
  );

  const [sort, setSort] = useState<SortModel>(
    shortTable ? { value: 'desc' } : {}
  );

  const getSortedAssets = () => {
    if (sort.asset) {
      return R.sort(
        (asset1, asset2) =>
          (sort.asset === 'asc' ? ascComp : descComp)(
            asset1.balance.currency,
            asset2.balance.currency
          ),
        assets
      );
    }
    if (sort.wallet) {
      return R.sort(
        (asset1, asset2) =>
          (sort.wallet === 'asc' ? ascComp : descComp)(
            asset1.wallet.name,
            asset2.wallet.name
          ),
        assets
      );
    }
    if (sort.value) {
      return R.sort(
        (asset1, asset2) =>
          (sort.value === 'asc' ? ascFiatCmp : descFiatCmp)(
            asset1.balance,
            asset2.balance
          ),
        assets
      );
    }
    return assets;
  };

  const headers: SortConfig[] = [
    {
      sort: sort.asset,
      onSort: (next: SortValue) => {
        setSort({ asset: next });
      },
      text: formatMessage('common.asset'),
      className: styles.headerCellAsset,
    },
    {
      sort: sort.wallet,
      onSort: (next: SortValue) => {
        setSort({ wallet: next });
      },
      text: formatMessage('common.wallet'),

      className: styles.headerCellWallet,
    },
    {
      sort: sort.value,
      onSort: (next: SortValue) => {
        setSort({ value: next });
      },
      text: formatMessage('common.value'),
      className: styles.headerCellAmount,
    },
  ];

  const assetsList = shortTable
    ? getSortedAssets().slice(0, DASHBOARD_ENTRIES_NUMBER)
    : getSortedAssets();

  return (
    <>
      <div className={clsx(styles.headerRow)}>
        {headers?.map((item: SortConfig, index) => (
          <HeaderCell
            sort={enableSort ? item.sort : undefined}
            onSort={enableSort ? item.onSort : undefined}
            key={index}
            className={item.className}
          >
            {item.text}
          </HeaderCell>
        ))}
        {enableSort && (
          <PopupSorter
            className={styles.headerCellSorter}
            config={headers}
            isOnLeft={true}
          />
        )}
        {viewMode && onViewModeChange && (
          <ViewModeSwitch
            className={styles.headerCellSwitch}
            viewMode={viewMode}
            onViewModeChange={onViewModeChange}
          />
        )}
      </div>
      {isWalletsLoading && <LoaderBox />}
      {!isWalletsLoading && !wallets.length && <NothingToDisplay />}
      {assetsList.map((asset) => (
        <AssetRow
          asset={asset}
          key={`${asset.wallet.id}-${asset.balance.currency}`}
          dataTest='assetRow'
        />
      ))}
    </>
  );
};
