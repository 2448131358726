import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useKyxContext } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import {
  actions,
  buildRecaptchaHeader,
  useHandleRecaptchaError,
  useRecaptcha,
} from 'modules/recaptcha';
import { USER_QUERY_KEY } from 'modules/user';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  isCompanySuspendedError,
  LoginPayload,
  LoginResponseData,
  useLoginMutation,
} from './api';
import { CompanySuspended } from './components/CompanySuspended';
import { StepLogin } from './components/StepLogin';
import { useLoginLocationState } from './hooks';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
  code2FARequired,
  setup2FARequired,
  Setup2FA,
} from 'modules/2FA';

type Step = 'login' | '2FAsetup' | '2FAcode' | '2FAcancel';

export const Login = () => {
  const formatMessage = useFormatMessage();

  const navigate = useNavigate();
  const locationState = useLoginLocationState();

  useEffect(() => {
    // when isCompanySuspended state is cleared (for example when Login button in UnauthTopbar is clicked),
    // reset mutation so that normal login flow is displayed instaed of error)
    if (!locationState.isCompanySuspended) {
      loginMutation.reset();
    }
  }, [locationState.isCompanySuspended]);

  const queryClient = useQueryClient();

  const loginMutation = useLoginMutation();
  const { isLoading, error } = loginMutation;

  const { showFirstLoginModal } = useKyxContext();

  const { executeRecaptcha } = useRecaptcha();
  const { handleRecaptchaError, handleRecaptchaNotLoaded } =
    useHandleRecaptchaError();

  const [step, setStep] = useState<Step>('login');
  const [secretKey, setSecretKey] = useState('');
  const [uri, setUri] = useState('');
  const [error2FA, setError2FA] = useState<string>();

  const [data, setData] = useState<LoginPayload>({ email: '', password: '' });

  const onLoginSuccess = async (response: AxiosResponse<LoginResponseData>) => {
    queryClient.invalidateQueries(USER_QUERY_KEY);

    if (locationState.redirectTo) {
      navigate(locationState.redirectTo);
      return;
    }

    if (response.data.company_role === 'OWNER' && response.data.first_login) {
      showFirstLoginModal();
    }
    navigate('/dashboard', { replace: true });
  };

  const check2FAKey = async (
    response: AxiosResponse<LoginResponseData>,
    payload: LoginPayload
  ) => {
    if (setup2FARequired(response.data?.status)) {
      setSecretKey(response.data.secret_key);
      setUri(response.data.uri);
      setStep('2FAsetup');
    } else if (code2FARequired(response.data?.status)) {
      setStep('2FAcode');
    }
  };

  const onLogin = useCallback(
    async (email: string, password: string) => {
      if (!executeRecaptcha) {
        handleRecaptchaNotLoaded();
        return;
      }

      const token = await executeRecaptcha(actions.loginStep1);

      const payload = { email, password };
      setData(payload);

      loginMutation.mutate(
        { payload, headers: buildRecaptchaHeader(token) },
        {
          onSuccess: (response) => {
            check2FAKey(response, payload);
          },
          onError: (err) => {
            if (isCompanySuspendedError(err)) {
              navigate('/login', {
                state: { ...locationState, isCompanySuspended: true },
              }); // same URL, additional state - this makes UnauthTopbar to show different buttons
            }
            handleRecaptchaError(err);
          },
        }
      );
    },
    [executeRecaptcha]
  );

  const onVerify = useCallback(
    async (otp: string) => {
      if (!executeRecaptcha) {
        handleRecaptchaNotLoaded();
        return;
      }

      const token = await executeRecaptcha(actions.loginStep2);

      loginMutation.mutate(
        {
          payload: data,
          headers: {
            ...generateHeader2FA(otp),
            ...buildRecaptchaHeader(token),
          },
        },
        {
          onSuccess: (response) => {
            onLoginSuccess(response);
          },
          onError: (err) => {
            const wasRecaptchaError = handleRecaptchaError(err);
            if (wasRecaptchaError) {
              return;
            }
            setErrorOTP(err, setError2FA, formatMessage);
          },
        }
      );
    },
    [executeRecaptcha, data]
  );

  const companySuspended = isCompanySuspendedError(error);
  if (companySuspended) {
    const { isCompanyOwner } = companySuspended;
    return <CompanySuspended isCompanyOwner={isCompanyOwner} />;
  }

  return (
    <>
      {step === 'login' && (
        <StepLogin onLogin={onLogin} error={error} isLoading={isLoading} />
      )}
      {step === '2FAsetup' && (
        <Setup2FA
          onVerify={onVerify}
          secretKey={secretKey}
          uri={uri}
          onError={(err) => setError2FA(err)}
          error={error2FA}
          isLoading={isLoading}
          onGoBack={() => setStep('login')}
        />
      )}
      {step === '2FAcode' && (
        <Module2FA
          title={formatMessage('login')}
          onVerify={onVerify}
          onCancel={() => setStep('2FAcancel')}
          onError={(err) => setError2FA(err)}
          error={error2FA}
          isLoading={isLoading}
        />
      )}
      {step === '2FAcancel' && <Cancel2FA onClose={() => setStep('login')} />}
    </>
  );
};
