import { HslType, HsvType, RgbType } from './types';

export const RGB_MAX_VALUE = 255;
export const HEX_LENGTH = 7;
export const NATURAL_NUMBER_REGEX = /^\d+$/;

export const rgbToHex = ({ r, g, b }: RgbType) => {
  if (r === undefined || g === undefined || b === undefined) {
    return;
  }

  const hr = Math.max(0, Math.min(255, Math.round(+r))).toString(16);
  const hg = Math.max(0, Math.min(255, Math.round(+g))).toString(16);
  const hb = Math.max(0, Math.min(255, Math.round(+b))).toString(16);

  return (
    '#' +
    (hr.length < 2 ? '0' : '') +
    hr +
    (hg.length < 2 ? '0' : '') +
    hg +
    (hb.length < 2 ? '0' : '') +
    hb
  );
};

export const hsv2rgb = ({ h, s, v }: HsvType): RgbType => {
  const f = (n: number, k = (n + h / 60) % 6) =>
    v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return {
    r: Math.floor(f(5) * 255),
    g: Math.floor(f(3) * 255),
    b: Math.floor(f(1) * 255),
  };
};

export const hsl2rgb = ({ h, s, l }: HslType): RgbType => {
  const a = s * Math.min(l, 1 - l);
  const f = (n: number, k = (n + h / 30) % 12) =>
    l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  return {
    r: Math.round(255 * f(0)),
    g: Math.round(255 * f(8)),
    b: Math.round(255 * f(4)),
  };
};

const normalizeHex = (hex: string) =>
  hex.length > 3 ? hex : hex.replaceAll(/(.{1})/g, '$1$1');

export const hexToRgb = (value: string): RgbType | undefined => {
  let hex =
    value.length === 4
      ? `#${normalizeHex(value.slice(1, value.length))}`
      : value;

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return;

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

export const isValidHex = (hex: string) =>
  /^#(([0-9A-Fa-f]{2}){3,4}|[0-9A-Fa-f]{3})$/.test(hex);
