import { HelpCenterSSO as HelpCenterSSOFeature } from 'modules/help-center-sso';
import { UnauthLayout } from 'modules/layout';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const HelpCenterSSO: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.sso')}</title>
      </Helmet>
      <UnauthLayout>
        <HelpCenterSSOFeature />
      </UnauthLayout>
    </>
  );
};
