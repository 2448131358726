import clsx from 'clsx';
import { textStyles } from 'common/components/Text';
import styles from './Initials.module.scss';

const getInitials = (name: string) => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

type NameProps = { name: string; surname: string };

type InitialsProps = NameProps & { className?: string };

export const Initials: React.FC<InitialsProps> = ({
  name,
  surname,
  className,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      {getInitials(`${name} ${surname}`)}
    </div>
  );
};

type InitialsButtonProps = NameProps & { onClick: () => void };

export const InitialsButton: React.FC<InitialsButtonProps> = ({
  name,
  surname,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(textStyles.light1421, styles.initialsButton)}
      data-test='initialsButtons'
    >
      {getInitials(`${name} ${surname}`)}
    </div>
  );
};
