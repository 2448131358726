import clsx from 'clsx';
import { Text } from 'common/components/Text';
import { DECIMALS_MAP } from 'common/utils/currency-enum-utils';
import debounce from 'lodash/debounce';
import { useFeatureFlag } from 'modules/feature-flags';
import { Separator } from 'modules/financial-ops/common/components/Separator';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { KyxAlert, useIsKyxCompleted } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { CurrencyWallet } from 'modules/select-currency-wallet/types';
import { useCallback, useEffect } from 'react';
import {
  TradesCalculationBase,
  TradesEffectPayload,
  TradesEffectResponseData,
  TradesEffectResponseError,
  useTradeEffectMutation,
} from '../api';
import { PriceToleranceBox } from './PriceToleranceBox';
import styles from './TradeEffectCalculator.module.scss';
import { InstrumentCurrency, TradeOptions } from './types';
import { TradeDisabledAlert } from './TradeDisabledAlert';
import { useAreNoWalletsCreated } from 'modules/assets-and-wallets';
import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { GoToSummaryButton } from 'common/components/button';

type Props = {
  calculationBase: TradesCalculationBase;
  spendCurrency?: InstrumentCurrency;
  receiveCurrency?: InstrumentCurrency;
  receiveAmount?: string;
  spendAmount?: string;
  option: TradeOptions;
  spendWallet: CurrencyWallet | undefined;
  isBuy: boolean;
  limitPrice: string;
  orderPrice: string;
  pairId?: number;
  tolerance: string;
  maxCount: number; // UGLY HACK WARNING!!!
  onToleranceChange: (value: string) => void;
  onContinueToSummary: () => void;
  onEffectCalculated: (
    effect: TradesEffectResponseData,
    payload: TradesEffectPayload
  ) => void;
  onEffectError: (
    error: TradesEffectResponseError,
    payload: TradesEffectPayload
  ) => void;
  isLoading?: boolean;
};

export const TradeEffectCalculator: React.FC<Props> = ({
  calculationBase,
  spendCurrency,
  receiveCurrency,
  spendAmount,
  receiveAmount,
  option,
  isBuy,
  spendWallet,
  pairId,
  orderPrice,
  limitPrice,
  tolerance,
  maxCount, // UGLY HACK WARNING!!!
  onToleranceChange,
  onContinueToSummary,
  onEffectCalculated,
  onEffectError,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  const isKyxCompleted = useIsKyxCompleted();

  const tradeEffectMutation = useTradeEffectMutation();
  const newTradeFeatureFlag = useFeatureFlag('CREATING_TRADE');
  const { data: walletsFailed } = useAreNoWalletsCreated();

  const featureUnAvailable =
    walletsFailed || !newTradeFeatureFlag.data?.enabled;

  useEffect(() => {
    tradeEffectMutation.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spendCurrency, receiveCurrency, spendWallet, option]);

  const calculateEffect = useCallback(
    debounce((payload: TradesEffectPayload) => {
      tradeEffectMutation.mutate(payload, {
        onSuccess: (response) => {
          onEffectCalculated(response.data, payload);
        },
        onError: (err) => {
          if (err.response) {
            onEffectError(err.response?.data, payload);
          }
        },
      });
    }, 200),
    [spendCurrency, receiveCurrency, isBuy, tolerance]
  );

  useEffect(() => {
    if (!spendCurrency || !receiveCurrency) {
      return;
    }

    if (pairId) {
      const commonPayload: TradesEffectPayload = {
        exchange_instrument_id: pairId,
        side: option,
        calculation_base: calculationBase,
        price_tolerance_pct: tolerance,
        summary: false,
      };
      if (
        (isBuy &&
          calculationBase === 'CURRENCY_1' &&
          toDecimal(receiveAmount).gt(0)) ||
        (!isBuy &&
          calculationBase === 'CURRENCY_2' &&
          toDecimal(receiveAmount).gt(0))
      ) {
        calculateEffect({
          ...commonPayload,
          amount: toFixed(receiveAmount),
        });
        return;
      }

      if (
        (isBuy &&
          calculationBase === 'CURRENCY_2' &&
          toDecimal(spendAmount).gt(0)) ||
        (!isBuy &&
          calculationBase === 'CURRENCY_1' &&
          toDecimal(spendAmount).gt(0))
      ) {
        calculateEffect({
          ...commonPayload,
          amount: toFixed(spendAmount),
        });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    spendCurrency?.currency,
    (isBuy && calculationBase === 'CURRENCY_2') ||
    (!isBuy && calculationBase === 'CURRENCY_1')
      ? spendAmount
      : undefined,
    (isBuy && calculationBase === 'CURRENCY_1') ||
    (!isBuy && calculationBase === 'CURRENCY_2')
      ? receiveAmount
      : undefined,
    receiveCurrency?.currency,
    spendWallet?.id,
    calculationBase,
    maxCount, // UGLY HACK WARNING!!! this dependency is used to trigger request when no actual parameters change
    option,
    tolerance,
  ]);

  const getHeader = () => {
    if (spendCurrency && receiveCurrency) {
      return isBuy
        ? formatMessage('trade.buyFor', {
            currency2: spendCurrency?.currency,
            currency1: receiveCurrency?.currency,
          })
        : formatMessage('trade.sellFor', {
            currency2: spendCurrency?.currency,
            currency1: receiveCurrency?.currency,
          });
    } else {
      return isBuy
        ? formatMessage('trade.buyCurrency')
        : formatMessage('trade.sellCurrency');
    }
  };

  const invertedPrice = !!orderPrice ? toDecimal(1).div(orderPrice) : 0;

  return (
    <>
      <Text
        style='xxbold1619'
        inlineStyle={{ display: 'block', marginBottom: '30px' }}
      >
        {getHeader()}
      </Text>
      <div className={styles.spaceBetween}>
        <Text style='light1425'>{formatMessage('trade.price')}</Text>
        <Text style='light1425' className={styles.textRight}>
          {spendCurrency && receiveCurrency && spendAmount && receiveAmount
            ? formatMessage('trade.priceValues', {
                currency1: isBuy
                  ? receiveCurrency?.currency
                  : spendCurrency?.currency,
                currency2: isBuy
                  ? spendCurrency?.currency
                  : receiveCurrency?.currency,
                currencyValue: toLimitedPrec(toFixed(orderPrice ?? 0)),
              })
            : '-'}
        </Text>
      </div>
      <div className={styles.spaceBetween}>
        <Text style='light1425'>{formatMessage('trade.invertedPrice')}</Text>
        <Text style='light1425' className={styles.textRight}>
          {spendCurrency && receiveCurrency && spendAmount && receiveAmount
            ? formatMessage('trade.priceValues', {
                currency1: isBuy
                  ? spendCurrency?.currency
                  : receiveCurrency?.currency,
                currency2: isBuy
                  ? receiveCurrency?.currency
                  : spendCurrency?.currency,
                currencyValue: toLimitedPrec(
                  toFixed(
                    invertedPrice,
                    DECIMALS_MAP[
                      isBuy
                        ? receiveCurrency?.currency
                        : spendCurrency?.currency
                    ]
                  )
                ),
              })
            : '-'}
        </Text>
      </div>
      <Separator />
      <div className={clsx(styles.spaceBetween, styles.priceChangeTolerance)}>
        <LabelWithInfoTooltip
          text={formatMessage('trade.priceChangeTolerance')}
          tooltipText={formatMessage('trade.acceptablePriceVariationValue')}
        />
        <Text style='light1425'>
          {!spendCurrency || !receiveCurrency ? '-' : `${tolerance}%`}
        </Text>
      </div>
      {spendCurrency && receiveCurrency && (
        <PriceToleranceBox
          onChange={(value: string) => onToleranceChange(value)}
          tolerance={tolerance}
        />
      )}
      <div className={clsx(styles.spaceBetween, styles.lastItem)}>
        <Text style='light1425'>
          {isBuy
            ? formatMessage('trade.maximalPrice')
            : formatMessage('trade.minimalPrice')}
        </Text>
        <Text style='light1425' className={styles.textRight}>
          {spendCurrency && receiveCurrency && spendAmount && receiveAmount
            ? formatMessage('trade.priceValues', {
                currency1: isBuy
                  ? receiveCurrency?.currency
                  : spendCurrency?.currency,
                currency2: isBuy
                  ? spendCurrency?.currency
                  : receiveCurrency?.currency,
                currencyValue: toLimitedPrec(toFixed(limitPrice ?? 0)),
              })
            : '-'}
        </Text>
      </div>
      <KyxAlert className={styles.warningAlert} />
      <TradeDisabledAlert className={styles.warningAlert} />
      <GoToSummaryButton
        className={styles.summaryButton}
        disabled={
          featureUnAvailable ||
          !isKyxCompleted ||
          isLoading ||
          tradeEffectMutation.isLoading
        }
        onClick={onContinueToSummary}
      />
    </>
  );
};
