import { ModalLike } from 'common/components/modal';
import { UnauthLayout } from 'modules/layout';
import { useFormatMessage } from 'modules/messages';
import { ResetPassword as ResetPasswordFeature } from 'modules/reset-password';
import { Helmet } from 'react-helmet-async';

export const ResetPassword: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.resetPassword')}</title>
      </Helmet>
      <UnauthLayout>
        <ModalLike>
          <ResetPasswordFeature />
        </ModalLike>
      </UnauthLayout>
    </>
  );
};
