import { useCallback } from 'react';
import {
  SavingEffectResponseError,
  SavingEffectPayload,
  SavingEffectResponseData,
  interestRateDoesNotExist,
  useSavingsEffectMutation,
  amountTooBigError,
  globalLimitReached,
  maximumSavingsReached,
  amountTooSmallError,
} from '../api';
import debounce from 'lodash/debounce';
import { useNewSavingContext } from './context';
import { CurrencyEnum } from 'common/types';
import { useFormatMessage } from 'modules/messages';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';
import { SavingCalculationBase } from '../types';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';

type InputData = {
  amount?: string;
  calculationBase: SavingCalculationBase;
  currency?: CurrencyEnum;
  wallet?: number;
};

export const useCalculateEffect = () => {
  const formatMessage = useFormatMessage();

  const mutation = useSavingsEffectMutation();

  const context = useNewSavingContext();

  const onEffectCalculated = useCallback(
    (effect: SavingEffectResponseData, payload: SavingEffectPayload) => {
      context.setAssetAndAmountError('');
      if (payload.calculation_base === 'AMOUNT') {
        return;
      }
      context.setAmount(toLimitedPrec(toFixed(effect.amount)));
      if (effect.limit_exceeded) {
        context.setAmountInfo(
          formatMessage('common.reducedToMaximalAllowedAmount')
        );
        return;
      }
      context.setAmountInfo('');
    },
    []
  );

  const onEffectError = useCallback(
    (error: SavingEffectResponseError, payload: SavingEffectPayload) => {
      const tooSmallMatch = amountTooSmallError(error);
      if (tooSmallMatch) {
        context.setAssetAndAmountError(
          formatMessage('saving.minimumAmountForSaving', {
            amount: toFixed(tooSmallMatch[1]),
            currency: payload.currency,
          })
        );
        if (payload.calculation_base === 'MAX') {
          context.setAmount(INITIAL_AMOUNT);
        }
      }
      const tooBigMatch = amountTooBigError(error);
      if (tooBigMatch) {
        context.setAssetAndAmountError(
          formatMessage('saving.maximumAmountForSaving', {
            amount: toFixed(tooBigMatch[1]),
            currency: payload.currency,
          })
        );
      }

      if (interestRateDoesNotExist(error)) {
        context.setAssetAndAmountError(
          formatMessage('saving.interestRateDoesNotExist')
        );
      }

      if (globalLimitReached(error)) {
        context.setAssetAndAmountError(
          formatMessage('saving.maximumGlobalLimitReached')
        );
      }

      const maximumSavingsReachedMatch = maximumSavingsReached(error);
      if (maximumSavingsReachedMatch) {
        context.setAssetAndAmountError(
          formatMessage('saving.maximumNumberOfSavingsReached', {
            count: maximumSavingsReachedMatch[1],
          })
        );
      }
    },
    []
  );

  const mutateDebounced = useCallback(
    debounce((payload: SavingEffectPayload) => {
      mutation.mutate(payload, {
        onSuccess: (response) => {
          onEffectCalculated(response.data, payload);
        },
        onError: (err) => {
          if (err.response) {
            onEffectError(err.response?.data, payload);
          }
        },
      });
    }, 200),
    []
  );

  // take input from last user interaction, merge it with previous input stored in context, and call /effect endpoint
  const calculateEffect = (inputData: InputData) => {
    const data: InputData = {
      amount: context.amount,
      currency: context.currency?.currency,
      wallet: context.wallet?.id,
      ...inputData,
    };

    if (!data.currency || !data.wallet) {
      return;
    }
    const commonPayload: SavingEffectPayload = {
      calculation_base: data.calculationBase,
      currency: data.currency,
      wallet_id: data.wallet,
      summary: false,
    };
    if (commonPayload.calculation_base === 'MAX') {
      mutateDebounced(commonPayload);
    }
    if (
      commonPayload.calculation_base === 'AMOUNT' &&
      toDecimal(data.amount).gt(0)
    ) {
      mutateDebounced({
        ...commonPayload,
        amount: toFixed(data.amount),
      });
    }
  };

  return { calculateEffect, mutation };
};
