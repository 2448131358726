import clsx from 'clsx';
import { ReactComponent as WalletIcon } from 'common/assets/grey-icons/wallet-small-grey.svg';
import { NetworkBox } from 'common/components/NetworkBox';
import { Text, textColorStyles } from 'common/components/Text';
import { NUMBER_OF_LETTERS_WALLET_NAME } from 'common/consts/consts';
import { formatDatetime } from 'common/utils/utils';
import D from 'decimal.js';
import { CryptoToFiatAmount } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import { LendRead } from '../../types';
import styles from './LendBox.module.scss';
import { OpenedDate } from 'modules/common-data-fields';
import { DetailsButton } from 'modules/financial-ops/common/components/DetailsButton';
import { LendName, ProgressCircle } from '../../common';

type Props = { lend: LendRead };

export const LendBox: React.FC<Props> = ({ lend }) => {
  const formatMessage = useFormatMessage();

  const {
    id,
    principal_wallet: { network, name },
    principal_currency,
    principal_currency_rate,
    principal_amount,
    interest_total,
    interest_earned,
    term,
    rate_pct,
    state,
    start_at,
    end_at,
  } = lend;

  const earnedSoFar = new D(interest_earned).div(interest_total).mul(100);

  const walletName =
    name.length <= NUMBER_OF_LETTERS_WALLET_NAME
      ? name
      : `${name.slice(0, NUMBER_OF_LETTERS_WALLET_NAME)}...`;

  const isFinished = state === 'COMPLETED';

  return (
    <div className={styles.container} data-test='lendBox'>
      <div className={styles.header}>
        <LendName
          principalCurrency={principal_currency}
          term={term}
          state={state}
        />
        <DetailsButton path={`/lends/${id}`} />
      </div>
      <div className={styles.details}>
        <div>
          <div className={styles.bigMargin}>
            <Text style='light1215' className={styles.smallMargin}>
              {formatMessage('common.totalInterest')}:
            </Text>
            <div>
              <Text style='xxbold1619' dataTest='totalInterest'>
                {new D(interest_total).toFixed()} {principal_currency}
              </Text>
            </div>
            {!isFinished && (
              <CryptoToFiatAmount
                amount={interest_total}
                currency={principal_currency}
                currencyRate={principal_currency_rate}
              />
            )}
          </div>
          <div className={styles.bigMargin}>
            <Text style='xbold1215' dataTest='lendApr'>
              {formatMessage('common.rateApr', { rate_pct })}
            </Text>
          </div>
          <div className={styles.bigMargin}>
            <Text style='light1215' className={styles.smallMargin}>
              {formatMessage('finopsCommon.fundsLocked')}:
            </Text>
            <div>
              <Text style='xxbold1619' dataTest='fundsLocked'>
                {new D(principal_amount).toFixed()} {principal_currency}
              </Text>
            </div>
            {!isFinished && (
              <CryptoToFiatAmount
                amount={principal_amount}
                currency={principal_currency}
                currencyRate={principal_currency_rate}
              />
            )}
          </div>

          <div className={clsx(textColorStyles.secondary, styles.gapSpace)}>
            <div>
              <Text style='light1215' dataTest='lendId'>
                {formatMessage('common.id', { id })}
              </Text>
            </div>
            <div className={styles.flexContainer}>
              <NetworkBox network={network} />
              <div>
                <WalletIcon />{' '}
                <Text style='light1215' dataTest='walletName'>
                  {walletName}
                </Text>
              </div>
            </div>
            <OpenedDate startAt={start_at} />
            <div>
              <Text style='light1215' dataTest='maturityDate'>
                {formatMessage(
                  state === 'TERMINATED'
                    ? 'lend.terminatedAndDate'
                    : 'common.maturityAndDate',
                  {
                    date: formatDatetime(end_at),
                  }
                )}
              </Text>
            </div>
          </div>
        </div>
        {state !== 'TERMINATED' && (
          <div className={styles.earnedBox}>
            <div className={styles.progressCircleContainer}>
              <ProgressCircle progress={earnedSoFar} state={state} />
            </div>
            <div className={styles.summaryText}>
              <Text style='xbold1215' dataTest='earnedSoFar'>
                {new D(interest_earned).toFixed()} {principal_currency},
              </Text>
              <Text style='light1215' dataTest='earnedSoFarPercent'>
                {earnedSoFar.toFixed(0)}%
              </Text>
              <Text style='light1215'>
                {formatMessage('lend.earnedSoFar', {
                  progress: earnedSoFar.toFixed(0),
                })}
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
