import { useFormatMessage } from 'modules/messages';
import styles from './OrganizerLogo.module.scss';

type Props = { url?: string };

export const OrganizerLogo: React.FC<Props> = ({ url }) => {
  const formatMessage = useFormatMessage();

  return (
    <img
      alt={formatMessage('charityForms.organizerLogomark')}
      className={styles.logo}
      src={url}
    />
  );
};
