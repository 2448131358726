import styles from './FilterBoxTemplate.module.scss';
import { Text } from 'common/components/Text';

type Props = {
  header: string;
  buttons: React.ReactNode;
};

export const FilterBoxTemplate: React.FC<Props> = ({ buttons, header }) => {
  return (
    <div className={styles.container}>
      <Text style='xxbold1619'>{header}</Text>
      <div className={styles.buttons}>{buttons}</div>
    </div>
  );
};
