import { useState } from 'react';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import { MAX_INPUT_CHARACTERS_LIMIT_LONG } from 'common/consts/consts';
import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { validateEmail, validateLengthLong } from 'common/utils/validators';
import { useLocation } from 'react-router-dom';
import { Alert } from 'common/components/Alert';
import { Text } from 'common/components/Text';
import { getLocationState } from 'common/utils/utils';

type Props = {
  onSubmit: (email: string) => void;
  email?: string;
  errorSendingAgain?: string;
};

type LocationState = { expired: boolean };

export const StepEmail: React.FC<Props> = ({
  onSubmit,
  email: emailProp,
  errorSendingAgain,
}) => {
  const formatMessage = useFormatMessage();
  const [email, setEmail] = useState(emailProp ?? '');
  const [error, setError] = useState('');
  const location = useLocation();
  const { expired } = getLocationState(location) as LocationState;

  const validateAndSubmit = () => {
    const emailTrimmed = email.trim();
    if (!emailTrimmed) {
      setError(formatMessage('common.fieldRequired'));
      return;
    }

    if (!validateEmail(emailTrimmed)) {
      setError(formatMessage('common.error.input.validation.email'));
      return;
    }

    if (!error) onSubmit(emailTrimmed);
  };

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('resetPassword.stepSuccess.header')}
      </ModalHeader>
      <ModalBody>
        <div>
          {expired && (
            <Alert
              text={formatMessage('resetPassword.error.linkExpired')}
              severity='error'
            />
          )}
          {errorSendingAgain && (
            <Alert severity='error' text={errorSendingAgain} />
          )}
          <div style={{ margin: '20px 0' }}>
            <Text style='light1425'>
              {formatMessage('resetPassword.subtitle')}
            </Text>
          </div>
          <InputWithHeader
            header={formatMessage('common.email')}
            value={email}
            onChange={(e) => {
              const data = e.target.value;
              if (!validateLengthLong(data)) {
                setError(
                  formatMessage('common.error.characters.limit', {
                    limit: MAX_INPUT_CHARACTERS_LIMIT_LONG,
                  })
                );
              } else {
                setError('');
              }

              setEmail(data);
            }}
            placeholder={formatMessage('signup.emailPlaceholder')}
            errorText={error}
            autofocus={true}
            onEnter={validateAndSubmit}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('resetPassword.button.sendResetLink')}
            type='dark'
            onClick={validateAndSubmit}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
