import { useFormatMessage } from 'modules/messages';
import { useFeatureFlag } from 'modules/feature-flags';
import styles from './AlternativeOffer.module.scss';
import { Text } from 'common/components/Text';
import { TermAndRate } from 'modules/financial-ops/common/term-and-rate/TermAndRate';
import {
  ConfigSection,
  InterestRate,
  NewOperationLayout,
  SummarySection,
} from 'modules/financial-ops/common';
import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { LoaderBox } from 'common/components/loader';
import { EarnMoreOnLendModal } from './components/EarnMoreOnLendModal';
import { Currency } from 'modules/select-currency-wallet/types';

type Props = {
  currency: Currency;
  amount?: string;
  rate: InterestRate;
  walletId: number;
  disabled: boolean;
};

export const AlternativeOffer: React.FC<Props> = ({
  currency,
  amount,
  rate,
  walletId,
  disabled,
}) => {
  const formatMessage = useFormatMessage();
  const newSaveFeatureFlag = useFeatureFlag('CREATING_SAVING');
  const newLendFeatureFlag = useFeatureFlag('CREATING_LEND');
  const lendFeatureFlag = useFeatureFlag('LEND');

  if (
    newSaveFeatureFlag.isLoading ||
    newLendFeatureFlag.isLoading ||
    lendFeatureFlag.isLoading
  ) {
    return <LoaderBox />;
  }

  if (
    !newLendFeatureFlag.data?.enabled ||
    !newSaveFeatureFlag.data?.enabled ||
    !lendFeatureFlag.data?.enabled
  ) {
    return null;
  }

  return (
    <NewOperationLayout className={styles.layout}>
      <ConfigSection className={styles.alternativeOffer}>
        <div className={styles.container}>
          <Text style='light1215' colorStyle='inactive'>
            {formatMessage('saving.alternativeOffer')}
          </Text>
          <div className={styles.box}>
            <div className={styles.texts}>
              <div className={styles.header}>
                <CurrencyIcon currencyName={currency.currency} />
                <Text style='light1822'>
                  {formatMessage('finopsCommon.lend', {
                    currency: currency.currency,
                  })}
                </Text>
              </div>
              <Text style='bold1619'>
                {formatMessage('saving.earnMoneyByCreatingLend')}
              </Text>
              <Text style='light1622'>
                {formatMessage('saving.chooseYourTermsAndEarnMore')}
              </Text>
            </div>
            <TermAndRate
              rate={rate.rate_pct}
              term={rate.term}
              className={styles.apr}
              scaleResponsively={false}
            />
          </div>
          <EarnMoreOnLendModal
            amount={amount}
            currency={currency}
            rate={rate}
            walletId={walletId}
            disabled={disabled}
          />
        </div>
      </ConfigSection>
      <SummarySection />
    </NewOperationLayout>
  );
};
