import { Checkbox } from 'common/components/Checkbox';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { useFormatMessage } from 'modules/messages';
import styles from './TermsAndConditions.module.scss';
import { textColorStyles, textStyles } from 'common/components/Text';
import clsx from 'clsx';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { useDocument } from '../api';

type Props = {
  checked: boolean;
  error: boolean;
  onToggle: (checked: boolean) => void;
  className?: string;
};

export const TermsAndConditions: React.FC<Props> = ({
  checked,
  error,
  onToggle,
  className,
}) => {
  const formatMessage = useFormatMessage();
  const { data: url, isLoading } = useDocument('TERMS_AND_CONDITIONS');

  return (
    <div className={className}>
      <SectionSubheader>
        {formatMessage('common.termsAndConditions')}
      </SectionSubheader>
      <div className={styles.termsConditionsCheckbox}>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            onToggle(e.currentTarget.checked);
          }}
          id='TermsAndConditions-checkbox'
          dataTest='termsAndConditionsCheckbox'
          error={error}
        />
        <span
          className={clsx(
            textStyles.light1425,
            error ? textColorStyles.error : textColorStyles.primary
          )}
          dangerouslySetInnerHTML={{
            __html: formatMessage('finopsCommon.acceptTermsConditions', {
              termsAndConditions: `<a class="${clsx(
                styles.termsConditionsLink,
                textStyles.light1425,
                textColorStyles.primary,
                error && textColorStyles.error
              )}" ${
                url && !isLoading ? `href=${url}` : ''
              } target='_blank' rel='noreferrer' >${formatMessage(
                'common.termsAndConditions'
              )}</a>`,
            }),
          }}
        />
        {isLoading && <RotatingLoader size={10} type='secondary' />}
      </div>
    </div>
  );
};
