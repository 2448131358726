import { AxiosError } from 'axios';
import { openSnackbar } from 'common/components/snackbar';
import { useFormatMessage } from 'modules/messages';

export type RecaptchaHeader = {
  'Recaptcha-Token': string;
};

export const ERR_STATUS_RECAPTCHA_VERIFICATION_FAILED =
  'RECAPTCHA_VERIFICATION_FAILED';

export const ERR_STATUS_RECAPTCHA_LOW_SCORE = 'RECAPTCHA_LOW_SCORE';

export type RecaptchaErrorResponse = {
  status?: string;
  details?: string;
};

export const isRecaptchaLowScoreError = (
  error: AxiosError<RecaptchaErrorResponse> | null
) => {
  if (!error) {
    return null;
  }
  return error.response?.data.status === ERR_STATUS_RECAPTCHA_LOW_SCORE;
};

export const isRecaptchaVerificationFailedError = (
  error: AxiosError<RecaptchaErrorResponse> | null
) => {
  if (!error) {
    return null;
  }
  return (
    error.response?.data.status === ERR_STATUS_RECAPTCHA_VERIFICATION_FAILED
  );
};

export const isRecaptchaError = (
  error: AxiosError<RecaptchaErrorResponse> | null
) => {
  return [
    isRecaptchaLowScoreError(error),
    isRecaptchaVerificationFailedError(error),
  ].some((isError) => isError === true);
};

export const useHandleRecaptchaError = () => {
  const formatMessage = useFormatMessage();

  // returns if error was recaptcha error or not
  const handleRecaptchaError = (error: AxiosError<RecaptchaErrorResponse>) => {
    if (!error.response) {
      return false;
    }
    if (isRecaptchaVerificationFailedError(error)) {
      openSnackbar(
        formatMessage('recaptcha.weCouldNotVerifyRecaptcha'),
        'error'
      );
      return true;
    }
    if (isRecaptchaLowScoreError(error)) {
      openSnackbar(formatMessage('recaptcha.weThinkYouAreBot'), 'error');
      return true;
    }
    return false;
  };

  const handleRecaptchaNotLoaded = () => {
    openSnackbar(
      formatMessage('recaptcha.waitingForRecaptchaToLoad'),
      'warning'
    );
  };

  return { handleRecaptchaError, handleRecaptchaNotLoaded };
};

export const buildRecaptchaHeader = (token: string): RecaptchaHeader => ({
  'Recaptcha-Token': token,
});
