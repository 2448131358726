import { BackButton, Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import styles from './StepTerminate.module.scss';
import { Text } from 'common/components/Text';
import {
  PostLendTerminateEffectResponseData,
  PostLendTerminateEffectResponseError,
  lendDetailsQueryKey,
  useLendDetails,
  useLendTerminateMutation,
} from '../../api';
import { toFixed } from 'modules/input-amount';
import {
  AssetAmountReadOnly,
  SnapshotAlert,
  TokenExpiryTimer,
  WalletBox,
  tokenInvalidValue,
} from 'modules/financial-ops/common';
import { formatDate } from 'common/utils/utils';
import { addHours } from 'date-fns';
import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { useWallets } from 'modules/assets-and-wallets/api';
import { useState } from 'react';
import { Alert } from 'common/components/Alert';
import { LoaderBox } from 'common/components/loader';
import { useQueryClient } from '@tanstack/react-query';
import { isAlertError, useGetErrorText } from './error-utils';
import { AxiosError } from 'axios';

type Props = {
  lendId: number;
  summary: PostLendTerminateEffectResponseData;
  summaryError: AxiosError<PostLendTerminateEffectResponseError> | null;
  onBack: () => void;
  onNext: (transactionId: number) => void;
  onRefreshSummary: () => void;
};

export const StepTerminate: React.FC<Props> = ({
  lendId,
  summary,
  summaryError,
  onBack,
  onNext,
  onRefreshSummary,
}) => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const { data: lend, isLoading: isLoadingLend } = useLendDetails(lendId);

  const { data: wallets, isLoading: isLoadingWallets } = useWallets();

  const lendWallet = wallets?.find(
    (wallet) => wallet.id === lend?.principal_wallet.id
  );

  const isLoading = isLoadingLend || isLoadingWallets;

  const isData = lend && lendWallet;

  const [tokenExpired, setTokenExpired] = useState(false);

  const terminateMutation = useLendTerminateMutation(lendId);

  const tokenInvalid = tokenInvalidValue(
    terminateMutation.error?.response?.data
  );

  const getErrorText = useGetErrorText();

  return (
    <>
      <ModalHeader
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('lend.reviewAndConfirmDetails')}
          </Text>
        }
      >
        {formatMessage('lend.terminateLendSummary')}
      </ModalHeader>
      <ModalBody className={styles.content}>
        {summaryError && (
          <Alert severity='error' text={getErrorText(summaryError)} />
        )}
        {terminateMutation.error && isAlertError(terminateMutation.error) && (
          <Alert
            severity='error'
            text={getErrorText(terminateMutation.error)}
          />
        )}
        {isLoading && <LoaderBox height='400px' />}
        {isData && (
          <>
            <div>
              <AssetAmountReadOnly
                amount={lend.principal_amount}
                currency={{
                  currency: lend.principal_currency,
                  network: lend.principal_wallet.network,
                }}
              />
              <WalletBox
                wallet={{
                  ...lendWallet,
                  balance: lendWallet.balances.find(
                    (balance) => balance.currency === lend.principal_currency
                  )!.amount,
                }}
                currency={lend.principal_currency}
                className={styles.wallet}
              />
            </div>
            <div className={styles.summary}>
              <div className={styles.field}>
                <Text style='light1425'>
                  {formatMessage('finopsCommon.fundsLocked')}
                </Text>
                <Text style='light1425'>{`${toFixed(lend.principal_amount)} ${
                  lend?.principal_currency
                }`}</Text>
              </div>
              <div>
                <div className={styles.field}>
                  <Text style='light1425'>
                    {formatMessage('lend.newInterestRate') + ':'}
                  </Text>
                  <Text style='light1425'>
                    {toFixed(summary.rate_pct, 2) + '%'}
                  </Text>
                </div>
                <div className={styles.field}>
                  <Text style='light1425' colorStyle='secondary'>
                    {formatMessage('lend.initialInterestRate') + ':'}
                  </Text>
                  <Text style='light1425' colorStyle='secondary'>
                    {toFixed(lend.rate_pct, 2) + '%'}
                  </Text>
                </div>
              </div>
              <div>
                <div className={styles.field}>
                  <Text style='light1425'>
                    {formatMessage('lend.newTotalEarn') + ':'}
                  </Text>
                  <Text style='light1425'>{`${toFixed(
                    summary.interest_total
                  )} ${lend.principal_currency}`}</Text>
                </div>
                <div className={styles.field}>
                  <Text style='light1425' colorStyle='secondary'>
                    {formatMessage('lend.initialTotalEarn') + ':'}
                  </Text>
                  <Text style='light1425' colorStyle='secondary'>
                    {`${toFixed(lend.interest_total)} ${
                      lend.principal_currency
                    }`}
                  </Text>
                </div>
              </div>
              <div className={styles.field}>
                <LabelWithInfoTooltip
                  text={formatMessage('lend.fundsReleaseDate')}
                  tooltipText={formatMessage(
                    'lend.terminationTakesXHoursAndDependsOnMultiapprovalDate',
                    { hours: summary.payout_hours }
                  )}
                />
                <Text style='light1425'>
                  {formatDate(
                    addHours(new Date(), summary.payout_hours).toISOString()
                  )}
                </Text>
              </div>
            </div>
            {(tokenExpired || tokenInvalid) && (
              <SnapshotAlert
                onRefresh={() => {
                  onRefreshSummary();
                  setTokenExpired(false);
                  terminateMutation.reset();
                }}
              />
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onBack} />
          <Button
            isLoading={terminateMutation.isLoading}
            disabled={
              !summary.token ||
              terminateMutation.isLoading ||
              terminateMutation.isError ||
              tokenExpired
            }
            name={
              <div className={styles.terminateButton}>
                <Text style='xbold1417'>{formatMessage('lend.terminate')}</Text>
                {summary.token && !terminateMutation.isError && (
                  <TokenExpiryTimer
                    token={summary.token}
                    onTimeExpired={() => setTokenExpired(true)}
                  />
                )}
              </div>
            }
            type='dark'
            onClick={() => {
              if (terminateMutation.isLoading) {
                return;
              }
              terminateMutation.mutate(
                { token: summary.token! },
                {
                  onSuccess: (data) => {
                    queryClient.invalidateQueries(lendDetailsQueryKey(lendId));
                    onNext(data.transaction_id);
                  },
                  onError: () => {
                    // refresh if lend becomes inactive or gets has_pending_transaction = true in the meantime
                    queryClient.invalidateQueries(lendDetailsQueryKey(lendId));
                  },
                }
              );
            }}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
