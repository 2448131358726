import clsx from 'clsx';
import styles from './Pill.module.scss';
import { Text } from './Text';

type Props = { text?: string; className?: string; dataTest?: string };

// this not a self-sufficient component
// this is only a base for specific pill components which must pass border and background colors using className prop
export const Pill: React.FC<Props> = ({ text, className, dataTest }) => {
  return (
    <Text
      style='light1215'
      className={clsx(styles.container, className)}
      dataTest={dataTest}
    >
      {text}
    </Text>
  );
};
