import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { get, post } from 'modules/api';
import { CurrencyEnum, NetworkEnum } from 'common/types';

const WITHDRAWAL_FEE_URL = '/withdrawal-fee';
const WITHDRAW_URL = '/withdraw';
const WITHDRAW_EFFECT_URL = '/withdraw/effect';

export const WITHDRAWAL_FEE_KEY = ['GET', WITHDRAWAL_FEE_URL];

export const POST_WITHDRAW_KEY = ['POST', WITHDRAW_URL];

export const POST_WITHDRAW_EFFECT_QUERY_KEY = [
  'POST',
  WITHDRAW_EFFECT_URL,
] as const;

export type WithdrawFee = {
  currency: CurrencyEnum;
  network: NetworkEnum;
  fee: string;
};

export type Withdraw = {
  currency: CurrencyEnum;
  amount: string;
  whitelisted_address_id: number;
  wallet_id: number;
  token: string;
};

export type WithdrawError = {
  amount?: string[];
  address?: string[];
  whitelisted_address_id?: string[];
  token?: string[];
};

export type WithdrawEffectPayload = {
  currency: CurrencyEnum;
  amount: string;
  whitelisted_address_id: number;
  wallet_id: number;
  summary: boolean;
};

export type WithdrawEffectResponseData = {
  token: string;
  fee: string;
};

export type WithdrawEffectResponseError = {
  amount?: string[];
  address?: string[];
  whitelisted_address_id?: string[];
  token?: string[];
};

export type WithdrawResponse = {
  transaction_id: number;
};

const getWithdrawalFee = () => {
  return get<WithdrawFee[]>(WITHDRAWAL_FEE_URL);
};

const postWithdrawEffect = (payload: WithdrawEffectPayload) =>
  post<WithdrawEffectResponseData, WithdrawEffectPayload>(
    WITHDRAW_EFFECT_URL,
    payload
  );

const postWithdraw = (payload: Withdraw) =>
  post<WithdrawResponse, Withdraw>(WITHDRAW_URL, payload);

export const useGetWithdrawalFeeQuery = (
  options?: UseQueryOptions<AxiosResponse<WithdrawFee[]>>
) =>
  useQuery<AxiosResponse<WithdrawFee[]>>(
    WITHDRAWAL_FEE_KEY,
    getWithdrawalFee,
    options
  );

export const useWithdrawMutation = () =>
  useMutation<
    AxiosResponse<WithdrawResponse>,
    AxiosError<WithdrawError>,
    Withdraw
  >(POST_WITHDRAW_KEY, postWithdraw);

export const useWithdrawEffectMutation = () =>
  useMutation<
    AxiosResponse<WithdrawEffectResponseData>,
    AxiosError<WithdrawEffectResponseError>,
    WithdrawEffectPayload
  >(POST_WITHDRAW_EFFECT_QUERY_KEY, postWithdrawEffect);

export const notEnoughFundsToWithdraw = (
  errorResp?: WithdrawEffectResponseError
) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_NOT_ENOUGH_FUNDS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const whitelistedAddressNotExist = (
  errorResp?: WithdrawEffectResponseError
) => {
  for (let err of errorResp?.whitelisted_address_id || []) {
    const match = err.match(ERR_WHITELISTED_ADDRESS_NOT_EXIST);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const whitelistedAddressHasBeenRemoved = (
  errorResp?: WithdrawEffectResponseError
) => {
  for (let err of errorResp?.whitelisted_address_id || []) {
    const match = err.match(ERR_WHITELISTED_ADDRESS_HAS_BEEN_REMOVED);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const addressCannotBeBlank = (
  errorResp?: WithdrawEffectResponseError
) => {
  for (let err of errorResp?.address || []) {
    const match = err.match(ERR_BLANK_VALUE);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const amountMustBeGreaterThanZero = (
  errorResp?: WithdrawEffectResponseError
) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_AMOUNT_MUST_BE_GT_ZERO);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const amountMustBeGreaterOrEqualTo = (
  errorResp?: WithdrawEffectResponseError
) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_AMOUNT_MUST_BE_GTE_TO);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const ERR_NOT_ENOUGH_FUNDS = /Not enough funds to withdraw./;
export const ERR_WHITELISTED_ADDRESS_NOT_EXIST =
  /Whitelisted address with provided ID does not exist./;
export const ERR_WHITELISTED_ADDRESS_HAS_BEEN_REMOVED =
  /Whitelisted address has been removed./;
export const ERR_BLANK_VALUE = /This field may not be blank./;
export const ERR_AMOUNT_MUST_BE_GT_ZERO = /Amount must be greater than 0./;
export const ERR_AMOUNT_MUST_BE_GTE_TO =
  /Amount must be greater or equal to: (\d+.\d+)/;
