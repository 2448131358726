import { useNavigate } from 'react-router-dom';
import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters';
import { TransactionFilters } from 'modules/transactions/api';

export const FullHistoryButton: React.FC<{
  filters: TransactionFilters;
  className?: string;
  disabled?: boolean;
}> = ({ filters, className, disabled }) => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();

  const { setFilters, resetFilters, setRefetchTransactionsFlag } =
    useInfiniteTransactionsContext();

  return (
    <Button
      type='secondarySmall'
      name={formatMessage('common.fullHistory')}
      disabled={disabled}
      onClick={() => {
        resetFilters();
        setFilters(filters);
        setRefetchTransactionsFlag(true);
        navigate('/transactions');
      }}
      className={className}
    />
  );
};
