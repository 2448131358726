import { ReactComponent as ArrowLeftIcon } from 'common/assets/black-icons/arrow-left-small.svg';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './SavingDetailsHeader.module.scss';

type Props = { onBack: () => void };

export const SavingDetailsHeader: React.FC<Props> = ({ onBack }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ArrowLeftIcon
          onClick={onBack}
          data-test='backArrow'
          className={styles.arrowBack}
        />
        <div>
          <Text style='xxbold3644'>
            {formatMessage('saving.savingDetails')}
          </Text>
        </div>
      </div>
    </div>
  );
};
