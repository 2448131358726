import { CancelButton, NextButton } from 'common/components/button';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { MAX_INPUT_CHARACTERS_LIMIT_SHORT } from 'common/consts/consts';
import { validateLengthShort } from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';

type Props = {
  onNext: () => void;
  walletName: string;
  onWalletNameChange: (name: string) => void;
  onCancel: () => void;
};

export const StepWalletName: React.FC<Props> = ({
  walletName,
  onWalletNameChange,
  onNext,
  onCancel,
}) => {
  const formatMessage = useFormatMessage();

  const [error, setError] = useState('');

  const validateAndSubmit = () => {
    if (!walletName.trim()) {
      setError(formatMessage('wallets.error.nameRequired'));
      return;
    }
    if (!validateLengthShort(walletName.trim())) {
      setError(
        formatMessage('common.error.characters.limit', {
          limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
        })
      );
      return;
    }
    setError('');
    onNext();
  };

  return (
    <>
      <ModalHeader>{formatMessage('wallets.createWallet')}</ModalHeader>
      <ModalBody>
        <InputWithHeader
          header={formatMessage('wallets.insertWalletName')}
          value={walletName}
          onChange={(e) => {
            const data = e.target.value;
            if (!validateLengthShort(data)) {
              setError(
                formatMessage('common.error.characters.limit', {
                  limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
                })
              );
            } else {
              setError('');
            }
            onWalletNameChange(data);
          }}
          errorText={error}
          dataTest='walletNameInput'
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <NextButton onClick={validateAndSubmit} />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
