import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useNavigate } from 'react-router-dom';

type Props = { path: string; isSmall?: boolean };

export const ShowAllButton: React.FC<Props> = ({ path, isSmall = false }) => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();

  return (
    <Button
      name={formatMessage('common.button.showAll')}
      onClick={() => {
        navigate(path);
        window.scrollTo(0, 0);
      }}
      type={isSmall ? 'secondarySmall' : 'secondary'}
    />
  );
};
