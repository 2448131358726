import { LoaderBox } from 'common/components/loader';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { NewTrade } from './new-trade/NewTrade';
import { RecentTrades } from './trade-history/RecentTrades';

export const TradeDashboard: React.FC<{}> = () => {
  const tradeFeatureFlag = useRequireFeatureFlag('TRADE');

  if (tradeFeatureFlag.isLoading) {
    return <LoaderBox />;
  }
  if (!tradeFeatureFlag.data?.enabled) {
    return null;
  }

  return (
    <>
      <NewTrade />
      <RecentTrades />
    </>
  );
};
