import { useState } from 'react';
import styles from './StatusFilter.module.scss';
import { useFormatMessage } from 'modules/messages';
import { ModalTemplate } from './components/ModalTemplate';
import { FilterButton } from './components/FilterButton';
import { MainFilterButton } from './components/MainFilterButton';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters/context';
import { toggleElement } from 'common/utils/toggle-element';

type Props = {};

export const StatusFilter: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();
  const { filters, setFilters, setRefetchTransactionsFlag } =
    useInfiniteTransactionsContext();

  const [open, setOpen] = useState(false);
  const [preventClose, setPreventClose] = useState(false);

  const statesList: {
    text: string;
    value: string;
  }[] = [
    {
      text: formatMessage('common.completed'),
      value: 'COMPLETED',
    },
    {
      text: formatMessage('transactions.pending'),
      value: 'PENDING',
    },
    {
      text: formatMessage('common.failed'),
      value: 'FAILED',
    },
    {
      text: formatMessage('transactions.rejected'),
      value: 'REJECTED',
    },
    {
      text: formatMessage('transactions.expired'),
      value: 'EXPIRED',
    },
  ];

  const buttonText =
    formatMessage('transactions.status') +
    (filters.states?.length
      ? ': ' +
        filters.states
          ?.map(
            (state) => statesList.find((value) => value.value === state)?.text
          )
          .join(', ')
      : '');

  return (
    <div>
      <MainFilterButton
        onClick={() => {
          setPreventClose(true);
          setOpen((prevIsOpen) => !prevIsOpen);
        }}
        text={buttonText}
      />
      <ModalTemplate
        open={open}
        onChangeOpen={(value) => setOpen(value)}
        onChangePreventClose={(value) => setPreventClose(value)}
        preventClose={preventClose}
        children={
          <div className={styles.modalContent}>
            {statesList.map((state, i) => (
              <FilterButton
                onClick={() => {
                  setFilters({
                    ...filters,
                    states: toggleElement(
                      filters.states ?? [],
                      state.value
                    ).sort(),
                  });
                  setRefetchTransactionsFlag(true);
                }}
                text={state.text}
                isSelected={filters.states?.includes(state.value)}
                key={i}
              />
            ))}
          </div>
        }
      />
    </div>
  );
};
