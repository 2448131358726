import { PageLoader } from 'common/components/loader';
import { SavingDetails as SavingDetailsFeature } from 'modules/financial-ops/savings-account';
import { NavigationLayout } from 'modules/layout';
import { useLoginRequired } from 'modules/login';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const SavingsAccountDetails: React.FC<{}> = () => {
  const { isLoading } = useLoginRequired();

  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.savingsAccount')}</title>
      </Helmet>
      {isLoading ? (
        <PageLoader />
      ) : (
        <NavigationLayout>
          <SavingDetailsFeature />
        </NavigationLayout>
      )}
    </>
  );
};
