import { Text } from 'common/components/Text';
import { DATE_FORMAT, TIME_TZ_FORMAT } from 'common/utils/utils';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import styles from './ReferenceClock.module.scss';

type Props = {};

export const ReferenceClock: React.FC<Props> = () => {
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    const handle = window.setInterval(() => {
      setTime(Date.now());
    }, 1000);
    return () => {
      window.clearInterval(handle);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Text style='light1215' colorStyle='secondary'>
        {format(time, DATE_FORMAT)}
      </Text>
      <Text style='light1012' colorStyle='secondary'>
        {format(time, TIME_TZ_FORMAT)}
      </Text>
    </div>
  );
};
