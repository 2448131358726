import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { useGetAvailableNetworks } from 'modules/assets-and-wallets/api';
import { NetworkSelector } from 'modules/assets-and-wallets/components/NetworkSelector';
import { LoaderBox } from 'common/components/loader';
import styles from './SelectNetworks.module.scss';
import { useNewCharityFormContext } from '../context';
import { SupportedCurrencies } from './SupportedCurrencies';
import clsx from 'clsx';
import { NetworkEnum } from 'common/types';

type Props = {
  selectable?: boolean;
  onChange?: () => void;
  networks?: NetworkEnum[];
  className?: string;
};

export const SelectNetworks: React.FC<Props> = ({
  selectable = true,
  onChange,
  networks,
  className,
}) => {
  const formatMessage = useFormatMessage();
  const context = useNewCharityFormContext();

  const { data: availableNetworksData, isLoading: isAvailableNetworksLoading } =
    useGetAvailableNetworks();

  const availableNetworks = !networks
    ? availableNetworksData?.data
    : availableNetworksData?.data.filter((item) =>
        networks.includes(item.network)
      );

  return (
    <>
      {isAvailableNetworksLoading && <LoaderBox />}
      {availableNetworks && (
        <div className={clsx(styles.networkContainer, className)}>
          <div>
            <Text style='xxbold1619' className={styles.subheader}>
              {formatMessage(
                selectable
                  ? 'charityForms.selectNetworks'
                  : 'charityForms.selectedNetworks'
              )}
            </Text>
            {availableNetworks.map((avaiableNetwork) => (
              <NetworkSelector
                key={avaiableNetwork.network}
                network={avaiableNetwork}
                className={clsx(!selectable && styles.notSelectable)}
                currenciesClassName={styles.networkContainerMobile}
                isSelected={
                  context.networkList
                    ? context.networkList.includes(avaiableNetwork.network)
                    : false
                }
                onSelect={() => {
                  if (!selectable) {
                    return;
                  }
                  if (context.networkList) {
                    if (context.networkList.includes(avaiableNetwork.network)) {
                      const found = context.networkList.indexOf(
                        avaiableNetwork.network
                      );

                      if (found > -1) {
                        const newList = [...context.networkList];
                        newList.splice(found, 1);
                        context.setNetworkList(newList);
                      }
                    } else {
                      context.setNetworkList([
                        ...context.networkList,
                        avaiableNetwork.network,
                      ]);
                    }
                  } else {
                    context.setNetworkList([avaiableNetwork.network]);
                  }
                  if (onChange) onChange();
                }}
              />
            ))}
          </div>
          <div className={styles.currencies}>
            {!!context.networkList?.length && (
              <SupportedCurrencies
                networks={availableNetworks.filter((availableNetwork) =>
                  context.networkList?.includes(availableNetwork.network)
                )}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
