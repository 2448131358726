import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './BorrowDetailsHeader.module.scss';
import { ReactComponent as ArrowLeftIcon } from 'common/assets/black-icons/arrow-left-small.svg';
import { BorrowDetails } from '../types';

type Props = { onArrowClick: () => void; borrow?: BorrowDetails };

export const BorrowDetailsHeader: React.FC<Props> = ({
  onArrowClick,
  borrow,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ArrowLeftIcon
          onClick={onArrowClick}
          data-test='backArrow'
          className={styles.arrowBack}
        />
        <div>
          <Text style='xxbold3644'>
            {formatMessage('borrow.borrowDetails')}
          </Text>
        </div>
      </div>
    </div>
  );
};
