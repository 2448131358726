import { Alert } from 'common/components/Alert';
import { LoaderBox } from 'common/components/loader';
import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useFormatMessage } from 'modules/messages';
import { BalanceSummary } from './BalanceSummary';
import { useBalanceSummaryData } from './hooks';

type Props = {};

export const SectionBalanceSummary: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const { isFeatureFlagsLoading, isError } = useBalanceSummaryData();

  return (
    <Section>
      <SectionHeader header={formatMessage('common.balance')} />
      {isFeatureFlagsLoading && <LoaderBox height='50px' />}
      {isError && (
        <Alert
          severity='error'
          text='Sorry, there was an error while fetching data.'
        />
      )}
      <BalanceSummary />
    </Section>
  );
};
