import { useState } from 'react';
import * as R from 'ramda';
import { useFormatMessage } from 'modules/messages';
import styles from './Savings.module.scss';
import { Text } from 'common/components/Text';
import { LoaderBox } from 'common/components/loader';
import {
  PopupSorter,
  SortConfig,
} from 'common/components/table-subcomponents/sort/PopupSorter';
import { ascComp, descComp } from 'common/utils/comparators';
import { SortValue } from 'common/components/table-subcomponents';
import { PopupFilter } from 'common/components/table-subcomponents/filter/PopupFilter';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { BoxLayout } from 'modules/financial-ops/common/components';
import { useFilters } from 'modules/financial-ops/common/hooks';
import { useFiatComparators } from 'modules/fiat';
import { Saving } from '../types';
import { useSavingsQuery } from '../api';
import { ActiveSavingsCount } from './components/ActiveSavingsCount';
import { SavingBox } from './components/SavingBox';

type SortModel = {
  balance?: SortValue;
  currency?: SortValue;
  activated_at?: SortValue;
};

export const Savings: React.FC = () => {
  const formatMessage = useFormatMessage();

  const { data, isLoading } = useSavingsQuery();

  const savings: Saving[] = data?.data ?? [];

  const [sort, setSort] = useState<SortModel>({});

  const [filter, filterConfig] = useFilters();

  const { ascFiatCmp, descFiatCmp } = useFiatComparators();

  const getSortedAndFilteredData = () => {
    const filteredSavings = filter.active
      ? R.filter((item: Saving) => item.state === 'ACTIVE', savings)
      : filter.finished
      ? R.filter((item: Saving) => item.state === 'COMPLETED', savings)
      : savings;

    if (sort.balance) {
      return R.sort(
        (item1, item2) =>
          (sort.balance === 'asc' ? ascFiatCmp : descFiatCmp)(
            {
              amount: item1.balance,
              currency: item1.currency,
            },
            {
              amount: item2.balance,
              currency: item2.currency,
            }
          ),
        filteredSavings
      );
    }
    if (sort.currency) {
      return R.sort(
        (item1, item2) =>
          (sort.currency === 'asc' ? ascComp : descComp)(
            item1.currency,
            item2.currency
          ),
        filteredSavings
      );
    }
    if (sort.activated_at) {
      return R.sort(
        (item1, item2) =>
          (sort.activated_at === 'asc' ? ascComp : descComp)(
            item1.activated_at,
            item2.activated_at
          ),
        filteredSavings
      );
    }

    return filteredSavings;
  };

  const sortList: SortConfig[] = [
    {
      sort: sort.balance,
      onSort: (next: SortValue) => {
        setSort({ balance: next });
      },
      text: formatMessage('finopsCommon.lockedAmount'),
    },
    {
      sort: sort.currency,
      onSort: (next: SortValue) => {
        setSort({ currency: next });
      },
      text: formatMessage('finopsCommon.currencyAZ'),
    },
    {
      sort: sort.activated_at,
      onSort: (next: SortValue) => {
        setSort({ activated_at: next });
      },
      text: formatMessage('common.openingDate'),
    },
  ];

  const sortedAndFilteredData = getSortedAndFilteredData();

  return (
    <div>
      <Text style='xxbold3048' className={styles.header}>
        {formatMessage('saving.savings')}
      </Text>
      {!isLoading ? (
        <>
          <div className={styles.subHeader}>
            <ActiveSavingsCount />
            <div className={styles.sortFilter}>
              <PopupFilter filters={filterConfig} />
              <PopupSorter config={sortList} />
            </div>
          </div>
          <BoxLayout>
            {sortedAndFilteredData.map((saving) => (
              <SavingBox saving={saving} key={saving.id} />
            ))}
          </BoxLayout>
          {sortedAndFilteredData.length === 0 && <NothingToDisplay />}
        </>
      ) : (
        <LoaderBox />
      )}
    </div>
  );
};
