import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useIsSigner } from 'modules/user';
import { ApproveTransactionText } from 'modules/2FA';
import { CurrentThreshold } from 'modules/financial-ops/common/components/CurrentThreshold';

type Props = {
  onClose: () => void;
  onApprove: () => void;
};

export const StepSuccess: React.FC<Props> = ({ onClose, onApprove }) => {
  const formatMessage = useFormatMessage();
  const { data: isSigner } = useIsSigner();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('common.withdrawalHasBeenInitiated')}
      </ModalHeader>
      <ModalBody>
        <Text
          style='light1425'
          inlineStyle={{
            whiteSpace: 'pre-line',
            display: 'block',
            marginBottom: '30px',
          }}
        >
          {formatMessage('common.fundsWillBeWithdrawn')}
        </Text>
        <CurrentThreshold />
        {isSigner && (
          <div style={{ marginTop: '30px' }}>
            <ApproveTransactionText />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          {isSigner ? (
            <>
              <Button
                type='secondary'
                onClick={onClose}
                name={formatMessage('common.button.later')}
              />
              <Button
                type='dark'
                onClick={onApprove}
                name={formatMessage('common.button.approve')}
              />
            </>
          ) : (
            <Button
              type='dark'
              onClick={onClose}
              name={formatMessage('common.button.done')}
            />
          )}
        </ModalActions>
      </ModalFooter>
    </>
  );
};
