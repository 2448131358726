import { useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { useCallback } from 'react';
import {
  LENDS_QUERY_KEY,
  LENDS_SUMMARY_QUERY_KEY,
} from 'modules/financial-ops/lend/api';
import { LendRead } from 'modules/financial-ops/lend/types';
import { log } from 'modules/logger';
import { LendNewEvent } from '../types/lend-new-event';

export const useHandleLendNew = () => {
  const queryClient = useQueryClient();

  const handleLendNew = useCallback(
    (event: LendNewEvent) => {
      log('handling LEND_NEW WebSocket event', event);

      queryClient.setQueryData(
        LENDS_QUERY_KEY,
        (prevState: LendRead[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          return sortBy(
            [event.lend, ...prevState],
            (l) => (l.state === 'CREATED' ? 2 : l.state === 'ACTIVE' ? 1 : 0),
            (l) => new Date(l.created_at)
          ).reverse(); // reverse needed, because sortBy sorts in ascending order
        }
      );

      queryClient.invalidateQueries(LENDS_SUMMARY_QUERY_KEY);
    },
    [queryClient]
  );

  return handleLendNew;
};
