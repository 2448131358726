import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { CurrencyEnum, NetworkEnum } from 'common/types';
import { post } from 'modules/api';
import {
  FeatureFlagDisabledError,
  useFeatureFlag,
  useFeatureFlagDisabledHandler,
} from 'modules/feature-flags';
import { FiatCurrencyEnum } from 'modules/fiat';
import { get } from 'modules/api';
import {
  CharityFormRead,
  CharityFormDetailed,
  CharityFormDonations,
} from './types';
import { useQuery } from '@tanstack/react-query';
import { CharityFormPictureKind } from 'modules/charity-forms-public/types';

export const CHARITY_FORMS_URL = '/charity-forms';
export const CHARITY_FORMS_PICTURES = '/charity-forms-pictures';
export const CHARITY_FORM_URL = '/charity-forms/{id}';
export const CHARITY_FORM_DONATIONS_URL = '/charity-forms/{id}/donations';
export const CHARITY_FORMS_WITHDRAW_URL = '/charity-forms/{id}/withdraw';
export const CHARITY_FORMS_WITHDRAW_EFFECT_URL =
  '/charity-forms/{id}/withdraw/effect';
export const CHARITY_FORMS_FINISH_URL = '/charity-forms/{id}/finish';

export const POST_CHARITY_FORMS_QUERY_KEY = [
  'POST',
  CHARITY_FORMS_URL,
] as const;
export const POST_CHARITY_FORMS_PICTURES_KEY = [
  'POST',
  CHARITY_FORMS_PICTURES,
] as const;

export const CHARITY_FORMS_QUERY_KEY = ['GET', CHARITY_FORMS_URL] as const;

export const CHARITY_FORM_QUERY_KEY = (id: number) =>
  ['GET', CHARITY_FORM_URL, id] as const;

export const CHARITY_FORM_ALL_DETAILS_QUERY_KEY = [
  'GET',
  CHARITY_FORM_URL,
] as const;

export const CHARITY_FORM_DONATIONS_QUERY_KEY = (id: number) =>
  ['GET', CHARITY_FORM_DONATIONS_URL, id] as const;

export const CHARITY_FORM_ALL_DONATIONS_QUERY_KEY = [
  'GET',
  CHARITY_FORM_DONATIONS_URL,
] as const;
export const POST_CHARITY_FORM_WITHDRAW_QUERY_KEY = (id: number) =>
  ['POST', CHARITY_FORMS_WITHDRAW_URL, id] as const;

export const POST_CHARITY_FORM_WITHDRAW_EFFECT_QUERY_KEY = (id: number) =>
  ['POST', CHARITY_FORMS_WITHDRAW_EFFECT_URL, id] as const;

export const POST_CHARITY_FORM_FINISH_QUERY_KEY = (id: number) =>
  ['POST', CHARITY_FORMS_FINISH_URL, id] as const;

export type PostCharityFormPicturesPayload = {
  file: File;
  kind: CharityFormPictureKind;
};

export type PostCharityFormPicturesResponseData = {
  id: number;
  file: string;
  kind: CharityFormPictureKind;
};

export type PostCharityFormsPicturesError = {
  file?: string[];
  kind?: string[];
} & FeatureFlagDisabledError;

export type PostCharityFormsPayload = {
  name: string;
  overview: string;
  description: string;
  youtube_link?: string;
  youtube_link_order?: number;
  dark_mode: boolean;
  primary_color: string;
  secondary_color?: string;
  goal_enabled: boolean;
  end_at_enabled: boolean;
  goal_amount?: string;
  goal_fiat: FiatCurrencyEnum;
  end_at?: string;
  pictures_logo_id: number;
  pictures_content_ids: number[];
  networks_list: NetworkEnum[];
};

export type PostCharityFormsResponseData = {
  form_id: number;
};

export type PostCharityFormsError = {
  name: string[];
  overview: string[];
  description: string[];
  youtube_link: string[];
  youtube_link_order: string[];
  dark_mode: string[];
  primary_color: string[];
  secondary_color: string[];
  goal_enabled: string[];
  end_at_enabled: string[];
  goal_amount: string[];
  goal_fiat: string[];
  end_at: string[];
  pictures_logo_id: string[];
  pictures_content_ids: string[];
  networks_list: string[];
  non_field_errors?: string[];
} & FeatureFlagDisabledError;

export type CharityFormWithdrawPayload = {
  wallet_id: number;
  amount: string;
  currency: CurrencyEnum;
  network: NetworkEnum;
};

export type CharityFormWithdrawResponseData = {
  transaction_id: number;
};

export type CharityFormWithdrawResponseError = {
  wallet_id?: string[];
  amount?: string[];
  currency?: string[];
  network?: string[];
  non_field_errors?: string[];
} & FeatureFlagDisabledError;

export type CharityFormWithdrawEffectPayload = {
  wallet_id: number;
  amount: string;
  currency: CurrencyEnum;
  network: NetworkEnum;
};

export type CharityFormWithdrawEffectResponseData = {
  amount: string;
  currency: CurrencyEnum;
  network: NetworkEnum;
  balance_current: string;
  balance_remaining: string;
};

export type CharityFormWithdrawEffectResponseError = {
  wallet_id?: string[];
  amount?: string[];
  currency?: string[];
  network?: string[];
  non_field_errors?: string[];
} & FeatureFlagDisabledError;

export type CharityFormFinishRequest = {
  headers?: AxiosRequestHeaders;
};

export type CharityFormFinishResponseData = {
  status: string;
  detail: string;
  secret_key: string;
  uri: string;
};

export type CharityFormFinishResponseError = {
  status?: string[];
  detail?: string;
  secret_key?: string[];
  uri?: string[];
  non_field_errors?: string[];
} & FeatureFlagDisabledError;

const postCharityForms = (
  payload: PostCharityFormsPayload,
  headers?: AxiosRequestHeaders
) =>
  post<PostCharityFormsResponseData, PostCharityFormsPayload>(
    CHARITY_FORMS_URL,
    payload,
    { headers }
  );

export type PostCharityFormsRequest = {
  payload: PostCharityFormsPayload;
  headers?: AxiosRequestHeaders;
};

export const useCharityFormsMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<PostCharityFormsResponseData>,
    AxiosError<PostCharityFormsError>,
    PostCharityFormsRequest
  >(
    POST_CHARITY_FORMS_QUERY_KEY,
    ({ payload, headers }) => postCharityForms(payload, headers),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const postCharityFormPictures = async (
  payload: PostCharityFormPicturesPayload
) =>
  post<PostCharityFormPicturesResponseData, PostCharityFormPicturesPayload>(
    CHARITY_FORMS_PICTURES,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const useCharityFormsPicturesMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<
      PostCharityFormPicturesResponseData,
      PostCharityFormPicturesPayload
    >[],
    AxiosError<PostCharityFormsPicturesError>,
    PostCharityFormPicturesPayload[]
  >(
    POST_CHARITY_FORMS_PICTURES_KEY,
    (items: PostCharityFormPicturesPayload[]) =>
      Promise.all(items.map(postCharityFormPictures)),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

const getCharityForms = async () => {
  const response = await get<CharityFormRead[]>(CHARITY_FORMS_URL);
  return response.data;
};

export const useCharityForms = () =>
  useQuery<CharityFormRead[]>(CHARITY_FORMS_QUERY_KEY, getCharityForms);

const getCharityFormById = (id: number) =>
  get<CharityFormDetailed>(CHARITY_FORM_URL.replace('{id}', id.toString()));

export const useCharityForm = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('CHARITY_FORM');

  return useQuery<
    AxiosResponse<CharityFormDetailed>,
    AxiosError<FeatureFlagDisabledError>
  >(CHARITY_FORM_QUERY_KEY(id), () => getCharityFormById(id), {
    enabled: !!featureFlag.data?.enabled && !isNaN(id),
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

const getCharityFormDonations = (id: number) =>
  get<CharityFormDonations[]>(
    CHARITY_FORM_DONATIONS_URL.replace('{id}', id.toString())
  );

export const useCharityFormDonations = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('CHARITY_FORM');

  return useQuery<
    AxiosResponse<CharityFormDonations[]>,
    AxiosError<FeatureFlagDisabledError>
  >(CHARITY_FORM_DONATIONS_QUERY_KEY(id), () => getCharityFormDonations(id), {
    enabled: !!featureFlag.data?.enabled && !isNaN(id),
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const postWithdraw = (payload: CharityFormWithdrawPayload, id: number) =>
  post<CharityFormWithdrawResponseData, CharityFormWithdrawPayload>(
    CHARITY_FORMS_WITHDRAW_URL.replace('{id}', `${id}`),
    payload
  );

export const useCharityFormWithdrawMutation = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<CharityFormWithdrawResponseData>,
    AxiosError<CharityFormWithdrawResponseError>,
    CharityFormWithdrawPayload
  >(
    POST_CHARITY_FORM_WITHDRAW_QUERY_KEY(id),
    (payload) => postWithdraw(payload, id),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const postWithdrawEffect = (
  payload: CharityFormWithdrawEffectPayload,
  id: number
) =>
  post<CharityFormWithdrawEffectResponseData, CharityFormWithdrawEffectPayload>(
    CHARITY_FORMS_WITHDRAW_EFFECT_URL.replace('{id}', `${id}`),
    payload
  );

export const useCharityFormWithdrawEffectMutation = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<CharityFormWithdrawEffectResponseData>,
    AxiosError<CharityFormWithdrawEffectResponseError>,
    CharityFormWithdrawEffectPayload
  >(
    POST_CHARITY_FORM_WITHDRAW_EFFECT_QUERY_KEY(id),
    (payload) => postWithdrawEffect(payload, id),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const postFinish = (id: number, headers?: AxiosRequestHeaders) =>
  post<CharityFormFinishResponseData>(
    CHARITY_FORMS_FINISH_URL.replace('{id}', `${id}`),
    undefined,
    { headers }
  );

export const useCharityFormFinishMutation = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<CharityFormFinishResponseData>,
    AxiosError<CharityFormFinishResponseError>,
    CharityFormFinishRequest
  >(
    POST_CHARITY_FORM_FINISH_QUERY_KEY(id),
    ({ headers }) => postFinish(id, headers),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const endDateMustBeInFuture = (
  errResponseData?: PostCharityFormsError
) => {
  for (let err of errResponseData?.end_at || []) {
    const match = err.match(END_DATE_MUST_BE_IN_FUTURE);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const secondaryColorHasNoMoreThanSomeChars = (
  errResponseData?: PostCharityFormsError
) => {
  for (let err of errResponseData?.secondary_color || []) {
    const match = err.match(FIELD_HAS_NO_MORE_THAN_CHARS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const primaryColorHasNoMoreThanSomeChars = (
  errResponseData?: PostCharityFormsError
) => {
  for (let err of errResponseData?.primary_color || []) {
    const match = err.match(FIELD_HAS_NO_MORE_THAN_CHARS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const fromWithThisNameAlreadyExists = (
  errResponseData?: PostCharityFormsError
) => {
  for (let err of errResponseData?.name || []) {
    const match = err.match(FORM_WITH_THIS_NAME_ALREADY_EXISTS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const companyCanHaveFiveActiveForms = (
  errResponseData?: PostCharityFormsError
) => {
  for (let err of errResponseData?.non_field_errors || []) {
    const match = err.match(COMPANY_CAN_HAVE_FIVE_ACTIVE_FORMS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const notEnoughFunds = (
  errResponseData?:
    | CharityFormWithdrawEffectResponseError
    | CharityFormWithdrawResponseError
) => {
  for (let err of errResponseData?.amount || []) {
    const match = err.match(NOT_ENOUGH_FUNDS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const charityFormHasPandingWithdrawalTransaction = (
  errResponseData?:
    | CharityFormWithdrawEffectResponseError
    | CharityFormWithdrawResponseError
) => {
  for (let err of errResponseData?.non_field_errors || []) {
    const match = err.match(FORM_HAS_WITHDRAWAL_TRANSACTION);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const youDoNotHavePermission = (
  errResponseData?: CharityFormFinishResponseError
) => errResponseData?.detail?.match(ERR_YOU_HAVE_NO_PERMISSION);

export const charityFormAlreadyFinished = (
  errResponseData?: CharityFormFinishResponseError
) => {
  for (let err of errResponseData?.non_field_errors || []) {
    const match = err.match(ERR_FORM_ALREADY_FINISHED);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const END_DATE_MUST_BE_IN_FUTURE = 'End date must be in the future';
export const COMPANY_CAN_HAVE_FIVE_ACTIVE_FORMS =
  'Company can have max 5 forms in ACTIVE/CREATED state.';
export const FORM_WITH_THIS_NAME_ALREADY_EXISTS =
  'Form with this name already exists.';

const FIELD_HAS_NO_MORE_THAN_CHARS =
  /Ensure this field has no more than (\d+) characters./;

const NOT_ENOUGH_FUNDS = /Not enough funds./;

const FORM_HAS_WITHDRAWAL_TRANSACTION =
  /Form has withdrawal transaction in pending state./;

const ERR_YOU_HAVE_NO_PERMISSION =
  /You do not have permission to perform this action./;

const ERR_FORM_ALREADY_FINISHED = /Form already finished./;
