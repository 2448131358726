import { useQueryClient } from '@tanstack/react-query';
import { ReactComponent as ArrowheadDownGrey } from 'common/assets/black-icons/arrowhead-down-12px-withpadding-grey.svg';
import { ReactComponent as ArrowheadDownBlack } from 'common/assets/black-icons/arrowhead-down-12px-withpadding.svg';
import { Text } from 'common/components/Text';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { BORROWS_SUMMARY_QUERY_KEY } from 'modules/financial-ops/borrow/api';
import { LENDS_SUMMARY_QUERY_KEY } from 'modules/financial-ops/lend/api';
import { USER_QUERY_KEY, useUserMutation, useUserQuery } from 'modules/user';
import { useState } from 'react';
import styles from './FiatSwitch.module.scss';
import { FiatCurrencyEnum } from './types';
import { CURRENCY_SYMBOLS } from './utils';
import { BALANCE_QUERY_KEY } from 'modules/dashboard/section-balance-summary/api';
import { SAVINGS_SUMMARY_QUERY_KEY } from 'modules/financial-ops/savings-account/api';
import {
  CHARITY_FORMS_QUERY_KEY,
  CHARITY_FORM_ALL_DETAILS_QUERY_KEY,
  CHARITY_FORM_ALL_DONATIONS_QUERY_KEY,
} from 'modules/charity-forms/api';

type Props = {};

export const FiatSwitch: React.FC<Props> = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const { data, isLoading } = useUserQuery();

  const queryClient = useQueryClient();
  const userMutation = useUserMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(USER_QUERY_KEY);
      queryClient.invalidateQueries(BORROWS_SUMMARY_QUERY_KEY);
      queryClient.invalidateQueries(LENDS_SUMMARY_QUERY_KEY);
      queryClient.invalidateQueries(SAVINGS_SUMMARY_QUERY_KEY);
      queryClient.invalidateQueries(BALANCE_QUERY_KEY);
      queryClient.invalidateQueries(CHARITY_FORMS_QUERY_KEY);
      queryClient.invalidateQueries(CHARITY_FORM_ALL_DETAILS_QUERY_KEY);
      queryClient.invalidateQueries(CHARITY_FORM_ALL_DONATIONS_QUERY_KEY);

      queryClient.removeQueries({
        queryKey: ['GET', '/balance-measures'],
        exact: false,
      });
    },
  });

  return (
    <div className={styles.container}>
      <div
        className={styles.switch}
        onClick={() => setPopupOpen((prevState) => !prevState)}
      >
        {(isLoading || userMutation.isLoading) && (
          <RotatingLoader size={14} type='secondary' />
        )}
        {data && (
          <>
            <Text
              style='bold1417'
              colorStyle={popupOpen ? 'primary' : 'secondary'}
            >
              {CURRENCY_SYMBOLS[data.data.fiat_currency]}
            </Text>
            {popupOpen ? <ArrowheadDownBlack /> : <ArrowheadDownGrey />}
          </>
        )}
      </div>
      {popupOpen && (
        <div className={styles.popup}>
          {Object.entries(CURRENCY_SYMBOLS).map(([currency, symbol]) => (
            <button
              key={currency}
              onClick={() => {
                userMutation.mutate(
                  {
                    fiat_currency: currency as FiatCurrencyEnum,
                  },
                  {
                    onSettled: () => {
                      setPopupOpen(false);
                    },
                  }
                );
              }}
              className={styles.button}
            >
              <Text
                style='light1215'
                colorStyle='primary'
              >{`${symbol} (${currency})`}</Text>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
