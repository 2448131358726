import clsx from 'clsx';
import { ModalContext } from './context';
import styles from './ModalLike.module.scss';

type Props = { className?: string; children: React.ReactNode };

export const ModalLike: React.FC<Props> = ({ className, children }) => {
  return (
    <ModalContext.Provider value={{ onClose: () => {}, smallModal: false }}>
      <div className={styles.background}>
        <div className={clsx(styles.contentContainer, className)}>
          {children}
        </div>
      </div>
    </ModalContext.Provider>
  );
};
