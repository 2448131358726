import { Text } from 'common/components/Text';
import { ReactComponent as FalseIcon } from 'common/assets/black-icons/false-5px.svg';
import { ReactComponent as OkIcon } from 'common/assets/black-icons/ok-8px.svg';
import { useTransactionActionsQuery } from 'modules/transactions/api';
import { useFormatMessage } from 'modules/messages';
import { Loader } from 'common/components/loader';

type Props = {
  id: number;
};

export const MultiapprovalList: React.FC<Props> = ({ id }) => {
  const formatMessage = useFormatMessage();
  const { data, isLoading } = useTransactionActionsQuery(id);

  return (
    <>
      {data && !!data.length && (
        <div>
          <Text style='light1215' inlineStyle={{ display: 'block' }}>
            {formatMessage('transactions.multiapprovalList') + ':'}
          </Text>
          {data.map((action, i) => (
            <div key={i}>
              {action.action === 'REJECT' && <FalseIcon />}
              {action.action === 'APPROVE' && <OkIcon />}&nbsp;
              <Text style='light1215'>
                {action.user.name} {action.user.surname}
              </Text>
            </div>
          ))}
        </div>
      )}
      {isLoading && <Loader size={4} />}
    </>
  );
};
