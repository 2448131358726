import { NetworkEnum } from 'common/types';
import { descCmpAmounts } from 'common/utils/comparators';
import { GetWalletsResponseData } from 'modules/assets-and-wallets/api';
import {
  BaseCurrency,
  CurrencyWallet,
} from 'modules/select-currency-wallet/types';
import {
  Instrument,
  InstrumentCurrency,
  InstrumentLimits,
  TradeOptions,
} from './types';

export const getUniqueMainNetworks = (
  instruments?: Instrument[],
  wallets?: GetWalletsResponseData
) => {
  if (instruments) {
    const tmp: InstrumentCurrency[] = [];
    instruments.forEach((item, i) => {
      const justAdded = tmp.findIndex(
        (val) => val.currency === item.currency_1
      );
      if (justAdded === -1) {
        const instrument = instruments.filter(
          (inst) => inst.currency_1 === item.currency_1
        );

        const obj: InstrumentCurrency = {
          currency: instrument[0].currency_1,
          network: instrument[0].currency_1_network,
          id: instrument[0].id,
          pairs: [
            {
              currency: instrument[0].currency_2,
              id: instrument[0].id,
              lot_size: instrument[0].lot_size,
            },
          ],
          wallets: [],
        };

        const spendWallets = getWalletsForCurrency(wallets, obj);
        obj.wallets = [...spendWallets];
        tmp.push(obj);
      } else {
        tmp[justAdded].pairs?.push({
          currency: item.currency_2,
          id: item.id,
          lot_size: item.lot_size,
        });
      }
    });
    return tmp;
  }
  return [];
};

export const getUniquePairNetworks = (
  instruments?: Instrument[],
  network?: NetworkEnum,
  wallets?: GetWalletsResponseData
) => {
  if (instruments && network) {
    const tmp: InstrumentCurrency[] = [];

    instruments
      .filter((item) => network === item.currency_1_network)
      .forEach((item, i) => {
        const justAdded = tmp.findIndex(
          (val) => val.currency === item.currency_2
        );
        if (justAdded === -1) {
          const instrument = instruments.filter(
            (inst) => inst.currency_2 === item.currency_2
          );

          const obj: InstrumentCurrency = {
            currency: instrument[0].currency_2,
            network: instrument[0].currency_2_network,
            id: instrument[0].id,
            wallets: [],
            pairs: [
              {
                currency: instrument[0].currency_1,
                id: instrument[0].id,
                lot_size: instrument[0].lot_size,
              },
            ],
          };
          const spendWallets = getWalletsForCurrency(wallets, obj);
          obj.wallets = [...spendWallets];
          tmp.push(obj);
        } else {
          tmp[justAdded].pairs?.push({
            currency: item.currency_1,
            id: item.id,
            lot_size: item.lot_size,
          });
        }
      });
    return tmp;
  }
  return [];
};

export const getWalletsForCurrency = (
  wallets?: GetWalletsResponseData,
  currency?: BaseCurrency
): CurrencyWallet[] =>
  wallets && currency
    ? wallets
        .filter((wallet) => wallet.network === currency.network)
        .map((wallet) => {
          return {
            name: wallet.name,
            address: wallet.address,
            id: wallet.id,
            balance:
              wallet.balances.find(
                (balance) => balance.currency === currency.currency
              )?.amount ?? '0',
          };
        })
        .sort((a, b) => descCmpAmounts(a.balance, b.balance))
    : [];

export const getLimitsForOption = (
  option: TradeOptions,
  limits?: InstrumentLimits[]
) => limits?.find((limit) => limit.side === option);
