import clsx from 'clsx';
import { useEffect } from 'react';
import { ModalContext } from './context';
import styles from './Modal.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  dataTest?: string;
  isOpen: boolean;
  smallModal?: boolean;
  onClose: () => void;
  id?: string;
};

export const Modal: React.FC<Props> = ({
  children,
  className,
  dataTest,
  isOpen,
  onClose,
  smallModal = false,
  id,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.bodyScrollLock);
    } else {
      document.body.classList.remove(styles.bodyScrollLock);
    }
  }, [isOpen]);

  return (
    <ModalContext.Provider value={{ onClose, smallModal }}>
      {isOpen && (
        <div className={styles.background}>
          <div
            className={clsx(
              styles.contentContainer,
              smallModal && styles.smallModal,
              className
            )}
            id={id}
            onClick={(e) => {
              e.stopPropagation();
            }}
            data-test={dataTest}
          >
            {children}
          </div>
        </div>
      )}
    </ModalContext.Provider>
  );
};
