import { useEffect, useState } from 'react';
import { Timer } from 'common/components/Timer';

type Props = {
  expirationDate: string;
  creationDate: string;
  onTimeEnd: () => void;
};

export const TransactionExpiryTimer: React.FC<Props> = ({
  expirationDate,
  creationDate,
  onTimeEnd,
}) => {
  const [value, setValue] = useState(0);
  const [text, setText] = useState('0');
  const [lessThanOneHour, setLessThanOneHour] = useState(false);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    getMaxValue();
    setValue(0);
    setText('0');
    setLessThanOneHour(false);
    const intervalId = setInterval(() => getValue(), 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [expirationDate]);

  const getMaxValue = () => {
    const expiration = new Date(expirationDate);
    const creation = new Date(creationDate);

    const timeDiff = expiration.getTime() - creation.getTime();
    const secDiff = timeDiff / 1000;

    setMaxValue(Math.abs(secDiff) / 60);
  };

  const getValue = () => {
    const expiration = new Date(expirationDate);
    const now = new Date();

    if (expiration < now) {
      setValue(0);
      setText('0');
      setLessThanOneHour(false);
      onTimeEnd();
      return;
    }

    const timeDiff = expiration.getTime() - now.getTime();
    const secDiff = timeDiff / 1000;
    const minDiff = Math.abs(secDiff) / 60;
    const hDiff = minDiff / 60;

    setValue(minDiff);
    setText(
      minDiff > 59
        ? `${hDiff.toFixed()}h`
        : `0:${minDiff < 9 ? `0${minDiff.toFixed()}` : minDiff.toFixed()}`
    );
    setLessThanOneHour(minDiff > 59 ? false : true);
  };

  return (
    <Timer
      value={value}
      text={text}
      maxValue={maxValue}
      darkBackgroundTimerPath={lessThanOneHour}
    />
  );
};
