import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Threshold } from './Threshold';
import { Text } from 'common/components/Text';
import { generateTextFromMembers } from '../utils';
import { Member } from 'modules/company-members/api';

type Props = {
  threshold: number;
  onClose: () => void;
  selectedMembers: Member[];
};

export const StepSuccess: React.FC<Props> = ({
  threshold,
  onClose,
  selectedMembers,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('multiapproval.changeMultiapprovalSuccess')}
      </ModalHeader>
      <ModalBody>
        <Threshold current={threshold} max={selectedMembers.length} />
        <div style={{ marginTop: '40px' }}>
          <Text style='bold1417'>
            {formatMessage('common.multiapprovalChosenMembers', {
              chosenMembers: selectedMembers.length,
            })}
          </Text>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Text style='light1417'>
            {generateTextFromMembers(selectedMembers)}
          </Text>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            type='dark'
            name={formatMessage('common.button.close')}
            onClick={onClose}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
