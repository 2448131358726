import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { NotificationDot } from 'common/components/NotificationDot';
import { Text } from 'common/components/Text';
import { formatDatetime } from 'common/utils/utils';
import { CryptoToFiatAmount } from 'modules/fiat';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { Transaction } from 'modules/transactions/types';
import { useIsOwner, useUserQuery } from 'modules/user';
import {
  NOTIFICATIONS_PREVIEW_QUERY_KEY,
  UNREAD_COUNT_NOTIFICATIONS_QUERY_KEY,
  useMarkAsReadNotificationMutation,
} from '../api';
import { EventKind, Notification } from '../types';
import {
  generateDetailsPath,
  generateNotificationText,
  generateDescription,
} from '../utils';
import { DetailsButton } from './DetailsButton';
import styles from './NotificationBox.module.scss';

export const NotificationsName: React.FC<{
  kind: EventKind;
  notification: Notification;
}> = ({ kind, notification }) => {
  const formatMessage = useFormatMessage();

  const { data: isOwner } = useIsOwner();
  const { data: userData } = useUserQuery();

  const text = generateNotificationText(
    formatMessage,
    kind,
    notification.event,
    isOwner,
    userData?.data
  );

  const description = generateDescription(notification.event, formatMessage);

  return (
    <div className={styles.notificationName}>
      {!notification.is_read && <NotificationDot />}
      <div className={styles.notificationNameText}>
        <Text style={notification.is_read ? 'bold1619' : 'xxbold1619'}>
          {text?.header}
        </Text>
        {description && <Text style='light1421'>{description}</Text>}
        <Text style='light1421'>{text?.subheader}</Text>
      </div>
    </div>
  );
};

export const NotificationsDate: React.FC<{
  date: string;
}> = ({ date }) => {
  return (
    <Text style={'light1425'} colorStyle='secondary'>
      {formatDatetime(date)}
    </Text>
  );
};

export const NotificationsAmount: React.FC<{
  transaction: Transaction;
  isSmall: boolean;
  isRead: boolean;
}> = ({ transaction, isSmall, isRead }) => {
  const { currency, amount, currency_rate } = transaction;

  return (
    <div
      className={clsx(
        isSmall && !isRead && styles.boxMarginSmall,
        !isRead && styles.boxMarginDesktop,
        !isSmall && styles.alignRight
      )}
    >
      <div>
        <Text style='bold1622'>{`${toLimitedPrec(
          toFixed(amount)
        )} ${currency}`}</Text>
      </div>
      <CryptoToFiatAmount
        amount={amount}
        currency={currency}
        currencyRate={currency_rate}
      />
    </div>
  );
};

export const NotificationBox: React.FC<{
  notification: Notification;
  isSmall?: boolean;
  markAsReadOnClick?: (id: number) => void;
}> = ({ notification, isSmall = true, markAsReadOnClick }) => {
  const { mutate } = useMarkAsReadNotificationMutation();
  const queryClient = useQueryClient();

  const onClick = () => {
    if (!notification.is_read)
      mutate(notification.id, {
        onSuccess: () => {
          queryClient.invalidateQueries(NOTIFICATIONS_PREVIEW_QUERY_KEY);
          queryClient.invalidateQueries(UNREAD_COUNT_NOTIFICATIONS_QUERY_KEY);
          if (markAsReadOnClick) {
            markAsReadOnClick(notification.id);
          }
        },
      });
  };

  const path = generateDetailsPath(notification.event);

  return (
    <div className={styles.mainContainer} onClick={onClick}>
      <div className={clsx(styles.container, isSmall && styles.smallMenu)}>
        <NotificationsName
          notification={notification}
          kind={notification.event.kind}
        />
        {notification.event.transaction ? (
          <NotificationsAmount
            transaction={notification.event.transaction}
            isSmall={isSmall}
            isRead={notification.is_read}
          />
        ) : (
          <div></div>
        )}
        <div
          className={clsx(
            isSmall && !notification.is_read && styles.boxMarginSmall,
            !notification.is_read && styles.boxMarginDesktop,
            !isSmall && styles.alignRight
          )}
        >
          <NotificationsDate date={notification.created_at} />
        </div>
        <div className={styles.details}>
          {path && <DetailsButton path={path} />}
        </div>
      </div>
    </div>
  );
};
