import { Text } from 'common/components/Text';
import styles from './LinkBox.module.scss';
import { ReactComponent as Copy } from 'common/assets/black-icons/copy.svg';
import { copyToClipboard } from 'common/utils/utils';
import { openSnackbar } from 'common/components/snackbar';
import { useFormatMessage } from 'modules/messages';
import clsx from 'clsx';

type Props = { link: string; text?: string; clickable?: boolean };

export const LinkBox: React.FC<Props> = ({ link, text, clickable = false }) => {
  const formatMessage = useFormatMessage();

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    copyToClipboard(link);
    openSnackbar(
      text || formatMessage('charityForms.linkCopiedToClipboard'),
      'success'
    );
  };

  return (
    <div className={styles.container}>
      <Text
        style='light1425'
        className={clsx(styles.linkText, clickable && styles.clickable)}
        onClick={() => {
          if (clickable) {
            window.open(link, '_blank');
          }
        }}
      >
        {link}
      </Text>
      <Copy className={styles.iconCopy} onClick={handleClick} />
    </div>
  );
};
