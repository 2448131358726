import { Alert } from 'common/components/Alert';
import { Button, BackButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import styles from './StepSummary.module.scss';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  SnapshotAlert,
  TokenExpiryTimer,
  WalletBox,
  tokenInvalidValue,
  tokenIsRequired,
} from 'modules/financial-ops/common';
import { getInterestAccrualDate } from '../../helpers';
import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { Text } from 'common/components/Text';
import { AmountReadOnly } from '../../common/AmountReadOnly';
import { SectionSubheader } from 'common/components/SectionSubheader';
import {
  SAVINGS_LIMITS_QUERY_KEY,
  SavingWithdrawEffectPayload,
  SavingWithdrawPayload,
  SavingWithdrawResponseError,
  amountExceedsSavingBalance,
  amountMustBeGreaterThanZero,
  amountTooBigDueToPendingTransactions,
  existPendingWithdrawalTransactionsAgainstFullBalance,
  savingHasTopupTransactionInPendingState,
  savingHasWithdrawalTransactionInPendingState,
  savingIsNotActive,
  useWithdrawEffectMutation,
  useWithdrawMutation,
} from '../../api';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { prettifyError } from 'common/utils/prettify-error';
import { WithdrawEffectResponseError } from 'modules/withdraw/api';

type Props = {
  amount: string;
  onGoBack: () => void;
  onGoNext: (transactionId: number) => void;
  currency?: Currency;
  wallet?: CurrencyWallet;
  initialRemainingFunds?: string;
  initialNewDailyEarn?: string;
  interestTotal: string;
  remainingFundsInfo: string;
  id: number;
  initialToken: string | undefined;
};

export const StepSummary: React.FC<Props> = ({
  amount,
  onGoBack,
  onGoNext,
  currency,
  wallet,
  initialRemainingFunds,
  initialNewDailyEarn,
  interestTotal,
  remainingFundsInfo,
  id,
  initialToken,
}) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const [timeExpired, setTimeExpired] = useState(false);

  const withdrawEffectMutation = useWithdrawEffectMutation(id);
  const withdrawMutation = useWithdrawMutation(id);

  const refetchToken = () => {
    if (!wallet) {
      return;
    }

    const payload: SavingWithdrawEffectPayload = {
      wallet_id: wallet.id,
      amount: toFixed(amount),
      summary: true,
    };

    withdrawEffectMutation.mutate(payload, {
      onSettled: (res) => {
        queryClient.invalidateQueries(SAVINGS_LIMITS_QUERY_KEY);
      },
    });
  };

  const getErrorText = (error: AxiosError<SavingWithdrawResponseError>) => {
    if (amountTooBigDueToPendingTransactions(error.response?.data)) {
      return formatMessage(
        'saving.dueToPendingTransactionMaximumAmountChanged'
      );
    }

    if (
      existPendingWithdrawalTransactionsAgainstFullBalance(error.response?.data)
    ) {
      return formatMessage(
        'saving.transactionForClosingSavingsAccountAlreadyInitiated'
      );
    }

    if (savingIsNotActive(error.response?.data)) {
      return formatMessage('saving.savingHasBeenClosedWithdrawIsNotAvailable');
    }

    if (savingHasWithdrawalTransactionInPendingState(error.response?.data)) {
      return formatMessage(
        'saving.savingHasWithdrawalTransactionInPendingState'
      );
    }

    if (savingHasTopupTransactionInPendingState(error.response?.data)) {
      return formatMessage('saving.savingHasTopupTransactionInPendingState');
    }

    if (tokenIsRequired(error.response?.data)) {
      return formatMessage('common.tokenIsRequired');
    }

    return prettifyError(error);
  };

  const getRefetchTokenError = (
    error: AxiosError<WithdrawEffectResponseError>
  ) => {
    const amountExceedsSavingBalanceMatch = amountExceedsSavingBalance(
      error.response?.data
    );

    if (amountExceedsSavingBalanceMatch) {
      return formatMessage('saving.withdrawAmountMustBeLessOrEqual', {
        amount: toFixed(amountExceedsSavingBalanceMatch[1]),
        currency: currency?.currency,
      });
    }

    const amountTooBigDueToPendingTransactionsMatch =
      amountTooBigDueToPendingTransactions(error.response?.data);

    if (amountTooBigDueToPendingTransactionsMatch) {
      return formatMessage(
        'saving.dueToPendingWithdrawalTransactionsMaximumAmount',
        {
          amount: toFixed(amountTooBigDueToPendingTransactionsMatch[1]),
          currency: currency?.currency,
        }
      );
    }

    if (amountMustBeGreaterThanZero(error.response?.data)) {
      return formatMessage('inputAmount.error.amountMustBeGreaterThanZero');
    }

    if (
      existPendingWithdrawalTransactionsAgainstFullBalance(error.response?.data)
    ) {
      return formatMessage(
        'saving.transactionForClosingSavingsAccountAlreadyInitiated'
      );
    }

    if (savingHasWithdrawalTransactionInPendingState(error.response?.data)) {
      return formatMessage(
        'saving.savingHasWithdrawalTransactionInPendingState'
      );
    }

    if (savingHasTopupTransactionInPendingState(error.response?.data)) {
      return formatMessage('saving.savingHasTopupTransactionInPendingState');
    }

    if (savingIsNotActive(error.response?.data)) {
      return formatMessage('saving.savingHasBeenClosedWithdrawIsNotAvailable');
    }

    return prettifyError(error);
  };

  const modalHeader = (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('common.reviewAndConfirmWithdraw')}
          </Text>
        }
      >
        {formatMessage('common.withdrawFundsSummary')}
      </ModalHeader>
    </>
  );

  const isSummaryDataComplete =
    currency &&
    wallet &&
    initialToken &&
    initialNewDailyEarn &&
    initialRemainingFunds;
  if (!isSummaryDataComplete) {
    return (
      <>
        {modalHeader}
        <ModalBody>
          <Alert
            severity='error'
            text={formatMessage('saving.summaryCannotBeDisplayed')}
          />
        </ModalBody>
      </>
    );
  }

  const token = withdrawEffectMutation.data?.data.token || initialToken;
  const newDailyEarn =
    withdrawEffectMutation.data?.data.interest_daily || initialNewDailyEarn;
  const remainingFunds =
    withdrawEffectMutation.data?.data.amount_target || initialRemainingFunds;

  return (
    <>
      {modalHeader}
      <ModalBody>
        {withdrawMutation.isError &&
          !tokenInvalidValue(withdrawMutation.error.response?.data) && (
            <Alert
              severity='error'
              text={getErrorText(withdrawMutation.error)}
            />
          )}
        {withdrawEffectMutation.error && (
          <Alert
            severity='error'
            text={getRefetchTokenError(withdrawEffectMutation.error)}
            className={styles.error}
          />
        )}
        {amount && currency && (
          <>
            <SectionSubheader>
              {formatMessage('common.amount')}
            </SectionSubheader>
            <AmountReadOnly
              amount={amount}
              currency={currency}
              className={styles.amountAssetBox}
            />
          </>
        )}
        {wallet && currency && (
          <WalletBox
            wallet={wallet}
            currency={currency.currency}
            className={styles.wallet}
          />
        )}
        {toDecimal(remainingFunds).eq(0) ? (
          <>
            <Alert
              text={formatMessage('saving.savingsAccountWillBeClosed')}
              severity='secondary'
            />
            <Summary>
              <SummaryLabel textStyle='xxbold1425'>
                {formatMessage('finopsCommon.totalEarn')}
              </SummaryLabel>
              <SummaryValue textStyle='xxbold1425'>{`${toLimitedPrec(
                toFixed(interestTotal)
              )} ${currency?.currency}`}</SummaryValue>
            </Summary>
          </>
        ) : (
          <Summary>
            <SummaryLabel>
              {remainingFundsInfo ? (
                <LabelWithInfoTooltip
                  text={formatMessage('common.remainingFunds')}
                  textStyle={'light1215'}
                  tooltipText={remainingFundsInfo}
                />
              ) : (
                formatMessage('common.remainingFunds')
              )}
            </SummaryLabel>
            <SummaryValue>{`${toLimitedPrec(toFixed(remainingFunds))} ${
              currency?.currency
            }`}</SummaryValue>
            <SummaryLabel>{formatMessage('saving.newDailyEarn')}</SummaryLabel>
            <SummaryValue>{`${toLimitedPrec(toFixed(newDailyEarn))} ${
              currency?.currency
            }`}</SummaryValue>
            <SummaryLabel>
              <LabelWithInfoTooltip
                text={formatMessage('saving.expectedInterestAccrual')}
                textStyle={'light1215'}
                tooltipText={formatMessage(
                  'saving.interestsAreCalculatedEvery24HoursWithdraw'
                )}
              />
            </SummaryLabel>
            <SummaryValue>{getInterestAccrualDate()}</SummaryValue>
          </Summary>
        )}
        {(tokenInvalidValue(withdrawEffectMutation.error?.response?.data) ||
          timeExpired) && (
          <SnapshotAlert
            onRefresh={() => {
              refetchToken();
              setTimeExpired(false);
              withdrawMutation.reset();
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            type='dark'
            onClick={() => {
              if (!wallet) {
                return;
              }

              const payload: SavingWithdrawPayload = {
                amount,
                wallet_id: wallet.id,
                token,
              };

              withdrawMutation.mutate(payload, {
                onSuccess: (res) => {
                  onGoNext(res.data.transaction_id);
                },
              });
            }}
            name={
              <div className={styles.button}>
                <Text style='xbold1417'>
                  {formatMessage('common.withdraw')}
                </Text>
                {!withdrawEffectMutation.error && !withdrawMutation.error && (
                  <TokenExpiryTimer
                    token={token}
                    onTimeExpired={() => setTimeExpired(true)}
                  />
                )}
              </div>
            }
            isLoading={withdrawMutation.isLoading}
            disabled={
              withdrawMutation.isLoading ||
              timeExpired ||
              !!withdrawEffectMutation.error ||
              !!withdrawMutation.error
            }
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
