import { useFormatMessage } from 'modules/messages';
import styles from './AutomaticCollateralTopup.module.scss';
import { Text } from 'common/components/Text';
import { ReactComponent as AutomaticTopupIcon } from '../assets/automatic-topup.svg';
import { InfoTooltip } from 'common/components/InfoTooltip';
import { Switch } from 'common/components/Switch';

type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
  className?: string;
};

export const AutomaticCollateralTopup: React.FC<Props> = ({
  checked,
  onChange,
  className,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={className}>
      <div className={styles.header}>
        <div className={styles.title}>
          <AutomaticTopupIcon />
          <Text style='light1425'>
            {formatMessage('borrow.automaticCollateralTopup')}
          </Text>
          <InfoTooltip
            text={formatMessage(
              'borrow.amountTransferredThroughAutomiaticTopup'
            )}
          />
        </div>
        <Switch
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
      </div>
      <Text style='light1215' colorStyle='secondary'>
        {formatMessage('borrow.thisFeatureTransfersFundsFormCollateralWallet')}
      </Text>
    </div>
  );
};
