import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { TextStyle } from 'common/components/Text';
import { FiatAmount } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import { LendsSummary } from '../../api';

type Props = {
  lendSummary?: LendsSummary;
  isLoading?: boolean;
  textStyle?: TextStyle;
};

export const RemainingInterestToEarn: React.FC<Props> = ({
  lendSummary,
  isLoading,
  textStyle = 'xxbold3048',
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <LabelWithInfoTooltip
        text={formatMessage('lend.remainingInterestToEarn')}
        textStyle={'light1215'}
      />
      <FiatAmount
        amount={lendSummary?.expected_profit_fiat}
        isDataLoading={isLoading}
        loaderContainerSize={48}
        loaderSize={30}
        textColorStyle='primary'
        textStyle={textStyle}
      />
    </div>
  );
};
