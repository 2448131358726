import { useFormatMessage } from 'modules/messages';
import styles from './LtvRatio.module.scss';
import { Text } from 'common/components/Text';
import { toFixed } from 'modules/input-amount';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';

type Props = {
  newRatio?: string;
  currentRatio?: string;
  isLoading: boolean;
};

export const LtvRatio: React.FC<Props> = ({
  newRatio,
  currentRatio,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Text style={'light1421'} inlineStyle={{ display: 'block' }}>
          {formatMessage('borrow.newLtvRatio')}
        </Text>
        <Text style={'light1421'}>
          {isLoading ? (
            <RotatingLoader size={12} type={'secondary'} />
          ) : newRatio ? (
            `${toFixed(newRatio)}%`
          ) : (
            '-'
          )}
        </Text>
      </div>
      <div className={styles.row}>
        <Text style={'light1421'} colorStyle='secondary'>
          {formatMessage('borrow.currentLtvRatio')}
        </Text>
        <Text style={'light1421'} colorStyle='secondary'>
          {currentRatio ? `${toFixed(currentRatio)}%` : '-'}
        </Text>
      </div>
    </div>
  );
};
