import { Alert } from 'common/components/Alert';
import { Button, BackButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import styles from './StepSummary.module.scss';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { AssetAmountReadOnly, WalletBox } from 'modules/financial-ops/common';
import { Text } from 'common/components/Text';
import { SectionSubheader } from 'common/components/SectionSubheader';
import {
  CHARITY_FORMS_QUERY_KEY,
  CHARITY_FORM_QUERY_KEY,
  CharityFormWithdrawEffectResponseError,
  CharityFormWithdrawPayload,
  charityFormHasPandingWithdrawalTransaction,
  notEnoughFunds,
  useCharityFormWithdrawMutation,
} from '../../api';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { prettifyError } from 'common/utils/prettify-error';
import { useCharityFormWithdrawContext } from '../context';

type Props = {
  amount: string;
  onGoBack: () => void;
  onGoNext: (transactionId: number) => void;
  currency?: Currency;
  wallet?: CurrencyWallet;
  remainingFunds?: string;
  id: number;
};

export const StepSummary: React.FC<Props> = ({
  amount,
  onGoBack,
  onGoNext,
  currency,
  wallet,
  remainingFunds,
  id,
}) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();
  const context = useCharityFormWithdrawContext();

  const withdrawMutation = useCharityFormWithdrawMutation(id);

  const getErrorText = (
    error: AxiosError<CharityFormWithdrawEffectResponseError>
  ) => {
    if (notEnoughFunds(error.response?.data)) {
      return formatMessage('charityForms.notEnoughFundsToWithdraw');
    }

    if (charityFormHasPandingWithdrawalTransaction(error.response?.data)) {
      return formatMessage(
        'charityForms.charityFormHasWithdrawalTransactionInPendingState'
      );
    }

    return prettifyError(error);
  };

  return (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('common.reviewAndConfirmWithdraw')}
          </Text>
        }
      >
        {formatMessage('common.withdrawFundsSummary')}
      </ModalHeader>
      <ModalBody>
        {withdrawMutation.isError && (
          <Alert severity='error' text={getErrorText(withdrawMutation.error)} />
        )}
        {amount && currency && (
          <>
            <SectionSubheader>
              {formatMessage('common.amount')}
            </SectionSubheader>
            <AssetAmountReadOnly amount={amount} currency={currency} />
          </>
        )}
        {wallet && currency && (
          <WalletBox
            wallet={wallet}
            currency={currency.currency}
            className={styles.wallet}
          />
        )}
        <Summary>
          <SummaryLabel>{formatMessage('common.remainingFunds')}</SummaryLabel>
          <SummaryValue>
            {withdrawMutation.isError || !remainingFunds
              ? '-'
              : `${toLimitedPrec(toFixed(remainingFunds))} ${
                  currency?.currency
                }`}
          </SummaryValue>
        </Summary>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            type='dark'
            onClick={() => {
              if (!wallet || !currency) {
                return;
              }

              const payload: CharityFormWithdrawPayload = {
                amount,
                wallet_id: wallet.id,
                currency: currency?.currency,
                network: currency.network,
              };

              withdrawMutation.mutate(payload, {
                onSuccess: (res) => {
                  onGoNext(res.data.transaction_id);
                  queryClient.invalidateQueries(CHARITY_FORM_QUERY_KEY(id));
                },
                onError: (error) => {
                  context.setRemainingFunds(undefined);
                  queryClient.invalidateQueries(CHARITY_FORM_QUERY_KEY(id));

                  if (notEnoughFunds(error.response?.data)) {
                    context.setAmountError(
                      formatMessage('charityForms.notEnoughFundsToWithdraw')
                    );

                    if (context.charityForm?.id) {
                      queryClient.invalidateQueries(
                        CHARITY_FORM_QUERY_KEY(context.charityForm.id)
                      );
                      queryClient.invalidateQueries(CHARITY_FORMS_QUERY_KEY);
                    }
                  }

                  if (
                    charityFormHasPandingWithdrawalTransaction(
                      error.response?.data
                    )
                  ) {
                    context.setErrorAlert(
                      formatMessage(
                        'charityForms.charityFormHasWithdrawalTransactionInPendingState'
                      )
                    );
                  }
                },
              });
            }}
            name={formatMessage('common.withdraw')}
            isLoading={withdrawMutation.isLoading}
            disabled={withdrawMutation.isLoading || !!withdrawMutation.error}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
