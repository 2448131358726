import clsx from 'clsx';
import styles from './Section.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const Section: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={clsx(styles.outerContainer, className)}>
      <div className={clsx(styles.container)}>{children}</div>
    </div>
  );
};
