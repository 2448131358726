import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormatMessage } from 'modules/messages';
import { NavigationLayout } from 'modules/layout';
import { buildCharityFormEmbeddedPageUrl } from 'modules/charity-forms-public';

export const CharityFormIframeTestpage: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.testpage')}</title>
      </Helmet>
      <NavigationLayout>
        <CharityFormIframeTest />
      </NavigationLayout>
    </>
  );
};

const CharityFormIframeTest: React.FC<{}> = () => {
  const [token, setToken] = useState('');
  const [height, setHeight] = useState(1024);
  const [width, setWidth] = useState(768);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '20px',
        gap: '10px',
      }}
    >
      <div>
        <label htmlFor='token'>Charity form token:</label>
        <input
          name='token'
          type='text'
          value={token}
          onChange={(event) => setToken(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor='height'>Iframe height:</label>
        <input
          name='height'
          type='number'
          value={height}
          onChange={(event) => setHeight(parseInt(event.target.value))}
        />
      </div>
      <div>
        <label htmlFor='width'>Iframe width:</label>
        <input
          name='width'
          type='number'
          value={width}
          onChange={(event) => setWidth(parseInt(event.target.value))}
        />
      </div>
      {token && (
        <iframe
          src={buildCharityFormEmbeddedPageUrl(token)}
          style={{
            height: `${height}px`,
            width: `${width}px`,
            border: '0px',
          }}
        />
      )}
    </div>
  );
};
