import { OverviewField } from 'modules/financial-ops/common/components/overview-components';
import { useLendsSummaryQuery } from '../../api';
import { FundsLockedInLends } from './FundsLockedInLends';
import { LendBubbleChart } from './LendBubbleChart';

type Props = {};

export const CurrentFunds: React.FC<Props> = () => {
  const { data, isLoading } = useLendsSummaryQuery();

  return (
    <>
      <OverviewField>
        <FundsLockedInLends
          amount={data?.current_principal_amount_fiat}
          isLoading={isLoading}
        />
      </OverviewField>
      <LendBubbleChart
        assets={data?.current_principal_assets}
        id='lend-overview-bubble-chart'
      />
    </>
  );
};
