import styles from './StepTopup.module.scss';
import { Button, CancelButton } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { TopupEffect } from './TopupEffect';
import { Saving } from '../../types';
import { Loader } from 'common/components/loader';
import { Alert } from 'common/components/Alert';
import { Text } from 'common/components/Text';
import { AmountInput } from '../../common';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { useTopupContext } from '../context';

type Props = {
  amount: string;
  errorAmount: string;
  onSummary: () => void;
  currency?: Currency;
  wallets?: CurrencyWallet[];
  wallet?: CurrencyWallet;
  errorWallet: string;
  saving?: Saving;
  newLockedAmount?: string;
  newDailyEarn?: string;
  amountInfo: string;
  onCancel: () => void;
  onAmountChange: (amount: string) => void;
  onMax: () => void;
  onWalletChange: (wallet: CurrencyWallet) => void;
  isLoading: boolean;
};

export const StepTopup: React.FC<Props> = ({
  amount,
  errorAmount,
  onSummary,
  wallets,
  currency,
  wallet,
  errorWallet,
  saving,
  newLockedAmount,
  newDailyEarn,
  amountInfo,
  onCancel,
  onMax,
  onAmountChange,
  onWalletChange,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();
  const context = useTopupContext();

  return (
    <>
      <ModalHeader
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('saving.topupSavingsAccount')}
          </Text>
        }
      >
        {formatMessage('saving.topUpFunds')}
      </ModalHeader>
      <ModalBody>
        {currency && wallet && saving && (
          <>
            {context.errorAlert && (
              <Alert
                text={context.errorAlert}
                severity='error'
                className={styles.alert}
              />
            )}
            <SectionSubheader>
              {formatMessage('common.amount')}
            </SectionSubheader>
            <AmountInput
              amount={amount}
              currency={currency}
              wallets={wallets}
              error={errorAmount}
              wallet={wallet}
              walletError={errorWallet}
              onAmountChange={(value) => {
                context.setErrorAlert('');
                context.setAmountInfo('');

                onAmountChange(value);
                context.setAmount(value);
              }}
              onWalletChange={(value) => {
                context.setErrorAlert('');
                context.setWalletId(value.id);

                onWalletChange(value);
              }}
              onMax={() => {
                onMax();
              }}
              info={amountInfo}
              onError={(error) => context.setAmountError(error)}
            />
            <TopupEffect
              currency={currency}
              amount={amount}
              saving={saving}
              newDailyEarn={newDailyEarn}
              newLockedAmount={newLockedAmount}
              isLoading={isLoading}
            />
          </>
        )}
        {!currency && <Loader />}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <Button
            type='dark'
            onClick={onSummary}
            name={formatMessage('common.goToSummary')}
            disabled={!!context.errorAlert || isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
