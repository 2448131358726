import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './SendAgainEmailLink.module.scss';

type Props = {
  onClick: () => void;
  isLoading?: boolean;
};

export const SendAgainEmailLink: React.FC<Props> = ({ isLoading, onClick }) => {
  const formatMessage = useFormatMessage();

  return (
    <div onClick={onClick} className={styles.link}>
      {isLoading && <RotatingLoader size={12} type={'secondary'} />}
      <Text style='regular1215' colorStyle={'primary'}>
        {formatMessage('signup.sendAgainEmailLink')}
      </Text>
    </div>
  );
};
