import clsx from 'clsx';
import styles from './Field.module.scss';

export const Field: React.FC<{
  children: React.ReactNode;
  className?: string;
  extraGap?: boolean;
}> = ({ children, className, extraGap = false }) => (
  <div className={clsx(styles.field, extraGap && styles.extraGap, className)}>
    {children}
  </div>
);

export const FieldLabel: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <div className={clsx(styles.fieldLabel, className)}>{children}</div>
);
