import { CircularProgressbar } from 'react-circular-progressbar';
import styles from './Timer.module.scss';
import 'react-circular-progressbar/dist/styles.css';
import { theme } from 'common/consts/theme';

type Props = {
  value: number;
  text: string;
  maxValue: number;
  darkBackgroundTimerPath?: boolean;
  darkMode?: boolean;
};

export const Timer: React.FC<Props> = ({
  value,
  text,
  maxValue,
  darkBackgroundTimerPath = false,
  darkMode = true,
}) => {
  return (
    <div className={styles.circle}>
      <CircularProgressbar
        value={value}
        text={text}
        maxValue={maxValue}
        strokeWidth={12}
        counterClockwise={true}
        styles={{
          text: {
            fontSize: '34px',
            fontWeight: theme.fontWeights.light,
            fontFamily: theme.fontFamilies.inter,
            fill: darkMode
              ? theme.colors.textPrimary
              : theme.colors.backgroundLight,
          },
          trail: {
            stroke: darkMode
              ? theme.colors.backgroundDark
              : theme.colors.backgroundTimerTrailTight,
          },
          path: {
            stroke: darkBackgroundTimerPath
              ? theme.colors.backgroundTimerPathError
              : theme.colors.backgroundTimerPath,
          },
        }}
      />
    </div>
  );
};
