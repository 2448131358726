import clsx from 'clsx';
import { Text, textStyles } from 'common/components/Text';
import { InputAmount } from 'modules/input-amount';
import { CurrencySelector } from 'modules/currency-selector';
import { useRef } from 'react';
import styles from './AmountInput.module.scss';
import { DECIMALS_MAP } from 'common/utils/currency-enum-utils';
import { CryptoToFiatAmount } from 'modules/fiat';
import {
  BaseCurrency,
  CurrencyWallet,
} from 'modules/select-currency-wallet/types';
import { MaxButton, WalletBox } from 'modules/financial-ops/common';

type Props = {
  amount: string;
  currency?: BaseCurrency;
  error?: string;
  info?: string;
  wallet?: CurrencyWallet;
  onMax?: () => void;
  onAmountChange: (value: string) => void;
  onError: (error: string) => void;
};

export const AmountInput: React.FC<Props> = ({
  amount,
  currency,
  error = '',
  info = '',
  wallet,
  onAmountChange,
  onMax,
  onError,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.rootContainer}>
      <div className={styles.container}>
        <div
          className={clsx(
            styles.inputContainer,
            error && styles.inputContainerError,
            info && !error && styles.inputContainerInfo
          )}
        >
          {currency && onMax && (
            <div className={styles.maxButtonContainer}>
              <MaxButton onClick={onMax} />
            </div>
          )}
          {currency && wallet && (
            <div
              className={styles.amountContainer}
              onClick={() => {
                inputRef.current?.focus();
              }}
            >
              <div className={styles.inputWithCurrency}>
                <InputAmount
                  value={amount}
                  onChange={onAmountChange}
                  onError={onError}
                  decimals={DECIMALS_MAP[currency.currency]}
                  className={clsx(styles.amountInput, textStyles.light1622)}
                  inputRef={inputRef}
                />
                <Text style={'light1622'} className={styles.inputCurrency}>
                  {currency.currency}
                </Text>
              </div>
              <CryptoToFiatAmount
                amount={amount || '0.00'}
                currency={currency.currency}
                className={styles.amountFiat}
              />
            </div>
          )}
          <CurrencySelector
            className={styles.hideOnMobile}
            readOnly={true}
            currency={currency}
            showApr={false}
          />
        </div>
        <div className={styles.marginBottom}>
          {error && (
            <Text style='regular1215' colorStyle='error' dataTest='errorText'>
              {error}
            </Text>
          )}
          {info && !error && (
            <Text style='regular1215' colorStyle='inputInfo'>
              {info}
            </Text>
          )}
        </div>
      </div>
      {wallet && currency && (
        <WalletBox
          wallet={wallet}
          currency={currency.currency}
          onSelectWallet={() => {}}
          selectedWallet={wallet}
          key={wallet.id}
          className={styles.walletBox}
        />
      )}
    </div>
  );
};
