import { LoaderBox } from 'common/components/loader';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { Text } from 'common/components/Text';
import { termToMonths } from 'modules/financial-ops/common/utils';
import { useFormatMessage } from 'modules/messages';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { Currency } from 'modules/select-currency-wallet/types';
import { useInterestRates } from '../api';
import { InterestRate, InterestRateKind } from '../types';
import { TermAndRate } from './TermAndRate';
import styles from './TermAndRateSelector.module.scss';
import { filter, identity, pipe, sort } from 'ramda';

type Props = {
  className?: string;
  currency: Currency | undefined;
  error?: string;
  headerText?: string;
  interestRateKind: InterestRateKind;
  noDataText?: string;
  selectedRate: InterestRate | undefined;
  onSelectRate: (rate: InterestRate) => void;
};

export const TermAndRateSelector: React.FC<Props> = ({
  className,
  currency,
  error = '',
  headerText,
  interestRateKind,
  noDataText,
  selectedRate,
  onSelectRate,
}) => {
  const formatMessage = useFormatMessage();

  const { data, isLoading } = useInterestRates(interestRateKind);

  if (!currency) {
    return null;
  }

  if (isLoading) {
    return <LoaderBox />;
  }

  if (!data) {
    return null;
  }

  const rates = pipe(
    filter(
      (rate: InterestRate) =>
        rate.currency === currency.currency && rate.network === currency.network
    ),
    interestRateKind === 'SAVING'
      ? identity<InterestRate[]>
      : sort(
          (a: InterestRate, b: InterestRate) =>
            termToMonths(a.term!) - termToMonths(b.term!)
        )
  )(data);

  return (
    <div className={className}>
      <SectionSubheader>
        {headerText || formatMessage('lend.selectTermAndRate')}
      </SectionSubheader>
      {rates.length === 0 && (
        <NothingToDisplay text={noDataText} size={125} iconSize={60} />
      )}
      <div className={styles.ratesContainer} data-test='termAndRateSelector'>
        {rates.map((rate) => (
          <TermAndRate
            rate={rate.rate_pct}
            term={rate.term}
            key={`${rate.currency}-${rate.network}-${rate.term}`}
            onClick={() => onSelectRate(rate)}
            selected={
              rate.currency === selectedRate?.currency &&
              rate.network === selectedRate.network &&
              rate.term === selectedRate.term
            }
          />
        ))}
      </div>
      {error && (
        <Text style='regular1215' colorStyle='error'>
          {error}
        </Text>
      )}
    </div>
  );
};
