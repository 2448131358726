import clsx from 'clsx';
import { useFormatMessage } from 'modules/messages';
import styles from './PriceToleranceBox.module.scss';
import { Text, textStyles } from 'common/components/Text';
import { useEffect, useState } from 'react';
import { InputAmount, toDecimal } from 'modules/input-amount';
import { Button } from 'common/components/button';

const tolerancesList: string[] = ['0.5', '1', '1.5'];
export const TOLERANCE_INITIAL_VALUE = '1';
const MAX_TOLERANCE_VALUE = '100';
const MAX_TOLERANCE_INPUT = '99.99';

export const PriceToleranceBox: React.FC<{
  onChange: (tolerance: string) => void;
  tolerance: string;
}> = ({ onChange, tolerance }) => {
  const formatMessage = useFormatMessage();
  const [openCustom, setOpenCustom] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputToleranceAcceptedValue, setInputToleranceAcceptedValue] =
    useState('');
  const [okClicked, setOkClicked] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setInputValue(inputToleranceAcceptedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCustom]);

  return (
    <>
      <div className={styles.container}>
        {tolerancesList.map((item) => (
          <div
            className={clsx(
              styles.box,
              tolerance === item &&
                (!openCustom || !inputToleranceAcceptedValue) &&
                styles.activeBox
            )}
            key={item}
            onClick={() => {
              setOpenCustom(false);
              onChange(item);
            }}
          >
            <Text style='light1425'>{item}%</Text>
          </div>
        ))}
        <div
          className={clsx(
            styles.box,
            inputToleranceAcceptedValue && openCustom && styles.activeBox
          )}
          onClick={() => {
            if (!!inputToleranceAcceptedValue)
              onChange(inputToleranceAcceptedValue);
            setOpenCustom(true);
          }}
        >
          <Text style='light1425'>{formatMessage('trade.custom')}</Text>
        </div>
      </div>
      {openCustom && (
        <>
          <div className={styles.customBox}>
            <InputAmount
              className={clsx(
                styles.input,
                textStyles.light1425,
                error && styles.errorInput
              )}
              placeholder={formatMessage('trade.enterAmount')}
              onChange={(value) => {
                if (toDecimal(value).lt(MAX_TOLERANCE_VALUE))
                  setInputValue(value);
              }}
              onEnter={() => document.getElementById('btnOk')?.click()}
              value={inputValue}
              disabled={okClicked}
              onError={(err) => {
                if (inputValue !== '0') setError(err);
              }}
              maxAmount={MAX_TOLERANCE_INPUT}
              decimals={2}
              maxErrorText={formatMessage('trade.toleranceCannotBeMoreThan99')}
              minAmount={'-1'}
              showMinErrorText={false}
            />
            {!okClicked ? (
              <Button
                type='darkSmall'
                id='btnOk'
                name={formatMessage('trade.ok')}
                className={textStyles.light1425}
                disabled={!inputValue}
                onClick={() => {
                  setInputToleranceAcceptedValue(inputValue);
                  onChange(inputValue);
                  setError('');
                  setOkClicked(true);
                }}
              />
            ) : (
              <div
                onClick={() => {
                  setOkClicked(false);
                }}
                className={styles.edit}
              >
                <Text style='light1425'>
                  {formatMessage('common.button.edit')}
                </Text>
              </div>
            )}
          </div>
          {error && (
            <Text style='regular1215' colorStyle={'error'}>
              {error}
            </Text>
          )}
        </>
      )}
    </>
  );
};
