import clsx from 'clsx';
import styles from './TextArea.module.scss';
import { textStyles, Text } from '../Text';
import { TextareaHTMLAttributes } from 'react';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  errorText?: string;
  dataTest?: string;
};

export const TextArea: React.FC<Props> = ({
  errorText,
  dataTest,
  className,
  ...props
}) => {
  return (
    <>
      <textarea
        {...props}
        className={clsx(
          textStyles.light1425,
          styles.textarea,
          errorText && styles.errorInput,
          className
        )}
        data-test={dataTest}
      />
      {errorText && (
        <Text style='regular1215' colorStyle={'error'}>
          {errorText}
        </Text>
      )}
    </>
  );
};
