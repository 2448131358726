import { useQueryClient } from '@tanstack/react-query';
import { useUserQuery } from 'modules/user';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginLocationState } from './types';
import { useLoginContext } from './context';

export const useLoginRequired = () => {
  const { setIsLoggedIn } = useLoginContext();

  return useUserQuery({
    retry: false,
    onSuccess: () => {
      setIsLoggedIn(true);
    },
  });
};

// should be invoked only once in the app
export const useHandleUnauthorized = () => {
  const { setIsLoggedIn } = useLoginContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('Montesauri:unauthorized', () => {
      setIsLoggedIn(false);
      queryClient.removeQueries(); // remove all queries
      navigate('/login');
    });
  }, [navigate]);
};

export const useLoginLocationState = () => {
  const location = useLocation();
  return (location.state || {}) as LoginLocationState;
};
