import { Text } from 'common/components/Text';
import styles from './CharityFormDetailsHeader.module.scss';
import { ReactComponent as ArrowLeftIcon } from 'common/assets/black-icons/arrow-left-small.svg';
import { useFormatMessage } from 'modules/messages';

type Props = { onArrowClick: () => void; name?: string };

export const CharityFormDetailsHeader: React.FC<Props> = ({
  onArrowClick,
  name,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ArrowLeftIcon
          onClick={onArrowClick}
          data-test='backArrow'
          className={styles.arrowBack}
        />
        <Text style='xxbold3644' inlineStyle={{ display: 'block' }}>
          {name ?? formatMessage('charityForms.charityFormDetails')}
        </Text>
      </div>
    </div>
  );
};
