import D from 'decimal.js';
import { CryptoToFiatAmount, CurrencyRate } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import { BaseDataField } from 'modules/common-data-fields';
import { CurrencyEnum } from 'common/types';

type Props = {
  interest_total: string;
  currency: CurrencyEnum;
  currency_rate: CurrencyRate;
};

export const InterestEarnedSoFar: React.FC<Props> = ({
  interest_total,
  currency,
  currency_rate,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <BaseDataField
      label={formatMessage('finopsCommon.interestEarnedSoFar')}
      value={`${new D(interest_total).toFixed()} ${currency}`}
      valueTextStyle='xxbold1619'
      secondaryValue={
        <CryptoToFiatAmount
          amount={interest_total}
          currency={currency_rate.currency}
          currencyRate={currency_rate}
        />
      }
    />
  );
};
