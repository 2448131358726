import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useBorrowByIdQuery } from '../api';
import { BorrowStatePill } from '../borrow-list/components/BorrowStatePill';
import styles from './BorrowDetailsLTV.module.scss';
import { BorrowStateAlert } from './BorrowStateAlert';
import { BorrowLTVChart } from './borrow-ltv-chart';

type Props = {
  borrowId: number;
};

export const BorrowDetailsLTV: React.FC<Props> = ({ borrowId }) => {
  const formatMessage = useFormatMessage();

  const { data } = useBorrowByIdQuery(borrowId);

  if (!data) {
    return null;
  }

  const borrow = data.data;
  const { ltv_pct, state } = borrow;

  const showLTV =
    state !== 'LIQUIDATING' &&
    state !== 'LIQUIDATED' &&
    state !== 'CANCELLED' &&
    state !== 'COMPLETED';

  return (
    <div>
      <Text style='xxbold2024'>{formatMessage('borrow.ltv')}</Text>
      <div className={styles.ltvAndPill}>
        {showLTV && <Text style='xxbold1619'>{ltv_pct}%</Text>}
        <BorrowStatePill state={state} />
      </div>
      <BorrowStateAlert borrow={borrow} />
      <BorrowLTVChart borrowId={borrowId} className={styles.chart} />
    </div>
  );
};
