import styles from './ShowMorePill.module.scss';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { ReactComponent as ExpandIcon } from 'common/assets/grey-icons/arrowhead-down-9px.svg';
import clsx from 'clsx';

export const ShowMorePill: React.FC<{
  isMore: boolean;
  onClick: () => void;
}> = ({ isMore, onClick }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container} onClick={onClick}>
      <Text style='light1425' colorStyle='secondary'>
        {formatMessage(isMore ? 'common.showMore' : 'common.showLess')}
      </Text>
      <ExpandIcon className={clsx(!isMore && styles.showLess)} />
    </div>
  );
};
