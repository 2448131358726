import { LoaderBox } from 'common/components/loader';
import styles from './PictureBox.module.scss';
import { CharityFormPicture } from 'modules/charity-forms-public/types';

type Props = { picture?: CharityFormPicture; name: string };

export const PictureBox: React.FC<Props> = ({ picture, name }) => {
  return (
    <div className={styles.container}>
      {picture ? (
        <img
          src={picture.file}
          alt={`Hero picture for charity form ${name}`}
          className={styles.image}
        />
      ) : (
        <LoaderBox />
      )}
    </div>
  );
};
