import { Currency } from 'modules/select-currency-wallet/types';
import { useFormatMessage } from 'modules/messages';
import { RemainingFunds } from 'modules/common-data-fields/RemainingFunds';

type Props = {
  currency?: Currency;
  remainingFunds?: string;
  currentFunds?: string;
  isLoading: boolean;
};

export const CharityFormWithdrawEffect: React.FC<Props> = ({
  currency,
  currentFunds,
  remainingFunds,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <RemainingFunds
      amount={remainingFunds}
      subamount={currentFunds}
      currency={currency?.currency}
      isLoading={isLoading}
      subtitle={formatMessage('charityForms.currentFundraiserBalance')}
    />
  );
};
