import styles from './StepWithdraw.module.scss';
import { useFormatMessage } from 'modules/messages';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { WithdrawAddressBox } from './WithdrawAddressBox';
import { WhitelistedAddress } from 'modules/address-whitelist/types';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { AssetAmountInput } from 'modules/financial-ops/common/components/AssetAmountInput';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { findCurrency } from 'modules/financial-ops/common';
import { GoToSummaryButton, CancelButton } from 'common/components/button';

type Props = {
  whitelistedAddress?: WhitelistedAddress;
  onChangeWithdrawAddress: (addr: WhitelistedAddress) => void;
  amount: string;
  onChangeAmount: (amount: string, fee: string, balance?: string) => void;
  errorAddress: string;
  errorAmount: string;
  onErrorAddress: (err: string) => void;
  onSummary: () => void;
  onMax: () => void;
  isLoadingCurrencies: boolean;
  fee: string;
  currencies?: Currency[];
  currency?: Currency;
  wallets?: CurrencyWallet[];
  wallet?: CurrencyWallet;
  onCurrencyChange: (value?: Currency) => void;
  onWalletChange: (value?: CurrencyWallet) => void;
  errorWallet: string;
  onCancel: () => void;
};

export const StepWithdraw: React.FC<Props> = ({
  whitelistedAddress,
  onChangeWithdrawAddress,
  amount,
  onChangeAmount,
  errorAddress,
  errorAmount,
  onErrorAddress,
  onSummary,
  onMax,
  isLoadingCurrencies,
  fee,
  currencies,
  wallets,
  currency,
  wallet,
  onCurrencyChange,
  onWalletChange,
  errorWallet,
  onCancel,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader>{formatMessage('withdraw.withdrawCurrency')}</ModalHeader>
      <ModalBody>
        <AssetAmountInput
          amount={amount}
          currency={currency}
          wallets={wallets}
          desktopHeader={formatMessage('common.selectAssetAndAmount')}
          error={errorAmount}
          mobileAssetHeader={formatMessage('common.currency')}
          mobileAmountHeader={formatMessage('common.amount')}
          showApr={false}
          wallet={wallet}
          walletError={errorWallet}
          onAmountChange={(value) =>
            onChangeAmount(value, fee, wallet?.balance)
          }
          onCurrencyChange={(currency) => {
            const foundCurrency = findCurrency(currency, currencies);
            onCurrencyChange(foundCurrency);
          }}
          onWalletChange={onWalletChange}
          onMax={onMax}
          currencies={currencies}
          isLoadingCurrencies={isLoadingCurrencies}
        />
        {wallet && currency && (
          <>
            <WithdrawAddressBox
              address={whitelistedAddress?.address}
              name={whitelistedAddress?.name}
              errorText={errorAddress}
              onError={onErrorAddress}
              onChangeWithdrawAddress={onChangeWithdrawAddress}
              className={styles.address}
              currency={currency}
            />
            <Summary>
              <SummaryLabel>
                {formatMessage('withdraw.withdrawalFee')}
              </SummaryLabel>
              <SummaryValue>
                {fee
                  ? `${toLimitedPrec(toFixed(fee))} ${currency.currency}`
                  : formatMessage('common.noFee')}
              </SummaryValue>
            </Summary>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          {wallet && (
            <>
              <CancelButton onClick={onCancel} />
              <GoToSummaryButton id='button-summary' onClick={onSummary} />
            </>
          )}
        </ModalActions>
      </ModalFooter>
    </>
  );
};
