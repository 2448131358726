import { Modal, ModalBody, ModalHeader } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { WalletSelector } from './components/WalletSelector';
import { Text } from 'common/components/Text';
import { WalleQRCode } from './components/WalletQRCode';
import { Alert } from 'common/components/Alert';
import { Copy } from 'common/components/Copy';
import {
  SelectCurrencyWalletContextProvider,
  useSelectCurrencyWalletContext,
} from 'modules/select-currency-wallet';

type Props = { open: boolean; onClose: () => void; dataTest?: string };

const DepositModal: React.FC<Props> = ({ open, onClose }) => {
  const formatMessage = useFormatMessage();
  const { wallet, reset } = useSelectCurrencyWalletContext();

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <Modal isOpen={open} onClose={closeModal}>
      <ModalHeader>{formatMessage('deposit.depositCurrency')}</ModalHeader>
      <ModalBody>
        {wallet && (
          <Text style='xbold1417'>
            {formatMessage('deposit.walletAddressQRCode')}
          </Text>
        )}
        <WalletSelector dataTest='selectedWallet' />
        {wallet && (
          <>
            <WalleQRCode />
            <Text style='xbold1417'>
              {formatMessage('deposit.yourWalletAddress')}
            </Text>
            <Copy dataTest='walletAddress' data={wallet.address} />
            <Alert
              text={formatMessage('deposit.warning')}
              severity='secondary'
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export const Deposit: React.FC<Props> = ({ open, onClose }) => {
  return (
    <SelectCurrencyWalletContextProvider>
      <DepositModal open={open} onClose={onClose} />
    </SelectCurrencyWalletContextProvider>
  );
};
