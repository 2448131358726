import { LoaderBox } from 'common/components/loader';
import { HeaderCell, SortValue } from 'common/components/table-subcomponents';
import {
  PopupSorter,
  SortConfig,
} from 'common/components/table-subcomponents/sort/PopupSorter';
import { ascComp, descComp } from 'common/utils/comparators';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { useFormatMessage } from 'modules/messages';
import { NothingToDisplay } from 'modules/nothing-to-display';
import * as R from 'ramda';
import { useState } from 'react';
import { AddressRow } from './AddressRow';
import styles from './AddressWhitelistTable.module.scss';
import { useWhitelistedAddressQuery } from './api';

type SortModel = {
  name?: SortValue;
  network?: SortValue;
};

export const AddressWhitelistTable: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const [sort, setSort] = useState<SortModel>({});

  const { data, isLoading } = useWhitelistedAddressQuery();

  const headers: SortConfig[] = [
    {
      sort: sort.name,
      onSort: (next) => setSort({ name: next }),
      text: formatMessage('common.name'),
      className: styles.headerCellName,
    },
    {
      sort: sort.network,
      onSort: (next) => setSort({ network: next }),
      text: formatMessage('common.network'),
      className: styles.headerCellNetwork,
    },
  ];

  const getSortedData = () => {
    if (!data) {
      return [];
    }
    if (sort.name) {
      return R.sort(
        (a1, a2) =>
          (sort.name === 'asc' ? ascComp : descComp)(a1.name, a2.name),
        data.data
      );
    }
    if (sort.network) {
      return R.sort(
        (a1, a2) =>
          (sort.network === 'asc' ? ascComp : descComp)(
            displayNetwork(a1.network),
            displayNetwork(a2.network)
          ),
        data.data
      );
    }
    return data.data;
  };

  const nothingToDisplay = !isLoading && !data?.data.length;

  return (
    <>
      <div className={styles.headerRow}>
        {headers.map((headerConfig, idx) => (
          <HeaderCell
            key={idx}
            className={headerConfig.className}
            sort={headerConfig.sort}
            onSort={headerConfig.onSort}
          >
            {headerConfig.text}
          </HeaderCell>
        ))}
        <HeaderCell
          className={styles.headerCellName}
          sort={sort.name}
          onSort={(next) => setSort({ name: next })}
        >
          {formatMessage('common.name')}
        </HeaderCell>
        <HeaderCell
          className={styles.headerCellNetwork}
          sort={sort.network}
          onSort={(next) => setSort({ network: next })}
        >
          {formatMessage('common.network')}
        </HeaderCell>
      </div>
      {!nothingToDisplay && (
        <PopupSorter
          className={styles.mobileSorter}
          isOnLeft={true}
          config={headers}
        />
      )}
      {isLoading && <LoaderBox />}
      {data &&
        getSortedData().map((item) => <AddressRow item={item} key={item.id} />)}
      {nothingToDisplay && <NothingToDisplay />}
    </>
  );
};
