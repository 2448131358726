import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button } from 'common/components/button';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { NavigationLayout } from 'modules/layout';
import { openSnackbar } from 'common/components/snackbar';
import { Tooltip } from 'common/components/Tooltip';
import { Switch } from 'common/components/Switch';
import { NetworkIcon } from 'common/components/NetworkIcon';
import { NetworkBadge } from 'common/components/NetworkBadge';
import { NetworkBox } from 'common/components/NetworkBox';
import { LabeledNetworkBadge } from 'common/components/LabeledNetworkBadge';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { WalletNameBox } from 'common/components/wallet/WalletNameBox';
import { WalletVisual } from 'modules/assets-and-wallets/components/WalletVisual';

export const ComponentTestpage: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.testpage')}</title>
      </Helmet>
      <NavigationLayout>
        <button onClick={() => setIsOpen(true)}>Open modal</button>
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <>
            <ModalHeader>{'header'}</ModalHeader>
            <ModalBody>{'body'}</ModalBody>
            <ModalFooter>{'footer'}</ModalFooter>
          </>
        </Modal>
        <Button isLoading={true} name='Button' type='dark' />
        <Button isLoading={true} name='Button' type='darkSmall' />
        <Button isLoading={true} name='Button' type='secondary' />
        <Button isLoading={true} name='Button snackbar' type='secondarySmall' />
        <Button
          name='Button snackbar'
          type='secondarySmall'
          onClick={() => {
            openSnackbar('hehe', 'error');
          }}
        />
        <Tooltip title='hello'>
          <div style={{ width: '250px', background: 'salmon' }}>
            hover over me to see tooltip
          </div>
        </Tooltip>
        <Switch
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
          }}
        />
        <Box>
          NetworkIcon
          <NetworkIcon networkName='ETHEREUM' />
        </Box>
        <Box>
          NetworkBadge
          <NetworkBadge network='ETHEREUM' />
        </Box>
        <Box>
          NetworkBox
          <NetworkBox network='ETHEREUM' />
        </Box>
        <Box>
          LabeledNetworkBadge
          <LabeledNetworkBadge text='Label' network='ETHEREUM' />
        </Box>
        <Box>
          CurrencyBox
          <CurrencyBox
            network='ETHEREUM'
            currency='ETH'
            iconStyle='blackTransparent'
          />
        </Box>
        <Box>
          WalletBox
          <WalletNameBox name='Label' />
        </Box>
        <Box>
          WalletVisual
          <WalletVisual network='ETHEREUM' walletName='Label' />
        </Box>
      </NavigationLayout>
    </>
  );
};

const Box: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <div
    style={{
      display: 'flex',
      gap: '10px',
      flexDirection: 'column',
      padding: '20px 0',
      width: '500px',
    }}
  >
    {children}
  </div>
);
