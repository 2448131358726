import { CurrencyEnum } from 'common/types';
import D from 'decimal.js';
import { useUserQuery } from 'modules/user';
import { useCurrencyRatesQuery } from './api';

export const useFiatCurrency = () => {
  const query = useUserQuery();

  return {
    ...query,
    data: query.data?.data.fiat_currency,
  };
};

type AssetAmount = {
  amount: string | number;
  currency: CurrencyEnum;
};

// safe for use only if all compared values are current/live, and not historical like in case of completed borrow for example
export const useFiatComparators = () => {
  const { data } = useCurrencyRatesQuery();

  const rates = data?.data.reduce(
    (acc, next) => ({
      ...acc,
      [next.currency]: next.price_usd,
    }),
    {} as { [key in CurrencyEnum]: string }
  );

  const ascFiatCmp = (itemA: AssetAmount, itemB: AssetAmount) => {
    const fiatValueA = D.mul(itemA.amount, rates?.[itemA.currency] || 0);
    const fiatValueB = D.mul(itemB.amount, rates?.[itemB.currency] || 0);
    return fiatValueA.sub(fiatValueB).toNumber();
  };

  const descFiatCmp = (itemA: AssetAmount, itemB: AssetAmount) => {
    const fiatValueA = D.mul(itemA.amount, rates?.[itemA.currency] || 0);
    const fiatValueB = D.mul(itemB.amount, rates?.[itemB.currency] || 0);
    return fiatValueA.sub(fiatValueB).mul(-1).toNumber();
  };

  return { ascFiatCmp, descFiatCmp };
};
