import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { InterestRateNewEvent } from '../types/interest-rate-new-event';
import {
  InterestRate,
  getInterestRatesQueryKey,
} from 'modules/financial-ops/common';
import { log } from 'modules/logger';

export const useHandleInterestRateNew = () => {
  const queryClient = useQueryClient();

  const handleInterestRateNew = useCallback(
    (event: InterestRateNewEvent) => {
      log('handling INTEREST_RATE_NEW WebSocket event', event);

      const newInterestRate = event.interest_rate;

      queryClient.setQueryData(
        getInterestRatesQueryKey(newInterestRate.kind),
        (prevState: InterestRate[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          return [newInterestRate, ...prevState];
        }
      );
    },
    [queryClient]
  );

  return handleInterestRateNew;
};
