import { CurrencyEnum } from 'common/types';
import { CryptoToFiatAmount, CurrencyRate } from 'modules/fiat';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { BaseDataField } from './BaseDataField';

type Props = {
  title: string;
  amount: string;
  currency: CurrencyEnum;
  currencyRate: CurrencyRate;
  showFiat?: boolean;
};

export const Price: React.FC<Props> = ({
  title,
  amount,
  currency,
  currencyRate,
  showFiat = true,
}) => {
  return (
    <BaseDataField
      label={title}
      value={`${toLimitedPrec(toFixed(amount))} ${currency}`}
      secondaryValue={
        showFiat ? (
          <CryptoToFiatAmount
            amount={amount}
            currency={currency}
            currencyRate={currencyRate}
          />
        ) : undefined
      }
    />
  );
};
