import styles from './CurrencyItem.module.scss';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { CurrencyWalletBox } from './CurrencyWalletBox';
import { Currency } from '../types';

type Props = {
  selectedCurrency: Currency;
  showArrow?: boolean;
  onSelect?: (currency: Currency) => void;
  dataTest?: string;
};

export const CurrencyItem: React.FC<Props> = ({
  selectedCurrency,
  showArrow = true,
  onSelect,
}) => {
  const { currency, network, wallets } = selectedCurrency;

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (onSelect) onSelect(selectedCurrency);
      }}
    >
      <CurrencyBox
        currency={currency}
        network={network}
        dataTest={`currencyBox-${currency}`}
      />
      <CurrencyWalletBox
        walletsCount={wallets?.length}
        showArrow={showArrow}
        dataTest='walletsCount'
      />
    </div>
  );
};
