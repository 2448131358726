import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { get } from 'modules/api';
import { FeatureFlags } from './types';

const FEATURE_FLAGS_URL = '/feature-flags';

export const FEATURE_FLAGS_QUERY_KEY = ['GET', FEATURE_FLAGS_URL] as const;

const getFeatureFlags = async () => {
  const response = await get<FeatureFlags>(FEATURE_FLAGS_URL);
  return response.data;
};

export const useFeatureFlags = () =>
  useQuery<FeatureFlags, AxiosError<any>>(
    FEATURE_FLAGS_QUERY_KEY,
    getFeatureFlags,
    {
      staleTime: Infinity,
    }
  );

export const FEATURE_FLAG_DISABLED = 'FEATURE_FLAG_DISABLED' as const;

// this is error returned by feature endpoints (like borrow, lend, trade) when given feature is disabled with a flag
// this IS NOT an error return from /feature-flags endpoint!
export type FeatureFlagDisabledError = {
  status?: string;
  details?: string;
};

export const isFeatureDisabledError = (
  error: AxiosError<FeatureFlagDisabledError> | null
) => {
  if (!error) {
    return false;
  }
  return error.response?.data.status === FEATURE_FLAG_DISABLED;
};
