import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import styles from './StepSuccess.module.scss';
import { Text } from 'common/components/Text';
import { CurrentThreshold } from 'modules/financial-ops/common/components/CurrentThreshold';
import { ApproveTransactionText } from 'modules/2FA';
import { useIsSigner } from 'modules/user';
import { Button } from 'common/components/button';

type Props = {
  onApprove: () => void;
  onDone: () => void;
};

export const StepSuccess: React.FC<Props> = ({ onDone, onApprove }) => {
  const formatMessage = useFormatMessage();

  const { data: isSigner } = useIsSigner();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('lend.lendTerminationHasBeenInitiated')}
      </ModalHeader>
      <ModalBody className={styles.content}>
        <Text style='light1425'>
          {formatMessage('lend.lendTerminatedAfterReachingAcceptances')}
        </Text>
        <div className={styles.thresholdContainer}>
          <CurrentThreshold />
        </div>
        {isSigner && <ApproveTransactionText />}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          {isSigner ? (
            <>
              <Button
                type='secondary'
                onClick={onDone}
                name={formatMessage('common.button.later')}
              />
              <Button
                type='dark'
                onClick={onApprove}
                name={formatMessage('common.button.approve')}
              />
            </>
          ) : (
            <Button
              type='dark'
              onClick={onDone}
              name={formatMessage('common.button.done')}
            />
          )}
        </ModalActions>
      </ModalFooter>
    </>
  );
};
