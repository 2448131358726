import clsx from 'clsx';
import { Initials } from 'common/components/Initials';
import { LoaderBox } from 'common/components/loader';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useUserQuery } from './api';
import styles from './UserInfo.module.scss';
import { UserName } from './UserName';
import { displayRole } from './utils';

type Props = { className?: string };

export const UserInfo: React.FC<Props> = ({ className }) => {
  const formatMessage = useFormatMessage();
  const { data: userData } = useUserQuery();

  if (!userData) {
    return <LoaderBox />;
  }

  const { name, surname, email, company_role, company, is_kyc_completed } =
    userData.data;

  return (
    <div className={clsx(styles.container, className)}>
      <Initials className={styles.initials} name={name} surname={surname} />
      <UserName
        name={name}
        surname={surname}
        isKycCompleted={is_kyc_completed}
      />
      <Text
        className={styles.email}
        style={'light1425'}
        colorStyle='secondary'
        dataTest='userInfoEmail'
      >
        {email}
      </Text>
      <Text
        className={styles.role}
        style={'light1425'}
        colorStyle='secondary'
        dataTest='userInfoRole'
      >
        {displayRole(formatMessage, company_role)}
      </Text>
      <Text
        className={styles.companyName}
        style={'light1425'}
        colorStyle='secondary'
      >
        {company.name}
      </Text>
    </div>
  );
};
