import { Text } from 'common/components/Text';
import { Alert } from 'common/components/Alert';
import { useFormatMessage } from 'modules/messages';
import styles from './SnapshotAlert.module.scss';

export const SnapshotAlert: React.FC<{ onRefresh: () => void }> = ({
  onRefresh,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <Alert
      severity='warning'
      text={
        <div className={styles.warningContent}>
          {formatMessage('common.confirmationTimeHasPassedRefreshToContinue')}
          <Text
            style='xxbold1417'
            onClick={onRefresh}
            className={styles.refresh}
          >
            {formatMessage('common.button.refresh')}
          </Text>
        </div>
      }
      className={styles.warning}
    />
  );
};
