import clsx from 'clsx';
import { LoaderBox } from 'common/components/loader';
import {
  formatDatetime,
  formatDateWithoutYear,
  formatTimeWithoutSec,
} from 'common/utils/utils';
import {
  differenceInDays,
  differenceInMonths,
  differenceInSeconds,
  subDays,
  subMonths,
} from 'date-fns';
import { toFixed } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { map, sortWith, uniqBy } from 'ramda';
import { useState } from 'react';
import {
  Label,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from 'recharts';
import { useBorrowByIdQuery, useBorrowMeasuresByIdQuery } from '../../api';
import { BorrowMeasure, BorrowMeasuresPeriod } from '../../types';
import styles from './BorrowLTVChart.module.scss';
import { CurrentLTVIndicator } from './components/CurrentLTVIndicator';
import { PeriodSelector } from './components/PeriodSelector';
import { LTV_CHART_HEIGHT } from './consts';

type Props = {
  borrowId: number;
  className?: string;
};

type ChartPoint = BorrowMeasure & {
  created_at_number: number;
};

const HOUR_MS = 60 * 60 * 1000;
const DAY_MS = 24 * HOUR_MS;

const periodToInterval: { [key in BorrowMeasuresPeriod]: number } = {
  ALL: 24,
  '1D': 1,
  '1W': 1,
  '1M': 24,
  '3M': 24,
};

export const DEFAULT_PERIOD = '1W';

export const BorrowLTVChart: React.FC<Props> = ({ borrowId, className }) => {
  const formatMessage = useFormatMessage();

  const [selectedPeriod, setSelectedPeriod] =
    useState<BorrowMeasuresPeriod>(DEFAULT_PERIOD);

  const { data: measures, isLoading } = useBorrowMeasuresByIdQuery(
    borrowId,
    selectedPeriod
  );

  const { data: borrow, isLoading: isBorrowLoading } =
    useBorrowByIdQuery(borrowId);

  if (isLoading || isBorrowLoading) {
    return <LoaderBox />;
  }

  if (!measures?.data || !borrow?.data) {
    return null;
  }

  // currently all borrows contain ltv levels and at least one event/measure, so this if can be removed in not distant future (when/if dev enviroment is cleaned of old borrows)
  if (!borrow?.data.ltv || measures.data.length === 0) {
    return null;
  }

  const dataPrepared: ChartPoint[] = uniqBy(
    (item: ChartPoint) => item.created_at, // because if for some reasone the created_at is not unique, the ReferenceArea breaks (is not displayed)
    sortWith(
      [
        (item1: ChartPoint, item2: ChartPoint) =>
          item1.created_at_number - item2.created_at_number,
      ],
      map(
        (item) => ({
          ...item,
          created_at_number: Number(new Date(item.created_at)),
        }),
        measures.data
      )
    )
  );

  const oldest = dataPrepared[0];
  const newest = dataPrepared[dataPrepared.length - 1];

  const subtractPeriod = (date: string, period: BorrowMeasuresPeriod) => {
    switch (period) {
      case '1D':
        return subDays(new Date(date), 1);
      case '1W':
        return subDays(new Date(date), 7);
      case '1M':
        return subMonths(new Date(date), 1);
      case '3M':
        return subMonths(new Date(date), 3);
      default:
        return new Date(date); // just to avoid uncaught exception, otherwise default case does not make sense
    }
  };

  const minX =
    selectedPeriod === 'ALL' ||
    Math.abs(
      differenceInSeconds(
        oldest.created_at_number,
        subtractPeriod(newest.created_at, selectedPeriod)
      )
    ) <=
      periodToInterval[selectedPeriod] * 60 * 60
      ? oldest.created_at_number
      : Number(subtractPeriod(newest.created_at, selectedPeriod));

  const maxX = newest.created_at_number;

  const minXHour = minX - (minX % HOUR_MS);
  const minXDay = minX - (minX % DAY_MS);

  const getTicks = () => {
    let ticks: number[] = [];
    switch (selectedPeriod) {
      case '1D': {
        ticks = [...new Array(24)].map((_, i) => minXHour + (i + 1) * HOUR_MS);
        break;
      }
      case '1W': {
        ticks = [...new Array(7)].map((_, i) => minXDay + (i + 1) * DAY_MS);
        break;
      }
      case '1M': {
        const numTicks = differenceInDays(maxX, minX);
        ticks = [...new Array(numTicks)].map(
          (_, i) => minXDay + (i + 1) * DAY_MS
        );
        break;
      }
      case '3M': {
        const numTicks = differenceInDays(maxX, minX);
        ticks = [...new Array(numTicks)].map(
          (_, i) => minXDay + (i + 1) * DAY_MS
        );
        break;
      }
    }
    // make sure all ticks are bounded within X axis valus range
    return ticks.filter((tick) => tick >= minX && tick <= maxX);
  };

  const liquidationMargin = borrow.data.ltv.liquidation;

  const showAllTime =
    differenceInMonths(new Date(), new Date(borrow.data.start_at)) >= 3;

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.chartAndPeriodSelectorContainer}>
        <ResponsiveContainer width={'100%'} height={LTV_CHART_HEIGHT}>
          <LineChart width={600} height={210} data={dataPrepared}>
            <Line
              dot={SmallDot}
              dataKey={'ltv_pct'}
              stroke='black'
              isAnimationActive={false}
            />
            <YAxis
              unit={'%'}
              stroke='black'
              tickLine={false}
              domain={[0, 100]}
              axisLine={false}
              ticks={[20, 40, 60, 80, 100]}
              dataKey={'ltv_pct'}
              fontSize={11}
            />
            <XAxis
              type='number'
              domain={[minX, maxX]}
              dataKey={'created_at_number'}
              tickLine={false}
              tickCount={30}
              axisLine={false}
              stroke={'black'}
              fontSize={11}
              ticks={getTicks()}
              tickFormatter={(value) => {
                if (value === 'auto') {
                  return '';
                }
                if (value === 0) {
                  return '';
                }
                if (selectedPeriod === '1D') {
                  return formatTimeWithoutSec(new Date(value).toISOString());
                }
                return formatDateWithoutYear(new Date(value).toISOString());
              }}
            />
            <ReferenceLine
              y={liquidationMargin}
              stroke={'#EE7F51'}
              label={
                <Label
                  textAnchor='start'
                  position='top'
                  fontSize={'9'}
                  fill={'#EE7F51'}
                >
                  {formatMessage('borrow.liquidationMargin', {
                    pct: toFixed(liquidationMargin),
                  })}
                </Label>
              }
              strokeDasharray={'2 2'}
              textAnchor='start'
            />
            {dataPrepared.length && (
              <ReferenceArea
                x1={minX}
                y1={20}
                x2={maxX}
                y2={80}
                fill={'url(#sampleGradient)'}
                fillOpacity={'0.15'}
              />
            )}
            <RechartsTooltip
              formatter={(value) => {
                return [`${value}%`, formatMessage('borrow.ltvAbbr')];
              }}
              labelFormatter={(value) => {
                return formatDatetime(new Date(value).toISOString());
              }}
            />
            <defs>
              <linearGradient id='sampleGradient' x1='0' x2='0' y1='0' y2='1'>
                <stop offset='0%' stopColor='rgb(255, 184, 0)' />
                <stop offset='54%' stopColor='rgb(166, 198, 86)' />
                <stop offset='100%' stopColor='rgb(152, 213, 0)' />
              </linearGradient>
            </defs>
            {/* linear-gradient(180deg, rgba(255, 184, 0, 0.15) 0%, rgba(166, 198, 86, 0.15) 54.3%, rgba(152, 213, 0, 0.15) 97.4%); */}
          </LineChart>
        </ResponsiveContainer>
        <PeriodSelector
          selectedPeriod={selectedPeriod}
          onSelectPeriod={(period) => setSelectedPeriod(period)}
          showAllTime={showAllTime}
        />
      </div>
      {dataPrepared.length && (
        <CurrentLTVIndicator currentLtv={parseFloat(newest.ltv_pct)} />
      )}
    </div>
  );
};

const SmallDot = (props: any) => {
  const { cx, cy, r } = props;
  return <circle cx={cx} cy={cy} r='0.5' fill='black' />;
};
