import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useFormatMessage } from 'modules/messages';
import { NewCharityFormButton } from './new-form/NewCharityFormButton';
import { LoaderBox } from 'common/components/loader';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { CharityForms } from './charity-forms-list/CharityForms';

export const CharityFormsDashboard: React.FC = () => {
  const formatMessage = useFormatMessage();

  const charityFormFeatureFlag = useRequireFeatureFlag('CHARITY_FORM');

  if (charityFormFeatureFlag.isLoading) {
    return <LoaderBox />;
  }
  if (!charityFormFeatureFlag.data?.enabled) {
    return null;
  }

  return (
    <Section>
      <SectionHeader
        actions={<NewCharityFormButton />}
        header={formatMessage('charityForms.charityForms')}
        subheader={formatMessage('charityForms.createAndManageForms')}
      />
      <CharityForms />
    </Section>
  );
};
