import clsx from 'clsx';
import { NetworkBox } from 'common/components/NetworkBox';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { TransactionDetails } from 'modules/transactions/types';
import { Date } from 'modules/common-data-fields/Date';
import { ExternalWallet } from 'modules/common-data-fields/ExternalWallet';
import { InternalWallet } from 'modules/common-data-fields/InternalWallet';
import styles from './DatesWalletsBox.module.scss';
import { BorrowID } from './components/BorrowID';
import { LendID } from './components/LendID';
import { MaturityDate } from './components/MaturityDate';
import { SavingID } from './components/SavingID';
import { TradeID } from './components/TradeID';
import { CharityFormName } from './components/CharityFormName';
import { CharityFormID } from './components/CharityFormID';

type Props = { transaction: TransactionDetails; className?: string };

export const DatesWalletsBox: React.FC<Props> = ({
  transaction,
  className,
}) => {
  const formatMessage = useFormatMessage();

  const {
    state,
    kind,
    wallet,
    whitelisted_address,
    created_at,
    extra_content,
    lend,
    borrow,
    trade,
    saving,
    charity_form,
  } = transaction;

  return (
    <div className={clsx(styles.container, className)}>
      {kind === 'DEPOSIT' && (
        <>
          {extra_content?.source_address && (
            <ExternalWallet name={extra_content?.source_address} />
          )}
          <div className={styles.flexGap10}>
            <InternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
        </>
      )}
      {kind === 'WITHDRAWAL' && (
        <>
          <div className={styles.flexGap10}>
            <ExternalWallet name={wallet.name} />
            <div className={styles.hideOnMobile}>
              <NetworkBox network={wallet.network} />
            </div>
          </div>
          <div className={styles.flexGap10}>
            <InternalWallet name={whitelisted_address.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
        </>
      )}
      {kind === 'LOAN_LEND' && (
        <>
          <div className={styles.flexGap10}>
            <ExternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          {extra_content?.end_at && (
              <MaturityDate
                date={extra_content.end_at}
                tooltipText={formatMessage('transactions.lendExecutedMaturity')}
              />
            )
          }
        </>
      )}
      {kind === 'LOAN_BORROW' && (
        <>
          <div className={styles.flexGap10}>
            <div>
              <Text style='light1215' colorStyle='secondary'>
                {formatMessage('common.collateral') + ':'}
              </Text>
            </div>
            <ExternalWallet name={borrow.collateral_wallet.name} />
            <NetworkBox network={borrow.collateral_wallet.network} />
          </div>
          <Date date={created_at} />
          {extra_content?.end_at && (
              <MaturityDate
                date={extra_content.end_at}
                tooltipText={formatMessage('transactions.borrowExecutedMaturity')}
              />
            )
          }
        </>
      )}
      {kind === 'LOAN_PRINCIPAL_PAYOUT' && (
        <>
          <div className={styles.flexGap10}>
            <div>
              <Text style='light1215' colorStyle='secondary'>
                {formatMessage('common.principal') + ':'}
              </Text>
            </div>
            <InternalWallet name={borrow.principal_wallet.name} />
            <NetworkBox network={borrow.principal_wallet.network} />
          </div>
          <Date date={created_at} />
          <BorrowID id={borrow.id} />
        </>
      )}
      {kind === 'LOAN_INTEREST' && (
        <>
          <div className={styles.flexGap10}>
            <InternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <MaturityDate
            date={lend.end_at}
            tooltipText={
              state === 'PENDING'
                ? formatMessage('transactions.lendExecutedMaturity')
                : undefined
            }
          />
          <LendID id={lend.id} />
        </>
      )}
      {kind === 'LOAN_COLLATERAL_TOPUP_AUTO' && (
        <>
          <div className={styles.flexGap10}>
            <ExternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <BorrowID id={borrow.id} />
        </>
      )}
      {kind === 'LOAN_COLLATERAL_TOPUP' && (
        <>
          <div className={styles.flexGap10}>
            <ExternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <BorrowID id={borrow.id} />
        </>
      )}
      {kind === 'LOAN_COLLATERAL_EXCESS_RETURN' && (
        <>
          <div className={styles.flexGap10}>
            <InternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <BorrowID id={borrow.id} />
        </>
      )}
      {kind === 'LOAN_COLLATERAL_FULL_RETURN' && (
        <>
          <div className={styles.flexGap10}>
            <InternalWallet name={borrow.collateral_wallet.name} />
            <NetworkBox network={borrow.collateral_wallet.network} />
          </div>
          <Date date={created_at} />
          <BorrowID id={borrow.id} />
        </>
      )}
      {kind === 'LOAN_BORROW_REPAY' && (
        <>
          <div className={styles.flexGap10}>
            <div>
              <Text style='light1215' colorStyle='secondary'>
                {formatMessage('common.principal') + ':'}
              </Text>
            </div>
            <ExternalWallet name={borrow.principal_wallet.name} />
            <NetworkBox network={borrow.principal_wallet.network} />
          </div>
          <Date date={created_at} />
          <MaturityDate date={borrow.end_at} />
          <BorrowID id={borrow.id} />
        </>
      )}
      {kind === 'LOAN_BORROW_LIQUIDATION' && (
        <>
          <div className={styles.flexGap10}>
            <InternalWallet name={borrow.collateral_wallet.name} />
            <NetworkBox network={borrow.collateral_wallet.network} />
          </div>
          <Date date={created_at} />
          <BorrowID id={borrow.id} />
        </>
      )}
      {kind === 'LOAN_BORROW_CANCELLATION' && (
        <>
          <div className={styles.flexGap10}>
            <InternalWallet name={borrow.collateral_wallet.name} />
            <NetworkBox network={borrow.collateral_wallet.network} />
          </div>
          <Date date={created_at} />
          <BorrowID id={borrow.id} />
        </>
      )}
      {kind === 'LOAN_PRINCIPAL_RETURN' && (
        <>
          <div className={styles.flexGap10}>
            <InternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <MaturityDate date={lend.end_at} />
          <LendID id={lend.id} />
        </>
      )}
      {kind === 'LOAN_LEND_TERMINATION' && (
        <>
          <Date date={created_at} />
          <MaturityDate date={lend.end_at} />
          <LendID id={lend.id} />
        </>
      )}
      {kind === 'TRADE_IN' && (
        <>
          <div className={styles.flexGap10}>
            <InternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <TradeID id={trade.id} />
        </>
      )}
      {kind === 'TRADE_OUT' && (
        <>
          <div className={styles.flexGap10}>
            <ExternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <TradeID id={trade.id} />
        </>
      )}
      {kind === 'TRADE_EXCESS_RETURN' && (
        <>
          <div className={styles.flexGap10}>
            <InternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <TradeID id={trade.id} />
        </>
      )}
      {(kind === 'SAVING' || kind === 'SAVING_TOPUP') && (
        <>
          <div className={styles.flexGap10}>
            <ExternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <SavingID id={saving.id} />
        </>
      )}
      {(kind === 'SAVING_INTEREST' || kind === 'SAVING_WITHDRAWAL') && (
        <>
          <div className={styles.flexGap10}>
            <InternalWallet name={wallet.name} />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
          <SavingID id={saving.id} />
        </>
      )}
      {kind === 'CHARITY_DONATION' && (
        <>
          <ExternalWallet name={extra_content.source_address || ''} />
          <div className={styles.flexGap10}>
            <CharityFormName name={charity_form.name} direction='INCOMING' />
            <NetworkBox network={wallet.network} />
          </div>
          <Date date={created_at} />
        </>
      )}
      {kind === 'CHARITY_WITHDRAWAL_IN' && (
        <>
          <CharityFormName name={charity_form.name} direction='OUTGOING' />
          <InternalWallet name={wallet.name} />
          <Date date={created_at} />
          <CharityFormID id={charity_form.id} />
        </>
      )}
      {kind === 'CHARITY_WITHDRAWAL_OUT' && (
        <>
          <CharityFormName name={charity_form.name} direction='OUTGOING' />
          <InternalWallet name={extra_content.wallet_target_name!} />
          <Date date={created_at} />
          <CharityFormID id={charity_form.id} />
        </>
      )}
    </div>
  );
};
