import { FormatMessageFn } from 'modules/messages';
import { TransactionKind } from 'modules/transactions/types';

export const getSavingTransactionName = (
  kind: TransactionKind,
  formatMessage: FormatMessageFn
) => {
  switch (kind) {
    case 'SAVING':
      return formatMessage('saving.savingStarted');
    case 'SAVING_TOPUP':
      return formatMessage('common.savingTopup');
    case 'SAVING_INTEREST':
      return formatMessage('saving.interestPayout');
    case 'SAVING_WITHDRAWAL':
      return formatMessage('common.withdrawFunds');
    default:
      return kind;
  }
};
