import { Text } from 'common/components/Text';
import styles from './InterestAccrual.module.scss';
import { useFormatMessage } from 'modules/messages';
import { getInterestAccrualDate } from '../helpers';
import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';

type Props = { tooltipText: string; showDate?: boolean };

export const InterestAccrual: React.FC<Props> = ({
  tooltipText,
  showDate = true,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <LabelWithInfoTooltip
        text={formatMessage('saving.expectedInterestAccrual')}
        tooltipText={tooltipText}
      />
      <Text style='light1421'>{showDate ? getInterestAccrualDate() : '-'}</Text>
    </div>
  );
};
