import clsx from 'clsx';
import { ReactComponent as SortFilterIcon } from 'common/assets/black-icons/sort-filter.svg';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './Sorter.module.scss';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const Sorter: React.FC<Props> = ({ className, onClick }) => {
  const formatMessage = useFormatMessage();
  return (
    <div className={clsx(styles.container, className)} onClick={onClick}>
      <Text style='xbold1417'>
        {formatMessage('companyMembers.theader.sort')}
      </Text>
      <SortFilterIcon />
    </div>
  );
};
