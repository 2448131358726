import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from 'common/assets/black-icons/arrow-right-14px.svg';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';

type Props = { id: number };

export const LinkExpandedView: React.FC<Props> = ({ id }) => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/transactions/${id}`);
      }}
      style={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Text style='xxbold1417'>
        {formatMessage('transactions.showExtendedView', { id })}
      </Text>
      <ArrowRight />
    </div>
  );
};
