import { useCallback, useState } from 'react';
import { useFormatMessage } from 'modules/messages';
import {
  RegisterPayload,
  RegisterResponseError,
  useRegisterMutation,
} from './api';
import { StepCompanyName } from './components/StepCompanyName';
import { StepEmail } from './components/StepEmail';
import { StepPassword } from './components/StepPassword';
import { StepSuccess } from './components/StepSuccess';
import { StepUsername } from './components/StepUsername';
import { AxiosError } from 'axios';
import { isEmailTakenError } from './utils';
import { prettifyError } from 'common/utils/prettify-error';
import {
  actions,
  buildRecaptchaHeader,
  isRecaptchaError,
  useHandleRecaptchaError,
  useRecaptcha,
} from 'modules/recaptcha';
import { isFeatureDisabledError } from 'modules/feature-flags';
import { StepTermsAndConditions } from './components/StepTermsAndConditions';
import { StepSetupFailed } from './components/StepSetupFailed';

type Props = {};

type CreateCompanyStep =
  | 'userEmail'
  | 'userName'
  | 'companyName'
  | 'userPassword'
  | 'termsAndConditions'
  | 'success'
  | 'setupFailed';

export const CreateCompany: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();
  const { executeRecaptcha } = useRecaptcha();
  const { handleRecaptchaError, handleRecaptchaNotLoaded } =
    useHandleRecaptchaError();

  const [step, setStep] = useState<CreateCompanyStep>('userEmail');

  const [email, setEmail] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [password, setPassword] = useState('');

  const registerMutation = useRegisterMutation();

  const createCompany = useCallback(async () => {
    if (registerMutation.isLoading) {
      return;
    }

    if (!executeRecaptcha) {
      handleRecaptchaNotLoaded();
      return;
    }

    const token = await executeRecaptcha(actions.register);

    const payload: RegisterPayload = {
      email,
      name: userFirstName,
      surname: userSurname,
      company_name: companyName,
      password,
    };
    registerMutation.mutate(
      { payload, headers: buildRecaptchaHeader(token) },
      {
        onSuccess: () => {
          setStep('success');
        },
        onError: (error) => {
          const wasRecaptchaError = handleRecaptchaError(error);
          if (wasRecaptchaError) {
            return;
          }
          if (isEmailTakenError(error)) {
            setStep('userEmail');
          }
        },
      }
    );
  }, [
    executeRecaptcha,
    email,
    userFirstName,
    userSurname,
    companyName,
    password,
  ]);

  const getErrorText = (error: AxiosError<RegisterResponseError>) => {
    if (isEmailTakenError(error)) {
      return formatMessage('common.error.input.validation.email.exists');
    }
    if (isFeatureDisabledError(error)) {
      return formatMessage(
        'featureFlag.registrationIsCurrentlyDisabledTryLaterContactSupport'
      );
    }
    if (isRecaptchaError(error)) {
      return ''; // dont show recaptcha error on Alert, because the snackbar will appear instead
    }
    return prettifyError(error);
  };

  switch (step) {
    case 'userEmail':
      return (
        <StepEmail
          onSubmit={(emailValue) => {
            if (
              emailValue !== email &&
              (isEmailTakenError(registerMutation.error) ||
                isFeatureDisabledError(registerMutation.error))
            ) {
              registerMutation.reset();
            }
            setEmail(emailValue);
            setStep('userName');
          }}
          email={email}
          emailTakenError={isEmailTakenError(registerMutation.error)}
          header={formatMessage('signup.firstEnterEmail')}
        />
      );
    case 'userName':
      return (
        <StepUsername
          onSubmit={(firstName, surname) => {
            setUserFirstName(firstName);
            setUserSurname(surname);
            setStep('companyName');
          }}
          onGoBack={() => {
            setStep('userEmail');
          }}
          userFirstName={userFirstName}
          userSurname={userSurname}
        />
      );
    case 'companyName':
      return (
        <StepCompanyName
          onSubmit={(companyName) => {
            setCompanyName(companyName);
            setStep('userPassword');
          }}
          onGoBack={() => {
            setStep('userName');
          }}
          companyName={companyName}
        />
      );
    case 'userPassword':
      return (
        <StepPassword
          password={password}
          onSubmit={(password) => {
            setPassword(password);
            setStep('termsAndConditions');
          }}
          onGoBack={() => {
            setStep('companyName');
          }}
        />
      );
    case 'termsAndConditions':
      return (
        <StepTermsAndConditions
          error={
            registerMutation.error
              ? getErrorText(registerMutation.error)
              : undefined
          }
          isLoading={registerMutation.isLoading}
          onAccept={() => {
            registerMutation.reset();
            createCompany();
          }}
          onDecline={() => {
            setStep('setupFailed');
          }}
        />
      );
    case 'success':
      return <StepSuccess email={email} />;
    case 'setupFailed':
      return <StepSetupFailed />;
  }
};
