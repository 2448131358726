import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { get } from 'modules/api';
import { InterestRate, InterestRateKind } from './types';

export const INTEREST_RATES_URL = '/interest-rates';

export const getInterestRatesQueryKey = (kind: InterestRateKind) =>
  ['GET', INTEREST_RATES_URL, kind] as const;

const getInterestRates = async (kind: InterestRateKind) => {
  const response = await get<InterestRate[]>(
    `${INTEREST_RATES_URL}?kind=${kind}`
  );
  return response.data;
};

export const useInterestRates = (
  kind: InterestRateKind,
  options?: UseQueryOptions<InterestRate[], AxiosError<any>>
) =>
  useQuery<InterestRate[], AxiosError<any>>(
    getInterestRatesQueryKey(kind),
    () => getInterestRates(kind),
    {
      ...options,
    }
  );

type SnapshotError = { token?: string[] };

export const tokenIsRequired = (errorResp?: SnapshotError) => {
  for (let err of errorResp?.token || []) {
    const match = err.match(ERR_TOKEN_IS_REQUIRED);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const tokenInvalidValue = (errorResp?: SnapshotError) => {
  for (let err of errorResp?.token || []) {
    const match = err.match(ERR_TOKEN_INVALID_VALUE);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const ERR_TOKEN_IS_REQUIRED = /This field is required./;
export const ERR_TOKEN_INVALID_VALUE = /Invalid value./;
