import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { NetworkEnum } from 'common/types';
import { get, LONG_STALE_TIME, patch, post, remove } from 'modules/api';
import { WhitelistedAddress } from './types';

export const WHITELISTED_ADDRESS_URL = '/whitelisted-address';

const getWhitelistedAddress = () =>
  get<WhitelistedAddress[]>(WHITELISTED_ADDRESS_URL);

export const useWhitelistedAddressQuery = () => {
  return useQuery<AxiosResponse<WhitelistedAddress[]>, AxiosError<any>>(
    ['GET', WHITELISTED_ADDRESS_URL],
    getWhitelistedAddress,
    {
      staleTime: LONG_STALE_TIME,
    }
  );
};

export type CreateWhitelistedAddressPayload = {
  name: string;
  address: string;
  network: NetworkEnum;
};

export type WhitelistedAddressResponseError = {
  name?: string[];
  address?: string[];
  network?: string[];
  non_fields_errors?: string[];
  detail?: string;
  status?: string;
};

const postWhitelistedAddress = (
  payload: CreateWhitelistedAddressPayload,
  headers?: AxiosRequestHeaders
) =>
  post<WhitelistedAddress, CreateWhitelistedAddressPayload>(
    WHITELISTED_ADDRESS_URL,
    payload,
    { headers }
  );

export type CreateWhitelistedAddressRequest = {
  payload: CreateWhitelistedAddressPayload;
  headers?: AxiosRequestHeaders;
};

export const useWhitelistAddressPostMutation = () => {
  return useMutation<
    AxiosResponse<WhitelistedAddress>,
    AxiosError<WhitelistedAddressResponseError>,
    CreateWhitelistedAddressRequest
  >(['POST', WHITELISTED_ADDRESS_URL], ({ payload, headers }) =>
    postWhitelistedAddress(payload, headers)
  );
};

export const ERR_NAME_USED = 'Name for whitelisted address already used.';
export const ERR_ADDRESS_USED = 'Whitelisted address already exists.';
export const ERR_INVALID_ADDRESS = (network: NetworkEnum) =>
  `Invalid ${network} address.`;
export const ERR_NAME_TOO_LONG =
  'Ensure this field has no more than 40 characters.';

export type DeleteWhitelistedAddressResponseData = {
  detail: string;
};

export type DeleteWhitelistedAddressPayload = {
  id: number;
};

const deleteWhitelistedAddress = (id: number, headers?: AxiosRequestHeaders) =>
  remove<DeleteWhitelistedAddressResponseData, DeleteWhitelistedAddressPayload>(
    `${WHITELISTED_ADDRESS_URL}/${id}`,
    { headers }
  );

export type DeleteWhitelistedAddressRequest = {
  id: number;
  headers?: AxiosRequestHeaders;
};

export const useWhitelistAddressDeleteMutation = () => {
  return useMutation<
    AxiosResponse<DeleteWhitelistedAddressResponseData>,
    AxiosError<WhitelistedAddressResponseError>,
    DeleteWhitelistedAddressRequest
  >(['DELETE', WHITELISTED_ADDRESS_URL], ({ id, headers }) => {
    return deleteWhitelistedAddress(id, headers);
  });
};

export type PatchWhitelistedAddressPayload = {
  id: number;
  name: string;
};

export const patchWhitelistedAddress = (
  payload: PatchWhitelistedAddressPayload,
  headers?: AxiosRequestHeaders
) => {
  return patch<WhitelistedAddress, PatchWhitelistedAddressPayload>(
    `${WHITELISTED_ADDRESS_URL}/${payload.id}`,
    payload,
    { headers }
  );
};

export type PatchWhitelistedAddressRequest = {
  payload: PatchWhitelistedAddressPayload;
  headers?: AxiosRequestHeaders;
};

export const useWhitelistAddressPatchMutation = () => {
  return useMutation<
    AxiosResponse<WhitelistedAddress>,
    AxiosError<WhitelistedAddressResponseError>,
    PatchWhitelistedAddressRequest
  >(['PATCH', WHITELISTED_ADDRESS_URL], ({ payload, headers }) => {
    return patchWhitelistedAddress(payload, headers);
  });
};
