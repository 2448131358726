import { createContext, useContext } from 'react';
import { CharityFormPublicRead, FormDonationPublicRead } from './types';

type CharityFormPublicContextValue = {
  donations: FormDonationPublicRead[];
  form: CharityFormPublicRead;
};

export const CharityFormPublicContext =
  createContext<CharityFormPublicContextValue>({
    donations: [],
    form: {} as CharityFormPublicRead,
  });

export const useCharityFormPublicContext = () => {
  return useContext(CharityFormPublicContext);
};
