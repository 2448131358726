import { useCallback } from 'react';
import { TransactionUpdatedEvent } from '../types/transaction-updated-event';
import { Transaction, TransactionDetails } from 'modules/transactions/types';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters';
import {
  buildTransactionsInfiniteUrl,
  findTransaction,
  insertTransactionToTransactionsInfinite,
  matchesFilters,
  removeTransactionFromTransactionsInfinite,
  updateTransactionInTransactionsInfinite,
} from './utils-transactions';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  PAGINATED_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  TransactionsResponse,
  getTransactionDetailsQueryKey,
} from 'modules/transactions/api';
import { get } from 'modules/api';
import { sortBy } from 'lodash';
import { PAGE_SIZE } from 'common/consts/consts';
import { useIsSigner, useUserQuery } from 'modules/user';
import { useLoginContext } from 'modules/login/context';
import { log } from 'modules/logger';

export const useHandleTransactionUpdated = () => {
  const queryClient = useQueryClient();

  const { filters, page, setPage } = useInfiniteTransactionsContext();

  const { isLoggedIn } = useLoginContext();

  const { data: { data: user } = {} } = useUserQuery({
    enabled: isLoggedIn,
  });
  const { data: isSigner } = useIsSigner({
    enabled: isLoggedIn,
  });

  const handleTransactionUpdated = useCallback(
    (event: TransactionUpdatedEvent) => {
      log('handling TRANSACTION_UPDATED WebSocket event', event);

      // there is potential for rare race condition here: if TRANASCTION_UPDATED event arrives before /api/user is fetched,
      // then userAction will be null and also as a consequence requiresAction might be set to true even if user already performed an action
      const userAction =
        event.actions.find((action) => action.user.email === user?.email)
          ?.action || null;

      const requiresAction =
        event.transaction.type === 'OUTGOING' &&
        event.transaction.substate === 'PENDING_SIGNATURE' &&
        !userAction;

      const updatedTransaction: Transaction = {
        ...event.transaction,
        requires_action: requiresAction,
        user_action: userAction,
      };

      // refresh count if the user is a signer, also if it is unknown yet if the user is a signer, update as well in case the user is signer.
      if (typeof isSigner === 'undefined' || isSigner) {
        queryClient.invalidateQueries(
          TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
        );
      }

      queryClient.setQueryData(
        DASHBOARD_TRANSACTIONS_QUERY_KEY,
        (prevState: Transaction[] | undefined) => {
          if (typeof prevState === 'undefined') {
            return undefined;
          }

          const prevIdx = prevState.findIndex(
            (t) => t.id === updatedTransaction.id
          );

          if (prevIdx === -1) {
            return undefined;
          }

          const prevRequiresAction = prevState[prevIdx].requires_action;

          const nextState: typeof prevState = [
            ...prevState.slice(0, prevIdx),
            updatedTransaction,
            ...prevState.slice(prevIdx + 1),
          ];

          const nextStateSorted = sortBy(nextState, [
            'requires_action',
            (t) => new Date(t.created_at),
          ]).reverse(); // reverse needed, because sortBy sorts in ascending order

          const nextIdx = nextStateSorted.findIndex(
            (t) => t.id === updatedTransaction.id
          );

          // if transaction goes from requires_transaction true to false and ends up as last element,
          // then refetch to check if newer (or another pending) transaction should not replace it as last item on dashboard.
          if (
            updatedTransaction.requires_action !== prevRequiresAction &&
            nextIdx === PAGE_SIZE - 1
          ) {
            queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
            return undefined;
          }

          return nextStateSorted;
        }
      );

      queryClient.setQueriesData(
        PAGINATED_TRANSACTIONS_QUERY_KEY,
        (prevState: InfiniteData<TransactionsResponse> | undefined) => {
          if (typeof prevState === 'undefined') {
            return undefined;
          }

          const matches = matchesFilters(updatedTransaction, filters);

          const found = findTransaction(updatedTransaction.id, prevState);

          if (matches && found) {
            return updateTransactionInTransactionsInfinite(
              updatedTransaction,
              prevState,
              found.pageIdx
            );
          }

          if (matches && !found) {
            return insertTransactionToTransactionsInfinite(
              updatedTransaction,
              prevState,
              filters
            );
          }

          if (!matches && found) {
            // remove from cache

            const lastPageIdx = prevState.pages.length - 1;
            const lastPage = prevState.pages[lastPageIdx];

            // this is impossible situation, but przezorny zawsze ubezpieczony.
            if (!lastPage) {
              return prevState;
            }

            const isEveryPageFetched = lastPage.next === null;

            const intermediateState = removeTransactionFromTransactionsInfinite(
              updatedTransaction,
              prevState
            );

            // if last page has been removed entirely, decrement current page number in infinite transactions state
            if (intermediateState.pages.length < prevState.pages.length) {
              setPage(page - 1);
            }

            if (!isEveryPageFetched) {
              // refetch last page
              get<TransactionsResponse>(
                buildTransactionsInfiniteUrl(lastPageIdx + 1, filters, {
                  withApiBaseUrl: false,
                })
              ).then((response) => {
                const refetchedLastPage = response.data;
                queryClient.setQueriesData(
                  PAGINATED_TRANSACTIONS_QUERY_KEY,
                  (
                    prevIntermediateState:
                      | InfiniteData<TransactionsResponse>
                      | undefined
                  ) => {
                    if (!prevIntermediateState) {
                      return undefined; // not really possible
                    }
                    const nextState: typeof prevIntermediateState = {
                      ...prevIntermediateState,
                      pages: prevIntermediateState.pages.map((page, pageIdx) =>
                        pageIdx === lastPageIdx ? refetchedLastPage : page
                      ),
                    };
                    return nextState;
                  }
                );
              });
            }

            return intermediateState;
          }

          // doesn't match and is not in cache - do nothing
          return prevState;
        }
      );

      queryClient.setQueryData(
        getTransactionDetailsQueryKey(event.transaction.id),
        (prevState: TransactionDetails | undefined) => {
          if (typeof prevState === 'undefined') {
            return undefined;
          }

          const nextState: typeof prevState = {
            ...prevState,
            state: event.transaction.state,
            substate: event.transaction.substate,
            requires_action: requiresAction,
            user_action: userAction,
          };

          return nextState;
        }
      );
    },
    [filters, isSigner, page, queryClient, user]
  );

  return handleTransactionUpdated;
};
