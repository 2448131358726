import { PageLoader } from 'common/components/loader';
import { NewBorrow as NewBorrowFeature } from 'modules/financial-ops/borrow';
import { LimitedNavigationLayout } from 'modules/layout';
import { useLoginRequired } from 'modules/login';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const NewBorrow: React.FC<{}> = () => {
  const { isLoading } = useLoginRequired();

  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.newBorrow')}</title>
      </Helmet>
      {isLoading ? (
        <PageLoader />
      ) : (
        <LimitedNavigationLayout>
          <NewBorrowFeature />
        </LimitedNavigationLayout>
      )}
    </>
  );
};
