import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { post } from 'modules/api';

export const ERR_LIMIT_SENDING_EMAIL =
  'Email verification link was already sent. You can request a new link in';

export const LOGIN_URL = '/login';

export type LoginPayload = {
  email: string;
  password: string;
};

export type LoginResponseData = {
  email: string;
  first_login: boolean;
  company_role: string;
  status: string;
  detail: string;
  secret_key: string;
  uri: string;
};

export type LoginResponseError = {
  detail?: string;
  email?: string[];
  password?: string[];
  non_field_errors?: string[];
  status?: string;
};

export const login = (payload: LoginPayload, headers?: AxiosRequestHeaders) =>
  post<LoginResponseData, LoginPayload>(LOGIN_URL, payload, { headers });

export type LoginRequest = {
  payload: LoginPayload;
  headers?: AxiosRequestHeaders;
};

export const useLoginMutation = () =>
  useMutation<
    AxiosResponse<LoginResponseData>,
    AxiosError<LoginResponseError>,
    LoginRequest
  >(['POST', LOGIN_URL], ({ payload, headers }) => login(payload, headers));

export const ERR_ACCESS_DENIED =
  'Your access has been denied. To regain the access to your account contact the workspace owner.';

const ERR_COMPANY_SUSPENDED_MEMBER =
  'Company account blocked. Please contact the workspace owner.';
const ERR_COMPANY_SUSPENDED_OWNER =
  'Company account blocked. Please contact Montes Auri support to unlock the access.';

export const isCompanySuspendedError = (
  error: AxiosError<LoginResponseError> | null
) => {
  if (
    error?.response?.data.non_field_errors?.includes(
      ERR_COMPANY_SUSPENDED_MEMBER
    )
  ) {
    return { isCompanyOwner: false }; // object evaluates to true + conveys more information
  }
  if (
    error?.response?.data.non_field_errors?.includes(
      ERR_COMPANY_SUSPENDED_OWNER
    )
  ) {
    return { isCompanyOwner: true }; // true
  }
  return undefined; // false
};
