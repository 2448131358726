import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

type Props = {
  children?: React.ReactNode;
};

export const RecaptchaProvider: React.FC<Props> = ({ children }) => {
  const key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  if (!key) {
    console.error('no recaptcha key');
    return <>{children}</>;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={key}>
      {children}
    </GoogleReCaptchaProvider>
  );
};
