import { LoaderBox } from 'common/components/loader';
import { Section } from 'common/components/Section';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useBorrowByIdQuery,
  useBorrowHistoryByIdQuery,
  useBorrowMeasuresByIdQuery,
} from '../api';
import { DEFAULT_PERIOD } from './borrow-ltv-chart';
import styles from './BorrowDetails.module.scss';
import { BorrowDetailsHeader } from './BorrowDetailsHeader';
import { BorrowDetailsLTV } from './BorrowDetailsLTV';
import { BorrowDetailsMainBox } from './BorrowDetailsMainBox';
import { BorrowHistory } from './BorrowHistory';
import { SectionHeader } from 'common/components/SectionHeader';
import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { BorrowTopup } from '../borrow-topup';
import { KyxTooltip, useIsKyxCompleted } from 'modules/kyx';
import { ButtonBorrowRepay } from './ButtonBorrowRepay';
import { BorrowTopupBar } from './BorrowTopupBar';

export const BorrowDetails: React.FC<{}> = () => {
  const navigate = useNavigate();
  const formatMessage = useFormatMessage();

  const { id } = useParams();

  const { data, isLoading, error } = useBorrowByIdQuery(parseInt(id!));

  // start fetching data faster before respective components are mounted
  useBorrowHistoryByIdQuery(parseInt(id!));
  useBorrowMeasuresByIdQuery(parseInt(id!), DEFAULT_PERIOD);

  const featureFlag = useRequireFeatureFlag('BORROW');
  const [isOpenTopup, setIsOpenTopup] = useState(false);
  const isKyxCompleted = useIsKyxCompleted();

  if (featureFlag.isLoading) {
    return <LoaderBox />;
  }

  if (!featureFlag.data?.enabled) {
    return null;
  }

  if (!id) {
    return <NothingToDisplay />;
  }

  return (
    <>
      <BorrowTopupBar />
      <Section>
        <SectionHeader
          header={
            <BorrowDetailsHeader
              onArrowClick={() => navigate('/borrows')}
              borrow={data?.data}
            />
          }
          actions={
            <>
              <ButtonBorrowRepay
                borrow={data?.data}
                className={styles.button}
              />
              {!data?.data.auto_topup &&
                !isLoading &&
                data?.data.state !== 'COMPLETED' &&
                data?.data.state !== 'CANCELLED' &&
                data?.data.state !== 'LIQUIDATED' &&
                data?.data.state !== 'LIQUIDATING' && (
                  <KyxTooltip
                    extraTitle={
                      data?.data.has_pending_topup_transaction
                        ? formatMessage('borrow.borrowHasTopupTransaction')
                        : ''
                    }
                  >
                    <span className={styles.button}>
                      <Button
                        type='secondary'
                        name={formatMessage('common.topUp')}
                        onClick={() => setIsOpenTopup(true)}
                        isLoading={isLoading}
                        disabled={
                          !isKyxCompleted ||
                          data?.data.has_pending_topup_transaction
                        }
                        className={styles.button}
                      />
                    </span>
                  </KyxTooltip>
                )}
            </>
          }
          actionsClassName={styles.buttons}
          className={styles.header}
        />
        {data?.data && (
          <>
            <BorrowDetailsMainBox borrow={data?.data} />
            <div className={styles.ltvAndHistory}>
              <BorrowDetailsLTV borrowId={parseInt(id)} />
              <BorrowHistory borrowId={parseInt(id)} />
            </div>
          </>
        )}
        {isLoading && <LoaderBox />}
        {error && <NothingToDisplay />}
        <BorrowTopup
          open={isOpenTopup}
          onClose={() => {
            setIsOpenTopup(false);
          }}
          id={parseInt(id!)}
          borrow={data?.data}
        />
      </Section>
    </>
  );
};
