import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import styles from './ConfirmationBox.module.scss';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import clsx from 'clsx';
import { useActiveTransactionsDataQuery } from 'modules/transactions/hook';

export const ConfirmationBox = () => {
  const formatMessage = useFormatMessage();
  const { data, isLoading } = useActiveTransactionsDataQuery();

  return (
    <div className={clsx(styles.container, !!data && styles.containerYellow)}>
      <Text style='xxbold1619'>
        {formatMessage('dashboard.sectionTransacions.confirmationBox.header')}
      </Text>
      <div className={styles.subTitle}>
        {isLoading ? (
          <RotatingLoader size={16} type='secondary' />
        ) : (
          <Text style='xxbold3030'>{data}</Text>
        )}
        <Text style='light1417' className={styles.textPending}>
          {formatMessage(
            'dashboard.sectionTransacions.confirmationBox.transactionsToConfirm',
            { transactions: data }
          )}
        </Text>
      </div>
    </div>
  );
};
