import { ReactComponent as NetworkBoxesIcon } from 'common/assets/boxes.svg';
import { Text } from 'common/components/Text';
import { NetworkEnum } from 'common/types';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { useFormatMessage } from 'modules/messages';

import styles from './NetworkBox.module.scss';
import clsx from 'clsx';

type Props = {
  className?: string;
  network?: NetworkEnum;
};

export const NetworkBox: React.FC<Props> = ({ className, network }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={clsx(styles.networkBox, className)}>
      <NetworkBoxesIcon />{' '}
      <Text style='light1215' colorStyle='secondary' dataTest='networkName'>
        {network ? displayNetwork(network) : formatMessage('common.network')}
      </Text>
    </div>
  );
};
