import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Search } from 'common/components/search/Search';
import { useState } from 'react';
import { NothingToDisplay } from 'modules/nothing-to-display';
import styles from './SelectCurrencyStep.module.scss';
import { CurrencyItem } from './CurrencyItem';
import { Currency } from '../types';

type Props = {
  onSelect: (currency: Currency) => void;
  currencies: Currency[] | undefined;
};

export const SelectCurrencyStep: React.FC<Props> = ({
  onSelect,
  currencies,
}) => {
  const formatMessage = useFormatMessage();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredList = currencies?.filter(
    (token) =>
      token.currency.toLowerCase().includes(searchQuery.toLowerCase()) &&
      token.wallets.length > 0
  );

  return (
    <>
      <ModalHeader>
        {formatMessage('selectCurrency.stepSelectCurrency.header')}
      </ModalHeader>
      <ModalBody dataTest='selectCurrencyModal'>
        <Search onFilter={setSearchQuery} dataTest='searchAsset' />
        {!!filteredList?.length && (
          <div className={styles.container}>
            {filteredList.map((element, index) => (
              <CurrencyItem
                selectedCurrency={element}
                onSelect={onSelect}
                key={index}
                showArrow={element.wallets.length > 1}
              />
            ))}
          </div>
        )}
        {!filteredList?.length && <NothingToDisplay />}
      </ModalBody>
      <ModalFooter>
        <ModalActions> </ModalActions>
      </ModalFooter>
    </>
  );
};
