import { CurrencyEnum } from 'common/types';
import Decimal from 'decimal.js';
import { useCurrencyRatesQuery, useFiatCurrency } from 'modules/fiat';
import { toDecimal } from 'modules/input-amount';
import { useWallets } from './api';
import { Wallet } from './types';

export const useWalletsTotalValue = () => {
  const { data, isLoading, isError } = useWallets();

  const {
    data: prices,
    isLoading: isPricesLoading,
    isError: isPricesError,
  } = useFiatPrices();

  const totalFiatValue =
    data && prices
      ? data
          .flatMap((wallet) => [...wallet.balances])
          .reduce((acc, next) => {
            const amount = next.amount;
            const price = prices[next.currency];
            return acc.add(toDecimal(amount).mul(price));
          }, new Decimal(0))
      : undefined;

  return {
    data: totalFiatValue,
    isLoading: isLoading || isPricesLoading,
    isError: isError || isPricesError,
  };
};

export const useWalletTotalValue = (walletId: Wallet['id']) => {
  const { data, isLoading } = useWallets();

  const { data: prices, isLoading: isPricesLoading } = useFiatPrices();

  const wallet = data
    ? data.find((wallet) => wallet.id === walletId)
    : undefined;

  const walletTotalValue =
    wallet && prices
      ? wallet.balances.reduce((acc, next) => {
          const amount = next.amount;
          const price = prices[next.currency];
          return acc.add(toDecimal(amount).mul(price));
        }, new Decimal(0))
      : undefined;

  return {
    data: walletTotalValue,
    isLoading: isLoading || isPricesLoading,
  };
};

export const useGetWalletTotalValue = () => {
  const { data } = useWallets();

  const { data: prices } = useFiatPrices();

  const getWalletTotalValue = (walletId: Wallet['id']) => {
    const wallet = data
      ? data.find((wallet) => wallet.id === walletId)
      : undefined;

    const walletTotalValue =
      wallet && prices
        ? wallet.balances.reduce((acc, next) => {
            const amount = next.amount;
            const price = prices[next.currency];
            return acc.add(toDecimal(amount).mul(price));
          }, new Decimal(0))
        : undefined;

    return walletTotalValue;
  };

  return getWalletTotalValue;
};

const useFiatPrices = () => {
  const {
    data: fiatCurrency,
    isLoading: isFiatCurrencyLoading,
    isError: isFiatCurrencyError,
  } = useFiatCurrency();

  const {
    data: currencyRates,
    isLoading: isCurrencyRatesLoading,
    isError: isCurrencyRatesError,
  } = useCurrencyRatesQuery();

  const prices =
    currencyRates && fiatCurrency
      ? currencyRates.data.reduce(
          (acc, next) => ({
            ...acc,
            [next.currency]:
              fiatCurrency === 'EUR'
                ? next.price_eur
                : fiatCurrency === 'USD'
                ? next.price_usd
                : '0',
          }),
          {} as { [currency in CurrencyEnum]: string }
        )
      : undefined;

  return {
    data: prices,
    isLoading: isFiatCurrencyLoading || isCurrencyRatesLoading,
    isError: isFiatCurrencyError || isCurrencyRatesError,
  };
};

export const useAreNoWalletsCreated = () => {
  const query = useWallets();

  return {
    ...query,
    data: !query.data?.filter((data) => data.state !== 'PENDING').length,
  };
};
