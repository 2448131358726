import { MultimediaProps } from '../types';

export const reorderFiles = (
  list: MultimediaProps[],
  sourceIndex: number,
  destinationIndex: number
) => {
  const result: MultimediaProps[] = Array.from(list);

  // A video file must not occupy the first place, so when a user attempts to move the video to the first position, we instead move it to the second position.

  if (result[sourceIndex].kind === 'VIDEO' && destinationIndex === 0) {
    destinationIndex = 1;
  }
  const [removed] = result.splice(sourceIndex, 1);

  result.splice(destinationIndex, 0, removed);

  return result;
};

export const matchYoutubeUrl = (url: string) => {
  var p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(p)) {
    return url.match(p)![1];
  }
  return false;
};

export const HTTP_HTTPS_REGEX = /(http|https)?:\/\/(\S+)/;
export const YOUTUBE_ID_REGEX =
  /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
