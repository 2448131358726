import { useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { useCallback } from 'react';
import {
  LENDS_QUERY_KEY,
  LENDS_SUMMARY_QUERY_KEY,
  lendDetailsQueryKey,
} from 'modules/financial-ops/lend/api';
import { LendRead } from 'modules/financial-ops/lend/types';
import { log } from 'modules/logger';
import { LendUpdatedEvent } from '../types/lend-updated-event';

export const useHandleLendUpdated = () => {
  const queryClient = useQueryClient();

  const handleLendUpdated = useCallback(
    (event: LendUpdatedEvent) => {
      log('handling LEND_UPDATED WebSocket event', event);

      queryClient.setQueryData(
        LENDS_QUERY_KEY,
        (prevState: LendRead[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          const idx = prevState.findIndex((lend) => lend.id === event.lend.id);

          // just for caution, but this should be impossible situation
          if (idx === -1) {
            return undefined;
          }

          return sortBy(
            [
              ...prevState.slice(0, idx),
              event.lend,
              ...prevState.slice(idx + 1),
            ],
            (l) => (l.state === 'CREATED' ? 2 : l.state === 'ACTIVE' ? 1 : 0),
            (l) => new Date(l.created_at)
          ).reverse(); // reverse needed, because sortBy sorts in ascending order
        }
      );

      queryClient.setQueryData(
        lendDetailsQueryKey(event.lend.id),
        (prevState: LendRead | undefined) => {
          if (typeof prevState === 'undefined') {
            return undefined;
          }

          return event.lend;
        }
      );

      queryClient.invalidateQueries(LENDS_SUMMARY_QUERY_KEY);
    },
    [queryClient]
  );

  return handleLendUpdated;
};
