import { useCallback } from 'react';

import debounce from 'lodash/debounce';
import { useFormatMessage } from 'modules/messages';
import { toFixed } from 'modules/input-amount';
import {
  BORROW_QUERY_KEY,
  BorrowTopupEffectPayload,
  BorrowTopupEffectResponseData,
  BorrowTopupEffectResponseError,
  borrowHasPendingTopup,
  borrowIsNotActive,
  notEnoughFunds,
  useBorrowTopupEffectMutation,
} from '../api';
import { useBorrowTopupContext } from './context';
import { useQueryClient } from '@tanstack/react-query';

type InputData = {
  amount?: string;
};

export const useCalculateEffect = () => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const context = useBorrowTopupContext();
  const mutation = useBorrowTopupEffectMutation(context.borrow?.id!);

  const onEffectCalculated = useCallback(
    (
      effect: BorrowTopupEffectResponseData,
      payload: BorrowTopupEffectPayload
    ) => {
      context.setErrorAlert('');
      context.setAmountError('');
    },
    []
  );

  const onEffectError = useCallback(
    (
      error: BorrowTopupEffectResponseError,
      payload: BorrowTopupEffectPayload
    ) => {
      if (notEnoughFunds(error)) {
        context.setAmountError(formatMessage('common.notEnoughFundsToTopup'));
      }
      if (borrowHasPendingTopup(error)) {
        context.setAmountError(
          formatMessage('borrow.borrowHasTopupTransaction')
        );
      }

      if (borrowIsNotActive(error)) {
        context.setErrorAlert(
          formatMessage('borrow.topupIsNotAvailableForBorrow')
        );
      }
    },
    []
  );

  const mutateDebounced = useCallback(
    debounce((payload: BorrowTopupEffectPayload) => {
      mutation.mutate(payload, {
        onSuccess: (response) => {
          onEffectCalculated(response.data, payload);
        },
        onError: (err) => {
          if (err.response) {
            onEffectError(err.response?.data, payload);
            if (context.borrow)
              queryClient.invalidateQueries(
                BORROW_QUERY_KEY(context.borrow.id)
              );
          }
        },
      });
    }, 200),
    []
  );

  // take input from last user interaction, merge it with previous input stored in context, and call /effect endpoint
  const calculateEffect = (inputData: InputData) => {
    const data: InputData = {
      ...inputData,
    };

    mutateDebounced({
      amount: toFixed(data.amount),
    });
  };

  return { calculateEffect, mutation };
};
