import { Text } from 'common/components/Text';
import { BalanceChartController } from 'modules/balance-chart';
import { FiatAmount } from 'modules/fiat';
import { FundsLockedAsCollateral } from 'modules/financial-ops/borrow/borrow-overview';
import { FundsLockedInLends } from 'modules/financial-ops/lend/lend-overview';
import { useFormatMessage } from 'modules/messages';
import styles from './BalanceSummary.module.scss';
import { useBalanceSummaryData } from './hooks';
import { FundsLockedInSavings } from 'modules/financial-ops/savings-account/saving-overview';

export const BalanceSummary: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const {
    lendFeatureFlag,
    borrowFeatureFlag,
    savingFeatureFlag,
    lendsSummary,
    borrowsSummary,
    savingsSummary,
    balance,
    isLendsSummaryLoading,
    isBorrowsSummaryLoading,
    isSavingsSummaryLoading,
    isBalanceLoading,
    isFeatureFlagsLoading,
    isError,
  } = useBalanceSummaryData();

  // show nothing when feature flags are loading (or error), after that summary component structure can be displayed and individual loaders used to wait for further data
  if (isFeatureFlagsLoading || isError) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.fields}>
        <div className={styles.field}>
          <Text style='light1215'>
            {formatMessage('dashboard.totalPortfolioBalance')}
          </Text>
          <FiatAmount
            amount={balance?.data.total_balance}
            loaderContainerSize={56}
            loaderSize={40}
            isDataLoading={isBalanceLoading}
            textColorStyle='primary'
            textStyle='xxbold4056'
          />
          <Text style='light1215' colorStyle='secondary'>
            {formatMessage(
              'dashboard.assetsWithUnknownBalanceNotAddedToTheBalance'
            )}
          </Text>
        </div>
        <div className={styles.field}>
          <Text style='light1215'>
            {formatMessage('dashboard.availableBalance')}
          </Text>
          <FiatAmount
            amount={balance?.data.available_balance}
            loaderContainerSize={28}
            loaderSize={20}
            isDataLoading={isBalanceLoading}
            textColorStyle={'primary'}
            textStyle={'xbold2028'}
          />
        </div>
        <div className={styles.horizontalFields}>
          {lendFeatureFlag?.enabled && (
            <div className={styles.field}>
              <FundsLockedInLends
                amount={lendsSummary?.current_principal_amount_fiat}
                isLoading={isLendsSummaryLoading}
                textStyle={'xxbold1420'}
                loaderSize={14}
                loaderContainerSize={20}
              />
            </div>
          )}
          {savingFeatureFlag?.enabled && (
            <div className={styles.field}>
              <FundsLockedInSavings
                amount={savingsSummary?.data.current_amount_fiat}
                isLoading={isSavingsSummaryLoading}
                textStyle={'xxbold1420'}
                loaderSize={14}
                loaderContainerSize={20}
              />
            </div>
          )}
          {borrowFeatureFlag?.enabled && (
            <div className={styles.field}>
              <FundsLockedAsCollateral
                amount={borrowsSummary?.data.current_collateral_amount_fiat}
                isLoading={isBorrowsSummaryLoading}
                textStyle={'xxbold1420'}
                showTooltip={false}
                loaderSize={14}
                loaderContainerSize={20}
              />
            </div>
          )}
        </div>
      </div>
      <BalanceChartController />
    </div>
  );
};
