import clsx from 'clsx';
import { Text, textStyles } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { ReactComponent as VerifiedIcon } from '../assets/verified-icon.svg';
import styles from './CharityFormHeader.module.scss';
import { useCharityFormPublicContext } from '../context';

export const CharityFormHeader: React.FC<{}> = () => {
  const { form } = useCharityFormPublicContext();

  return (
    <header className={styles.container}>
      <VerifiedByMontesauri />
      <h1 className={clsx(styles.name, textStyles.xxbold3644)}>{form.name}</h1>
      <p className={clsx(styles.overview, textStyles.light1827)}>
        {form.overview}
      </p>
    </header>
  );
};

const VerifiedByMontesauri: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  return (
    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
      <VerifiedIcon />
      <Text style='light1215' inlineStyle={{ color: '#315DCC' }}>
        {formatMessage('charityForms.organizationVerifiedByMontesauri')}
      </Text>
    </div>
  );
};
