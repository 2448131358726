import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './SettingsMenu.module.scss';
import { ReactComponent as GlobalIcon } from 'common/assets/grey-icons/settings.svg';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { GlobalSettings } from './GlobalSettings';
import { useIsOwner } from 'modules/user';
import { LoaderBox } from 'common/components/loader';
import { ReactComponent as AccountIcon } from 'common/assets/grey-icons/account.svg';
import { AccountSettings } from './AccountSettings';

type Subpage = 'global' | 'account';

export const SettingsMenu: React.FC = () => {
  const formatMessage = useFormatMessage();
  const { data: isOwner, isLoading } = useIsOwner();
  const [subpage, setSubpage] = useState<Subpage>();

  useEffect(() => {
    if (!isLoading && isOwner !== undefined) {
      // setSubpage(isOwner ? 'global' : 'account');
      setSubpage('global');
    }
  }, [isOwner]);

  return (
    <>
      <div className={styles.container}>
        {!isLoading && (
          <div className={styles.options}>
            {isOwner && (
              <div
                onClick={() => {
                  setSubpage('global');
                }}
                className={clsx(
                  styles.button,
                  subpage === 'global' && styles.buttonActive
                )}
              >
                <GlobalIcon />
                <Text style='bold1417'>{formatMessage('settings.global')}</Text>
              </div>
            )}
            {/* <div
              className={clsx(
                styles.button,
                subpage === 'account' && styles.buttonActive
              )}
              onClick={() => {
                if (isOwner) {
                  setSubpage('account');
                }
              }}
            >
              <AccountIcon />
              <Text style='bold1417'>{formatMessage('settings.account')}</Text>
            </div> */}
          </div>
        )}
        <div>
          {subpage === 'global' && <GlobalSettings />}
          {/* {subpage === 'account' && <AccountSettings />} */}
        </div>
      </div>
      {isLoading && <LoaderBox />}
    </>
  );
};
