import { Topbar } from 'modules/navigation';
import styles from './LimitedNavigationLayout.module.scss';
import { MontesauriLogotype } from './MontesauriLogotype';

type Props = {
  children?: React.ReactNode;
};

export const LimitedNavigationLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className={styles.topContainer} data-test='topBanner'>
        <MontesauriLogotype path={'/dashboard'} />
        <Topbar showFeatureButtons={false} />
      </div>
      <div className={styles.mainContainer}>{children}</div>
    </>
  );
};
