import { ReactComponent as BtcIcon } from 'common/assets/tokens/btc.svg';
import { ReactComponent as EthIcon } from 'common/assets/tokens/eth.svg';
import { ReactComponent as UsdcIcon } from 'common/assets/tokens/usdc.svg';
import { ReactComponent as UsdtIcon } from 'common/assets/tokens/usdt.svg';
import { ReactComponent as WbtcIcon } from 'common/assets/tokens/wbtc.svg';

import { ReactComponent as BtcGreyIcon } from 'common/assets/tokens/grey-373737/btc.svg';
import { ReactComponent as EthGreyIcon } from 'common/assets/tokens/grey-373737/eth.svg';
import { ReactComponent as UsdcGreyIcon } from 'common/assets/tokens/grey-373737/usdc.svg';
import { ReactComponent as UsdtGreyIcon } from 'common/assets/tokens/grey-373737/usdt.svg';
import { ReactComponent as WbtcGreyIcon } from 'common/assets/tokens/grey-373737/wbtc.svg';

import { ReactComponent as PlaceholderIcon } from 'common/assets/tokens/placeholder.svg';
import { CurrencyEnum } from 'common/types';
import clsx from 'clsx';
import styles from './CurrencyIcon.module.scss';

const placeholderIcon = <PlaceholderIcon />;

const currencyIcons = {
  BTC: <BtcIcon />,
  ETH: <EthIcon />,
  USDC: <UsdcIcon />,
  USDT: <UsdtIcon />,
  WBTC: <WbtcIcon />,
};

const currencyGrayIcons = {
  BTC: <BtcGreyIcon />,
  ETH: <EthGreyIcon />,
  USDC: <UsdcGreyIcon />,
  USDT: <UsdtGreyIcon />,
  WBTC: <WbtcGreyIcon />,
};

export type IconStyle = 'whiteColor' | 'blackTransparent';

export const icons: { readonly [key in IconStyle]: any } = {
  whiteColor: currencyIcons,
  blackTransparent: currencyGrayIcons,
};

export type IconSizes = 'size15' | 'size20' | 'size24' | 'size34';

export const iconSizes: { readonly [key in IconSizes]: string } = {
  size15: styles.size15,
  size20: styles.size20,
  size24: styles.size24,
  size34: styles.size34,
};

type Props = {
  currencyName?: CurrencyEnum;
  iconStyle?: IconStyle;
  iconSize?: IconSizes;
};

export const CurrencyIcon: React.FC<Props> = ({
  currencyName,
  iconStyle = 'whiteColor',
  iconSize = 'size24',
}) => {
  return (
    <span className={clsx(iconSizes[iconSize])}>
      {currencyName ? icons[iconStyle][currencyName] : placeholderIcon}
    </span>
  );
};
