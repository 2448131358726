import { InputShort } from 'common/components/input/InputShort';

import { useEffect, useState } from 'react';

import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './ThresholdWithInput.module.scss';

type Props = {
  value: number;
  onChange: (v: number) => void;
  max: number;
  dataTest?: string;
  error: string;
  onError: (err: string) => void;
};

export const ThresholdWithInput: React.FC<Props> = ({
  value,
  onChange,
  max,
  dataTest,
  error,
  onError,
}) => {
  const formatMessage = useFormatMessage();
  const [valueText, setValueText] = useState(`${value}`);

  useEffect(() => {
    if (value !== +valueText) setValueText(`${value}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const setValues = (data: string) => {
    onChange(+data);
    setValueText(data);
  };

  const validateAndChange = (data: string) => {
    const numberData = +data;

    if (
      !Number.isInteger(numberData) ||
      data.includes('.') ||
      data.includes('+')
    ) {
      // Error is set as a space because input takes the errror value as a string,
      // and here we just want a frame without additional text
      onError(' ');
      return;
    } else {
      onError('');
    }

    if (data === '') {
      setValues(data);
      return;
    }

    if (numberData <= 0) {
      setValues('1');
      return;
    }

    if (numberData >= max) {
      setValues(`${max}`);
      return;
    }

    setValues(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.thresholdContainer}>
        <InputShort
          onChange={(e) => {
            validateAndChange(e.target.value);
          }}
          value={valueText}
          errorText={error}
        />
        <Text style='bold3048' dataTest={dataTest}>{`/ ${max}`}</Text>
      </div>

      <div className={styles.descriptionContainer}>
        <Text style='light1425'>
          {value === 1
            ? formatMessage('multiapproval.ownerApproval')
            : formatMessage(
                'multiapproval.approvalsAreRequiredToExecuteTransaction'
              )}
        </Text>
      </div>
    </div>
  );
};
