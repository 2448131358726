import { useState } from 'react';
import { Alert } from 'common/components/Alert';
import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { prettifyError } from 'common/utils/prettify-error';
import { useFormatMessage } from 'modules/messages';
import { Assets } from './components/Assets';
import { Wallets } from './components/Wallets';
import { ViewMode } from './types';
import { useWallets } from './api';
import { CreateWallet } from './CreateWallet';
import { TotalAssetFiatValue } from './components/TotalAssetFiatValue';

type Props = {};

export const AssetsAndWallets: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const [viewMode, setViewMode] = useState<ViewMode>('wallets');

  const {
    data: wallets,
    isLoading: isWalletsLoading,
    isError: isWalletsError,
    error: walletsError,
  } = useWallets();

  return (
    <Section>
      <SectionHeader
        header={formatMessage('wallets.header')}
        actions={<CreateWallet />}
      />
      {isWalletsError && (
        <Alert
          severity='error'
          text={prettifyError(walletsError)}
          style={{ marginBottom: '20px' }}
        />
      )}
      <TotalAssetFiatValue />
      {viewMode === 'assets' && (
        <Assets
          wallets={wallets || []}
          isWalletsLoading={isWalletsLoading}
          viewMode={viewMode}
          onViewModeChange={(mode) => setViewMode(mode)}
        />
      )}
      {viewMode === 'wallets' && (
        <Wallets
          wallets={wallets || []}
          isWalletsLoading={isWalletsLoading}
          viewMode={viewMode}
          onViewModeChange={(mode) => setViewMode(mode)}
        />
      )}
    </Section>
  );
};
