import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { PAGE_SIZE } from 'common/consts/consts';
import { CurrencyEnum, NetworkEnum } from 'common/types';
import { get, post } from 'modules/api';
import {
  FeatureFlagDisabledError,
  useFeatureFlag,
  useFeatureFlagDisabledHandler,
} from 'modules/feature-flags';
import { CurrencyRate } from 'modules/fiat';
import { Instrument, InstrumentLimits, TradeOptions } from './new-trade/types';
import { WalletSimple } from 'modules/assets-and-wallets/types';

export const EXCHANGE_INSTRUMENTS_URL = '/exchange-instruments';
export const EXCHANGE_INSTRUMENT_LIMITS_URL =
  '/exchange-instruments/{id}/limits?price_tolerance_pct={tolerance}';

export const EXCHANGE_INSTRUMENT_LIMITS_KEY = (
  id?: number,
  tolerance?: string
) => ['GET', EXCHANGE_INSTRUMENT_LIMITS_URL, id, tolerance] as const;

export const EXCHANGE_INSTRUMENTS_QUERY_KEY = [
  'GET',
  EXCHANGE_INSTRUMENTS_URL,
] as const;

export const TRADE_EFFECT_URL = '/trades/effect';
export const TRADES_URL = '/trades';
export const POST_TRADES_QUERY_KEY = ['POST', TRADES_URL] as const;
export const PAGINATED_TRADES_QUERY_KEY = ['GET_PAGINATE', TRADES_URL];
export const TRADES_QUERY_KEY = ['GET', TRADES_URL] as const;

export type TradesCalculationBase = 'CURRENCY_1' | 'CURRENCY_2';

export type TradesEffectPayload = {
  exchange_instrument_id: number;
  side: TradeOptions;
  amount?: string;
  calculation_base: TradesCalculationBase;
  price_tolerance_pct: string;
  summary: boolean;
};

export type TradesEffectResponseData = {
  side: TradeOptions;
  currency_1_amount: string;
  currency_2_amount: string;
  fee: string;
  fee_currency: CurrencyEnum;
  order_price: string;
  limit_price: string;
  token: string;
};

export type TradesEffectResponseError = {
  side?: string[];
  non_field_errors?: string[];
  exchange_instrument_id?: string[];
  amount?: string[];
  calculation_base?: string[];
  price_tolerance_pct?: string[];
} & FeatureFlagDisabledError;

export type GetExchangeInstrumentsResponseError = FeatureFlagDisabledError;

export type PostTradeResponseData = {
  transaction_id: number;
};

export type PostTradeError = {
  side: string;
  exchange_instrument_id: string;
  amount?: string[];
  calculation_base?: string[];
  price_tolerance_pct?: string[];
  currency_1_wallet_id?: string[];
  currency_2_wallet_id?: string[];
  order_price?: string[];
  token?: string[];
} & FeatureFlagDisabledError;

export type PostTradePayload = {
  exchange_instrument_id: number;
  side: TradeOptions;
  amount: string;
  calculation_base: TradesCalculationBase;
  price_tolerance_pct: string;
  currency_1_wallet_id: number;
  currency_2_wallet_id: number;
  order_price: string;
  token: string;
};

export type TradeState = 'FAILED' | 'COMPLETED';

export type TradeSubstate = 'UNKNOWN_ERROR' | 'LIMIT_PRICE_NOT_MET';

export type Trade = {
  id: number;
  exchange_instrument_id: number;
  side: TradeOptions;
  amount: string;
  calculation_base: TradesCalculationBase;
  price_tolerance_pct: string;
  currency_1: CurrencyEnum;
  currency_2: CurrencyEnum;
  currency_1_amount: string;
  currency_2_amount: string;
  currency_1_wallet: WalletSimple;
  currency_2_wallet: WalletSimple;
  currency_2_rate: CurrencyRate;
  currency_1_rate: CurrencyRate;
  fee: string;
  fee_currency: CurrencyEnum;
  order_price: string;
  limit_price: string;
  final_price: string;
  final_price_change_pct: string;
  state: TradeState;
  substate: TradeSubstate;
  executed_at: string;
};
export const getCurrencies = () => {
  return get<Instrument[]>(EXCHANGE_INSTRUMENTS_URL);
};

export const useGetExchangeInstrumentsQuery = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('TRADE');

  return useQuery<
    AxiosResponse<Instrument[]>,
    AxiosError<GetExchangeInstrumentsResponseError>
  >(EXCHANGE_INSTRUMENTS_QUERY_KEY, getCurrencies, {
    enabled: !!featureFlag.data?.enabled,
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const getCurrencyLimits = (id?: number, tolerance?: string) => {
  return get<InstrumentLimits[]>(
    `${EXCHANGE_INSTRUMENT_LIMITS_URL.replace(
      '{id}',
      id ? id.toString() : ''
    ).replace('{tolerance}', tolerance ?? '')}`
  );
};

export const useGetExchangeInstrumentLimitQuery = (
  id?: number,
  tolerance?: string
) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('TRADE');

  return useQuery<
    AxiosResponse<InstrumentLimits[]>,
    AxiosError<GetExchangeInstrumentsResponseError>
  >(
    EXCHANGE_INSTRUMENT_LIMITS_KEY(id, tolerance),
    () => getCurrencyLimits(id, tolerance),
    {
      enabled: !!featureFlag.data?.enabled && !!id,
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

const postTradeEffect = (payload: TradesEffectPayload) =>
  post<TradesEffectResponseData, TradesEffectPayload>(
    TRADE_EFFECT_URL,
    payload
  );

export const useTradeEffectMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<TradesEffectResponseData>,
    AxiosError<TradesEffectResponseError>,
    TradesEffectPayload
  >(['POST', TRADE_EFFECT_URL], postTradeEffect, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

const postTrade = (payload: PostTradePayload) =>
  post<PostTradeResponseData, PostTradePayload>(TRADES_URL, payload);

export const useTradeMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<PostTradeResponseData>,
    AxiosError<PostTradeError>,
    PostTradePayload
  >(POST_TRADES_QUERY_KEY, postTrade, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export type TradesResponseData = {
  count: number;
  next: number;
  previous: number;
  results: Trade[];
};

export type TradesResponseError = {} & FeatureFlagDisabledError;

export const getTrades = (page: number) =>
  get<TradesResponseData>(`${TRADES_URL}?page=${page}&page_size=${PAGE_SIZE}`);

export const useTradesQuery = (page: number = 1) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  const query = useQuery<
    AxiosResponse<TradesResponseData>,
    AxiosError<TradesResponseError>
  >(TRADES_QUERY_KEY, () => getTrades(page), {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
  return query;
};

export const getTradesInfinite = (
  pageParam = 1,
  date?: string,
  sort?: string,
  filter?: string
) =>
  get<TradesResponseData>(
    `${TRADES_URL}?page=${pageParam}&page_size=${PAGE_SIZE}&date=${date}${
      sort ? `&sort=${sort}` : ''
    }${filter ? `&state=${filter}` : ''}`
  );

type TradeInfiniteParams = {
  date?: string;
  sort?: string;
  filter?: string;
};

export const useTradesInfiniteQuery = ({
  date,
  sort,
  filter,
}: TradeInfiniteParams) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useInfiniteQuery<
    AxiosResponse<TradesResponseData>,
    AxiosError<TradesResponseError>
  >(
    PAGINATED_TRADES_QUERY_KEY,
    ({ pageParam }) => getTradesInfinite(pageParam, date, sort, filter),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const amountMustLessError = (
  errResponseData?: TradesEffectResponseError | PostTradeError
) => {
  for (let err of errResponseData?.amount || []) {
    const match = err.match(AMOUNT_MUST_BE_LESS_OR_EQUAL);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const amountMustGreaterError = (
  errResponseData?: TradesEffectResponseError | PostTradeError
) => {
  for (let err of errResponseData?.amount || []) {
    const match = err.match(AMOUNT_MUST_BE_GREATER_OR_EQUAL);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const ensureMaxDecimalPlaces = (
  errResponseData?: TradesEffectResponseError
) => {
  for (let err of errResponseData?.amount || []) {
    const match = err.match(ENSURE_NO_MORE_THAN_TWO_DECIMAL_PLACES);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const ERR_NOT_ENOUGH_FUNDS = 'Not enough funds to create a trade.';

const AMOUNT_MUST_BE_LESS_OR_EQUAL =
  /Amount must be less or equal to (\d+.\d+)/;

const AMOUNT_MUST_BE_GREATER_OR_EQUAL =
  /Amount must be greater or equal to (\d+.\d+)/;

const ENSURE_NO_MORE_THAN_TWO_DECIMAL_PLACES =
  /Ensure that there are no more than (\d+) decimal places./;
