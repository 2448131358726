import { CurrencyEnum, NetworkEnum } from 'common/types';
import { TermEnum } from 'modules/financial-ops/common';
import { useLocation } from 'react-router-dom';

type SavingLocationState = {
  currency?: CurrencyEnum;
  network?: NetworkEnum;
  term?: TermEnum;
  walletId?: number;
};

export const useParams = () => {
  const location = useLocation() as { state?: SavingLocationState };
  return { ...location.state };
};
