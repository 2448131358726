import { createContext, useContext, useState } from 'react';
import { SetState, NetworkEnum } from 'common/types';
import { FiatCurrencyEnum } from 'modules/fiat';
import { CustomColor, MultimediaProps } from '../types';
import { useUserQuery } from 'modules/user';

type NewCharityFormContextValue = {
  name: string;
  overview: string;
  description: string;
  youtubeLink: string | undefined;
  youtubeLinkIndex: number | undefined;
  darkMode: boolean;
  primaryColor: string;
  secondaryColor: string | undefined;
  goalEnabled: boolean;
  endAtEnabled: boolean;
  endAt: string | undefined;
  networkList: NetworkEnum[] | undefined;
  files: MultimediaProps[] | undefined;
  picturesIds: number[] | undefined;
  goalAmount: string | undefined;
  logo: MultimediaProps | undefined;
  customColors: CustomColor[] | undefined;
  fiatCurrency: FiatCurrencyEnum | undefined;
  selectedColorId: number;
  setName: SetState<string>;
  setOverview: SetState<string>;
  setDescription: SetState<string>;
  setYoutubeLink: SetState<string | undefined>;
  setPrimaryColor: SetState<string>;
  setDarkMode: SetState<boolean>;
  setGoalAmount: SetState<string | undefined>;
  setGoalEnabled: SetState<boolean>;
  setEndAtEnabled: SetState<boolean>;
  setEndAt: SetState<string | undefined>;
  setNetworkList: SetState<NetworkEnum[] | undefined>;
  setFiles: SetState<MultimediaProps[] | undefined>;
  setLogo: SetState<MultimediaProps | undefined>;
  setCustomColors: SetState<CustomColor[] | undefined>;
  setSecondaryColor: SetState<string | undefined>;
  reset: () => void;
  setSelectedColorId: SetState<number>;
};

const NewCharityFormContext = createContext<NewCharityFormContextValue>({
  name: '',
  overview: '',
  description: '',
  youtubeLink: undefined,
  youtubeLinkIndex: undefined,
  darkMode: true,
  primaryColor: '',
  goalEnabled: false,
  endAtEnabled: false,
  endAt: undefined,
  networkList: ['ETHEREUM'],
  files: undefined,
  goalAmount: undefined,
  logo: undefined,
  customColors: undefined,
  fiatCurrency: undefined,
  secondaryColor: undefined,
  picturesIds: undefined,
  selectedColorId: 0,
  setName: () => {},
  setOverview: () => {},
  setDescription: () => {},
  setYoutubeLink: () => {},
  setPrimaryColor: () => {},
  setDarkMode: () => {},
  setGoalEnabled: () => {},
  setGoalAmount: () => {},
  setEndAtEnabled: () => {},
  setEndAt: () => {},
  setNetworkList: () => {},
  setFiles: () => {},
  setLogo: () => {},
  setCustomColors: () => {},
  setSecondaryColor: () => {},
  reset: () => {},
  setSelectedColorId: () => {},
});

export const NewCharityFormContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [name, setName] = useState('');
  const [overview, setOverview] = useState('');
  const [description, setDescription] = useState('');
  const [youtubeLink, setYoutubeLink] = useState<string>();
  const [darkMode, setDarkMode] = useState(true);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState<string>();
  const [goalAmount, setGoalAmount] = useState<string>();
  const [goalEnabled, setGoalEnabled] = useState(false);
  const [endAtEnabled, setEndAtEnabled] = useState(false);
  const [endAt, setEndAt] = useState<string>();
  const [networkList, setNetworkList] = useState<NetworkEnum[]>();
  const [files, setFiles] = useState<MultimediaProps[]>();
  const [logo, setLogo] = useState<MultimediaProps>();
  const [customColors, setCustomColors] = useState<CustomColor[]>();
  const [selectedColorId, setSelectedColorId] = useState<number>(0);

  const { data: user } = useUserQuery();

  const fiatCurrency = user?.data.fiat_currency;

  const youtubeLinkIndex = files?.findIndex((file) => file.kind === 'VIDEO');

  const picturesIds = files
    ?.filter((file) => file.kind === 'PHOTO')
    .map((file) => file.id);

  const reset = () => {
    setName('');
    setOverview('');
    setDescription('');
    setYoutubeLink(undefined);
    setPrimaryColor('');
    setDarkMode(true);
    setGoalEnabled(false);
    setEndAtEnabled(false);
    setEndAt(undefined);
    setNetworkList(undefined);
    setFiles(undefined);
    setGoalAmount(undefined);
    setLogo(undefined);
    setCustomColors(undefined);
    setSecondaryColor(undefined);
    setSelectedColorId(0);
  };

  return (
    <NewCharityFormContext.Provider
      value={{
        name,
        overview,
        description,
        youtubeLink,
        youtubeLinkIndex:
          youtubeLinkIndex !== undefined && youtubeLinkIndex !== -1
            ? youtubeLinkIndex + 1
            : undefined,
        picturesIds,
        darkMode,
        primaryColor,
        goalEnabled,
        endAtEnabled,
        endAt,
        networkList,
        files,
        goalAmount,
        logo,
        customColors,
        fiatCurrency,
        secondaryColor,
        selectedColorId,
        setName,
        setOverview,
        setDescription,
        setYoutubeLink,
        setPrimaryColor,
        setDarkMode,
        setGoalEnabled,
        setEndAtEnabled,
        setEndAt,
        setNetworkList,
        setFiles,
        setGoalAmount,
        setLogo,
        setCustomColors,
        setSecondaryColor,
        setSelectedColorId,
        reset,
      }}
    >
      {children}
    </NewCharityFormContext.Provider>
  );
};

export const useNewCharityFormContext = () => {
  return useContext(NewCharityFormContext);
};
