import styles from './ActivePill.module.scss';
import { ReactComponent as RemoveIcon } from '../../assets/remove.svg';
import { Text } from 'common/components/Text';

export const ActivePill: React.FC<{
  header: string;
  text: string;
  onClick: () => void;
}> = ({ header, text, onClick }) => {
  return (
    <div className={styles.container}>
      <Text style='light1425' colorStyle='secondary'>{`${header}${
        text ? ': ' + text : ''
      }`}</Text>
      <RemoveIcon onClick={onClick} style={{ cursor: 'pointer' }} />
    </div>
  );
};
