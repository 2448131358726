import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useLoginContext } from 'modules/login/context';
import { log } from 'modules/logger';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters';
import { wsURL } from './api';
import { useHandleMessage } from './handlers';

export const useWebsocketSubscription = () => {
  const queryClient = useQueryClient();

  const { isLoggedIn } = useLoginContext();

  const handleMessage = useHandleMessage();

  const { setRefetchTransactionsFlag } = useInfiniteTransactionsContext();

  // handleMessage might and will change, this is normal behaviour, but to prevent
  // websocket reconnect on every handleMessage change, it is stored in ref, and every time
  // message arrives, it will be handled by current version of handleMessage.
  const handleMessageRef = useRef(handleMessage);
  useEffect(() => {
    handleMessageRef.current = handleMessage;
  }, [handleMessageRef, handleMessage]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    let intentionalClose = true;
    let websocket: WebSocket | undefined;

    const connect = () => {
      websocket = new WebSocket(wsURL);

      websocket.addEventListener('open', () => {
        log(`WebSocket log msg: connection to ${wsURL} opened.`);

        // if reconnect after unintentional close, refresh data to accomodate for potentially missed events.
        if (!intentionalClose) {
          queryClient.invalidateQueries();
          setRefetchTransactionsFlag(true);
        }

        intentionalClose = false;
      });

      websocket.addEventListener('close', () => {
        log(`WebSocket log msg: connection to ${wsURL} closed.`);
        if (intentionalClose) {
          return;
        }
        log(`WebSocket log msg: reconnecting to ${wsURL}...`);
        connect();
      });

      websocket.addEventListener('error', (event) => {
        log('WebSocket log msg: error', event);
      });

      websocket.addEventListener('message', (event) => {
        handleMessageRef.current(event);
      });
    };

    connect();

    return () => {
      if (!websocket) {
        return;
      }
      intentionalClose = true;
      websocket.close();
    };
  }, [isLoggedIn, handleMessageRef, queryClient]);
};
