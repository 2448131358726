import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { ActiveTransactionsNotificationCircle } from 'modules/notifications/ActiveTransactionsNotificationCircle';
import styles from './Transactions.module.scss';
import { ExportButton } from './components/ExportButton';
import { TransactionsTable } from './components/TransactionsTable';
import { TransactionFilters } from './filters';
import { ActiveFilters } from './filters/components/ActiveFilters';

export const Transactions: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  return (
    <Section>
      <SectionHeader
        header={
          <div className={styles.header}>
            <Text style='xxbold3644'>
              {formatMessage('transactions.header')}
            </Text>
            <ActiveTransactionsNotificationCircle />
          </div>
        }
        actions={<ExportButton />}
      />
      <TransactionFilters />
      <ActiveFilters />
      <TransactionsTable />
    </Section>
  );
};
