import clsx from 'clsx';
import {
  CurrencyIcon,
  IconSizes,
  IconStyle,
} from 'common/components/CurrencyIcon';
import { Text, TextStyle } from 'common/components/Text';
import { CurrencyEnum, NetworkEnum } from 'common/types';
import { useFormatMessage } from 'modules/messages';
import { NetworkBox } from '../NetworkBox';

import styles from './CurrencyBox.module.scss';

type Props = {
  currency?: CurrencyEnum;
  network?: NetworkEnum;
  isPlaceholder?: boolean;
  iconStyle?: IconStyle;
  textStyle?: TextStyle;
  iconSize?: IconSizes;
  dataTest?: string;
  className?: string;
};

export const CurrencyBox: React.FC<Props> = ({
  currency,
  network,
  isPlaceholder = false,
  iconStyle,
  textStyle = 'light1622',
  iconSize,
  dataTest,
  className,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={clsx(styles.container, className)} data-test={dataTest}>
      {isPlaceholder ? (
        <CurrencyIcon iconSize={iconSize} />
      ) : (
        <CurrencyIcon
          currencyName={currency}
          iconStyle={iconStyle}
          iconSize={iconSize}
        />
      )}
      <div>
        <Text style={textStyle} colorStyle={'primary'} dataTest='currencyName'>
          {currency ?? formatMessage('common.currency')}
        </Text>
        <NetworkBox network={network} />
      </div>
    </div>
  );
};
