import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { SummaryLabel } from 'common/components/Summary';
import { useFormatMessage } from 'modules/messages';

export const MaturitySummaryLabel: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  return (
    <SummaryLabel>
      <LabelWithInfoTooltip
        textStyle='light1215'
        text={formatMessage('finopsCommon.maturity')}
        tooltipText={formatMessage('finopsCommon.maturity.tooltip')}
      />
    </SummaryLabel>
  );
};
