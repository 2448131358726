export const Separator = () => (
  <div
    style={{
      margin: '20px 0',
      width: '100%',
      height: '1px',
      background: 'var(--border-light)',
    }}
  />
);
