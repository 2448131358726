import { Text } from 'common/components/Text';
import { ReactComponent as FalseIcon } from 'common/assets/black-icons/false-5px.svg';
import { ReactComponent as OkIcon } from 'common/assets/black-icons/ok-8px.svg';
import { ReactComponent as EmptyDotIcon } from 'common/assets/black-icons/empty-dot-8px.svg';
import { TransactionUserAction } from 'modules/transactions/types';
import { useFormatMessage } from 'modules/messages';

type Props = {
  threshold: number;
  approvals: number;
  owner_action: TransactionUserAction;
};

export const Multiapproval: React.FC<Props> = ({
  threshold,
  approvals,
  owner_action,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text style='light1215' inlineStyle={{ display: 'block' }}>
        {formatMessage('common.multiapproval') + ':'}
      </Text>
      <div>
        {approvals !== threshold && <FalseIcon />}
        {approvals === threshold && <OkIcon />}&nbsp;
        <Text style='light1215'>
          {formatMessage('transactions.approvals', {
            approvals,
            threshold,
          })}
        </Text>
      </div>
      <div>
        {owner_action === null && <EmptyDotIcon />}
        {owner_action === 'APPROVE' && <OkIcon />}
        {owner_action === 'REJECT' && <FalseIcon />}&nbsp;
        <Text style='light1215'>
          {formatMessage('transactions.ownerApproval')}
        </Text>
      </div>
    </div>
  );
};
