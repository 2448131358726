import { useIntl, IntlProvider } from 'react-intl';

import messages from './en-US.json';

export type MessageDict = typeof messages;
export type MessageKey = keyof typeof messages;

type Props = {
  children: React.ReactNode;
};

export const MessageProvider: React.FC<Props> = ({ children }) => {
  const locale = 'en-US';
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export const useFormatMessage = () => {
  const { formatMessage } = useIntl();

  // simplify formatMessage API
  const simpleFormatMessage = (
    id: MessageKey,
    values?: {
      [key: string]: string | number | boolean | null | undefined | Date;
    }
  ) => formatMessage({ id }, values);

  return simpleFormatMessage;
};

export type FormatMessageFn = ReturnType<typeof useFormatMessage>;
