import { Button } from 'common/components/button';
import { NetworkBadge } from 'common/components/NetworkBadge';
import { Text } from 'common/components/Text';
import { NetworkEnum } from 'common/types';
import { CopyableAddress } from 'modules/assets-and-wallets/components/CopyableAddress';
import { useFormatMessage } from 'modules/messages';
import { useIsOwner } from 'modules/user';
import { useState } from 'react';
import styles from './AddressRow.module.scss';
import { EditModal } from './components/EditModal';
import { RemoveModal } from './components/RemoveModal';

export type AddressItem = {
  name: string;
  network: NetworkEnum;
  address: string;
  id: number;
};

type Props = {
  item: AddressItem;
};

export const AddressRow: React.FC<Props> = ({ item }) => {
  const formatMessage = useFormatMessage();
  const { data: isOwner } = useIsOwner();
  const [openEdit, setOpenEdit] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  return (
    <div className={styles.row} data-test='addressRow'>
      <div className={styles.cellName} data-test='nameCell'>
        <Text style={'bold1622'}>{item.name}</Text>
      </div>
      <div className={styles.cellNetwork} data-test='networkCell'>
        <NetworkBadge network={item.network} />
      </div>
      <div className={styles.cellAddress} data-test='addressCell'>
        <CopyableAddress address={item.address} />
      </div>
      {isOwner && (
        <div className={styles.cellActions}>
          <Button
            type='secondarySmall'
            name={formatMessage('common.button.edit')}
            onClick={() => setOpenEdit(true)}
          />
          <Button
            type='secondarySmall'
            name={formatMessage('common.button.remove')}
            onClick={() => setOpenRemove(true)}
          />
        </div>
      )}
      <EditModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        oldName={item.name}
        id={item.id}
      />
      <RemoveModal
        open={openRemove}
        onClose={() => setOpenRemove(false)}
        item={item}
      />
    </div>
  );
};
