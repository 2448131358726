import { Text } from 'common/components/Text';
import styles from './BubbleChartPlaceholder.module.scss';

type Props = {
  text?: string;
};

export const BubbleChartPlaceholder: React.FC<Props> = ({ text }) => {
  return (
    <div className={styles.root}>
      <Text style='light1215' colorStyle='secondary'>
        {text}
      </Text>
    </div>
  );
};
