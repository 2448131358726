import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { generateHeader2FA } from 'modules/2FA';
import { get, LONG_STALE_TIME, post, remove } from 'modules/api';
import { CompanyRole } from 'modules/user';

export const INVITATIONS_URL = '/invitations';
export const INVITATIONS_QUERY_KEY = '/invitations';
export const MEMBERS_URL = '/members';
export const MEMBERS_QUERY_KEY = '/members';
export const MEMBERS_DELETED_URL = '/members/deleted';

export const MEMBERS_DELETED_QUERY_KEY = ['GET', MEMBERS_DELETED_URL] as const;

export const ERR_LIMIT_SENDING_INVITATION_AGAIN =
  'You will be able to resent invitation in';

export type InviteResponseError = {
  email?: string;
  status?: string;
  detail: string;
};

export type InvitePayload = {
  email: string;
  name: string;
  surname: string;
  message?: string;
};

type GetMemberResponseError = {
  status?: string;
};

export const invite = (payload: InvitePayload, headers?: AxiosRequestHeaders) =>
  post<void, InvitePayload>(INVITATIONS_URL, payload, { headers });

export type InviteRequest = {
  payload: InvitePayload;
  headers?: AxiosRequestHeaders;
};
export const useInviteMutation = (extraQueryKey?: string) => {
  const mutation = useMutation<
    AxiosResponse<void>,
    AxiosError<InviteResponseError>,
    InviteRequest
  >(['POST', INVITATIONS_QUERY_KEY, extraQueryKey], ({ payload, headers }) =>
    invite(payload, headers)
  );
  return mutation;
};

export type Invitation = {
  email: string;
  name: string;
  surname: string;
};

export type GetInvitationsResponseData = Invitation[];

export type Member = {
  id: number;
  email: string;
  name: string;
  surname: string;
  company_role: CompanyRole;
  is_signer: boolean;
};

export type GetMembersResponseData = Member[];

export const getInvitations = () => {
  return get<GetInvitationsResponseData>(INVITATIONS_URL);
};

export const getMembers = () => {
  return get<GetMembersResponseData>(MEMBERS_URL);
};

export const useInvitationsQuery = (
  options?: UseQueryOptions<
    AxiosResponse<GetInvitationsResponseData>,
    AxiosError<any>
  >
) => {
  const query = useQuery<
    AxiosResponse<GetInvitationsResponseData>,
    AxiosError<any>
  >(['GET', INVITATIONS_QUERY_KEY], getInvitations, {
    staleTime: LONG_STALE_TIME,
    ...options,
  });
  return query;
};

export const useMembersQuery = (
  options?: UseQueryOptions<
    AxiosResponse<GetMembersResponseData>,
    AxiosError<any>
  >
) => {
  const query = useQuery<
    AxiosResponse<GetMembersResponseData>,
    AxiosError<GetMemberResponseError>
  >(['GET', MEMBERS_QUERY_KEY], getMembers, {
    staleTime: LONG_STALE_TIME,
    ...options,
  });
  return query;
};

const deleteMember = (memberId: number, otp: string) =>
  remove<DeleteMemberResposeData, void>(`${MEMBERS_URL}/${memberId}`, {
    headers: generateHeader2FA(otp),
  });

type DeleteMemberResposeData = {};

type DeleteMemberResponseError = {
  status?: string;
  detail?: string;
};

export const useDeleteMemberMutation = () => {
  return useMutation<
    AxiosResponse<DeleteMemberResposeData>,
    AxiosError<DeleteMemberResponseError>,
    { memberId: number; otp: string }
  >(['DELETE', MEMBERS_URL], ({ memberId, otp }) =>
    deleteMember(memberId, otp)
  );
};

type DeletedMemberResponseError = {
  status?: string;
  detail?: string;
};

export const useDeletedMembersQuery = (
  options?: UseQueryOptions<
    AxiosResponse<GetMembersResponseData>,
    AxiosError<DeletedMemberResponseError>
  >
) => {
  const query = useQuery<
    AxiosResponse<GetMembersResponseData>,
    AxiosError<GetMemberResponseError>
  >(MEMBERS_DELETED_QUERY_KEY, getDeletedMembers, {
    staleTime: LONG_STALE_TIME,
    ...options,
  });
  return query;
};

export const getDeletedMembers = () => {
  return get<GetMembersResponseData>(MEMBERS_DELETED_URL);
};
