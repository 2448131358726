import clsx from 'clsx';
import { FiatSwitch } from 'modules/fiat';
import { useNotificationsMenuContext } from 'modules/notifications/context';
import { ReferenceClock } from 'modules/reference-clock';
import { HelpCenterButton } from 'modules/settings/components/HelpCenterButton';
import { SettingsButton } from 'modules/settings/components/SettingsButton';
import { LogoutButton, UserInfo } from 'modules/user';
import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import styles from './HamburgerMenu.module.scss';
import { Sidebar } from './Sidebar';
import { useHamburgerMenuContext } from './context';

type Props = {};

export const HamburgerMenu: React.FC<Props> = () => {
  const { isOpen, closeHamburger, toggleHamburger } = useHamburgerMenuContext();
  const { closeNotifications } = useNotificationsMenuContext();

  const isNewBorrowPage = useMatch('/borrow/new-borrow');
  const isNewLendPage = useMatch('/lends/new-lend');
  const isLimitedNavigation = isNewBorrowPage || isNewLendPage;

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.bodyScrollLock);
    } else {
      document.body.classList.remove(styles.bodyScrollLock);
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={clsx(styles.burgerIcon, isOpen && styles.closeIcon)}
        onClick={() => {
          toggleHamburger();
          closeNotifications();
        }}
      />
      {isOpen && (
        <>
          <div className={styles.container}>
            <div>
              <UserInfo className={styles.userInfoContainer} />
              {!isLimitedNavigation && (
                <>
                  <Sidebar />
                  <div className={styles.settingButtonsContainer}>
                    <SettingsButton />
                    <HelpCenterButton />
                  </div>
                </>
              )}
            </div>
            <div
              className={clsx(
                styles.bottomContainer,
                isLimitedNavigation && styles.topBorder
              )}
            >
              <div className={styles.fiatSwitchContainer}>
                <ReferenceClock />
                <FiatSwitch />
              </div>
              <LogoutButton />
            </div>
          </div>
          <div
            className={styles.catchClickContainer}
            onClick={closeHamburger}
          />
        </>
      )}
    </>
  );
};
