import { useMatch } from 'react-router-dom';
import { Link } from 'common/components/Link';
import { Text } from 'common/components/Text';
import styles from './SidebarButton.module.scss';
import clsx from 'clsx';
import { useHamburgerMenuContext } from '../context';

export type SidebarProps = {
  to: string;
  icon: React.ReactNode;
  iconActive: React.ReactNode;
  text: string;
  dataTest?: string;
  position?: number;
  notification?: React.ReactNode;
  onClick?: () => void;
};

export const SidebarButton: React.FC<SidebarProps> = ({
  to,
  icon,
  iconActive,
  text,
  dataTest,
  notification,
  onClick,
}) => {
  const match = useMatch(to);
  const { closeHamburger } = useHamburgerMenuContext();

  return (
    <Link to={to} className={styles.link}>
      <div
        className={clsx(
          styles.container,
          match && styles.containerCurrentUrlMatched,
          notification && styles.withNotification
        )}
        onClick={() => {
          closeHamburger();
          if (onClick) onClick();
        }}
        data-test={dataTest}
      >
        <span className={styles.textWithIcon}>
          {match ? iconActive : icon}
          <Text style='bold1417'>{text}</Text>
        </span>
        {notification && <span className={styles.icon}>{notification}</span>}
      </div>
    </Link>
  );
};
