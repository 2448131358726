import clsx from 'clsx';
import styles from './Section.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  dataTest?: string;
};

export const ConfigSection: React.FC<Props> = ({ children, className }) => (
  <div className={clsx(styles.section, styles.configSection, className)}>
    {children}
  </div>
);

export const SummarySection: React.FC<Props> = ({ children, dataTest }) =>
  children ? (
    <div
      className={clsx(styles.section, styles.summarySection)}
      data-test={dataTest}
    >
      {children}
    </div>
  ) : (
    <div className={styles.summarySection} />
  );
