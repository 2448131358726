import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { LinkBox } from './LinkBox';
import { buildCharityFormIframeCode } from 'modules/charity-forms-public';

type Props = { token: string };

export const IframeCode: React.FC<Props> = ({ token }) => {
  const formatMessage = useFormatMessage();

  const link = buildCharityFormIframeCode(token);

  return (
    <div>
      <Text style='xxbold1619' inlineStyle={{ display: 'block' }}>
        {formatMessage('charityForms.iframeCode')}
      </Text>
      <LinkBox
        link={link}
        text={formatMessage('charityForms.iframeCodeCopiedToClipboard')}
      />
    </div>
  );
};
