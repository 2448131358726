import { SummaryAsset } from 'modules/financial-ops/common';
import { BubbleChart } from 'modules/financial-ops/common/components/overview-components';
import { BubbleChartPlaceholder } from 'modules/financial-ops/common/components/overview-components/BubbleChartPlaceholder';
import { toDecimal } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';

export const SavingBubbleChart: React.FC<{
  assets?: SummaryAsset[];
}> = ({ assets }) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      {assets?.some((item) => toDecimal(item.share_pct).greaterThan(0)) ? (
        <BubbleChart items={assets} id='saving-overview-bubble-chart' />
      ) : (
        <BubbleChartPlaceholder
          text={formatMessage('saving.noFundsLockedInSaving')}
        />
      )}
    </>
  );
};
