import clsx from 'clsx';
import styles from './InputShort.module.scss';
import { BasicInputProps } from './types';
import { Input } from './Input';
import { textStyles } from 'common/components/Text';

export const InputShort: React.FC<BasicInputProps> = ({
  className,
  ...inputProps
}) => {
  return (
    <Input
      {...inputProps}
      className={clsx(styles.container, textStyles.bold3048, className)}
    />
  );
};
