import { LimitedNavigationLayout } from 'modules/layout';
import { FeatureUnavailable as FeatureUnavailableFeature } from 'modules/feature-flags';
import { Helmet } from 'react-helmet-async';
import { useFormatMessage } from 'modules/messages';

export const FeatureUnavailable: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.featureUnavailable')}</title>
      </Helmet>
      <LimitedNavigationLayout>
        <FeatureUnavailableFeature />
      </LimitedNavigationLayout>
    </>
  );
};
