import styles from './Tooltip.module.scss';
import { default as MuiTooltip } from '@mui/material/Tooltip';
import clsx from 'clsx';

type Props = {
  children: React.ReactElement;
  title: string;
  tooltipClassName?: string;
};

export const Tooltip: React.FC<Props> = ({
  children,
  title,
  tooltipClassName,
}) => {
  return (
    <MuiTooltip
      placement='top'
      arrow
      enterDelay={0}
      enterTouchDelay={0}
      classes={{
        tooltip: clsx(styles.customTooltip, tooltipClassName),
        arrow: styles.customArrow,
      }}
      title={title}
    >
      {children}
    </MuiTooltip>
  );
};
