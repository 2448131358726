// factor > 1 --> ligher color
// factor < 1 --> darker color
export const modifyColorIntensity = (hexColor: string, factor: number) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const rprim = Math.min(Math.floor(r * factor), 255).toString(16);
  const gprim = Math.min(Math.floor(g * factor), 255).toString(16);
  const bprim = Math.min(Math.floor(b * factor), 255).toString(16);

  return (
    '#' +
    (rprim.length < 2 ? '0' + rprim : rprim) +
    (gprim.length < 2 ? '0' + gprim : gprim) +
    (bprim.length < 2 ? '0' + bprim : bprim)
  );
};
