import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { LoaderBox } from 'common/components/loader';
import { TradesResponseData, useTradesInfiniteQuery } from '../api';
import { useInfiniteScrolling } from 'common/hooks';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { TradeHistoryBox } from './components/TradeHistoryBox';
import { useFilters, useSort } from '../hooks';
import { PopupFilter } from 'common/components/table-subcomponents/filter/PopupFilter';
import { PopupSorter } from 'common/components/table-subcomponents/sort/PopupSorter';
import styles from './TradeList.module.scss';
import { getFilterText, getSortText } from './helpers';

export const TradeList: React.FC<{}> = () => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState<AxiosResponse<TradesResponseData, any>[]>();

  const [filter, filterConfig] = useFilters();
  const [sort, sortConfig] = useSort();

  const sortText = getSortText(sort);
  const filterText = getFilterText(filter);

  const {
    isLoading,
    data,
    isRefetching,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    remove,
    refetch,
  } = useTradesInfiniteQuery({
    date:
      pages && pages.length > 0 && pages[0].data.results.length > 0
        ? pages[0].data.results[0].executed_at
        : undefined,
    sort: sortText,
    filter: filterText,
  });

  const total = data?.pages[0].data.count;

  const dataLength = data?.pages
    ? data.pages
        .map((page) => page.data.results.length)
        .reduce((num, current) => num + current, 0)
    : 0;

  useEffect(() => {
    if (data?.pages) setPages(data.pages);
  }, [data]);

  const { lastItemRef } = useInfiniteScrolling(() => {
    if (
      total &&
      data?.pages &&
      dataLength < total &&
      !isRefetching &&
      !isFetchingNextPage
    ) {
      setPage(page + 1);
      fetchNextPage({ pageParam: page + 1 });
    }
  });

  useEffect(() => {
    setPage(1);
    setPages(undefined);
    remove();
    refetch();
  }, [sort, filter]);

  return (
    <div>
      <div className={styles.filterBox}>
        <PopupFilter filters={filterConfig} />
        <PopupSorter config={sortConfig} />
      </div>
      {pages &&
        pages.map((page, idx) =>
          page.data.results.map((item, idx_page) => (
            <div
              key={(idx + 1) * idx_page}
              data-test={`row-${idx_page}`}
              ref={
                (idx + 1) * (idx_page + 1) === dataLength
                  ? lastItemRef
                  : undefined
              }
            >
              <TradeHistoryBox trade={item} />
            </div>
          ))
        )}
      {(isLoading || isFetching || isFetchingNextPage) && <LoaderBox />}
      {!isLoading && pages && pages[0].data.results.length === 0 && (
        <NothingToDisplay />
      )}
    </div>
  );
};
