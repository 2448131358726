import { ReactComponent as WalletIcon } from 'common/assets/grey-icons/wallet-small-grey.svg';
import { CurrencyWallet } from 'modules/select-currency-wallet/types';
import styles from './WalletBox.module.scss';
import { Text } from 'common/components/Text';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import clsx from 'clsx';
import { CurrencyEnum } from 'common/types';

type Props = {
  wallet: CurrencyWallet;
  currency: CurrencyEnum;
  onSelectWallet?: (wallet: CurrencyWallet) => void;
  selectedWallet?: CurrencyWallet;
  className?: string;
  dataTest?: string;
};

const NUMBER_OF_LETTERS_WALLET_NAME = 16;

export const WalletBox: React.FC<Props> = ({
  wallet,
  currency,
  onSelectWallet,
  selectedWallet,
  className,
}) => {
  const walletName =
    wallet.name.length <= NUMBER_OF_LETTERS_WALLET_NAME
      ? wallet.name
      : `${wallet.name.slice(0, NUMBER_OF_LETTERS_WALLET_NAME)}...`;

  return (
    <div
      data-test='walletBox'
      className={clsx(
        styles.container,
        selectedWallet?.id === wallet.id && styles.selectedWallet,
        onSelectWallet && styles.cursorPointer,
        className
      )}
      onClick={() => {
        if (onSelectWallet) onSelectWallet(wallet);
      }}
    >
      <div className={styles.name}>
        <WalletIcon width={14} height={14} />
        <Text style='light1215' colorStyle='secondary' dataTest='walletName'>
          {walletName}
        </Text>
      </div>
      <Text style='light1215' dataTest='walletBalance'>
        {`${toLimitedPrec(toFixed(wallet.balance))} ${currency}`}
      </Text>
    </div>
  );
};
