import { useState } from 'react';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Switch } from 'common/components/Switch';
import styles from './StepChangeOwner.module.scss';
import { SelectOwner } from './SelectOwner';
import { Member } from 'modules/company-members/api';
import { Button, CancelButton } from 'common/components/button';
import { Alert } from 'common/components/Alert';

type Props = {
  checked: boolean;
  onChecked: (checked: boolean) => void;
  newOwner?: Member;
  onSelect: (owner?: Member) => void;
  onClick: () => void;
  onCancel: () => void;
};

export const StepChangeOwner: React.FC<Props> = ({
  onChecked,
  checked,
  onSelect,
  newOwner,
  onClick,
  onCancel,
}) => {
  const formatMessage = useFormatMessage();
  const [error, setError] = useState<boolean>();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('settings.changeOwner')}
      </ModalHeader>
      <ModalBody>
        <div>
          <Switch
            checked={checked}
            onChange={(event) => {
              setError(false);
              onChecked(event.target.checked);
            }}
            error={error}
          />
          <Text style='xbold1417' className={styles.textSwitch}>
            {formatMessage('settings.changeCompanyOwner')}
          </Text>
        </div>
        <SelectOwner
          onSelect={onSelect}
          selectedMember={newOwner}
          checked={checked}
          onError={setError}
        />
        {newOwner && (
          <div className={styles.alert}>
            <Alert
              severity='secondary'
              text={formatMessage('settings.warning')}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <Button
            name={formatMessage('settings.changeOwner')}
            type='dark'
            onClick={onClick}
            disabled={!newOwner || !checked}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
