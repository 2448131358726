import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { CharityFormFinishModal } from './components/CharityFormFinishModal';
import { CharityFormDetailed } from '../types';

type Props = { charityForm: CharityFormDetailed; className?: string };

export const CharityFormFinishButton: React.FC<Props> = ({
  charityForm,
  className,
}) => {
  const formatMessage = useFormatMessage();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        type='secondary'
        name={formatMessage('charityForms.finish')}
        onClick={() => setIsOpen(true)}
        className={className}
      />
      <CharityFormFinishModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        charityForm={charityForm}
      />
    </>
  );
};
