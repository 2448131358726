import { useFormatMessage } from 'modules/messages';
import { Button, ButtonProps } from './Button';

type Props = Omit<ButtonProps, 'name' | 'type'>;

export const GoToSummaryButton: React.FC<Props> = (props) => {
  const formatMessage = useFormatMessage();
  return (
    <Button name={formatMessage('common.goToSummary')} type='dark' {...props} />
  );
};
