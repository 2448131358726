import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useEffect, useState } from 'react';
import {
  useWhitelistAddressPatchMutation,
  WhitelistedAddressResponseError,
  WHITELISTED_ADDRESS_URL,
} from '../api';
import { EditStep } from './EditStep';
import { SuccessStep } from './SuccessStep';
import { useQueryClient } from '@tanstack/react-query';
import { Text } from 'common/components/Text';
import { AxiosError } from 'axios';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Step = 'edit' | '2FA' | 'success' | '2FAcancel';

export const EditModal: React.FC<{
  open: boolean;
  onClose: () => void;
  oldName: string;
  id: number;
}> = ({ open, onClose, oldName, id }) => {
  const queryClient = useQueryClient();
  const formatMessage = useFormatMessage();
  const [step, setStep] = useState<Step>('edit');

  const [name, setName] = useState('');
  const [error2FA, setError2FA] = useState<string>();
  const [error, setError] =
    useState<AxiosError<WhitelistedAddressResponseError>>();

  const { mutate, isLoading } = useWhitelistAddressPatchMutation();

  useEffect(() => {
    setStep('edit');
    setName('');
    setError2FA(undefined);
    setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const edit = (newName: string) => {
    setName(newName);
    mutate(
      {
        payload: {
          id,
          name: newName,
        },
      },

      {
        onSuccess: () => {
          setStep('2FA');
        },
        onError: (err) => setError(err),
      }
    );
  };

  const onVerify = (code: string) => {
    mutate(
      {
        payload: {
          id,
          name,
        },
        headers: generateHeader2FA(code),
      },
      {
        onSuccess: () => {
          setStep('success');
          queryClient.invalidateQueries(['GET', WHITELISTED_ADDRESS_URL]);
        },
        onError: (err) => {
          setErrorOTP(err, setError2FA, formatMessage);
        },
      }
    );
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      {step === 'edit' && (
        <EditStep
          onClick={(name: string) => edit(name)}
          oldName={oldName}
          error={error}
          isLoading={isLoading}
          onCancel={onClose}
        />
      )}
      {step === '2FA' && (
        <Module2FA
          onVerify={onVerify}
          onError={(err) => setError2FA(err)}
          error={error2FA}
          isLoading={isLoading}
          onCancel={() => setStep('2FAcancel')}
          title={formatMessage('addressWhitelist.editWhitelistedAddress')}
        />
      )}
      {step === 'success' && (
        <SuccessStep
          onDone={onClose}
          title={formatMessage('addressWhitelist.changesHaveBeenSaved')}
          subtitle={
            <>
              <Text style='light1425'>
                {formatMessage('addressWhitelist.newAddressName')}
              </Text>
              <Text style='bold1417'> {name}</Text>
            </>
          }
        />
      )}
      {step === '2FAcancel' && <Cancel2FA onClose={onClose} />}
    </Modal>
  );
};
