import { useFormatMessage } from 'modules/messages';
import { SummaryAmount } from 'common/components/SummaryAmount';
import { CurrencyEnum } from 'common/types';

type Props = {
  amount?: string;
  subamount?: string | number;
  currency?: CurrencyEnum;
  isLoading: boolean;
};

export const LockedAmount: React.FC<Props> = ({
  amount,
  subamount,
  currency,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <SummaryAmount
      title={formatMessage('saving.newLockedAmount')}
      subtitle={formatMessage('saving.currentFundsLocked')}
      amount={amount}
      subamount={`${subamount}`}
      currency={currency}
      isLoading={isLoading}
    />
  );
};
