import clsx from 'clsx';
import { Text } from 'common/components/Text';
import styles from './FundraisingBalanceFilters.module.scss';
import { useFormatMessage } from 'modules/messages';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ArrowDown } from 'common/assets/black-icons/arrowhead-down-12px-withpadding.svg';

export type FundraisingBalanceFilterType = 'CURRENT' | 'TOTAL';

type FundraisingBalanceFilter = {
  name: string;
  type: FundraisingBalanceFilterType;
};

type Props = {
  selectedFilter: FundraisingBalanceFilterType;
  onSelect: (type: FundraisingBalanceFilterType) => void;
};

export const FundraisingBalanceFilters: React.FC<Props> = ({
  selectedFilter,
  onSelect,
}) => {
  const formatMessage = useFormatMessage();

  const filters = [
    {
      name: formatMessage('charityForms.current'),
      type: 'CURRENT' as FundraisingBalanceFilterType,
    },
    {
      name: formatMessage('common.total'),
      type: 'TOTAL' as FundraisingBalanceFilterType,
    },
  ] as FundraisingBalanceFilter[];

  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.document.body.addEventListener('click', close);
      }, 0);
    } else {
      window.document.body.removeEventListener('click', close);
    }
    return () => {
      window.document.body.removeEventListener('click', close);
    };
  }, [isOpen]);

  return (
    <div>
      <div
        className={clsx(styles.filter)}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Text style='xbold1417'>
          {filters.find((fil) => fil.type === selectedFilter)?.name}
        </Text>
        <ArrowDown className={clsx(isOpen && styles.arrowUp)} />
      </div>
      <div className={styles.container}>
        {isOpen && (
          <div
            className={clsx(styles.menuContainer)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {filters?.map((item, i) => (
              <div
                onClick={() => {
                  onSelect(item.type);
                  setIsOpen(false);
                }}
                key={i}
                className={styles.filterItems}
              >
                <Text
                  style={
                    selectedFilter === item.type ? 'xbold1417' : 'light1417'
                  }
                  inlineStyle={{
                    display: 'block',
                  }}
                >
                  {item.name}
                </Text>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
