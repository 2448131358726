import { generateLimitSendingEmailError } from 'common/utils/utils';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import {
  ResetPasswordPayload,
  useResetPasswordMutation,
  ERR_LIMIT_SENDING_EMAIL,
} from './api';
import { StepEmail } from './components/StepEmail';
import { StepSuccess } from './components/StepSuccess';

type ResetPasswordStep = 'resetPassword' | 'success';

export const ResetPassword = () => {
  const [step, setStep] = useState<ResetPasswordStep>('resetPassword');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const resetPasswordMutation = useResetPasswordMutation();
  const formatMessage = useFormatMessage();

  const resetPassword = (emailUser: string) => {
    setEmail(emailUser);
    const payload: ResetPasswordPayload = {
      email: emailUser,
    };
    resetPasswordMutation.mutate(payload, {
      onSuccess: () => {
        setStep('success');
        setError('');
      },
      onError: (err) => {
        if (err.response?.data.detail?.startsWith(ERR_LIMIT_SENDING_EMAIL)) {
          setError(
            generateLimitSendingEmailError(
              err.response?.data.detail,
              formatMessage('resetPassword.errorLimitSendingEmails'),
              formatMessage
            )
          );
        }
      },
    });
  };

  switch (step) {
    case 'resetPassword':
      return (
        <StepEmail
          onSubmit={(data: string) => {
            resetPassword(data);
          }}
          email={email}
          errorSendingAgain={error}
        />
      );
    case 'success':
      return (
        <StepSuccess
          onLinkResend={() => {
            resetPassword(email);
          }}
          errorSendingAgain={error}
        />
      );
  }
};
