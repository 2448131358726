import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FeatureFlagDisabledError,
  FEATURE_FLAGS_QUERY_KEY,
  isFeatureDisabledError,
  useFeatureFlags,
} from './api';
import { FeatureFlagKindEnum } from './types';

// just gets the feature flag and does not decide what to do with either true or false value
export const useFeatureFlag = (kind: FeatureFlagKindEnum) => {
  const query = useFeatureFlags();
  return {
    ...query,
    data: (query.data ?? []).find((flag) => flag.kind === kind),
  };
};

export const useRedirectToFeatureUnavailable = () => {
  const navigate = useNavigate();

  const redirectToFeatureUnavailable = useCallback(
    () => navigate('/feature-unavailable', { replace: true }),
    [navigate]
  );

  return redirectToFeatureUnavailable;
};

// gets the feature flag, but also redirects to Feature unavailable page
// before redirect happens, the value of the feature flag can be used to show loader of prevent flashes of feature that should be disabled
export const useRequireFeatureFlag = (kind: FeatureFlagKindEnum) => {
  const redirectToFeatureUnavailable = useRedirectToFeatureUnavailable();

  const featureFlag = useFeatureFlag(kind);

  useEffect(() => {
    if (featureFlag.data && !featureFlag.data?.enabled) {
      redirectToFeatureUnavailable();
    }
  }, [featureFlag.data]);

  return featureFlag;
};

export const useFeatureFlagDisabledHandler = (
  shouldRedirect: boolean = true
) => {
  const redirectToFeatureUnavailable = useRedirectToFeatureUnavailable();
  const queryClient = useQueryClient();

  const handleFeatureFlagDisabled = (
    error: AxiosError<FeatureFlagDisabledError> | null
  ) => {
    if (isFeatureDisabledError(error)) {
      queryClient.invalidateQueries(FEATURE_FLAGS_QUERY_KEY);
      if (shouldRedirect) {
        redirectToFeatureUnavailable();
      }
      return true;
    }
    return false;
  };

  return handleFeatureFlagDisabled;
};
