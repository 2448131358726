import { Text } from 'common/components/Text';
import styles from './Description.module.scss';

type Props = {
  title: string;
  description: string;
};

export const Description: React.FC<Props> = ({ title, description }) => {
  return (
    <div>
      <Text
        style='xxbold1619'
        className={styles.text}
        inlineStyle={{ marginBottom: '10px' }}
      >
        {title}
      </Text>
      <Text style='light1425' className={styles.text}>
        {description}
      </Text>
    </div>
  );
};
