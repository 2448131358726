import { useFormatMessage } from 'modules/messages';
import { CurrencyEnum } from 'common/types';
import { SummaryAmount } from 'common/components/SummaryAmount';

type Props = {
  amount?: string;
  subamount?: number | string;
  currency?: CurrencyEnum;
  remainingFundsInfo?: string;
  isLoading: boolean;
  subtitle: string;
};

export const RemainingFunds: React.FC<Props> = ({
  amount,
  subamount,
  currency,
  remainingFundsInfo,
  isLoading,
  subtitle,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <SummaryAmount
      title={formatMessage('common.remainingFunds')}
      subtitle={subtitle}
      amount={amount}
      subamount={subamount}
      currency={currency}
      tooltipText={remainingFundsInfo}
      isLoading={isLoading}
    />
  );
};
