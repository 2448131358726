import clsx from 'clsx';
import { Text } from 'common/components/Text';
import {
  InterestRate,
  Separator,
  useInterestRates,
} from 'modules/financial-ops/common';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { KyxAlert, useIsKyxCompleted } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import styles from './SavingEffect.module.scss';
import { DailyEarn } from './components/DailyEarn';
import { GoToSummaryButton } from 'common/components/button';

type Props = {
  amount?: string;
  currency?: Currency;
  dailyEarn?: string;
  rate?: InterestRate;
  wallet?: CurrencyWallet;
  onContinueToSummary: () => void;
  isLoading: boolean;
};

export const SavingEffect: React.FC<Props> = ({
  amount,
  isLoading,
  currency,
  dailyEarn,
  rate,
  wallet,
  onContinueToSummary,
}) => {
  const formatMessage = useFormatMessage();

  const isKyxCompleted = useIsKyxCompleted();

  const { data: interestRatesData } = useInterestRates('SAVING');

  const areAvailableSavings =
    interestRatesData?.filter(
      (rate) =>
        rate.currency === currency?.currency &&
        rate.network === currency.network
    ).length !== 0;

  return (
    <div>
      <div className={styles.field}>
        <Text style='light1425'>{formatMessage('common.interestRate')}</Text>
        <Text style='light1425'>
          {rate ? `${toFixed(rate.rate_pct)}%` : '-'}
        </Text>
      </div>
      <Separator />
      <div className={styles.field}>
        <Text style='light1425'>{formatMessage('saving.investedAmount')}</Text>
        {toDecimal(amount).gt(0) ? (
          <Text
            style='xxbold1619'
            className={styles.textRight}
          >{`${toLimitedPrec(toFixed(amount))} ${currency?.currency}`}</Text>
        ) : (
          <Text style={'light1425'}>{'-'}</Text>
        )}
      </div>
      <div className={clsx(styles.field, styles.lastField)}>
        <Text style='light1425'>{formatMessage('saving.dailyEarn')}</Text>
        <DailyEarn
          isLoading={isLoading}
          dailyEarn={dailyEarn}
          currency={currency?.currency}
          className={styles.textRight}
        />
      </div>
      <Text style='light1215' className={styles.info}>
        {formatMessage('saving.depositAndWithdrawWheneverYouWant')}
      </Text>
      <KyxAlert className={styles.kycAlert} />
      <GoToSummaryButton
        className={styles.summaryButton}
        disabled={!areAvailableSavings || !isKyxCompleted || isLoading}
        onClick={onContinueToSummary}
      />
    </div>
  );
};
