import { LoaderBox } from 'common/components/loader';
import { useFiatCurrency } from 'modules/fiat';
import {
  OverviewColumn,
  OverviewGrid,
} from 'modules/financial-ops/common/components/overview-components';
import { useLendsSummaryQuery } from '../api';
import { CurrentFunds } from './components/CurrentFunds';
import { InterestsBoxes } from './components/InterestsBoxes';

export const LendOverview: React.FC<{}> = () => {
  const { isLoading: isLendsSummaryLoading } = useLendsSummaryQuery();
  const { isLoading: isFiatCurrencyLoading } = useFiatCurrency();

  if (isLendsSummaryLoading || isFiatCurrencyLoading) {
    return <LoaderBox />;
  }

  return (
    <OverviewGrid>
      <OverviewColumn>
        <CurrentFunds />
      </OverviewColumn>
      <OverviewColumn>
        <InterestsBoxes />
      </OverviewColumn>
    </OverviewGrid>
  );
};
