import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { InterestRateDeletedEvent } from '../types/interest-rate-deleted-event';
import {
  InterestRate,
  getInterestRatesQueryKey,
} from 'modules/financial-ops/common';
import { log } from 'modules/logger';

export const useHandleInterestRateDeleted = () => {
  const queryClient = useQueryClient();

  const handleInterestRateDeleted = useCallback(
    (event: InterestRateDeletedEvent) => {
      log('handling INTEREST_RATE_DELETED WebSocket event', event);

      const deletedInterestRate = event.interest_rate;

      queryClient.setQueryData(
        getInterestRatesQueryKey(deletedInterestRate.kind),
        (prevState: InterestRate[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          const idx = prevState.findIndex(
            (interestRate) =>
              interestRate.term === deletedInterestRate.term &&
              interestRate.currency === deletedInterestRate.currency &&
              interestRate.network === deletedInterestRate.network
          );

          // just for caution, but this should be impossible situation
          if (idx === -1) {
            return undefined;
          }

          return [...prevState.slice(0, idx), ...prevState.slice(idx + 1)];
        }
      );
    },
    [queryClient]
  );

  return handleInterestRateDeleted;
};
