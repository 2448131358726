import clsx from 'clsx';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { Text } from 'common/components/Text';
import Decimal from 'decimal.js';
import { CryptoToFiatAmount } from 'modules/fiat';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { BaseCurrency } from 'modules/select-currency-wallet/types';
import styles from './AssetAmountReadOnly.module.scss';

export const AssetAmountReadOnly: React.FC<{
  amount: Decimal | string;
  currency: BaseCurrency;
  className?: string;
  prefix?: string;
  dataTest?: string;
  showFiat?: boolean;
}> = ({
  amount,
  currency,
  className,
  prefix = '',
  dataTest,
  showFiat = true,
}) => {
  return (
    <div className={className} data-test={dataTest}>
      <div className={clsx(styles.container)}>
        <div className={styles.amountContainer}>
          <div>
            <Text style='light1622' dataTest='assetAmount'>
              {prefix + toLimitedPrec(toFixed(amount))}
            </Text>
            <Text style={'light1622'} className={styles.inputCurrency}>
              &nbsp;{currency.currency}
            </Text>
          </div>
          {showFiat && (
            <CryptoToFiatAmount amount={amount} currency={currency.currency} />
          )}
        </div>
        <CurrencyBox
          currency={currency.currency}
          network={currency.network}
          className={styles.currencyBox}
          dataTest='currencyBox'
        />
      </div>
    </div>
  );
};
