import { createContext, useContext, useState } from 'react';

type HamburgerMenuContextValue = {
  isOpen: boolean;
  closeHamburger: () => void;
  openHamburger: () => void;
  toggleHamburger: () => void;
};

const HamburgerMenuContext = createContext<HamburgerMenuContextValue>({
  isOpen: false,
  closeHamburger: () => {},
  openHamburger: () => {},
  toggleHamburger: () => {},
});

export const HamburgerMenuContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HamburgerMenuContext.Provider
      value={{
        isOpen,
        closeHamburger: () => setIsOpen(false),
        openHamburger: () => setIsOpen(true),
        toggleHamburger: () => setIsOpen((prevIsOpen) => !prevIsOpen),
      }}
    >
      {children}
    </HamburgerMenuContext.Provider>
  );
};

export const useHamburgerMenuContext = () => {
  return useContext(HamburgerMenuContext);
};

type FeatureButtonsContextValue = {
  isDepositOpen: boolean;
  setIsDepositOpen: (value: boolean) => void;
  isWithdrawOpen: boolean;
  setIsWithdrawOpen: (value: boolean) => void;
};

const FeatureButtonsContext = createContext<FeatureButtonsContextValue>({
  isDepositOpen: false,
  setIsDepositOpen: () => {},
  isWithdrawOpen: false,
  setIsWithdrawOpen: () => {},
});

export const FeatureButtonsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isDepositOpen, setIsDepositOpen] = useState(false);
  const [isWithdrawOpen, setIsWithdrawOpen] = useState(false);

  return (
    <FeatureButtonsContext.Provider
      value={{
        isDepositOpen,
        setIsDepositOpen,
        isWithdrawOpen,
        setIsWithdrawOpen,
      }}
    >
      {children}
    </FeatureButtonsContext.Provider>
  );
};

export const useFeatureButtonsContext = () => {
  return useContext(FeatureButtonsContext);
};
