import clsx from 'clsx';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import styles from './DragAndDrop.module.scss';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { ReactComponent as PlusIcon } from 'common/assets/grey-icons/plus.svg';
import { ReactComponent as FileIcon } from 'common/assets/theme-icons/file.svg';
import { LoaderBox } from 'common/components/loader';

type Props = {
  maxFilesSize: number;
  disabled: boolean;
  onDrop: (items: File[], rejectedFiles: FileRejection[]) => void;
  btnText: string;
  description: string;
  fileFormats: Accept;
  isLoading: boolean;
  error: string;
};

export const DragAndDrop: React.FC<Props> = ({
  maxFilesSize,
  disabled,
  onDrop,
  btnText,
  description,
  fileFormats,
  isLoading,
  error,
}) => {
  const formatMessage = useFormatMessage();

  const { getRootProps, open, getInputProps, isDragAccept } = useDropzone({
    accept: fileFormats,
    noClick: true,
    noKeyboard: true,
    maxFiles: maxFilesSize,
    maxSize: 2000000,
    disabled: disabled,
    onDrop,
  });

  return (
    <>
      <div
        className={clsx(
          styles.container,
          isDragAccept && styles.dropzone,
          error && styles.errorFile
        )}
        {...getRootProps()}
      >
        {disabled && <div className={styles.disabledBox} />}
        <div>
          <input {...getInputProps()} />
          {isLoading ? (
            <LoaderBox />
          ) : isDragAccept ? (
            <div className={clsx(styles.isDragContent)}>
              <FileIcon />
              <Text style='xxbold1619' colorStyle='themeDark'>
                {formatMessage('charityForms.dropHere')}
              </Text>
            </div>
          ) : (
            <>
              <div className={clsx(styles.defaultContent)}>
                <AddButton onClick={open} text={btnText} />
                <Text style='light1417' colorStyle='inactive'>
                  {formatMessage('charityForms.orDragAndDropItHere')}
                </Text>
              </div>
              <Text style='light1012' colorStyle='inactive'>
                {description}
              </Text>
            </>
          )}
        </div>
      </div>
      {error && (
        <Text
          style='regular1215'
          colorStyle={'error'}
          inlineStyle={{ display: 'block', marginTop: '5px' }}
        >
          {error}
        </Text>
      )}
    </>
  );
};

const AddButton: React.FC<{ onClick: () => void; text: string }> = ({
  onClick,
  text,
}) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <PlusIcon />
      <Text
        style='xbold1417'
        inlineStyle={{ display: 'block' }}
        colorStyle='primary'
      >
        {text}
      </Text>
    </button>
  );
};
