import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { Button } from 'common/components/button';
import { LoaderBox } from 'common/components/loader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { openSnackbar } from 'common/components/snackbar';
import { prettifyError } from 'common/utils/prettify-error';
import {
  ERR_BLANK_VALUE,
  ERR_INVALID_VALUE,
  ERR_NULL_VALUE,
  ERR_REQUIRED_VALUE,
} from 'modules/api';
import { ERR_LIMIT_SENDING_EMAIL } from 'modules/login';
import { useFormatMessage } from 'modules/messages';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  RegisterEmailConfirmResponseError,
  RegisterEmailRequestResponseError,
  useRegisterEmailConfirmMutation,
  useRegisterEmailRequestMutation,
} from './api';
import { generateLimitSendingEmailError } from 'common/utils/utils';
import { ERR_EMAIL_ALREADY_VERIFIED } from './consts';
import { Text } from 'common/components/Text';
import styles from './ConfirmEmail.module.scss';

export const ConfirmEmail: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const uid = searchParams.get('uid');

  const navigate = useNavigate();

  const confirmMutation = useRegisterEmailConfirmMutation();

  useEffect(() => {
    // delay to see if effect gets executed again (this happens in React dev mode),
    // in which case cancel previous timeouts to avoid sending more than 1 requests.
    const handle = setTimeout(() => {
      confirmMutation.mutate(
        {
          token: token || '',
          uid: uid || '',
        },
        {
          onSuccess: () => {
            navigate('/login', {
              state: { emailConfirmed: true },
              replace: true,
            });
          },
          onError: (error) => {
            if (isEmailAlreadyConfirmed(error)) {
              navigate('/login', {
                state: { emailAlreadyConfirmed: true },
                replace: true,
              });
            }
          },
        }
      );
    }, 100);
    return () => {
      clearTimeout(handle);
    };
  }, [token, uid]);

  const resendMutation = useRegisterEmailRequestMutation();

  const isEmailAlreadyConfirmed = (
    error: AxiosError<RegisterEmailConfirmResponseError>
  ) => {
    return (
      typeof error.response?.data === 'object' &&
      error.response.data.non_field_errors?.includes(ERR_EMAIL_ALREADY_VERIFIED)
    );
  };

  // it is not possible to differentiate between invalid and expired token
  const isTokenInvalidOrExpired = (
    error: AxiosError<RegisterEmailConfirmResponseError>
  ) => {
    return (
      typeof error.response?.data === 'object' &&
      error.response.data.token?.includes(ERR_INVALID_VALUE)
    );
  };

  const isUidInvalid = (
    error: AxiosError<RegisterEmailConfirmResponseError>
  ) => {
    return (
      typeof error.response?.data === 'object' &&
      error.response.data.uid?.includes(ERR_INVALID_VALUE)
    );
  };

  const isAnyValueMissing = (
    error: AxiosError<RegisterEmailConfirmResponseError>
  ) => {
    return (
      typeof error.response?.data === 'object' &&
      (error.response.data.uid?.includes(ERR_NULL_VALUE) ||
        error.response.data.token?.includes(ERR_NULL_VALUE) ||
        error.response.data.uid?.includes(ERR_BLANK_VALUE) ||
        error.response.data.token?.includes(ERR_BLANK_VALUE) ||
        error.response.data.uid?.includes(ERR_REQUIRED_VALUE) ||
        error.response.data.token?.includes(ERR_REQUIRED_VALUE))
    );
  };

  const getConfirmErrorText = (
    error: AxiosError<RegisterEmailConfirmResponseError>
  ) => {
    if (isEmailAlreadyConfirmed(error)) {
      return formatMessage('confirmEmail.error.emailAlreadyConfirmed');
    }
    if (isTokenInvalidOrExpired(error)) {
      return formatMessage('confirnEmail.error.tokenInvalid');
    }
    if (isUidInvalid(error)) {
      return formatMessage('confirnEmail.error.uidInvalid');
    }
    if (isAnyValueMissing(error)) {
      return formatMessage('confirnEmail.error.missingValue');
    }
    return prettifyError(error);
  };

  const getResendErrorText = (
    error: AxiosError<RegisterEmailRequestResponseError>
  ) => {
    if (error.response?.data.detail?.startsWith(ERR_LIMIT_SENDING_EMAIL)) {
      return generateLimitSendingEmailError(
        error.response?.data.detail,
        formatMessage('signup.errorLimitSendingEmails'),
        formatMessage
      );
    }
    return prettifyError(error);
  };

  if (
    confirmMutation.isError &&
    isTokenInvalidOrExpired(confirmMutation.error)
  ) {
    return (
      <>
        <ModalHeader showClose={false}>
          {formatMessage('confirmEmail.expired.header')}
        </ModalHeader>
        {resendMutation.isError && (
          <Alert
            severity='error'
            text={getResendErrorText(resendMutation.error)}
            className={styles.alert}
          />
        )}
        <ModalBody>
          <Text style='light1425' className={styles.subtitle}>
            {formatMessage('confirmEmail.expired.subheader')}
          </Text>
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button
              type='dark'
              name={formatMessage('confirmEmail.expired.resendLink')}
              onClick={() => {
                if (resendMutation.isLoading) {
                  return;
                }
                resendMutation.mutate(
                  { uid: uid || '' },
                  {
                    onSuccess: () => {
                      openSnackbar(
                        formatMessage('confirmEmail.resendLink.success'),
                        'success'
                      );
                    },
                  }
                );
              }}
              isLoading={resendMutation.isLoading}
            />
          </ModalActions>
        </ModalFooter>
      </>
    );
  }

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('confirmEmail.confirming')}
      </ModalHeader>
      <ModalBody>
        {confirmMutation.isError && (
          <Alert
            text={getConfirmErrorText(confirmMutation.error)}
            severity='error'
          />
        )}
        {!confirmMutation.isError && <LoaderBox />}
      </ModalBody>
    </>
  );
};
