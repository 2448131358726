import { CurrencyEnum } from 'common/types';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { BaseDataField } from './BaseDataField';

type BaseProps = {
  title: string;
  currency1: CurrencyEnum;
  currency2: CurrencyEnum;
  currencyValue: string;
};

export const PriceBase: React.FC<BaseProps> = ({
  title,
  currency1,
  currency2,
  currencyValue,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <BaseDataField
      label={title}
      value={formatMessage('trade.priceValues', {
        currency1,
        currency2,
        currencyValue,
      })}
    />
  );
};
