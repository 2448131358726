import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useContext } from 'react';

type Props = {};

export const StepSuccess: React.FC<Props> = () => {
  const { onClose } = useContext(ModalContext);

  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader
        showClose={false}
        subtitle={formatMessage(
          'addressWhitelist.addressUseableForWithdrawals'
        )}
      >
        {formatMessage('addressWhitelist.addressAdded')}
      </ModalHeader>
      <ModalBody>{}</ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            type='dark'
            onClick={onClose}
            name={formatMessage('common.button.done')}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
