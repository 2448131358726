import { Text } from 'common/components/Text';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { useFormatMessage } from 'modules/messages';
import { useBorrows } from '../../api';
import styles from './ActiveBorrowsCount.module.scss';
import clsx from 'clsx';

type Props = {};

export const ActiveBorrowsCount: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const { data, isLoading } = useBorrows();

  return (
    <div className={clsx(styles.container)}>
      {isLoading && <RotatingLoader size={16} type='secondary' />}
      {!isLoading && data && (
        <Text style='xxbold1619'>
          {formatMessage('finopsCommon.activeCount', {
            count: data!.data.filter(
              (borrow) =>
                borrow.state === 'ACTIVE' ||
                borrow.state === 'NEAR_MARGIN_CALL' ||
                borrow.state === 'LIQUIDATING' ||
                borrow.state === 'MARGIN_CALL'
            ).length,
          })}
        </Text>
      )}
    </div>
  );
};
