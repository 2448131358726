import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './MaxButton.module.scss';

type Props = {
  onClick: () => void;
};
export const MaxButton: React.FC<Props> = ({ onClick }) => {
  const formatMessage = useFormatMessage();
  return (
    <button className={styles.button} onClick={onClick} data-test='maxButton'>
      <Text style='xbold1219' colorStyle='primary'>
        {formatMessage('common.max')}
      </Text>
    </button>
  );
};
