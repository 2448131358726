import { useAreNoWalletsCreated } from 'modules/assets-and-wallets';
import { useFeatureFlag } from 'modules/feature-flags';
import { NewProductButton } from 'modules/financial-ops/common/components/NewProductButton';
import { useFormatMessage } from 'modules/messages';
import { useNavigate } from 'react-router-dom';
import { useAllCurrenciesAvailableForSaving } from '../hooks';
import { useInterestRatesExistForKind } from 'modules/financial-ops/common';

export const NewSavingButton: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const navigate = useNavigate();
  const { data: walletsFailed, isLoading: isLoadingWallets } =
    useAreNoWalletsCreated();
  const { data: allCurrenciesAvailable, isLoading } =
    useAllCurrenciesAvailableForSaving();

  const { data: ratesExists, isLoading: isLoadingRates } =
    useInterestRatesExistForKind('SAVING');

  const { data: newSaveFF, isLoading: isLoadingFF } =
    useFeatureFlag('CREATING_SAVING');

  const disabled =
    !newSaveFF?.enabled ||
    walletsFailed ||
    !ratesExists ||
    (!isLoading && !allCurrenciesAvailable);

  return (
    <NewProductButton
      disabled={disabled}
      isLoading={isLoadingFF || isLoadingWallets || isLoadingRates}
      tooltipTitle={
        !isLoading && !allCurrenciesAvailable
          ? formatMessage('saving.limitOfDepositedFunds')
          : disabled
          ? formatMessage('common.featureCurrentlyUnavailableTryLater')
          : ''
      }
      text={formatMessage('saving.newSaving')}
      onClick={() => navigate('new-saving')}
    />
  );
};
