import { useCurrencies } from './api';

export const useGetCurrenciesWithWallets = () => {
  const query = useCurrencies();

  return {
    ...query,
    data: query.data?.data.filter((item) => item.wallets.length > 0),
  };
};
