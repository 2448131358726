import styles from './TransactionMoreInformation.module.scss';
import { LoaderBox } from 'common/components/loader';
import { useTransactionDetailsQuery } from 'modules/transactions/api';
import { DatesWalletsBox } from './DatesWalletsBox';
import { AmountsPercentageBox } from './AmountsPercentageBox';
import { ApprovalsBox } from './ApprovalsBox';
import clsx from 'clsx';

type Props = { id: number; className?: string };

export const TransactionMoreInformation: React.FC<Props> = ({
  id,
  className,
}) => {
  const { data, isLoading } = useTransactionDetailsQuery(id);

  return (
    <>
      {isLoading ? (
        <LoaderBox height='50px' />
      ) : (
        <>
          {data && (
            <div className={clsx(styles.container, className)}>
              <DatesWalletsBox transaction={data} className={styles.dates} />
              <AmountsPercentageBox
                transaction={data}
                className={styles.amounts}
              />
              <ApprovalsBox transaction={data} className={styles.approvals} />
            </div>
          )}
        </>
      )}
    </>
  );
};
