import Decimal from 'decimal.js';
import {
  MAX_DIGITS,
  MAX_INTEGER_DIGITS,
  NUM_REGEX,
} from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { useEffect, useRef } from 'react';

type Props = {
  value: string;
  onChange: (value: string) => void;
  onError: (error: string) => void;
  onEnter?: () => void;
  decimals?: number;
  maxAmount?: string;
  minAmount?: string;
  className?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  id?: string;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  maxErrorText?: string;
  showMinErrorText?: boolean;
};

const INPUT_PLACEHOLDER = '0.00';

export const InputAmount: React.FC<Props> = ({
  value,
  onChange,
  onError,
  onEnter,
  decimals = 8,
  maxAmount,
  minAmount = '0',
  className,
  inputRef,
  id,
  name,
  disabled = false,
  placeholder,
  maxErrorText,
  showMinErrorText = true,
}) => {
  const formatMessage = useFormatMessage();

  const internalInputRef = useRef<HTMLInputElement>(null);

  const ref = inputRef ?? internalInputRef;

  const adjustWidth = () => {
    if (ref.current) {
      const length = ref.current.value.length || INPUT_PLACEHOLDER.length;
      ref.current.style.width = Math.max(length + 2, 2) + 'ch';
    }
  };

  useEffect(() => {
    adjustWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const validateAndChange = (newValue: string) => {
    newValue = newValue.replace(',', '.');

    if (newValue === '' || newValue === '.') {
      onChange(newValue);

      if (showMinErrorText)
        onError(formatMessage('inputAmount.error.amountMustBeGreaterThanZero'));

      return;
    }

    const match = newValue.match(NUM_REGEX);

    if (!match) {
      return;
    }

    const [, zeroes, numberWithoutZeroes, integerPart, decimalPart] = match;

    // disallow leading zeroes, but:
    // allow "0"
    // allow "0.", "0.1", "0.14", etc
    // allow ".1", ".2512", etc
    const formattedNumber =
      zeroes && !integerPart ? `0${numberWithoutZeroes}` : numberWithoutZeroes;

    if (decimalPart.length > decimals) {
      return;
    }

    if (integerPart.length > MAX_INTEGER_DIGITS) {
      return;
    }

    if (integerPart.length + decimalPart.length > MAX_DIGITS) {
      onError(
        formatMessage('inputAmount.error.maxDigits', { maxDigits: MAX_DIGITS })
      );
      return;
    }

    const newAmount = new Decimal(formattedNumber);

    if (minAmount && newAmount.eq(minAmount)) {
      onChange(formattedNumber);
      if (showMinErrorText)
        onError(formatMessage('inputAmount.error.amountMustBeGreaterThanZero'));
      return;
    }

    if (maxAmount && newAmount.gt(maxAmount)) {
      onError(
        maxErrorText ?? formatMessage('inputAmount.error.insufficientFunds')
      );
      if (!maxAmount) onChange(formattedNumber);
      return;
    }

    onChange(formattedNumber);
    onError('');
  };

  return (
    <input
      autoComplete={'off'}
      className={className}
      data-test='amountInput'
      id={id}
      placeholder={placeholder ?? INPUT_PLACEHOLDER}
      name={name}
      onChange={(e) => {
        validateAndChange(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          onEnter?.();
          return;
        }
      }}
      ref={internalInputRef}
      type='text'
      value={value}
      disabled={disabled}
    />
  );
};
