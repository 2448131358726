import { createContext, useContext, useState } from 'react';
import { useParams } from './use-params';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';
import { SetState } from 'common/types';
import {
  BaseCurrency,
  Currency,
  CurrencyWallet,
} from 'modules/select-currency-wallet/types';
import {
  InterestRate,
  getHighestBalanceWallet,
  useSortedInterestRates,
} from 'modules/financial-ops/common';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';

type NewSavingContextValue = {
  amount: string;
  amountInfo: string;
  assetAndAmountError: string;
  currency: Currency | undefined;
  rate: InterestRate | undefined;
  rateError: string;
  wallet: CurrencyWallet | undefined;
  walletError: string;
  setAmount: SetState<string>;
  setAmountInfo: SetState<string>;
  setAssetAndAmountError: SetState<string>;
  setCurrencyKey: SetState<BaseCurrency | undefined>;
  setRateError: SetState<string>;
  setWalletError: SetState<string>;
  setWalletId: SetState<number | undefined>;
};

const NewSavingContext = createContext<NewSavingContextValue>({
  amount: INITIAL_AMOUNT,
  amountInfo: '',
  assetAndAmountError: '',
  rate: undefined,
  rateError: '',
  currency: undefined,
  wallet: undefined,
  walletError: '',
  setAmount: () => {},
  setAmountInfo: () => {},
  setAssetAndAmountError: () => {},
  setCurrencyKey: () => {},
  setRateError: () => {},
  setWalletError: () => {},
  setWalletId: () => {},
});

export const NewSavingContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // url state
  const params = useParams();

  // base state
  const [amount, setAmount] = useState(INITIAL_AMOUNT);

  const [currencyKey, setCurrencyKey] = useState<BaseCurrency | undefined>(
    params.currency && params.network
      ? { currency: params.currency, network: params.network }
      : undefined
  );

  const [walletId, setWalletId] = useState<number | undefined>(params.walletId);

  // data derived from base state
  const { data: currencies } = useGetCurrenciesWithWallets();
  const { data: rates } = useSortedInterestRates({
    sortDirection: 'desc',
    kind: 'SAVING',
  });

  const currency = currencies?.find(
    (item) =>
      item.currency === currencyKey?.currency &&
      item.network === currencyKey.network
  );

  const rate = rates?.find(
    (item) =>
      item.currency === currency?.currency && item.network === currency?.network
  );

  const wallet = walletId
    ? currency?.wallets?.find((item) => item.id === walletId)
    : getHighestBalanceWallet(currency?.wallets); // if walletId undefined, use wallet with highest balance

  // error state
  const [amountInfo, setAmountInfo] = useState('');
  const [assetAndAmountError, setAssetAndAmountError] = useState('');
  const [rateError, setRateError] = useState('');
  const [walletError, setWalletError] = useState('');

  return (
    <NewSavingContext.Provider
      value={{
        amount,
        amountInfo,
        assetAndAmountError,
        currency,
        rate,
        rateError,
        wallet,
        walletError,
        setAmount,
        setAmountInfo,
        setAssetAndAmountError,
        setCurrencyKey,
        setRateError,
        setWalletError,
        setWalletId,
      }}
    >
      {children}
    </NewSavingContext.Provider>
  );
};

export const useNewSavingContext = () => {
  return useContext(NewSavingContext);
};
