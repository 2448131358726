import { LoaderBox } from 'common/components/loader';
import { useInfiniteScrolling } from 'common/hooks';
import { NOTIFICATIONS_INFINITE_QUERY_KEY, NotificationResponse } from '../api';
import { NotificationBox } from './NotificationBox';
import { NothingToDisplay } from 'modules/nothing-to-display';
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useQueryClient,
} from '@tanstack/react-query';

type Props = {
  page: number;
  setPage: (page: number) => void;
  notificationsQuery: UseInfiniteQueryResult<NotificationResponse>;
};

export const NotificationsTable: React.FC<Props> = ({
  page,
  setPage,
  notificationsQuery,
}) => {
  const queryClient = useQueryClient();

  const {
    isLoading,
    data,
    isRefetching,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = notificationsQuery;

  const total = data?.pages[0].count;

  const dataLength = data?.pages
    ? data.pages
        .map((page) => page.results.length)
        .reduce((num, current) => num + current, 0)
    : 0;

  const { lastItemRef } = useInfiniteScrolling(() => {
    if (
      total &&
      data?.pages &&
      dataLength < total &&
      !isRefetching &&
      !isFetchingNextPage
    ) {
      setPage(page + 1);
      fetchNextPage({ pageParam: page + 1 });
    }
  });

  // modify infinite notifications state without refetching
  const markAsRead = (notificationId: number) => {
    queryClient.setQueryData(
      NOTIFICATIONS_INFINITE_QUERY_KEY,
      (prevState: InfiniteData<NotificationResponse> | undefined) => {
        if (prevState === undefined) {
          return undefined;
        }

        const nextState: typeof prevState = {
          ...prevState,
          pages: prevState.pages.map((page) => {
            const foundOnPage = page.results.find(
              (n) => n.id === notificationId
            );
            // reuse whole page if possible
            if (!foundOnPage) {
              return page;
            }
            return {
              ...page,
              results: page.results.map((n) =>
                n.id === notificationId ? { ...n, is_read: true } : n
              ),
            };
          }),
        };

        return nextState;
      }
    );
  };

  return (
    <div>
      {data?.pages &&
        data.pages.map((page, idx) =>
          page.results.map((item, idx_page) => (
            <div
              key={item.id}
              ref={
                (idx + 1) * (idx_page + 1) === dataLength
                  ? lastItemRef
                  : undefined
              }
            >
              <NotificationBox
                notification={item}
                isSmall={false}
                markAsReadOnClick={markAsRead}
              />
            </div>
          ))
        )}
      {(isLoading || isFetching || isFetchingNextPage) && <LoaderBox />}
      {!isLoading && data?.pages && data.pages[0].results.length === 0 && (
        <NothingToDisplay />
      )}
    </div>
  );
};
