import { OverviewField } from 'modules/financial-ops/common/components/overview-components';
import { useSavingsSummaryQuery } from '../../api';
import { FundsLockedInSavings } from './FundsLockedInSavings';
import { SavingBubbleChart } from './SavingBubbleChart';

type Props = {};

export const FundsLocked: React.FC<Props> = () => {
  const { data, isLoading } = useSavingsSummaryQuery();

  return (
    <>
      <OverviewField>
        <FundsLockedInSavings
          amount={data?.data.current_amount_fiat}
          isLoading={isLoading}
        />
      </OverviewField>
      <SavingBubbleChart assets={data?.data.current_assets} />
    </>
  );
};
