import { OverviewField } from 'modules/financial-ops/common/components/overview-components';
import { useLendsSummaryQuery } from 'modules/financial-ops/lend/api';
import { AverageLendingApr } from './AverageLendingApr';
import { ExpectedEarnFromLends } from './ExpectedEarnFromLends';
import { InterestEarnedSoFar } from './InterestEarnedSoFar';
import { RemainingInterestToEarn } from './RemainingInterestToEarn';

export const InterestsBoxes: React.FC = () => {
  const { data, isLoading } = useLendsSummaryQuery();

  return (
    <>
      <OverviewField>
        <ExpectedEarnFromLends lendSummary={data} isLoading={isLoading} />
      </OverviewField>
      <OverviewField>
        <InterestEarnedSoFar lendSummary={data} isLoading={isLoading} />
      </OverviewField>
      <OverviewField>
        <RemainingInterestToEarn lendSummary={data} isLoading={isLoading} />
      </OverviewField>
      <OverviewField>
        <AverageLendingApr lendSummary={data} isLoading={isLoading} />
      </OverviewField>
    </>
  );
};
