import { useEffect, useState } from 'react';

import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useQueryClient } from '@tanstack/react-query';
import { toDecimal, toFixed } from 'modules/input-amount';
import {
  TransactionActionPayload,
  useTransactionActionMutation,
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { openSnackbar } from 'common/components/snackbar';
import { StepSuccess } from './components/StepSuccess';
import { StepSummary } from './components/StepSummary';
import { StepTopup } from './components/StepTopup';
import {
  SAVINGS_LIMITS_QUERY_KEY,
  SAVINGS_QUERY_KEY,
  SAVING_QUERY_KEY,
  TopupEffectPayload,
  amountExceedsMaxToAllocate,
  globalLimitReached,
  noFundsInWallet,
  noPossibilityToAllocateMoreFunds,
  savingHasTopupTransactionInPendingState,
  savingHasWithdrawalTransactionInPendingState,
  savingIsNotActive,
  useTopupEffectMutation,
} from 'modules/financial-ops/savings-account/api';
import { Saving } from '../types';
import { CURRENCIES_QUERY_KEY } from 'modules/select-currency-wallet/api';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
  STATUS_INVALID_OTP,
  ALREADY_USED_OTP,
} from 'modules/2FA';
import { prettifyError } from 'common/utils/prettify-error';
import { TopupContextProvider, useTopupContext } from './context';
import { useCalculateEffect } from './use-calculate-effect';

type TopupSteps = 'topup' | 'summary' | '2FA' | 'success' | '2FAcancel';

type Props = {
  open: boolean;
  onClose: () => void;
  id: number;
  saving?: Saving;
};

const TopupFundsWrapped: React.FC<Props> = ({ open, onClose, id, saving }) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();
  const topupEffectMutation = useTopupEffectMutation(id);

  const [step, setStep] = useState<TopupSteps>('topup');
  const [transactionId, setTransactionId] = useState<number>();
  const [error2FA, setError2FA] = useState<string>();
  const [token, setToken] = useState('');

  const context = useTopupContext();

  const { mutate: transactionActionMutation, isLoading: isLoadingTransaction } =
    useTransactionActionMutation();

  const continueToSummary = () => {
    if (context.amountError || context.walletError) {
      return;
    }

    if (!context.wallet) {
      context.setWalletError('common.walletIsNotSelected');
      return;
    }

    if (toDecimal(context.amount).gt(toDecimal(context.wallet?.balance))) {
      context.setAmountError(formatMessage('common.notEnoughFundsToTopup'));
      return;
    }

    if (toDecimal(context.amount).eq(0)) {
      context.setAmountError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }

    context.setWalletError('');
    context.setAmountError('');
    getInitialToken();
  };

  const getInitialToken = () => {
    if (!context.wallet) {
      return;
    }

    const payload: TopupEffectPayload = {
      calculation_base: 'AMOUNT',
      wallet_id: context.wallet.id,
      amount: toFixed(context.amount),
      summary: true,
    };

    topupEffectMutation.mutate(payload, {
      onSuccess: (response) => {
        setToken(response.data.token);
        setStep('summary');
      },
      onError: (error) => {
        const amountExceedsMaxToAllocateMatch = amountExceedsMaxToAllocate(
          error.response?.data
        );

        if (amountExceedsMaxToAllocateMatch) {
          context.setAmountError(
            formatMessage('saving.topUpAmountMustBeLessOrEqual', {
              amount: toFixed(amountExceedsMaxToAllocateMatch[1]),
              currency: context.currency?.currency,
            })
          );
          return;
        }

        if (noFundsInWallet(error.response?.data)) {
          context.setAmountError(formatMessage('saving.noFundsInWallet'));
          return;
        }

        if (noPossibilityToAllocateMoreFunds(error.response?.data)) {
          context.setAmountError(
            formatMessage('saving.limitOfDepositedFundsReached')
          );
          return;
        }

        if (globalLimitReached(error.response?.data)) {
          context.setAmountError(
            formatMessage('saving.maximumGlobalLimitReached')
          );
          return;
        }

        if (
          savingHasWithdrawalTransactionInPendingState(error.response?.data)
        ) {
          context.setAmountError(
            formatMessage('saving.savingHasWithdrawalTransactionInPendingState')
          );
          return;
        }

        if (savingHasTopupTransactionInPendingState(error.response?.data)) {
          context.setAmountError(
            formatMessage('saving.savingHasTopupTransactionInPendingState')
          );
          return;
        }

        if (savingIsNotActive(error.response?.data)) {
          context.setAmountError(
            formatMessage('saving.savingHasBeenClosedTopupIsNotAvailable')
          );
          return;
        }

        context.setAmountError(prettifyError(error));
      },
    });
  };

  const onVerify = (keys: string) => {
    if (!transactionId) {
      return;
    }

    const payload: TransactionActionPayload = {
      transaction_id: transactionId,
      action: 'APPROVE',
    };

    transactionActionMutation(
      { payload, headers: generateHeader2FA(keys) },
      {
        onSuccess: () => {
          openSnackbar(
            formatMessage('transactions.approved.message'),
            'success'
          );
          queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
          queryClient.invalidateQueries(
            TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
          );
          queryClient.invalidateQueries(SAVINGS_QUERY_KEY);
          queryClient.invalidateQueries(SAVING_QUERY_KEY(id));
          queryClient.invalidateQueries(CURRENCIES_QUERY_KEY);
          queryClient.invalidateQueries(SAVINGS_LIMITS_QUERY_KEY);
          closeModal();
        },
        onError: (err) => {
          if (
            err.response?.data?.status === STATUS_INVALID_OTP ||
            err.response?.data?.status === ALREADY_USED_OTP
          ) {
            setErrorOTP(err, setError2FA, formatMessage);
          } else {
            const text = Object.values(err.response!.data)[0] as string;
            openSnackbar(text, 'error');
          }
        },
      }
    );
  };

  const {
    calculateEffect,
    mutation: { isLoading: isLoadingEffect, data: effectData },
  } = useCalculateEffect();

  const closeModal = () => {
    onClose();
    context.setWalletId(undefined);
    setStep('topup');
    context.setAmount('');
    context.setAmountError('');
    context.setAmountInfo('');
  };
  return (
    <>
      <Modal isOpen={open} onClose={closeModal} dataTest='withdrawModal'>
        {step === 'topup' && (
          <StepTopup
            amount={context.amount}
            onSummary={continueToSummary}
            currency={context.currency}
            wallet={context.wallet}
            wallets={context.currency?.wallets}
            errorWallet={context.walletError}
            saving={context.saving}
            newDailyEarn={effectData?.data.interest_daily}
            newLockedAmount={effectData?.data.amount_target}
            onCancel={closeModal}
            onAmountChange={(amount) => {
              calculateEffect({
                calculationBase: 'AMOUNT',
                amount,
              });
            }}
            onWalletChange={(wallet) => {
              calculateEffect({
                calculationBase: 'AMOUNT',
                wallet: wallet?.id,
              });
            }}
            isLoading={isLoadingEffect}
            errorAmount={context.amountError}
            amountInfo={context.amountInfo}
            onMax={() => {
              calculateEffect({
                calculationBase: 'MAX',
              });
            }}
          />
        )}
        {step === 'summary' && (
          <StepSummary
            amount={context.amount}
            onGoBack={() => {
              setStep('topup');
            }}
            currency={context.currency}
            wallet={context.wallet}
            initialNewDailyEarn={topupEffectMutation.data?.data.interest_daily}
            initialNewLockedAmount={
              topupEffectMutation.data?.data.amount_target
            }
            onGoNext={(txId) => {
              setTransactionId(txId);
              setStep('success');
            }}
            initialToken={token}
            id={id}
          />
        )}
        {step === 'success' && (
          <StepSuccess onClose={closeModal} onApprove={() => setStep('2FA')} />
        )}
        {step === '2FA' && (
          <Module2FA
            onVerify={onVerify}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={isLoadingTransaction}
            onCancel={() => setStep('2FAcancel')}
            approvalOfTransaction={true}
            title={formatMessage('saving.approveTopUp')}
          />
        )}
        {step === '2FAcancel' && (
          <Cancel2FA onClose={closeModal} approvalOfTransaction={true} />
        )}
      </Modal>
    </>
  );
};

export const TopupFunds: React.FC<Props> = ({ open, onClose, id, saving }) => {
  return (
    <TopupContextProvider saving={saving}>
      <TopupFundsWrapped
        open={open}
        onClose={onClose}
        id={id}
        saving={saving}
      />
    </TopupContextProvider>
  );
};
