import { textColorStyles, textStyles } from 'common/components/Text';
import clsx from 'clsx';
import styles from './FeatureButton.module.scss';

export type FeatureButtonProps = {
  text: string;
  onClick: () => void;
  active: boolean;
  dataTest?: string;
  hasBorder?: boolean;
  disabled?: boolean;
};

export const FeatureButton: React.FC<FeatureButtonProps> = ({
  text,
  onClick,
  active,
  dataTest,
  hasBorder,
  disabled = false,
}) => {
  return (
    <div
      className={clsx(
        styles.container,
        hasBorder &&
          (disabled
            ? styles.borderDisabled
            : active
            ? styles.borderActive
            : styles.border),
        disabled
          ? textColorStyles.inactive
          : active
          ? textColorStyles.theme
          : styles.colorText,
        textStyles.bold1417
      )}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      data-test={dataTest}
    >
      {text}
    </div>
  );
};
