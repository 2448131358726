import { useCallback } from 'react';
import { FeatureFlagUpdatedEvent } from '../types/feature-flag-updated-event';
import { useQueryClient } from '@tanstack/react-query';
import { FEATURE_FLAGS_QUERY_KEY, FeatureFlags } from 'modules/feature-flags';
import { WALLETS_QUERY_KEY } from 'modules/assets-and-wallets/api';
import { log } from 'modules/logger';

export const useHandleFeatureFlagUpdated = () => {
  const queryClient = useQueryClient();

  const handleFeatureFlagUpdated = useCallback(
    (event: FeatureFlagUpdatedEvent) => {
      // update /feature-flags data
      queryClient.setQueryData(
        FEATURE_FLAGS_QUERY_KEY,
        (prevState: FeatureFlags | undefined) => {
          // ignore this event if feature flags are not fetched yet (it can happen for example before user is logged in)
          if (!prevState) {
            return undefined;
          }
          // update query cache
          return prevState.map((flag) => {
            if (flag.kind === event.feature_flag.kind) {
              return { ...event.feature_flag };
            }
            return { ...flag };
          });
        }
      );

      // refetch wallets on KYB flag change, because wallet addresses visibility depends on KYB flag
      if (event.feature_flag.kind === 'KYB') {
        queryClient.invalidateQueries(WALLETS_QUERY_KEY);
      }

      log('handling FEATURE_FLAG_UPDATED WebSocket event', event);
    },
    [queryClient]
  );

  return handleFeatureFlagUpdated;
};
