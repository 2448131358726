import styles from './Copy.module.scss';
import { Text } from 'common/components/Text';
import { copyToClipboard } from 'common/utils/utils';
import { Button } from 'common/components/button';
import { openSnackbar } from 'common/components/snackbar';
import { useFormatMessage } from 'modules/messages';

type Props = { dataTest?: string; data: string; snackbarText?: string };

export const Copy: React.FC<Props> = ({ dataTest, data, snackbarText }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <Text style='regular1416' className={styles.address} dataTest={dataTest}>
        {data}
      </Text>
      <Button
        name={formatMessage('deposit.copy')}
        type='dark'
        onClick={() => {
          copyToClipboard(data);
          openSnackbar(
            snackbarText ?? formatMessage('common.copy.message'),
            'success'
          );
        }}
      />
    </div>
  );
};
