import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'common/components/button';
import { Modal } from 'common/components/modal';
import { openSnackbar } from 'common/components/snackbar';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import {
  TransactionActionPayload,
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  useTransactionActionMutation,
  getTransactionDetailsQueryKey,
} from '../api';
import {
  Transaction,
  TransactionDetails,
  TransactionUserAction,
} from '../types';
import { TransactionExpiryTimer } from './TransactionExpiryTimer';
import styles from './CellActions.module.scss';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
  ALREADY_USED_OTP,
  STATUS_INVALID_OTP,
} from 'modules/2FA';
import { TransactionApprovePreview } from './TransactionApprovePreview';

type Props = {
  onAction?: () => void;
  dataTest?: string;
  transaction: Transaction | TransactionDetails;
};

type ModalSteps = '2FA' | '2FAcancel';

export const CellActions: React.FC<Props> = ({
  onAction,
  dataTest,
  transaction,
}) => {
  const { id, requires_action, user_action, expires_at, created_at } =
    transaction;

  const queryClient = useQueryClient();
  const formatMessage = useFormatMessage();
  const { mutate: transactionActionMutation, isLoading } =
    useTransactionActionMutation();

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<TransactionUserAction>();
  const [error2FA, setError2FA] = useState<string>();
  const [hideBtns, setHideBtns] = useState(false);
  const [step, setStep] = useState<ModalSteps>('2FA');

  const displayAction = () => {
    switch (user_action) {
      case 'APPROVE':
        return formatMessage('common.approved');
      case 'REJECT':
        return formatMessage('common.rejected');
      default:
        return '';
    }
  };

  const onClick = (userAction: TransactionUserAction) => {
    setAction(userAction);
    setOpen(true);
  };

  const transactionAction = (keys: string) => {
    if (action) {
      const payload: TransactionActionPayload = {
        transaction_id: id,
        action,
      };

      transactionActionMutation(
        { payload, headers: generateHeader2FA(keys) },
        {
          onSuccess: () => {
            openSnackbar(
              formatMessage(
                action === 'APPROVE'
                  ? 'transactions.approved.message'
                  : 'transactions.rejected.message'
              ),
              'success'
            );
            queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
            queryClient.invalidateQueries(getTransactionDetailsQueryKey(id));
            setAction(null);
            setOpen(false);
            if (onAction) onAction();
          },
          onError: (err) => {
            if (
              err.response?.data?.status === STATUS_INVALID_OTP ||
              err.response?.data?.status === ALREADY_USED_OTP
            ) {
              setErrorOTP(err, setError2FA, formatMessage);
            } else {
              const text = Object.values(err.response!.data)[0] as string;
              openSnackbar(text, 'error');
            }
          },
        }
      );
    }
  };

  return (
    <div className={styles.container} data-test={dataTest}>
      {requires_action && !hideBtns ? (
        <>
          <TransactionExpiryTimer
            expirationDate={expires_at}
            creationDate={created_at}
            onTimeEnd={() => setHideBtns(true)}
          />
          <div className={styles.btns}>
            <Button
              type='secondarySmall'
              name={formatMessage('common.button.reject')}
              isLoading={action === 'REJECT' && isLoading}
              onClick={() => onClick('REJECT')}
              className={styles.btn}
            />
            <Button
              type='darkSmall'
              name={formatMessage('common.button.approve')}
              isLoading={action === 'APPROVE' && isLoading}
              onClick={() => onClick('APPROVE')}
              className={styles.btn}
            />
          </div>
        </>
      ) : (
        <Text style='light1425'>{displayAction()}</Text>
      )}
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        {step === '2FA' && (
          <Module2FA
            onVerify={transactionAction}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={isLoading}
            onCancel={() => setStep('2FAcancel')}
            approvalOfTransaction={action === 'APPROVE'}
            title={
              action === 'APPROVE'
                ? formatMessage('transactions.approveTransaction')
                : formatMessage('transactions.rejectTransaction')
            }
            additionalDescription={
              <TransactionApprovePreview transaction={transaction} />
            }
          />
        )}
        {step === '2FAcancel' && (
          <Cancel2FA
            onClose={() => {
              setStep('2FA');
              setOpen(false);
            }}
            approvalOfTransaction={action === 'APPROVE'}
          />
        )}
      </Modal>
    </div>
  );
};
