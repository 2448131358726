import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useHamburgerMenuContext } from 'modules/navigation/context';
import { useUserMenuContext } from 'modules/user';
import styles from './HelpCenterButton.module.scss';

export const ZENDESK_LINK = 'https://montesauri.zendesk.com/hc/en-us';

export const HelpCenterButton: React.FC = () => {
  const formatMessage = useFormatMessage();
  const { closeUserMenu } = useUserMenuContext();
  const { closeHamburger } = useHamburgerMenuContext();

  return (
    <a
      href={ZENDESK_LINK}
      onClick={() => {
        closeUserMenu();
        closeHamburger();
      }}
      className={styles.button}
      target='_blank'
      rel='noreferrer'
    >
      <Text style='bold1417'>{formatMessage('zendesk.helpCenter')}</Text>
    </a>
  );
};
