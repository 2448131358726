import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { get } from 'modules/api';
import { Currency } from './types';
import { useMemo } from 'react';
import { ascCmpCurrencies } from 'common/utils/comparators';

export const CURRENCIES_URL = '/currencies';

export const CURRENCIES_QUERY_KEY = ['GET', CURRENCIES_URL] as const;

export const getCurrencies = async () => {
  const response = await get<Currency[]>(CURRENCIES_URL);
  return response.data;
};

export const useCurrencies = (options?: UseQueryOptions<Currency[]>) => {
  const query = useQuery<Currency[]>(
    CURRENCIES_QUERY_KEY,
    getCurrencies,
    options
  );
  return {
    ...query,
    data: useMemo(() => {
      return query.data
        ? { ...query.data, data: [...query.data].sort(ascCmpCurrencies) }
        : undefined;
    }, [query.data]),
  };
};
