import { CurrencyEnum } from 'common/types';
import { TermEnum, termToMonths } from 'modules/financial-ops/common';
import { FormatMessageFn } from 'modules/messages';
import { Transaction, TransactionKind } from 'modules/transactions/types';
import { User } from 'modules/user';
import { EventKind, NotificationEvent } from './types';

export const getTransactionKindName = (
  kind: TransactionKind,
  formatMessage: FormatMessageFn
) => {
  switch (kind) {
    case 'DEPOSIT':
      return formatMessage('transactions.deposit');
    case 'WITHDRAWAL':
      return formatMessage('transactions.withdraw');
    case 'LOAN_LEND':
      return formatMessage('transactions.lend');
    case 'LOAN_LEND_TERMINATION':
      return formatMessage('transactions.lendTermination');
    case 'LOAN_BORROW':
      return formatMessage('transactions.borrow');
    case 'LOAN_INTEREST':
      return formatMessage('transactions.lendInterestPayout');
    case 'LOAN_COLLATERAL_TOPUP_AUTO':
      return formatMessage('transactions.marginCallTransfer');
    case 'LOAN_COLLATERAL_TOPUP':
      return formatMessage('transactions.collateralTopup');
    case 'LOAN_COLLATERAL_EXCESS_RETURN':
      return formatMessage('transactions.overcollateralizationTransfer');
    case 'LOAN_COLLATERAL_FULL_RETURN':
      return formatMessage('transactions.collateralReturn');
    case 'LOAN_BORROW_REPAY':
      return formatMessage('transactions.repayment');
    case 'TRADE_OUT':
    case 'TRADE_IN':
      return formatMessage('common.trade');
    default:
      return kind;
  }
};

const getKindName = (kind: EventKind, formatMessage: FormatMessageFn) => {
  switch (kind) {
    case 'LEND_ACTIVE':
    case 'LEND_COMPLETED':
      return formatMessage('transactions.lend');
    default:
      return kind;
  }
};

const generateSubheaderFromTransaction = (
  transaction: Transaction,
  formatMessage: FormatMessageFn
) => {
  if (transaction.kind === 'LOAN_BORROW') {
    return formatMessage('notifications.borrowAssetForCollateral', {
      months: transaction.extra_content.term
        ? termToMonths(transaction.extra_content.term)
        : '',
      principal: transaction.extra_content.principal_currency,
      collateral: transaction.extra_content.collateral_currency,
    });
  }
  if (transaction.kind === 'LOAN_BORROW_REPAY') {
    return formatMessage('notifications.borrowRepaymentAssetForCollateral', {
      months: termToMonths(transaction.extra_content.term),
      principal: transaction.extra_content.principal_currency,
      collateral: transaction.extra_content.collateral_currency,
    });
  }
  if (transaction.kind === 'SAVING') {
    return formatMessage('saving.savingCurrency', {
      currency: transaction.currency,
    });
  }
  if (transaction.kind === 'SAVING_WITHDRAWAL') {
    return formatMessage('transactions.savingCurrencyWithdraw', {
      currency: transaction.currency,
    });
  }
  if (transaction.kind === 'SAVING_TOPUP') {
    return formatMessage('transactions.savingCurrencyTopup', {
      currency: transaction.currency,
    });
  }
  if (transaction.extra_content.term) {
    return formatMessage('notifications.kindCurrencyTerms', {
      kind: getTransactionKindName(transaction.kind, formatMessage),
      currency: transaction.currency,
      months: termToMonths(transaction.extra_content.term),
    });
  }

  if (
    transaction.extra_content.in_currency &&
    transaction.extra_content.out_currency
  ) {
    return formatMessage('common.tradeOut', {
      inCurrency: transaction.extra_content.in_currency,
      outCurrency: transaction.extra_content.out_currency,
    });
  }

  if (transaction.kind === 'CHARITY_WITHDRAWAL_OUT') {
    return formatMessage('transactions.withdrawFundsCharityForm');
  }

  return formatMessage('notifications.kindCurrency', {
    kind: getTransactionKindName(transaction.kind, formatMessage),
    currency: transaction.currency,
  });
};

const generateLendSubheader = (
  currency: CurrencyEnum,
  term: TermEnum,
  formatMessage: FormatMessageFn
) =>
  term
    ? formatMessage('notifications.lendCurrency', {
        currency,
        months: termToMonths(term),
      })
    : formatMessage('finopsCommon.lend', {
        kind: getKindName('LEND' as EventKind, formatMessage),
        currency: currency,
      });

const getBorrowNotificationSubheader = (
  formatMessage: FormatMessageFn,
  event: NotificationEvent
) =>
  formatMessage('notifications.borrowAssetForCollateral', {
    principal: event.extra_content.principal_currency,
    collateral: event.extra_content.collateral_currency,
    months: event.extra_content.term
      ? termToMonths(event.extra_content.term)
      : '',
  });

export const generateNotificationText = (
  formatMessage: FormatMessageFn,
  kind: EventKind,
  event: NotificationEvent,
  isOwner?: boolean,
  user?: User
) => {
  switch (kind) {
    case 'INVITATION_ACCEPTED':
      return isOwner
        ? {
            header: formatMessage('notifications.memberAcceptedInvitation', {
              name: event.user.name,
              surname: event.user.surname,
            }),
            subheader: formatMessage(
              'notifications.youCanUpdateMultiapprovalThreshold'
            ),
          }
        : {
            header: formatMessage('notifications.memberJoinedTheCompany', {
              name: event.user.name,
              surname: event.user.surname,
            }),
          };

    case 'WALLET_CREATION_COMPLETED':
      return {
        header: formatMessage('notifications.walletReadyToUse', {
          name: event.wallet.name,
        }),
      };
    case 'WALLET_CREATION_FAILED':
      return {
        header: formatMessage('notifications.walletCreationFailed', {
          name: event.wallet.name,
        }),
      };
    case 'DEPOSIT_COMPLETED':
      return {
        header: formatMessage('notifications.newDeposit'),
      };
    case 'TRANSACTION_EXPIRED':
      return {
        header: formatMessage('notifications.transactionExpired'),
        subheader: generateSubheaderFromTransaction(
          event.transaction,
          formatMessage
        ),
      };
    case 'TRANSACTION_REJECTED':
      return {
        header: formatMessage('notifications.transactionRejected'),
        subheader: generateSubheaderFromTransaction(
          event.transaction,
          formatMessage
        ),
      };
    case 'TRANSACTION_FAILED':
      return {
        header: formatMessage('notifications.transactionFailed'),
        subheader: generateSubheaderFromTransaction(
          event.transaction,
          formatMessage
        ),
      };
    case 'TRANSACTION_MULTI_APPROVAL_CHANGED':
      return {
        header: formatMessage('notifications.transactionToConfirm'),
        subheader: generateSubheaderFromTransaction(
          event.transaction,
          formatMessage
        ),
      };
    case 'TRANSACTION_PENDING_SIGNATURE':
      return {
        header: formatMessage('notifications.newTransactionToConfirm'),
        subheader: generateSubheaderFromTransaction(
          event.transaction,
          formatMessage
        ),
      };
    case 'WITHDRAWAL_COMPLETED':
      return {
        header: formatMessage('notifications.withdrawCompleted'),
      };
    case 'MULTI_APPROVAL_CHANGED':
      if (
        user?.email &&
        event.extra_content?.new_signers?.includes(user?.email)
      )
        return {
          header: formatMessage('notifications.youHaveBeenAppointedAsSigner'),
          subheader: formatMessage(
            'notifications.youAreAbleToApproveTransactions'
          ),
        };
      if (
        user?.email &&
        event?.extra_content?.removed_signers?.includes(user?.email)
      )
        return {
          header: formatMessage(
            'notifications.youHaveBeenRemovedFromMultiapproval'
          ),
        };
      return { header: '', subheader: '' };
    case 'BORROW_ACTIVE':
      return {
        header: formatMessage('notifications.borrowIsNowActive'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_CAN_BE_REPAID_GRACE_PERIOD':
      return {
        header: formatMessage('notifications.borrowIsReadyToRepay'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_CAN_BE_REPAID_LATTER':
      return {
        header: formatMessage('notifications.borrowWillReachMaturity', {
          days: event.extra_content.days_to_maturity,
        }),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_COLLATERAL_EXCESS_RETURN':
      return {
        header: formatMessage('notifications.borrowReachedOvercollateralized'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_COLLATERAL_TOPUP':
      return {
        header: formatMessage('notifications.borrowHasBeenToppedUp'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_COLLATERAL_TOPUP_AUTO':
      return {
        header: formatMessage('notifications.borrowReachedMarginCall'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_LIQUIDATED':
      return {
        header: formatMessage('notifications.borrowHasBeenLiquidated'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_CANCELLED':
      return {
        header: formatMessage('notifications.borrowHasBeenCancelled'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_LIQUIDATION':
      return {
        header: formatMessage('notifications.borrowHasReachedLiquidationState'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_MARGIN_CALL':
      return {
        header: formatMessage('notifications.borrowReachedMarginCall'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_MATURING':
      return {
        header: formatMessage(
          'notifications.borrowHasReachedMaturityPleaseRepay'
        ),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_NEAR_MARGIN_CALL':
      return {
        header: formatMessage('notifications.borrowReachedNearMarginCall'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'BORROW_REPAID':
      return {
        header: formatMessage('notifications.borrowIsRepaid'),
        subheader: getBorrowNotificationSubheader(formatMessage, event),
      };
    case 'LEND_ACTIVE':
      return {
        header: formatMessage('notifications.lendIsActive'),
        subheader:
          event.extra_content?.principal_currency && event.extra_content?.term
            ? generateLendSubheader(
                event.extra_content?.principal_currency,
                event.extra_content?.term,
                formatMessage
              )
            : '',
      };
    case 'LEND_COMPLETED':
      return {
        header: formatMessage('notifications.lendIsFinished'),
        subheader:
          event.extra_content?.principal_currency && event.extra_content?.term
            ? generateLendSubheader(
                event.extra_content?.principal_currency,
                event.extra_content?.term,
                formatMessage
              )
            : '',
      };
    case 'LEND_TERMINATED':
      return {
        header: formatMessage('notifications.lendHasBeenTerminated'),
        subheader:
          event.extra_content?.principal_currency && event.extra_content?.term
            ? generateLendSubheader(
                event.extra_content?.principal_currency,
                event.extra_content?.term,
                formatMessage
              )
            : '',
      };
    case 'LEND_TERMINATED_PAID_OUT':
      return {
        header: formatMessage(
          'notifications.fundsFromTerminatedLendHaveBeenSent'
        ),
        subheader:
          event.extra_content?.principal_currency && event.extra_content?.term
            ? generateLendSubheader(
                event.extra_content?.principal_currency,
                event.extra_content?.term,
                formatMessage
              )
            : '',
      };
    case 'KYB_COMPLETED':
      return {
        header: formatMessage('kyx.kybHasBeenCompletedSuccessfully'),
        subheader: formatMessage('kyx.youCanUseAllFeaturesNow'),
      };
    case 'KYB_FAILED':
      return {
        header: formatMessage('kyx.kybHasFailed'),
        subheader: formatMessage('kyx.contactHelpCenterToRetryKyb'),
      };
    case 'KYC_COMPLETED':
      return {
        header: formatMessage('kyx.kycHasBeenCompletedSuccessfully'),
      };
    case 'TRADE_COMPLETED':
      return {
        header: formatMessage('notifications.tradeCompleted'),
        subheader: generateSubheaderFromTransaction(
          event.transaction,
          formatMessage
        ),
      };
    case 'SAVING_ACTIVE':
      return {
        header: formatMessage('notifications.savingIsNowActive'),
        subheader: formatMessage('saving.savingCurrency', {
          currency: event.extra_content.currency,
        }),
      };
    case 'SAVING_TOPUP_COMPLETED':
      return {
        header: formatMessage('notifications.savingHasBeenToppedUp'),
        subheader: formatMessage('saving.savingCurrency', {
          currency: event.transaction.currency,
        }),
      };
    case 'SAVING_WITHDRAWAL_COMPLETED':
      return {
        header: formatMessage('notifications.fundsHaveBeenWithdrawnFromSaving'),
        subheader: formatMessage('saving.savingCurrency', {
          currency: event.transaction.currency,
        }),
      };
    case 'SAVING_COMPLETED':
      return {
        header: formatMessage('notifications.savingsAccountHasBeenClosed'),
        subheader: formatMessage('saving.savingCurrency', {
          currency: event.extra_content.currency,
        }),
      };
    case 'CHARITY_FORM_WITHDRAWAL_COMPLETED':
      return {
        header: formatMessage(
          'notifications.fundsHaveBeenWithdrawnFromCharityForm'
        ),
        subheader: event.extra_content.charity_form_name,
      };

    case 'CHARITY_FORM_COMPLETED':
      return {
        header: formatMessage('notifications.charityFormHasBeenFinished'),
        subheader: event.extra_content.charity_form_name,
      };
    case 'CHARITY_FORM_COMPLETED_NEW_DONATION':
      return {
        header: formatMessage(
          'notifications.newDonationReceivedForFinishedFundraiser'
        ),
        subheader: event.extra_content.charity_form_name,
      };

    default:
      return { header: '', subheader: '' };
  }
};

export const generateDetailsPath = (event: NotificationEvent) => {
  const { kind } = event;
  if (
    [
      'TRANSACTION_EXPIRED',
      'DEPOSIT_COMPLETED',
      'TRANSACTION_REJECTED',
      'TRANSACTION_FAILED',
      'WITHDRAWAL_COMPLETED',
      'TRANSACTION_PENDING_SIGNATURE',
      'TRANSACTION_MULTI_APPROVAL_CHANGED',
    ].includes(kind)
  ) {
    return `/transactions/${event.transaction.id}`;
  }
  if (
    [
      'BORROW_ACTIVE',
      'BORROW_CAN_BE_REPAID_GRACE_PERIOD',
      'BORROW_CAN_BE_REPAID_LATTER',
      'BORROW_COLLATERAL_EXCESS_RETURN',
      'BORROW_COLLATERAL_TOPUP',
      'BORROW_COLLATERAL_TOPUP_AUTO',
      'BORROW_LIQUIDATED',
      'BORROW_CANCELLED',
      'BORROW_LIQUIDATION',
      'BORROW_MARGIN_CALL',
      'BORROW_MATURING',
      'BORROW_NEAR_MARGIN_CALL',
      'BORROW_REPAID',
    ].includes(kind)
  ) {
    return `/borrows/${event.extra_content.borrow_id}`;
  }
  if (
    [
      'SAVING_ACTIVE',
      'SAVING_TOPUP_COMPLETED',
      'SAVING_WITHDRAWAL_COMPLETED',
      'SAVING_COMPLETED',
    ].includes(kind)
  ) {
    return `/savings-accounts/${event.extra_content.saving_id}`;
  }
  if (
    [
      'LEND_ACTIVE',
      'LEND_COMPLETED',
      'LEND_TERMINATED',
      'LEND_TERMINATED_PAID_OUT',
    ].includes(kind)
  ) {
    return `/lends/${event.extra_content.lend_id}`;
  }

  if (
    ['CHARITY_FORM_COMPLETED', 'CHARITY_FORM_COMPLETED_NEW_DONATION'].includes(
      kind
    )
  ) {
    return `/charity-forms/${event.extra_content.charity_form_id}`;
  }
};

export const generateDescription = (
  event: NotificationEvent,
  formatMessage: FormatMessageFn
) => {
  switch (event.kind) {
    case 'LEND_COMPLETED':
      return formatMessage('notifications.interestHasBeenPayOut');
    case 'LEND_TERMINATED':
      return formatMessage(
        'notifications.principalAndInterestRelesedInXHours',
        { hours: event.extra_content.payout_hours }
      );
    case 'LEND_TERMINATED_PAID_OUT':
      return formatMessage('notifications.principalAndInterestHaveBeenPaidOut');
    case 'BORROW_COLLATERAL_EXCESS_RETURN':
      return formatMessage(
        'notifications.excessCollateralHasBeenTransferredToYourWallet'
      );
    case 'BORROW_COLLATERAL_TOPUP_AUTO':
      return formatMessage(
        'notifications.missingCollateralAmountAutomaticallyTransferred'
      );
    case 'BORROW_LIQUIDATED':
      return formatMessage(
        'notifications.remainingCollateralHasBeenPaidOutIntoWallet'
      );
    case 'BORROW_MARGIN_CALL': {
      if (event.extra_content.auto_topup) {
        return formatMessage(
          'notifications.toAvoidLiquidationMakeSureRequiredAmountIsStored'
        );
      }
      return formatMessage('notifications.toAvoidLiquidationTopup');
    }
    case 'SAVING_COMPLETED':
      return formatMessage('notifications.interestHasBeenPayOut');
    default:
      return undefined;
  }
};
