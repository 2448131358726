import { Modal } from 'common/components/modal';
import { useRef, useState } from 'react';
import { StepSuccess, StepTerminate, StepWarning } from './components';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';
import { useFormatMessage } from 'modules/messages';
import {
  TransactionActionPayload,
  useTransactionActionMutation,
} from 'modules/transactions/api';
import { lendDetailsQueryKey, useLendTerminateEffectMutation } from '../api';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  lendId: number;
  onClose: () => void;
};

type Step = 'warning' | 'action' | 'success' | '2fa' | '2faCancel';

export const LendTerminationModal: React.FC<Props> = ({ lendId, onClose }) => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const [step, setStep] = useState<Step>('warning');

  const summaryEffectMutation = useLendTerminateEffectMutation(lendId);

  const transactionIdRef = useRef<number>();

  const transactionActionMutation = useTransactionActionMutation();

  const [error2fa, setError2fa] = useState<string>();

  const approveTransaction = (code2fa: string) => {
    if (!transactionIdRef.current) {
      return;
    }
    const payload: TransactionActionPayload = {
      transaction_id: transactionIdRef.current,
      action: 'APPROVE',
    };
    const headers = generateHeader2FA(code2fa);
    transactionActionMutation.mutate(
      { payload, headers },
      {
        onSuccess: () => {
          onClose();
        },
        onError: (error) => {
          setErrorOTP(error, setError2fa, formatMessage);
        },
      }
    );
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      {step === 'warning' && (
        <StepWarning
          lendId={lendId}
          isLoadingNextStep={summaryEffectMutation.isLoading}
          nextStepError={summaryEffectMutation.error}
          onCancel={onClose}
          onNext={() => {
            summaryEffectMutation.mutate(
              { summary: true },
              {
                onSuccess: () => {
                  setStep('action');
                },
                onError: () => {
                  // refresh if lend becomes inactive or gets has_pending_transaction = true in the meantime
                  queryClient.invalidateQueries(lendDetailsQueryKey(lendId));
                },
              }
            );
          }}
        />
      )}
      {step === 'action' && (
        <StepTerminate
          lendId={lendId}
          summary={summaryEffectMutation.data!}
          summaryError={summaryEffectMutation.error}
          onBack={() => {
            setStep('warning');
          }}
          onNext={(transactionId) => {
            transactionIdRef.current = transactionId;
            setStep('success');
          }}
          onRefreshSummary={() => {
            summaryEffectMutation.mutate({ summary: true });
          }}
        />
      )}
      {step === 'success' && (
        <StepSuccess
          onApprove={() => {
            setStep('2fa');
          }}
          onDone={onClose}
        />
      )}
      {step === '2fa' && (
        <Module2FA
          onVerify={approveTransaction}
          onError={(err) => setError2fa(err)}
          error={error2fa}
          isLoading={transactionActionMutation.isLoading}
          onCancel={() => setStep('2faCancel')}
          approvalOfTransaction={true}
          title={formatMessage('lend.approveLendTermination')}
        />
      )}
      {step === '2faCancel' && (
        <Cancel2FA
          onClose={() => {
            onClose();
          }}
          approvalOfTransaction={true}
        />
      )}
    </Modal>
  );
};
