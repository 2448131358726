import { ReactComponent as BlockedIcon } from 'common/assets/illustrations/blocked.svg';
import { Button } from 'common/components/button';
import { ModalLike } from 'common/components/modal';
import { Text } from 'common/components/Text';
import { TIME_TO_REDIRECT } from 'common/consts/consts';
import { useFormatMessage } from 'modules/messages';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FeatureUnavailable.module.scss';

export const FeatureUnavailable: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const navigate = useNavigate();

  const redirectToDashboard = () => navigate('/dashboard', { replace: true });

  useEffect(() => {
    const handleId = window.setTimeout(() => {
      redirectToDashboard();
    }, TIME_TO_REDIRECT);
    return () => {
      window.clearTimeout(handleId);
    };
  }, []);

  return (
    <ModalLike className={styles.container}>
      <div className={styles.columns}>
        <div className={styles.textContent}>
          <Text style='xxbold3048'>
            {formatMessage('featureFlags.featureUnavailable')}
          </Text>
          <Text style='light1425'>
            {formatMessage(
              'featureFlags.featureCurrentlyUnavailableTryLaterRedirectDashboard'
            )}
          </Text>
        </div>
        <BlockedIcon className={styles.icon} />
      </div>
      <Button
        className={styles.button}
        name={formatMessage('featureFlags.goToDashboard')}
        onClick={redirectToDashboard}
        type='dark'
      />
    </ModalLike>
  );
};
