import { InfiniteData } from '@tanstack/react-query';
import { PAGE_SIZE } from 'common/consts/consts';
import { subDays } from 'date-fns';
import { uniqBy } from 'lodash';
import { apiBaseURL } from 'modules/api';
import {
  TRANSACTIONS_URL,
  TransactionFilters,
  TransactionsResponse,
  buildTransactionsInfiniteParams,
} from 'modules/transactions/api';
import { Transaction } from 'modules/transactions/types';

export const matchesFilters = (
  transaction: Transaction,
  filters: TransactionFilters
) => {
  const {
    categories,
    currencies,
    dateFrom,
    dateTo,
    lastDays,
    networks,
    savings,
    states,
    types,
    wallets,
    charityForms,
    transactionKinds,
  } = filters;

  if (categories?.length && !categories.includes(transaction.category)) {
    return false;
  }
  if (currencies?.length && !currencies.includes(transaction.currency)) {
    return false;
  }
  if (dateFrom && new Date(dateFrom) > new Date(transaction.created_at)) {
    return false;
  }
  if (dateTo && new Date(dateTo) < new Date(transaction.created_at)) {
    return false;
  }
  if (
    lastDays &&
    subDays(new Date(), lastDays) > new Date(transaction.created_at)
  ) {
    return false;
  }
  if (networks?.length && !networks.includes(transaction.wallet.network)) {
    return false;
  }
  if (
    savings?.length &&
    (transaction.saving === null ||
      transaction.saving === undefined ||
      !savings.includes(transaction.saving))
  ) {
    return false;
  }
  if (states?.length && !states.includes(transaction.state)) {
    return false;
  }
  if (types?.length && !types.includes(transaction.type)) {
    return false;
  }
  if (wallets?.length && !wallets.includes(transaction.wallet.id)) {
    return false;
  }
  if (
    charityForms?.length &&
    (transaction.charity_form === null ||
      transaction.charity_form === undefined ||
      !charityForms.includes(transaction.charity_form))
  ) {
    return false;
  }
  if (
    transactionKinds?.length &&
    !transactionKinds.includes(transaction.kind)
  ) {
    return false;
  }

  return true;
};

export const buildTransactionsInfiniteUrl = (
  page: number,
  filters: TransactionFilters,
  options: { withApiBaseUrl: boolean } = { withApiBaseUrl: true }
) =>
  (options.withApiBaseUrl ? apiBaseURL : '') +
  TRANSACTIONS_URL +
  '?' +
  buildTransactionsInfiniteParams(page, filters);

export const findTransaction = (
  transactionId: number,
  state: InfiniteData<TransactionsResponse>
) => {
  for (let pageIdx = 0; pageIdx < state.pages.length; pageIdx++) {
    const page = state.pages[pageIdx];
    const transactionIdx = page.results.findIndex(
      (transaction) => transaction.id === transactionId
    );
    if (transactionIdx >= 0) {
      return {
        pageIdx,
        transaction: page.results[transactionIdx],
        transactionIdx,
      };
    }
  }
  return undefined;
};

export const insertTransactionToTransactionsInfinite = (
  transaction: Transaction,
  prevState: InfiniteData<TransactionsResponse>,
  filters: TransactionFilters
) => {
  const pageCount = prevState.pages.length;
  const lastPage = prevState.pages.at(-1);

  // this is impossible situation.
  // if prevState is not undefined, then there is at least one (possibly empty) page.
  if (!lastPage) {
    return prevState;
  }

  const isEveryPageFetched = lastPage.next === null;
  const isLastPageFull = lastPage.results.length === PAGE_SIZE;

  const firstPage = prevState.pages[0];

  const flatTransactions = prevState.pages.flatMap((page) => page.results);

  let idx = 0;
  while (
    flatTransactions[idx] &&
    new Date(transaction.created_at) <
      new Date(flatTransactions[idx].created_at)
  ) {
    idx++;
  }

  const nextTransactions = [
    ...flatTransactions.slice(0, idx),
    transaction,
    ...flatTransactions.slice(idx),
  ];

  // remove duplicates. This is for race condition case where next page is fetched
  // faster than TRANSACTION_NEW arrives which causes one transaction to appear on two pages.
  const nextTransactionsUniq = uniqBy(nextTransactions, (t) => t.id);

  // use count from first page, because last page might have different count in case of doubled transaction issue.
  const nextTransactionCount = firstPage.count + 1;

  const nextState: typeof prevState = {
    ...prevState,
    pages: prevState.pages.map((page, idx) => ({
      previous: page.previous,
      next:
        idx === pageCount - 1 && isEveryPageFetched && isLastPageFull
          ? buildTransactionsInfiniteUrl(pageCount + 1, filters)
          : page.next,
      count: nextTransactionCount,
      results: nextTransactionsUniq.slice(
        idx * PAGE_SIZE,
        (idx + 1) * PAGE_SIZE
      ),
    })),
  };

  return nextState;
};

/**
 * @param pageIdx index of page on which the transaction can be found. this arg allows for reuse of unaffected pages
 */
export const updateTransactionInTransactionsInfinite = (
  updatedTransaction: Transaction,
  prevState: InfiniteData<TransactionsResponse>,
  pageIdx: number
) => {
  return {
    ...prevState,
    pages: prevState.pages.map((page, idx) =>
      idx === pageIdx
        ? {
            ...page,
            results: page.results.map((transaction) =>
              transaction.id === updatedTransaction.id
                ? updatedTransaction
                : transaction
            ),
          }
        : page
    ),
  };
};

export const removeTransactionFromTransactionsInfinite = (
  transactionToRemove: Transaction,
  prevState: InfiniteData<TransactionsResponse>
) => {
  const flatTransactions = prevState.pages.flatMap((page) => page.results);
  const idx = flatTransactions.findIndex(
    (t) => t.id === transactionToRemove.id
  );

  const nextTransactions = [
    ...flatTransactions.slice(0, idx),
    ...flatTransactions.slice(idx + 1),
  ];

  const firstPage = prevState.pages[0];
  const nextCount = firstPage.count - 1;

  const nextState: typeof prevState = {
    pageParams: [...prevState.pageParams],
    pages: prevState.pages.map((page, pageIdx) => ({
      ...page,
      count: nextCount,
      results: nextTransactions.slice(
        pageIdx * PAGE_SIZE,
        (pageIdx + 1) * PAGE_SIZE
      ),
    })),
  };

  // if last page is empty (had one element before removal), remove page and set field next of page - 1 to null
  const lastPageIdx = nextState.pages.length - 1;
  if (lastPageIdx >= 1 && nextState.pages[lastPageIdx].results.length === 0) {
    nextState.pageParams.pop();
    nextState.pages.pop();
    nextState.pages[lastPageIdx - 1].next = null;
  }

  return nextState;
};
