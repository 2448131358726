import { Alert } from 'common/components/Alert';
import { Button, BackButton, CancelButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { useFormatMessage } from 'modules/messages';
import styles from './StepSummary.module.scss';
import { Text } from 'common/components/Text';
import {
  CharityFormFinishResponseError,
  charityFormAlreadyFinished,
  youDoNotHavePermission,
} from '../../api';
import { AxiosError } from 'axios';
import { prettifyError } from 'common/utils/prettify-error';
import { CharityFormDetailed } from 'modules/charity-forms/types';
import { SummaryAmount } from 'common/components/SummaryAmount';
import { FiatCurrencyEnum } from 'modules/fiat';
import { AmountWithFiat } from 'modules/charity-forms/charity-form-details/components/AmountWithFiat';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { CurrencyEnum, NetworkEnum } from 'common/types';
import { FundraisingBalance } from 'modules/charity-forms/charity-form-details/components/FundraisingBalanceTable';

type Props = {
  charityForm: CharityFormDetailed;
  onGoBack: () => void;
  onCancel: () => void;
  onFinish: () => void;
  finishError: AxiosError<CharityFormFinishResponseError> | null;
  isLoading: boolean;
  fiatCurrency: FiatCurrencyEnum;
};

export const StepSummary: React.FC<Props> = ({
  charityForm,
  onGoBack,
  onCancel,
  onFinish,
  finishError,
  isLoading,
  fiatCurrency,
}) => {
  const formatMessage = useFormatMessage();

  const getErrorText = (error: AxiosError<CharityFormFinishResponseError>) => {
    if (youDoNotHavePermission(error.response?.data)) {
      return formatMessage('charityForms.youDoNotHavePermission');
    }

    if (charityFormAlreadyFinished(error.response?.data)) {
      return formatMessage('charityForms.charityFormHasAlreadyBeenFinished');
    }

    return prettifyError(error);
  };

  const balances = Object.entries(charityForm.current_balance_by_currency)
    .map((key) => {
      return {
        currency: key[0],
        amount: key[1],
        fiatAmount:
          charityForm.current_balance_fiat_by_currency[key[0] as CurrencyEnum],
        network: (key[0] === 'BTC' ? 'BITCOIN' : 'ETHEREUM') as NetworkEnum,
      };
    })
    .filter((bal) =>
      charityForm.networks.includes(bal.network)
    ) as FundraisingBalance[];

  return (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('charityForms.reviewAndConfirmFinishing')}
          </Text>
        }
      >
        {formatMessage('charityForms.finishCharityFormSummary')}
      </ModalHeader>
      <ModalBody>
        {finishError && (
          <Alert severity='error' text={getErrorText(finishError)} />
        )}
        <div className={styles.container}>
          <Summary className={styles.summary}>
            <SummaryLabel>
              {formatMessage('charityForms.fundraiserName')}
            </SummaryLabel>
            <SummaryValue>{charityForm.name}</SummaryValue>
            <SummaryLabel>
              {formatMessage('charityForms.duration')}
            </SummaryLabel>
            <SummaryValue>
              {formatMessage('common.days', { days: charityForm.days_active })}
            </SummaryValue>
            <SummaryLabel>
              {formatMessage('charityForms.donations')}
            </SummaryLabel>
            <SummaryValue>{charityForm.donation_count}</SummaryValue>
          </Summary>
          <SummaryAmount
            title={formatMessage('charityForms.collectedFunds')}
            subtitle={formatMessage('charityForms.financialFundraisingGoal')}
            amount={charityForm.total_raised_fiat}
            subamount={charityForm.goal_amount_fiat}
            currency={fiatCurrency}
            isLoading={false}
            className={styles.funds}
            textStyle={'light1215'}
            hideSubtitle={!charityForm.goal_enabled}
          />
          <div className={styles.currencies}>
            {balances.map((balance, i) => (
              <div key={i} className={styles.row}>
                <CurrencyBox
                  currency={balance.currency}
                  network={balance.network}
                  textStyle='light1215'
                  iconSize='size15'
                />
                <AmountWithFiat
                  currency={balance.currency}
                  amount={balance.amount}
                  fiatAmount={balance.fiatAmount}
                  textStyle='light1215'
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.warning}>
          <Text style='light1215'>
            {formatMessage(
              'charityForms.warningFinishingFundraiserIsPermanent'
            )}
          </Text>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          {charityForm.end_at ? (
            <BackButton onClick={onGoBack} />
          ) : (
            <CancelButton onClick={onCancel} />
          )}
          <Button
            name={formatMessage('charityForms.finish')}
            onClick={onFinish}
            isLoading={isLoading}
            disabled={isLoading || !!finishError}
            type={'dark'}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
