import { ReactComponent as ArrowLeftIcon } from 'common/assets/black-icons/arrow-left-small.svg';
import { Link } from 'common/components/Link';
import { Text } from 'common/components/Text';
import styles from './NavigationHeader.module.scss';

type Props = {
  to: string;
  children?: React.ReactNode;
};

export const NavigationHeader: React.FC<Props> = ({ to, children }) => {
  return (
    <Link to={to} className={styles.link}>
      <div className={styles.container}>
        <ArrowLeftIcon />
        <Text style='xxbold1619'>{children}</Text>
      </div>
    </Link>
  );
};
