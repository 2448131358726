import { useFormatMessage } from 'modules/messages';
import { BaseDataField } from './BaseDataField';

type Props = {
  tolerance: string;
};

export const PriceChangeTolerance: React.FC<Props> = ({ tolerance }) => {
  const formatMessage = useFormatMessage();

  return (
    <BaseDataField
      label={formatMessage('trade.priceChangeTolerance')}
      value={tolerance + '%'}
    />
  );
};
