import { ReactComponent as NotificationIcon } from 'common/assets/notifications-dot.svg';

export const NotificationDot: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <NotificationIcon
      className={className}
      style={{ minWidth: '10px', minHeight: '10px' }}
    />
  );
};
