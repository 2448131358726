import { ModalLike } from 'common/components/modal';
import { UnauthLayout } from 'modules/layout';
import { useFormatMessage } from 'modules/messages';
import { SignupInvitation as SignupInvitationFeature } from 'modules/signup-invitation/SignupInvitation';
import { Helmet } from 'react-helmet-async';

export const SignupInvitation: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.signupFromInvitation')}</title>
      </Helmet>
      <UnauthLayout>
        <ModalLike>
          <SignupInvitationFeature />
        </ModalLike>
      </UnauthLayout>
    </>
  );
};
