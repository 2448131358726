import { Modal } from 'common/components/modal';
import { LoaderBox } from 'common/components/loader';
import { SelectWalletStep } from './SelectWalletStep';
import { SelectCurrencyStep } from './SelectCurrencyStep';
import { useState } from 'react';
import { useSelectCurrencyWalletContext } from '../context';
import { useGetCurrenciesWithWallets } from '../hooks';

type Props = {
  open: boolean;
  onClose: () => void;
};

type SelectCurrencySteps = 'selectCurrency' | 'selectWallet';

export const SelectCurrencyModal: React.FC<Props> = ({ open, onClose }) => {
  const { data, isLoading } = useGetCurrenciesWithWallets();

  const [step, setStep] = useState<SelectCurrencySteps>('selectCurrency');
  const { setCurrencyKey, setWalletId, currency } =
    useSelectCurrencyWalletContext();

  const closeModal = () => {
    setStep('selectCurrency');
    onClose();
  };

  return (
    <Modal onClose={closeModal} isOpen={open} smallModal={true}>
      {step === 'selectCurrency' && (
        <SelectCurrencyStep
          currencies={data}
          onSelect={(curr) => {
            if (curr.wallets.length > 1) {
              setCurrencyKey(curr);
              setStep('selectWallet');
              return;
            }
            setCurrencyKey(curr);
            setWalletId(curr.wallets[0].id);
            closeModal();
          }}
        />
      )}
      {step === 'selectWallet' && (
        <SelectWalletStep
          onSelect={(wallet) => {
            setWalletId(wallet.id);
            closeModal();
          }}
          currency={currency}
          onArrowClick={() => setStep('selectCurrency')}
        />
      )}
      {isLoading && <LoaderBox />}
    </Modal>
  );
};
