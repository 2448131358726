import { createContext, useContext, useState } from 'react';
import { useParams } from './use-params';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';
import { SetState } from 'common/types';
import {
  BaseCurrency,
  Currency,
  CurrencyWallet,
} from 'modules/select-currency-wallet/types';
import {
  InterestRate,
  TermEnum,
  useSortedInterestRates,
} from 'modules/financial-ops/common';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';

type NewLendContextValue = {
  amount: string;
  amountInfo: string;
  assetAndAmountError: string;
  currency: Currency | undefined;
  rate: InterestRate | undefined;
  rateError: string;
  wallet: CurrencyWallet | undefined;
  walletError: string;
  setAmount: SetState<string>;
  setAmountInfo: SetState<string>;
  setAssetAndAmountError: SetState<string>;
  setCurrencyKey: SetState<BaseCurrency | undefined>;
  setRateError: SetState<string>;
  setTerm: SetState<TermEnum | undefined>;
  setWalletError: SetState<string>;
  setWalletId: SetState<number | undefined>;
};

const NewLendContext = createContext<NewLendContextValue>({
  amount: INITIAL_AMOUNT,
  amountInfo: '',
  assetAndAmountError: '',
  rate: undefined,
  rateError: '',
  currency: undefined,
  wallet: undefined,
  walletError: '',
  setAmount: () => {},
  setAmountInfo: () => {},
  setAssetAndAmountError: () => {},
  setCurrencyKey: () => {},
  setRateError: () => {},
  setTerm: () => {},
  setWalletError: () => {},
  setWalletId: () => {},
});

export const NewLendContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // url state
  const params = useParams();

  // base state
  const [amount, setAmount] = useState(params.amount ?? INITIAL_AMOUNT);

  const [currencyKey, setCurrencyKey] = useState<BaseCurrency | undefined>(
    params.currency && params.network
      ? { currency: params.currency, network: params.network }
      : undefined
  );

  const [term, setTerm] = useState<TermEnum | undefined>(params.term);
  const [walletId, setWalletId] = useState<number | undefined>(params.walletId);

  // data derived from base state
  const { data: currencies } = useGetCurrenciesWithWallets();
  const { data: rates } = useSortedInterestRates({
    sortDirection: 'desc',
    kind: 'LEND',
  });

  const currency = currencies?.find(
    (item) =>
      item.currency === currencyKey?.currency &&
      item.network === currencyKey.network
  );

  const wallet = currency?.wallets?.find((item) => item.id === walletId);

  const rate = rates?.find(
    (item) =>
      item.currency === currency?.currency &&
      item.network === currency?.network &&
      (term ? item.term === term : true) // if term undefined, use rate with highest APR
  );

  // error state
  const [amountInfo, setAmountInfo] = useState('');
  const [assetAndAmountError, setAssetAndAmountError] = useState('');
  const [rateError, setRateError] = useState('');
  const [walletError, setWalletError] = useState('');

  return (
    <NewLendContext.Provider
      value={{
        amount,
        amountInfo,
        assetAndAmountError,
        currency,
        rate,
        rateError,
        wallet,
        walletError,
        setAmount,
        setAmountInfo,
        setAssetAndAmountError,
        setCurrencyKey,
        setRateError,
        setTerm,
        setWalletError,
        setWalletId,
      }}
    >
      {children}
    </NewLendContext.Provider>
  );
};

export const useNewLendContext = () => {
  return useContext(NewLendContext);
};
