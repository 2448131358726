import { Text } from 'common/components/Text';
import { WalletNameBox } from 'common/components/wallet/WalletNameBox';
import { CurrencyEnum } from 'common/types';
import { toLimitedPrec, toFixed } from 'modules/input-amount';
import { CurrencyWallet } from '../types';
import styles from './WalletItem.module.scss';

type Props = {
  wallet: CurrencyWallet;
  currency: CurrencyEnum;
  onSelect: (wallet: CurrencyWallet) => void;
  dataTest?: string;
};

export const WalletItem: React.FC<Props> = ({
  wallet,
  currency,
  onSelect,
  dataTest,
}) => {
  const { balance, name } = wallet;

  return (
    <div className={styles.container} onClick={() => onSelect(wallet)}>
      <WalletNameBox name={name} dataTest={dataTest} />
      <Text style='light1622' dataTest='walletsCount'>
        {`${toLimitedPrec(toFixed(balance))} ${currency}`}
      </Text>
    </div>
  );
};
