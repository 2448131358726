import { CurrencyEnum, NetworkEnum } from 'common/types';
import { BaseCurrency } from 'modules/select-currency-wallet/types';

export const DECIMALS_MAP: { [key in CurrencyEnum]: number } = {
  BTC: 8,
  ETH: 8,
  USDC: 6,
  USDT: 6,
  WBTC: 8,
};

export const STABLECOINS: CurrencyEnum[] = ['USDC', 'USDT'];

export const isStablecoin = (currency: CurrencyEnum) =>
  STABLECOINS.includes(currency);

export const currenciesByNetwork = (network: NetworkEnum): BaseCurrency[] => {
  if (network === 'BITCOIN') {
    return [{ currency: 'BTC', network: 'BITCOIN' }];
  }
  if (network === 'ETHEREUM') {
    return [
      { currency: 'ETH', network: 'ETHEREUM' },
      { currency: 'USDC', network: 'ETHEREUM' },
      { currency: 'USDT', network: 'ETHEREUM' },
      { currency: 'WBTC', network: 'ETHEREUM' },
    ];
  }
  return [];
};
