import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';

type Props = { id: number };

export const CharityFormID: React.FC<Props> = ({ id }) => {
  const formatMessage = useFormatMessage();

  return (
    <Text
      style='light1215'
      colorStyle='secondary'
      inlineStyle={{ display: 'block' }}
    >
      {formatMessage('transactions.charityFormID', { id })}
    </Text>
  );
};
