import { useLendsSummaryQuery } from 'modules/financial-ops/lend/api';
import { useFormatMessage } from 'modules/messages';
import { ShowAllButton } from '../ShowAllButton';
import { ActiveLendsCount } from 'modules/financial-ops/lend/lend-list/components/ActiveLendsCount';
import { SectionTemplate } from './SectionTemplate';
import { useFeatureFlag } from 'modules/feature-flags';
import { NewProductButton } from 'modules/financial-ops/common/components/NewProductButton';
import { useNavigate } from 'react-router-dom';
import {
  AverageLendingApr,
  ExpectedEarnFromLends,
  FundsLockedInLends,
  InterestEarnedSoFar,
  LendBubbleChart,
  RemainingInterestToEarn,
} from 'modules/financial-ops/lend/lend-overview';
import { useAreNoWalletsCreated } from 'modules/assets-and-wallets';

export const SectionLendSummary = () => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();

  const { data, isLoading } = useLendsSummaryQuery();
  const { data: walletsFailed } = useAreNoWalletsCreated();

  const featureFlagBorrow = useFeatureFlag('BORROW');
  const featureFlagNewLend = useFeatureFlag('CREATING_LEND');

  return (
    <SectionTemplate
      header={formatMessage('dashboard.lendSummary')}
      activeCount={<ActiveLendsCount />}
      isVerticalLayout={!featureFlagBorrow.data?.enabled}
      mainValue={
        <div>
          <ExpectedEarnFromLends lendSummary={data} isLoading={isLoading} />
        </div>
      }
      subValues={
        <>
          <InterestEarnedSoFar
            lendSummary={data}
            isLoading={isLoading}
            textStyle='xxbold1619'
          />
          <RemainingInterestToEarn
            lendSummary={data}
            isLoading={isLoading}
            textStyle='xxbold1619'
          />
          <FundsLockedInLends
            amount={data?.current_principal_amount_fiat}
            isLoading={isLoading}
            textStyle='xxbold1619'
          />
          <AverageLendingApr
            lendSummary={data}
            isLoading={isLoading}
            textStyle='xxbold1619'
          />
        </>
      }
      chart={
        <LendBubbleChart
          assets={data?.current_principal_assets}
          id='dashboard-lend-bubble-chart'
        />
      }
      buttons={
        <>
          <ShowAllButton path='/lends' isSmall={true} />
          <NewProductButton
            disabled={!featureFlagNewLend.data?.enabled || walletsFailed}
            tooltipTitle={
              !featureFlagNewLend.data?.enabled || walletsFailed
                ? formatMessage('common.featureCurrentlyUnavailableTryLater')
                : ''
            }
            text={formatMessage('lend.newLend')}
            onClick={() => navigate('/lends/new-lend')}
            type='darkSmall'
          />
        </>
      }
    />
  );
};
