import { useFormatMessage } from 'modules/messages';
import styles from './APRBox.module.scss';
import { Text } from 'common/components/Text';

type Props = {
  ratePct: string;
  dataTest?: string;
};

export const APRBox: React.FC<Props> = ({ ratePct, dataTest }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container} data-test={dataTest}>
      <Text style='light1215'>{formatMessage('common.apr', { ratePct })}</Text>
    </div>
  );
};
