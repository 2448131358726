import { useSpring, animated } from '@react-spring/web';
import clsx from 'clsx';
import { useEffect } from 'react';
import D from 'decimal.js';
import styles from './ProgressCircle.module.scss';
import { ReactComponent as WaveIcon } from './assets/wave.svg';
import { ReactComponent as CheckedIcon } from './assets/checked.svg';
import { LendState } from 'modules/financial-ops/common';

const Wave: React.FC<{ progress: D }> = ({ progress }) => {
  const [stylesSpring, api] = useSpring(() => ({
    from: { x: -400, opacity: 1 },
  }));

  useEffect(() => {
    api({
      x: 0,
      opacity: 1,
      loop: { reverse: true },
      config: { duration: 25000, clamp: true },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div
      style={{
        ...stylesSpring,
        marginTop: `-${progress.add(12)}px`,
      }}
    >
      <WaveIcon className={styles.wave} />
    </animated.div>
  );
};

export const ProgressCircle: React.FC<{ progress?: D; state: LendState }> = ({
  progress = new D(0),
  state,
}) => {
  return (
    <div
      className={clsx(
        styles.slider,
        (state === 'COMPLETED' || state === 'TERMINATED') && styles.completed
      )}
    >
      {state === 'ACTIVE' && <Wave progress={progress} />}
      {(state === 'COMPLETED' || state === 'TERMINATED') && <CheckedIcon />}
    </div>
  );
};
