import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { Member } from '../api';
import { useDeleteMemberContext } from './context';

type Props = {
  member: Member;
};

export const DeleteMemberButton: React.FC<Props> = ({ member }) => {
  const formatMessage = useFormatMessage();
  const { setMember } = useDeleteMemberContext();

  return (
    <Button
      type='secondarySmall'
      name={formatMessage('companyMembers.remove')}
      onClick={() => {
        setMember(member);
      }}
    />
  );
};
