import { useCallback } from 'react';
import { TransactionActionNewEvent } from '../types/transaction-action-new-event';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  PAGINATED_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  TransactionsRequiringAction,
  TransactionsResponse,
  getTransactionActionsQueryKey,
  getTransactionDetailsQueryKey,
} from 'modules/transactions/api';
import { useUserQuery } from 'modules/user';
import { findTransaction } from './utils-transactions';
import {
  Transaction,
  TransactionActionRead,
  TransactionDetails,
} from 'modules/transactions/types';
import { sortBy } from 'lodash';
import { PAGE_SIZE } from 'common/consts/consts';
import { useLoginContext } from 'modules/login/context';
import { log } from 'modules/logger';

export const useHandleTransactionActionNew = () => {
  const queryClient = useQueryClient();

  const { isLoggedIn } = useLoginContext();
  const { data: { data: user } = {} } = useUserQuery({
    enabled: isLoggedIn,
  });

  const handleTransactionActionNew = useCallback(
    (event: TransactionActionNewEvent) => {
      log('handling TRANSACTION_ACTION_NEW WebSocket event', event);

      // precautionary check, but normally this shouldn't ever happen, because websocket connection is established only after user logs in.
      if (typeof user === 'undefined') {
        return;
      }

      queryClient.setQueryData(
        getTransactionDetailsQueryKey(event.transaction_id),
        (prevState: TransactionDetails | undefined) => {
          if (typeof prevState === 'undefined') {
            return undefined;
          }

          let changed = false;
          const nextState: typeof prevState = { ...prevState };

          if (event.action.user.email === user.email) {
            nextState.requires_action = false;
            nextState.user_action = event.action.action;
            changed = true;
          }

          if (event.action.action === 'APPROVE') {
            nextState.approvals_count += 1;
            changed = true;
          }

          if (event.action.user_company_role === 'OWNER') {
            nextState.owner_action = event.action.action;
            changed = true;
          }

          if (!changed) {
            return undefined;
          }

          return nextState;
        }
      );

      queryClient.setQueryData(
        getTransactionActionsQueryKey(event.transaction_id),
        (prevState: TransactionActionRead[] | undefined) => {
          if (typeof prevState === 'undefined') {
            return undefined;
          }
          return [...prevState, event.action];
        }
      );

      // for the remaining data, don't do anything if action from event has been performed by user other than logged in user.
      // in other words, TRANSACTION_ACTION_NEW will affect only the exact user who performed given action.
      // all other users will get updates from TRANSACTION_UPDATED and until transaction state changes, they still can approve/reject.
      if (event.action.user.email !== user.email) {
        return undefined;
      }

      queryClient.setQueryData(
        TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
        (prevState: TransactionsRequiringAction | undefined) => {
          if (!prevState) {
            return undefined;
          }
          return { count: prevState.count - 1 };
        }
      );

      queryClient.setQueryData(
        DASHBOARD_TRANSACTIONS_QUERY_KEY,
        (prevState: Transaction[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          const prevIdx = prevState.findIndex(
            (t) => t.id === event.transaction_id
          );

          if (prevIdx === -1) {
            return undefined;
          }

          const transaction = prevState[prevIdx];

          const updatedTransaction: Transaction = {
            ...transaction,
            requires_action: false,
            user_action: event.action.action,
          };

          const nextState: typeof prevState = [
            ...prevState.slice(0, prevIdx),
            updatedTransaction,
            ...prevState.slice(prevIdx + 1),
          ];

          const nextStateSorted = sortBy(nextState, [
            'requires_action',
            (t) => new Date(t.created_at),
          ]).reverse(); // reverse needed, because sortBy sorts in ascending order

          // if page is full and updated transaction ended up as last element (it is older than the rest),
          // then refetch transactions to see if there is a newer transaction (or another pending one) that should be the last element.
          if (
            nextStateSorted.length === PAGE_SIZE &&
            nextStateSorted[nextStateSorted.length - 1].id ===
              event.transaction_id
          ) {
            queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
            return undefined;
          }

          return nextStateSorted;
        }
      );

      queryClient.setQueryData(
        PAGINATED_TRANSACTIONS_QUERY_KEY,
        (prevState: InfiniteData<TransactionsResponse> | undefined) => {
          if (typeof prevState === 'undefined') {
            return undefined;
          }

          // check if transaction from event is loaded into cache
          const found = findTransaction(event.transaction_id, prevState);

          // if transaction from event is not loaded into cache, don't do anything
          if (!found) {
            return undefined;
          }

          // transaction was found in cache, so update its user_action and requires_action fields
          const nextState: typeof prevState = {
            ...prevState,
            pages: prevState.pages.map((page) => {
              const foundTransaction = page.results.find(
                (transaction) => transaction.id === event.transaction_id
              );
              if (!foundTransaction) {
                return page;
              }
              return {
                ...page,
                results: page.results.map((transaction) => {
                  return transaction.id === event.transaction_id
                    ? {
                        ...transaction,
                        user_action: event.action.action,
                        requires_action: false,
                      }
                    : transaction;
                }),
              };
            }),
          };

          return nextState;
        }
      );
    },
    [queryClient, user]
  );

  return handleTransactionActionNew;
};
