import { useState } from 'react';

import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useQueryClient } from '@tanstack/react-query';
import { StepSuccess } from './StepSuccess';
import { StepSummary } from './StepSummary';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';
import { CharityFormDetailed } from 'modules/charity-forms/types';
import { StepInfoFinish } from './StepInfoFinish';
import {
  CHARITY_FORM_QUERY_KEY,
  useCharityFormFinishMutation,
} from 'modules/charity-forms/api';
import { useFiatCurrency } from 'modules/fiat';
import { LoaderBox } from 'common/components/loader';

type WithdrawSteps = 'finish' | 'summary' | '2FA' | 'success' | '2FAcancel';

type Props = {
  open: boolean;
  onClose: () => void;
  charityForm: CharityFormDetailed;
};

export const CharityFormFinishModal: React.FC<Props> = ({
  open,
  onClose,
  charityForm,
}) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const { data: fiatCurrency, isLoading: isFiatCurrencyLoading } =
    useFiatCurrency();

  const [step, setStep] = useState<WithdrawSteps>(
    charityForm.end_at ? 'finish' : 'summary'
  );
  const [error2FA, setError2FA] = useState<string>();

  const {
    mutate: finishMutation,
    isLoading: isFinishLoading,
    error: finishError,
  } = useCharityFormFinishMutation(charityForm.id);

  const onConfirm = (keys: string) => {
    finishMutation(
      { headers: generateHeader2FA(keys) },
      {
        onSuccess: () => {
          setStep('success');
          queryClient.invalidateQueries(CHARITY_FORM_QUERY_KEY(charityForm.id));
        },
        onError: (err) => {
          setErrorOTP(err, setError2FA, formatMessage);
        },
      }
    );
  };

  const closeModal = () => {
    setStep(charityForm.end_at ? 'finish' : 'summary');
    onClose();
  };

  if (isFiatCurrencyLoading) {
    return <LoaderBox />;
  }

  if (!fiatCurrency) {
    return null;
  }

  return (
    <>
      <Modal isOpen={open} onClose={closeModal}>
        {step === 'finish' && (
          <StepInfoFinish
            onGoNext={() => setStep('summary')}
            onCancel={closeModal}
            endDate={charityForm.end_at}
          />
        )}
        {step === 'summary' && (
          <StepSummary
            onGoBack={() => setStep('finish')}
            onCancel={closeModal}
            charityForm={charityForm}
            onFinish={() => {
              finishMutation(
                {},
                {
                  onSuccess: () => {
                    setStep('2FA');
                  },
                }
              );
            }}
            isLoading={isFinishLoading}
            finishError={finishError}
            fiatCurrency={fiatCurrency}
          />
        )}
        {step === 'success' && <StepSuccess onClose={closeModal} />}
        {step === '2FA' && (
          <Module2FA
            onVerify={onConfirm}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={isFinishLoading}
            onCancel={() => setStep('2FAcancel')}
            approvalOfTransaction={false}
            title={formatMessage('charityForms.finishCharityForm')}
          />
        )}
        {step === '2FAcancel' && (
          <Cancel2FA onClose={closeModal} approvalOfTransaction={false} />
        )}
      </Modal>
    </>
  );
};
