import { useTransactionsRequiringActionCountQuery } from './api';

export const useActiveTransactionsDataQuery = () => {
  const { data, isLoading } = useTransactionsRequiringActionCountQuery();

  return {
    data: data?.count,
    isLoading,
  };
};
