import clsx from 'clsx';
import styles from './InputPassword.module.scss';
import { Text, textStyles } from '../Text';
import { useState } from 'react';
import { InputWithTitleProps } from './types';

type InputType = 'password' | 'text';

export const InputPassword: React.FC<InputWithTitleProps> = ({
  onChange,
  onEnter,
  value,
  header,
  errorText,
  dataTest,
  dataTestShowBtn,
  id,
  autofocus,
}) => {
  const [inputType, setInputType] = useState<InputType>('password');

  return (
    <div className={styles.container}>
      <div className={styles.headerText}>
        <Text style='xbold1417' colorStyle={'primary'}>
          {header}
        </Text>
      </div>
      <div
        className={clsx(
          styles.inputContainer,
          errorText && styles.errorInputContainer
        )}
      >
        <input
          value={value}
          type={inputType}
          onChange={onChange}
          className={clsx(
            textStyles.light1425,
            styles.input,
            errorText && styles.errorInput
          )}
          id={id}
          data-test={dataTest}
          autoFocus={autofocus}
          onKeyUp={(event) => {
            if (event.code === 'Enter') {
              onEnter?.();
            }
          }}
          autoComplete='new-password'
        />
        <div
          className={styles.showPassword}
          onClick={() =>
            inputType === 'password'
              ? setInputType('text')
              : setInputType('password')
          }
          data-test={dataTestShowBtn}
        >
          <Text style='xbold1417' colorStyle={'light'}>
            {inputType === 'password' ? 'Show' : 'Hide'}
          </Text>
        </div>
      </div>
      {errorText && (
        <Text style='regular1215' colorStyle={'error'} dataTest='errorText'>
          {errorText}
        </Text>
      )}
    </div>
  );
};
