import clsx from 'clsx';
import { ReactComponent as SortArrowAsc } from 'common/assets/black-icons/sort-arrow-asc.svg';
import { ReactComponent as SortArrowDesc } from 'common/assets/black-icons/sort-arrow-desc.svg';
import { ReactComponent as SortArrowNone } from 'common/assets/black-icons/sort-arrow-none.svg';
import { CellTextBold } from './CellText';
import styles from './HeaderCell.module.scss';

export type SortValue = 'asc' | 'desc' | undefined;

type Props = {
  children: React.ReactNode;
  onSort?: (next: SortValue) => void;
  sort?: SortValue;
  className?: string;
};

export const HeaderCell: React.FC<Props> = ({
  children,
  sort,
  onSort,
  className,
}) => {
  return (
    <div
      className={clsx(styles.headerCell, className, !onSort && styles.sortless)}
      onClick={() => {
        if (onSort) {
          const next =
            sort === 'asc' ? 'desc' : sort === 'desc' ? undefined : 'asc';
          if (onSort) onSort(next);
        }
      }}
    >
      <CellTextBold>{children}</CellTextBold>
      {onSort && (
        <span className={styles.sort}>
          {sort === 'asc' ? (
            <SortArrowAsc />
          ) : sort === 'desc' ? (
            <SortArrowDesc />
          ) : (
            <SortArrowNone />
          )}
        </span>
      )}
    </div>
  );
};
