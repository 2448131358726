import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Text } from 'common/components/Text';
import { ApproveTransactionText } from 'modules/2FA';
import { CurrentThreshold } from 'modules/financial-ops/common/components/CurrentThreshold';
import { useFormatMessage } from 'modules/messages';
import { useIsSigner } from 'modules/user';

type Props = {
  onClose: () => void;
  onApprove: () => void;
};

export const StepNewBorrowSuccess: React.FC<Props> = ({
  onApprove,
  onClose,
}) => {
  const formatMessage = useFormatMessage();

  const { data: isSigner } = useIsSigner();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('borrow.borrowInitiated')}
      </ModalHeader>
      <ModalBody dataTest='borrowSuccessModal'>
        {isSigner ? (
          <>
            <div style={{ marginBottom: '30px' }}>
              <Text style='light1425'>
                {formatMessage(
                  'borrow.borrowWillBeCompletedAfterReachingThreshold'
                )}
              </Text>
            </div>
            <CurrentThreshold />
            <div style={{ marginTop: '30px' }}>
              <ApproveTransactionText />
            </div>
          </>
        ) : (
          <Text style='light1425'>
            {formatMessage('borrow.youCanSeeBorrowInBorrowsSection')}
          </Text>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          {isSigner ? (
            <>
              <Button
                type='secondary'
                onClick={onClose}
                name={formatMessage('common.button.later')}
              />
              <Button
                type='dark'
                onClick={onApprove}
                name={formatMessage('common.button.approve')}
              />
            </>
          ) : (
            <Button
              type='dark'
              onClick={onClose}
              name={formatMessage('common.button.done')}
            />
          )}
        </ModalActions>
      </ModalFooter>
    </>
  );
};
