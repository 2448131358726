import D from 'decimal.js';
import { TermEnum } from 'modules/financial-ops/common/types';
import { termToMonths } from 'modules/financial-ops/common/utils';
import { Currency } from 'modules/select-currency-wallet/types';

export const ascComp = (s1: string, s2: string) => s1.localeCompare(s2);
export const descComp = (s1: string, s2: string) => s1.localeCompare(s2) * -1;

export const ascCmpAmounts = (a: string | D, b: string | D) =>
  new D(a).sub(b).toNumber();
export const descCmpAmounts = (a: string | D, b: string | D) =>
  new D(a).sub(b).mul(-1).toNumber();

export const ascCmpTerms = (term1: TermEnum, term2: TermEnum) =>
  termToMonths(term1) - termToMonths(term2);
export const descCmpTerms = (term1: TermEnum, term2: TermEnum) =>
  (termToMonths(term1) - termToMonths(term2)) * -1;

export const ascCmpCurrencies = (c1: Currency, c2: Currency) => {
  if (c1.network !== c2.network) {
    return c1.network.localeCompare(c2.network);
  }
  return c1.currency.localeCompare(c2.currency);
};

export const descCmpCurrencies = (c1: Currency, c2: Currency) => {
  if (c1.network !== c2.network) {
    return c1.network.localeCompare(c2.network) * -1;
  }
  return c1.currency.localeCompare(c2.currency) * -1;
};
