import { toFixed } from 'modules/input-amount';
import { FormatMessageFn } from 'modules/messages';
import { CompanyRolePlus } from './types';

export const displayRole = (
  formatMessage: FormatMessageFn,
  role: CompanyRolePlus
) => {
  switch (role) {
    case 'INVITED':
      return formatMessage('companyMembers.roleLabel.invited');
    case 'MEMBER':
      return formatMessage('companyMembers.roleLabel.member');
    case 'OWNER':
      return formatMessage('companyMembers.roleLabel.owner');
    default:
      return role;
  }
};
