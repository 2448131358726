import { Button } from 'common/components/button';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useEffect } from 'react';
import { useKyxContext } from './context';

type Props = {};

export const KycFirstLoginModal: React.FC<Props> = ({}) => {
  const formatMessage = useFormatMessage();

  const {
    isFirstLoginModalVisible,
    allowKyxBar,
    blockKyxBar,
    hideFirstLoginModal,
    showCompleteKycModal,
  } = useKyxContext();

  useEffect(() => {
    if (isFirstLoginModalVisible) {
      blockKyxBar();
    }
  }, [isFirstLoginModalVisible]);

  const skip = () => {
    hideFirstLoginModal();
    allowKyxBar();
  };

  const startKycProcedure = () => {
    hideFirstLoginModal();
    showCompleteKycModal();
  };

  return (
    <Modal isOpen={isFirstLoginModalVisible} onClose={skip}>
      <ModalHeader showClose={false}>
        {formatMessage('kyx.completeKyc')}
      </ModalHeader>
      <ModalBody>
        <Text style='light1421'>
          {formatMessage(
            'kyx.dueToSecurityFinishKYCItWillAllowUnlockAllFeatures'
          )}
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            type='secondary'
            name={formatMessage('common.skip')}
            onClick={skip}
          />
          <Button
            type='dark'
            name={formatMessage('kyx.completeKyc')}
            onClick={startKycProcedure}
          />
        </ModalActions>
      </ModalFooter>
    </Modal>
  );
};
