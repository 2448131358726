import clsx from 'clsx';
import styles from './FormTextarea.module.scss';
import { Text, textStyles } from 'common/components/Text';
import { toDecimal } from 'modules/input-amount';
import React, { CSSProperties } from 'react';
import { HeaderWithDescription } from './HeaderWithDescription';
import { TextArea } from 'common/components/input/TextArea';

type Props = {
  value: string;
  onChange: (value: string) => void;
  id?: string;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  decimalLimit?: number;
  rows?: number;
  title: string | React.ReactNode;
  description?: string;
  error?: string;
  inlineHeaderStyles?: CSSProperties;
  textareaClassName?: string;
  containerClassName?: string;
};

export const FormTextarea: React.FC<Props> = ({
  value,
  onChange,
  name,
  disabled = false,
  placeholder,
  decimalLimit,
  rows = 1,
  title,
  description,
  error,
  id,
  containerClassName,
  inlineHeaderStyles,
  textareaClassName,
}) => {
  return (
    <div className={containerClassName}>
      {typeof title === 'string' ? (
        <HeaderWithDescription
          title={title}
          description={description}
          inlineStyles={inlineHeaderStyles}
        />
      ) : (
        title
      )}
      <TextArea
        autoComplete={'off'}
        rows={rows}
        className={clsx(
          styles.textarea,
          textareaClassName,
          textStyles.light1425,
          error && styles.error
        )}
        id={id}
        placeholder={placeholder ?? ''}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        disabled={disabled}
      />
      {error && (
        <Text
          style='regular1215'
          colorStyle={'error'}
          dataTest='errorText'
          inlineStyle={{ display: 'block', marginTop: '-5px' }}
        >
          {error}
        </Text>
      )}
      {decimalLimit && (
        <Text
          style='light1215'
          colorStyle={
            toDecimal(value.length).lte(toDecimal(decimalLimit))
              ? 'secondary'
              : 'errorDark'
          }
          className={styles.decimals}
        >
          {value.length}/{decimalLimit}
        </Text>
      )}
    </div>
  );
};
