import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import {
  AssetAmountReadOnly,
  InterestRate,
  TermAndRateSelector,
} from 'modules/financial-ops/common';
import { useFormatMessage } from 'modules/messages';
import { Currency } from 'modules/select-currency-wallet/types';
import { useNavigate } from 'react-router-dom';
import { Button } from 'common/components/button';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { useState } from 'react';
import styles from './EarnMoreOnLendModal.module.scss';
import { LendEffectOnSaving } from './LendEffectOnSaving';
import {
  principalMinError,
  principalMaxError,
  useLendsEffectMutation,
  LendsEffectPayload,
} from 'modules/financial-ops/lend/api';
import { Alert } from 'common/components/Alert';
import { Tooltip } from 'common/components/Tooltip';

type Props = {
  amount?: string;
  currency: Currency;
  rate?: InterestRate;
  walletId: number;
  disabled: boolean;
};

export const EarnMoreOnLendModal: React.FC<Props> = ({
  amount,
  currency,
  rate,
  walletId,
  disabled,
}) => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRate, setSelectedRate] = useState(rate);
  const [lendAmount, setLendAmount] = useState(amount);

  const lendsEffectMutation = useLendsEffectMutation();

  const handleChange = (newAmount?: string, newRate?: InterestRate) => {
    setLendAmount(newAmount);
    setSelectedRate(newRate);

    const payload: LendsEffectPayload = {
      calculation_base: 'PRINCIPAL',
      principal_wallet_id: walletId,
      principal_currency: currency.currency,
      term: newRate?.term!,
      summary: false,
      principal_amount: newAmount,
    };

    lendsEffectMutation.mutate(payload, {
      onError: (err) => {
        const principalMinErrorMatch = principalMinError(err.response?.data);
        if (principalMinErrorMatch) {
          handleChange(principalMinErrorMatch[1], newRate);
          setError(
            formatMessage('saving.amountWasIncreasedToAllowedMinimumOnLend', {
              currency: currency.currency,
            })
          );
        }
        const principalMaxErrorMatch = principalMaxError(err.response?.data);
        if (principalMaxErrorMatch) {
          handleChange(principalMaxErrorMatch[1], newRate);
          setError(
            formatMessage('saving.amountWasReducedToAllowedMaximumOnLend', {
              currency: currency.currency,
            })
          );
        }
      },
    });
  };

  return (
    <>
      <Tooltip
        title={
          disabled
            ? formatMessage('saving.pleaseEnterSavingAmountCorrectly')
            : ''
        }
      >
        <span>
          <Button
            type='secondary'
            name={formatMessage('saving.checkLend')}
            onClick={() => {
              lendsEffectMutation.reset();
              setError('');
              handleChange(amount, rate);
              setIsOpen(true);
            }}
            className={styles.button}
            disabled={disabled}
          />
        </span>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalHeader>{formatMessage('saving.earnMoreOnLend')}</ModalHeader>
        <ModalBody>
          {error && (
            <Alert severity='warning' text={error} className={styles.alert} />
          )}
          <SectionSubheader>{formatMessage('common.amount')}</SectionSubheader>
          {currency && lendAmount && (
            <AssetAmountReadOnly amount={lendAmount} currency={currency} />
          )}
          <TermAndRateSelector
            currency={currency}
            selectedRate={selectedRate}
            onSelectRate={(rate) => {
              handleChange(lendAmount, rate);
            }}
            interestRateKind={'LEND'}
            className={styles.term}
          />
          <LendEffectOnSaving
            amount={lendAmount}
            currency={{
              currency: currency.currency,
              network: currency.network,
            }}
            rate={selectedRate}
            isLoading={lendsEffectMutation.isLoading}
            totalEarn={lendsEffectMutation.data?.data.interest_total}
          />
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button
              type='secondary'
              onClick={() => setIsOpen(false)}
              name={formatMessage('common.button.cancel')}
            />
            <Button
              type='dark'
              onClick={() =>
                navigate('/lends/new-lend', {
                  state: {
                    currency: currency.currency,
                    network: currency?.network,
                    amount: lendAmount,
                    term: selectedRate?.term,
                    walletId,
                  },
                })
              }
              name={formatMessage('saving.goToLend')}
            />
          </ModalActions>
        </ModalFooter>
      </Modal>
    </>
  );
};
