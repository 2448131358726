import { Text } from 'common/components/Text';
import { termToMonths } from 'modules/financial-ops/common';
import { FormatMessageFn, useFormatMessage } from 'modules/messages';
import { Transaction, TransactionDetails } from '../types';

type Props = {
  transaction: Transaction | TransactionDetails;
};

export const getTransactionName = (
  transaction: Transaction | TransactionDetails,
  formatMessage: FormatMessageFn
) => {
  const { currency, kind, extra_content } = transaction;
  const months = extra_content?.term ? termToMonths(extra_content.term) : 0;

  switch (kind) {
    case 'DEPOSIT':
      return formatMessage('transactions.depositCurrency', {
        currency,
      });
    case 'WITHDRAWAL':
      return formatMessage('transactions.withdrawCurrency', {
        currency,
      });
    case 'LOAN_BORROW':
      return formatMessage('transactions.loanBorrowOutcoming', {
        principalCurrency: extra_content.principal_currency,
        currency,
        months,
      });
    case 'LOAN_PRINCIPAL_PAYOUT':
      return formatMessage('transactions.loanBorrowIncoming', {
        currency,
      });
    case 'LOAN_COLLATERAL_TOPUP_AUTO':
      return formatMessage('transactions.loanMarginCallTransfer', {
        currency: extra_content.principal_currency,
      });
    case 'LOAN_COLLATERAL_TOPUP':
      return formatMessage('transactions.loanCollateralTopup', {
        currency: extra_content.principal_currency,
      });
    case 'LOAN_COLLATERAL_EXCESS_RETURN':
      return formatMessage('transactions.loanCollateralExcessReturn', {
        currency: extra_content.principal_currency,
      });
    case 'LOAN_COLLATERAL_FULL_RETURN':
      return formatMessage('transactions.loanCollateralFullReturn', {
        currency: extra_content.principal_currency,
      });
    case 'LOAN_BORROW_REPAY':
      return formatMessage('transactions.loanBorrowRepay', { currency });
    case 'LOAN_BORROW_LIQUIDATION':
      return formatMessage('transactions.loanLiquidationTransfer', {
        currency: extra_content.principal_currency,
      });
    case 'LOAN_BORROW_CANCELLATION':
      return formatMessage('transactions.loanCancellationTransfer', {
        currency: extra_content.principal_currency,
      });
    case 'LOAN_LEND':
      return formatMessage('transactions.loanLend', { currency, months });
    case 'LOAN_LEND_TERMINATION':
      return formatMessage('transactions.terminationLendCurrency', {
        currency,
      });
    case 'LOAN_INTEREST':
      return formatMessage('transactions.loanInterest', {
        currency,
      });
    case 'LOAN_PRINCIPAL_RETURN':
      return formatMessage('transactions.loanPrincipalReturn', {
        currency,
      });
    case 'TRADE_IN':
      return formatMessage('transactions.incomingFundsTrade', {
        outCurrency: extra_content.out_currency,
        inCurrency: extra_content.in_currency,
      });
    case 'TRADE_OUT':
      return formatMessage('common.tradeOut', {
        outCurrency: extra_content.out_currency,
        inCurrency: extra_content.in_currency,
      });
    case 'TRADE_EXCESS_RETURN':
      return formatMessage('transactions.excessReturnTrade', {
        outCurrency: extra_content.out_currency,
        inCurrency: extra_content.in_currency,
      });
    case 'SAVING':
      return formatMessage('saving.savingCurrency', { currency });
    case 'SAVING_TOPUP':
      return formatMessage('transactions.savingTopupCurrency', { currency });
    case 'SAVING_INTEREST':
      return formatMessage('transactions.interestPayoutSaving', { currency });
    case 'SAVING_WITHDRAWAL':
      return formatMessage('transactions.withdrawFundsSaving', { currency });
    case 'CHARITY_DONATION':
      return formatMessage('transactions.donationCurrency', { currency });
    case 'CHARITY_WITHDRAWAL_OUT':
      return formatMessage('transactions.withdrawFundsCharityForm', {
        currency,
      });
    case 'CHARITY_WITHDRAWAL_IN':
      return formatMessage('transactions.incomingFundsCharityForm', {
        currency,
      });
    default:
      return transaction.kind;
  }
};

export const TransactionName: React.FC<Props> = ({ transaction }) => {
  const formatMessage = useFormatMessage();
  const name = getTransactionName(transaction, formatMessage);

  return <Text style='light1622'>{name}</Text>;
};
