import { useFormatMessage } from 'modules/messages';
import { FilterButton } from './FilterButton';
import { FilterBoxTemplate } from './FilterBoxTemplate';
import { useWallets } from 'modules/assets-and-wallets/api';
import { toggleElement } from 'common/utils/toggle-element';

export const WalletFilter: React.FC<{
  wallets?: number[];
  onWalletsChange: (value?: number[]) => void;
}> = ({ wallets, onWalletsChange }) => {
  const formatMessage = useFormatMessage();
  const { data } = useWallets();

  return (
    <FilterBoxTemplate
      header={formatMessage('common.wallet')}
      buttons={
        <>
          {data?.map((wallet, i) => (
            <FilterButton
              onClick={() => {
                onWalletsChange(toggleElement(wallets || [], wallet.id).sort());
              }}
              text={wallet.name}
              key={i}
              isSelected={wallets?.includes(wallet.id)}
            />
          ))}
        </>
      }
    />
  );
};
