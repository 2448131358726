import { Text } from 'common/components/Text';
import styles from './StepDeposit.module.scss';
import { Container, Field } from './components';
import { useFormatMessage } from 'modules/messages';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import { useCharityFormPublicContext } from '../context';
import { CurrencySelector } from 'modules/currency-selector';
import { useNewDonationContext } from './context';
import { QRCodeCanvas } from 'third-party/qrcode.react';
import { ReactComponent as CopyIcon } from './assets/copy.svg';
import { copyToClipboard } from 'common/utils/utils';
import { openSnackbar } from 'common/components/snackbar';
import { ReactComponent as InfoIcon } from 'common/assets/icons/info.svg';

type Props = {
  onBack: () => void;
  onNext: () => void;
};

export const StepDeposit: React.FC<Props> = ({ onBack, onNext }) => {
  const formatMessage = useFormatMessage();

  const { form } = useCharityFormPublicContext();

  const context = useNewDonationContext();

  const donationWallet = form.wallets.find(
    (wallet) => wallet.network === context.currency?.network
  );

  return (
    <Container>
      <Field>
        <Text style='xxbold3048'>
          {formatMessage('charityForms.completeYourDonation')}
        </Text>
        <Text style='light1624'>
          {formatMessage('charityForms.selectDonationMethod')}
        </Text>
      </Field>
      <Field>
        <Text style='xbold1417'>
          {formatMessage('charityForms.cryptocurrency')}
        </Text>
        <CurrencySelector
          className={styles.currencySelectorReadonly}
          currency={context.currency}
          currencies={[]}
          readOnly={true}
          showApr={false}
        />
      </Field>
      <Field>
        <Text style='xbold1417'>{formatMessage('charityForms.qrCode')}</Text>
        {donationWallet?.address && (
          <QRCodeCanvas
            className={styles.qrCode}
            size={150}
            value={donationWallet.address}
            fgColor='#000'
          />
        )}
      </Field>
      <Field>
        <Text style='xbold1417'>
          {formatMessage('charityForms.walletAddress')}
        </Text>
        <div className={styles.addressContainer}>
          <Text style='bold1417' className={styles.address}>
            {donationWallet?.address}
          </Text>
          <CopyIcon
            className={styles.copyIcon}
            onClick={() => {
              copyToClipboard(donationWallet?.address || '');
              openSnackbar(formatMessage('common.copy.message'), 'success');
            }}
          />
        </div>
      </Field>
      <div className={styles.info}>
        <InfoIcon className={styles.infoIcon} />
        <Text style='light1425'>
          {formatMessage(
            'charityForms.pleaseUseYourSelectedCryptocurrencyForDontations'
          )}
        </Text>
      </div>
      <Field>
        <PrimaryButton
          name={formatMessage('charityForms.donationSent')}
          primaryColor={form.primary_color}
          secondaryColor={form.secondary_color}
          onClick={onNext}
        />
        <SecondaryButton
          name={formatMessage('common.back')}
          primaryColor={form.primary_color}
          secondaryColor={form.secondary_color}
          onClick={onBack}
        />
      </Field>
    </Container>
  );
};
