import { BoldAmount } from 'modules/common-data-fields/BoldAmount';
import { CryptoToFiatAmount } from 'modules/fiat';
import { Transaction, TransactionDetails } from '../types';

type Props = {
  transaction: Transaction | TransactionDetails;
  dataTest?: string;
};

export const CellAmount: React.FC<Props> = ({ transaction, dataTest }) => {
  const { currency, amount, currency_rate } = transaction;

  return (
    <div
      data-test={dataTest}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <BoldAmount amount={amount} currency={currency} />
      <CryptoToFiatAmount
        amount={amount}
        currency={currency}
        currencyRate={currency_rate}
      />
    </div>
  );
};
