import styles from './CurrencyItem.module.scss';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { BaseCurrency } from 'modules/select-currency-wallet/types';
import { InterestRate } from 'modules/financial-ops/common';
import { APRBox } from './APRBox';

type Props = {
  selectedCurrency: BaseCurrency;
  onSelect?: (currency: BaseCurrency, rate?: InterestRate) => void;
  dataTest?: string;
  rates?: InterestRate[];
  showApr?: boolean;
};

export const CurrencyItem: React.FC<Props> = ({
  selectedCurrency,
  onSelect,
  rates,
  showApr = true,
}) => {
  const { currency, network } = selectedCurrency;

  const aprs = rates?.filter(
    (rate) => rate.currency === currency && rate.network === network
  );

  const bestApr = showApr && aprs ? aprs[0] : undefined;

  if (showApr && !bestApr) {
    return null;
  }

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (!onSelect) {
          return;
        }
        onSelect(selectedCurrency, bestApr);
      }}
    >
      <CurrencyBox
        currency={currency}
        network={network}
        dataTest={`currencyBox-${currency}`}
      />
      {bestApr && <APRBox ratePct={bestApr.rate_pct} dataTest='APRBadge' />}
    </div>
  );
};
