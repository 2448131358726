import { Chart } from './Chart';
import { BalanceChartContextProvider } from './context';
import { ModeSelector } from './ModeSelector';
import { PeriodSelector } from './PeriodSelector';
import styles from './BalanceChartController.module.scss';

export const BalanceChartController: React.FC<{}> = () => {
  return (
    <div className={styles.container}>
      <BalanceChartContextProvider>
        <ModeSelector />
        <Chart />
        <PeriodSelector />
      </BalanceChartContextProvider>
    </div>
  );
};
