import { Text } from 'common/components/Text';
import { CurrencyEnum } from 'common/types';
import { useFormatMessage } from 'modules/messages';
import { BoldAmount } from 'modules/common-data-fields/BoldAmount';

type Props = {
  amount?: string;
  currency: CurrencyEnum;
};

export const InterestValue: React.FC<Props> = ({ amount = '0', currency }) => {
  const formatMessage = useFormatMessage();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text style='light1215'>
        {formatMessage('transactions.interestValue') + ':'}
      </Text>
      <BoldAmount amount={amount} currency={currency} />
    </div>
  );
};
