import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { FiatAmount } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import { SavingSummary } from '../../api';

type Props = {
  savingSummary?: SavingSummary;
  isLoading?: boolean;
};

export const InterestEarnedSoFar: React.FC<Props> = ({
  savingSummary,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <LabelWithInfoTooltip
        text={formatMessage('finopsCommon.interestEarnedSoFar')}
        textStyle={'light1215'}
        tooltipText={formatMessage('saving.interestEarnedInActiveSavings')}
      />
      <FiatAmount
        amount={savingSummary?.current_profit_fiat}
        isDataLoading={isLoading}
        loaderContainerSize={48}
        loaderSize={30}
        textColorStyle='primary'
        textStyle={'xxbold3048'}
      />
    </div>
  );
};
