import { useCallback } from 'react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { NotificationNewEvent } from '../types/notification-new-event';
import {
  NOTIFICATIONS_INFINITE_QUERY_KEY,
  NOTIFICATIONS_PREVIEW_QUERY_KEY,
  NotificationResponse,
  UNREAD_COUNT_NOTIFICATIONS_QUERY_KEY,
  UnreadNotficationsCountResponse,
  buildNotificationsUrl,
} from 'modules/notifications/api';
import { PAGE_SIZE } from 'common/consts/consts';
import { sortBy, uniqBy } from 'lodash';
import { log } from 'modules/logger';

export const useHandleNotificationNew = () => {
  const queryClient = useQueryClient();

  const handleNotificationNew = useCallback(
    (event: NotificationNewEvent) => {
      log('handling NOTIFICATION_NEW WebSocket event', event);

      const newNotification = event.notification;

      queryClient.setQueryData(
        UNREAD_COUNT_NOTIFICATIONS_QUERY_KEY,
        (prevState: UnreadNotficationsCountResponse | undefined) => {
          if (prevState === undefined) {
            return undefined;
          }

          return { count: prevState.count + 1 };
        }
      );

      queryClient.setQueryData(
        NOTIFICATIONS_PREVIEW_QUERY_KEY,
        (prevState: NotificationResponse | undefined) => {
          if (prevState === undefined) {
            return undefined;
          }

          const notifications = sortBy(
            [newNotification, ...prevState.results],
            (notification) => new Date(notification.created_at)
          )
            .reverse() // reverse needed, because sortBy sorts in ascending order
            .slice(0, PAGE_SIZE);

          return {
            ...prevState,
            count: prevState.count + 1,
            next:
              prevState.count + 1 > PAGE_SIZE
                ? buildNotificationsUrl(2, { withApiBaseUrl: true })
                : null,
            results: notifications,
          };
        }
      );

      queryClient.setQueryData(
        NOTIFICATIONS_INFINITE_QUERY_KEY,
        (prevState: InfiniteData<NotificationResponse> | undefined) => {
          if (prevState === undefined) {
            return undefined;
          }

          const pageCount = prevState.pages.length;
          const firstPage = prevState.pages.at(0);
          const lastPage = prevState.pages.at(-1);

          // this situation is actually impossible, this is only precautionary check
          if (!firstPage || !lastPage) {
            return undefined;
          }

          const isEveryPageFetched = lastPage.next === null;
          const isLastPageFull = lastPage.results.length === PAGE_SIZE;

          const flatNotifications = prevState.pages.flatMap(
            (page) => page.results
          );

          const nextNotifications = [newNotification, ...flatNotifications];

          // remove duplicates. This is for race condition case where next page is fetched
          // faster than TRANSACTION_NEW arrives which causes one transaction to appear on two pages.
          const nextNotificationsUniq = uniqBy(nextNotifications, (n) => n.id);

          const nextCount = firstPage.count + 1;

          const nextState: typeof prevState = {
            ...prevState,
            pages: prevState.pages.map((page, idx) => ({
              count: nextCount,
              next:
                idx === pageCount - 1 && isEveryPageFetched && isLastPageFull
                  ? buildNotificationsUrl(pageCount + 1, {
                      withApiBaseUrl: true,
                    })
                  : page.next,
              previous: page.previous,
              results: nextNotificationsUniq.slice(
                idx * PAGE_SIZE,
                (idx + 1) * PAGE_SIZE
              ),
            })),
          };

          return nextState;
        }
      );
    },
    [queryClient]
  );

  return handleNotificationNew;
};
