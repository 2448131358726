import { SetState } from 'common/types';
import { createContext, useContext, useState } from 'react';

type LoginContextValue = {
  isLoggedIn: boolean;
  setIsLoggedIn: SetState<boolean>;
};

const LoginContext = createContext<LoginContextValue>({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
});

export const LoginContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <LoginContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  return useContext(LoginContext);
};
