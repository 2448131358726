import { Text } from 'common/components/Text';
import { LoaderBox } from 'common/components/loader';
import {
  formatDateWithoutYear,
  formatDatetime,
  formatTimeWithoutSec,
} from 'common/utils/utils';
import { differenceInSeconds, subDays, subHours, subMonths } from 'date-fns';
import Decimal from 'decimal.js';
import { CURRENCY_SYMBOLS, FiatAmount, useFiatCurrency } from 'modules/fiat';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import styles from './Chart.module.scss';
import {
  BalanceChartMode,
  BalanceChartPeriod,
  useBalanceChartContext,
} from './context';
import { useBalanceMeasuresQuery } from './query';
import { BalanceMeasure, BalanceMeasureInterval } from './types';
import { ModeDot } from './ModeSelector';
import { useGetTransactionLabel } from './utils';
import { useFormatMessage } from 'modules/messages';

const CHART_HEIGHT = 200;

const MINUTE_MS = 60 * 1000;
const HOUR_MS = 60 * 60 * 1000;
const DAY_MS = 24 * HOUR_MS;

type DataPoint = BalanceMeasure & {
  created_at_number: number;
};

export const Chart: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { activePeriod, activeMode } = useBalanceChartContext();

  const { data: fiatCurrency, isLoading: isFiatCurrencyLoading } =
    useFiatCurrency();

  const { data: measures, isLoading: isMeasuresLoading } =
    useBalanceMeasuresQuery(activePeriod);

  if (isMeasuresLoading || isFiatCurrencyLoading) {
    return <LoaderBox height={`${CHART_HEIGHT}px`} />;
  }

  if (!measures || !fiatCurrency) {
    return null;
  }

  if (!measures.data.length) {
    return (
      <Text
        style='light1215'
        className={styles.noDataPlaceholder}
        inlineStyle={{ height: `${CHART_HEIGHT}px` }}
      >
        {formatMessage('balanceChart.noChartDataAvailable')}
      </Text>
    );
  }

  const yAxisMax = getYAxisMax(measures.data, activeMode);

  let dataPoints: DataPoint[] = measures.data.map((measure) => ({
    ...measure,
    created_at_number: Number(new Date(measure.created_at)),
  }));

  const oldest = dataPoints[0];
  const newest = dataPoints[dataPoints.length - 1];

  let xAxisMin =
    activePeriod === 'ALL' ||
    Math.abs(
      differenceInSeconds(
        oldest.created_at_number,
        onePeriodAgo(newest.created_at, activePeriod)
      )
    ) <= periodToInterval[activePeriod]
      ? oldest.created_at_number
      : Number(onePeriodAgo(newest.created_at, activePeriod));

  const xAxisMax = newest.created_at_number;

  // if period is ALL, make the X domain at least 24h wide
  if (activePeriod === 'ALL' && xAxisMax - xAxisMin < DAY_MS) {
    xAxisMin = xAxisMax - DAY_MS;
  }

  // if oldest measure point is not the min x value, extend the chart by drawing line at y: 0
  if (xAxisMin < oldest.created_at_number) {
    dataPoints = [
      {
        created_at: '',
        created_at_number: xAxisMin,
        interval: 'MINUTE' as BalanceMeasureInterval,
        total_balance_fiat: '0',
        available_balance_fiat: '0',
      },
      {
        created_at: '',
        created_at_number: oldest.created_at_number - 1,
        interval: 'MINUTE' as BalanceMeasureInterval,
        total_balance_fiat: '0',
        available_balance_fiat: '0',
      },
    ].concat(dataPoints);
  }

  const getTicks = () => {
    const firstTick5Min =
      xAxisMin - (xAxisMin % (5 * MINUTE_MS)) + 5 * MINUTE_MS;
    const firstTickHour = xAxisMin - (xAxisMin % HOUR_MS) + HOUR_MS;
    const firstTickDay = xAxisMin - (xAxisMin % DAY_MS) + DAY_MS;

    let ticks: number[] = [];
    switch (activePeriod) {
      case '1D': {
        ticks = [...new Array(6)].map(
          (_, i) => firstTickHour + i * HOUR_MS * 4
        );
        break;
      }
      case '1H': {
        ticks = [...new Array(6)].map(
          (_, i) => firstTick5Min + i * 10 * MINUTE_MS
        );
        break;
      }
      case '1M': {
        ticks = [...new Array(7)].map((_, i) => firstTickDay + i * DAY_MS * 5);
        break;
      }
      case '1W': {
        ticks = [...new Array(7)].map((_, i) => firstTickDay + i * DAY_MS);
        break;
      }
      case '1Y': {
        ticks = [...new Array(7)].map((_, i) => firstTickDay + i * DAY_MS * 60);
        break;
      }
      case '3M': {
        ticks = [...new Array(7)].map((_, i) => firstTickDay + i * DAY_MS * 14);
        break;
      }
      case 'ALL': {
        const diff = xAxisMax - xAxisMin;
        const tickWidth = Math.floor(diff / 6);
        ticks = [...new Array(7)].map((_, i) => xAxisMin + i * tickWidth);
        break;
      }
    }
    // make sure all ticks are bounded within X axis valus range
    return ticks.filter((tick) => tick >= xAxisMin && tick <= xAxisMax);
  };

  // width 99% - because when 100% is set, then the chart does not resize on window/viewport resize
  return (
    <ResponsiveContainer height={CHART_HEIGHT} width={'99%'}>
      <AreaChart data={dataPoints}>
        <YAxis
          axisLine={false}
          domain={[0, yAxisMax]}
          fontSize={11}
          fontWeight={600}
          stroke='black'
          tickCount={5}
          tickFormatter={(value) => {
            const currency = CURRENCY_SYMBOLS[fiatCurrency];
            if (value >= 10 ** 9) {
              return `${currency}${value / 10 ** 9}B`;
            }
            if (value >= 10 ** 6) {
              return `${currency}${value / 10 ** 6}M`;
            }
            if (value >= 10 ** 3) {
              return `${currency}${value / 10 ** 3}k`;
            }
            return `${currency}${value}`;
          }}
          tickLine={false}
          type='number'
        />
        <XAxis
          axisLine={false}
          dataKey={'created_at_number'}
          domain={[xAxisMin, xAxisMax]}
          fontSize={11}
          fontWeight={600}
          stroke={'black'}
          tickLine={false}
          tickFormatter={(tick) => {
            if (tick === 'auto' || tick === 0) {
              return '';
            }
            if (
              ['1D', '1H'].includes(activePeriod) ||
              (activePeriod === 'ALL' &&
                newest.created_at_number - oldest.created_at_number <= DAY_MS)
            ) {
              return formatTimeWithoutSec(new Date(tick).toISOString());
            }
            return formatDateWithoutYear(new Date(tick).toISOString());
          }}
          ticks={getTicks()}
          type='number'
        />
        {['TOTAL', 'TOTAL_AND_AVAILABLE'].includes(activeMode) && (
          <Area
            type='monotone'
            dataKey='total_balance_fiat'
            stroke='var(--balancechart-total)'
            fillOpacity={1}
            fill='url(#totalGradient)'
            isAnimationActive={false}
          />
        )}
        {['AVAILABLE', 'TOTAL_AND_AVAILABLE'].includes(activeMode) && (
          <Area
            type='monotone'
            dataKey='available_balance_fiat'
            stroke='var(--balancechart-available)'
            fillOpacity={1}
            fill='url(#availableGradient)'
            isAnimationActive={false}
          />
        )}
        <RechartsTooltip
          cursor={false}
          content={(props) => {
            const dataPoint = props.payload?.[0]?.payload;
            if (!dataPoint) {
              return null;
            }
            // this detects if data point is artificial, i.e. one of two points added to draw line at y:0
            if (!dataPoint.created_at) {
              return null;
            }
            return <CustomTooltip dataPoint={dataPoint} mode={activeMode} />;
          }}
        />
        <CartesianGrid
          strokeDasharray={'1 4'}
          stroke='var(--balancechart-reference)'
          vertical={false}
        />
        <defs>
          <linearGradient id='availableGradient' x1='0' x2='0' y1='0' y2='1'>
            <stop
              offset='0%'
              stopOpacity={0.33}
              stopColor='var(--balancechart-available)'
            />
            <stop
              offset='100%'
              stopOpacity={0}
              stopColor='var(--balancechart-available)'
            />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id='totalGradient' x1='0' x2='0' y1='0' y2='1'>
            <stop
              offset='0%'
              stopOpacity={0.33}
              stopColor='var(--balancechart-total)'
            />
            <stop
              offset='100%'
              stopOpacity={0}
              stopColor='var(--balancechart-total)'
            />
          </linearGradient>
        </defs>
      </AreaChart>
    </ResponsiveContainer>
  );
};

const getYAxisMax = (measures: BalanceMeasure[], mode: BalanceChartMode) => {
  const balanceKey =
    mode === 'AVAILABLE' ? 'available_balance_fiat' : 'total_balance_fiat';
  const maxBalance = Math.max(
    ...measures.map((measure) => Number(measure[balanceKey]))
  );
  let yAxisMax = new Decimal(1);
  while (yAxisMax.lte(maxBalance)) {
    yAxisMax = yAxisMax.mul(10);
  }
  for (
    let subscale = new Decimal('0.2');
    subscale.lte('0.8');
    subscale = subscale.add('0.2')
  ) {
    if (subscale.mul(yAxisMax).gt(maxBalance)) {
      return subscale.mul(yAxisMax).toNumber();
    }
  }
  return yAxisMax.toNumber();
};

const onePeriodAgo = (date: string, period: BalanceChartPeriod) => {
  switch (period) {
    case '1D':
      return subDays(new Date(date), 1);
    case '1H':
      return subHours(new Date(date), 1);
    case '1M':
      return subMonths(new Date(date), 1);
    case '1W':
      return subDays(new Date(date), 7);
    case '1Y':
      return subMonths(new Date(date), 12);
    case '3M':
      return subMonths(new Date(date), 3);
    default:
      return new Date(date); // just to avoid uncaught exception, otherwise default case does not make sense
  }
};

const periodToInterval: { [key in BalanceChartPeriod]: number } = {
  ALL: 24 * 60 * 60,
  '1D': 1 * 60 * 60,
  '1H': 5 * 60,
  '1M': 24 * 60 * 60,
  '1W': 24 * 60 * 60,
  '1Y': 24 * 60 * 60,
  '3M': 24 * 60 * 60,
};

const CustomTooltip: React.FC<{
  dataPoint: DataPoint;
  mode: BalanceChartMode;
}> = ({ dataPoint, mode }) => {
  const getTransactionLabel = useGetTransactionLabel();

  return (
    <div className={styles.tooltip}>
      {['TOTAL_AND_AVAILABLE', 'TOTAL'].includes(mode) && (
        <div className={styles.tooltipBalance}>
          <FiatAmount
            textStyle='xbold1417'
            textColorStyle='primary'
            amount={dataPoint.total_balance_fiat}
          />
          <ModeDot mode='TOTAL' />
        </div>
      )}
      {['TOTAL_AND_AVAILABLE', 'AVAILABLE'].includes(mode) && (
        <div className={styles.tooltipBalance}>
          <FiatAmount
            textStyle='xbold1417'
            textColorStyle='primary'
            amount={dataPoint.available_balance_fiat}
          />
          <ModeDot mode='AVAILABLE' />
        </div>
      )}
      <Text style='light1215' colorStyle='secondary'>
        {formatDatetime(dataPoint.created_at)}
      </Text>
      {dataPoint.transaction_kind && (
        <Text style='light1215' colorStyle='secondary'>
          {getTransactionLabel(dataPoint.transaction_kind)}
        </Text>
      )}
    </div>
  );
};
