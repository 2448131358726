import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { LinkBox } from './LinkBox';
import { buildCharityFormInappPageUrl } from 'modules/charity-forms-public/utils';

type Props = { token: string };

export const InappLink: React.FC<Props> = ({ token }) => {
  const formatMessage = useFormatMessage();

  const link = buildCharityFormInappPageUrl(token);

  return (
    <div>
      <Text style='xxbold1619' inlineStyle={{ display: 'block' }}>
        {formatMessage('charityForms.linkToShare')}
      </Text>
      <LinkBox link={link} clickable={true} />
    </div>
  );
};
