import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import styles from './StepSuccess.module.scss';
import { SendAgainEmailLink } from 'modules/create-company/components/SendAgainEmailLink';
import { Alert } from 'common/components/Alert';

type Props = { onLinkResend: () => void; errorSendingAgain?: string };

export const StepSuccess: React.FC<Props> = ({
  onLinkResend,
  errorSendingAgain,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('resetPassword.stepSuccess.header')}
      </ModalHeader>
      <ModalBody>
        {errorSendingAgain && (
          <Alert
            severity='error'
            text={errorSendingAgain}
            className={styles.errorAlert}
          />
        )}
        <div className={styles.subtitle}>
          <Text style='xbold1821' colorStyle={'primary'}>
            {formatMessage('resetPassword.stepSuccess.subtitle')}
          </Text>
        </div>
        <div>
          <Text style='light1421' colorStyle={'primary'}>
            {`${formatMessage('resetPassword.stepSuccess.description')}`}
          </Text>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          extraLink={<SendAgainEmailLink onClick={onLinkResend} />}
        />
      </ModalFooter>
    </>
  );
};
