import clsx from 'clsx';
import { ReactComponent as SortArrowAsc } from 'common/assets/black-icons/sort-arrow-asc.svg';
import { ReactComponent as SortArrowDesc } from 'common/assets/black-icons/sort-arrow-desc.svg';
import { ReactComponent as SortArrowNone } from 'common/assets/black-icons/sort-arrow-none.svg';
import styles from './SortText.module.scss';
import { Text } from 'common/components/Text';

export type SortValue = 'asc' | 'desc' | undefined;

type Props = {
  children: React.ReactNode;
  onSort?: (next: SortValue) => void;
  sort?: SortValue;
  className?: string;
};

export const SortText: React.FC<Props> = ({
  children,
  sort,
  onSort,
  className,
}) => {
  return (
    <div
      className={clsx(styles.container, className)}
      onClick={() => {
        const next =
          sort === 'asc' ? 'desc' : sort === 'desc' ? undefined : 'asc';
        if (onSort) onSort(next);
      }}
    >
      <Text style={sort ? 'xbold1215' : 'light1215'}>{children}</Text>
      <span className={styles.sort}>
        {sort === 'asc' ? (
          <SortArrowAsc />
        ) : sort === 'desc' ? (
          <SortArrowDesc />
        ) : (
          <SortArrowNone />
        )}
      </span>
    </div>
  );
};
