import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { Text } from 'common/components/Text';
import Decimal from 'decimal.js';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { InterestRate } from 'modules/financial-ops/common';
import { useInterestRates } from 'modules/financial-ops/common/api';
import { Separator } from 'modules/financial-ops/common/components/Separator';
import { KyxAlert, useIsKyxCompleted } from 'modules/kyx';
import styles from './LendEffect.module.scss';
import { GoToSummaryButton } from 'common/components/button';

type Props = {
  amount: Decimal | undefined;
  currency: Currency | undefined;
  isLoading: boolean;
  rate: InterestRate | undefined;
  totalEarn: string | undefined;
  wallet: CurrencyWallet | undefined;
  onContinueToSummary: () => void;
};

export const LendEffect: React.FC<Props> = ({
  amount,
  currency,
  isLoading,
  rate,
  totalEarn,
  wallet,
  onContinueToSummary,
}) => {
  const formatMessage = useFormatMessage();

  const isKyxCompleted = useIsKyxCompleted();

  const { data: interestRatesData } = useInterestRates('LEND');

  const areAvailableLoans =
    interestRatesData?.filter(
      (rate) =>
        rate.currency === currency?.currency &&
        rate.network === currency.network
    ).length !== 0;

  return (
    <>
      <div className={styles.spaceBetween}>
        <Text style='light1425'>{formatMessage('common.interestRate')}</Text>
        <Text style='light1425' dataTest='interestRate'>
          {rate ? `${toFixed(rate.rate_pct)}%` : '-'}
        </Text>
      </div>
      <Separator />
      <div className={styles.spaceBetween} style={{ marginBottom: '10px' }}>
        <Text style='light1425'>{formatMessage('finopsCommon.totalEarn')}</Text>
        {isLoading ? (
          <RotatingLoader size={16} type='secondary' />
        ) : rate &&
          amount &&
          currency &&
          wallet &&
          amount.gt(0) &&
          totalEarn ? (
          <Text
            style='xxbold1619'
            className={styles.textRight}
            dataTest='totalEarn'
          >
            {`${toLimitedPrec(toFixed(totalEarn))} ${currency.currency}`}
          </Text>
        ) : (
          <Text style='light1425' className={styles.textRight}>
            {'-'}
          </Text>
        )}
      </div>
      <div className={styles.spaceBetween} style={{ marginBottom: '40px' }}>
        <Text style='light1425'>{formatMessage('lend.lendingAmount')}</Text>
        {amount && amount.gt(0) ? (
          <Text
            style={'xxbold1619'}
            className={styles.textRight}
            dataTest='lendingAmount'
          >
            {`${toLimitedPrec(toFixed(amount))} ${currency?.currency}`}
          </Text>
        ) : (
          <Text style={'light1425'} className={styles.textRight}>
            {'-'}
          </Text>
        )}
      </div>
      <KyxAlert className={styles.kycAlert} />
      <GoToSummaryButton
        className={styles.summaryButton}
        disabled={
          (currency && interestRatesData && !areAvailableLoans) ||
          !isKyxCompleted ||
          isLoading
        }
        onClick={onContinueToSummary}
      />
    </>
  );
};
