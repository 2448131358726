import { Alert } from 'common/components/Alert';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { openSnackbar } from 'common/components/snackbar';
import { Text } from 'common/components/Text';
import { useRegisterEmailRequestMutation } from 'modules/confirm-email';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { ERR_LIMIT_SENDING_EMAIL } from '../api';
import { SendAgainEmailLink } from './SendAgainEmailLink';
import styles from './StepSuccess.module.scss';
import { generateLimitSendingEmailError } from 'common/utils/utils';
import { ReactComponent as InboxIcon } from '../assets/inbox.svg';

type Props = { email: string };

export const StepSuccess: React.FC<Props> = ({ email }) => {
  const formatMessage = useFormatMessage();
  const [error, setError] = useState('');

  const resendMutation = useRegisterEmailRequestMutation();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('signup.accountHasBeenCreated')}
      </ModalHeader>
      <ModalBody>
        {error && (
          <Alert severity='error' text={error} className={styles.errorAlert} />
        )}
        <Text
          style='xbold1821'
          colorStyle={'primary'}
          className={styles.subtitle}
        >
          {formatMessage('signup.pleaseConfirmEmail')}
        </Text>
        <Text
          style='light1421'
          colorStyle={'primary'}
          inlineStyle={{ display: 'block' }}
        >
          {formatMessage('signup.verifySentEmail', {
            email,
          })}
        </Text>
        <div className={styles.icon}>
          <InboxIcon />
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          extraLink={
            <SendAgainEmailLink
              isLoading={resendMutation.isLoading}
              onClick={() => {
                if (resendMutation.isLoading) {
                  return;
                }
                resendMutation.mutate(
                  { email },
                  {
                    onSuccess: () => {
                      openSnackbar(
                        formatMessage('confirmEmail.resendLink.success'),
                        'success'
                      );
                    },
                    onError: (err) => {
                      if (
                        err.response?.data.detail?.startsWith(
                          ERR_LIMIT_SENDING_EMAIL
                        )
                      ) {
                        setError(
                          generateLimitSendingEmailError(
                            err.response?.data.detail,
                            formatMessage('signup.errorLimitSendingEmails'),
                            formatMessage
                          )
                        );
                      }
                    },
                  }
                );
              }}
            />
          }
        />
      </ModalFooter>
    </>
  );
};
