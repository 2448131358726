import Decimal from 'decimal.js';
import { MAX_DIGITS } from './consts';

export const NUM_REGEX = /^(0*)((\d*)\.?(\d*))$/;

// this function cuts only decimal part, it does not affect integer part, so if `num` is a 17-digit long integer, the returned value will still be 17-digit long.
export const toLimitedPrec = (num: string) => {
  const [integerPart, decimalPart] = num.split('.');
  return (
    integerPart +
    (decimalPart && MAX_DIGITS - integerPart.length > 0
      ? '.' + decimalPart.slice(0, MAX_DIGITS - integerPart.length)
      : '')
  );
};

// this function assumes that all other invalid character are checked against / prevented elsewehere
export const toDecimal = (
  num: number | string | Decimal | undefined | null
) => {
  if (num === null || num === undefined || num === '.' || num === '') {
    return new Decimal(0);
  }
  return new Decimal(num);
};

export const toFixed = (
  num: number | string | Decimal | undefined | null,
  decimalPlaces?: number,
  roundingMode?: Decimal.Rounding
) => {
  if (typeof decimalPlaces === 'undefined') {
    return toDecimal(num).toFixed();
  }
  if (typeof roundingMode === 'undefined') {
    return toDecimal(num).toFixed(decimalPlaces);
  }
  return toDecimal(num).toFixed(decimalPlaces, roundingMode);
};
