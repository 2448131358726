import { Tooltip } from 'common/components/Tooltip';
import { useFormatMessage } from 'modules/messages';
import { useAreNoWalletsCreated } from '../hooks';

type Props = {
  children: React.ReactElement;
};

export const WalletsCreationFailedTooltip: React.FC<Props> = ({ children }) => {
  const formatMessage = useFormatMessage();
  const { data } = useAreNoWalletsCreated();

  const title = data
    ? formatMessage('common.featureCurrentlyUnavailableTryLater')
    : '';

  return <Tooltip title={title}>{children}</Tooltip>;
};
