import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useContext } from 'react';
import { Member } from '../api';
import { MemberBadge } from '../components/MemberBadge';

type Props = {
  member: Member | undefined;
  onRemove: () => void;
};

export const StepConfirm: React.FC<Props> = ({ member, onRemove }) => {
  const formatMessage = useFormatMessage();

  const { onClose } = useContext(ModalContext);

  return (
    <>
      <ModalHeader
        subtitle={formatMessage(
          'companyMembers.removeMember.willRemoveMemberWantToProceed'
        )}
      >
        {formatMessage('companyMembers.removeMember')}
      </ModalHeader>
      <ModalBody>{member && <MemberBadge {...member} />}</ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            type='secondary'
            name={formatMessage('common.button.cancel')}
            onClick={onClose}
          />
          <Button
            type={'dark'}
            name={formatMessage('companyMembers.remove')}
            onClick={onRemove}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
