import { useState } from 'react';
import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import {
  TransactionActionPayload,
  useTransactionActionMutation,
} from 'modules/transactions/api';
import { openSnackbar } from 'common/components/snackbar';
import { BorrowDetails } from '../types';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
  STATUS_INVALID_OTP,
  ALREADY_USED_OTP,
} from 'modules/2FA';
import { BorrowTopupContextProvider, useBorrowTopupContext } from './context';
import { useCalculateEffect } from './use-calculate-effect';
import { StepBorrowTopup } from './components/StepBorrowTopup';
import { toDecimal } from 'modules/input-amount';
import { StepSuccess } from './components/StepSuccess';
import { StepSummary } from './components/StepSummary';

type TopupSteps = 'topup' | 'summary' | '2FA' | 'success' | '2FAcancel';

type Props = {
  open: boolean;
  onClose: () => void;
  id: number;
  borrow?: BorrowDetails;
};

type TopupWrappedProps = {
  open: boolean;
  onClose: () => void;
  id: number;
};

const BorrowTopupWrapped: React.FC<TopupWrappedProps> = ({
  open,
  onClose,
  id,
}) => {
  const formatMessage = useFormatMessage();

  const [step, setStep] = useState<TopupSteps>('topup');
  const [transactionId, setTransactionId] = useState<number>();
  const [error2FA, setError2FA] = useState<string>();

  const context = useBorrowTopupContext();

  const { mutate: transactionActionMutation, isLoading: isLoadingTransaction } =
    useTransactionActionMutation();

  const continueToSummary = () => {
    if (context.amountError || context.walletError) {
      return;
    }

    if (toDecimal(context.amount).gt(toDecimal(context.wallet?.balance))) {
      context.setAmountError(formatMessage('common.notEnoughFundsToTopup'));
      return;
    }

    if (toDecimal(context.amount).eq(0)) {
      context.setAmountError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }

    context.setWalletError('');
    context.setAmountError('');
    setStep('summary');
  };

  const onTopup = (code2FA: string) => {
    if (!transactionId) {
      return;
    }

    const payload: TransactionActionPayload = {
      transaction_id: transactionId,
      action: 'APPROVE',
    };

    transactionActionMutation(
      { payload, headers: generateHeader2FA(code2FA) },
      {
        onSuccess: () => {
          openSnackbar(
            formatMessage('transactions.approved.message'),
            'success'
          );
          closeModal();
        },
        onError: (err) => {
          if (
            err.response?.data?.status === STATUS_INVALID_OTP ||
            err.response?.data?.status === ALREADY_USED_OTP
          ) {
            setErrorOTP(err, setError2FA, formatMessage);
          } else {
            const text = Object.values(err.response!.data)[0] as string;
            openSnackbar(text, 'error');
          }
        },
      }
    );
  };

  const {
    calculateEffect,
    mutation: { isLoading: isLoadingEffect, data: effectData, reset },
  } = useCalculateEffect();

  const closeModal = () => {
    onClose();
    setStep('topup');
    context.setAmount('');
    context.setAmountError('');
    context.setAmountInfo('');
    reset();
  };
  return (
    <>
      <Modal isOpen={open} onClose={closeModal} dataTest='withdrawModal'>
        {step === 'topup' && (
          <StepBorrowTopup
            amount={context.amount}
            onSummary={continueToSummary}
            currency={context.currency}
            wallet={context.wallet}
            borrow={context.borrow}
            newCollateralAmount={effectData?.data.collateral_amount_new}
            currentCollateralAmount={context.borrow?.collateral_amount}
            currentLtvRatio={`${context.borrow?.ltv_pct}`}
            newLtvRatio={effectData?.data.ltv_pct_new}
            newState={effectData?.data.state_new}
            currentState={context.borrow?.state}
            onCancel={closeModal}
            onAmountChange={(amount) => {
              calculateEffect({
                amount,
              });
            }}
            isLoading={isLoadingEffect}
            errorAmount={context.amountError}
            amountInfo={context.amountInfo}
            onMax={() => {
              if (context.wallet?.balance) {
                context.setAmount(context.wallet?.balance);
                calculateEffect({
                  amount: context.wallet?.balance,
                });
              }
            }}
          />
        )}
        {step === 'summary' && (
          <StepSummary
            amount={context.amount}
            onGoBack={() => {
              setStep('topup');
            }}
            currency={context.currency}
            wallet={context.wallet}
            newCollateralAmount={effectData?.data.collateral_amount_new}
            newLtvRatio={effectData?.data.ltv_pct_new}
            newState={effectData?.data.state_new}
            onGoNext={(txId) => {
              setTransactionId(txId);
              setStep('success');
            }}
            id={id}
          />
        )}
        {step === 'success' && (
          <StepSuccess onClose={closeModal} onApprove={() => setStep('2FA')} />
        )}
        {step === '2FA' && (
          <Module2FA
            onVerify={onTopup}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={isLoadingTransaction}
            onCancel={() => setStep('2FAcancel')}
            approvalOfTransaction={true}
            title={formatMessage('borrow.approveCollateralTopUp')}
          />
        )}
        {step === '2FAcancel' && (
          <Cancel2FA onClose={closeModal} approvalOfTransaction={true} />
        )}
      </Modal>
    </>
  );
};

export const BorrowTopup: React.FC<Props> = ({ open, onClose, id, borrow }) => {
  return (
    <BorrowTopupContextProvider borrow={borrow}>
      <BorrowTopupWrapped open={open} onClose={onClose} id={id} />
    </BorrowTopupContextProvider>
  );
};
