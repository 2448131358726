import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { SettingsSection } from './SettingsSection';
import { Text } from 'common/components/Text';
import { useState } from 'react';
import { ChangeOwnerModal } from './change-owner-section/ChangeOwnerModal';
import { useMembersQuery } from 'modules/company-members/api';
import { Tooltip } from 'common/components/Tooltip';

export const ChangeOwnerSection: React.FC = () => {
  const formatMessage = useFormatMessage();
  const [open, setOpen] = useState(false);

  const { data: membersData } = useMembersQuery();

  const isDataNotAvailable = typeof membersData === 'undefined';

  const getTooltipText = () => {
    if (isDataNotAvailable) {
      return '';
    }

    if (membersData.data.length <= 1) {
      return formatMessage('settings.changeOwnerNotEnoughMembers');
    }
    return '';
  };

  return (
    <>
      <SettingsSection
        header={formatMessage('settings.changeCompanyOwner')}
        content={
          <div>
            <Text style='light1417'>
              {formatMessage('settings.transferOwnerToMember')}
            </Text>
          </div>
        }
        actions={
          <Tooltip title={getTooltipText()}>
            <span>
              <Button
                type='secondarySmall'
                name={formatMessage('common.button.change')}
                onClick={() => setOpen(true)}
                disabled={isDataNotAvailable || membersData?.data.length <= 1}
              />
            </span>
          </Tooltip>
        }
      />
      <ChangeOwnerModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};
