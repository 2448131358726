import { createContext, useContext, useState } from 'react';
import { Member } from '../api';

type DeleteMemberContextValue = {
  member: Member | undefined;
  setMember: (member: Member | undefined) => void;
};

const DeleteMemberContext = createContext<DeleteMemberContextValue>({
  member: undefined,
  setMember: () => {},
});

export const DeleteMemberContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [member, setMember] = useState<Member>();

  return (
    <DeleteMemberContext.Provider value={{ member, setMember }}>
      {children}
    </DeleteMemberContext.Provider>
  );
};

export const useDeleteMemberContext = () => {
  return useContext(DeleteMemberContext);
};
