import { Text } from 'common/components/Text';
import styles from './DetailsPageSectionHeader.module.scss';
import { ReactComponent as ArrowLeftIcon } from 'common/assets/black-icons/arrow-left-small.svg';

type Props = {
  actions?: React.ReactNode;
  header: string;
  onBack: () => void;
};

export const DetailsPageSectionHeader: React.FC<Props> = ({
  actions,
  header,
  onBack,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ArrowLeftIcon
          onClick={onBack}
          data-test='backArrow'
          className={styles.arrowBack}
        />
        <Text style='xxbold3644'>{header}</Text>
      </div>
      <div className={styles.actions}>{actions}</div>
    </div>
  );
};
