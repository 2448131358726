import { descCmpAmounts } from 'common/utils/comparators';
import {
  BaseCurrency,
  Currency,
  CurrencyWallet,
} from 'modules/select-currency-wallet/types';
import { InstrumentCurrency } from '../trade/new-trade/types';
import { TermEnum } from './types';

export const termToMonths = (term: TermEnum) =>
  parseInt(term.match(/^[0-9]+/)?.[0] || '');

export const getHighestBalanceWallet = (wallets?: CurrencyWallet[]) =>
  wallets?.sort((a, b) => descCmpAmounts(a.balance, b.balance))[0];

export const findCurrency = (currency: BaseCurrency, currencies?: Currency[]) =>
  currencies?.find(
    (item) =>
      currency.currency === item.currency && currency.network === item.network
  );

export const findInstrumentCurrency = (
  currency: BaseCurrency,
  currencies?: InstrumentCurrency[]
) =>
  currencies?.find(
    (item) =>
      currency.currency === item.currency && currency.network === item.network
  );
