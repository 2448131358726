import {
  MAX_INPUT_CHARACTERS_LIMIT_EXTRA_LONG,
  MAX_INPUT_CHARACTERS_LIMIT_LONG,
  MAX_INPUT_CHARACTERS_LIMIT_MESSAGE,
  MAX_INPUT_CHARACTERS_LIMIT_SHORT,
} from 'common/consts/consts';

const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

export const validateEmail = (email: string) => emailRegex.test(email);

export const validateLengthShort = (name: string) =>
  name.length <= MAX_INPUT_CHARACTERS_LIMIT_SHORT;

export const validateLengthLong = (name: string) =>
  name.length <= MAX_INPUT_CHARACTERS_LIMIT_LONG;

export const validateLengthExtraLong = (name: string) =>
  name.length <= MAX_INPUT_CHARACTERS_LIMIT_EXTRA_LONG;

export const validateLengthMessage = (name: string) =>
  name.length <= MAX_INPUT_CHARACTERS_LIMIT_MESSAGE;

export const validatePassword = (password: string) =>
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
