import { useState } from 'react';
import D from 'decimal.js';

import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Currency } from 'modules/select-currency-wallet/types';
import { StepWithdraw } from './components/StepWithdraw';
import { StepSuccess } from './components/StepSuccess';
import { StepSummary } from './components/StepSummary';
import {
  WithdrawFee,
  useGetWithdrawalFeeQuery,
  WithdrawError,
  useWithdrawEffectMutation,
  WithdrawEffectPayload,
  WITHDRAWAL_FEE_KEY,
  notEnoughFundsToWithdraw,
  whitelistedAddressNotExist,
  whitelistedAddressHasBeenRemoved,
  amountMustBeGreaterOrEqualTo,
} from './api';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toLimitedPrec, toDecimal, toFixed } from 'modules/input-amount';
import {
  TransactionActionPayload,
  useTransactionActionMutation,
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { openSnackbar } from 'common/components/snackbar';
import { WhitelistedAddress } from 'modules/address-whitelist/types';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';

import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
  STATUS_INVALID_OTP,
  ALREADY_USED_OTP,
} from 'modules/2FA';
import { prettifyError } from 'common/utils/prettify-error';
import { getHighestBalanceWallet } from 'modules/financial-ops/common';
import { CURRENCIES_QUERY_KEY } from 'modules/select-currency-wallet/api';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';
import { MIN_BTC_AMOUNT } from './consts';

type WithdrawSteps = 'withdraw' | 'summary' | '2FA' | 'success' | '2FAcancel';

type Props = { open: boolean; onClose: () => void };

export const Withdraw: React.FC<Props> = ({ open, onClose }) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const [step, setStep] = useState<WithdrawSteps>('withdraw');
  const [amount, setAmount] = useState('');
  const [whitelistedAddress, setWhitelistedAddress] =
    useState<WhitelistedAddress>();
  const [transactionId, setTransactionId] = useState<number>();
  const [errorAddress, setErrorAddress] = useState('');
  const [errorAmount, setErrorAmount] = useState('');
  const [errorWithdraw, setErrorWithdraw] =
    useState<AxiosError<WithdrawError> | null>(null);
  const [error2FA, setError2FA] = useState<string>();
  const [errorWallet, setErrorWallet] = useState('');

  const { data: withdrawFeeList, isLoading: isFeeLoading } =
    useGetWithdrawalFeeQuery();

  const { data: currenciesList, isLoading: isLoadingCurrencies } =
    useGetCurrenciesWithWallets();

  const { mutate: transactionActionMutation, isLoading: isLoadingTransaction } =
    useTransactionActionMutation();

  const withdrawEffectMutation = useWithdrawEffectMutation();

  const [currencyKey, setCurrencyKey] = useState<Currency>();
  const [walletKey, setWalletKey] = useState<number | undefined>();

  const currency = currenciesList?.find(
    (curr) =>
      curr.currency === currencyKey?.currency &&
      curr.network === currencyKey.network
  );

  const fee =
    withdrawFeeList?.data.find(
      (data: WithdrawFee) =>
        data.currency === currency?.currency &&
        data.network === currency.network
    )?.fee || '';

  const wallet = currency?.wallets.find((val) => val.id === walletKey);

  const continueToSummary = () => {
    if (!whitelistedAddress) {
      setErrorAddress(formatMessage('withdraw.addressCannotBeBlank'));
    } else {
      setErrorAddress('');
    }

    if (!wallet) {
      setErrorWallet('common.walletIsNotSelected');
    } else {
      setErrorWallet('');
    }

    if (currency?.currency === 'BTC' && toDecimal(amount).lt(MIN_BTC_AMOUNT)) {
      setErrorAmount(
        formatMessage('withdraw.amountMustBeGreaterOrEqualToX', {
          amount: MIN_BTC_AMOUNT,
          currency: currency.currency,
        })
      );
      return;
    }

    if (toDecimal(amount).eq(0)) {
      setErrorAmount(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }

    if (new D(wallet!.balance).minus(fee).lt(amount)) {
      setErrorAmount(formatMessage('withdraw.notEnoughFundsToWithdraw'));
      return;
    }

    getInitialToken();
  };

  const onModalClose = () => {
    setWalletKey(undefined);
    setCurrencyKey(undefined);
    setStep('withdraw');
    setAmount(INITIAL_AMOUNT);
    setWhitelistedAddress(undefined);
    setErrorAddress('');
    setErrorAmount('');
    onClose();
  };

  const onVerify = (keys: string) => {
    if (transactionId) {
      const payload: TransactionActionPayload = {
        transaction_id: transactionId,
        action: 'APPROVE',
      };

      transactionActionMutation(
        { payload, headers: generateHeader2FA(keys) },
        {
          onSuccess: () => {
            openSnackbar(
              formatMessage('transactions.approved.message'),
              'success'
            );
            onModalClose();
            queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
            queryClient.invalidateQueries(
              TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
            );
          },
          onError: (err) => {
            if (
              err.response?.data?.status === STATUS_INVALID_OTP ||
              err.response?.data?.status === ALREADY_USED_OTP
            ) {
              setErrorOTP(err, setError2FA, formatMessage);
            } else {
              const text = Object.values(err.response!.data)[0] as string;
              openSnackbar(text, 'error');
            }
          },
        }
      );
    }
  };

  const getInitialToken = () => {
    if (!whitelistedAddress || !currency || !wallet) {
      return;
    }

    const payload: WithdrawEffectPayload = {
      currency: currency?.currency,
      amount,
      whitelisted_address_id: whitelistedAddress.id,
      wallet_id: wallet?.id,
      summary: true,
    };

    withdrawEffectMutation.mutate(payload, {
      onSettled: () => {
        queryClient.invalidateQueries(WITHDRAWAL_FEE_KEY);
      },
      onSuccess: () => {
        setStep('summary');
      },
      onError: (error, payload) => {
        queryClient.invalidateQueries(CURRENCIES_QUERY_KEY);

        if (notEnoughFundsToWithdraw(error.response?.data)) {
          setErrorAmount(formatMessage('withdraw.notEnoughFundsToWithdraw'));
          return;
        }

        const mustBeGte = amountMustBeGreaterOrEqualTo(error.response?.data);
        if (mustBeGte) {
          setErrorAmount(
            formatMessage('withdraw.amountMustBeGreaterOrEqualToX', {
              amount: toFixed(mustBeGte[1]),
              currency: payload.currency,
            })
          );
          return;
        }

        if (whitelistedAddressNotExist(error.response?.data)) {
          setErrorAddress(
            formatMessage('withdraw.whitelistedAddressDoesNotExist')
          );
          return;
        }

        if (whitelistedAddressHasBeenRemoved(error.response?.data)) {
          setErrorAddress(
            formatMessage('withdraw.whitelistedAddressHasBeenRemoved')
          );
          return;
        }

        setErrorAmount(prettifyError(error));
      },
    });
  };

  return (
    <>
      <Modal isOpen={open} onClose={onModalClose} dataTest='withdrawModal'>
        {step === 'withdraw' && (
          <StepWithdraw
            whitelistedAddress={whitelistedAddress}
            onChangeWithdrawAddress={(address) => {
              setErrorAddress('');
              setWhitelistedAddress(address);
            }}
            currencies={currenciesList}
            onChangeAmount={(newAmount, newFee, balance) => {
              setAmount(newAmount);
              setErrorAmount('');

              if (
                currency?.currency === 'BTC' &&
                toDecimal(newAmount).lt(MIN_BTC_AMOUNT)
              ) {
                setErrorAmount(
                  formatMessage('withdraw.amountMustBeGreaterOrEqualToX', {
                    amount: MIN_BTC_AMOUNT,
                    currency: currency.currency,
                  })
                );
                return;
              }

              if (toDecimal(newAmount).lte(0)) {
                setErrorAmount(
                  formatMessage('withdraw.amountMustBeGreaterThanZero', {
                    currency: currency?.currency,
                  })
                );
                return;
              }

              const maxMinusFee = toDecimal(balance).minus(newFee);
              if (
                toDecimal(maxMinusFee).gt(0) &&
                toDecimal(newAmount).gt(maxMinusFee) &&
                !toDecimal(newAmount).lte(0)
              ) {
                setErrorAmount(
                  formatMessage('common.amountMustBeLessOrEqual', {
                    amount: maxMinusFee.gt(0) ? maxMinusFee.toNumber() : 0,
                    currency: currency?.currency,
                  })
                );
              }

              if (toDecimal(maxMinusFee).lte(0)) {
                setErrorAmount(
                  formatMessage('withdraw.notEnoughFundsToWithdraw')
                );
              }
            }}
            amount={amount}
            onMax={() => {
              if (!wallet) {
                return;
              }
              const maxMinusFee = new D(wallet.balance).minus(fee);
              if (maxMinusFee.gt(0)) {
                setAmount(toLimitedPrec(maxMinusFee.toFixed()));
                setErrorAmount('');
              } else {
                setAmount(INITIAL_AMOUNT);
                setErrorAmount(
                  formatMessage('withdraw.notEnoughFundsToWithdraw')
                );
              }
            }}
            errorAddress={errorAddress}
            errorAmount={errorAmount}
            onSummary={continueToSummary}
            onErrorAddress={(err) => setErrorAddress(err)}
            isLoadingCurrencies={isLoadingCurrencies}
            fee={fee}
            onCurrencyChange={(value) => {
              setAmount(INITIAL_AMOUNT);
              setErrorAmount('');
              setWhitelistedAddress(undefined);
              setCurrencyKey(value);

              const foundWallet = getHighestBalanceWallet(value?.wallets);
              if (foundWallet) {
                setWalletKey(foundWallet.id);
              }
            }}
            currency={currency}
            wallet={wallet}
            wallets={currency?.wallets}
            onWalletChange={(value) => {
              setErrorWithdraw(null);
              setErrorAmount('');
              setErrorAddress('');
              setWalletKey(value?.id);
            }}
            errorWallet={errorWallet}
            onCancel={onModalClose}
          />
        )}
        {step === 'summary' && (
          <StepSummary
            whitelistedAddress={whitelistedAddress}
            amount={toDecimal(amount)}
            onGoBack={() => {
              setErrorWithdraw(null);
              setStep('withdraw');
            }}
            initialFee={fee}
            isFeeLoading={isFeeLoading}
            error={errorWithdraw}
            currency={currency}
            wallet={wallet}
            initialToken={withdrawEffectMutation.data?.data.token}
            onGoNext={(transactionId) => {
              setTransactionId(transactionId);
              setStep('success');
            }}
          />
        )}
        {step === 'success' && (
          <StepSuccess
            onClose={onModalClose}
            onApprove={() => setStep('2FA')}
          />
        )}
        {step === '2FA' && (
          <Module2FA
            title={formatMessage('common.approveWithdraw')}
            onVerify={onVerify}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={isLoadingTransaction}
            onCancel={() => setStep('2FAcancel')}
            approvalOfTransaction={true}
          />
        )}
        {step === '2FAcancel' && (
          <Cancel2FA onClose={onModalClose} approvalOfTransaction={true} />
        )}
      </Modal>
    </>
  );
};
