import { Button } from 'common/components/button';
import { Modal } from 'common/components/modal';
import { openSnackbar } from 'common/components/snackbar';
import { generateLimitSendingEmailError } from 'common/utils/utils';
import {
  generateHeader2FA,
  setErrorOTP,
  Module2FA,
  Cancel2FA,
} from 'modules/2FA';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import {
  ERR_LIMIT_SENDING_INVITATION_AGAIN,
  Invitation,
  useInviteMutation,
} from '../api';
import { ERR_EMAIL_EXISTS } from 'modules/api';

type Props = {
  invitation: Invitation;
};

type Steps = '2FA' | '2FAcancel';

export const ResendInvitationButton: React.FC<Props> = ({ invitation }) => {
  const formatMessage = useFormatMessage();
  const inviteMutation = useInviteMutation(invitation.email);
  const [open, setOpen] = useState(false);
  const [error2FA, setError2FA] = useState<string>();
  const [step, setStep] = useState<Steps>('2FA');

  const resend = () => {
    inviteMutation.mutate(
      {
        payload: {
          name: invitation.name,
          surname: invitation.surname,
          email: invitation.email,
        },
      },
      {
        onSuccess: () => {
          setOpen(true);
        },
        onError: (err) => {
          if (
            err.response?.data.detail?.startsWith(
              ERR_LIMIT_SENDING_INVITATION_AGAIN
            )
          ) {
            openSnackbar(
              generateLimitSendingEmailError(
                err.response?.data.detail,
                formatMessage('companyMembers.errorLimitSendingEmails'),
                formatMessage
              ),
              'error'
            );
          }
          if (err.response?.data.email?.includes(ERR_EMAIL_EXISTS)) {
            openSnackbar(
              formatMessage('companyMembers.thisUserIsMemberOfAnotherCompany', {
                email: invitation.email,
              }),
              'error'
            );
          }
        },
      }
    );
  };

  const onVerify = (keys: string) => {
    if (inviteMutation.isLoading) {
      return;
    }
    inviteMutation.mutate(
      {
        payload: {
          name: invitation.name,
          surname: invitation.surname,
          email: invitation.email,
        },
        headers: generateHeader2FA(keys),
      },
      {
        onSuccess: () => {
          setOpen(false);
          openSnackbar(
            formatMessage('inviteMembers.invitationSent.message'),
            'success'
          );
          setStep('2FA');
        },
        onError: (err) => {
          setErrorOTP(err, setError2FA, formatMessage);
        },
      }
    );
  };

  return (
    <>
      <Button
        type='secondarySmall'
        name={formatMessage('companyMembers.resendInvitation')}
        onClick={() => resend()}
      />
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        {step === '2FA' && (
          <Module2FA
            onVerify={onVerify}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={inviteMutation.isLoading}
            onCancel={() => setStep('2FAcancel')}
            title={formatMessage('companyMembers.resendInvitation')}
          />
        )}
        {step === '2FAcancel' && (
          <Cancel2FA
            onClose={() => {
              setOpen(false);
              setStep('2FA');
            }}
          />
        )}
      </Modal>
    </>
  );
};
