import clsx from 'clsx';
import { CSSProperties } from 'react';
import styles from './Text.module.scss';

export type TextStyle =
  | 'light1012'
  | 'xxxbold1113'
  | 'bold1115'
  | 'light1215'
  | 'regular1215'
  | 'xbold1214'
  | 'xxbold1218'
  | 'xbold1219'
  | 'xbold1215'
  | 'light1417'
  | 'light1421'
  | 'light1425'
  | 'xxbold1220'
  | 'regular1416'
  | 'bold1417'
  | 'bold1422'
  | 'bold1425'
  | 'xbold1425'
  | 'xbold1417'
  | 'xxbold1417'
  | 'xxbold1420'
  | 'xxbold1425'
  | 'light1619'
  | 'light1622'
  | 'regular1622'
  | 'light1624'
  | 'bold1619'
  | 'xbold1619'
  | 'xxbold1619'
  | 'bold1622'
  | 'light1827'
  | 'light1822'
  | 'xbold1821'
  | 'light2028'
  | 'xbold2028'
  | 'regular2032'
  | 'xxbold2024'
  | 'xxbold2227'
  | 'xxbold2233'
  | 'xxbold2630'
  | 'xxbold2648'
  | 'regular3042'
  | 'bold3048'
  | 'xxbold3030'
  | 'light3248'
  | 'xxbold3248'
  | 'xxbold3644'
  | 'xxbold3048'
  | 'xxbold4056';

export const textStyles: { readonly [key in TextStyle]: string } = {
  light1012: styles.light1012,
  xxxbold1113: styles.xxxbold1113,
  bold1115: styles.bold1115,
  light1215: styles.light1215,
  regular1215: styles.regular1215,
  xbold1214: styles.xbold1214,
  xxbold1218: styles.xxbold1218,
  xbold1219: styles.xbold1219,
  xbold1215: styles.xbold1215,
  xxbold1220: styles.xxbold1220,
  regular1416: styles.regular1416,
  light1417: styles.light1417,
  light1421: styles.light1421,
  light1425: styles.light1425,
  light1619: styles.light1619,
  light1622: styles.light1622,
  regular1622: styles.regular1622,
  light1624: styles.light1624,
  bold1417: styles.bold1417,
  bold1422: styles.bold1422,
  bold1425: styles.bold1425,
  bold1622: styles.bold1622,
  bold1619: styles.bold1619,
  xbold1417: styles.xbold1417,
  xxbold1420: styles.xxbold1420,
  xbold1425: styles.xbold1425,
  xxbold1417: styles.xxbold1417,
  xxbold1425: styles.xxbold1425,
  light1827: styles.light1827,
  light1822: styles.light1822,
  xbold1821: styles.xbold1821,
  xbold1619: styles.xbold1619,
  xbold2028: styles.xbold2028,
  light2028: styles.light2028,
  regular2032: styles.regular2032,
  xxbold2024: styles.xxbold2024,
  xxbold1619: styles.xxbold1619,
  xxbold2630: styles.xxbold2630,
  xxbold2648: styles.xxbold2648,
  xxbold2227: styles.xxbold2227,
  xxbold2233: styles.xxbold2233,
  regular3042: styles.regular3042,
  bold3048: styles.bold3048,
  xxbold3030: styles.xxbold3030,
  light3248: styles.light3248,
  xxbold3248: styles.xxbold3248,
  xxbold3644: styles.xxbold3644,
  xxbold3048: styles.xxbold3048,
  xxbold4056: styles.xxbold4056,
};

export type TextColorStyle =
  | 'primary'
  | 'secondary'
  | 'theme'
  | 'light'
  | 'inactive'
  | 'error'
  | 'lightNavbar'
  | 'inputInfo'
  | 'errorDark'
  | 'themeDark';

export const textColorStyles: { readonly [key in TextColorStyle]: string } = {
  primary: styles.primaryColor,
  secondary: styles.secondaryColor,
  theme: styles.themeColor,
  light: styles.lightColor,
  inactive: styles.inactiveColor,
  error: styles.errorColor,
  lightNavbar: styles.lightNavbarColor,
  inputInfo: styles.inputInfoColor,
  errorDark: styles.errorDarkColor,
  themeDark: styles.themeDarkColor,
};

export type LetterSpacingStyle = 'small';

export const letterSpacingStyles: {
  readonly [key in LetterSpacingStyle]: string;
} = {
  small: styles.letterSpacingSmall,
};

type Props = {
  style: TextStyle;
  colorStyle?: TextColorStyle;
  letterSpacingStyle?: LetterSpacingStyle;
  className?: string;
  inlineStyle?: CSSProperties;
  children: React.ReactNode;
  onClick?: () => void;
  dataTest?: string;
};

export const Text: React.FC<Props> = ({
  style,
  colorStyle,
  letterSpacingStyle,
  className,
  inlineStyle,
  children,
  onClick,
  dataTest,
}) => {
  return (
    <span
      className={clsx(
        styles.text,
        textStyles[style],
        colorStyle && textColorStyles[colorStyle],
        letterSpacingStyle && letterSpacingStyles[letterSpacingStyle],
        className
      )}
      style={inlineStyle}
      onClick={onClick}
      data-test={dataTest}
    >
      {children}
    </span>
  );
};
