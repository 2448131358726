import styles from './MemberBox.module.scss';
import { Text } from 'common/components/Text';
import { ReactComponent as SelectedCircle } from '../assets/selected-circle.svg';
import clsx from 'clsx';
import { Tooltip } from 'common/components/Tooltip';
import { useFormatMessage } from 'modules/messages';

type Props = {
  onClick: () => void;
  name: string;
  surname: string;
  checked: boolean;
  isOwner: boolean;
};

export const MemberBox: React.FC<Props> = ({
  onClick,
  name,
  surname,
  checked,
  isOwner,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <Tooltip
      title={isOwner ? formatMessage('multiapproval.ownerApproval') : ''}
    >
      <div
        className={clsx(
          styles.container,
          !isOwner && checked && styles.containerSelected,
          !isOwner && styles.memberContainer,
          isOwner && styles.ownerContainer
        )}
        onClick={onClick}
      >
        <Text
          style={checked ? 'bold1622' : 'light1622'}
        >{`${name} ${surname}`}</Text>
        {checked && <SelectedCircle className={styles.circleIcon} />}
      </div>
    </Tooltip>
  );
};
