import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { Severity } from 'common/components/Alert';
import { Snackbar } from './Snackbar';
import styles from './SnackbarManager.module.scss';

const SNACKBAR_EVENT = 'Montesauri:openSnackbar';

export const openSnackbar = (message: string, severity: Severity) => {
  document.dispatchEvent(
    new CustomEvent<Message>(SNACKBAR_EVENT, { detail: { message, severity } })
  );
};

type Message = {
  message: string;
  severity: Severity;
};

type MessageWithId = Message & { id: string };

export const SnackbarManager: React.FC<{}> = () => {
  const [messages, setMessages] = useState<MessageWithId[]>([]);

  const addMessage = (message: Message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: v4(),
        ...message,
      },
    ]);
  };

  const removeMessage = (id: string) => {
    setMessages((prevMessages) =>
      prevMessages.filter((message) => message.id !== id)
    );
  };

  const handleOpenSnackbar = (event: any) => {
    addMessage((event as CustomEvent<Message>).detail);
  };

  useEffect(() => {
    document.addEventListener(SNACKBAR_EVENT, handleOpenSnackbar);
    return () => {
      document.removeEventListener(SNACKBAR_EVENT, handleOpenSnackbar);
    };
  }, []);

  if (messages.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      {messages.map((message) => (
        <Snackbar
          key={message.id}
          message={message.message}
          severity={message.severity}
          onClose={() => {
            removeMessage(message.id);
          }}
        />
      ))}
    </div>
  );
};
