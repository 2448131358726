import { SnackbarManager } from 'common/components/snackbar';
import { ApiProvider } from 'modules/api';
import { isDev } from 'modules/env-utils';
import { HelpCenterSSOContextProvider } from 'modules/help-center-sso';
import { KyxContextProvider } from 'modules/kyx';
import { LoginContextProvider, useHandleUnauthorized } from 'modules/login';
import { MessageProvider } from 'modules/messages';
import { SetMultiapprovalContextProvider } from 'modules/multiapproval';
import {
  FeatureButtonsContextProvider,
  HamburgerMenuContextProvider,
} from 'modules/navigation/context';
import { NotificationsMenuContextProvider } from 'modules/notifications/context';
import { RecaptchaProvider } from 'modules/recaptcha';
import { TransactionFiltersContextProvider } from 'modules/transactions/filters';
import { UserMenuContextProvider } from 'modules/user';
import { useWebsocketSubscription } from 'modules/websocket';
import * as pages from 'pages';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// initialize pdf-dist worker
// pdf.worker.min.js is from v3.6.172 of pdfjs-dist, if the library is updated, copy worker from node_modules/pdfjs-dist/build
import { GlobalWorkerOptions } from 'pdfjs-dist';
GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/pdf.worker.min.js';

export const App: React.FC<{}> = () => {
  return (
    <RecaptchaProvider>
      <ApiProvider>
        <HelmetProvider>
          <MessageProvider>
            <BrowserRouter>
              <LoginContextProvider>
                <SetMultiapprovalContextProvider>
                  <UserMenuContextProvider>
                    <HamburgerMenuContextProvider>
                      <FeatureButtonsContextProvider>
                        <NotificationsMenuContextProvider>
                          <KyxContextProvider>
                            <HelpCenterSSOContextProvider>
                              <TransactionFiltersContextProvider>
                                <WrappedApp />
                              </TransactionFiltersContextProvider>
                            </HelpCenterSSOContextProvider>
                          </KyxContextProvider>
                        </NotificationsMenuContextProvider>
                      </FeatureButtonsContextProvider>
                    </HamburgerMenuContextProvider>
                  </UserMenuContextProvider>
                </SetMultiapprovalContextProvider>
              </LoginContextProvider>
            </BrowserRouter>
          </MessageProvider>
        </HelmetProvider>
      </ApiProvider>
    </RecaptchaProvider>
  );
};

const WrappedApp: React.FC<{}> = () => {
  useHandleUnauthorized();
  useWebsocketSubscription();

  return (
    <>
      <Routes>
        <Route path='/' element={<Navigate replace to='/dashboard' />} />
        <Route path='/address-whitelist' element={<pages.AddressWhitelist />} />
        <Route
          path='/charity-form/:token'
          element={<pages.CharityFormPublicInappPage />}
        />
        <Route
          path='/charity-form-embedded/:token'
          element={<pages.CharityFormPublicEmbeddedPage />}
        />
        <Route
          path='/charity-form-preview/:json'
          element={<pages.CharityFormPublicPreviewPage />}
        />
        {isDev && (
          <Route
            path='/charity-form-iframe-testpage'
            element={<pages.CharityFormIframeTestpage />}
          />
        )}
        <Route path='/charity-forms' element={<pages.CharityForms />} />
        <Route
          path='/charity-forms/:id'
          element={<pages.CharityFormDetails />}
        />
        <Route path='/company-members' element={<pages.CompanyMembers />} />
        <Route path='/confirm-email' element={<pages.ConfirmEmail />} />
        <Route path='/borrows' element={<pages.Borrows />} />
        <Route path='/borrows/:id' element={<pages.BorrowDetails />} />
        <Route path='/borrows/new-borrow' element={<pages.NewBorrow />} />
        <Route path='/create-company' element={<pages.CreateCompany />} />
        <Route path='/dashboard' element={<pages.Dashboard />} />
        <Route
          path='/feature-unavailable'
          element={<pages.FeatureUnavailable />}
        />
        <Route path='/help-center-sso' element={<pages.HelpCenterSSO />} />
        <Route path='/lends' element={<pages.Lends />} />
        <Route path='/lends/:id' element={<pages.LendDetailsPage />} />
        <Route path='/lends/new-lend' element={<pages.NewLend />} />
        <Route path='/login' element={<pages.Login />} />
        <Route path='/notifications' element={<pages.Notifications />} />
        <Route path='/reset-password' element={<pages.ResetPassword />} />
        <Route path='/set-new-password' element={<pages.SetNewPassword />} />
        <Route path='/settings' element={<pages.Settings />} />
        <Route path='/signup-invitation' element={<pages.SignupInvitation />} />
        {isDev && (
          <Route path='/testpage' element={<pages.ComponentTestpage />} />
        )}
        <Route path='/trades' element={<pages.Trade />} />
        <Route path='/trades/history' element={<pages.TradeHistory />} />
        <Route path='/transactions' element={<pages.Transactions />} />
        <Route
          path='/transactions/:id'
          element={<pages.TransactionDetails />}
        />
        <Route path='/wallets' element={<pages.AssetsAndWallets />} />
        <Route path='/savings-accounts' element={<pages.SavingsAccounts />} />
        <Route
          path='/savings-accounts/:id'
          element={<pages.SavingsAccountDetails />}
        />
        <Route
          path='/savings-accounts/new-saving'
          element={<pages.NewSaving />}
        />
        <Route path='/*' element={<pages.NotFound />} />
      </Routes>
      <SnackbarManager />
    </>
  );
};
