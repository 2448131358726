import styles from './DetailsInformations.module.scss';
import { LoaderBox } from 'common/components/loader';
import { useTransactionDetailsQuery } from 'modules/transactions/api';
import { DatesWalletsBox } from './details/DatesWalletsBox';
import { AmountsPercentageBox } from './details/AmountsPercentageBox';
import { ApprovalsBox } from './details/ApprovalsBox';
import { CellTransaction } from './CellTransaction';
import { CellAmount } from './CellAmount';
import { CellStatus } from './CellStatus';
import { NothingToDisplay } from 'modules/nothing-to-display';

type Props = { id?: string };

export const DetailsInformations: React.FC<Props> = ({ id }) => {
  const { data, isLoading } = useTransactionDetailsQuery(id);

  if (isLoading) {
    return <LoaderBox height='50px' />;
  }

  if (!data) {
    return <NothingToDisplay />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.name}>
        <CellTransaction
          transaction={data}
          dataTest='transaction'
          showTypeIcon={false}
        />
      </div>
      <div className={styles.amount}>
        <CellAmount transaction={data} dataTest='totalAmount' />
      </div>
      <div className={styles.status}>
        <CellStatus dataTest='status' status={data.state} />
      </div>
      <div className={styles.dates}>
        <DatesWalletsBox transaction={data} />
      </div>
      <div className={styles.amounts}>
        <AmountsPercentageBox transaction={data} />
      </div>
      <div className={styles.approvals}>
        <ApprovalsBox transaction={data} isDetailsPage={true} />
      </div>
    </div>
  );
};
