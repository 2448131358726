import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Text } from 'common/components/Text';
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg';
import { DATE_FORMAT, formatDate } from 'common/utils/utils';
import styles from './EndDatePicker.module.scss';
import { DialogActions } from '@mui/material';
import { ReactComponent as CloseIcon } from 'common/assets/black-icons/close.svg';
import clsx from 'clsx';

type Props = {
  onChange: (value?: string | null) => void;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  value: Date | null;
  disabled: boolean;
  error: string;
};

export const DATE_PLACEHOLDER = 'DD.MM.YYYY';

export const EndDatePicker: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onChange,
  value,
  disabled,
  error,
}) => {
  const today = new Date();
  const tomorrow = new Date(today.valueOf() + 24 * 60 * 60 * 1000);

  return (
    <div className={styles.container}>
      {disabled && <div className={styles.disabledBox} />}
      <div
        className={clsx(styles.box, error && styles.errorInput)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text
          style='light1622'
          inlineStyle={{ display: 'block', marginBottom: '5px' }}
          colorStyle={value ? 'primary' : 'secondary'}
        >
          {value ? formatDate(value.toISOString()) : DATE_PLACEHOLDER}
        </Text>
        <CalendarIcon className={styles.icon} />
      </div>
      {error && (
        <Text style='regular1215' colorStyle={'error'} dataTest='errorText'>
          {error}
        </Text>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiDatePicker
          open={isOpen}
          value={value}
          format={DATE_FORMAT}
          disabled={disabled}
          minDate={tomorrow}
          slots={{
            actionBar: () => ActionBar({ onClose: () => setIsOpen(false) }),
          }}
          closeOnSelect={true}
          onAccept={(newValue) => {
            if (newValue) {
              const date = new Date(newValue);
              onChange(date?.toISOString());
            }
            setIsOpen(false);
          }}
          slotProps={{
            layout: {
              sx: {
                [`.MuiDateCalendar-viewTransitionContainer`]: {
                  maxHeight: '280px',
                },
              },
            },
          }}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              padding: '0',
              height: ' 0',
              border: 'none',
            },
            '.MuiSvgIcon-root': {
              display: 'none',
            },
            '.MuiOutlinedInput-input': {
              display: 'none',
            },
            '.MuiPickersPopper-root': {
              float: 'right',
            },
          }}
          className={styles.picker}
        />
      </LocalizationProvider>
    </div>
  );
};

const ActionBar: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <DialogActions className={styles.actions}>
      <CloseIcon onClick={onClose} className={styles.close} />
    </DialogActions>
  );
};
