import { ReactComponent as MontesauriBackgroundLogomark } from 'common/assets/logo/montesauri-background-logomark.svg';
import { UnauthFooter, UnauthTopbar } from 'modules/navigation';
import { MontesauriLogomark } from './MontesauriLogomark';
import { MontesauriLogotype } from './MontesauriLogotype';
import styles from './UnauthLayout.module.scss';

type Props = {
  children?: React.ReactNode;
};

export const UnauthLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <MontesauriLogotype className={styles.logotype} isDark={true} />
        <MontesauriLogomark className={styles.logomark} />
        <UnauthTopbar />
      </div>
      <div className={styles.mainContainer}>
        <MontesauriBackgroundLogomark className={styles.backgroundIcon} />
        {children}
      </div>
      <div className={styles.footerContainer}>
        <UnauthFooter />
      </div>
    </div>
  );
};
