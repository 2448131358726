import {
  AverageApr,
  OverviewField,
} from 'modules/financial-ops/common/components/overview-components';
import { useFormatMessage } from 'modules/messages';
import { InterestEarnedSoFar } from './InterestEarnedSoFar';
import { useSavingsSummaryQuery } from '../../api';

export const InterestsBoxes: React.FC = () => {
  const { data, isLoading } = useSavingsSummaryQuery();
  const formatMessage = useFormatMessage();

  return (
    <>
      <OverviewField>
        <InterestEarnedSoFar savingSummary={data?.data} isLoading={isLoading} />
      </OverviewField>
      <OverviewField>
        <AverageApr
          rate={data?.data.current_avg_rate_pct}
          isLoading={isLoading}
          text={formatMessage('saving.averageSavingApr')}
        />
      </OverviewField>
    </>
  );
};
