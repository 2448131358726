import clsx from 'clsx';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './Threshold.module.scss';

type Props = {
  current: number;
  max: number;
  className?: string;
  dataTest?: string;
};

export const Threshold: React.FC<Props> = ({
  current,
  max,
  className,
  dataTest,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={clsx(styles.container, className)}>
      <Text style='xxbold3048' dataTest={dataTest}>
        {formatMessage('common.threshold.slash', { current, max })}
      </Text>
      <div className={styles.descriptionContainer}>
        <Text style='light1425'>
          {current === 1
            ? formatMessage('multiapproval.ownerApproval')
            : formatMessage(
                'multiapproval.approvalsAreRequiredToExecuteTransaction'
              )}
        </Text>
      </div>
    </div>
  );
};
