import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from './api';
import { useUserMenuContext } from './context';
import styles from './LogoutButton.module.scss';
import { useLoginContext } from 'modules/login/context';

export const LogoutButton: React.FC<{}> = () => {
  const navigate = useNavigate();
  const formatMessage = useFormatMessage();
  const { setIsLoggedIn } = useLoginContext();
  const queryClient = useQueryClient();

  const logoutMutation = useLogoutMutation();

  const { closeUserMenu } = useUserMenuContext();

  return (
    <Button
      className={styles.logoutButton}
      type='secondary'
      name={formatMessage('common.button.logout')}
      isLoading={logoutMutation.isLoading}
      onClick={() => {
        if (logoutMutation.isLoading) {
          return;
        }
        logoutMutation.mutate(undefined, {
          onSuccess: () => {
            closeUserMenu();
            setIsLoggedIn(false);
            queryClient.removeQueries(); // remove all queries
            navigate('/login');
          },
        });
      }}
    />
  );
};
