import styles from './RotatingLoader.module.scss';
import rotatingLoaderBw from './rotating-loader-bw.png';
import rotatingLoaderTheme from './rotating-loader-theme.png';

type Props = {
  size?: number;
  type?: 'primary' | 'secondary';
  containerSize?: number;
  dataTest?: string;
};

export const RotatingLoader: React.FC<Props> = ({
  size = 30,
  type = 'primary',
  containerSize,
  dataTest,
}) => (
  <div
    className={styles.container}
    style={
      containerSize
        ? {
            height: `${containerSize}px`,
          }
        : {}
    }
    data-test={dataTest}
  >
    <div className={styles.iconContainer}>
      <img
        src={type === 'primary' ? rotatingLoaderTheme : rotatingLoaderBw}
        alt='Rotating loader'
        style={{ width: `${size}px`, height: `${size}px` }}
      />
    </div>
  </div>
);
