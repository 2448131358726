import styles from './SectionHeader.module.scss';
import { Text, TextStyle } from 'common/components/Text';
import clsx from 'clsx';

type Props = {
  header: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
  textStyle?: TextStyle;
  subheader?: string;
  actionsClassName?: string;
};

export const SectionHeader: React.FC<Props> = ({
  header,
  actions,
  className,
  textStyle = 'xxbold3644',
  subheader,
  actionsClassName,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.headerContainer}>
        {typeof header === 'string' ? (
          <Text style={textStyle}>{header}</Text>
        ) : (
          header
        )}
        {subheader && (
          <Text style='light1622' className={styles.subheader}>
            {subheader}
          </Text>
        )}
      </div>
      {actions && (
        <div className={clsx(styles.actionsContainer, actionsClassName)}>
          {actions}
        </div>
      )}
    </div>
  );
};
