import { Text } from 'common/components/Text';
import { Tooltip } from 'common/components/Tooltip';
import { formatDate } from 'common/utils/utils';
import { differenceInDays, differenceInSeconds, parseISO } from 'date-fns';
import { useFormatMessage } from 'modules/messages';
import { ReactComponent as StatusNearOverdueIcon } from '../assets/status-near-overdue.svg';
import { ReactComponent as StatusOverdueIcon } from '../assets/status-overdue.svg';
import { BorrowReadStateEnum } from '../../types';
import styles from './Maturity.module.scss';

type Props = {
  end_at: string;
  state: BorrowReadStateEnum;
  can_be_repaid: boolean;
  has_pending_repay_transaction: boolean;
};

export const Maturity: React.FC<Props> = ({
  end_at,
  state,
  can_be_repaid,
  has_pending_repay_transaction,
}) => {
  const formatMessage = useFormatMessage();

  const parsed = parseISO(end_at);
  const now = new Date();
  const diffInSecs = differenceInSeconds(parsed, now);
  const diffInDays = differenceInDays(parsed, now);

  const isFinished =
    state === 'COMPLETED' ||
    state === 'LIQUIDATED' ||
    state === 'LIQUIDATING' ||
    state === 'CANCELLED';

  if (diffInSecs < 0 && !isFinished && can_be_repaid) {
    return (
      <div className={styles.container}>
        <Text
          style='xxbold1619'
          letterSpacingStyle='small'
          className={styles.textOverdue}
          dataTest='maturityDate'
        >
          {formatMessage('common.days', { days: 0 })}
        </Text>
        {!has_pending_repay_transaction && (
          <Tooltip title={formatMessage('borrow.borrowOverdueRepayNow')}>
            <StatusOverdueIcon />
          </Tooltip>
        )}
      </div>
    );
  }

  if (diffInDays === 0 && !isFinished && can_be_repaid) {
    return (
      <div className={styles.container}>
        <Text
          style='xxbold1619'
          letterSpacingStyle='small'
          className={styles.textOverdue}
          dataTest='maturityDate'
        >
          {formatMessage('common.days', { days: 0 })}
        </Text>
        {!has_pending_repay_transaction && (
          <Tooltip title={formatMessage('borrow.borrowReadyRepayNow')}>
            <StatusOverdueIcon />
          </Tooltip>
        )}
      </div>
    );
  }

  if (diffInDays <= 7 && !isFinished && can_be_repaid) {
    return (
      <div className={styles.container}>
        <Text
          style='xxbold1619'
          letterSpacingStyle='small'
          className={styles.textNear}
          dataTest='maturityDate'
        >
          {formatMessage('common.days', { days: diffInDays })}
        </Text>
        {!has_pending_repay_transaction && (
          <Tooltip
            title={formatMessage('borrow.borrowReadyRepayInDays', {
              days: diffInDays,
            })}
          >
            <StatusNearOverdueIcon />
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Text
        style='xxbold1619'
        letterSpacingStyle='small'
        dataTest='maturityDate'
      >
        {formatDate(end_at)}
      </Text>
    </div>
  );
};
