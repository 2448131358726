import styles from './StepSuccess.module.scss';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useIsSigner } from 'modules/user';
import { ApproveTransactionText } from 'modules/2FA';

type Props = {
  onClose: () => void;
  onApprove: () => void;
  threshold: number;
  usersAmount: number;
};

export const StepSuccess: React.FC<Props> = ({
  onClose,
  onApprove,
  threshold,
  usersAmount,
}) => {
  const formatMessage = useFormatMessage();
  const { data: isSigner } = useIsSigner();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('borrow.repaymentInitiated')}
      </ModalHeader>
      <ModalBody>
        <Text style='light1425'>
          {formatMessage('borrow.borrowWillBeRepaid')}
        </Text>
        <div className={styles.marginTop}>
          <div>
            <Text style='light1425'>
              {formatMessage('common.currentMultiapprovalThreshold')}
            </Text>
          </div>
          <div className={styles.threshold}>
            <Text style='xxbold1425'>
              {formatMessage('common.threshold.slash', {
                current: threshold,
                max: usersAmount,
              })}{' '}
            </Text>
            <Text style='light1425'>
              {formatMessage('common.approvalsRequired')}
            </Text>
          </div>
        </div>
        {isSigner && (
          <div className={styles.marginTop}>
            <ApproveTransactionText />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          {isSigner ? (
            <>
              <Button
                type='secondary'
                onClick={onClose}
                name={formatMessage('common.button.later')}
              />
              <Button
                type='dark'
                onClick={onApprove}
                name={formatMessage('common.button.approve')}
              />
            </>
          ) : (
            <Button
              type='dark'
              onClick={onClose}
              name={formatMessage('common.button.done')}
            />
          )}
        </ModalActions>
      </ModalFooter>
    </>
  );
};
