import { Alert } from 'common/components/Alert';
import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { SignupTermsConditionsViewer } from 'modules/documents';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';

type Props = {
  error?: string;
  isLoading?: boolean;
  onAccept: () => void;
  onDecline: () => void;
};

export const StepTermsAndConditions: React.FC<Props> = ({
  error,
  isLoading,
  onAccept,
  onDecline,
}) => {
  const formatMessage = useFormatMessage();

  const [finishedReading, setFinishedReading] = useState(false);

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('signup.termsConditionsAndPrivacyPolicy')}
      </ModalHeader>
      <ModalBody>
        {error && (
          <Alert
            text={error}
            severity={'error'}
            style={{ marginBottom: '40px' }}
          />
        )}
        <SignupTermsConditionsViewer
          onFinishedReading={() => {
            setFinishedReading(true);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('common.button.decline')}
            type='secondary'
            onClick={onDecline}
            disabled={!finishedReading}
          />
          <Button
            name={formatMessage('common.button.accept')}
            type='dark'
            onClick={onAccept}
            disabled={!finishedReading}
            isLoading={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
