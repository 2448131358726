import { DetailsButton } from 'modules/financial-ops/common/components/DetailsButton';
import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { NetworkBox } from 'common/components/NetworkBox';
import { Text } from 'common/components/Text';
import { StatePill } from 'modules/financial-ops/common/components/StatePill';
import { useFormatMessage } from 'modules/messages';
import { Saving } from '../../types';
import styles from './SavingBox.module.scss';
import {
  DailyEarn,
  FinishedDate,
  FundsLocked,
  InterestEarned,
  InterestEarnedSoFar,
  InterestRate,
} from '../../saving-details';
import { ID, OpenedDate } from 'modules/common-data-fields';

type Props = { saving: Saving };

export const SavingBox: React.FC<Props> = ({ saving }) => {
  const formatMessage = useFormatMessage();
  const {
    id,
    currency,
    rate_pct,
    state,
    network,
    balance,
    interest_total,
    currency_rate,
    expected_interest_daily,
    activated_at,
    completed_at,
  } = saving;

  const isFinished = state === 'COMPLETED';

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.name}>
          <CurrencyIcon currencyName={currency} />
          <Text style='bold1622'>
            {formatMessage('saving.savingCurrency', { currency })}
          </Text>
        </div>
        <DetailsButton path={`/savings-accounts/${id}`} />
      </div>
      <StatePill state={state} />
      <div className={styles.details}>
        {isFinished ? (
          <InterestEarned interest_total={interest_total} currency={currency} />
        ) : (
          <DailyEarn
            expected_interest_daily={expected_interest_daily}
            currency={currency}
            currency_rate={currency_rate}
            isFinished={isFinished}
          />
        )}
        <FundsLocked
          balance={balance}
          currency={currency}
          currency_rate={currency_rate}
          isFinished={isFinished}
        />
        {!isFinished && (
          <InterestEarnedSoFar
            interest_total={interest_total}
            currency={currency}
            currency_rate={currency_rate}
          />
        )}
        <InterestRate rate_pct={rate_pct} />
      </div>
      <div className={styles.bottomText}>
        <ID id={id} />
        <NetworkBox network={network} />
        <OpenedDate startAt={activated_at} />
        {isFinished && completed_at && (
          <FinishedDate completed_at={completed_at} />
        )}
      </div>
    </div>
  );
};
