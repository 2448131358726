import { LoaderBox } from 'common/components/loader';
import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { useFormatMessage } from 'modules/messages';
import { APRProducts } from '../common/components/APRProducts';
import { Lends } from './lend-list';
import { LendOverview } from './lend-overview/LendOverview';
import { NewLendButton } from './new-lend/NewLendButton';

export const LendDashboard: React.FC = () => {
  const formatMessage = useFormatMessage();

  const lendFeatureFlag = useRequireFeatureFlag('LEND');

  if (lendFeatureFlag.isLoading) {
    return <LoaderBox />;
  }
  if (!lendFeatureFlag.data?.enabled) {
    return null;
  }

  return (
    <Section>
      <SectionHeader
        header={formatMessage('lend.lendOverview')}
        actions={<NewLendButton />}
        subheader={formatMessage('lend.keepYourFundsInFixedLendsAndEarn')}
      />
      <LendOverview />
      <APRProducts kind='LEND' />
      <Lends />
    </Section>
  );
};
