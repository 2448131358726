import styles from './MembersSummary.module.scss';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { generateTextFromMembers } from '../utils';
import { Member } from 'modules/company-members/api';

type Props = { selectedMembers: Member[] };

export const MembersSummary: React.FC<Props> = ({ selectedMembers }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <div>
        <Text style='bold1417'>
          {formatMessage('common.multiapprovalChosenMembers', {
            chosenMembers: selectedMembers.length,
          })}
        </Text>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Text style='light1417'>
          {generateTextFromMembers(selectedMembers)}
        </Text>
      </div>
    </div>
  );
};
