import { AxiosError } from 'axios';
import {
  PostLendTerminateEffectResponseError,
  PostLendTerminateResponseError,
} from '../../api';
import { prettifyError } from 'common/utils/prettify-error';
import { useFormatMessage } from 'modules/messages';

export const useGetErrorText = () => {
  const formatMessage = useFormatMessage();

  const getErrorText = (
    error:
      | AxiosError<PostLendTerminateEffectResponseError>
      | AxiosError<PostLendTerminateResponseError>
  ) => {
    if (
      error.response?.data.non_field_errors?.includes(ERR_TERMINATION_PENDING)
    ) {
      return formatMessage('lend.lendHasTerminationTransactionInPendingState');
    }
    if (error.response?.data.non_field_errors?.includes(ERR_LEND_NOT_ACTIVE)) {
      return formatMessage('lend.lendIsNotActive');
    }
    return prettifyError(error);
  };

  return getErrorText;
};

// check if terminate error should be displayed as Alert (other errors are handled separately)
export const isAlertError = (
  error: AxiosError<PostLendTerminateResponseError>
) => {
  if (
    error.response?.data.non_field_errors?.includes(ERR_TERMINATION_PENDING) ||
    error.response?.data.non_field_errors?.includes(ERR_LEND_NOT_ACTIVE)
  ) {
    return true;
  }
  return false;
};

const ERR_TERMINATION_PENDING =
  'Lend has termination transaction in pending state.';

const ERR_LEND_NOT_ACTIVE = 'Lend is not active.';
