import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { FiatAmount } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import { BorrowsSummaryResponseData } from '../api';

type Props = {
  borrowsSummary?: BorrowsSummaryResponseData;
  isLoading?: boolean;
};

export const CurrentBorrowedAmount: React.FC<Props> = ({
  borrowsSummary,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <LabelWithInfoTooltip
        text={formatMessage('borrow.currentBorrowedAmount')}
        textStyle={'light1215'}
        tooltipText={formatMessage('borrow.approximateValue')}
      />
      <FiatAmount
        amount={borrowsSummary?.current_principal_amount_fiat}
        isDataLoading={isLoading}
        loaderContainerSize={48}
        loaderSize={30}
        textColorStyle='primary'
        textStyle='xxbold3048'
      />
    </div>
  );
};
