import { Button, BackButton } from 'common/components/button';
import { InputPassword } from 'common/components/input/InputPassword';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { validatePassword } from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';

type Props = {
  password: string;
  repeatedPassword: string;
  passwordError: string;
  repeatedPasswordError: string;
  onSubmit: () => void;
  onPasswordChange: (value: string) => void;
  onRepeatedPasswordChange: (value: string) => void;
  onPasswordError: (error: string) => void;
  onRepeatedPasswordError: (error: string) => void;
  onGoBack: () => void;
};

export const StepPassword: React.FC<Props> = ({
  password,
  repeatedPassword,
  passwordError,
  repeatedPasswordError,
  onSubmit,
  onPasswordChange,
  onRepeatedPasswordChange,
  onPasswordError,
  onRepeatedPasswordError,
  onGoBack,
}) => {
  const formatMessage = useFormatMessage();

  const validateAndSubmit = () => {
    if (password === '') {
      onPasswordError(formatMessage('common.fieldRequired'));
      return;
    }
    if (!validatePassword(password)) {
      onPasswordError(formatMessage('common.wrongPasswordFormat'));
      return;
    }
    onPasswordError('');

    if (password !== repeatedPassword) {
      onRepeatedPasswordError(formatMessage('common.passwordsNotIdentical'));
      return;
    }
    onRepeatedPasswordError('');

    onSubmit();
  };

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('signup.setPassword')}
      </ModalHeader>
      <ModalBody>
        <>
          <InputPassword
            value={password}
            onChange={(e) => {
              onPasswordChange(e.target.value);
            }}
            header={formatMessage('common.password')}
            autofocus
            onEnter={() => {
              document
                .getElementById('SignupFromInvitation-input-repeatpassword')
                ?.focus();
            }}
            errorText={passwordError}
          />
          <InputPassword
            value={repeatedPassword}
            onChange={(e) => {
              onRepeatedPasswordChange(e.target.value);
            }}
            header={formatMessage('common.repeatPassword')}
            id='SignupFromInvitation-input-repeatpassword'
            onEnter={() => {
              document
                .getElementById('SignupFromInvitation-buttonCreate')
                ?.click();
            }}
            errorText={repeatedPasswordError}
          />
        </>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            name={formatMessage('signup.createAccount')}
            type='dark'
            id='SignupFromInvitation-buttonCreate'
            onClick={validateAndSubmit}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
