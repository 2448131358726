import styles from './MainFilterButton.module.scss';
import { ReactComponent as ArrowDown } from 'common/assets/black-icons/arrowhead-down-12px.svg';
import { Text } from 'common/components/Text';

type Props = { onClick: () => void; text: string };

const NUMBERS_FILTERS_BUTTON_DESKTOP = 20;
const NUMBERS_FILTERS_BUTTON_SMALL_DESKTOP = 12;
const NUMBERS_FILTERS_BUTTON_MOBILE = 25;

export const MainFilterButton: React.FC<Props> = ({ onClick, text }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <Text style='xxbold1417' className={styles.desktopText}>
        {text.length > NUMBERS_FILTERS_BUTTON_DESKTOP
          ? text.substring(0, NUMBERS_FILTERS_BUTTON_DESKTOP) + '...'
          : text}
      </Text>
      <Text style='xxbold1417' className={styles.desktopLgText}>
        {text.length > NUMBERS_FILTERS_BUTTON_SMALL_DESKTOP
          ? text.substring(0, NUMBERS_FILTERS_BUTTON_SMALL_DESKTOP) + '...'
          : text}
      </Text>
      <Text style='xxbold1417' className={styles.mobileText}>
        {text.length > NUMBERS_FILTERS_BUTTON_MOBILE
          ? text.substring(0, NUMBERS_FILTERS_BUTTON_MOBILE) + '...'
          : text}
      </Text>
      <ArrowDown className={styles.arrowDown} />
    </div>
  );
};
