import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { Button, CancelButton } from 'common/components/button';
import { LabeledNetworkBadge } from 'common/components/LabeledNetworkBadge';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { Text } from 'common/components/Text';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { prettifyError } from 'common/utils/prettify-error';
import { useFormatMessage } from 'modules/messages';
import { AddressItem } from '../AddressRow';
import { WhitelistedAddressResponseError } from '../api';

export const RemoveStep: React.FC<{
  onClick: () => void;
  item: AddressItem;
  error?: AxiosError<WhitelistedAddressResponseError> | null;
  isLoading: boolean;
  onCancel: () => void;
}> = ({ onClick, item, error, isLoading, onCancel }) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader
        subtitle={
          <Text style='bold1622'>
            {formatMessage(
              'addressWhitelist.doYouWantToRemoveWhitelistedAddress'
            )}
          </Text>
        }
      >
        {formatMessage('addressWhitelist.removeWhitelistedAddress')}
      </ModalHeader>
      <ModalBody>
        {error && !error?.response?.data?.status && (
          <Alert severity='error' text={prettifyError(error)} />
        )}
        <div style={{ marginBottom: '20px' }}>
          <LabeledNetworkBadge text={item.name} network={item.network} />
        </div>
        <Summary>
          <SummaryLabel>{formatMessage('common.addressName')}</SummaryLabel>
          <SummaryValue>{item.name}</SummaryValue>
          <SummaryLabel>{formatMessage('common.network')}</SummaryLabel>
          <SummaryValue>{displayNetwork(item.network)}</SummaryValue>
          <SummaryLabel>{formatMessage('common.address')}</SummaryLabel>
          <SummaryValue>{item.address}</SummaryValue>
        </Summary>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <Button
            type='dark'
            name={formatMessage('common.button.remove')}
            onClick={onClick}
            isLoading={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
