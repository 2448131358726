import { NetworkEnum } from 'common/types';

export const networkMap = {
  BITCOIN: 'Bitcoin',
  ETHEREUM: 'Ethereum',
};

export const networkKeys = Object.keys(networkMap) as NetworkEnum[];

export const displayNetwork = (network: NetworkEnum) => networkMap[network];
