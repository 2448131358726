import { useFormatMessage } from 'modules/messages';
import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';

type Props = {
  onClose: () => void;
};

export const StepSuccess: React.FC<Props> = ({ onClose }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <ModalHeader
        showClose={false}
        subtitle={formatMessage(
          'charityForms.charityFormIsFinishedDonationsAreNoLongerAvailable'
        )}
      >
        {formatMessage('charityForms.charityFormFinished')}
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button
            type='dark'
            onClick={onClose}
            name={formatMessage('common.button.done')}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
