import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormatMessage } from 'modules/messages';
import { Alert } from 'common/components/Alert';
import { Button } from 'common/components/button';
import { InputPassword } from 'common/components/input/InputPassword';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { prettifyError } from 'common/utils/prettify-error';
import { validatePassword } from 'common/utils/validators';
import { ERR_INVALID_VALUE, ERR_NEW_PASSWORDS_SAME } from 'modules/api';
import {
  SetNewPasswordPayload,
  SetNewPasswordResponseError,
  useSetNewPasswordMutation,
} from './api';

export const SetNewPassword = () => {
  const formatMessage = useFormatMessage();

  const [password, setPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorSecondPassword, setErrorSecondPassword] = useState('');

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const uid = searchParams.get('uid');
  const token = searchParams.get('token');

  const setNewPasswordMutation = useSetNewPasswordMutation();

  const setNewPassword = () => {
    if (uid && token) {
      const payload: SetNewPasswordPayload = {
        new_password: password,
        uid,
        token,
      };
      setNewPasswordMutation.mutate(payload, {
        onSuccess: () => {
          navigate('/login', { state: { changedPassword: true } });
        },
        onError: (error: AxiosError<SetNewPasswordResponseError>) => {
          if (error.response?.data.token?.includes(ERR_INVALID_VALUE)) {
            return navigate('/reset-password', {
              state: { expired: true },
            });
          }
          if (
            error.response?.data.new_password?.includes(ERR_NEW_PASSWORDS_SAME)
          ) {
            setErrorPassword(
              formatMessage('resetPassword.newPasswordCannotBeTheSameAsOld')
            );
          }
        },
      });
    }
  };

  const validateAndSubmit = () => {
    setNewPasswordMutation.reset();

    if (password === '') {
      setErrorPassword(formatMessage('common.fieldRequired'));
      return;
    }
    if (!validatePassword(password)) {
      setErrorPassword(formatMessage('common.wrongPasswordFormat'));
      return;
    }
    setErrorPassword('');

    if (password !== secondPassword) {
      setErrorSecondPassword(
        formatMessage('common.passwordsNotIdentical')
      );
      return;
    }
    setErrorSecondPassword('');

    setNewPassword();
  };

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('resetPassword.stepSetUpNewPassword.header')}
      </ModalHeader>
      <ModalBody>
        <>
          {setNewPasswordMutation.isError && !errorPassword && (
            <Alert
              text={prettifyError(setNewPasswordMutation.error)}
              severity={'error'}
            />
          )}
          <InputPassword
            value={password}
            onChange={(e: any) => {
              setPassword(e.target.value);
            }}
            header={formatMessage('common.password')}
            errorText={errorPassword}
            onEnter={() => {
              document
                .getElementById('SetNewPassword-input-secondpassword')
                ?.focus();
            }}
          />
          <InputPassword
            value={secondPassword}
            onChange={(e: any) => {
              setSecondPassword(e.target.value);
              setErrorSecondPassword('');
            }}
            header={formatMessage('common.repeatPassword')}
            errorText={errorSecondPassword}
            id={'SetNewPassword-input-secondpassword'}
            onEnter={validateAndSubmit}
          />
        </>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('common.button.submit')}
            type='dark'
            onClick={validateAndSubmit}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
