import { BaseDataField } from 'modules/common-data-fields';
import { LendDetailed } from '../../types';
import { useFormatMessage } from 'modules/messages';
import { toDecimal, toFixed } from 'modules/input-amount';
import { CryptoToFiatAmount } from 'modules/fiat';
import { Text } from 'common/components/Text';
import { formatDatetime } from 'common/utils/utils';
import { ProgressCircle } from '../../common/ProgressCircle';
import styles from './LendData.module.scss';
import { NetworkBox } from 'common/components/NetworkBox';
import { ReactComponent as WalletIcon } from 'common/assets/grey-icons/wallet-small-grey.svg';

type Props = { lend: LendDetailed };

export const LendData: React.FC<Props> = ({ lend }) => {
  const formatMessage = useFormatMessage();

  const interestEarnedPct = toDecimal(lend.interest_earned)
    .div(lend.interest_total)
    .mul(100);

  return (
    <div className={styles.container}>
      <div className={styles.dataFieldsContainer}>
        <div className={styles.dataFields}>
          <div className={styles.dataField}>
            <BaseDataField
              label={formatMessage('finopsCommon.fundsLocked')}
              value={`${toFixed(lend.principal_amount)} ${
                lend.principal_currency
              }`}
              valueTextStyle={'xxbold1619'}
              secondaryValue={
                lend.state !== 'COMPLETED' ? (
                  <CryptoToFiatAmount
                    amount={lend.principal_amount}
                    currency={lend.principal_currency}
                    currencyRate={lend.principal_currency_rate}
                  />
                ) : null
              }
            />
            <div className={styles.wallet}>
              <NetworkBox network={lend.principal_wallet.network} />
              <div>
                <WalletIcon />{' '}
                <Text style='light1215' colorStyle='secondary'>
                  {lend.principal_wallet.name}
                </Text>
              </div>
            </div>
          </div>
          <BaseDataField
            className={styles.dataField}
            label={formatMessage('common.totalInterest')}
            value={`${toFixed(lend.interest_total)} ${lend.principal_currency}`}
            valueTextStyle={'xxbold1619'}
            secondaryValue={
              lend.state !== 'COMPLETED' ? (
                <CryptoToFiatAmount
                  amount={lend.interest_total}
                  currency={lend.principal_currency}
                  currencyRate={lend.principal_currency_rate}
                />
              ) : null
            }
          />
          <BaseDataField
            className={styles.dataField}
            label={formatMessage('common.interestRate')}
            value={formatMessage('common.rateApr', { rate_pct: lend.rate_pct })}
            valueTextStyle={'xxbold1619'}
          />
        </div>
        {lend.state === 'TERMINATED' && (
          <Text
            className={styles.terminatedInfoBox}
            style='light1425'
            colorStyle='secondary'
          >
            {lend.is_paid_out
              ? formatMessage(
                  'lend.lendTerminatedBeforeMaturityFundsHaveBeenReleased'
                )
              : formatMessage(
                  'lend.lendTerminatedBeforeMaturityFundsWillBeReleased',
                  {
                    date: formatDatetime(lend.payout_at!),
                  }
                )}
          </Text>
        )}
        <div className={styles.secondaryDataFields}>
          <Text style='light1215' colorStyle='secondary'>
            {formatMessage('common.opened', {
              date: formatDatetime(lend.start_at),
            })}
          </Text>
          <Text style='light1215' colorStyle='secondary'>
            {formatMessage(
              lend.state === 'TERMINATED'
                ? 'lend.terminatedAndDate'
                : 'common.maturityAndDate',
              {
                date: formatDatetime(lend.end_at),
              }
            )}
          </Text>
          <Text style='light1215' colorStyle='secondary'>
            {formatMessage('common.id', {
              id: lend.id,
            })}
          </Text>
        </div>
      </div>
      {lend.state !== 'TERMINATED' && (
        <div className={styles.progressCircleContainer}>
          <ProgressCircle progress={interestEarnedPct} state={lend.state} />
          <div className={styles.progressCircleLabel}>
            <Text className={styles.labelPart} style='xbold1215'>{`${toFixed(
              lend.interest_earned
            )} ${lend.principal_currency}`}</Text>
            <Text className={styles.labelPart} style='light1215'>{`${toFixed(
              interestEarnedPct,
              0
            )}%`}</Text>
            <Text className={styles.labelPart} style='light1215'>
              {formatMessage('lend.earnedSoFar', {
                progress: toFixed(interestEarnedPct, 0),
              })}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};
