import styles from './FilterButton.module.scss';
import { Text } from 'common/components/Text';
import clsx from 'clsx';

type Props = {
  onClick: () => void;
  text: string;
  isSelected?: boolean;
  className?: string;
};

export const FilterButton: React.FC<Props> = ({
  text,
  onClick,
  isSelected,
  className,
}) => {
  return (
    <div
      className={clsx(
        styles.button,
        isSelected && styles.selectedButton,
        className
      )}
      onClick={onClick}
    >
      <Text style='bold1417'>{text}</Text>
    </div>
  );
};
