import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearchParam = (
  key: string
): [string, (value: string) => void] => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(new URLSearchParams(search));

  const value = searchAsObject[key];
  const setValue = (value: string) =>
    setSearch({ ...searchAsObject, key: value });

  return [value, setValue];
};

type LoadMoreFn = () => void;

export const useInfiniteScrolling = (loadMore: LoadMoreFn) => {
  const lastItemObserverRef = useRef<IntersectionObserver>();
  const lastItemRef = (node: HTMLElement | null) => {
    if (!node) {
      return;
    }

    if (lastItemObserverRef && lastItemObserverRef.current) {
      lastItemObserverRef.current.disconnect();
    }
    lastItemObserverRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });
    lastItemObserverRef.current.observe(node);
  };
  return { lastItemRef };
};
