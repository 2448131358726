import { BackButton, NextButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Text, textStyles } from 'common/components/Text';
import { useState } from 'react';
import { InputAmount, toDecimal } from 'modules/input-amount';
import styles from './StepSetFundraisingGoal.module.scss';
import { Switch } from 'common/components/Switch';
import { HeaderWithDescription } from './HeaderWithDescription';
import clsx from 'clsx';
import { EndDatePicker } from './EndDatePicker';
import { useNewCharityFormContext } from '../context';
import { SelectNetworks } from './SelectNetworks';
import { Alert } from 'common/components/Alert';

type Props = {
  onGoNext: () => void;
  onGoBack: () => void;
};

const INPUT_DECIMALS = 2;
const MAX_AMOUNT = 9999999999.99;

export const StepSetFundraisingGoal: React.FC<Props> = ({
  onGoNext,
  onGoBack,
}) => {
  const formatMessage = useFormatMessage();
  const context = useNewCharityFormContext();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [errorAmount, setErrorAmount] = useState('');
  const [errorDate, setErrorDate] = useState('');
  const [errorAlert, setErrorAlert] = useState('');

  const validateAndContinue = () => {
    if (
      context.goalEnabled &&
      (!context.goalAmount || toDecimal(context.goalAmount).lte(0))
    ) {
      setErrorAmount(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }

    if (errorAmount) {
      return;
    }

    if (context.endAtEnabled && !context.endAt) {
      setErrorDate(formatMessage('common.fieldRequired'));
      return;
    }

    if (errorDate) {
      return;
    }

    if (!context.networkList?.length) {
      setErrorAlert(formatMessage('common.networkRequired'));
      return;
    }

    onGoNext();
  };

  return (
    <>
      <ModalHeader showClose>
        {formatMessage('charityForms.newCharityForm')}
      </ModalHeader>
      <ModalBody>
        <Text
          style='light1425'
          inlineStyle={{ display: 'block', margin: '-10px 0 40px' }}
        >
          {formatMessage('charityForms.specifyFormDetails')}
        </Text>
        {errorAlert && (
          <Alert
            text={errorAlert}
            severity={'error'}
            style={{ marginBottom: '40px' }}
          />
        )}
        <div className={styles.container}>
          <div className={styles.flexBox}>
            <HeaderWithDescription
              title={formatMessage('charityForms.financialFundraisingGoal')}
              description={formatMessage(
                'charityForms.achievementOrSurpassing'
              )}
              tooltip={formatMessage(
                'charityForms.settingFinancialGoalMotivatesDonors'
              )}
            />
            <Switch
              checked={context.goalEnabled}
              onChange={(event) => {
                context.setGoalEnabled(event.target.checked);
                setErrorAmount('');
              }}
            />
          </div>
          <div className={styles.inputContainer}>
            {!context.goalEnabled && <div className={styles.disabledBox} />}
            <div
              className={clsx(
                styles.amountContainer,
                errorAmount && styles.errorInput
              )}
              onClick={() => {
                document.getElementById('fundraisingGoal')?.focus();
              }}
            >
              <InputAmount
                value={context.goalAmount ?? ''}
                onChange={(value) => {
                  context.setGoalAmount(value);
                  if (toDecimal(value).gt(MAX_AMOUNT)) {
                    setErrorAmount(
                      formatMessage('common.amountMustBeLessOrEqual', {
                        amount: MAX_AMOUNT,
                        currency: context.fiatCurrency,
                      })
                    );
                  } else {
                    setErrorAmount('');
                  }
                }}
                onError={() => {}}
                decimals={INPUT_DECIMALS}
                className={clsx(styles.input, textStyles.light1622)}
                id={'fundraisingGoal'}
                disabled={!context.goalEnabled}
              />
              <Text
                inlineStyle={{ display: 'block' }}
                style={'light1622'}
                colorStyle={context.goalEnabled ? 'primary' : 'secondary'}
              >
                &nbsp;{context.fiatCurrency}
              </Text>
            </div>
          </div>
          {errorAmount && (
            <Text style='regular1215' colorStyle={'error'} dataTest='errorText'>
              {errorAmount}
            </Text>
          )}
        </div>
        <div className={styles.container}>
          <div className={styles.flexBox}>
            <HeaderWithDescription
              title={formatMessage('charityForms.fundraiserEndDate')}
              description={formatMessage(
                'charityForms.fundraiserWillRunUntilSetDate'
              )}
            />
            <Switch
              checked={context.endAtEnabled}
              onChange={(event) => {
                context.setEndAtEnabled(event.target.checked);
                setOpenDatePicker(false);
                setErrorDate('');
              }}
            />
          </div>
          <EndDatePicker
            error={errorDate}
            value={context.endAt ? new Date(context.endAt) : null}
            onChange={(value) => {
              if (value) context.setEndAt(value);
              setErrorDate('');
            }}
            disabled={!context.endAtEnabled}
            setIsOpen={(val) => setOpenDatePicker(val)}
            isOpen={openDatePicker}
          />
        </div>
        <SelectNetworks
          onChange={() => {
            setErrorAlert('');
          }}
          className={styles.networks}
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <NextButton
            onClick={validateAndContinue}
            disabled={!context.networkList?.length}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
