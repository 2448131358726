export const ERR_EMAIL_EXISTS = 'User with this email already exists.';
export const ERR_EMAIL_INVALID = 'Enter a valid email address.';
export const ERR_EMAIL_NOT_VERIFIED = 'Email is not verified.';
export const ERR_EMAIL_OR_PASSWORD_INCORRECT = 'Incorrect email or password.';
export const ERR_NEW_PASSWORDS_SAME =
  'New password cannot be the same as old password.';

export const ERR_INVALID_VALUE = 'Invalid value.';
export const ERR_NULL_VALUE = 'This field may not be null.';
export const ERR_BLANK_VALUE = 'This field may not be blank.';
export const ERR_REQUIRED_VALUE = 'This field is required.';

export const ERR_NOT_ENOUGH_FUNDS = 'Not enough funds to withdraw.';
