import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { BalanceBox } from 'modules/withdraw/components/BalanceBox';
import styles from './WalletSelector.module.scss';
import { WalletBox } from 'modules/financial-ops/common';
import {
  SelectCurrencyWalletsButton,
  useSelectCurrencyWalletContext,
} from 'modules/select-currency-wallet';

type Props = {
  dataTest?: string;
};

export const WalletSelector: React.FC<Props> = ({ dataTest }) => {
  const formatMessage = useFormatMessage();
  const { wallet, currency } = useSelectCurrencyWalletContext();

  return (
    <>
      <div className={styles.desktopContainer} data-test='selectorDesktop'>
        <div className={styles.textMargin} data-test='walletSelector'>
          <Text style='light1622' dataTest={dataTest}>
            {wallet
              ? wallet.name
              : formatMessage('deposit.chooseCurrencyAndWallet')}
          </Text>
          {wallet && <BalanceBox currency={currency} wallet={wallet} />}
        </div>
        <SelectCurrencyWalletsButton
          currencyName={currency?.currency}
          networkName={currency?.network}
        />
      </div>
      <div className={styles.mobileContainer} data-test='selectorMobile'>
        {!wallet && (
          <Text style='xbold1417'>
            {formatMessage('deposit.chooseCurrencyAndWallet')}
          </Text>
        )}
        <SelectCurrencyWalletsButton
          currencyName={currency?.currency}
          networkName={currency?.network}
          className={styles.selectCurrencyMobile}
        />
        {wallet && currency && (
          <WalletBox
            wallet={wallet}
            currency={currency.currency}
            selectedWallet={wallet}
          />
        )}
      </div>
    </>
  );
};
