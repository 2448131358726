import {
  ModalActions,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Button } from 'common/components/button';

type Props = {
  onClose: () => void;
  approvalOfTransaction?: boolean;
};

export const Cancel2FA: React.FC<Props> = ({
  onClose,
  approvalOfTransaction,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <ModalHeader
        subtitle={
          approvalOfTransaction
            ? formatMessage('2FA.transactionHasNotBeenApproved')
            : formatMessage('2FA.actionHasNotBennConfirmed')
        }
        showClose={false}
      >
        {formatMessage('2FA.actionCancelled')}
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('common.button.close')}
            type='secondary'
            onClick={onClose}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
