import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { Button, BackButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Text } from 'common/components/Text';
import { prettifyError } from 'common/utils/prettify-error';
import { Member } from 'modules/company-members/api';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import {
  ERR_MEMBERS_NOT_FOUND,
  ERR_THRESHOLD_THE_SAME,
  PostMultiapprovalResponseError,
} from '../api';
import { MembersSummary } from './MembersSummary';
import styles from './StepAdjustMultiapproval.module.scss';
import { ThresholdSlider } from './ThresholdSlider';
import { ThresholdWithInput } from './ThresholdWithInput';

type Props = {
  threshold: number;
  mutationError: AxiosError<PostMultiapprovalResponseError> | null;
  isMutationLoading: boolean;
  onChange: (value: number) => void;
  onSubmit: () => void;
  dataTest?: string;
  onGoBack: () => void;
  selectedMembers: Member[];
};

export const StepAdjustMultiapproval: React.FC<Props> = ({
  threshold,
  isMutationLoading,
  mutationError,
  onChange,
  onSubmit,
  onGoBack,
  selectedMembers,
}) => {
  const formatMessage = useFormatMessage();
  const [error, setError] = useState('');

  const getErrorText = (error: AxiosError<PostMultiapprovalResponseError>) => {
    if (typeof error.response?.data === 'object') {
      if (error.response.data.threshold?.includes(ERR_THRESHOLD_THE_SAME))
        return formatMessage('multiapproval.newThresholdCannotBeTheSame');
      if (error.response.data.signers?.includes(ERR_MEMBERS_NOT_FOUND))
        return formatMessage('multiapproval.someMembersWereNotFound');
    }
    return prettifyError(error);
  };

  return (
    <>
      <ModalHeader
        showClose={true}
        subtitle={formatMessage(
          'multiapproval.stepAdjustMultiapproval.description'
        )}
      >
        {formatMessage('multiapproval.stepAdjustMultiapproval.header')}
      </ModalHeader>
      <ModalBody>
        {mutationError && (
          <Alert
            className={styles.error}
            severity='error'
            text={getErrorText(mutationError)}
          />
        )}
        <div className={styles.headerMultiapproval}>
          <Text style='xbold1417'>{formatMessage('common.multiapproval')}</Text>
        </div>
        <ThresholdSlider
          className={styles.slider}
          maxValue={selectedMembers.length}
          value={threshold}
          onChange={onChange}
        />
        <ThresholdWithInput
          value={threshold}
          onChange={onChange}
          max={selectedMembers.length}
          dataTest='multiApprovalNumber'
          error={error}
          onError={(err: string) => setError(err)}
        />
        <MembersSummary selectedMembers={selectedMembers} />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            type='dark'
            isLoading={isMutationLoading}
            disabled={isMutationLoading}
            name={formatMessage('common.button.confirm')}
            onClick={() => {
              if (threshold > 0) {
                onSubmit();
              } else {
                setError(' ');
              }
            }}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
