import clsx from 'clsx';
import { ReactComponent as BorrowClearBw } from 'common/assets/icons/borrow-clear-bw.svg';
import { ReactComponent as BorrowClearTheme } from 'common/assets/icons/borrow-clear-theme.svg';
import { ReactComponent as WalletClearBw } from 'common/assets/icons/wallet-clear-bw.svg';
import { ReactComponent as WalletClearTheme } from 'common/assets/icons/wallet-clear-theme.svg';
import { ViewMode } from '../types';

import styles from './ViewModeSwitch.module.scss';

type Props = {
  className?: string;
  viewMode: ViewMode;
  onViewModeChange: (mode: ViewMode) => void;
};

export const ViewModeSwitch: React.FC<Props> = ({
  className,
  viewMode,
  onViewModeChange,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div
        onClick={() => onViewModeChange('wallets')}
        className={clsx(styles.button, viewMode === 'wallets' && styles.active)}
        data-test='walletsSwitch'
      >
        {viewMode === 'wallets' ? <WalletClearTheme /> : <WalletClearBw />}
      </div>
      <div
        onClick={() => onViewModeChange('assets')}
        className={clsx(styles.button, viewMode === 'assets' && styles.active)}
        data-test='assetsSwitch'
      >
        {viewMode === 'assets' ? <BorrowClearTheme /> : <BorrowClearBw />}
      </div>
    </div>
  );
};
