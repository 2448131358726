import { useFormatMessage } from 'modules/messages';
import { FilterButton } from './FilterButton';
import { FilterBoxTemplate } from './FilterBoxTemplate';
import { toggleElement } from 'common/utils/toggle-element';

export const TransactionTypeFilter: React.FC<{
  types?: string[];
  onTypesChange: (value?: string[]) => void;
}> = ({ types, onTypesChange }) => {
  const formatMessage = useFormatMessage();

  const typesList = [
    {
      text: formatMessage('transactions.incoming'),
      value: 'INCOMING',
    },
    {
      text: formatMessage('transactions.outgoing'),
      value: 'OUTGOING',
    },
  ];

  return (
    <FilterBoxTemplate
      header={formatMessage('transactions.type')}
      buttons={
        <>
          {typesList.map((type, i) => (
            <FilterButton
              onClick={() =>
                onTypesChange(toggleElement(types ?? [], type.value).sort())
              }
              text={type.text}
              key={i}
              isSelected={types?.includes(type.value)}
            />
          ))}
        </>
      }
    />
  );
};
