import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  useTransactionActionMutation,
} from 'modules/transactions/api';
import { useEffect, useState } from 'react';
import { TradesCalculationBase } from '../api';
import { StepNewTradeSuccess } from './StepNewTradeSuccess';
import { StepNewTradeSummary } from './StepNewTradeSummary';
import { InstrumentCurrency, TradeOptions } from './types';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Props = {
  tolerance?: string;
  orderPrice?: string;
  limitPrice?: string;
  spendCurrency?: InstrumentCurrency;
  spendWallet?: CurrencyWallet;
  receiveCurrency?: InstrumentCurrency;
  receiveWallet?: CurrencyWallet;
  spendAmount?: string;
  receiveAmount?: string;
  calculationBase: TradesCalculationBase;
  isBuy: boolean;
  isOpen: boolean;
  option: TradeOptions;
  pairId?: number;
  onClose: () => void;
  onSuccessTrade: () => void;
  onAmountError: () => void;
  token: string | undefined;
};

type Step = 'summary' | 'success' | '2fa' | '2FAcancel';

export const NewTradeModal: React.FC<Props> = ({
  isOpen,
  tolerance,
  limitPrice,
  orderPrice,
  spendCurrency,
  spendWallet,
  receiveCurrency,
  receiveWallet,
  spendAmount,
  receiveAmount,
  calculationBase,
  pairId,
  isBuy,
  option,
  onClose,
  onSuccessTrade,
  onAmountError,
  token,
}) => {
  const formatMessage = useFormatMessage();

  const [step, setStep] = useState<Step>('summary');
  const [tradeError, setTradeError] = useState(false);
  const [transactionId, setTransactionId] = useState<number>();
  const [error2fa, setError2fa] = useState<string>();

  useEffect(() => {
    setStep('summary');
    setTransactionId(undefined);
    setError2fa('');
  }, [isOpen]);

  const queryClient = useQueryClient();

  const transactionActionMutation = useTransactionActionMutation();

  const approveTransaction = (otp: string) => {
    if (!transactionId) {
      return;
    }
    transactionActionMutation.mutate(
      {
        payload: {
          transaction_id: transactionId,
          action: 'APPROVE',
        },
        headers: generateHeader2FA(otp),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
          queryClient.invalidateQueries(
            TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
          );
          onClose();
        },
        onError: (error) => {
          setErrorOTP(error, setError2fa, formatMessage);
        },
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (tradeError) onAmountError();
        onClose();
      }}
    >
      {step === 'summary' && (
        <StepNewTradeSummary
          tolerance={tolerance}
          initialLimitPrice={limitPrice}
          initialOrderPrice={orderPrice}
          spendCurrency={spendCurrency}
          spendWallet={spendWallet}
          receiveCurrency={receiveCurrency}
          receiveWallet={receiveWallet}
          initialSpendAmount={spendAmount}
          initialReceiveAmount={receiveAmount}
          isBuy={isBuy}
          pairId={pairId}
          calculationBase={calculationBase}
          onSuccess={(transactionId) => {
            setStep('success');
            setTransactionId(transactionId);
            onSuccessTrade();
          }}
          onError={(err) => setTradeError(err)}
          onAmountError={onAmountError}
          option={option}
          initialToken={token}
        />
      )}
      {step === 'success' && (
        <StepNewTradeSuccess
          onClose={onClose}
          onApprove={() => setStep('2fa')}
        />
      )}
      {step === '2fa' && (
        <Module2FA
          onVerify={approveTransaction}
          onError={(err) => setError2fa(err)}
          error={error2fa}
          isLoading={transactionActionMutation.isLoading}
          onCancel={() => setStep('2FAcancel')}
          approvalOfTransaction={true}
          title={formatMessage('trade.approveTrade')}
        />
      )}
      {step === '2FAcancel' && (
        <Cancel2FA onClose={onClose} approvalOfTransaction={true} />
      )}
    </Modal>
  );
};
