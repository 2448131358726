import { useFormatMessage } from 'modules/messages';
import { BorrowsSummaryResponseData } from '../api';
import { TextStyle } from 'common/components/Text';
import { AverageApr } from 'modules/financial-ops/common/components/overview-components';

type Props = {
  borrowsSummary?: BorrowsSummaryResponseData;
  isLoading?: boolean;
  textStyle?: TextStyle;
};

export const AverageBorrowApr: React.FC<Props> = ({
  borrowsSummary,
  isLoading,
  textStyle = 'xxbold3048',
}) => {
  const formatMessage = useFormatMessage();

  return (
    <AverageApr
      rate={borrowsSummary?.current_avg_rate_pct}
      isLoading={isLoading}
      text={formatMessage('borrow.averageBorrowApr')}
      textStyle={textStyle}
    />
  );
};
