import { Text } from 'common/components/Text';
import { CancelButton, NextButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import styles from './StepWarning.module.scss';
import {
  PostLendTerminateEffectResponseError,
  lendDetailsQueryKey,
  useLendDetails,
  useLendTerminateEffectMutation,
} from '../../api';
import { LoaderBox } from 'common/components/loader';
import { toDecimal, toFixed } from 'modules/input-amount';
import { useEffect } from 'react';
import { Alert } from 'common/components/Alert';
import { useGetErrorText } from './error-utils';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  lendId: number;
  isLoadingNextStep: boolean;
  nextStepError: AxiosError<PostLendTerminateEffectResponseError> | null;
  onCancel: () => void;
  onNext: () => void;
};

export const StepWarning: React.FC<Props> = ({
  lendId,
  isLoadingNextStep,
  nextStepError,
  onCancel,
  onNext,
}) => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const { data: lend, isLoading: isLoadingLend } = useLendDetails(lendId);

  const effectMutation = useLendTerminateEffectMutation(lendId);

  useEffect(() => {
    effectMutation.mutate(
      { summary: false },
      {
        onError: () => {
          // refresh if lend becomes inactive or gets has_pending_transaction = true in the meantime
          queryClient.invalidateQueries(lendDetailsQueryKey(lendId));
        },
      }
    );
  }, []);

  const isLoading = isLoadingLend || effectMutation.isLoading;

  const isData = lend !== undefined && effectMutation.data !== undefined;

  const getErrorText = useGetErrorText();

  return (
    <>
      <ModalHeader
        subtitle={
          <Text style={'xxbold1619'}>
            {formatMessage(
              'lend.ensureYouWantToTerminateLendBeforeMaturityDate'
            )}
          </Text>
        }
      >
        {formatMessage('lend.terminateLend')}
      </ModalHeader>
      <ModalBody>
        {effectMutation.error && (
          <Alert severity='error' text={getErrorText(effectMutation.error)} />
        )}
        {nextStepError && (
          <Alert severity='error' text={getErrorText(nextStepError)} />
        )}
        {isLoading && <LoaderBox height='245px' />}
        {isData && (
          <div className={styles.content}>
            {toDecimal(effectMutation.data.rate_pct).gt(0) ? (
              <Text style={'xbold1425'}>
                {formatMessage(
                  'lend.terminationBeforeMaturityResultInAprDecrease'
                )}
              </Text>
            ) : (
              <Text style={'xbold1425'}>
                {formatMessage(
                  'lend.terminationBeforeMaturityResultInEarnedInterestLoss'
                )}
              </Text>
            )}
            <div>
              <div className={styles.interestRate}>
                <Text style={'light1425'}>
                  {formatMessage('lend.newInterestRate') + ':'}
                </Text>
                <Text style={'light1425'}>
                  {toFixed(effectMutation.data.rate_pct, 2) + '%'}
                </Text>
              </div>
              <div className={styles.interestRate}>
                <Text style={'light1425'} colorStyle={'secondary'}>
                  {formatMessage('lend.initialInterestRate') + ':'}
                </Text>
                <Text style={'light1425'} colorStyle={'secondary'}>
                  {toFixed(lend.rate_pct, 2) + '%'}
                </Text>
              </div>
            </div>
            <Text style={'light1425'}>
              {formatMessage('lend.warningTerminationTakesXHours', {
                hours: effectMutation.data.payout_hours,
              })}
            </Text>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <NextButton
            disabled={!!nextStepError}
            isLoading={isLoadingNextStep}
            onClick={onNext}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
