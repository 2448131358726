import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useNavigate } from 'react-router-dom';

type Props = { path: string; className?: string; disabled?: boolean };

export const DetailsButton: React.FC<Props> = ({
  path,
  className,
  disabled,
}) => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();

  return (
    <Button
      disabled={disabled}
      type='secondarySmall'
      name={formatMessage('common.details')}
      className={className}
      onClick={() => navigate(path)}
    />
  );
};
