import { SetState } from 'common/types';
import { createContext, useContext, useState } from 'react';

type SetMultiapprovalContextValue = {
  isOpen: boolean;
  setIsOpen: SetState<boolean>;
};

const SetMultiapprovalContext = createContext<SetMultiapprovalContextValue>({
  isOpen: false,
  setIsOpen: () => {},
});

export const SetMultiapprovalContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SetMultiapprovalContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </SetMultiapprovalContext.Provider>
  );
};

export const useSetMultiapprovalContext = () => {
  return useContext(SetMultiapprovalContext);
};
