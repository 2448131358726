import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useMultiapprovalQuery } from 'modules/multiapproval/api';

export const CurrentThreshold: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { data } = useMultiapprovalQuery();

  return (
    <>
      <div>
        <Text style='light1425'>
          {formatMessage('common.currentMultiapprovalThreshold')}
        </Text>
      </div>
      <div>
        {!data ? (
          <RotatingLoader size={14} type='secondary' />
        ) : (
          <Text style='xxbold1425'>
            {formatMessage('common.threshold.slash', {
              current: data?.data.threshold,
              max: data?.data.signers_count,
            })}{' '}
          </Text>
        )}
        <Text style='light1425'>
          {formatMessage('common.approvalsRequired')}
        </Text>
      </div>
    </>
  );
};
