import { Text } from 'common/components/Text';
import {
  BaseCurrency,
  CurrencyWallet,
} from 'modules/select-currency-wallet/types';
import { WalletBox } from './WalletBox';
import styles from './WalletSelector.module.scss';
import { ReactComponent as ArrowheadDownBlack } from 'common/assets/black-icons/arrowhead-down-12px-withpadding.svg';
import { useEffect, useState } from 'react';
import { descCmpAmounts } from 'common/utils/comparators';
import clsx from 'clsx';

type Props = {
  currency: BaseCurrency | undefined;
  wallets: CurrencyWallet[] | undefined;
  selectedWallet: CurrencyWallet | undefined;
  error?: string;
  onSelectWallet: (wallet: CurrencyWallet) => void;
  className?: string;
  dataTest?: string;
};

const BIG_SCREENS_WALLETS_LENGTH = 3;
const SHORT_SCREENS_WALLETS_LENGTH = 2;

export const WalletSelector: React.FC<Props> = ({
  currency,
  wallets,
  selectedWallet,
  error = '',
  onSelectWallet,
  className,
}) => {
  const [lengthElementsSmallToShow, setLengthElementsSmallToShow] = useState(
    SHORT_SCREENS_WALLETS_LENGTH
  );

  const sortedWallets = (wallets || []).sort((a, b) =>
    descCmpAmounts(a.balance, b.balance)
  );

  const [showAll, setShowAll] = useState(false);
  const [walletsToShowSmall, setWalletsToShowSmall] = useState(
    sortedWallets.slice(0, SHORT_SCREENS_WALLETS_LENGTH)
  );

  useEffect(() => {
    setWalletsToShowSmall(sortedWallets);
    setLengthElementsSmallToShow(SHORT_SCREENS_WALLETS_LENGTH);
    setShowAll(false);
  }, [currency]);

  const getWalletArray = () =>
    showAll
      ? sortedWallets
      : sortedWallets.slice(0, BIG_SCREENS_WALLETS_LENGTH);

  const getWalletSmallArray = () =>
    walletsToShowSmall.slice(0, lengthElementsSmallToShow);

  return (
    <div className={clsx(styles.container, className)}>
      {currency && (
        <>
          <div className={styles.containerBox} data-test='walletSelector'>
            {getWalletArray().map((wallet) => (
              <WalletBox
                wallet={wallet}
                currency={currency.currency}
                onSelectWallet={onSelectWallet}
                selectedWallet={selectedWallet}
                key={wallet.id}
              />
            ))}
          </div>
          <div className={styles.containerBoxSmall}>
            {getWalletSmallArray()?.map((wallet) => (
              <WalletBox
                wallet={wallet}
                currency={currency.currency}
                onSelectWallet={(wallet: CurrencyWallet) => {
                  const tmpArray = [...sortedWallets];
                  const foundIndex = tmpArray.findIndex(
                    ({ id }) => id === wallet.id
                  );
                  tmpArray.splice(foundIndex, 1);

                  onSelectWallet(wallet);
                  setWalletsToShowSmall([wallet, ...tmpArray]);
                  setLengthElementsSmallToShow(1);
                  setShowAll(false);
                }}
                selectedWallet={selectedWallet}
                key={wallet.id}
              />
            ))}
          </div>
        </>
      )}
      {!showAll && sortedWallets?.length > BIG_SCREENS_WALLETS_LENGTH && (
        <div
          data-test='arrowToSeeMoreWallets'
          className={styles.arrowBox}
          onClick={() => {
            setLengthElementsSmallToShow(sortedWallets.length);
            setShowAll(true);
          }}
        >
          <ArrowheadDownBlack />
        </div>
      )}
      {!showAll && sortedWallets?.length > lengthElementsSmallToShow && (
        <div
          className={styles.arrowBoxMobile}
          onClick={() => {
            setLengthElementsSmallToShow(sortedWallets.length);
            setShowAll(true);
          }}
        >
          <ArrowheadDownBlack />
        </div>
      )}
      {error && (
        <Text style='regular1215' colorStyle='error'>
          {error}
        </Text>
      )}
    </div>
  );
};
