import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useWallets } from 'modules/assets-and-wallets/api';
import { Assets } from 'modules/assets-and-wallets/components/Assets';
import { TotalAssetFiatValue } from 'modules/assets-and-wallets/components/TotalAssetFiatValue';
import { useFormatMessage } from 'modules/messages';
import { ShowAllButton } from './ShowAllButton';

export const SectionAssets = () => {
  const formatMessage = useFormatMessage();

  const { data, isLoading } = useWallets();

  return (
    <Section>
      <SectionHeader
        header={formatMessage('wallets.header')}
        actions={<ShowAllButton path='/wallets' />}
      />
      <div>
        <TotalAssetFiatValue />
        <Assets
          wallets={data ?? []}
          isWalletsLoading={isLoading}
          enableSort={false}
          shortTable={true}
        />
      </div>
    </Section>
  );
};
