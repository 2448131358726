import { FiatSwitch } from 'modules/fiat';
import { ReferenceClock } from 'modules/reference-clock';
import { Sidebar } from './Sidebar';
import styles from './SidebarDesktop.module.scss';

type Props = {};

export const SidebarDesktop: React.FC<Props> = () => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.fiatSwitchContainer}>
        <ReferenceClock />
        <FiatSwitch />
      </div>
    </div>
  );
};
