import { CurrencyEnum } from 'common/types';
import { useFormatMessage } from 'modules/messages';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { DECIMALS_MAP } from 'common/utils/currency-enum-utils';
import { PriceBase } from 'modules/common-data-fields';

type PriceProps = {
  amount: string;
  currency1: CurrencyEnum;
  currency2: CurrencyEnum;
};

export const InvertedMinimalPrice: React.FC<PriceProps> = ({
  amount,
  currency1,
  currency2,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <PriceBase
      title={formatMessage('transactions.invertedMinimalPrice')}
      currency1={currency1}
      currency2={currency2}
      currencyValue={toLimitedPrec(
        toFixed(toDecimal(1).div(amount), DECIMALS_MAP[currency2])
      )}
    />
  );
};

export const InvertedMaximalPrice: React.FC<PriceProps> = ({
  amount,
  currency1,
  currency2,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <PriceBase
      title={formatMessage('transactions.invertedMaximalPrice')}
      currency1={currency1}
      currency2={currency2}
      currencyValue={toLimitedPrec(
        toFixed(toDecimal(1).div(amount), DECIMALS_MAP[currency2])
      )}
    />
  );
};

export const MinimalPrice: React.FC<PriceProps> = ({
  amount,
  currency1,
  currency2,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <PriceBase
      title={formatMessage('trade.minimalPrice')}
      currency1={currency1}
      currency2={currency2}
      currencyValue={toLimitedPrec(toFixed(amount))}
    />
  );
};

export const MaximalPrice: React.FC<PriceProps> = ({
  amount,
  currency1,
  currency2,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <PriceBase
      title={formatMessage('trade.maximalPrice')}
      currency1={currency1}
      currency2={currency2}
      currencyValue={toLimitedPrec(toFixed(amount))}
    />
  );
};
