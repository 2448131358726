import { SetState } from 'common/types';
import { createContext, useContext, useState } from 'react';
import { BaseCurrency, Currency, CurrencyWallet } from './types';
import { useGetCurrenciesWithWallets } from './hooks';

type SelectCurrencyWalletContextValue = {
  currency: Currency | undefined;
  wallet: CurrencyWallet | undefined;
  setCurrencyKey: SetState<BaseCurrency | undefined>;
  setWalletId: SetState<number | undefined>;
  isOpenModal: boolean;
  setIsOpenModal: SetState<boolean>;
  reset: () => void;
};

const SelectCurrencyWalletContext =
  createContext<SelectCurrencyWalletContextValue>({
    currency: undefined,
    wallet: undefined,
    isOpenModal: false,
    setCurrencyKey: () => {},
    setWalletId: () => {},
    setIsOpenModal: () => {},
    reset: () => {},
  });

export const SelectCurrencyWalletContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [currencyKey, setCurrencyKey] = useState<BaseCurrency | undefined>();
  const [walletId, setWalletId] = useState<number>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  // data derived from base state
  const { data: currencies } = useGetCurrenciesWithWallets();

  const currency = currencies?.find(
    (item) =>
      item.currency === currencyKey?.currency &&
      item.network === currencyKey.network
  );

  const wallet = walletId
    ? currency?.wallets.find((item) => item.id === walletId)
    : undefined;

  const reset = () => {
    setWalletId(undefined);
    setCurrencyKey(undefined);
    setIsOpenModal(false);
  };

  return (
    <SelectCurrencyWalletContext.Provider
      value={{
        wallet,
        currency,
        isOpenModal,
        setCurrencyKey,
        setWalletId,
        setIsOpenModal,
        reset,
      }}
    >
      {children}
    </SelectCurrencyWalletContext.Provider>
  );
};

export const useSelectCurrencyWalletContext = () => {
  return useContext(SelectCurrencyWalletContext);
};
