import { Alert } from 'common/components/Alert';
import D from 'decimal.js';
import { Button, CancelButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { useFormatMessage } from 'modules/messages';
import styles from './StepSummary.module.scss';
import { BorrowName } from '../../borrow-list/components/BorrowName';
import { BorrowDetails } from '../../types';
import { Text } from 'common/components/Text';
import { formatDatetimeWithoutSec } from 'common/utils/utils';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { LoaderBox } from 'common/components/loader';
import { useBorrowByIdQuery } from '../../api';
import { useWallets } from 'modules/assets-and-wallets/api';

type Props = {
  onClick: () => void;
  onClose: () => void;
  borrow?: BorrowDetails;
  error?: string;
  isLoadingBtn: boolean;
  id: number;
};

export const StepSummary: React.FC<Props> = ({
  onClick,
  onClose,
  borrow,
  error,
  isLoadingBtn,
  id,
}) => {
  const formatMessage = useFormatMessage();

  const { data, isLoading: isBorrowLoading } = useBorrowByIdQuery(id);
  const { data: wallets, isLoading: isWalletLoading } = useWallets();

  const borrowDetails = borrow ?? data?.data;
  const wallet = (wallets || []).find(
    (item) => item.id === borrowDetails?.principal_wallet.id
  );

  return (
    <>
      <ModalHeader>{formatMessage('borrow.repayConfirmation')}</ModalHeader>
      <ModalBody>
        <div className={styles.subheader}>
          <Text style='xxbold1619'>
            {formatMessage('borrow.reviewConfirmBorrowRepayment')}
          </Text>
        </div>
        {error && <Alert severity='error' text={error} />}
        {!isBorrowLoading && !isWalletLoading && borrowDetails && wallet && (
          <>
            <div className={styles.name}>
              <BorrowName borrow={borrowDetails} isSmall={false} />
            </div>
            <Summary>
              <SummaryLabel textStyle='xbold1215'>
                {formatMessage('common.totalAmountToRepay')}
              </SummaryLabel>
              <SummaryValue textStyle='xbold1215'>
                {new D(borrowDetails.principal_amount)
                  .add(borrowDetails.interest)
                  .toString()}{' '}
                {borrowDetails.principal_currency}
              </SummaryValue>
              <SummaryLabel>{formatMessage('common.walletName')}</SummaryLabel>
              <SummaryValue>{wallet.name}</SummaryValue>
              <SummaryLabel>
                {formatMessage('borrow.walletBalance')}
              </SummaryLabel>
              <SummaryValue>{`${toLimitedPrec(
                toFixed(
                  wallet?.balances.find(
                    (item) =>
                      item.currency === borrowDetails?.principal_currency
                  )?.amount
                )
              )} ${borrowDetails.principal_currency}`}</SummaryValue>
              <SummaryLabel>
                {formatMessage('finopsCommon.maturity')}
              </SummaryLabel>
              <SummaryValue>
                {formatDatetimeWithoutSec(borrowDetails.end_at)}
              </SummaryValue>
            </Summary>
          </>
        )}
        {(isBorrowLoading || isWalletLoading) && <LoaderBox />}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onClose} />
          <Button
            type='dark'
            onClick={onClick}
            name={formatMessage('borrow.repay')}
            disabled={isLoadingBtn}
            isLoading={isLoadingBtn}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
