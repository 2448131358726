import clsx from 'clsx';
import { NetworkIcon } from 'common/components/NetworkIcon';
import { Text } from 'common/components/Text';
import { displayNetwork } from 'common/utils/network-enum-utils';
import styles from './NetworkSelector.module.scss';
import { SupportedCurrencies } from './SupportedCurrencies';
import { AvailableNetwork } from 'modules/assets-and-wallets/types';

type Props = {
  network: AvailableNetwork;
  isSelected: boolean;
  onSelect: () => void;
  className?: string;
  currenciesClassName?: string;
};

export const NetworkSelector: React.FC<Props> = ({
  network,
  isSelected,
  onSelect,
  className,
  currenciesClassName,
}) => {
  return (
    <>
      <div
        className={clsx(
          styles.container,
          isSelected && styles.selected,
          className
        )}
        onClick={onSelect}
        data-test='networkSelector'
      >
        <NetworkIcon networkName={network.network} />
        <Text style='xxbold1619'>{displayNetwork(network.network)}</Text>
      </div>
      {isSelected && (
        <div className={clsx(styles.availableCurrencies, currenciesClassName)}>
          <SupportedCurrencies network={network} />
        </div>
      )}
    </>
  );
};
