import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'common/components/modal';
import {
  Member,
  MEMBERS_QUERY_KEY,
  useMembersQuery,
} from 'modules/company-members/api';
import { useFormatMessage } from 'modules/messages';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { useEffect, useState } from 'react';
import {
  MULTIAPPROVAL_URL,
  useMultiapprovalMutation,
  useMultiapprovalQuery,
} from './api';
import { StepAdjustMultiapproval } from './components/StepAdjustMultiapproval';
import { StepSetSigners } from './components/StepSetSigners';
import { StepSuccess } from './components/StepSuccess';
import { useSetMultiapprovalContext } from './context';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Step =
  | 'stepSetSigners'
  | 'stepAdjustMultiapproval'
  | '2FA'
  | 'success'
  | '2FAcancel';

export const SetMultiapproval: React.FC = () => {
  const formatMessage = useFormatMessage();

  const { isOpen, setIsOpen } = useSetMultiapprovalContext();

  const [step, setStep] = useState<Step>('stepSetSigners');
  const [threshold, setThreshold] = useState(1);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const { data } = useMultiapprovalQuery();
  const [error2FA, setError2FA] = useState<string>();

  const multiapprovalMutation = useMultiapprovalMutation();
  const { data: members } = useMembersQuery();
  const queryClient = useQueryClient();

  useEffect(() => {
    setStep('stepSetSigners');
    if (isOpen === false) setSelectedMembers([]);
    multiapprovalMutation.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (data?.data.threshold) {
      setThreshold(data.data.threshold);
    }
  }, [data?.data.threshold]);

  useEffect(() => {
    if (selectedMembers.length > 0)
      setThreshold(
        threshold >= selectedMembers!.length
          ? selectedMembers!.length
          : threshold
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMembers]);

  useEffect(() => {
    if (
      selectedMembers.length === 0 &&
      members?.data &&
      members?.data.length > 0 &&
      isOpen
    )
      setSelectedMembers(
        (members?.data).filter(
          (data: Member) => data.is_signer || data.company_role === 'OWNER'
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members?.data, isOpen]);

  const changeThreshold = () => {
    multiapprovalMutation.mutate(
      {
        payload: {
          threshold,
          signers: selectedMembers.map((member) => member.id) ?? [],
        },
      },
      {
        onSuccess: () => {
          setStep('2FA');
        },
      }
    );
  };

  const onVerify = (keys: string) => {
    multiapprovalMutation.mutate(
      {
        payload: {
          threshold,
          signers: selectedMembers.map((member) => member.id) ?? [],
        },
        headers: generateHeader2FA(keys),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
          queryClient.invalidateQueries(TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY);
          queryClient.invalidateQueries(['GET', MULTIAPPROVAL_URL]);
          queryClient.invalidateQueries(['GET', MEMBERS_QUERY_KEY]);
          setStep('success');
        },
        onError: (err) => {
          setErrorOTP(err, setError2FA, formatMessage);
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      {step === 'stepSetSigners' && (
        <StepSetSigners
          threshold={threshold}
          membersCount={members?.data.length ?? 0}
          isMutationLoading={multiapprovalMutation.isLoading}
          onSubmit={() => {
            if (selectedMembers.length === 1) {
              changeThreshold();
            } else {
              setStep('stepAdjustMultiapproval');
            }
          }}
          selectedMembers={selectedMembers}
          onSelectedMember={(member: Member[]) => setSelectedMembers(member)}
          onCancel={() => setIsOpen(false)}
        />
      )}
      {step === 'stepAdjustMultiapproval' && (
        <StepAdjustMultiapproval
          threshold={threshold}
          isMutationLoading={multiapprovalMutation.isLoading}
          mutationError={multiapprovalMutation.error}
          onChange={(value: number) => setThreshold(value)}
          onSubmit={() => {
            changeThreshold();
          }}
          onGoBack={() => {
            setStep('stepSetSigners');
          }}
          selectedMembers={selectedMembers}
        />
      )}
      {step === '2FA' && (
        <Module2FA
          title={formatMessage('multiapproval.multiapprovalChange')}
          onVerify={onVerify}
          onError={(err) => setError2FA(err)}
          error={error2FA}
          isLoading={multiapprovalMutation.isLoading}
          onCancel={() => setStep('2FAcancel')}
        />
      )}
      {step === 'success' && (
        <StepSuccess
          threshold={threshold}
          onClose={() => setIsOpen(false)}
          selectedMembers={selectedMembers}
        />
      )}
      {step === '2FAcancel' && <Cancel2FA onClose={() => setIsOpen(false)} />}
    </Modal>
  );
};
