import { LoaderBox } from 'common/components/loader';
import { useDashboardTransactionsQuery } from '../api';
import { NothingToDisplay } from 'modules/nothing-to-display';

import { TransactionBox } from './TransactionBox';
import { TransactionsTableHeader } from './TransactionsTableHeader';

type Props = {};

export const ShortTransactionsTable: React.FC<Props> = () => {
  const { data, isLoading } = useDashboardTransactionsQuery();

  return (
    <div>
      <TransactionsTableHeader />
      {isLoading ? (
        <LoaderBox />
      ) : (
        data &&
        data.map((item) => (
          <div key={item.id}>
            <TransactionBox transaction={item} />
          </div>
        ))
      )}
      {!isLoading && !data?.length && <NothingToDisplay />}
    </div>
  );
};
