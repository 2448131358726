import clsx from 'clsx';
import { Text } from 'common/components/Text';
import Decimal from 'decimal.js';
import { TermEnum } from 'modules/financial-ops/common/types';
import { termToMonths } from 'modules/financial-ops/common/utils';
import { useFormatMessage } from 'modules/messages';
import styles from './TermAndRate.module.scss';

type Props = {
  rate: string;
  term?: TermEnum;
  selected?: boolean;
  onClick?: () => void;
  className?: string;
  scaleResponsively?: boolean;
};

export const TermAndRate: React.FC<Props> = ({
  rate,
  term,
  selected = false,
  onClick,
  className,
  scaleResponsively = true,
}) => {
  const formatMessage = useFormatMessage();

  const smallerRateFont = scaleResponsively
    ? new Decimal(rate).toFixed().length > 4
    : false;

  return (
    <div
      data-test={`APRBox-${selected}`}
      className={clsx(
        styles.container,
        scaleResponsively && styles.responsive,
        selected && styles.selected,
        className
      )}
      onClick={onClick}
    >
      <span
        data-test='rate'
        className={clsx(
          styles.cellRate,
          scaleResponsively && styles.responsive,
          smallerRateFont && styles.smallerRateFont
        )}
      >
        {rate}
      </span>
      <span
        className={clsx(styles.cellApr, scaleResponsively && styles.responsive)}
      >
        {formatMessage('common.percentApr')}
      </span>
      <Text className={styles.cellTerm} style='light1215' dataTest='term'>
        {term
          ? formatMessage('termAndRate.term', { months: termToMonths(term) })
          : formatMessage('termAndRate.termless')}
      </Text>
    </div>
  );
};
