import { ModalLike } from 'common/components/modal';
import { UnauthLayout } from 'modules/layout';
import { Login as LoginFeature } from 'modules/login';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const Login: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.login')}</title>
      </Helmet>
      <UnauthLayout>
        <ModalLike>
          <LoginFeature />
        </ModalLike>
      </UnauthLayout>
    </>
  );
};
