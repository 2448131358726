import { InputWithHeader } from 'common/components/input/InputWithHeader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Text } from 'common/components/Text';
import { MAX_INPUT_CHARACTERS_LIMIT_EXTRA_LONG } from 'common/consts/consts';
import { NetworkEnum } from 'common/types';
import { networkKeys } from 'common/utils/network-enum-utils';
import { validateLengthExtraLong } from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { NetworkSelector } from './NetworkSelector';
import styles from './StepAddress.module.scss';
import { BackButton, GoToSummaryButton } from 'common/components/button';

type Props = {
  address: string;
  onAddressChange: (address: string) => void;
  network: NetworkEnum | undefined;
  onNetworkChange: (network: NetworkEnum) => void;
  onGoNext: () => void;
  onGoBack: () => void;
};

export const StepAddress: React.FC<Props> = ({
  address,
  onAddressChange,
  network,
  onNetworkChange,
  onGoNext,
  onGoBack,
}) => {
  const formatMessage = useFormatMessage();

  const [networkError, setNetworkError] = useState('');
  const [addressError, setAddressError] = useState('');

  const validateAndSubmit = () => {
    if (!network) {
      setNetworkError(formatMessage('common.networkRequired'));
      return;
    }
    if (!address.trim()) {
      setAddressError(formatMessage('addressWhitelist.errors.addressRequired'));
      return;
    }
    if (!validateLengthExtraLong(address.trim())) {
      setAddressError(
        formatMessage('common.error.characters.limit', {
          limit: MAX_INPUT_CHARACTERS_LIMIT_EXTRA_LONG,
        })
      );
      return;
    }
    setNetworkError('');
    setAddressError('');
    onGoNext();
  };

  return (
    <>
      <ModalHeader>
        {formatMessage('addressWhitelist.addWhitelistedAddress')}
      </ModalHeader>
      <ModalBody>
        <Text style='xbold1417' className={styles.networkSelectorsHeader}>
          {formatMessage('common.selectNetwork')}
        </Text>
        <div className={styles.networkSelectorsContainer}>
          <div className={styles.networkSelectors}>
            {networkKeys.map((availableNetwork) => (
              <NetworkSelector
                key={availableNetwork}
                network={availableNetwork}
                selected={network === availableNetwork}
                onSelect={() => {
                  setNetworkError('');
                  onNetworkChange(availableNetwork);
                }}
                error={!!networkError}
              />
            ))}
          </div>
          {networkError && (
            <Text style='light1215' colorStyle='error' dataTest='errorText'>
              {formatMessage('common.networkRequired')}
            </Text>
          )}
        </div>
        <InputWithHeader
          header={formatMessage('common.address')}
          value={address}
          onChange={(e) => {
            const value = e.target.value;
            if (!value.trim()) {
              setAddressError(
                formatMessage('addressWhitelist.errors.addressRequired')
              );
            } else if (!validateLengthExtraLong(value.trim())) {
              setAddressError(
                formatMessage('common.error.characters.limit', {
                  limit: MAX_INPUT_CHARACTERS_LIMIT_EXTRA_LONG,
                })
              );
            } else {
              setAddressError('');
            }
            onAddressChange(value);
          }}
          autofocus={true}
          onEnter={validateAndSubmit}
          errorText={addressError}
          dataTest='addressInput'
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <GoToSummaryButton onClick={validateAndSubmit} />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
