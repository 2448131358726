import clsx from 'clsx';
import { Text } from 'common/components/Text';
import styles from './SectionSubheader.module.scss';
import { InfoTooltip } from './InfoTooltip';

type Props = {
  children?: React.ReactNode;
  className?: string;
  tooltip?: string;
};

export const SectionSubheader: React.FC<Props> = ({
  children,
  className,
  tooltip,
}) => {
  if (!tooltip) {
    return (
      <Text className={clsx(styles.container, className)} style='xbold1417'>
        {children}
      </Text>
    );
  }
  return (
    <div className={clsx(styles.container, className)}>
      <Text style='xbold1417'>{children}</Text>
      <InfoTooltip text={tooltip} />
    </div>
  );
};
