import { Alert } from 'common/components/Alert';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { Text } from 'common/components/Text';
import { NetworkEnum } from 'common/types';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { useFormatMessage } from 'modules/messages';
import { WalletVisual } from './WalletVisual';
import { BackButton, Button } from 'common/components/button';

type Props = {
  network: NetworkEnum;
  walletName: string;
  onGoBack: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  error?: string;
};

export const StepReview: React.FC<Props> = ({
  network,
  walletName,
  onGoBack,
  onSubmit,
  isLoading,
  error,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader
        subtitle={
          <Text style='xxbold1619'>{formatMessage('wallets.summary')}</Text>
        }
      >
        {formatMessage('wallets.createWallet')}
      </ModalHeader>
      <ModalBody>
        {error && (
          <div style={{ marginBottom: '40px' }}>
            <Alert text={error} severity={'error'} />
          </div>
        )}
        <WalletVisual walletName={walletName} network={network} />
        <Summary dataTest='walletCreationSummary'>
          <SummaryLabel>{formatMessage('common.walletName')}</SummaryLabel>
          <SummaryValue dataTest='walletAddressInSummary'>
            {walletName}
          </SummaryValue>
          <SummaryLabel>{formatMessage('common.network')}</SummaryLabel>
          <SummaryValue dataTest='networkInSummary'>
            {displayNetwork(network)}
          </SummaryValue>
        </Summary>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            type='dark'
            onClick={onSubmit}
            name={formatMessage('common.create')}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
