import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { Button, CancelButton } from 'common/components/button';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import { TextAreaWithHeader } from 'common/components/input/TextAreaWithHeader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import {
  MAX_INPUT_CHARACTERS_LIMIT_LONG,
  MAX_INPUT_CHARACTERS_LIMIT_MESSAGE,
  MAX_INPUT_CHARACTERS_LIMIT_SHORT,
} from 'common/consts/consts';
import { prettifyError } from 'common/utils/prettify-error';
import { ERR_EMAIL_EXISTS, ERR_EMAIL_INVALID } from 'modules/api';
import {
  validateEmail,
  validateLengthLong,
  validateLengthMessage,
  validateLengthShort,
} from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { InviteResponseError } from '../api';

type InviteMembersFormModalProps = {
  error?: AxiosError<InviteResponseError> | string;
  isLoading: boolean;
  onClick: ({
    name,
    surname,
    email,
    message,
  }: {
    name: string;
    surname: string;
    email: string;
    message: string;
  }) => void;
  onCancel: () => void;
};

export const InviteMembersUserData = ({
  onClick,
  error,
  isLoading,
  onCancel,
}: InviteMembersFormModalProps) => {
  const formatMessage = useFormatMessage();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [errorName, setErrorName] = useState('');
  const [errorSurname, setErrorSurname] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const invite = () => {
    const nameTrim = name.trim();
    const surnameTrim = surname.trim();
    const emailTrim = email.trim();
    const messageTrim = message.trim();

    if (!nameTrim) {
      setErrorName(formatMessage('common.fieldRequired'));
      return;
    } else if (errorName) {
      return;
    } else {
      setErrorName('');
    }
    if (!surnameTrim) {
      setErrorSurname(formatMessage('common.fieldRequired'));
      return;
    } else if (errorSurname) {
      return;
    } else {
      setErrorSurname('');
    }

    if (!emailTrim) {
      setErrorEmail(formatMessage('common.fieldRequired'));
      return;
    } else if (!validateEmail(emailTrim)) {
      setErrorEmail(formatMessage('common.error.input.validation.email'));
      return;
    } else if (errorEmail) {
      return;
    } else {
      setErrorEmail('');
    }

    if (!validateEmail(emailTrim)) {
      setErrorEmail(formatMessage('common.error.input.validation.email'));
      return;
    }

    if (!validateLengthMessage(message)) {
      setErrorMessage(
        formatMessage('common.error.characters.limit', {
          limit: MAX_INPUT_CHARACTERS_LIMIT_MESSAGE,
        })
      );
      return;
    } else {
      setErrorMessage('');
    }

    onClick({
      email: emailTrim,
      surname: surnameTrim,
      message: messageTrim,
      name: nameTrim,
    });
  };

  const getErrorText = (error: AxiosError<InviteResponseError>) => {
    if (typeof error.response?.data === 'object') {
      if (error.response.data.email?.includes(ERR_EMAIL_INVALID)) {
        return formatMessage('login.error.invalidEmail');
      }
      if (error.response.data.email?.includes(ERR_EMAIL_EXISTS)) {
        return formatMessage('common.error.input.validation.email.exists');
      }
    }
    return prettifyError(error);
  };

  return (
    <>
      <ModalHeader>{formatMessage('inviteMember')}</ModalHeader>
      <ModalBody>
        {error && (
          <div style={{ marginBottom: '40px' }}>
            <Alert
              text={typeof error === 'string' ? error : getErrorText(error)}
              severity={'error'}
            />
          </div>
        )}
        <InputWithHeader
          header={formatMessage('common.name')}
          value={name}
          onChange={(e) => {
            const data = e.target.value;
            if (!validateLengthShort(data)) {
              setErrorName(
                formatMessage('common.error.characters.limit', {
                  limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
                })
              );
            } else {
              setErrorName('');
            }
            setName(data);
          }}
          errorText={errorName}
          dataTest='nameField'
        />
        <InputWithHeader
          header={formatMessage('common.surname')}
          value={surname}
          onChange={(e) => {
            const data = e.target.value;
            if (!validateLengthShort(data)) {
              setErrorSurname(
                formatMessage('common.error.characters.limit', {
                  limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
                })
              );
            } else {
              setErrorSurname('');
            }
            setSurname(data);
          }}
          errorText={errorSurname}
          dataTest='surnameField'
        />
        <InputWithHeader
          header={formatMessage('common.email')}
          value={email}
          onChange={(e) => {
            const data = e.target.value;
            if (!validateLengthLong(data)) {
              setErrorEmail(
                formatMessage('common.error.characters.limit', {
                  limit: MAX_INPUT_CHARACTERS_LIMIT_LONG,
                })
              );
            } else {
              setErrorEmail('');
            }
            setEmail(data);
          }}
          errorText={errorEmail}
          type='email'
          dataTest='emailField'
        />
        <TextAreaWithHeader
          title={formatMessage('inviteMembers.input.message')}
          value={message}
          onChange={(e) => {
            const data = e.target.value;
            if (!validateLengthMessage(data)) {
              setErrorMessage(
                formatMessage('common.error.characters.limit', {
                  limit: MAX_INPUT_CHARACTERS_LIMIT_MESSAGE,
                })
              );
            } else {
              setErrorMessage('');
            }
            setMessage(e.target.value);
          }}
          placeholder={formatMessage('inviteMembers.input.message.placeholder')}
          rows={2}
          errorText={errorMessage}
          dataTest='messageField'
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <Button
            name={formatMessage('companyMembers.invite')}
            type='dark'
            onClick={invite}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
