import { WarningAlert } from 'common/components/WarningAlert';
import { useFormatMessage } from 'modules/messages';
import { useIsKyxCompleted, useCompanyKyxState } from './hooks';

type Props = {
  className?: string;
};

export const KyxAlert: React.FC<Props> = ({ className }) => {
  const formatMessage = useFormatMessage();

  const isKyxCompleted = useIsKyxCompleted();

  const { data } = useCompanyKyxState();

  if (!data) {
    return null;
  }

  if (isKyxCompleted) {
    return null;
  }

  const { kyb, kyc } = data;

  const header =
    kyc === 'INITIAL'
      ? formatMessage('kyx.completeKycToUnlockThisFeature')
      : formatMessage('kyx.completeKybToUnlockThisFeature');

  const body =
    kyc === 'INITIAL'
      ? formatMessage('kyx.inOrderToPerformActionPleaseFinishKyc')
      : kyb === 'INITIAL'
      ? formatMessage('kyx.inOrderToPerformActionPleaseFinishKyb')
      : kyb === 'PENDING'
      ? formatMessage('kyx.kybHasToBeCompletedWaitForStatusChange')
      : formatMessage(
          'kyx.yourKybFailedYouCannotPerformThisActionContactSupport'
        );

  return <WarningAlert className={className} header={header} body={body} />;
};
