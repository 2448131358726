import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './CellStatus.module.scss';
import { ReactComponent as CompletedIcon } from 'common/assets/dots/completed.svg';
import { ReactComponent as FailedIcon } from 'common/assets/dots/failed.svg';
import { ReactComponent as PendingIcon } from '../assets/pending.svg';
import { TransactionState } from '../types';

type Props = {
  status: TransactionState;
  dataTest?: string;
};

export const CellStatus: React.FC<Props> = ({ status, dataTest }) => {
  const formatMessage = useFormatMessage();

  const displayStatus = (status: TransactionState) => {
    switch (status) {
      case 'PENDING':
        return (
          <div className={styles.status}>
            <PendingIcon />
            {formatMessage('transactions.pending')}
          </div>
        );
      case 'FAILED':
        return (
          <div className={styles.status}>
            <FailedIcon />
            {formatMessage('common.failed')}
          </div>
        );
      case 'COMPLETED':
        return (
          <div className={styles.status}>
            <CompletedIcon />
            {formatMessage('common.completed')}
          </div>
        );
      case 'EXPIRED':
        return (
          <div className={styles.status}>
            <FailedIcon />
            {formatMessage('transactions.expired')}
          </div>
        );
      case 'REJECTED':
        return (
          <div className={styles.status}>
            <FailedIcon />
            {formatMessage('transactions.rejected')}
          </div>
        );
      default:
        return status;
    }
  };

  return (
    <div data-test={dataTest}>
      <Text style='light1425'>{displayStatus(status)}</Text>
    </div>
  );
};
