import { Button } from 'common/components/button';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useKyxContext } from './context';
import { useCompanyKyxState } from './hooks';
import styles from './KyxBar.module.scss';

type Props = {};

export const KyxBar: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const { showCompleteKybModal, showCompleteKycModal } = useKyxContext();

  const { data } = useCompanyKyxState();

  const text =
    data?.kyc === 'INITIAL'
      ? formatMessage('kyx.pleaseFinishKycToUnlockFeatures')
      : data?.kyb === 'INITIAL'
      ? formatMessage('kyx.pleaseFinishKybToUnlockFeatures')
      : '';

  return (
    <div className={styles.container}>
      <Text style='xbold1425'>{text}</Text>
      {data?.kyc === 'INITIAL' ? (
        <Button
          type='darkSmall'
          name={formatMessage('kyx.completeKyc')}
          onClick={showCompleteKycModal}
        />
      ) : data?.kyb === 'INITIAL' ? (
        <Button
          type='darkSmall'
          name={formatMessage('kyx.completeKyb')}
          onClick={showCompleteKybModal}
        />
      ) : undefined}
    </div>
  );
};
