import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { get, LONG_STALE_TIME, post } from 'modules/api';

export type SendInvitationCodePayload = {
  invitation_code: string;
};

export type GetInviteeDataResponse = {
  name: string;
  surname: string;
  email: string;
  company_name: string;
};

export type GetInviteeDataError = {
  invitation_code?: string[];
};

export type SignupFromInvitationCodePayload = {
  invitation_code: string;
  name: string;
  surname: string;
  password: string;
};

export type SignupFromInvitationCodeResponse = {};

export type SignupFromInvitationCodeError = {
  non_field_errors?: string[];
  status?: string;
  detail?: string;
};

const SIGNUP_FROM_INVITATION_QUERY_KEY = '/register-from-invitation';
const SIGNUP_FROM_INVITATION_URL = '/register-from-invitation';

export const getInviteeData = (invitation_code: string) =>
  get<GetInviteeDataResponse>(
    `${SIGNUP_FROM_INVITATION_URL}/${invitation_code}`
  );

export const useGetInviteeDataQuery = (
  invitation_code: string,
  options?: UseQueryOptions<
    AxiosResponse<GetInviteeDataResponse>,
    AxiosError<GetInviteeDataError>
  >
) => {
  const query = useQuery<
    AxiosResponse<GetInviteeDataResponse>,
    AxiosError<GetInviteeDataError>
  >(
    ['GET', SIGNUP_FROM_INVITATION_QUERY_KEY, invitation_code],
    () => {
      return getInviteeData(invitation_code);
    },
    { staleTime: LONG_STALE_TIME, ...options }
  );
  return query;
};

export const signupFromInvitationCode = (
  payload: SignupFromInvitationCodePayload
) =>
  post<SignupFromInvitationCodeResponse, SignupFromInvitationCodePayload>(
    SIGNUP_FROM_INVITATION_URL,
    payload
  );

export const useSignupFromInvitationCodeMutation = () => {
  const mutation = useMutation<
    AxiosResponse<SignupFromInvitationCodeResponse>,
    AxiosError<SignupFromInvitationCodeError>,
    SignupFromInvitationCodePayload
  >(['POST', SIGNUP_FROM_INVITATION_URL], (payload) =>
    signupFromInvitationCode(payload)
  );
  return mutation;
};

export const ERR_INVITATION_CODE_EXPIRED = 'Invitation with this code expired.';
export const ERR_INVITATION_CODE_USED =
  'Invitation with this code has already been used.';
export const ERR_USER_ALREADY_EXISTS = 'User already exists.';
