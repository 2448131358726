import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { FiatAmount } from 'modules/fiat/FiatAmount';
import { useWalletsTotalValue } from '../hooks';

export const TotalAssetFiatValue = () => {
  const formatMessage = useFormatMessage();

  const { data: totalFiatValue, isLoading } = useWalletsTotalValue();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text style='light1215'>{formatMessage('wallets.totalAssetValue')}</Text>
      <FiatAmount
        amount={totalFiatValue}
        isDataLoading={isLoading}
        textStyle='xbold2028'
        textColorStyle={'primary'}
        loaderSize={20}
        loaderContainerSize={28}
      />
    </div>
  );
};
