import { Text } from 'common/components/Text';
import { Tooltip } from 'common/components/Tooltip';
import { Button } from 'common/components/button';
import { toFixed } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BorrowRepay } from '../../borrow-repay/BorrowRepay';
import { BorrowRead } from '../../types';
import styles from './BorrowBox.module.scss';
import { BorrowName } from './BorrowName';
import { BorrowStatePill, stateToMessageKey } from './BorrowStatePill';
import { CollateralAmount } from './CollateralAmount';
import { HorizontalHealthIndicator } from './HorizontalHealthIndicator';
import { Maturity } from './Maturity';
import { PrincipalAmount } from './PrincipalAmount';
import { RepaymentPill } from './RepaymentPill';
import { DetailsButton } from 'modules/financial-ops/common/components/DetailsButton';

type Props = {
  borrow: BorrowRead;
};

export const BorrowBox: React.FC<Props> = ({ borrow }) => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();
  const [openRepay, setOpenRepay] = useState(false);

  const {
    state,
    can_be_repaid,
    principal_amount,
    principal_currency,
    principal_currency_rate,
    collateral_amount,
    collateral_currency,
    collateral_currency_rate,
    end_at,
    ltv_pct,
    id,
    has_pending_repay_transaction,
  } = borrow;

  const isFinished =
    state === 'COMPLETED' || state === 'LIQUIDATED' || state === 'CANCELLED';

  return (
    <div className={styles.container} data-test='borrowBox'>
      <div className={styles.header}>
        <div className={styles.nameAndState}>
          <BorrowName borrow={borrow} dataTest='borrowName' />
          <div className={styles.state}>
            <BorrowStatePill state={state} />
            {can_be_repaid && <RepaymentPill />}
          </div>
        </div>
        <div className={styles.buttons}>
          <DetailsButton
            path={`/borrows/${id}`}
            className={styles.buttonWidth}
          />
          {can_be_repaid && (
            <Tooltip
              title={
                has_pending_repay_transaction
                  ? formatMessage('borrow.borrowRepaymentTransactionIsPending')
                  : ''
              }
            >
              <span>
                <Button
                  type='darkSmall'
                  name={formatMessage('borrow.repay')}
                  onClick={() => setOpenRepay(true)}
                  disabled={has_pending_repay_transaction}
                  className={styles.buttonWidth}
                />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.detailField}>
          <PrincipalAmount
            amount={principal_amount}
            currency={principal_currency}
            currencyRate={principal_currency_rate}
            showFiat={!isFinished}
          />
        </div>
        <div className={styles.detailField}>
          <Text style='light1215'>
            {formatMessage('finopsCommon.maturity') + ':'}
          </Text>
          <Maturity
            end_at={end_at}
            state={state}
            can_be_repaid={can_be_repaid}
            has_pending_repay_transaction={has_pending_repay_transaction}
          />
        </div>
        <div className={styles.detailField}>
          <CollateralAmount
            amount={collateral_amount}
            currency={collateral_currency}
            currencyRate={collateral_currency_rate}
            showFiat={!isFinished}
          />
        </div>
        {!isFinished && (
          <div className={styles.detailField}>
            <Text style='light1215'>{formatMessage('borrow.ltv') + ':'}</Text>
            {state === 'LIQUIDATING' ? (
              <div style={{ height: '19px' }} />
            ) : (
              <Text
                style='xxbold1619'
                letterSpacingStyle='small'
                dataTest='ltvRatio'
              >{`${toFixed(ltv_pct)}%`}</Text>
            )}
            <HorizontalHealthIndicator pct={ltv_pct} />
            <Text style='light1215' dataTest='stateUnderHorizontalsScale'>
              {formatMessage(stateToMessageKey(state))}
            </Text>
          </div>
        )}
      </div>
      <div className={styles.marginTop}>
        <Text style='light1215' colorStyle='secondary' dataTest='borrowId'>
          {formatMessage('common.id', { id })}
        </Text>
      </div>
      <BorrowRepay
        open={openRepay}
        onClose={() => setOpenRepay(false)}
        id={id}
      />
    </div>
  );
};
