import styles from './StepBorrowTopup.module.scss';
import { Button, CancelButton } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';

import { Loader } from 'common/components/loader';
import { Alert } from 'common/components/Alert';
import { Text } from 'common/components/Text';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { useBorrowTopupContext } from '../context';
import { BorrowDetails, BorrowReadStateEnum } from '../../types';
import { BorrowTopupEffect } from './BorrowTopupEffect';
import { AmountInput } from './AmountInput';

type Props = {
  amount: string;
  errorAmount: string;
  onSummary: () => void;
  currency?: Currency;
  wallet?: CurrencyWallet;
  borrow?: BorrowDetails;
  newCollateralAmount?: string;
  currentCollateralAmount?: string;
  newLtvRatio?: string;
  currentLtvRatio?: string;
  newState?: BorrowReadStateEnum;
  currentState?: BorrowReadStateEnum;
  amountInfo: string;
  onCancel: () => void;
  onAmountChange: (amount: string) => void;
  onMax: () => void;
  isLoading: boolean;
};

export const StepBorrowTopup: React.FC<Props> = ({
  amount,
  errorAmount,
  onSummary,
  currency,
  wallet,
  borrow,
  newCollateralAmount,
  currentCollateralAmount,
  newLtvRatio,
  currentLtvRatio,
  newState,
  currentState,
  amountInfo,
  onCancel,
  onMax,
  onAmountChange,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();
  const context = useBorrowTopupContext();

  return (
    <>
      <ModalHeader
        subtitle={
          <Text style='xxbold1619'>{formatMessage('borrow.topUpBorrow')}</Text>
        }
      >
        {formatMessage('borrow.topUpCollateral')}
      </ModalHeader>
      <ModalBody>
        {currency && wallet && borrow && (
          <>
            {context.errorAlert && (
              <Alert
                text={context.errorAlert}
                severity='error'
                className={styles.alert}
              />
            )}
            <SectionSubheader>
              {formatMessage('common.amount')}
            </SectionSubheader>
            <AmountInput
              amount={amount}
              currency={currency}
              error={errorAmount}
              wallet={wallet}
              onAmountChange={(value) => {
                context.setErrorAlert('');
                context.setAmountInfo('');

                onAmountChange(value);
                context.setAmount(value);
              }}
              onMax={() => {
                onMax();
              }}
              info={amountInfo}
              onError={(error) => context.setAmountError(error)}
            />
            <BorrowTopupEffect
              currency={currency}
              isLoading={isLoading}
              currentCollateralAmount={currentCollateralAmount}
              newCollateralAmount={newCollateralAmount}
              newLtvRatio={newLtvRatio}
              currentLtvRatio={currentLtvRatio}
              newState={newState}
              currentState={currentState}
            />
          </>
        )}
        {!currency && <Loader />}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <Button
            type='dark'
            onClick={onSummary}
            name={formatMessage('common.goToSummary')}
            disabled={!!context.errorAlert || !!errorAmount || isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
