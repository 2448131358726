import { LoaderBox } from 'common/components/loader';
import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { useFormatMessage } from 'modules/messages';
import { APRProducts } from '../common/components/APRProducts';
import { Borrows } from './borrow-list/Borrows';
import { BorrowOverview } from './borrow-overview';
import { NewBorrowButton } from './new-borrow/NewBorrowButton';

export const BorrowDashboard: React.FC = () => {
  const formatMessage = useFormatMessage();

  const featureFlag = useRequireFeatureFlag('BORROW');

  if (featureFlag.isLoading) {
    return <LoaderBox />;
  }

  if (!featureFlag.data?.enabled) {
    return null;
  }

  return (
    <Section>
      <SectionHeader
        header={formatMessage('borrow.borrowOverview')}
        actions={<NewBorrowButton />}
        subheader={formatMessage(
          'borrow.useYourFundsAsCollateralToBorrowStablecoins'
        )}
      />
      <BorrowOverview />
      <APRProducts kind='BORROW' />
      <Borrows />
    </Section>
  );
};
