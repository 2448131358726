import { TransactionDetails } from 'modules/transactions/types';
import { Creator } from './components/Creator';
import { Multiapproval } from './components/Multiapproval';
import { MultiapprovalList } from './components/MultiapprovalList';
import { StatusReason } from './components/StatusReason';

type Props = {
  transaction: TransactionDetails;
  className?: string;
  isDetailsPage?: boolean;
};

export const ApprovalsBox: React.FC<Props> = ({
  transaction,
  className,
  isDetailsPage = false,
}) => {
  const {
    kind,
    owner_action,
    threshold,
    approvals_count,
    state,
    substate,
    id,
    created_by,
  } = transaction;

  const showApprovals = [
    'WITHDRAWAL',
    'LOAN_BORROW',
    'LOAN_LEND',
    'LOAN_LEND_TERMINATION',
    'LOAN_BORROW_REPAY',
    'TRADE_OUT',
    'SAVING',
    'SAVING_TOPUP',
    'SAVING_WITHDRAWAL',
    'CHARITY_WITHDRAWAL_OUT',
    'LOAN_COLLATERAL_TOPUP',
  ].includes(kind);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
      className={className}
    >
      {substate && <StatusReason state={state} substate={substate} />}
      {showApprovals && (
        <>
          <Multiapproval
            owner_action={owner_action}
            threshold={threshold}
            approvals={approvals_count}
          />
          {isDetailsPage && <MultiapprovalList id={id} />}
          <Creator creator={created_by} />
        </>
      )}
    </div>
  );
};
