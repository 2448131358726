import { Link } from 'common/components/Link';
import { useFormatMessage } from 'modules/messages';

export const ForgotPasswordLink = () => {
  const formatMessage = useFormatMessage();
  return (
    <Link to='/reset-password'>
      {formatMessage('login.link.forgotPassword')}
    </Link>
  );
};
