import { format, parseISO } from 'date-fns';
import { FormatMessageFn } from 'modules/messages';

export const DATETIME_FORMAT = 'dd.MM.yyy HH:mm:ss';
export const DATETIME_WITHOUT_SEC_FORMAT = 'dd.MM.yyy HH:mm';
export const DATE_FORMAT = 'dd.MM.yyy';
export const DATE_WITHOUT_YEAR_FORMAT = 'dd.MM';
export const TIME_TZ_FORMAT = "HH:mm:ss ('UTC'xxx)";
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_WITHOUT_SEC_FORMAT = 'HH:mm';

export const NUMBER_OF_LETTERS_LONG = 20;
export const NUMBER_OF_LETTERS_SHORT = 8;
const COMMON_SECOND = 'second';

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const getLocationState = (location: any) => {
  if (location.state) {
    return location.state;
  }
  return {};
};

export const formatDatetime = (date: string) =>
  format(parseISO(date), DATETIME_FORMAT);

export const formatDatetimeWithoutSec = (date: string) =>
  format(parseISO(date), DATETIME_WITHOUT_SEC_FORMAT);

export const formatDate = (date: string) => format(parseISO(date), DATE_FORMAT);

export const formatDateWithoutYear = (date: string) =>
  format(parseISO(date), DATE_WITHOUT_YEAR_FORMAT);

export const formatTimeWithoutSec = (date: string) =>
  format(parseISO(date), TIME_WITHOUT_SEC_FORMAT);

export const generateLimitSendingEmailError = (
  text: string,
  textFirstPart: string,
  formatMessage: FormatMessageFn
) => {
  const textArray = text.split(' ');
  const time = parseInt(textArray.at(-2) ?? '');
  const minuteOrSecond = textArray.at(-1);

  return `${textFirstPart} ${formatMessage(
    minuteOrSecond?.includes(COMMON_SECOND)
      ? 'common.seconds'
      : 'common.minutes',
    { time: isNaN(time) ? 1 : time }
  )}.`;
};
