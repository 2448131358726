import boxesIcon from 'common/assets/boxes.svg';
import btcIcon from 'common/assets/tokens/grey-4b4b4b/btc.svg';
import ethIcon from 'common/assets/tokens/grey-4b4b4b/eth.svg';
import otherAssetIcon from 'common/assets/tokens/grey-4b4b4b/other.svg';
import usdcIcon from 'common/assets/tokens/grey-4b4b4b/usdc.svg';
import usdtIcon from 'common/assets/tokens/grey-4b4b4b/usdt.svg';
import wbtcIcon from 'common/assets/tokens/grey-4b4b4b/wbtc.svg';
import { displayNetwork } from 'common/utils/network-enum-utils';
import * as d3 from 'd3';
import Decimal from 'decimal.js';
import { toDecimal, toFixed } from 'modules/input-amount';
import { useEffect } from 'react';
import styles from './BubbleChart.module.scss';

// https://codepen.io/cobralina/pen/BaBMLNx

// TODO: switch to TS

const iconMap = {
  BTC: btcIcon,
  ETH: ethIcon,
  USDC: usdcIcon,
  USDT: usdtIcon,
  WBTC: wbtcIcon,
  Others: otherAssetIcon,
};

const CHART_SIZE_SINGLE = 250; // in px, when only one bubble
const CHART_SIZE = 335; // in px
const TOOLTIP_SIZE = 185; // in px; includes padding; so in css width 150px and padding left and right 10px

const AGGREGATE_THRESHOLD = 5; // number of currencies

const MIN_PCT = '1.5'; // percent

const ICON_SIZE = 0.5; // in proportion to bubble radius
const TEXT_SIZE = 0.15; // in proportion to bubble radius
const VMODIFIER = 0.15; // vertical centering modifier; in proportion to bubble radius

const MIN_ICON_SIZE = 20; // in px
const MIN_TEXT_SIZE = 10; // in px
const MIN_VMODIFIER = 4; // in px

const HIDE_PCT_THRESHOLD = 10; // in percent

export const BubbleChart = ({ items, id }) => {
  // typeof items === LendSummary['current_principal_assets'] or BorrowSummary['current_collateral_assets']

  useEffect(() => {
    const sortedItems = items
      .sort((a, b) =>
        toDecimal(b.share_pct).sub(toDecimal(a.share_pct)).toNumber()
      )
      .filter((item) => toDecimal(item.share_pct).greaterThan(0));

    let bubbles;
    if (sortedItems.length <= AGGREGATE_THRESHOLD + 1) {
      bubbles = sortedItems;
    } else {
      bubbles = sortedItems.slice(0, AGGREGATE_THRESHOLD);

      bubbles.push({
        share_pct: sortedItems
          .slice(AGGREGATE_THRESHOLD)
          .reduce((acc, item) => acc.add(item.share_pct), new Decimal(0)),
        currency: 'Others',
        assets: sortedItems.slice(AGGREGATE_THRESHOLD),
      });
    }

    const dataset = {
      children: bubbles,
    };

    var effectiveChartSize =
      bubbles.length === 1 ? CHART_SIZE_SINGLE : CHART_SIZE;

    var bubble = d3
      .pack(dataset)
      .size([effectiveChartSize, effectiveChartSize])
      .padding(15);

    var root = d3.select(`#${id}`);
    root.select('svg').remove();

    var svg = d3
      .select(`#${id}`)
      .append('svg')
      .attr('width', effectiveChartSize)
      .attr('height', effectiveChartSize)
      .attr('class', 'bubble');

    // <defs>
    //   <linearGradient id='bubbleGradient' x1='0' x2='0' y1='0' y2='1'>
    //     <stop offset='0%' stopColor='#EDE300' />
    //     <stop offset='100%' stopColor='#FFD88D' />
    //   </linearGradient>
    // </defs>
    // linear-gradient(180deg, #EDE300 0%, #FFD88D 100%)
    var gradient = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'bubbleGradient')
      .attr('x1', '0')
      .attr('x2', '0')
      .attr('y1', '0')
      .attr('y2', '1');
    gradient.append('stop').attr('offset', '0%').attr('stop-color', '#ede300');
    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#ffd88d');

    var nodes = d3.hierarchy(dataset).sum(function (d) {
      return Math.max(d.share_pct, MIN_PCT); // all pct <1.5% are rounded to and displayed as 1.5%
    });

    var node = svg
      .selectAll('.node')
      .data(bubble(nodes).descendants())
      .enter()
      .filter(function (d) {
        return !d.children;
      })
      .append('g')
      .attr('class', 'node')
      .attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });

    node
      .append('circle')
      .attr('r', function (d) {
        return d.r;
      })
      .attr('class', 'circle')
      .style('fill', 'url(#bubbleGradient)');

    node
      .append('image')
      .attr('href', function (d) {
        return iconMap[d.data.currency];
      })
      .attr('x', function (d) {
        return Math.min(-0.5 * d.r * ICON_SIZE, -0.5 * MIN_ICON_SIZE);
      })
      .attr('y', function (d) {
        const hidePct = toDecimal(d.data.share_pct).lessThan(
          HIDE_PCT_THRESHOLD
        );
        return Math.min(
          -0.5 * d.r * (ICON_SIZE + (hidePct ? 0 : VMODIFIER)),
          -0.5 * (MIN_ICON_SIZE + (hidePct ? 0 : MIN_VMODIFIER))
        );
      })
      .attr('height', function (d) {
        return Math.max(d.r * ICON_SIZE, MIN_ICON_SIZE);
      })
      .attr('width', function (d) {
        return Math.max(d.r * ICON_SIZE, MIN_ICON_SIZE);
      });

    node
      .append('text')
      .attr('y', function (d) {
        const hidePct = toDecimal(d.data.share_pct).lessThan(
          HIDE_PCT_THRESHOLD
        );
        return Math.max(
          d.r * (TEXT_SIZE + 0.5 * (ICON_SIZE - (hidePct ? 0 : VMODIFIER))),
          0.5 * MIN_ICON_SIZE - (hidePct ? 0 : MIN_VMODIFIER) + MIN_TEXT_SIZE
        );
      })
      .attr('x', 0)
      .style('text-anchor', 'middle')
      .text(function (d) {
        if (toDecimal(d.data.share_pct).lessThan(HIDE_PCT_THRESHOLD)) {
          return '';
        }
        return `${d.data.share_pct}%`;
      })
      .attr('font-weight', 'bold')
      .attr('font-family', 'Inter')
      .attr('font-size', function (d) {
        return Math.max(d.r * TEXT_SIZE, MIN_TEXT_SIZE);
      })
      .attr('fill', 'rgba(62,62,62,0.4)')
      .attr('inline-size', 80)
      .attr('class', 'circletxt');

    node.on('mouseover', function (event, d) {
      var g = d3.select(this); // The node

      const assets = d.data.assets ?? [d.data];

      var div = d3
        .select(`#${id}`)
        .append('div')
        .attr('pointer-events', 'none')
        .attr('class', styles.infolayer)
        .html(
          assets
            .map(({ currency, network, amount, share_pct }) => {
              return `
                <div class="${styles.assetLayout}">
                  <div class="${styles.amount}">${toFixed(amount)}</div>
                  <div>${currency}</div>
                  <div class="${styles.pct}">(${share_pct}%)</div>
                  <div class="${styles.network}">
                    <img src="${boxesIcon}"/>
                    ${displayNetwork(network)}
                  </div>
                </div>
              `;
            })
            .join('<br />')
        )
        .style(
          'left',
          d.x < TOOLTIP_SIZE ? d.x + 'px' : d.x - TOOLTIP_SIZE + 'px'
        )
        .style('top', d.y + 'px');
    });

    node.on('mouseout', function (d) {
      d3.selectAll(`.${styles.infolayer}`).remove();
    });

    // d3.select(self.frameElement).style('height', diameter + 'px');
  }, [items]);

  return <div id={id} style={{ position: 'relative' }} />;
};
