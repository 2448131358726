import { useCallback } from 'react';
import { log } from 'modules/logger';
import { WebsocketEvent } from '../types';
import { useHandleFeatureFlagUpdated } from './feature-flag-updated';
import { useHandleInterestRateDeleted } from './interest-rate-deleted';
import { useHandleInterestRateNew } from './interest-rate-new';
import { useHandleInterestRateUpdated } from './interest-rate-updated';
import { useHandleLendNew } from './lend-new';
import { useHandleLendUpdated } from './lend-updated';
import { useHandleNotificationNew } from './notification-new';
import { useHandleSettingUpdated } from './setting-updated';
import { useHandleTransactionActionNew } from './transaction-action-new';
import { useHandleTransactionNew } from './transaction-new';
import { useHandleTransactionUpdated } from './transaction-updated';
import { useHandleWalletBalanceUpdated } from './wallet-balance-updated';
import { useHandleWalletNew } from './wallet-new';

export const useHandleMessage = () => {
  const handleFeatureFlagUpdated = useHandleFeatureFlagUpdated();
  const handleInterestRateDeleted = useHandleInterestRateDeleted();
  const handleInterestRateNew = useHandleInterestRateNew();
  const handleInterestRateUpdated = useHandleInterestRateUpdated();
  const handleLendNew = useHandleLendNew();
  const handleLendUpdated = useHandleLendUpdated();
  const handleNotificationNew = useHandleNotificationNew();
  const handleSettingUpdated = useHandleSettingUpdated();
  const handleTransactionActionNew = useHandleTransactionActionNew();
  const handleTransactionNew = useHandleTransactionNew();
  const handleTransactionUpdated = useHandleTransactionUpdated();
  const handleWalletBalanceUpdated = useHandleWalletBalanceUpdated();
  const handleWalletNew = useHandleWalletNew();

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const data = JSON.parse(event.data) as WebsocketEvent;

      switch (data.kind) {
        case 'FEATURE_FLAG_UPDATED':
          handleFeatureFlagUpdated(data);
          break;
        case 'INTEREST_RATE_DELETED':
          handleInterestRateDeleted(data);
          break;
        case 'INTEREST_RATE_NEW':
          handleInterestRateNew(data);
          break;
        case 'INTEREST_RATE_UPDATED':
          handleInterestRateUpdated(data);
          break;
        case 'LEND_NEW':
          handleLendNew(data);
          break;
        case 'LEND_UPDATED':
          handleLendUpdated(data);
          break;
        case 'NOTIFICATION_NEW':
          handleNotificationNew(data);
          break;
        case 'SETTING_UPDATED':
          handleSettingUpdated(data);
          break;
        case 'TRANSACTION_ACTION_NEW':
          handleTransactionActionNew(data);
          break;
        case 'TRANSACTION_NEW':
          handleTransactionNew(data);
          break;
        case 'TRANSACTION_UPDATED':
          handleTransactionUpdated(data);
          break;
        case 'WALLET_BALANCE_UPDATED':
          handleWalletBalanceUpdated(data);
          break;
        case 'WALLET_NEW':
          handleWalletNew(data);
          break;

        default:
          log('WebSocket log msg: unhandled event:', data);
      }
    },
    [
      handleFeatureFlagUpdated,
      handleInterestRateDeleted,
      handleInterestRateNew,
      handleInterestRateUpdated,
      handleLendNew,
      handleLendUpdated,
      handleNotificationNew,
      handleSettingUpdated,
      handleTransactionActionNew,
      handleTransactionNew,
      handleTransactionUpdated,
      handleWalletBalanceUpdated,
      handleWalletNew,
    ]
  );

  return handleMessage;
};
