import montesauriLogomarkSrc from 'common/assets/logo/montesauri-logotype-dark.svg';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './PoweredByMontesauri.module.scss';

export const PoweredByMontesauri: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <Text style='light1215'>
        {formatMessage('charityForms.poweredBy')}
      </Text>
      <img
        className={styles.logo}
        src={montesauriLogomarkSrc}
        alt={'Montesauri'}
      />
    </div>
  );
};
