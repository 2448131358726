import { Alert } from 'common/components/Alert';
import { Button, BackButton } from 'common/components/button';
import { LabeledNetworkBadge } from 'common/components/LabeledNetworkBadge';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { Text } from 'common/components/Text';
import { NetworkEnum } from 'common/types';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { useFormatMessage } from 'modules/messages';

type Props = {
  name: string;
  address: string;
  network: NetworkEnum;
  onGoBack: () => void;
  onCreate: () => void;
  isLoading: boolean;
  error?: string;
};

export const StepReview: React.FC<Props> = ({
  name,
  address,
  network,
  onGoBack,
  onCreate,
  isLoading,
  error,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <ModalHeader
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('addressWhitelist.addressSummary')}
          </Text>
        }
      >
        {formatMessage('addressWhitelist.addWhitelistedAddress')}
      </ModalHeader>
      <ModalBody>
        {error && (
          <div style={{ marginBottom: '40px' }}>
            <Alert severity='error' text={error} />
          </div>
        )}
        <div style={{ marginBottom: '20px' }} data-test='nameAndNetworkRow'>
          <LabeledNetworkBadge text={name} network={network} />
        </div>
        <Summary>
          <SummaryLabel>{formatMessage('common.addressName')}</SummaryLabel>
          <SummaryValue dataTest='name'>{name}</SummaryValue>
          <SummaryLabel>{formatMessage('common.network')}</SummaryLabel>
          <SummaryValue dataTest='network'>
            {displayNetwork(network)}
          </SummaryValue>
          <SummaryLabel>{formatMessage('common.address')}</SummaryLabel>
          <SummaryValue dataTest='address'>{address}</SummaryValue>
        </Summary>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            type='dark'
            name={formatMessage('common.button.add')}
            onClick={onCreate}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
