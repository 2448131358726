import { AxiosError } from 'axios';
import D from 'decimal.js';
import { Alert } from 'common/components/Alert';
import { Button, BackButton } from 'common/components/button';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { prettifyError } from 'common/utils/prettify-error';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import styles from './StepSummary.module.scss';
import {
  WITHDRAWAL_FEE_KEY,
  Withdraw,
  WithdrawEffectPayload,
  WithdrawEffectResponseError,
  WithdrawError,
  addressCannotBeBlank,
  amountMustBeGreaterOrEqualTo,
  amountMustBeGreaterThanZero,
  notEnoughFundsToWithdraw,
  useWithdrawEffectMutation,
  useWithdrawMutation,
  whitelistedAddressHasBeenRemoved,
  whitelistedAddressNotExist,
} from '../api';
import { CryptoToFiatAmount } from 'modules/fiat';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { WhitelistedAddress } from 'modules/address-whitelist/types';
import { Text } from 'common/components/Text';
import {
  AssetAmountReadOnly,
  SnapshotAlert,
  TokenExpiryTimer,
  WalletBox,
  tokenInvalidValue,
  tokenIsRequired,
} from 'modules/financial-ops/common';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { CURRENCIES_QUERY_KEY } from 'modules/select-currency-wallet/api';

type Props = {
  amount: D;
  whitelistedAddress?: WhitelistedAddress;
  onGoBack: () => void;
  onGoNext: (transactionId: number) => void;
  initialFee: string;
  isFeeLoading: boolean;
  error: AxiosError<WithdrawError> | null;
  currency?: Currency;
  wallet?: CurrencyWallet;
  initialToken: string | undefined;
};

export const StepSummary: React.FC<Props> = ({
  amount,
  whitelistedAddress,
  onGoNext,
  onGoBack,
  initialFee,
  isFeeLoading,
  error,
  currency,
  wallet,
  initialToken,
}) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const [timeExpired, setTimeExpired] = useState(false);

  const withdrawMutation = useWithdrawMutation();
  const withdrawEffectMutation = useWithdrawEffectMutation();

  const getErrorText = (error: AxiosError<WithdrawError>) => {
    if (amountMustBeGreaterThanZero(error.response?.data)) {
      return formatMessage('inputAmount.error.amountMustBeGreaterThanZero');
    }

    const mustBeGte = amountMustBeGreaterOrEqualTo(error.response?.data);
    if (mustBeGte) {
      return formatMessage('withdraw.amountMustBeGreaterOrEqualToX', {
        amount: toFixed(mustBeGte[1]),
        currency: currency?.currency,
      });
    }

    if (addressCannotBeBlank(error.response?.data)) {
      return formatMessage('withdraw.addressCannotBeBlank');
    }

    if (notEnoughFundsToWithdraw(error.response?.data)) {
      return formatMessage('withdraw.notEnoughFundsToWithdraw');
    }

    if (whitelistedAddressNotExist(error.response?.data)) {
      return formatMessage('withdraw.whitelistedAddressDoesNotExist');
    }

    if (whitelistedAddressHasBeenRemoved(error.response?.data)) {
      return formatMessage('withdraw.whitelistedAddressHasBeenRemoved');
    }

    if (tokenIsRequired(error.response?.data)) {
      return formatMessage('common.tokenIsRequired');
    }

    return prettifyError(error);
  };

  const refetchToken = () => {
    if (!amount || !whitelistedAddress || !currency || !wallet) {
      return;
    }

    const payload: WithdrawEffectPayload = {
      currency: currency?.currency,
      amount: toDecimal(amount).toFixed(),
      whitelisted_address_id: whitelistedAddress.id,
      wallet_id: wallet?.id,
      summary: true,
    };

    withdrawEffectMutation.mutate(payload, {
      onSettled: () => {
        queryClient.invalidateQueries(WITHDRAWAL_FEE_KEY);
      },
      onError: () => {
        queryClient.invalidateQueries(CURRENCIES_QUERY_KEY);
      },
    });
  };

  const getRefetchTokenError = (
    error: AxiosError<WithdrawEffectResponseError>
  ) => {
    const mustBeGte = amountMustBeGreaterOrEqualTo(error.response?.data);
    if (mustBeGte) {
      return formatMessage('withdraw.amountMustBeGreaterOrEqualToX', {
        amount: toFixed(mustBeGte[1]),
        currency: currency?.currency,
      });
    }

    if (notEnoughFundsToWithdraw(error.response?.data)) {
      return formatMessage('withdraw.notEnoughFundsToWithdraw');
    }

    if (whitelistedAddressNotExist(error.response?.data)) {
      return formatMessage('withdraw.whitelistedAddressDoesNotExist');
    }

    if (whitelistedAddressHasBeenRemoved(error.response?.data)) {
      return formatMessage('withdraw.whitelistedAddressHasBeenRemoved');
    }

    return prettifyError(error);
  };

  const modalHeader = (
    <ModalHeader>{formatMessage('withdraw.withdrawSummary')}</ModalHeader>
  );

  // this is just an extra safe guard, in reality this error should be handled earlier and the modal should not be opened at all
  const isSummaryDataComplete = currency && wallet && initialToken;
  if (!isSummaryDataComplete) {
    return (
      <>
        {modalHeader}
        <ModalBody>
          <Alert
            severity='error'
            text={formatMessage('withdraw.summaryCannotBeDisplayed')}
          />
        </ModalBody>
      </>
    );
  }

  const token = withdrawEffectMutation.data?.data.token || initialToken;
  const fee = withdrawEffectMutation.data?.data.fee || initialFee;

  return (
    <>
      {modalHeader}
      <ModalBody>
        {withdrawMutation.isError &&
          !tokenInvalidValue(withdrawMutation.error.response?.data) && (
            <Alert
              severity='error'
              text={getErrorText(withdrawMutation.error)}
            />
          )}
        {withdrawEffectMutation.error && (
          <Alert
            severity='error'
            text={getRefetchTokenError(withdrawEffectMutation.error)}
            className={styles.error}
          />
        )}
        {amount && currency && (
          <>
            <SectionSubheader>
              {formatMessage('common.amount')}
            </SectionSubheader>
            <AssetAmountReadOnly
              amount={amount}
              currency={currency}
              className={styles.amountAssetBox}
            />
          </>
        )}
        {wallet && currency && (
          <WalletBox
            wallet={wallet}
            currency={currency.currency}
            className={styles.wallet}
          />
        )}
        <Summary>
          <SummaryLabel>{formatMessage('common.addressName')}</SummaryLabel>
          <SummaryValue
            className={styles.address}
            dataTest='summaryWithdrawAddressName'
          >
            {whitelistedAddress?.name}
          </SummaryValue>
          <SummaryLabel>{formatMessage('common.addressNo')}</SummaryLabel>
          <SummaryValue
            className={styles.address}
            dataTest='summaryWithdrawAddress'
          >
            {whitelistedAddress?.address}
          </SummaryValue>
          <SummaryLabel>{formatMessage('common.amount')}</SummaryLabel>
          <SummaryValue dataTest='summaryWithdrawAmount'>{`${toLimitedPrec(
            amount.toFixed()
          )} ${currency?.currency}`}</SummaryValue>
          <SummaryLabel>{formatMessage('withdraw.withdrawalFee')}</SummaryLabel>
          <SummaryValue dataTest='summaryFee'>
            {!isFeeLoading ? (
              `${toLimitedPrec(toFixed(fee))} ${currency?.currency}`
            ) : (
              <RotatingLoader size={12} type={'secondary'} />
            )}
          </SummaryValue>
          <SummaryLabel>{formatMessage('common.totalAmount')}</SummaryLabel>
          <SummaryValue textStyle='xbold1417' dataTest='summaryTotalAmount'>
            {!isFeeLoading ? (
              `${toLimitedPrec(toFixed(toDecimal(fee).add(amount)))} ${
                currency?.currency
              }`
            ) : (
              <RotatingLoader size={12} type={'secondary'} />
            )}
          </SummaryValue>
          <SummaryLabel>
            {formatMessage('withdraw.totalAmountFiat')}
          </SummaryLabel>
          <SummaryValue dataTest='summaryTotalAmountFiat'>
            <CryptoToFiatAmount
              amount={!isFeeLoading ? toDecimal(fee).add(amount) : undefined}
              currency={currency?.currency}
              isDataLoading={isFeeLoading}
              textStyle='xbold1417'
              textColorStyle='primary'
            />
          </SummaryValue>
        </Summary>
        {(tokenInvalidValue(error?.response?.data) || timeExpired) && (
          <SnapshotAlert
            onRefresh={() => {
              refetchToken();
              setTimeExpired(false);
              withdrawMutation.reset();
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            type='dark'
            onClick={() => {
              if (currency && wallet && whitelistedAddress) {
                const payload: Withdraw = {
                  currency: currency?.currency,
                  amount: `${amount}`,
                  whitelisted_address_id: whitelistedAddress.id,
                  wallet_id: wallet?.id,
                  token,
                };

                withdrawMutation.mutate(payload, {
                  onSuccess: (res) => {
                    onGoNext(res.data.transaction_id);
                    queryClient.invalidateQueries(
                      DASHBOARD_TRANSACTIONS_QUERY_KEY
                    );
                    queryClient.invalidateQueries(
                      TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
                    );
                  },
                });
              }
            }}
            name={
              <div className={styles.button}>
                <Text style='xbold1417'>
                  {formatMessage('common.withdraw')}
                </Text>
                {!withdrawEffectMutation.error && (
                  <TokenExpiryTimer
                    token={token}
                    onTimeExpired={() => setTimeExpired(true)}
                  />
                )}
              </div>
            }
            isLoading={withdrawMutation.isLoading}
            disabled={
              withdrawMutation.isLoading ||
              withdrawMutation.isError ||
              !!withdrawEffectMutation.error ||
              withdrawEffectMutation.isLoading ||
              timeExpired
            }
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
