import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { log } from 'modules/logger';
import { WalletBalanceUpdatedEvent } from '../types/wallet-balance-updated-event';
import { WALLETS_QUERY_KEY } from 'modules/assets-and-wallets/api';
import { Wallet } from 'modules/assets-and-wallets/types';
import { CURRENCIES_QUERY_KEY } from 'modules/select-currency-wallet/api';
import { Currency } from 'modules/select-currency-wallet/types';

export const useHandleWalletBalanceUpdated = () => {
  const queryClient = useQueryClient();

  const handleWalletBalanceUpdated = useCallback(
    (event: WalletBalanceUpdatedEvent) => {
      log('handling WALLET_BALANCE_UPDATED WebSocket event', event);

      const walletId = event.wallet_id;
      const newWalletBalance = event.balance;

      queryClient.setQueryData(
        WALLETS_QUERY_KEY,
        (prevState: Wallet[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          const idx = prevState.findIndex((wallet) => wallet.id === walletId);

          if (idx === -1) {
            return undefined;
          }

          const updatedWallet: Wallet = {
            ...prevState[idx],
            balances: prevState[idx].balances.map((balance) =>
              balance.currency === newWalletBalance.currency
                ? newWalletBalance
                : balance
            ),
          };

          return [
            ...prevState.slice(0, idx),
            updatedWallet,
            ...prevState.slice(idx + 1),
          ];
        }
      );

      queryClient.setQueryData(
        CURRENCIES_QUERY_KEY,
        (prevState: Currency[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          const newState: typeof prevState = prevState.map((currency) => {
            if (currency.currency !== newWalletBalance.currency) {
              return currency;
            }

            const walletIdx = currency.wallets.findIndex(
              (wallet) => wallet.id === walletId
            );

            if (walletIdx === -1) {
              return currency;
            }

            const foundWallet = currency.wallets[walletIdx];

            return {
              ...currency,
              wallets: [
                ...currency.wallets.slice(0, walletIdx),
                { ...foundWallet, balance: newWalletBalance.amount },
                ...currency.wallets.slice(walletIdx + 1),
              ],
            };
          });

          return newState;
        }
      );
    },
    [queryClient]
  );

  return handleWalletBalanceUpdated;
};
