import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';

export const SuccessStep: React.FC<{
  title: string;
  onDone: () => void;
  subtitle?: React.ReactNode;
}> = ({ title, onDone, subtitle }) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader subtitle={subtitle}>{title}</ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button
            type='dark'
            name={formatMessage('common.button.done')}
            onClick={() => {
              onDone();
            }}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
