import styles from './TransactionFilters.module.scss';
import { DateFilter } from '../filters/DateFilter';
import { CategoryFilter } from '../filters/CategoryFilter';
import { StatusFilter } from '../filters/StatusFilter';
import { TransactionFiltersPopupMenu } from '../filters/TransactionFiltersPopupMenu';

export const TransactionFilters: React.FC<{}> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mainButtons}>
        <DateFilter />
        <CategoryFilter />
        <StatusFilter />
      </div>
      <div className={styles.filterButton}>
        <TransactionFiltersPopupMenu />
      </div>
    </div>
  );
};
