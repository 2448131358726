import { WarningAlert } from 'common/components/WarningAlert';
import { useAreNoWalletsCreated } from 'modules/assets-and-wallets';
import { useFeatureFlag } from 'modules/feature-flags';
import { useFormatMessage } from 'modules/messages';

type Props = { className?: string };

export const TradeDisabledAlert: React.FC<Props> = ({ className }) => {
  const formatMessage = useFormatMessage();

  const { data: walletsFailed, isLoading: isWalletsFailedLoading } =
    useAreNoWalletsCreated();

  const newTradeFeatureFlag = useFeatureFlag('CREATING_TRADE');

  if (newTradeFeatureFlag.isLoading || isWalletsFailedLoading) {
    return null;
  }

  if (newTradeFeatureFlag.data?.enabled && !walletsFailed) {
    return null;
  }

  return (
    <WarningAlert
      className={className}
      header={formatMessage('trade.featureCurrentlyUnavailable')}
      body={formatMessage('trade.tradeFeatureCurrentlyUnavailableTryLater')}
    />
  );
};
