import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './SavingHistory.module.scss';
import { Button } from 'common/components/button';
import { SavingHistoryBox } from './SavingHistoryBox';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters';
import { useNavigate } from 'react-router-dom';
import { LoaderBox } from 'common/components/loader';
import { useHistoricalSavingTransactionsQuery } from '../../api';

type Props = { id: number };

export const SavingHistory: React.FC<Props> = ({ id }) => {
  const formatMessage = useFormatMessage();

  const { isLoading, data } = useHistoricalSavingTransactionsQuery(id);
  const { setFilters, resetFilters, setRefetchTransactionsFlag } =
    useInfiniteTransactionsContext();
  const navigate = useNavigate();

  if (isLoading) {
    return <LoaderBox />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text style='xxbold2024' inlineStyle={{ display: 'block' }}>
          {formatMessage('saving.savingHistory')}
        </Text>
        <Button
          type='secondarySmall'
          name={formatMessage('common.fullHistory')}
          onClick={() => {
            resetFilters();
            setFilters({ savings: [id] });
            setRefetchTransactionsFlag(true);
            navigate('/transactions');
          }}
          isLoading={isLoading}
        />
      </div>
      <div className={styles.list}>
        {data?.data.results.map((transaction) => (
          <SavingHistoryBox transaction={transaction} key={transaction.id} />
        ))}
      </div>
    </div>
  );
};
