import { CurrencyEnum } from 'common/types';
import { toDecimal } from 'modules/input-amount';
import { useCurrencyRatesQuery } from './api';
import { FiatAmount, FiatAmountProps } from './FiatAmount';
import { useFiatCurrency } from './hooks';
import { CurrencyRate } from './types';

type Props = FiatAmountProps & {
  currency?: CurrencyEnum;
  currencyRate?: CurrencyRate;
};

export const CryptoToFiatAmount: React.FC<Props> = ({
  amount,
  currency,
  currencyRate,
  isDataLoading = false,
  ...restProps
}) => {
  const { data: fiatCurrency, isLoading: isFiatCurrencyLoading } =
    useFiatCurrency();

  const { data: currencyRates, isLoading: isCurrencyRatesLoading } =
    useCurrencyRatesQuery({ enabled: !isDataLoading && !currencyRate });

  const isLoading =
    isDataLoading ||
    isFiatCurrencyLoading ||
    (currencyRate ? false : isCurrencyRatesLoading);

  const appliedCurrencyRate =
    currencyRate ||
    currencyRates?.data?.find(
      (currencyRate) => currencyRate.currency === currency
    );

  const fiatPrice =
    fiatCurrency === 'EUR'
      ? appliedCurrencyRate?.price_eur
      : fiatCurrency === 'USD'
      ? appliedCurrencyRate?.price_usd
      : '0';

  const fiatAmount = toDecimal(fiatPrice).mul(toDecimal(amount) || 0);

  return (
    <FiatAmount amount={fiatAmount} isDataLoading={isLoading} {...restProps} />
  );
};
