import { PageLoader } from 'common/components/loader';
import { ModalLike } from 'common/components/modal';
import { NotFoundLayout } from 'modules/layout';
import { useLoginRequired } from 'modules/login';
import { useFormatMessage } from 'modules/messages';
import { PageNotFound as PageNotFoundFeature } from 'modules/not-found';
import { Helmet } from 'react-helmet-async';

export const NotFound: React.FC<{}> = () => {
  const { isLoading } = useLoginRequired();

  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.notFound')}</title>
      </Helmet>
      {isLoading ? (
        <PageLoader />
      ) : (
        <NotFoundLayout>
          <ModalLike>
            <PageNotFoundFeature />
          </ModalLike>
        </NotFoundLayout>
      )}
    </>
  );
};
