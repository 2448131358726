import { Text } from 'common/components/Text';
import { FiatAmount } from 'modules/fiat';
import { toDecimal } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { LendsSummary } from '../../api';

type Props = { lendSummary?: LendsSummary; isLoading?: boolean };

export const ExpectedEarnFromLends: React.FC<Props> = ({
  lendSummary,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text style='light1215' inlineStyle={{ display: 'block' }}>
        {formatMessage('lend.expectedEarnFromLends')}
      </Text>
      <FiatAmount
        amount={
          lendSummary
            ? toDecimal(lendSummary.expected_profit_fiat).add(
                lendSummary.current_profit_fiat
              )
            : undefined
        }
        isDataLoading={isLoading}
        loaderContainerSize={48}
        loaderSize={30}
        textColorStyle='primary'
        textStyle='xxbold3048'
      />
    </div>
  );
};
