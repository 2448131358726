import { Text } from 'common/components/Text';
import { CurrencyEnum } from 'common/types';
import { debounce } from 'lodash';
import { InterestRate } from 'modules/financial-ops/common';
import { Separator } from 'modules/financial-ops/common/components';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { KyxAlert, useIsKyxCompleted } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { useCallback, useEffect } from 'react';
import {
  BorrowsEffectError,
  BorrowsEffectPayload,
  BorrowsEffectResponseData,
  useBorrowsEffectMutation,
} from '../api';
import { BorrowCalculationBase } from '../types';
import styles from './BorrowEffectCalculator.module.scss';
import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { GoToSummaryButton } from 'common/components/button';
import {
  subscribeToSettingUpdate,
  unsubscribeFromSettingUpdate,
} from 'modules/websocket';
import { AutomaticCollateralTopup } from './AutomaticCollateralTopup';

type Props = {
  interestRate?: InterestRate;
  collateralAmount?: string;
  collateralCurrency?: CurrencyEnum;
  collateralWalletId?: number;
  principalAmount?: string;
  principalCurrency?: CurrencyEnum;
  principalWalletId?: number;
  calculationBase?: BorrowCalculationBase;
  automaticTopupChecked: boolean;
  maxCount: number; // UGLY HACK WARNING!!!
  onEffectCalculated: (
    data: BorrowsEffectResponseData,
    payload: BorrowsEffectPayload
  ) => void;
  onEffectError: (
    error: BorrowsEffectError,
    payload: BorrowsEffectPayload
  ) => void;
  onContinueToSummary: () => void;
  onAutomaticTopupChange: (val: boolean) => void;
};

export const BorrowEffectCalculator: React.FC<Props> = ({
  interestRate,
  collateralAmount,
  collateralCurrency,
  collateralWalletId,
  principalAmount,
  principalCurrency,
  principalWalletId,
  calculationBase,
  maxCount, // UGLY HACK WARNING!!!
  onEffectCalculated,
  onEffectError,
  onContinueToSummary,
  onAutomaticTopupChange,
  automaticTopupChecked,
}) => {
  const formatMessage = useFormatMessage();

  const isKyxCompleted = useIsKyxCompleted();

  const mutation = useBorrowsEffectMutation();

  const calculateEffect = useCallback(
    debounce((payload: BorrowsEffectPayload) => {
      mutation.mutate(payload, {
        onSuccess: (response) => {
          onEffectCalculated(response.data, payload); // check how passing payload can be improved by using `variables` argument from react-query
        },
        onError: (err) => {
          if (err.response) {
            onEffectError(err.response?.data, payload);
          }
          // other errors, like network connection errors, should be handled inside and not propagated outside
        },
      });
    }, 200),
    []
  );

  const calculateEffectWrapped = useCallback(() => {
    if (
      !interestRate ||
      !calculationBase ||
      !principalWalletId ||
      !principalCurrency ||
      !collateralWalletId ||
      !collateralCurrency
    ) {
      return;
    }
    const commonFields: BorrowsEffectPayload = {
      calculation_base: calculationBase,
      term: interestRate.term!,
      collateral_currency: collateralCurrency,
      collateral_wallet_id: collateralWalletId,
      principal_currency: principalCurrency,
      principal_wallet_id: principalWalletId,
      auto_topup: automaticTopupChecked,
      summary: false,
    };
    if (calculationBase === 'PRINCIPAL' && toDecimal(principalAmount).gt(0)) {
      calculateEffect({
        ...commonFields,
        principal_amount: toFixed(principalAmount),
      });
    }
    if (calculationBase === 'COLLATERAL' && toDecimal(collateralAmount).gt(0)) {
      calculateEffect({
        ...commonFields,
        collateral_amount: toFixed(collateralAmount),
      });
    }
    if (calculationBase === 'COLLATERAL_MAX') {
      calculateEffect(commonFields);
    }
  }, [
    interestRate?.term,
    interestRate?.rate_pct,
    calculationBase,
    calculationBase === 'PRINCIPAL' ? principalAmount : undefined,
    principalCurrency,
    principalWalletId,
    calculationBase === 'COLLATERAL' ? collateralAmount : undefined,
    collateralCurrency,
    collateralWalletId,
    calculateEffect,
    automaticTopupChecked,
  ]);

  useEffect(() => {
    calculateEffectWrapped();
  }, [
    calculateEffectWrapped,
    maxCount, // UGLY HACK WARNING!!! this dependency is used to trigger request when no actual parameters change
  ]);

  useEffect(() => {
    const borrowMaxHandle = subscribeToSettingUpdate(
      'BORROW_MAX_AMOUNT_EUR',
      () => {
        calculateEffectWrapped();
      }
    );
    const borrowMinHandle = subscribeToSettingUpdate(
      'BORROW_MIN_AMOUNT_EUR',
      () => {
        calculateEffectWrapped();
      }
    );
    return () => {
      unsubscribeFromSettingUpdate('BORROW_MAX_AMOUNT_EUR', borrowMaxHandle);
      unsubscribeFromSettingUpdate('BORROW_MIN_AMOUNT_EUR', borrowMinHandle);
    };
  }, [calculateEffectWrapped]);

  return (
    <>
      <div className={styles.spaceBetween}>
        <Text style='light1425'>{formatMessage('common.interestRate')}</Text>
        <Text style='light1425' dataTest='interestRate'>
          {interestRate ? `${toFixed(interestRate.rate_pct)}%` : '-'}
        </Text>
      </div>
      <div className={styles.spaceBetween}>
        <Text style='light1425'>{formatMessage('borrow.ltvRatio')}</Text>
        <Text style='light1425' dataTest='LTVratio'>
          {mutation.data?.data.ltv_pct
            ? `${toFixed(mutation.data?.data.ltv_pct)}%`
            : '-'}
        </Text>
      </div>
      <Separator />
      <div className={styles.spaceBetween}>
        <Text style='light1425'>{formatMessage('borrow.borrowAmount')}</Text>
        {principalAmount && toDecimal(principalAmount).gt(0) ? (
          <Text
            style='xxbold1619'
            className={styles.textRight}
            dataTest='borrowAmount'
          >{`${toLimitedPrec(
            toFixed(principalAmount)
          )} ${principalCurrency}`}</Text>
        ) : (
          <Text style='light1425'>{'-'}</Text>
        )}
      </div>
      <div className={styles.spaceBetween}>
        <LabelWithInfoTooltip
          text={formatMessage('borrow.collateralAmount')}
          tooltipText={formatMessage('borrow.collateralCanVary')}
        />
        {collateralAmount && toDecimal(collateralAmount).gt(0) ? (
          <Text
            style='xxbold1619'
            className={styles.textRight}
            dataTest='collateralAmount'
          >{`~${toLimitedPrec(
            toFixed(collateralAmount)
          )} ${collateralCurrency}`}</Text>
        ) : (
          <Text style='light1425'>{'-'}</Text>
        )}
      </div>
      <div className={styles.spaceBetween}>
        <Text style='light1425'>{formatMessage('borrow.interestAmount')}</Text>
        {mutation.data?.data.interest ? (
          <Text
            style='xxbold1619'
            className={styles.textRight}
            dataTest='interestAmount'
          >
            {`${toFixed(mutation.data?.data.interest)} ${principalCurrency}`}
          </Text>
        ) : (
          <Text style='light1425'>{'-'}</Text>
        )}
      </div>
      <Separator />
      <AutomaticCollateralTopup
        onChange={(value) => onAutomaticTopupChange(value)}
        checked={automaticTopupChecked}
        className={styles.lastItem}
      />
      <KyxAlert className={styles.kycAlert} />
      <GoToSummaryButton
        className={styles.summaryButton}
        disabled={!isKyxCompleted || mutation.isLoading}
        onClick={onContinueToSummary}
      />
    </>
  );
};
