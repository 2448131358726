import { CurrencyEnum } from 'common/types';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { BoldAmount } from 'modules/common-data-fields/BoldAmount';

type Props = { amount: string; currency: CurrencyEnum };

export const WithdrawalAmount: React.FC<Props> = ({ amount, currency }) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text style='light1215' inlineStyle={{ display: 'block' }}>
        {formatMessage('common.withdrawalAmount') + ':'}
      </Text>
      <BoldAmount amount={amount} currency={currency} />
    </div>
  );
};
