import { useCallback } from 'react';
import { TransactionNewEvent } from '../types/transaction-new-event';
import { Transaction } from 'modules/transactions/types';
import { useIsSigner } from 'modules/user';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  PAGINATED_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  TransactionsRequiringAction,
  TransactionsResponse,
} from 'modules/transactions/api';
import { useLoginContext } from 'modules/login/context';
import { PAGE_SIZE } from 'common/consts/consts';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters';
import {
  insertTransactionToTransactionsInfinite,
  matchesFilters,
} from './utils-transactions';
import { log } from 'modules/logger';

export const useHandleTransactionNew = () => {
  const queryClient = useQueryClient();

  const { isLoggedIn } = useLoginContext();
  const { data: isSigner } = useIsSigner({
    enabled: isLoggedIn,
  });

  const { filters, setRefetchTransactionsFlag } =
    useInfiniteTransactionsContext();

  const handleTransactionNew = useCallback(
    (event: TransactionNewEvent) => {
      log('handling TRANSACTION_NEW WebSocket event', event);

      // if, by any chance, it is unknown if user is signer (e.g. data is not yet fetched),
      // then there is no complete data to build new transaction and update the cache
      // and the new transaction must be fetched from BE.
      if (typeof isSigner === 'undefined') {
        queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
        queryClient.invalidateQueries(
          TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
        );
        queryClient.invalidateQueries(PAGINATED_TRANSACTIONS_QUERY_KEY);
        setRefetchTransactionsFlag(true);
        return;
      }

      const requiresAction =
        event.transaction.type === 'OUTGOING' &&
        event.transaction.state === 'PENDING' &&
        isSigner;

      const newTransaction: Transaction = {
        ...event.transaction,
        requires_action: requiresAction,
        user_action: null,
      };

      queryClient.setQueryData(
        DASHBOARD_TRANSACTIONS_QUERY_KEY,
        (prevState: Transaction[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          const transactionsWaitingForAction = prevState.filter(
            (t) => t.requires_action
          );
          const restTransactions = prevState.filter((t) => !t.requires_action);

          const newState = (
            requiresAction
              ? [
                  newTransaction,
                  ...transactionsWaitingForAction,
                  ...restTransactions,
                ]
              : [
                  ...transactionsWaitingForAction,
                  newTransaction,
                  ...restTransactions,
                ]
          ).slice(0, PAGE_SIZE);

          return newState;
        }
      );

      queryClient.setQueryData(
        TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
        (prevState: TransactionsRequiringAction | undefined) => {
          if (!prevState) {
            return undefined;
          }
          if (!requiresAction) {
            return prevState;
          }
          return {
            count: prevState.count + 1,
          };
        }
      );

      queryClient.setQueryData(
        PAGINATED_TRANSACTIONS_QUERY_KEY,
        (prevState: InfiniteData<TransactionsResponse> | undefined) => {
          if (!prevState) {
            return undefined;
          }
          if (!matchesFilters(newTransaction, filters)) {
            return undefined;
          }
          return insertTransactionToTransactionsInfinite(
            newTransaction,
            prevState,
            filters
          );
        }
      );
    },
    [filters, isSigner, queryClient, setRefetchTransactionsFlag]
  );

  return handleTransactionNew;
};
