export const buildCharityFormInappPageUrl = (token: string) =>
  window.location.origin + '/charity-form/' + token;

export const buildCharityFormEmbeddedPageUrl = (token: string) =>
  window.location.origin + '/charity-form-embedded/' + token;

export const buildCharityFormPreviewPageUrl = (json: string) =>
  window.location.origin + '/charity-form-preview/' + json;

export const buildCharityFormIframeCode = (token: string) => {
  const src = buildCharityFormEmbeddedPageUrl(token);
  return `<iframe src="${src}" width="1500" height="700" style="border: 0px;" />`;
};
