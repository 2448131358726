import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useFormatMessage } from 'modules/messages';
import { InviteMembers } from './components/InviteMembers';
import { MembersTable } from './components/MembersTable';
import { DeleteMemberContextProvider } from './delete-member/context';
import { DeleteMemberModal } from './delete-member/DeleteMemberModal';

type Props = {};

export const CompanyMembers: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();
  return (
    <Section>
      <SectionHeader
        header={formatMessage('companyMembers.header')}
        actions={<InviteMembers />}
      />
      <DeleteMemberContextProvider>
        <MembersTable />
        <DeleteMemberModal />
      </DeleteMemberContextProvider>
    </Section>
  );
};
