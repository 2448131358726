import { RefObject, useEffect, useRef } from 'react';
import styles from './Checkbox.module.scss';
import { theme } from 'common/consts/theme';

type Props = {
  checked: boolean;
  primaryColor: string;
  secondaryColor: string | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox: React.FC<Props> = ({
  checked,
  primaryColor,
  secondaryColor,
  onChange,
}) => {
  const ref = useRef() as RefObject<HTMLInputElement>;
  useEffect(() => {
    ref.current!.checked = checked;
  });

  return (
    <label className={styles.container} aria-label='Checkbox button'>
      <input
        type='checkbox'
        onChange={onChange}
        ref={ref}
        className={styles.input}
      />
      <div
        className={styles.checkmarkContainer}
        style={{
          borderColor: checked ? secondaryColor ?? primaryColor : undefined,
        }}
      >
        {checked && (
          <Checkmark
            className={styles.checkmark}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
        )}
      </div>
    </label>
  );
};

const Checkmark: React.FC<{
  className?: string;
  primaryColor: string;
  secondaryColor: string | null;
}> = ({ className, primaryColor, secondaryColor }) => {
  return (
    <svg
      className={className}
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='23' height='23' fill={secondaryColor ?? primaryColor} />
      <path
        d='M10.0625 18.4688L20.125 8.40625L17.9688 6.25L10.0625 14.1562L6.46875 10.5625L4.3125 12.7188L10.0625 18.4688Z'
        fill={secondaryColor ? primaryColor : theme.colors.btnLight}
      />
    </svg>
  );
};
