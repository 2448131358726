import clsx from 'clsx';
import { CurrencySelector } from 'modules/currency-selector';
import styles from './AssetAmountInputReadOnly.module.scss';
import { Currency } from 'modules/select-currency-wallet/types';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { ReactComponent as ArrowDownIcon } from 'common/assets/black-icons/arrowhead-down-12px-withpadding.svg';

type Props = {
  desktopHeader: string;
  mobileAssetHeader: string;
  mobileAmountHeader: string;
  textTooltip?: string;
  currencies?: Currency[];
};

export const AssetAmountInputReadOnly: React.FC<Props> = ({
  desktopHeader,
  mobileAssetHeader,
  mobileAmountHeader,
  textTooltip,
}) => {
  return (
    <div className={styles.rootContainer}>
      <div className={styles.hideOnDesktop}>
        <SectionSubheader tooltip={textTooltip}>
          {mobileAssetHeader}
        </SectionSubheader>
        <div className={styles.selectCurrencyBox}>
          <CurrencyBox
            currency={undefined}
            network={undefined}
            isPlaceholder={true}
          />
          <ArrowDownIcon />
          <div className={styles.readOnlyMobileBox}>{''}</div>
        </div>
      </div>
      <div className={clsx(styles.container, styles.hideOnMobile)}>
        <SectionSubheader
          className={clsx(styles.hideOnDesktop, styles.hideText)}
        >
          {mobileAmountHeader}
        </SectionSubheader>
        <SectionSubheader className={styles.hideOnMobile} tooltip={textTooltip}>
          {desktopHeader}
        </SectionSubheader>
        <div
          className={clsx(styles.inputContainer, styles.removeBorderOnSmall)}
        >
          <div className={styles.readOnlyBox}>{''}</div>
          <CurrencySelector className={styles.hideOnMobile} />
        </div>
      </div>
    </div>
  );
};
