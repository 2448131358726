import { NetworkBadge } from 'common/components/NetworkBadge';
import { Text } from 'common/components/Text';
import { NetworkEnum } from 'common/types';
import styles from './LabeledNetworkBadge.module.scss';

type Props = {
  network: NetworkEnum;
  text: string;
};

export const LabeledNetworkBadge: React.FC<Props> = ({ network, text }) => {
  return (
    <div className={styles.container}>
      <Text style='light1622'>{text}</Text>
      <NetworkBadge network={network} />
    </div>
  );
};
