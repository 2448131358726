import { CharityFormPublicRead, FormDonationPublicRead } from './types';
import styles from './CharityFormPublic.module.scss';
import {
  CharityFormDescription,
  CharityFormHeader,
  CharityFormPictures,
  CharityFormProgress,
  DonateWithCryptocurrencies,
  Donations,
  PoweredByMontesauri,
} from './components';
import { useState } from 'react';
import { DonationsModal } from './donations-modal';
import { CharityFormPublicContext } from './context';

type Props = {
  donations: FormDonationPublicRead[];
  form: CharityFormPublicRead;
  isPreview?: boolean;
};

export const CharityFormPublic: React.FC<Props> = ({
  donations,
  form,
  isPreview = false,
}) => {
  const [isDonationsModalOpen, setIsDonationsModalOpen] = useState(false);

  return (
    <CharityFormPublicContext.Provider value={{ donations, form }}>
      <div className={styles.container}>
        <div className={styles.contentGrid}>
          <div className={styles.pictureArea}>
            <CharityFormPictures />
          </div>
          <div className={styles.headerArea}>
            <CharityFormHeader />
          </div>
          <div className={styles.donationsArea}>
            <DonateWithCryptocurrencies />
            <Donations />
          </div>
          <div className={styles.descriptionArea}>
            <CharityFormDescription />
          </div>
          <div className={styles.progressArea}>
            <CharityFormProgress
              onDonate={() => {
                if (isPreview) {
                  return;
                }
                setIsDonationsModalOpen(true);
              }}
            />
          </div>
        </div>
        <PoweredByMontesauri />
      </div>
      {isDonationsModalOpen && (
        <DonationsModal
          onClose={() => {
            setIsDonationsModalOpen(false);
          }}
        />
      )}
    </CharityFormPublicContext.Provider>
  );
};
