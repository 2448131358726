import { useCharityFormPublicContext } from '../context';
import styles from './CharityFormPictures.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { ReactComponent as ArrowIcon } from '../assets/arrow-left.svg';
import { sortBy } from 'lodash';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { YOUTUBE_ID_REGEX } from 'modules/charity-forms/new-form/utils';
import clsx from 'clsx';
import { useState } from 'react';
import { KindMultimedia } from '../types';

type Picture = {
  src: string;
  kind: KindMultimedia;
};

export const CharityFormPictures: React.FC<{}> = ({}) => {
  const { form } = useCharityFormPublicContext();
  const [hideArrowLeft, setHideArrowLeft] = useState(true);
  const [hideArrowRight, setHideArrowRight] = useState(
    form.pictures.length === 1 && form.youtube_link === null
  );

  const getMultimediaList = () => {
    const attachments: Picture[] = sortBy(
      form.pictures.filter((p) => p.kind === 'CONTENT'),
      'order'
    ).map((p) => {
      return { src: p.file, kind: 'PHOTO' };
    });

    const matchVideoId = form.youtube_link?.match(YOUTUBE_ID_REGEX);

    if (
      form.youtube_link_order === null ||
      form.youtube_link === null ||
      !matchVideoId
    ) {
      return attachments;
    }

    const embeddedYoutubeLink = `https://www.youtube.com/embed/${matchVideoId[1]}`;

    const youtubeItem: Picture = {
      src: embeddedYoutubeLink,
      kind: 'VIDEO',
    };

    return [
      ...attachments.slice(0, form.youtube_link_order - 1),
      youtubeItem,
      ...attachments.slice(form.youtube_link_order - 1),
    ];
  };

  const stopVideo = () => {
    const videos = document.querySelectorAll('iframe, video');
    Array.prototype.forEach.call(videos, (video) => {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause();
      } else {
        const src = video.src;
        video.src = src;
      }
    });
  };

  const multimedia = getMultimediaList();

  return (
    <div className={styles.container}>
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        navigation={{
          nextEl: '.arrow-left',
          prevEl: '.arrow-right',
        }}
        modules={[Pagination, Navigation]}
        className={styles.pictures}
        onNavigationNext={() => {
          setHideArrowLeft(false);
          stopVideo();
        }}
        onNavigationPrev={() => {
          setHideArrowRight(false);
          stopVideo();
        }}
        onReachEnd={() => setHideArrowRight(true)}
        onReachBeginning={() => setHideArrowLeft(true)}
      >
        {multimedia.map((picture, i) => (
          <SwiperSlide key={picture.src}>
            <>
              {picture.kind === 'VIDEO' && picture.src ? (
                <iframe
                  className={styles.player}
                  id={'youtubePlayer'}
                  title={'Youtube player'}
                  src={picture.src}
                  allowFullScreen={true}
                />
              ) : (
                <img src={picture.src} className={styles.image} />
              )}
            </>
          </SwiperSlide>
        ))}
      </Swiper>
      <ArrowIcon
        className={clsx(
          `arrow-right`,
          styles.arrow,
          styles.arrowRight,
          hideArrowLeft && styles.hideArrow
        )}
      />
      <ArrowIcon
        className={clsx(
          `arrow-left`,
          styles.arrow,
          styles.arrowLeft,
          hideArrowRight && styles.hideArrow
        )}
      />
    </div>
  );
};
