import { Text, TextStyle, TextColorStyle } from 'common/components/Text';
import styles from './LabelWithInfoTooltip.module.scss';
import { InfoTooltip } from './InfoTooltip';

type Props = {
  text: string;
  textStyle?: TextStyle;
  tooltipText?: string;
  colorStyle?: TextColorStyle;
};

export const LabelWithInfoTooltip: React.FC<Props> = ({
  text,
  textStyle = 'light1425',
  tooltipText,
  colorStyle = 'primary',
}) => {
  return (
    <div className={styles.container}>
      <Text style={textStyle} colorStyle={colorStyle}>
        {text}
      </Text>
      <InfoTooltip text={tooltipText} />
    </div>
  );
};
