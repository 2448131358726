import { ReactComponent as BlockedIcon } from 'common/assets/illustrations/blocked.svg';
import { ModalBody, ModalHeader } from 'common/components/modal';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './CompanySuspended.module.scss';

type Props = {
  isCompanyOwner: boolean;
};

export const CompanySuspended: React.FC<Props> = ({ isCompanyOwner }) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('login.companyAccountBlocked')}
      </ModalHeader>
      <ModalBody className={styles.content}>
        <Text style='light1425'>
          {formatMessage(
            isCompanyOwner
              ? 'login.companyAccountBlockedContactMontesauri'
              : 'login.companyAccountBlockedCompanyOwnerShouldContactMontesauri'
          )}
        </Text>
        <BlockedIcon className={styles.icon} />
      </ModalBody>
    </>
  );
};
