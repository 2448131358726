import { useQueryClient } from '@tanstack/react-query';
import { SectionHeader } from 'common/components/SectionHeader';
import { LoaderBox } from 'common/components/loader';
import { openSnackbar } from 'common/components/snackbar';
import { prettifyError } from 'common/utils/prettify-error';
import { toDecimal, toFixed } from 'modules/input-amount';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { CURRENCY_RATES_QUERY_KEY } from 'modules/fiat';
import {
  findCurrency,
  getHighestBalanceWallet,
  TermAndRateSelector,
  useInterestRatesExistForKind,
} from 'modules/financial-ops/common';
import {
  ConfigSection,
  NewOperationLayout,
  NavigationHeader,
  SummarySection,
} from 'modules/financial-ops/common/components';
import { AssetAmountInput } from 'modules/financial-ops/common/components/AssetAmountInput';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';
import { KyxAlert, useIsKyxCompleted } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';
import { useCallback, useEffect, useState } from 'react';
import {
  LendsEffectPayload,
  principalMaxError,
  principalMinError,
  useLendsEffectMutation,
} from '../api';
import { LendEffect } from './LendEffect';
import { NewLendSummaryModal } from './NewLendSummaryModal';
import styles from './NewLend.module.scss';
import { NewLendContextProvider, useNewLendContext } from './context';
import { useCalculateEffect } from './use-calculate-effect';
import {
  subscribeToSettingUpdate,
  unsubscribeFromSettingUpdate,
} from 'modules/websocket';

const NewLendWrapped: React.FC<{}> = () => {
  const queryClient = useQueryClient();

  const formatMessage = useFormatMessage();

  const context = useNewLendContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const lendsEffectMutation = useLendsEffectMutation();

  const { data: currencies, isLoading: isLoadingCurrencies } =
    useGetCurrenciesWithWallets();

  const continueToSummary = () => {
    if (!context.currency) {
      context.setAssetAndAmountError(
        formatMessage('finopsCommon.currencyNotSelected')
      );
      return;
    }
    if (!context.wallet) {
      context.setWalletError(formatMessage('finopsCommon.walletNotSelected'));
      return;
    }
    if (toDecimal(context.amount).eq(0)) {
      context.setAssetAndAmountError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }
    if (!context.rate) {
      context.setRateError(formatMessage('finopsCommon.rateNotSelected'));
      return;
    }

    // error from /effect endpoint
    if (context.assetAndAmountError) {
      return;
    }

    if (toDecimal(context.amount).gt(context.wallet.balance)) {
      openSnackbar(
        formatMessage('lend.yourFundsInChosenWalletAreInsufficient'),
        'warning'
      );
      return;
    }

    context.setRateError('');
    context.setAssetAndAmountError('');

    getInitialToken();
  };

  const getInitialToken = () => {
    if (!context.wallet || !context.currency || !context.rate) {
      return;
    }

    let payload: LendsEffectPayload = {
      calculation_base: 'PRINCIPAL',
      principal_wallet_id: context.wallet.id,
      principal_currency: context.currency.currency,
      term: context.rate.term!,
      summary: true,
      principal_amount: context.amount,
    };

    lendsEffectMutation.mutate(payload, {
      onSuccess: () => {
        setIsModalOpen(true);
      },
      onError: (error) => {
        queryClient.invalidateQueries(CURRENCY_RATES_QUERY_KEY);

        const principalMaxMatch = principalMaxError(error.response?.data);
        if (principalMaxMatch) {
          context.setAssetAndAmountError(
            formatMessage('lend.currentPrincipalAmountMustBeLessOrEqual', {
              amount: toFixed(principalMaxMatch[1]),
              currency: context.currency?.currency,
            })
          );
          return;
        }

        const principalMinMatch = principalMinError(error.response?.data);
        if (principalMinMatch) {
          context.setAssetAndAmountError(
            formatMessage('lend.currentPrincipalAmountMustBeGreaterOrEqual', {
              amount: toFixed(principalMinMatch[1]),
              currency: payload.principal_currency,
            })
          );
          return;
        }

        context.setAssetAndAmountError(prettifyError(error));
      },
    });
  };

  const {
    calculateEffect,
    mutation: { isLoading: isLoadingEffect, data: effectData },
  } = useCalculateEffect();

  // Invoke calculateEffect on page load with only context state which in turn is populated from url state.
  // Currently this will cause actual /effect call only when New lend is entered through Alternative offer on New saving page.
  useEffect(() => {
    calculateEffect({ calculationBase: 'PRINCIPAL' });
  }, []);

  useEffect(() => {
    const lendMaxHandle = subscribeToSettingUpdate(
      'LEND_MAX_AMOUNT_EUR',
      () => {
        calculateEffect({ calculationBase: 'PRINCIPAL' });
      }
    );
    const lendMinHandle = subscribeToSettingUpdate(
      'LEND_MIN_AMOUNT_EUR',
      () => {
        calculateEffect({ calculationBase: 'PRINCIPAL' });
      }
    );
    return () => {
      unsubscribeFromSettingUpdate('LEND_MAX_AMOUNT_EUR', lendMaxHandle);
      unsubscribeFromSettingUpdate('LEND_MIN_AMOUNT_EUR', lendMinHandle);
    };
  }, [calculateEffect]);

  // Calculate effect when interest rate is changed outside of this component, for example when INTEREST_RATE_UPDATED WebSocket event is handled.
  // By using useEffect for this task, it is possible that under certain conditions, /api/effect will be called twice.
  useEffect(() => {
    calculateEffect({ calculationBase: 'PRINCIPAL' });
  }, [context.rate]);

  const lendFeatureFlag = useRequireFeatureFlag('LEND');
  const newLendFeatureFlag = useRequireFeatureFlag('CREATING_LEND');
  const ratesExists = useInterestRatesExistForKind('LEND', { redirect: true });

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const isKyxCompleted = useIsKyxCompleted();

  useEffect(() => {
    if (!isKyxCompleted) {
      closeModal();
    }
  }, [isKyxCompleted, closeModal]);

  if (
    lendFeatureFlag.isLoading ||
    newLendFeatureFlag.isLoading ||
    ratesExists.isLoading
  ) {
    return <LoaderBox />;
  }

  if (
    !lendFeatureFlag.data?.enabled ||
    !newLendFeatureFlag.data?.enabled ||
    !ratesExists.data
  ) {
    return null;
  }

  return (
    <>
      <NewOperationLayout className={styles.layout}>
        <ConfigSection>
          <NavigationHeader to='/lends'>
            {formatMessage('common.back')}
          </NavigationHeader>
          <SectionHeader header={formatMessage('lend.newLend')} />
          <KyxAlert className={styles.kycAlert} />
          <AssetAmountInput
            amount={context.amount}
            currency={context.currency}
            currencies={currencies}
            desktopHeader={formatMessage('common.selectAssetAndAmount')}
            error={context.assetAndAmountError}
            info={context.amountInfo}
            interestRateKind={'LEND'}
            isLoadingCurrencies={isLoadingCurrencies}
            mobileAssetHeader={formatMessage('common.currency')}
            mobileAmountHeader={formatMessage('common.amount')}
            showApr={true}
            wallet={context.wallet}
            wallets={context.currency?.wallets}
            walletError={context.walletError}
            onAmountChange={(value) => {
              context.setAmount(value);
              context.setAmountInfo('');
              calculateEffect({
                amount: value,
                calculationBase: 'PRINCIPAL',
              });
            }}
            onCurrencyChange={(currency, rate) => {
              context.setAmount(INITIAL_AMOUNT);
              context.setAmountInfo('');
              context.setAssetAndAmountError('');
              const foundCurrency = findCurrency(currency, currencies);
              if (foundCurrency) {
                context.setCurrencyKey({
                  currency: foundCurrency.currency,
                  network: foundCurrency.network,
                });
              }
              const foundWallet = getHighestBalanceWallet(
                foundCurrency?.wallets
              );
              if (foundWallet) {
                context.setWalletId(foundWallet.id);
              }

              if (rate) {
                context.setTerm(rate?.term);
              }
              context.setRateError('');

              calculateEffect({
                calculationBase: 'PRINCIPAL',
                currency: foundCurrency?.currency,
                term: rate?.term,
                wallet: foundWallet?.id,
              });
            }}
            onWalletChange={(value) => {
              context.setAmountInfo('');
              context.setWalletId(value.id);
              context.setWalletError('');
              calculateEffect({
                calculationBase: 'PRINCIPAL',
                wallet: value?.id,
              });
            }}
            onMax={() => {
              calculateEffect({
                calculationBase: 'PRINCIPAL_MAX',
              });
            }}
            onError={(error) => context.setAssetAndAmountError(error)}
            dataTest='amountField'
          />
          <TermAndRateSelector
            currency={context.currency}
            selectedRate={context.rate}
            onSelectRate={(rate) => {
              context.setTerm(rate.term);
              context.setRateError('');
              calculateEffect({
                calculationBase: 'PRINCIPAL',
                term: rate.term,
              });
            }}
            error={context.rateError}
            interestRateKind={'LEND'}
            noDataText={formatMessage('lend.thereAreNoLends')}
          />
        </ConfigSection>
        <SummarySection dataTest='lendSummarySection'>
          <LendEffect
            amount={toDecimal(context.amount)}
            currency={context.currency}
            isLoading={isLoadingEffect}
            rate={context.rate}
            totalEarn={effectData?.data.interest_total}
            wallet={context.wallet}
            onContinueToSummary={continueToSummary}
          />
        </SummarySection>
      </NewOperationLayout>
      <NewLendSummaryModal
        amount={toDecimal(context.amount)}
        currency={context.currency}
        interestAmount={lendsEffectMutation.data?.data.interest_total}
        interestRatePct={lendsEffectMutation.data?.data.rate_pct}
        interestRateTerm={lendsEffectMutation.data?.data.term}
        isOpen={isModalOpen}
        token={lendsEffectMutation.data?.data.token}
        wallet={context.wallet}
        onClose={closeModal}
      />
    </>
  );
};

export const NewLend: React.FC<{}> = () => {
  return (
    <NewLendContextProvider>
      <NewLendWrapped />
    </NewLendContextProvider>
  );
};
