import { ReactComponent as HelpIcon } from 'common/assets/icons/help-clear-bw.svg';
import { Button } from 'common/components/button';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { ZENDESK_LINK } from 'modules/settings/components/HelpCenterButton';

export const UnauthFooter: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        type='secondaryResponsive'
        name={
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <HelpIcon />
            <Text style='bold1417'>{formatMessage('zendesk.helpCenter')}</Text>
          </div>
        }
        onClick={() => {
          window.open(ZENDESK_LINK, '_blank');
        }}
      />
    </div>
  );
};
