import { Text } from 'common/components/Text';
import { CurrencyEnum } from 'common/types';
import { useFormatMessage } from 'modules/messages';
import { BoldAmount } from 'modules/common-data-fields/BoldAmount';

type Props = {
  amount: string;
  currency: CurrencyEnum;
  apr: string;
};

export const TotalInterest: React.FC<Props> = ({ amount, currency, apr }) => {
  const formatMessage = useFormatMessage();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text style='light1215'>
        {formatMessage('common.totalInterest') + ':'}
      </Text>
      <BoldAmount amount={amount} currency={currency} />
      <Text style='light1215'>
        {formatMessage('common.rateApr', { rate_pct: apr })}
      </Text>
    </div>
  );
};
