import {
  ModalActions,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Button } from 'common/components/button';

export const StepSetupFailed: React.FC<{}> = ({}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader
        showClose={false}
        subtitle={formatMessage('signup.unableToFinishRegistration')}
      >
        {formatMessage('signup.accountSetupFailed')}
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('common.button.close')}
            type='dark'
            onClick={() => window.location.reload()}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
