import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'common/components/modal';
import { TermEnum } from 'modules/financial-ops/common';
import { useFormatMessage } from 'modules/messages';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  useTransactionActionMutation,
} from 'modules/transactions/api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BORROWS_QUERY_KEY } from '../api';
import { StepNewBorrowSuccess } from './StepNewBorrowSuccess';
import { StepNewBorrowSummary } from './StepNewBorrowSummary';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Props = {
  collateralAmount?: string;
  collateralCurrency?: Currency;
  collateralWallet?: CurrencyWallet;
  initialLtv?: string;
  interestAmount?: string;
  interestRateTerm?: TermEnum;
  interestRatePct?: string;
  isOpen: boolean;
  maturity?: string;
  principalAmount?: string;
  principalCurrency?: Currency;
  principalWallet?: CurrencyWallet;
  token?: string;
  automaticTopupChecked: boolean;
  onClose: () => void;
};

type Step = 'summary' | 'success' | '2fa' | '2FAcancel';

export const NewBorrowModal: React.FC<Props> = ({
  collateralAmount,
  collateralCurrency,
  collateralWallet,
  initialLtv,
  interestAmount,
  interestRateTerm,
  interestRatePct,
  isOpen,
  maturity,
  principalAmount,
  principalCurrency,
  principalWallet,
  token,
  automaticTopupChecked,
  onClose,
}) => {
  const formatMessage = useFormatMessage();

  const [step, setStep] = useState<Step>('summary');
  // this is not a UI-related state, it holds data from create borrow request
  const [transactionId, setTransactionId] = useState<number>();
  const [error2fa, setError2fa] = useState<string>();

  useEffect(() => {
    setStep('summary');
    setTransactionId(undefined);
    setError2fa('');
  }, [isOpen]);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const transactionActionMutation = useTransactionActionMutation();

  const approveTransaction = (otp: string) => {
    if (!transactionId) {
      return;
    }
    transactionActionMutation.mutate(
      {
        payload: {
          transaction_id: transactionId,
          action: 'APPROVE',
        },
        headers: generateHeader2FA(otp),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
          queryClient.invalidateQueries(
            TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
          );
          queryClient.invalidateQueries(BORROWS_QUERY_KEY);
          navigate('/borrows');
        },
        onError: (error) => {
          setErrorOTP(error, setError2fa, formatMessage);
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} dataTest='borrowSummaryModal'>
      {step === 'summary' && (
        <StepNewBorrowSummary
          collateralCurrency={collateralCurrency}
          collateralWallet={collateralWallet}
          initialCollateralAmount={collateralAmount}
          initialInterestAmount={interestAmount}
          initialInterestRatePct={interestRatePct}
          initialLtv={initialLtv}
          initialMaturity={maturity}
          initialToken={token}
          interestRateTerm={interestRateTerm}
          principalAmount={principalAmount}
          principalCurrency={principalCurrency}
          principalWallet={principalWallet}
          automaticTopupChecked={automaticTopupChecked}
          onSuccess={(transactionId) => {
            setStep('success');
            setTransactionId(transactionId);
          }}
        />
      )}
      {step === 'success' && (
        <StepNewBorrowSuccess
          onClose={() => {
            onClose();
            navigate('/borrows');
          }}
          onApprove={() => {
            setStep('2fa');
          }}
        />
      )}
      {step === '2fa' && (
        <Module2FA
          onVerify={approveTransaction}
          onError={(err) => setError2fa(err)}
          error={error2fa}
          isLoading={transactionActionMutation.isLoading}
          onCancel={() => setStep('2FAcancel')}
          approvalOfTransaction={true}
          title={formatMessage('borrow.approveBorrow')}
        />
      )}
      {step === '2FAcancel' && (
        <Cancel2FA
          onClose={() => {
            onClose();
            navigate('/borrows');
          }}
          approvalOfTransaction={true}
        />
      )}
    </Modal>
  );
};
