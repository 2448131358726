import { LoaderBox } from 'common/components/loader';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import React from 'react';
import { useNotificationsPreview } from '../api';
import styles from './NotificationsMenuContent.module.scss';
import { NotificationBox } from './NotificationBox';
import { ShowAllButton } from './ShowAllButton';
import { NothingToDisplay } from 'modules/nothing-to-display';

export const NotificationsMenuContent: React.FC<{}> = ({}) => {
  const formatMessage = useFormatMessage();
  const { data, isLoading } = useNotificationsPreview();

  const menuDotsCount = (data?.results ?? []).filter(
    (notification) => !notification.is_read
  ).length;

  return (
    <div className={styles.container}>
      <div className={styles.headerText}>
        <Text style='xxbold2648' colorStyle='primary'>
          {formatMessage('common.notifications')}
        </Text>
      </div>
      {isLoading ? (
        <LoaderBox />
      ) : data && data.results.length > 0 ? (
        <>
          <div className={styles.button}>
            <ShowAllButton menuDotsCount={menuDotsCount} />
          </div>
          <div>
            {data.results.map((item) => (
              <div key={item.id}>
                <NotificationBox notification={item} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <NothingToDisplay
          text={formatMessage('notifications.nothingToDisplay')}
        />
      )}
    </div>
  );
};
