import clsx from 'clsx';
import { RefObject, useEffect, useRef } from 'react';
import styles from './Checkbox.module.scss';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  id: string;
  error?: boolean;
  dataTest?: string;
  onEnter?: () => void;
};

export const Checkbox: React.FC<Props> = ({
  onChange,
  checked,
  id,
  error,
  dataTest,
  onEnter,
}) => {
  const ref = useRef() as RefObject<HTMLInputElement>;
  useEffect(() => {
    ref.current!.checked = checked;
  });

  return (
    <label className={styles.customCheckbox} aria-label='Checkbox button'>
      <input
        type='checkbox'
        onChange={onChange}
        ref={ref}
        id={id}
        className={styles.input}
        data-test={dataTest}
        onKeyUp={(event) => {
          if (event.code === 'Enter') {
            onEnter?.();
          }
        }}
      />
      <span
        className={clsx(styles.checkmark, error && styles.errorCheckmark)}
      ></span>
    </label>
  );
};
