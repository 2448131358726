import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'common/components/modal';
import Decimal from 'decimal.js';
import { useFormatMessage } from 'modules/messages';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  TransactionActionPayload,
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  useTransactionActionMutation,
} from 'modules/transactions/api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewLendStepSuccess } from './NewLendStepSuccess';
import { NewLendStepSummary } from './NewLendStepSummary';
import { TermEnum } from 'modules/financial-ops/common';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Step = 'summary' | '2FA' | 'success' | '2FAcancel';

type Props = {
  amount: Decimal;
  currency: Currency | undefined;
  interestAmount: string | undefined;
  interestRatePct: string | undefined;
  interestRateTerm: TermEnum | undefined;
  isOpen: boolean;
  token: string | undefined;
  wallet: CurrencyWallet | undefined;
  onClose: () => void;
};

export const NewLendSummaryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  amount,
  interestRatePct,
  interestRateTerm,
  currency,
  wallet,
  interestAmount,
  token,
}) => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [error2fa, setError2fa] = useState<string>();
  const [step, setStep] = useState<Step>('summary');

  // this is not a UI-related state, it holds data from create lend request
  const [transactionId, setTransactionId] = useState<number>();

  const transactionActionMutation = useTransactionActionMutation();

  useEffect(() => {
    setStep('summary');
  }, [isOpen]);

  const onVerify = (code2fa: string) => {
    if (!transactionId) {
      return;
    }
    const payload: TransactionActionPayload = {
      transaction_id: transactionId,
      action: 'APPROVE',
    };
    transactionActionMutation.mutate(
      { payload, headers: generateHeader2FA(code2fa) },
      {
        onSuccess: () => {
          navigate('/lends');
          queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
          queryClient.invalidateQueries(
            TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
          );
        },
        onError: (error) => {
          setErrorOTP(error, setError2fa, formatMessage);
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {step === 'summary' && (
        <NewLendStepSummary
          amount={amount}
          currency={currency}
          initialInterestAmount={interestAmount}
          initialInterestRatePct={interestRatePct}
          interestRateTerm={interestRateTerm}
          wallet={wallet}
          onGoNext={(transactionId) => {
            setTransactionId(transactionId);
            setStep('success');
          }}
          initialToken={token}
        />
      )}
      {step === 'success' && (
        <NewLendStepSuccess
          onDone={() => {
            onClose();
            navigate('/lends');
          }}
          onApprove={() => setStep('2FA')}
        />
      )}
      {step === '2FA' && (
        <Module2FA
          onVerify={onVerify}
          onError={(err) => setError2fa(err)}
          error={error2fa}
          isLoading={transactionActionMutation.isLoading}
          onCancel={() => setStep('2FAcancel')}
          approvalOfTransaction={true}
          title={formatMessage('lend.approveLend')}
        />
      )}
      {step === '2FAcancel' && (
        <Cancel2FA
          onClose={() => {
            onClose();
            navigate('/lends');
          }}
          approvalOfTransaction={true}
        />
      )}
    </Modal>
  );
};
