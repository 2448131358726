import { useFormatMessage } from 'modules/messages';
import styles from './DonationsModal.module.scss';
import { Modal } from 'common/components/modal';
import { useState } from 'react';
import { StepDeposit } from './StepDeposit';
import { StepDetails } from './StepDetails';
import { PoweredByMontesauri } from '../components';
import { NewDonationContextProvider, useNewDonationContext } from './context';
import { StepThankYou } from './StepThankYou';
import { validateEmail } from 'common/utils/validators';
import { isAddressInvalidError, useDonationIntentsMutation } from '../api';
import { useCharityFormPublicContext } from '../context';
import {
  actions,
  buildRecaptchaHeader,
  isRecaptchaError,
  useHandleRecaptchaError,
  useRecaptcha,
} from 'modules/recaptcha';
import { AxiosError } from 'axios';
import { DonationIntentResponseError } from '../types';
import { prettifyError } from 'common/utils/prettify-error';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { NetworkEnum } from 'common/types';

type Props = {
  onClose: () => void;
};

type Step = 'details' | 'deposit' | 'thankyou';

const DonationsModalWrapped: React.FC<Props> = ({ onClose }) => {
  const formatMessage = useFormatMessage();

  const [step, setStep] = useState<Step>('details');

  const context = useNewDonationContext();

  const { form } = useCharityFormPublicContext();

  const donationsIntentMutation = useDonationIntentsMutation(form.token);

  const { executeRecaptcha } = useRecaptcha();

  const { handleRecaptchaError, handleRecaptchaNotLoaded } =
    useHandleRecaptchaError();

  const validateInput = () => {
    let isValid = true;

    const currency = context.currency;
    const name = context.name.trim();
    const surname = context.surname.trim();
    const email = context.email.trim();
    const company = context.company.trim();
    const walletAddress = context.walletAddress.trim();

    if (!currency) {
      context.setCurrencyError(
        formatMessage('charityForms.thisFieldIsRequired')
      );
      isValid = false;
    } else {
      context.setCurrencyError('');
    }

    if (!name) {
      context.setNameError(formatMessage('charityForms.thisFieldIsRequired'));
      isValid = false;
    } else if (name.length > 60) {
      context.setNameError(
        formatMessage('charityForms.thisFieldMustXCharactersLongOrShorter', {
          limit: 60,
        })
      );
      isValid = false;
    } else {
      context.setNameError('');
    }

    if (!surname) {
      context.setSurnameError(
        formatMessage('charityForms.thisFieldIsRequired')
      );
      isValid = false;
    } else if (surname.length > 60) {
      context.setSurnameError(
        formatMessage('charityForms.thisFieldMustXCharactersLongOrShorter', {
          limit: 60,
        })
      );
      isValid = false;
    } else {
      context.setSurnameError('');
    }

    if (email.length > 254) {
      context.setEmailError(
        formatMessage('charityForms.thisFieldMustXCharactersLongOrShorter', {
          limit: 254,
        })
      );
      isValid = false;
    } else if (email && !validateEmail(email)) {
      context.setEmailError(
        formatMessage('common.error.input.validation.email')
      );
      isValid = false;
    } else {
      context.setEmailError('');
    }

    if (company.length > 30) {
      context.setCompanyError(
        formatMessage('charityForms.thisFieldMustXCharactersLongOrShorter', {
          limit: 30,
        })
      );
      isValid = false;
    } else {
      context.setCompanyError('');
    }

    if (!context.isAnonymousDonation && !walletAddress) {
      context.setWalletAddressError(
        formatMessage('charityForms.thisFieldIsRequired')
      );
      isValid = false;
    } else if (!context.isAnonymousDonation && walletAddress.length > 60) {
      context.setWalletAddressError(
        formatMessage('charityForms.thisFieldMustXCharactersLongOrShorter', {
          limit: 60,
        })
      );
      isValid = false;
    } else {
      context.setWalletAddressError('');
    }

    return isValid;
  };

  const getErrorText = (error: AxiosError<DonationIntentResponseError>) => {
    if (isRecaptchaError(error)) {
      return ''; // dont show recaptcha error on Alert, because the snackbar will appear instead
    }
    if (isAddressInvalidError(error)) {
      return ''; // dont show this error on Alert, because it should be displayed under the address field
    }
    return prettifyError(error);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <div className={styles.container}>
        {step === 'details' && (
          <StepDetails
            error={
              donationsIntentMutation.error
                ? getErrorText(donationsIntentMutation.error)
                : undefined
            }
            onBack={onClose}
            onNext={async () => {
              const isValid = validateInput();
              if (!isValid) {
                return;
              }

              if (donationsIntentMutation.isLoading) {
                return;
              }

              if (!executeRecaptcha) {
                handleRecaptchaNotLoaded();
                return;
              }
              const token = await executeRecaptcha(actions.charityDonation);

              donationsIntentMutation.mutate(
                {
                  payload: {
                    currency: context.currency!.currency,
                    network: context.currency!.network,
                    address: !context.isAnonymousDonation
                      ? context.walletAddress.trim()
                      : undefined,
                    name: context.name.trim(),
                    surname: context.surname.trim(),
                    email: context.email.trim(),
                    company: context.company.trim(),
                    anonymous: context.isAnonymousDonation,
                  },
                  headers: buildRecaptchaHeader(token),
                },
                {
                  onSuccess: () => {
                    setStep('deposit');
                  },
                  onError: (error) => {
                    const wasRecaptchaError = handleRecaptchaError(error);
                    if (wasRecaptchaError) {
                      return;
                    }
                    const match = isAddressInvalidError(error);
                    if (match) {
                      context.setWalletAddressError(
                        formatMessage('common.invalidAddress', {
                          network: displayNetwork(match[1] as NetworkEnum),
                        })
                      );
                    }
                  },
                }
              );
            }}
          />
        )}
        {step === 'deposit' && (
          <StepDeposit
            onBack={() => setStep('details')}
            onNext={() => {
              setStep('thankyou');
            }}
          />
        )}
        {step === 'thankyou' && <StepThankYou onFinish={onClose} />}
        <PoweredByMontesauri />
      </div>
    </Modal>
  );
};

export const DonationsModal: React.FC<Props> = (props) => {
  return (
    <NewDonationContextProvider>
      <DonationsModalWrapped {...props} />
    </NewDonationContextProvider>
  );
};
