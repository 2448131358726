import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';

type Props = { ltv?: string; title?: string };

export const LTV: React.FC<Props> = ({ ltv, title }) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      {ltv && (
        <>
          <Text style='light1215' inlineStyle={{ display: 'block' }}>
            {title ? title : formatMessage('transactions.ltv') + ':'}
          </Text>
          <Text style='bold1422' inlineStyle={{ display: 'block' }}>
            {ltv + '%'}
          </Text>
        </>
      )}
    </div>
  );
};
