import clsx from 'clsx';
import { useContext } from 'react';
import { ReactComponent as ArrowLeftIcon } from 'common/assets/black-icons/arrow-left-small.svg';
import { ReactComponent as CloseIcon } from 'common/assets/black-icons/close.svg';
import { Text } from 'common/components/Text';
import { ModalContext } from './context';
import styles from './ModalHeader.module.scss';

type Props = {
  showArrow?: boolean;
  onArrowClick?: () => void;
  showClose?: boolean;
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
};

export const ModalHeader: React.FC<Props> = ({
  showArrow = false,
  onArrowClick = () => {},
  showClose = true,
  children,
  subtitle,
  className,
}) => {
  const { onClose, smallModal } = useContext(ModalContext);
  return (
    <div
      className={clsx(
        smallModal ? styles.smallContainer : styles.container,
        className
      )}
    >
      <div className={styles.headerContainer} data-test='modalTitle'>
        <div className={styles.textContainer}>
          {showArrow && (
            <ArrowLeftIcon
              onClick={onArrowClick}
              data-test='backArrow'
              className={clsx(
                styles.arrowBack,
                smallModal && styles.arrowBackSmall
              )}
            />
          )}
          <Text
            style={smallModal ? 'xxbold1619' : 'xxbold3048'}
            colorStyle={'primary'}
            inlineStyle={{ display: 'block' }}
          >
            {children}
          </Text>
        </div>
        {showClose && (
          <div
            className={clsx(
              styles.closeButtonContainer,
              smallModal && styles.closeButtonSmallContainer
            )}
            onClick={onClose}
            data-test='closeIcon'
          >
            <CloseIcon
              className={clsx(smallModal && styles.closeButtonSmall)}
            />
          </div>
        )}
      </div>
      {subtitle && (
        <div className={styles.subtitle}>
          <Text style='light1425' colorStyle={'primary'}>
            {subtitle}
          </Text>
        </div>
      )}
    </div>
  );
};
