import { ReactComponent as ArrowheadDownIcon } from 'common/assets/black-icons/arrowhead-down-12px-withpadding-grey.svg';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useCallback, useEffect, useState } from 'react';
import {
  BalanceChartMode,
  BalanceChartSingleMode,
  useBalanceChartContext,
} from './context';
import styles from './ModeSelector.module.scss';
import clsx from 'clsx';

export const balanceChartModes: BalanceChartMode[] = [
  'TOTAL',
  'AVAILABLE',
  'TOTAL_AND_AVAILABLE',
];

export const ModeSelector: React.FC<{}> = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { activeMode, changeActiveMode } = useBalanceChartContext();

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.document.addEventListener('click', close);
      }, 0);
    } else {
      window.document.removeEventListener('click', close);
    }
    return () => {
      window.document.removeEventListener('click', close);
    };
  }, [isOpen]);

  return (
    <div className={styles.container}>
      <PopupTrigger
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      {isOpen && (
        <div
          className={styles.popup}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {balanceChartModes.map((mode) => (
            <PopupItem
              key={mode}
              mode={mode}
              isSelected={mode === activeMode}
              onClick={() => changeActiveMode(mode)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const PopupTrigger: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const formatMessage = useFormatMessage();

  const { activeMode } = useBalanceChartContext();

  return (
    <div className={styles.popupTrigger} onClick={onClick}>
      {activeMode === 'AVAILABLE' && (
        <>
          <Text style='regular1215'>
            {formatMessage('balanceChart.availableBalance')}
          </Text>
          <ModeDot mode={'AVAILABLE'} />
        </>
      )}
      {activeMode === 'TOTAL' && (
        <>
          <Text style='regular1215'>
            {formatMessage('balanceChart.totalBalance')}
          </Text>
          <ModeDot mode={'TOTAL'} />
        </>
      )}
      {activeMode === 'TOTAL_AND_AVAILABLE' && (
        <>
          <Text style='regular1215'>{formatMessage('common.total')}</Text>
          <ModeDot mode={'TOTAL'} />
          <Text style='regular1215'>
            {formatMessage('balanceChart.available')}
          </Text>
          <ModeDot mode={'AVAILABLE'} />
        </>
      )}
      <ArrowheadDownIcon />
    </div>
  );
};

export const ModeDot: React.FC<{ mode: BalanceChartSingleMode }> = ({
  mode,
}) => {
  const className =
    mode === 'AVAILABLE' ? styles.modeDotAvailable : styles.modeDotTotal;
  return <div className={clsx(styles.modeDot, className)} />;
};

const ModeDots: React.FC<{ mode: BalanceChartMode }> = ({ mode }) => {
  return (
    <div className={styles.modeDotsContainer}>
      {['TOTAL', 'TOTAL_AND_AVAILABLE'].includes(mode) && (
        <ModeDot mode='TOTAL' />
      )}
      {['AVAILABLE', 'TOTAL_AND_AVAILABLE'].includes(mode) && (
        <ModeDot mode='AVAILABLE' />
      )}
    </div>
  );
};

const PopupItem: React.FC<{
  isSelected: boolean;
  mode: BalanceChartMode;
  onClick: () => void;
}> = ({ isSelected, mode, onClick }) => {
  const formatMessage = useFormatMessage();

  const modeLabel =
    mode === 'TOTAL_AND_AVAILABLE'
      ? formatMessage('balanceChart.showAll')
      : mode === 'AVAILABLE'
      ? formatMessage('balanceChart.availableBalance')
      : mode === 'TOTAL'
      ? formatMessage('balanceChart.totalBalance')
      : mode; // this will happen if someone adds new mode, bot forgets to add label

  return (
    <div className={styles.popupItem} onClick={onClick}>
      <Text style={isSelected ? 'xbold1215' : 'light1215'}>{modeLabel}</Text>
      <ModeDots mode={mode} />
    </div>
  );
};
