import { Text } from 'common/components/Text';
import styles from './UserInfo.module.scss';
import { ReactComponent as VerifiedIcon } from './assets/verified.svg';
import { Tooltip } from 'common/components/Tooltip';
import { useFormatMessage } from 'modules/messages';

type Props = { name: string; surname: string; isKycCompleted: boolean };

export const UserName: React.FC<Props> = ({
  name,
  surname,
  isKycCompleted,
}) => {
  const formatMessage = useFormatMessage();

  //this has been converted into a list because there may be a multiple first or last name in which case there will be more than one spaces
  const userNameArray = `${name} ${surname}`.split(' ');

  return (
    <div>
      {userNameArray.map((word, i) => (
        <Text
          key={word}
          className={styles.name}
          style='bold1622'
          inlineStyle={{
            whiteSpace: i === userNameArray.length - 1 ? 'nowrap' : 'initial',
          }}
        >
          {word}
          {isKycCompleted && i === userNameArray.length - 1 ? (
            <Tooltip
              title={formatMessage('user.verifiedViaPersonaKYCProcess')}
              tooltipClassName={styles.maturityTooltip}
            >
              <VerifiedIcon style={{ cursor: 'pointer', marginLeft: '10px' }} />
            </Tooltip>
          ) : (
            ' '
          )}
        </Text>
      ))}
    </div>
  );
};
