import D from 'decimal.js';
import { CryptoToFiatAmount, CurrencyRate } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import { BaseDataField } from 'modules/common-data-fields';
import { CurrencyEnum } from 'common/types';

type Props = {
  expected_interest_daily: string;
  currency_rate: CurrencyRate;
  isFinished: boolean;
  currency: CurrencyEnum;
};

export const DailyEarn: React.FC<Props> = ({
  expected_interest_daily,
  currency_rate,
  isFinished,
  currency,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <BaseDataField
      label={formatMessage('saving.dailyEarn')}
      value={`${new D(expected_interest_daily).toFixed()} ${currency}`}
      valueTextStyle='xxbold1619'
      secondaryValue={
        !isFinished && (
          <CryptoToFiatAmount
            amount={expected_interest_daily}
            currency={currency_rate.currency}
            currencyRate={currency_rate}
          />
        )
      }
    />
  );
};
