import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { formatDatetime } from 'common/utils/utils';

type Props = { startAt: string };

export const OpenedDate: React.FC<Props> = ({ startAt }) => {
  const formatMessage = useFormatMessage();

  return (
    <Text
      style='light1215'
      colorStyle='secondary'
      dataTest='openedDate'
      inlineStyle={{ display: 'block' }}
    >
      {formatMessage('common.opened', {
        date: formatDatetime(startAt),
      })}
    </Text>
  );
};
