import { Text } from 'common/components/Text';
import { useFeatureFlag } from 'modules/feature-flags';
import { useFormatMessage } from 'modules/messages';
import { useCompanyKyxState } from './hooks';
import styles from './KyxWalletAddressPlaceholder.module.scss';

export const KyxWalletAddressPlaceholder: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const kybFeatureFlag = useFeatureFlag('KYB');

  const { data } = useCompanyKyxState();

  if (!data) {
    return null;
  }

  const { kyb, kyc } = data;

  if (
    kyc === 'COMPLETED' &&
    (kybFeatureFlag.data?.enabled ? kyb === 'COMPLETED' : true)
  ) {
    return null;
  }

  const text =
    kyc === 'INITIAL'
      ? formatMessage('kyx.completeKycToShowAddress')
      : formatMessage('kyx.completeKybToShowAddress');

  return (
    <Text className={styles.container} style='light1215' colorStyle='secondary'>
      {text}
    </Text>
  );
};
