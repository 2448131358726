import clsx from 'clsx';
import { textStyles, Text } from 'common/components/Text';
import styles from './Input.module.scss';
import { BasicInputProps } from './types';

export const Input: React.FC<BasicInputProps> = ({
  onChange,
  onEnter,
  onBackspace,
  value,
  disabled = false,
  readonly = false,
  placeholder,
  type = 'text',
  errorText,
  dataTest,
  id,
  autofocus,
  className,
  style,
  onBlur,
}) => {
  return (
    <>
      <input
        value={value}
        type={type}
        style={style}
        disabled={disabled}
        readOnly={readonly}
        placeholder={placeholder}
        autoComplete='new-password'
        className={clsx(
          textStyles.light1425,
          styles.input,
          errorText && styles.errorInput,
          className
        )}
        data-test={dataTest}
        id={id}
        autoFocus={autofocus}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={(event) => {
          if (event.code === 'Enter') {
            onEnter?.();
          }
          if (event.code === 'Backspace') {
            onBackspace?.();
          }
        }}
      />
      {errorText && (
        <Text style='regular1215' colorStyle={'error'} dataTest='errorText'>
          {errorText}
        </Text>
      )}
    </>
  );
};
