import clsx from 'clsx';
import { Text } from 'common/components/Text';
import { BorrowMeasuresPeriod } from 'modules/financial-ops/borrow/types';
import { MessageKey, useFormatMessage } from 'modules/messages';
import styles from './PeriodSelector.module.scss';

type Props = {
  selectedPeriod: BorrowMeasuresPeriod;
  onSelectPeriod: (period: BorrowMeasuresPeriod) => void;
  showAllTime: boolean;
};

export const PeriodSelector: React.FC<Props> = ({
  selectedPeriod,
  onSelectPeriod,
  showAllTime,
}) => {
  const periods = ['1D', '1W', '1M', '3M'] as BorrowMeasuresPeriod[];
  if (showAllTime) {
    periods.push('ALL');
  }

  return (
    <div className={styles.container}>
      {periods.map((period) => (
        <PeriodButton
          period={period}
          isSelected={selectedPeriod === period}
          onClick={() => onSelectPeriod(period)}
          key={period}
        />
      ))}
    </div>
  );
};

type PeriodButtonProps = {
  period: BorrowMeasuresPeriod;
  onClick: () => void;
  isSelected: boolean;
};

const PERIOD_LABEL_KEYS: { [key in BorrowMeasuresPeriod]: MessageKey } = {
  ALL: 'borrow.allTime',
  '1D': 'borrow.1dayAbbr',
  '1W': 'borrow.1weekAbbr',
  '1M': 'borrow.1monthAbbr',
  '3M': 'borrow.3monthsAbbr',
};

const PeriodButton: React.FC<PeriodButtonProps> = ({
  period,
  onClick,
  isSelected,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <Text
      className={clsx(styles.periodButton)}
      style={isSelected ? 'xbold1215' : 'light1215'}
      onClick={onClick}
      key={period}
    >
      {formatMessage(PERIOD_LABEL_KEYS[period])}
    </Text>
  );
};
