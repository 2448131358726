import { CurrencyEnum } from 'common/types';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { BoldAmount } from 'modules/common-data-fields/BoldAmount';

type Props = { fee: string; currency: CurrencyEnum };

export const LiquidationFee: React.FC<Props> = ({ fee, currency }) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text style='light1215' inlineStyle={{ display: 'block' }}>
        {formatMessage('transactions.liquidationFee') + ':'}
      </Text>
      <BoldAmount amount={fee} currency={currency} />
    </div>
  );
};
