import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { log } from 'modules/logger';
import { WalletNewEvent } from '../types/wallet-new-event';
import { Wallet } from 'modules/assets-and-wallets/types';
import { WALLETS_QUERY_KEY } from 'modules/assets-and-wallets/api';
import { CURRENCIES_QUERY_KEY } from 'modules/select-currency-wallet/api';
import { Currency } from 'modules/select-currency-wallet/types';

export const useHandleWalletNew = () => {
  const queryClient = useQueryClient();

  const handleWalletNew = useCallback(
    (event: WalletNewEvent) => {
      log('handling WALLET_NEW WebSocket event', event);

      const newWallet = event.wallet;

      queryClient.setQueryData(
        WALLETS_QUERY_KEY,
        (prevState: Wallet[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          // Check if the user already had a given wallet in the list with the PENDING status. If not then add the whole object, if yes edit the found wallet.
          const idx = prevState.findIndex(
            (wallet) => wallet.id === newWallet.id
          );

          if (idx === -1) {
            return [...prevState, newWallet];
          }

          return [
            ...prevState.slice(0, idx),
            newWallet,
            ...prevState.slice(idx + 1),
          ];
        }
      );

      queryClient.setQueryData(
        CURRENCIES_QUERY_KEY,
        (prevState: Currency[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          const newState: typeof prevState = prevState.map((currency) => {
            const foundBalance = newWallet.balances.find(
              (bal) =>
                currency.currency === bal.currency &&
                newWallet.network === currency.network
            );

            if (!foundBalance) {
              return currency;
            }

            return {
              ...currency,
              wallets: [
                ...currency.wallets,
                {
                  name: newWallet.name,
                  balance: foundBalance.amount,
                  address: newWallet.address,
                  id: newWallet.id,
                },
              ],
            };
          });

          return newState;
        }
      );
    },
    [queryClient]
  );

  return handleWalletNew;
};
