import { useState } from 'react';
import * as R from 'ramda';
import { useFormatMessage } from 'modules/messages';
import styles from './CharityForms.module.scss';
import { useCharityForms } from '../api';
import { LoaderBox } from 'common/components/loader';
import {
  PopupSorter,
  SortConfig,
} from 'common/components/table-subcomponents/sort/PopupSorter';
import {
  ascCmpAmounts,
  ascComp,
  descCmpAmounts,
  descComp,
} from 'common/utils/comparators';
import { SortValue } from 'common/components/table-subcomponents';
import { PopupFilter } from 'common/components/table-subcomponents/filter/PopupFilter';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { BoxLayout } from 'modules/financial-ops/common/components';
import { useFilters } from 'modules/financial-ops/common/hooks';
import { CharityFormRead } from '../types';
import { CharityFormBox } from './components/CharityFormBox';
import { ActiveFormsCount } from './components/ActiveFormsCount';
import { useFiatCurrency } from 'modules/fiat';

type SortModel = {
  collected_funds?: SortValue;
  created_at?: SortValue;
  end_at?: SortValue;
};

export const CharityForms: React.FC = () => {
  const formatMessage = useFormatMessage();

  const { data: charityForms = [], isLoading: charityFormsIsLoading } =
    useCharityForms();
  const { data: fiatCurrency, isLoading: isFiatCurrencyLoading } =
    useFiatCurrency();

  const [sort, setSort] = useState<SortModel>({});

  const [filter, filterConfig] = useFilters();

  const getSortedAndFilteredData = () => {
    const filteredForms = filter.active
      ? R.filter(
          (item: CharityFormRead) => item.state === 'ACTIVE',
          charityForms
        )
      : filter.finished
      ? R.filter(
          (item: CharityFormRead) => item.state === 'COMPLETED',
          charityForms
        )
      : charityForms;

    if (sort.collected_funds) {
      return R.sort(
        (item1, item2) =>
          (sort.collected_funds === 'asc' ? ascCmpAmounts : descCmpAmounts)(
            item1.total_raised_fiat,
            item2.total_raised_fiat
          ),
        filteredForms
      );
    }

    if (sort.created_at) {
      return R.sort(
        (item1, item2) =>
          (sort.created_at === 'asc' ? ascComp : descComp)(
            item1.created_at,
            item2.created_at
          ),
        filteredForms
      );
    }

    if (sort.end_at) {
      return R.sort(
        (item1, item2) =>
          (sort.end_at === 'asc' ? ascComp : descComp)(
            item1.end_at || new Date().toISOString(),
            item2.end_at || new Date().toISOString()
          ),
        filteredForms
      );
    }

    return filteredForms;
  };

  const sortList: SortConfig[] = [
    {
      sort: sort.collected_funds,
      onSort: (next: SortValue) => {
        setSort({ collected_funds: next });
      },
      text: formatMessage('charityForms.collectedFunds'),
    },
    {
      sort: sort.end_at,
      onSort: (next: SortValue) => {
        setSort({ end_at: next });
      },
      text: formatMessage('charityForms.endDate'),
    },
    {
      sort: sort.created_at,
      onSort: (next: SortValue) => {
        setSort({ created_at: next });
      },
      text: formatMessage('common.openingDate'),
    },
  ];

  const sortedAndFilteredData = getSortedAndFilteredData();

  if (charityFormsIsLoading || isFiatCurrencyLoading) {
    return <LoaderBox />;
  }

  return (
    <div>
      <div className={styles.subHeader}>
        <ActiveFormsCount />
        <div className={styles.sortFilter}>
          <PopupFilter filters={filterConfig} />
          <PopupSorter config={sortList} />
        </div>
      </div>
      <BoxLayout>
        {sortedAndFilteredData.map((form) => (
          <CharityFormBox
            form={form}
            key={form.id}
            fiatCurrency={fiatCurrency!}
          />
        ))}
      </BoxLayout>
      {sortedAndFilteredData.length === 0 && <NothingToDisplay />}
    </div>
  );
};
