import { ReactComponent as WalletSmall } from 'common/assets/grey-icons/wallet-small-grey.svg';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { Text } from 'common/components/Text';
import { CryptoToFiatAmount } from 'modules/fiat';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { KyxWalletAddressPlaceholder, useIsKyxCompleted } from 'modules/kyx';
import { Wallet, WalletBalance } from '../types';
import styles from './AssetRow.module.scss';
import { CopyableAddress } from './CopyableAddress';

type Props = {
  asset: {
    balance: WalletBalance;
    wallet: Wallet;
  };
  dataTest?: string;
};

export const AssetRow: React.FC<Props> = ({ asset, dataTest }) => {
  const { balance, wallet } = asset;
  const { amount, currency } = balance;

  const isKyxCompleted = useIsKyxCompleted();

  return (
    <div className={styles.row} data-test={dataTest}>
      <CurrencyBox
        currency={currency}
        network={wallet.network}
        className={styles.cellCurrency}
      />
      <div className={styles.cellWalletName}>
        <WalletSmall className={styles.walletIcon} />
        <Text style='light1622' className={styles.walletName}>
          {wallet.name}
        </Text>
        <Text
          style='light1215'
          colorStyle={'secondary'}
          className={styles.mobileWalletName}
        >
          {wallet.name}
        </Text>
      </div>
      <div className={styles.cellAmount}>
        <Text
          style='bold1622'
          colorStyle={isKyxCompleted ? 'primary' : 'inactive'}
        >
          {`${toLimitedPrec(toFixed(amount))} ${currency}`}
        </Text>
        <CryptoToFiatAmount
          amount={balance.amount}
          currency={balance.currency}
          textColorStyle={isKyxCompleted ? 'secondary' : 'inactive'}
        />
      </div>
      <div className={styles.cellWalletAddress}>
        <KyxWalletAddressPlaceholder />
        {wallet.address && isKyxCompleted && (
          <CopyableAddress address={wallet.address} />
        )}
      </div>
    </div>
  );
};
