import { CancelButton, NextButton } from 'common/components/button';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { MAX_INPUT_CHARACTERS_LIMIT_LONG } from 'common/consts/consts';
import { validateLengthLong } from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';

type Props = {
  name: string;
  onNameChange: (name: string) => void;
  onGoNext: () => void;
  onCancel: () => void;
};

export const StepName: React.FC<Props> = ({
  name,
  onNameChange,
  onGoNext,
  onCancel,
}) => {
  const formatMessage = useFormatMessage();

  const [nameError, setNameError] = useState('');

  const validateAndSubmit = () => {
    if (!name.trim()) {
      setNameError(formatMessage('addressWhitelist.errors.nameRequired'));
      return;
    }
    if (!validateLengthLong(name.trim())) {
      setNameError(
        formatMessage('common.error.characters.limit', {
          limit: MAX_INPUT_CHARACTERS_LIMIT_LONG,
        })
      );
      return;
    }
    setNameError('');
    onGoNext();
  };

  const validateAndChange = (value: string) => {
    if (!value.trim()) {
      setNameError(formatMessage('addressWhitelist.errors.nameRequired'));
    } else if (!validateLengthLong(value.trim())) {
      setNameError(
        formatMessage('common.error.characters.limit', {
          limit: MAX_INPUT_CHARACTERS_LIMIT_LONG,
        })
      );
    } else {
      setNameError('');
    }
    onNameChange(value);
  };

  return (
    <>
      <ModalHeader>
        {formatMessage('addressWhitelist.addWhitelistedAddress')}
      </ModalHeader>
      <ModalBody>
        <InputWithHeader
          header={formatMessage('addressWhitelist.insertAddressName')}
          value={name}
          onChange={(e) => validateAndChange(e.target.value)}
          autofocus={true}
          onEnter={validateAndSubmit}
          errorText={nameError}
          dataTest='addressNameInput'
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <NextButton onClick={validateAndSubmit} />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
