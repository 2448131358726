import clsx from 'clsx';
import { ReactComponent as ArrowheadDown } from 'common/assets/black-icons/arrowhead-down-14px.svg';
import { ReactComponent as ArrowheadUp } from 'common/assets/black-icons/arrowhead-up-14px.svg';
import { NetworkBadge } from 'common/components/NetworkBadge';
import { Text } from 'common/components/Text';
import { FiatAmount } from 'modules/fiat';
import { KyxWalletAddressPlaceholder, useIsKyxCompleted } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { useWalletTotalValue } from '../hooks';
import { Wallet } from '../types';
import { CopyableAddress } from './CopyableAddress';
import { WalletBalanceRow } from './WalletBalanceRow';
import styles from './WalletRow.module.scss';

type Props = {
  wallet: Wallet;
  dataTest?: string;
};

export const WalletRow: React.FC<Props> = ({ wallet, dataTest }) => {
  const formatMessage = useFormatMessage();

  const [expanded, setExpaned] = useState(false);

  const isKyxCompleted = useIsKyxCompleted();

  const { data: walletTotalValue, isLoading: isWalletTotalValueLoading } =
    useWalletTotalValue(wallet.id);

  const isPending = wallet.state === 'PENDING';

  return (
    <div
      className={clsx(styles.container, !expanded && styles.containerNotExpand)}
    >
      <div
        className={clsx(
          styles.row,
          expanded && styles.rowExpanded,
          !isPending && styles.clickable
        )}
        onClick={() => {
          if (isPending) {
            return;
          }
          setExpaned((expanded) => !expanded);
        }}
        data-test={dataTest}
      >
        <div className={styles.cellExpand} data-test='expandRow'>
          {!isPending &&
            (expanded ? (
              <ArrowheadUp data-test='arrowUp' />
            ) : (
              <ArrowheadDown data-test='arrowDwon' />
            ))}
        </div>
        <div className={styles.cellName}>
          <Text
            style='bold1622'
            colorStyle={isPending ? 'secondary' : 'primary'}
          >
            {wallet.name}
          </Text>
        </div>
        <div className={styles.cellNetwork}>
          <NetworkBadge network={wallet.network} inactive={isPending} />
        </div>
        <div className={styles.cellValue}>
          {isPending ? (
            <FiatAmount
              amount={'0'}
              textStyle='bold1622'
              textColorStyle={'secondary'}
            />
          ) : (
            <FiatAmount
              amount={walletTotalValue}
              isDataLoading={isWalletTotalValueLoading}
              textStyle='bold1622'
              textColorStyle={isKyxCompleted ? 'primary' : 'inactive'}
            />
          )}
        </div>
        {isPending ? (
          <div className={styles.cellPending}>
            <Text style='light1622' colorStyle='secondary'>
              {formatMessage('wallets.pending')}
            </Text>
          </div>
        ) : (
          <>
            <div className={styles.cellAddress}>
              <KyxWalletAddressPlaceholder />
              {wallet.address && isKyxCompleted && (
                <CopyableAddress
                  address={wallet.address}
                  dataTest='walletAddress'
                />
              )}
            </div>
          </>
        )}
      </div>
      {expanded &&
        wallet.balances.map((balance) => (
          <WalletBalanceRow
            balance={balance}
            wallet={wallet}
            key={`${wallet.id}-${balance.currency}`}
            dataTest='walletBalanceRow'
          />
        ))}
    </div>
  );
};
