import styles from './WalletQRCode.module.scss';
import { QRCodeCanvas } from 'third-party/qrcode.react';
import { useSelectCurrencyWalletContext } from 'modules/select-currency-wallet';
import { theme } from 'common/consts/theme';

type Props = {};

export const WalleQRCode: React.FC<Props> = ({}) => {
  const { wallet, isOpenModal } = useSelectCurrencyWalletContext();

  return (
    <div className={styles.container}>
      {wallet?.address && (
        <QRCodeCanvas
          value={wallet.address}
          size={150}
          fgColor={isOpenModal ? theme.colors.backgroundOverlay : '#000'}
          data-test='QRcode'
        />
      )}
    </div>
  );
};
