import clsx from 'clsx';
import { Text } from 'common/components/Text';

import styles from './CellText.module.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
  dataTest?: string;
};

export const CellTextBold: React.FC<Props> = ({
  className,
  children,
  dataTest,
}) => (
  <Text
    style='bold1622'
    className={clsx(styles.cellText, className)}
    dataTest={dataTest}
  >
    {children}
  </Text>
);

export const CellTextLight: React.FC<Props> = ({
  className,
  children,
  dataTest,
}) => (
  <Text
    style='light1622'
    className={clsx(styles.cellText, className)}
    colorStyle='secondary'
    dataTest={dataTest}
  >
    {children}
  </Text>
);
