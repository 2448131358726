import { subDays } from 'date-fns';

export const getDateByDays = (dateFrom: number) => {
  const now = new Date();

  if (dateFrom) {
    const dateTmp = subDays(now, dateFrom);
    return dateTmp.toISOString();
  }
};
