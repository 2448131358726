import { useFormatMessage } from 'modules/messages';
import styles from './Donations.module.scss';
import { FormDonationPublicRead } from '../types';
import { Text } from 'common/components/Text';
import { CURRENCY_SYMBOLS } from 'modules/fiat';
import { toFixed } from 'modules/input-amount';
import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { useCharityFormPublicContext } from '../context';

export const Donations: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { donations, form } = useCharityFormPublicContext();

  const onlyPrimary = !form.secondary_color;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text style='xxbold2233'>
          {formatMessage('charityForms.donations')}
        </Text>
        <div className={styles.sortContainer}>
          <Text
            className={styles.sort}
            inlineStyle={{
              background: form.primary_color,
              color: onlyPrimary ? '#FFFFFF' : '#000000',
            }}
            style='xxbold1218'
          >
            {formatMessage('charityForms.latest')}
          </Text>
        </div>
      </div>
      {donations.length ? (
        <div className={styles.donations}>
          {donations.map((donation) => (
            <Donation
              key={`${donation.created_at}-${donation.name}-${donation.surname}`}
              donation={donation}
            />
          ))}
        </div>
      ) : (
        <NothingToDisplay
          text={formatMessage('charityForms.thereAreNoDonationsYet')}
          size={120}
          iconSize={60}
        />
      )}
    </div>
  );
};

const Donation: React.FC<{ donation: FormDonationPublicRead }> = ({
  donation,
}) => {
  const formatMessage = useFormatMessage();

  const name = donation.anonymous
    ? formatMessage('charityForms.anonymous')
    : `${donation.name} ${donation.surname}.`; // surname is provided by API as only the first letter of actual surname.

  const amount =
    CURRENCY_SYMBOLS[donation.fiat] + toFixed(donation.amount_fiat);

  return (
    <div className={styles.donation}>
      <CurrencyIcon iconSize='size20' currencyName={donation.currency} />
      <Text style='light1624' className={styles.donatorsName}>
        {name}
      </Text>
      <Text style='light1624'>{amount}</Text>
    </div>
  );
};
