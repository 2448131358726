import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { PAGE_SIZE } from 'common/consts/consts';
import { apiBaseURL, get, post } from 'modules/api';
import { Notification } from './types';
import { useRefreshKyx } from 'modules/kyx';

// URLs
export const NOTIFICATIONS_URL = '/notifications';

export const UNREAD_COUNT_NOTIFICATIONS_URL = '/notifications/unread-count';

export const MARK_ALL_READ_URL = '/notifications/mark-all-as-read';

// query keys
export const NOTIFICATIONS_PREVIEW_QUERY_KEY = [
  'GET',
  NOTIFICATIONS_URL,
  'PREVIEW',
] as const;

export const UNREAD_COUNT_NOTIFICATIONS_QUERY_KEY = [
  'GET',
  UNREAD_COUNT_NOTIFICATIONS_URL,
] as const;

const MARK_AS_READ_QUERY_KEY = ['POST', NOTIFICATIONS_URL] as const;

const MARK_ALL_READ_QUERY_KEY = ['POST', MARK_ALL_READ_URL] as const;

export const NOTIFICATIONS_INFINITE_QUERY_KEY = [
  'GET',
  NOTIFICATIONS_URL,
  'INFINITE',
] as const;

export type NotificationResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Notification[];
};

export type ResponseError = {
  detail?: string;
};

export const buildNotificationsUrl = (
  page: number,
  options?: {
    withApiBaseUrl?: boolean;
  }
) => {
  const params = new URLSearchParams();
  params.append('page', page.toString());
  params.append('page_size', PAGE_SIZE.toString());
  return (
    (options?.withApiBaseUrl ? apiBaseURL : '') +
    NOTIFICATIONS_URL +
    '?' +
    params.toString()
  );
};

export const getNotificationsPreview = async () => {
  const response = await get<NotificationResponse>(buildNotificationsUrl(1));
  return response.data;
};

export const useNotificationsPreview = () => {
  const refreshKyx = useRefreshKyx();

  const query = useQuery<NotificationResponse, AxiosError<any>>(
    NOTIFICATIONS_PREVIEW_QUERY_KEY,
    () => getNotificationsPreview(),
    {
      // this is a HACK.
      // problem: user reads "KYB completed" notification, but the data in app is not up-to-date and features requiring KYB are still blocked.
      // solution (hack): refresh user data (containing KYB data) when KYB_COMPLETED notification is fetched.
      // why this solution is bad: notifications are messages for user to read, they should not be used to control application flow.
      onSuccess: (data) => {
        const notifications = data.results;
        if (
          notifications.some(
            (notification) => notification.event.kind === 'KYB_COMPLETED'
          )
        ) {
          refreshKyx();
        }
      },
    }
  );
  return query;
};

export const getNotificationsInfinite = async (page: number = 1) => {
  const response = await get<NotificationResponse>(buildNotificationsUrl(page));
  return response.data;
};

export const useNotficationsInfinite = () => {
  const query = useInfiniteQuery<NotificationResponse, AxiosError<any>>(
    NOTIFICATIONS_INFINITE_QUERY_KEY,
    ({ pageParam }) => getNotificationsInfinite(pageParam)
  );
  return query;
};

export type UnreadNotficationsCountResponse = {
  count: number;
};

export const getUnreadNotficationsCount = async () => {
  const response = await get<UnreadNotficationsCountResponse>(
    UNREAD_COUNT_NOTIFICATIONS_URL
  );
  return response.data;
};

export const useUnreadNotificationsCount = () => {
  return useQuery<UnreadNotficationsCountResponse, AxiosError<ResponseError>>(
    UNREAD_COUNT_NOTIFICATIONS_QUERY_KEY,
    getUnreadNotficationsCount
  );
};

export type NotificationMarkAsReadRequset = {
  id: number;
};

export const markAsReadNotification = (id: number) =>
  post<NotificationResponse>(`${NOTIFICATIONS_URL}/${id}/mark-as-read`);

export const useMarkAsReadNotificationMutation = () => {
  return useMutation<
    AxiosResponse<NotificationResponse>,
    AxiosError<ResponseError>,
    number
  >(MARK_AS_READ_QUERY_KEY, (id) => markAsReadNotification(id));
};

export const markAllAsReadNotification = () =>
  post<NotificationResponse>(MARK_ALL_READ_URL);

export const useMarkAllAsReadNotificationMutation = () => {
  return useMutation<
    AxiosResponse<NotificationResponse>,
    AxiosError<ResponseError>,
    void
  >(MARK_ALL_READ_QUERY_KEY, markAllAsReadNotification);
};
