import clsx from 'clsx';
import { SectionHeader } from 'common/components/SectionHeader';
import styles from './SectionTemplate.module.scss';

type Props = {
  header: string;
  activeCount: React.ReactNode;
  mainValue: React.ReactNode;
  subValues: React.ReactNode;
  buttons: React.ReactNode;
  chart: React.ReactNode;
  isVerticalLayout?: boolean;
};

export const SectionTemplate: React.FC<Props> = ({
  header,
  activeCount,
  mainValue,
  subValues,
  buttons,
  chart,
  isVerticalLayout,
}) => {
  return (
    <div
      className={clsx(
        styles.container,
        isVerticalLayout && styles.verticalLayout
      )}
    >
      <div>
        <SectionHeader header={header} />
        <div className={styles.activeCount}>{activeCount}</div>
        {mainValue}
        <div
          className={clsx(
            styles.subAmounts,
            isVerticalLayout && styles.moreSpace
          )}
        >
          {subValues}
        </div>
      </div>
      <div className={clsx(styles.spaceBetween)}>
        {chart}
        <div className={styles.buttons}>{buttons}</div>
      </div>
    </div>
  );
};
