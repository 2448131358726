import clsx from 'clsx';
import dompurify from 'dompurify';
import { ReactComponent as SuccessIcon } from 'common/assets/black-icons/ok.svg';
import { ReactComponent as WarningIcon } from 'common/assets/black-icons/warning.svg';
import { ReactComponent as CloseIcon } from 'common/assets/black-icons/close.svg';
import { ReactComponent as ErrorIcon } from 'common/assets/black-icons/error.svg';
import { textStyles } from 'common/components/Text';
import styles from './Alert.module.scss';

export type Severity = 'error' | 'success' | 'warning' | 'secondary';

export type AlertProps = {
  text: React.ReactNode;
  severity: Severity;
  className?: string;
  style?: React.CSSProperties;
  onClose?: () => void;
};

export const Alert: React.FC<AlertProps> = ({
  text,
  severity,
  className,
  style,
  onClose,
}) => {
  const alertProps = {
    error: { style: styles.error, icon: <ErrorIcon /> },
    success: { style: styles.success, icon: <SuccessIcon /> },
    warning: { style: styles.warning, icon: <WarningIcon /> },
    secondary: { style: styles.secondary, icon: undefined },
  };

  return (
    <div
      className={clsx(
        textStyles.light1425,
        styles.container,
        alertProps[severity].style,
        alertProps[severity].icon && styles.gap,
        className
      )}
      style={style}
      data-test='alert'
    >
      <div className={clsx(alertProps[severity].icon && styles.severityIcon)}>
        {alertProps[severity].icon}
      </div>
      {typeof text === 'string' ? (
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: sanitize(text) }}
        />
      ) : (
        <div className={styles.text}>{text}</div>
      )}
      {onClose && <CloseIcon className={styles.closeIcon} onClick={onClose} />}
    </div>
  );
};

const sanitize = (text: string) =>
  dompurify.sanitize(text, {
    ALLOWED_TAGS: ['br', 'strong'],
  });
