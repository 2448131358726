import { Tooltip } from 'common/components/Tooltip';
import { useFeatureFlag } from 'modules/feature-flags';
import { useFormatMessage } from 'modules/messages';
import { useCompanyKyxState } from './hooks';

type Props = {
  children: React.ReactElement;
  extraTitle?: string;
};

export const KyxTooltip: React.FC<Props> = ({ children, extraTitle = '' }) => {
  const kybFeatureFlag = useFeatureFlag('KYB');

  const formatMessage = useFormatMessage();

  const { data } = useCompanyKyxState();

  const title =
    data?.kyc === 'INITIAL'
      ? formatMessage('kyx.finishKycToUnlockThisFeature')
      : !kybFeatureFlag.data?.enabled
      ? extraTitle
      : data?.kyb === 'INITIAL' ||
        data?.kyb === 'PENDING' ||
        data?.kyb === 'FAILED'
      ? formatMessage('kyx.finishKybToUnlockThisFeature')
      : extraTitle;

  return <Tooltip title={title}>{children}</Tooltip>;
};
