import { Text } from 'common/components/Text';
import styles from './PeriodSelector.module.scss';
import { BalanceChartPeriod, useBalanceChartContext } from './context';
import clsx from 'clsx';
import { useFormatMessage } from 'modules/messages';

export const balanceChartPeriods: BalanceChartPeriod[] = [
  '1H',
  '1D',
  '1W',
  '1M',
  '3M',
  '1Y',
  'ALL',
];

export const PeriodSelector: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { activePeriod, changeActivePeriod } = useBalanceChartContext();

  const getPeriodLabel = (period: BalanceChartPeriod) => {
    switch (period) {
      case '1D':
        return formatMessage('balanceChart.24hoursAbbr');
      case '1H':
        return formatMessage('balanceChart.1hourAbbr');
      case '1M':
        return formatMessage('balanceChart.1monthAbbr');
      case '1W':
        return formatMessage('balanceChart.1weekAbbr');
      case '1Y':
        return formatMessage('balanceChart.1yearAbbr');
      case '3M':
        return formatMessage('balanceChart.3monthsAbbr');
      case 'ALL':
        return formatMessage('balanceChart.allTime');
    }
  };

  return (
    <div className={styles.container}>
      {balanceChartPeriods.map((period) => (
        <Text
          key={period}
          style='bold1115'
          className={clsx(
            styles.period,
            period === activePeriod ? styles.activePeriod : undefined
          )}
          onClick={() => changeActivePeriod(period)}
        >
          {getPeriodLabel(period)}
        </Text>
      ))}
    </div>
  );
};
