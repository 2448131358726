import styles from './TextArea.module.scss';
import { Text } from '../Text';
import { TextArea } from './TextArea';
import { TextareaHTMLAttributes } from 'react';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  title: string;
  errorText?: string;
  dataTest?: string;
};

export const TextAreaWithHeader: React.FC<Props> = ({
  title,
  errorText,
  dataTest,
  ...props
}) => {
  return (
    <>
      <div className={styles.headerText}>
        <Text style='xbold1417' colorStyle={'primary'}>
          {title}
        </Text>
      </div>
      <TextArea {...props} errorText={errorText} dataTest={dataTest} />
    </>
  );
};
