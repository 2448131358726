import { SectionHeader } from 'common/components/SectionHeader';
import { LoaderBox } from 'common/components/loader';
import { openSnackbar } from 'common/components/snackbar';
import { isStablecoin } from 'common/utils/currency-enum-utils';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import {
  findCurrency,
  getHighestBalanceWallet,
  TermAndRateSelector,
  TermEnum,
  useInterestRatesExistForKind,
  useSortedInterestRates,
} from 'modules/financial-ops/common';
import {
  ConfigSection,
  NewOperationLayout,
  NavigationHeader,
  SummarySection,
} from 'modules/financial-ops/common/components';
import { AssetAmountInput } from 'modules/financial-ops/common/components/AssetAmountInput';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { KyxAlert, useIsKyxCompleted } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { BaseCurrency } from 'modules/select-currency-wallet/types';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BorrowsEffectPayload,
  collateralMaxError,
  collateralMinError,
  interestRateDoesNotExist,
  principalMaxError,
  principalMinError,
  useBorrowsEffectMutation,
} from '../api';
import { BorrowLocationState, BorrowCalculationBase } from '../types';
import { BorrowEffectCalculator } from './BorrowEffectCalculator';
import styles from './NewBorrow.module.scss';
import { NewBorrowModal } from './NewBorrowModal';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';
import { CURRENCY_RATES_QUERY_KEY } from 'modules/fiat';
import { useQueryClient } from '@tanstack/react-query';
import { prettifyError } from 'common/utils/prettify-error';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';

export const NewBorrow: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const location = useLocation() as { state?: BorrowLocationState };
  const { data: currencies, isLoading } = useGetCurrenciesWithWallets();

  const interestRateKind = 'BORROW';

  const [selectedRateError, setSelectedRateError] = useState('');

  const [calculationBase, setCalculationBase] =
    useState<BorrowCalculationBase>('PRINCIPAL');

  const currencyParam = location.state?.currency;
  const networkParam = location.state?.network;
  const termParam = location.state?.term;

  // UGLY HACK WARNING!!! Count how many times Max button was clicked.
  // Use it to make sure new request to /effect api is send on every click
  const [maxCount, setMaxCount] = useState<number>(0);

  const [principalAmount, setPrincipalAmount] = useState(INITIAL_AMOUNT);
  const [principalAmountAndAssetError, setPrincipalAmountAndAssetError] =
    useState('');
  const [principalCurrencyKey, setPrincipalCurrencyKey] = useState<
    BaseCurrency | undefined
  >(
    currencyParam && networkParam
      ? { currency: currencyParam, network: networkParam }
      : undefined
  );
  const [principalWalletKey, setPrincipalWalletKey] = useState<
    number | undefined
  >(location.state?.walletId);
  const [principalWalletError, setPrincipalWalletError] = useState('');

  const [collateralAmount, setCollateralAmount] = useState(INITIAL_AMOUNT);
  const [collateralAmountAndAssetError, setCollateralAmountAndAssetError] =
    useState('');
  const [collateralCurrencyKey, setCollateralCurrencyKey] = useState<
    BaseCurrency | undefined
  >();
  const [collateralInfo, setCollateralInfo] = useState('');
  const [collateralWalletError, setCollateralWalletError] = useState('');
  const [collateralWalletKey, setCollateralWalletKey] = useState<
    number | undefined
  >();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [automaticTopupChecked, setAutomaticTopupChecked] = useState(true);

  const collateralCurrency = currencies?.find(
    (item) =>
      item.currency === collateralCurrencyKey?.currency &&
      item.network === collateralCurrencyKey.network
  );

  const principalCurrency = currencies?.find(
    (item) =>
      item.currency === principalCurrencyKey?.currency &&
      item.network === principalCurrencyKey.network
  );

  const collateralWallet = collateralWalletKey
    ? (collateralCurrency?.wallets || []).find(
        (val) => val.id === collateralWalletKey
      )
    : getHighestBalanceWallet(collateralCurrency?.wallets);

  const principalWallet = principalWalletKey
    ? (principalCurrency?.wallets || []).find(
        (val) => val.id === principalWalletKey
      )
    : getHighestBalanceWallet(principalCurrency?.wallets);

  const borrowsEffectMutation = useBorrowsEffectMutation();

  const { data: rates } = useSortedInterestRates({
    sortDirection: 'desc',
    kind: interestRateKind,
  });

  const [rateKey, setRateKey] = useState<TermEnum | undefined>(
    termParam ? termParam : undefined
  );

  const rate = rates?.find(
    (val) =>
      val.currency === principalCurrency?.currency &&
      val.network === principalCurrency?.network &&
      (rateKey ? val.term === rateKey : true)
  );

  const principalCurrencies = currencies?.filter((currency) =>
    isStablecoin(currency.currency)
  );

  const collateralCurrencies = currencies?.filter(
    (currency) =>
      !(
        currency.network === principalCurrency?.network &&
        currency.currency === principalCurrency?.currency
      )
  );

  const getInitialToken = () => {
    if (
      !rate ||
      !calculationBase ||
      !collateralWallet ||
      !principalAmount ||
      !principalCurrency ||
      !principalWallet ||
      !collateralCurrency
    ) {
      return;
    }

    setCalculationBase('PRINCIPAL');

    let payload: BorrowsEffectPayload = {
      calculation_base: 'PRINCIPAL',
      term: rate.term!,
      collateral_currency: collateralCurrency.currency,
      collateral_wallet_id: collateralWallet.id,
      principal_currency: principalCurrency.currency,
      principal_wallet_id: principalWallet.id,
      principal_amount: toFixed(principalAmount),
      auto_topup: automaticTopupChecked,
      summary: true,
    };

    borrowsEffectMutation.mutate(payload, {
      onSuccess: () => {
        setIsModalOpen(true);
      },
      onError: (error) => {
        queryClient.invalidateQueries(CURRENCY_RATES_QUERY_KEY);
        const collateralMinErrorMatch = collateralMinError(
          error.response?.data
        );
        if (collateralMinErrorMatch) {
          setCollateralAmountAndAssetError(
            formatMessage('borrow.collateralAmountMustGreater', {
              amount: toFixed(collateralMinErrorMatch[1]),
              currency: payload.collateral_currency,
            })
          );
          return;
        }

        const collateralMaxErrorMatch = collateralMaxError(
          error.response?.data
        );
        if (collateralMaxErrorMatch) {
          setCollateralAmountAndAssetError(
            formatMessage('borrow.collateralAmountMustLess', {
              amount: toFixed(collateralMaxErrorMatch[1]),
              currency: payload.collateral_currency,
            })
          );
          return;
        }

        const principalMinErrorMatch = principalMinError(error.response?.data);
        if (principalMinErrorMatch) {
          setPrincipalAmountAndAssetError(
            formatMessage('borrow.principalAmountMustGreater', {
              amount: toFixed(principalMinErrorMatch[1]),
              currency: payload.principal_currency,
            })
          );
          return;
        }

        const principalMaxErrorMatch = principalMaxError(error.response?.data);
        if (principalMaxErrorMatch) {
          setPrincipalAmountAndAssetError(
            formatMessage('borrow.principalAmountMustLess', {
              amount: toFixed(principalMaxErrorMatch[1]),
              currency: payload.principal_currency,
            })
          );
          return;
        }

        if (interestRateDoesNotExist(error.response?.data)) {
          setPrincipalAmountAndAssetError(
            formatMessage('finopsCommon.noInterestRateWasFound')
          );
          return;
        }

        setPrincipalAmountAndAssetError(prettifyError(error));
      },
    });
  };

  const continueToSummary = () => {
    // check for input errors
    if (!principalCurrency) {
      setPrincipalAmountAndAssetError(
        formatMessage('borrow.principalCurrencyNotSelected')
      );
      return;
    }
    if (!principalWallet) {
      setPrincipalWalletError(
        formatMessage('borrow.principalWalletNotSelected')
      );
      return;
    }
    if (!collateralCurrency) {
      setCollateralAmountAndAssetError(
        formatMessage('borrow.collateralCurrencyNotSelected')
      );
      return;
    }
    if (!collateralWallet) {
      setCollateralWalletError(
        formatMessage('borrow.collateralWalletNotSelected')
      );
      return;
    }
    if (!rate) {
      setSelectedRateError(formatMessage('finopsCommon.rateNotSelected'));
      return;
    }
    if (toDecimal(principalAmount).eq(0)) {
      setPrincipalAmountAndAssetError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }
    if (toDecimal(collateralAmount).eq(0)) {
      setCollateralAmountAndAssetError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }

    // check if error is already set from a different place than this handler
    if (principalAmountAndAssetError || collateralAmountAndAssetError) {
      return;
    }

    // check for snackbar errors
    if (toDecimal(collateralAmount).gt(collateralWallet.balance)) {
      openSnackbar(
        formatMessage('borrow.insufficientCollateralFunds'),
        'warning'
      );
      return;
    }

    getInitialToken();
  };

  const borrowFeatureFlag = useRequireFeatureFlag('BORROW');
  const newBorrowFeatureFlag = useRequireFeatureFlag('CREATING_BORROW');
  const ratesExists = useInterestRatesExistForKind('BORROW', {
    redirect: true,
  });

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const isKyxCompleted = useIsKyxCompleted();

  useEffect(() => {
    if (!isKyxCompleted) {
      closeModal();
    }
  }, [isKyxCompleted, closeModal]);

  if (
    borrowFeatureFlag.isLoading ||
    newBorrowFeatureFlag.isLoading ||
    ratesExists.isLoading
  ) {
    return <LoaderBox />;
  }

  if (
    !borrowFeatureFlag.data?.enabled ||
    !newBorrowFeatureFlag.data?.enabled ||
    !ratesExists.data
  ) {
    return null;
  }

  return (
    <NewOperationLayout className={styles.layout}>
      <ConfigSection>
        <NavigationHeader to='/borrows'>
          {formatMessage('common.back')}
        </NavigationHeader>
        <SectionHeader header={formatMessage('borrow.newBorrow')} />
        <KyxAlert className={styles.kycAlert} />
        <AssetAmountInput
          amount={principalAmount}
          currency={principalCurrency}
          wallets={principalCurrency?.wallets}
          desktopHeader={formatMessage('borrow.selectPrincipalAndTypeAmount')}
          error={principalAmountAndAssetError}
          interestRateKind={interestRateKind}
          mobileAssetHeader={formatMessage('borrow.principalCurrency')}
          mobileAmountHeader={formatMessage('borrow.principalAmount')}
          showApr={true}
          wallet={principalWallet}
          walletError={principalWalletError}
          onAmountChange={(value) => {
            setPrincipalAmount(value);
            setCalculationBase('PRINCIPAL');
          }}
          onCurrencyChange={(currency, rate) => {
            const foundCurrency = findCurrency(currency, currencies);
            setPrincipalCurrencyKey(foundCurrency);

            const foundWallet = getHighestBalanceWallet(foundCurrency?.wallets);
            if (foundWallet) {
              setPrincipalWalletKey(foundWallet.id);
            }

            setRateKey(rate?.term);
            setSelectedRateError('');
            setPrincipalAmountAndAssetError('');
            if (
              collateralCurrency?.currency === currency.currency &&
              collateralCurrency?.network === currency.network
            ) {
              setCollateralAmount(INITIAL_AMOUNT);
              setCollateralCurrencyKey(undefined);
              setCollateralWalletKey(undefined);
              setCollateralAmountAndAssetError('');
              setCollateralWalletError('');
            }
            // "deactivate" Max button, if was clicked before
            if (calculationBase === 'COLLATERAL_MAX') {
              setCalculationBase('PRINCIPAL');
            }
          }}
          onWalletChange={(value) => {
            setPrincipalWalletKey(value.id);
            setPrincipalWalletError('');
          }}
          onMax={() => {
            setCalculationBase('COLLATERAL_MAX');
            setMaxCount(maxCount + 1); // UGLY HACK WARNING!!!
          }}
          onError={(error) => setPrincipalAmountAndAssetError(error)}
          currencies={principalCurrencies}
          isLoadingCurrencies={isLoading}
          dataTest='principalField'
        />
        <AssetAmountInput
          amount={collateralAmount}
          currency={collateralCurrency}
          wallets={collateralCurrency?.wallets}
          desktopHeader={formatMessage('borrow.selectCollateralAndTypeAmount')}
          error={collateralAmountAndAssetError}
          interestRateKind={interestRateKind}
          info={collateralInfo}
          mobileAssetHeader={formatMessage('borrow.collateralCurrency')}
          mobileAmountHeader={formatMessage('borrow.collateralAmount')}
          showApr={false}
          wallet={collateralWallet}
          walletError={collateralWalletError}
          onAmountChange={(value) => {
            setCalculationBase('COLLATERAL');
            setCollateralAmount(value);
            setCollateralInfo('');
          }}
          onCurrencyChange={(currency) => {
            const foundCurrency = findCurrency(currency, currencies);
            setCollateralCurrencyKey(foundCurrency);

            const foundWallet = getHighestBalanceWallet(foundCurrency?.wallets);
            if (foundWallet) {
              setCollateralWalletKey(foundWallet.id);
            }

            setCollateralAmountAndAssetError('');

            // recalculate effect with principal amount because the limits vary widely from one collateral currency to another and have a different number of decimal places
            setCalculationBase('PRINCIPAL');
          }}
          onWalletChange={(value) => {
            setCollateralWalletKey(value.id);
            setCollateralWalletError('');
          }}
          onError={(error) => setCollateralAmountAndAssetError(error)}
          currencies={collateralCurrencies}
          isLoadingCurrencies={isLoading}
          dataTest='collateralField'
        />
        <TermAndRateSelector
          currency={principalCurrency}
          selectedRate={rate}
          onSelectRate={(rate) => {
            setRateKey(rate.term);
            setSelectedRateError('');
          }}
          error={selectedRateError}
          interestRateKind={interestRateKind}
          noDataText={formatMessage('borrow.thereAreNoBorrows')}
        />
      </ConfigSection>
      <SummarySection dataTest='borrowSummarySection'>
        <BorrowEffectCalculator
          interestRate={rate}
          collateralAmount={collateralAmount}
          collateralCurrency={collateralCurrency?.currency}
          collateralWalletId={collateralWallet?.id}
          principalAmount={principalAmount}
          principalCurrency={principalCurrency?.currency}
          principalWalletId={principalWallet?.id}
          calculationBase={calculationBase}
          maxCount={maxCount} // UGLY HACK WARNING!!!
          onAutomaticTopupChange={(val) => setAutomaticTopupChecked(val)}
          automaticTopupChecked={automaticTopupChecked}
          onEffectCalculated={(effect, payload) => {
            // HACK / TO INVESTIGATE / TO IMPROVE
            // calculation_base that was used for the effect API call is passed to this callback as function argument,
            // because otherwise calculationBase state variable in NewBorrow at the moment of calling the callback
            // contains different value (causing incorrenct collateralAmount/principalAmount state variable update)
            setCollateralAmountAndAssetError('');
            setPrincipalAmountAndAssetError('');
            if (
              payload.calculation_base === 'PRINCIPAL' ||
              payload.calculation_base === 'COLLATERAL_MAX'
            ) {
              setCollateralAmount(
                toLimitedPrec(toFixed(effect.collateral_amount))
              );
            }
            if (
              payload.calculation_base === 'COLLATERAL' ||
              payload.calculation_base === 'COLLATERAL_MAX'
            ) {
              setPrincipalAmount(
                toLimitedPrec(toFixed(effect.principal_amount))
              );
            }
            if (
              payload.calculation_base === 'COLLATERAL_MAX' &&
              effect.limit_exceeded
            ) {
              setCollateralInfo(
                formatMessage('common.reducedToMaximalAllowedAmount')
              );
            } else {
              setCollateralInfo('');
            }
          }}
          onEffectError={(error, payload) => {
            let match: RegExpMatchArray | undefined;
            match = collateralMinError(error);
            if (match) {
              setCollateralAmountAndAssetError(
                formatMessage('borrow.collateralAmountMustGreater', {
                  amount: toFixed(match[1]),
                  currency: payload.collateral_currency,
                })
              );
              setPrincipalAmount(INITIAL_AMOUNT);
              setPrincipalAmountAndAssetError('');
              if (payload.calculation_base === 'COLLATERAL_MAX') {
                setCollateralAmount(INITIAL_AMOUNT);
              }
            }
            match = collateralMaxError(error);
            if (match) {
              setCollateralAmountAndAssetError(
                formatMessage('borrow.collateralAmountMustLess', {
                  amount: toFixed(match[1]),
                  currency: payload.collateral_currency,
                })
              );
              setPrincipalAmount(INITIAL_AMOUNT);
              setPrincipalAmountAndAssetError('');
            }
            match = principalMinError(error);
            if (match) {
              setPrincipalAmountAndAssetError(
                formatMessage('borrow.principalAmountMustGreater', {
                  amount: toFixed(match[1]),
                  currency: payload.principal_currency,
                })
              );
              setCollateralAmount(INITIAL_AMOUNT);
              setCollateralAmountAndAssetError('');
            }
            match = principalMaxError(error);
            if (match) {
              setPrincipalAmountAndAssetError(
                formatMessage('borrow.principalAmountMustLess', {
                  amount: toFixed(match[1]),
                  currency: payload.principal_currency,
                })
              );
              setCollateralAmount(INITIAL_AMOUNT);
              setCollateralAmountAndAssetError('');
            }
            if (interestRateDoesNotExist(error)) {
              setPrincipalAmountAndAssetError(
                formatMessage('finopsCommon.interestRateDoesNotExist')
              );
            }
          }}
          onContinueToSummary={continueToSummary}
        />
      </SummarySection>
      <NewBorrowModal
        collateralAmount={borrowsEffectMutation.data?.data.collateral_amount}
        collateralCurrency={collateralCurrency}
        collateralWallet={collateralWallet}
        initialLtv={borrowsEffectMutation.data?.data.ltv_pct}
        interestAmount={borrowsEffectMutation.data?.data.interest}
        interestRatePct={borrowsEffectMutation.data?.data.rate_pct}
        interestRateTerm={borrowsEffectMutation.data?.data.term}
        isOpen={isModalOpen}
        maturity={borrowsEffectMutation.data?.data.end_at}
        principalAmount={borrowsEffectMutation.data?.data.principal_amount}
        principalCurrency={principalCurrency}
        principalWallet={principalWallet}
        token={borrowsEffectMutation.data?.data.token}
        automaticTopupChecked={automaticTopupChecked}
        onClose={closeModal}
      />
    </NewOperationLayout>
  );
};
