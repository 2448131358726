import { useFormatMessage } from 'modules/messages';
import { useEffect, useState } from 'react';
import { StepSuccess } from './StepSuccess';
import { StepChangeOwner } from './StepChangeOwner';
import { StepSummary } from './StepSummary';
import { Modal } from 'common/components/modal';
import {
  ERROR_MEMBER_DOESNOT_EXIST,
  useChangeOwnerMutation,
} from 'modules/settings/api';
import { Member } from 'modules/company-members/api';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Steps = 'changeOwner' | 'summary' | '2FA' | 'success' | '2FAcancel';
type Props = { onClose: () => void; open: boolean };

export const ChangeOwnerModal: React.FC<Props> = ({ onClose, open }) => {
  const formatMessage = useFormatMessage();
  const [checked, setChecked] = useState(false);
  const [newOwner, setNewOwner] = useState<Member>();
  const [step, setStep] = useState<Steps>('changeOwner');
  const [error2FA, setError2FA] = useState<string>();
  const [error, setError] = useState<string>();
  const { mutate, isLoading } = useChangeOwnerMutation();

  useEffect(() => {
    setStep('changeOwner');
    setNewOwner(undefined);
    setError2FA(undefined);
    setChecked(false);
    setError(undefined);
  }, [open]);

  const changeOwner = () => {
    if (newOwner)
      mutate(
        {
          payload: {
            member_id: newOwner.id,
          },
        },
        {
          onSuccess: () => {
            setStep('2FA');
          },
          onError: (err) => {
            if (
              err.response?.data.member_id &&
              err.response?.data.member_id[0] === ERROR_MEMBER_DOESNOT_EXIST
            )
              setError(formatMessage('settings.memberDoesNotExist'));
          },
        }
      );
  };

  const onVerify = (keys: string) => {
    if (newOwner)
      mutate(
        {
          payload: {
            member_id: newOwner.id,
          },
          headers: generateHeader2FA(keys),
        },
        {
          onSuccess: () => {
            setStep('success');
          },
          onError: (error) => {
            setErrorOTP(error, setError2FA, formatMessage);
          },
        }
      );
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      {step === 'changeOwner' && (
        <StepChangeOwner
          onChecked={(checked) => {
            setChecked(checked);
            if (!checked) setNewOwner(undefined);
          }}
          checked={checked}
          newOwner={newOwner}
          onSelect={(member) => setNewOwner(member)}
          onClick={() => setStep('summary')}
          onCancel={onClose}
        />
      )}
      {step === 'summary' && (
        <StepSummary
          onClick={changeOwner}
          newOwner={newOwner}
          onClose={onClose}
          onGoBack={() => {
            setStep('changeOwner');
            setNewOwner(undefined);
            setChecked(false);
          }}
          error={error}
          isLoading={isLoading}
        />
      )}
      {step === '2FA' && (
        <Module2FA
          title={formatMessage('settings.changeOwner')}
          onVerify={onVerify}
          onError={(err) => setError2FA(err)}
          error={error2FA}
          isLoading={isLoading}
          onCancel={() => setStep('2FAcancel')}
        />
      )}
      {step === 'success' && (
        <StepSuccess onClose={onClose} newOwner={newOwner} />
      )}
      {step === '2FAcancel' && <Cancel2FA onClose={onClose} />}
    </Modal>
  );
};
