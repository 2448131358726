import clsx from 'clsx';
import { Text } from 'common/components/Text';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import styles from './NotificationCircle.module.scss';
import React from 'react';

type Props = { data: number; isLoading?: boolean; className?: string };

export const NotificationCircle: React.FC<Props> = ({
  data,
  isLoading,
  className,
}) => {
  return (
    <Text
      style='xxxbold1113'
      className={clsx(styles.notification, className)}
      colorStyle='lightNavbar'
    >
      {isLoading ? <RotatingLoader size={7} type='secondary' /> : data}
    </Text>
  );
};
