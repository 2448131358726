import { Modal } from 'common/components/modal';
import { ModalBody, ModalHeader } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Search } from 'common/components/search/Search';
import { useState } from 'react';
import {
  InterestRate,
  InterestRateKind,
  useSortedInterestRates,
} from 'modules/financial-ops/common';
import { LoaderBox } from 'common/components/loader';
import { useSavingsLimitsQuery } from 'modules/financial-ops/savings-account/api';
import { isSavingPossibleForCurrency } from 'modules/financial-ops/savings-account/helpers';
import styles from './CurrencySelectorModal.module.scss';
import { CurrencyItem } from './CurrencyItem';
import { BaseCurrency } from 'modules/select-currency-wallet/types';
import { NothingToDisplay } from 'modules/nothing-to-display';

type Props = {
  open: boolean;
  onClose: () => void;
  interestRateKind?: InterestRateKind;
  onChange?: (currency: BaseCurrency, rate?: InterestRate) => void;
  currencies?: BaseCurrency[];
  isLoadingCurrencies?: boolean;
  showApr?: boolean;
};

export const CurrencySelectorModal: React.FC<Props> = ({
  open,
  onClose,
  currencies,
  isLoadingCurrencies,
  interestRateKind,
  onChange,
  showApr,
}) => {
  const formatMessage = useFormatMessage();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { data: savingLimits, isLoading: isLoadingSavingLimits } =
    useSavingsLimitsQuery({ enabled: interestRateKind === 'SAVING' });

  // for lends and savings, the highest interest rate is best for the user, for borrows, it's the lowest
  const { data: rates, isLoading: isLoadingRates } = useSortedInterestRates({
    sortDirection:
      interestRateKind === 'LEND' || interestRateKind === 'SAVING'
        ? 'desc'
        : 'asc',
    kind: interestRateKind,
  });

  const filteredList = currencies?.filter(
    (token) =>
      token.currency.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (interestRateKind === 'SAVING'
        ? isSavingPossibleForCurrency(token.currency, savingLimits?.data)
        : true)
  );

  const isLoading =
    isLoadingCurrencies ||
    (interestRateKind && isLoadingRates) ||
    (interestRateKind === 'SAVING' && isLoadingSavingLimits);

  return (
    <Modal onClose={onClose} isOpen={open} smallModal={true}>
      <ModalHeader>
        {formatMessage('selectCurrency.stepSelectCurrency.header')}
      </ModalHeader>
      <ModalBody dataTest='selectCurrencyModal'>
        {isLoading && <LoaderBox />}
        {!isLoading && (
          <>
            <Search onFilter={setSearchQuery} dataTest='searchAsset' />
            <div className={styles.container}>
              {filteredList?.length &&
                (showApr ? rates : true) &&
                filteredList.map((element, index) => (
                  <CurrencyItem
                    selectedCurrency={element}
                    onSelect={(currency, rate) => {
                      if (onChange) onChange(currency, rate);
                      onClose();
                    }}
                    key={index}
                    rates={rates}
                    showApr={showApr}
                  />
                ))}
              {(!filteredList?.length || (showApr && !rates)) && (
                <NothingToDisplay />
              )}
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
