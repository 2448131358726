import { createContext, useCallback, useContext, useState } from 'react';

export type BalanceChartMode = 'AVAILABLE' | 'TOTAL' | 'TOTAL_AND_AVAILABLE';

export type BalanceChartSingleMode = 'AVAILABLE' | 'TOTAL';

export type BalanceChartPeriod =
  | '1D'
  | '1H'
  | '1M'
  | '1W'
  | '1Y'
  | '3M'
  | 'ALL';

type BalanceChartContextValue = {
  activeMode: BalanceChartMode;
  activePeriod: BalanceChartPeriod;
  changeActiveMode: (mode: BalanceChartMode) => void;
  changeActivePeriod: (period: BalanceChartPeriod) => void;
};

const defaultMode: BalanceChartMode = 'TOTAL_AND_AVAILABLE';
const defaultPeriod: BalanceChartPeriod = '1D';

const BalanceChartContext = createContext<BalanceChartContextValue>({
  activeMode: defaultMode,
  activePeriod: defaultPeriod,
  changeActiveMode: () => {},
  changeActivePeriod: () => {},
});

export const BalanceChartContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [activeMode, setActiveMode] = useState<BalanceChartMode>(defaultMode);

  const [activePeriod, setActivePeriod] =
    useState<BalanceChartPeriod>(defaultPeriod);

  const changeActiveMode = useCallback((mode: BalanceChartMode) => {
    setActiveMode(mode);
  }, []);

  const changeActivePeriod = useCallback((period: BalanceChartPeriod) => {
    setActivePeriod(period);
  }, []);

  return (
    <BalanceChartContext.Provider
      value={{
        activeMode,
        activePeriod,
        changeActiveMode,
        changeActivePeriod,
      }}
    >
      {children}
    </BalanceChartContext.Provider>
  );
};

export const useBalanceChartContext = () => {
  return useContext(BalanceChartContext);
};
