import clsx from 'clsx';
import { NotificationDot } from 'common/components/NotificationDot';
import { NotificationsMenuContent } from './components/NotificationsMenuContent';
import { useHamburgerMenuContext } from 'modules/navigation/context';
import styles from './NotificationsMenu.module.scss';
import { useUnreadNotificationsCount } from './api';
import { useNotificationsMenuContext } from './context';

export const NotificationsMenu: React.FC<{}> = ({}) => {
  const { isOpen, closeNotifications, toggleNotifications } =
    useNotificationsMenuContext();
  const { closeHamburger } = useHamburgerMenuContext();

  const { data } = useUnreadNotificationsCount();

  return (
    <div className={styles.container} data-test='notificationsBtn'>
      {!!data?.count && <NotificationDot className={styles.dot} />}
      <div
        className={clsx(styles.bellIcon, isOpen && styles.closeIcon)}
        onClick={() => {
          toggleNotifications();
          closeHamburger();
        }}
      />
      {isOpen && (
        <>
          <div
            className={styles.catchClickContainer}
            onClick={closeNotifications}
          />
          <div className={styles.menuContainer}>
            <NotificationsMenuContent />
          </div>
        </>
      )}
    </div>
  );
};
