import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Text } from 'common/components/Text';
import { ApproveTransactionText } from 'modules/2FA';
import { CurrentThreshold } from 'modules/financial-ops/common/components/CurrentThreshold';
import { useFormatMessage } from 'modules/messages';
import { useIsSigner } from 'modules/user';

type Props = {
  onDone: () => void;
  onApprove: () => void;
};

export const NewLendStepSuccess: React.FC<Props> = ({ onDone, onApprove }) => {
  const formatMessage = useFormatMessage();

  const { data: isSigner } = useIsSigner();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('lend.lendHasBeenInitiated')}
      </ModalHeader>
      <ModalBody dataTest='lendSuccessModal'>
        <div style={{ marginBottom: '30px' }}>
          <Text style='light1425'>
            {formatMessage('lend.processCompletedAfterReachingThreshold')}
          </Text>
        </div>
        <CurrentThreshold />
        {isSigner && (
          <div style={{ marginTop: '30px' }}>
            <ApproveTransactionText />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          {isSigner ? (
            <>
              <Button
                type='secondary'
                onClick={onDone}
                name={formatMessage('common.button.later')}
              />
              <Button
                type='dark'
                onClick={onApprove}
                name={formatMessage('common.button.approve')}
              />
            </>
          ) : (
            <Button
              type='dark'
              onClick={onDone}
              name={formatMessage('common.button.done')}
            />
          )}
        </ModalActions>
      </ModalFooter>
    </>
  );
};
