import clsx from 'clsx';
import { Text } from 'common/components/Text';
import { ReactComponent as FilterIcon } from 'common/assets/black-icons/filter.svg';
import styles from './PopupFilter.module.scss';
import { useFormatMessage } from 'modules/messages';
import { FilterText } from './FilterText';
import { useCallback, useEffect, useState } from 'react';

export type FilterConfig = {
  onFilter?: (next: boolean | undefined) => void;
  filter?: boolean;
  text: string;
  className?: string;
};

type Props = {
  filters?: FilterConfig[];
  className?: string;
  isOnLeft?: boolean;
};

export const PopupFilter: React.FC<Props> = ({
  filters,
  className,
  isOnLeft = false,
}) => {
  const formatMessage = useFormatMessage();

  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.document.body.addEventListener('click', close);
      }, 0);
    } else {
      window.document.body.removeEventListener('click', close);
    }
    return () => {
      window.document.body.removeEventListener('click', close);
    };
  }, [isOpen]);

  return (
    <div className={clsx(className)}>
      <div
        className={clsx(styles.filter)}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Text style='xbold1417'>{formatMessage('common.filter')}</Text>
        <FilterIcon />
      </div>
      <div className={styles.container}>
        {isOpen && (
          <div
            className={clsx(
              styles.menuContainer,
              isOnLeft ? styles.positionLeft : styles.positionRight
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {filters?.map((item: FilterConfig, index) => (
              <FilterText
                filter={item.filter}
                onFilter={item.onFilter}
                key={index}
                className={styles.filterItems}
              >
                {item.text}
              </FilterText>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
