import { Button } from 'common/components/button';
import { openSnackbar } from 'common/components/snackbar';
import { formatDate } from 'common/utils/utils';
import { useFormatMessage } from 'modules/messages';
import { useUserQuery } from 'modules/user';
import { useCallback, useEffect, useState } from 'react';
import { TransactionFilters, useTransactionExportMutation } from '../api';
import { useInfiniteTransactionsContext } from '../filters';
import styles from './ExportButton.module.scss';
import { Text } from 'common/components/Text';
import { subDays } from 'date-fns';

type Props = {};

export const ExportButton: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.document.body.addEventListener('click', close);
      }, 0);
    } else {
      window.document.body.removeEventListener('click', close);
    }
    return () => {
      window.document.body.removeEventListener('click', close);
    };
  }, [isOpen]);

  const { areAnyFiltersSelected, filters } = useInfiniteTransactionsContext();

  const exportMutation = useTransactionExportMutation();

  const { data: userData } = useUserQuery();

  const downloadFile = async (filters: TransactionFilters) => {
    try {
      const response = await exportMutation.mutateAsync(filters);

      const companyName = userData?.data.company.name;

      const now = new Date();
      const range =
        filters.dateFrom && filters.dateTo
          ? `${formatDate(filters.dateFrom)}-${formatDate(filters.dateTo)}`
          : filters.dateFrom
          ? `${formatDate(filters.dateFrom)}-${formatDate(now.toISOString())}`
          : filters.dateTo
          ? `up to ${formatDate(filters.dateTo)}`
          : filters.lastDays === 1
          ? `${formatDate(subDays(now, 1).toISOString())}-${formatDate(
              now.toISOString()
            )}`
          : filters.lastDays === 7
          ? `${formatDate(subDays(now, 7).toISOString())}-${formatDate(
              now.toISOString()
            )}`
          : filters.lastDays === 30
          ? `${formatDate(subDays(now, 30).toISOString())}-${formatDate(
              now.toISOString()
            )}`
          : '';

      const filename =
        `${companyName} ${formatMessage('transactions.transactions')} ${range}`
          .trim()
          .split(' ')
          .join('_') + `.csv`;

      const blob = new Blob([response.data], {
        type: 'text/csv;charset=utf-8;',
      });
      const tmp = document.createElement('a');

      tmp.href = URL.createObjectURL(blob);
      tmp.setAttribute('download', filename);
      tmp.click();
    } catch (error) {
      openSnackbar(
        formatMessage('transactions.somethingWentWrongWhenDownloading'),
        'error'
      );
    }
  };

  if (!userData) {
    return null;
  }

  return (
    <div>
      <Button
        name={formatMessage('transactions.export')}
        onClick={() => {
          if (areAnyFiltersSelected) {
            setIsOpen(!isOpen);
            return;
          }
          downloadFile({});
        }}
        type={'secondary'}
        disabled={!userData || exportMutation.isLoading}
        isLoading={exportMutation.isLoading}
      />
      <div className={styles.container}>
        {isOpen && (
          <div
            className={styles.menuContainer}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Text
              className={styles.menuItem}
              style='light1215'
              onClick={() => {
                downloadFile(filters);
              }}
            >
              {formatMessage('transactions.exportCurrentSelection')}
            </Text>
            <Text
              className={styles.menuItem}
              style='light1215'
              onClick={() => {
                downloadFile({});
              }}
            >
              {formatMessage('transactions.exportAll')}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
