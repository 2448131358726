import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useContext } from 'react';
import { Text } from 'common/components/Text';

type Props = {
  newName: string;
  newSurname: string;
  email: string;
  prevName: string;
  prevSurname: string;
  onRecover: () => void;
};

export const AccountRecovery: React.FC<Props> = ({
  newName,
  newSurname,
  email,
  prevName,
  prevSurname,
  onRecover,
}) => {
  const { onClose } = useContext(ModalContext);

  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('inviteMembers.accountRecovery')}
      </ModalHeader>
      <ModalBody>
        <div>
          <Text style='light1425'>
            {formatMessage('inviteMembers.memberWasInCompany', {
              newName,
              newSurname,
              email,
            })}
          </Text>
          <Text style='xbold1425'>
            {prevName} {prevSurname}
            {'. '}
          </Text>
          <Text style='light1425'>
            {formatMessage('inviteMembers.recoveredAccountIncludesHistory')}
          </Text>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Text style='xbold1425'>
            {formatMessage('inviteMembers.doYouWantToRecoverAccount')}
          </Text>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('common.button.cancel')}
            type='secondary'
            onClick={onClose}
          />
          <Button
            name={formatMessage('inviteMembers.recover')}
            type='dark'
            onClick={onRecover}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
