import { Text } from 'common/components/Text';
import styles from './WarningAlert.module.scss';
import { ReactComponent as WarningIcon } from 'common/assets/black-icons/warning.svg';
import clsx from 'clsx';

type Props = {
  className?: string;
  header: string;
  body: string;
};

export const WarningAlert: React.FC<Props> = ({ className, header, body }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.header}>
        <WarningIcon />
        <Text style='bold1425'>{header}</Text>
      </div>
      <Text style='light1215'>{body}</Text>
    </div>
  );
};
