import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  SAVING_QUERY_KEY,
  savingHasTopupTransactionInPendingState,
  savingHasWithdrawalTransactionInPendingState,
  savingIsNotActive,
  TopupEffectResponseError,
  TopupEffectResponseData,
  TopupEffectPayload,
  useTopupEffectMutation,
  SAVINGS_QUERY_KEY,
  amountExceedsMaxToAllocate,
  globalLimitReached,
  noPossibilityToAllocateMoreFunds,
  noFundsInWallet,
} from 'modules/financial-ops/savings-account/api';
import debounce from 'lodash/debounce';
import { useFormatMessage } from 'modules/messages';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { useTopupContext } from './context';
import { TopupCalculationBase } from '../types';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';

type InputData = {
  amount?: string;
  wallet?: number;
  calculationBase: TopupCalculationBase;
};

export const useCalculateEffect = () => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const context = useTopupContext();
  const mutation = useTopupEffectMutation(context.saving?.id!);

  const onEffectCalculated = useCallback(
    (effect: TopupEffectResponseData, payload: TopupEffectPayload) => {
      context.setErrorAlert('');
      context.setAmountError('');

      if (payload.calculation_base === 'AMOUNT') {
        return;
      }

      context.setAmount(toLimitedPrec(toFixed(effect.amount)));
      if (effect.limit_exceeded) {
        context.setAmountInfo(
          formatMessage('common.reducedToMaximalAllowedAmount')
        );
        return;
      }
    },
    []
  );

  const onEffectError = useCallback(
    (error: TopupEffectResponseError, payload: TopupEffectPayload) => {
      const amountExceedsMaxToAllocateMatch = amountExceedsMaxToAllocate(error);

      if (amountExceedsMaxToAllocateMatch) {
        context.setAmountError(
          formatMessage('saving.topUpAmountMustBeLessOrEqual', {
            amount: toFixed(amountExceedsMaxToAllocateMatch[1]),
            currency: context.currency?.currency,
          })
        );
        if (payload.calculation_base === 'MAX') {
          context.setAmount(INITIAL_AMOUNT);
        }
      }

      if (noFundsInWallet(error)) {
        context.setAmount(INITIAL_AMOUNT);
        context.setAmountError(formatMessage('saving.noFundsInWallet'));
      }

      if (noPossibilityToAllocateMoreFunds(error)) {
        queryClient.invalidateQueries(SAVINGS_QUERY_KEY);
        if (context.saving)
          queryClient.invalidateQueries(SAVING_QUERY_KEY(context.saving.id));
        context.setAmountError(
          formatMessage('saving.limitOfDepositedFundsReached')
        );
      }

      if (globalLimitReached(error)) {
        context.setErrorAlert(
          formatMessage('saving.maximumGlobalLimitReached')
        );
      }
      if (savingHasWithdrawalTransactionInPendingState(error)) {
        context.setErrorAlert(
          formatMessage('saving.savingHasWithdrawalTransactionInPendingState')
        );
      }

      if (savingHasTopupTransactionInPendingState(error)) {
        context.setErrorAlert(
          formatMessage('saving.savingHasTopupTransactionInPendingState')
        );
      }

      if (savingIsNotActive(error)) {
        context.setErrorAlert(
          formatMessage('saving.savingHasBeenClosedTopupIsNotAvailable')
        );
      }
    },
    []
  );

  const mutateDebounced = useCallback(
    debounce((payload: TopupEffectPayload) => {
      mutation.mutate(payload, {
        onSuccess: (response) => {
          onEffectCalculated(response.data, payload);
        },
        onError: (err) => {
          if (err.response) {
            onEffectError(err.response?.data, payload);
          }
        },
      });
    }, 200),
    []
  );

  // take input from last user interaction, merge it with previous input stored in context, and call /effect endpoint
  const calculateEffect = (inputData: InputData) => {
    const data: InputData = {
      amount: context.amount,
      wallet: context.wallet?.id,
      ...inputData,
    };

    if (!data.wallet) {
      return;
    }

    const commonPayload: TopupEffectPayload = {
      wallet_id: data.wallet,
      calculation_base: data.calculationBase,
      summary: false,
    };

    if (commonPayload.calculation_base === 'MAX') {
      mutateDebounced(commonPayload);
    }

    if (
      commonPayload.calculation_base === 'AMOUNT' &&
      toDecimal(data.amount).gt(0)
    ) {
      mutateDebounced({
        ...commonPayload,
        amount: toFixed(data.amount),
      });
    }
  };

  return { calculateEffect, mutation };
};
