import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { get, post } from 'modules/api';
import {
  FeatureFlagDisabledError,
  useFeatureFlag,
  useFeatureFlagDisabledHandler,
} from 'modules/feature-flags';
import { Saving, SavingCalculationBase, TopupCalculationBase } from './types';
import { CurrencyEnum, NetworkEnum } from 'common/types';
import { SummaryAsset } from '../common';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTIONS_URL,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  TransactionsResponse,
} from 'modules/transactions/api';
import { CURRENCIES_QUERY_KEY } from 'modules/select-currency-wallet/api';

export type SavingEffectPayload = {
  amount?: string;
  calculation_base?: SavingCalculationBase;
  currency: CurrencyEnum;
  wallet_id: number;
  summary: boolean;
};

export type SavingEffectResponseData = {
  amount: string;
  currency: CurrencyEnum;
  network: NetworkEnum;
  interest_daily: string;
  limit_exceeded: boolean;
  rate_pct: string;
  token: string;
};

export type SavingEffectResponseError = {
  detail?: string;
  non_field_errors?: string[];
  wallet_id?: string[];
  currency?: string[];
  amount?: string[];
  calculation_base?: string[];
  token?: string[];
} & FeatureFlagDisabledError;

export type PostSavingsPayload = {
  amount: string;
  wallet_id: number;
  currency: string;
  token?: string;
};

export type PostSavingsResponseData = {
  transaction_id: number;
};

export type PostSavingsResponseError = {
  detail?: string;
  non_field_errors?: string[];
  wallet_id?: string[];
  currency?: string[];
  amount?: string[];
  token?: string[];
} & FeatureFlagDisabledError;

export type TopupPayload = { amount: string; wallet_id: number; token: string };

export type TopupResponseData = { transaction_id: number };

export type TopupResponseError = {
  amount?: string[];
  wallet_id?: string[];
  token?: string[];
} & FeatureFlagDisabledError;

export type TopupEffectPayload = {
  amount?: string;
  wallet_id: number;
  calculation_base: TopupCalculationBase;
  summary: boolean;
};

export type TopupEffectResponseData = {
  amount: string;
  amount_target: string;
  currency: CurrencyEnum;
  network: NetworkEnum;
  interest_daily: string;
  limit_exceeded: boolean;
  token: string;
};

export type TopupEffectResponseError = {
  detail?: string;
  wallet_id?: string[];
  amount?: string[];
  calculation_base?: string[];
  non_field_errors?: string[];
  token?: string[];
} & FeatureFlagDisabledError;

export type SavingSummary = {
  current_profit_fiat: string;
  current_amount_fiat: string;
  current_assets: SummaryAsset[];
  current_avg_rate_pct: string;
};

export type SavingsLimits = {
  currency: CurrencyEnum;
  global_limit_exceeded: boolean;
  number_of_saving_limit_exceeded: boolean;
};

export type SavingWithdrawPayload = {
  amount: string;
  wallet_id: number;
  token: string;
};

export type SavingWithdrawResponseData = { transaction_id: number };

export type SavingWithdrawResponseError = {
  amount?: string[];
  wallet_id?: string[];
  token?: string[];
} & FeatureFlagDisabledError;

export type SavingWithdrawEffectPayload = {
  amount?: string;
  wallet_id: number;
  summary: boolean;
};

export type SavingWithdrawEffectResponseData = {
  amount: string;
  amount_target: string;
  currency: CurrencyEnum;
  network: NetworkEnum;
  interest_daily: string;
  token: string;
};

export type SavingWithdrawEffectResponseError = {
  detail?: string;
  wallet_id?: string[];
  amount?: string[];
  calculation_base?: string[];
  non_field_errors?: string[];
  token?: string[];
} & FeatureFlagDisabledError;

export const SAVINGS_URL = '/savings';
export const SAVING_URL = '/savings/{id}';
export const SAVINGS_EFFECT_URL = '/savings/effect';
export const SAVINGS_TOPUP_URL = '/savings/{id}/topup';
export const SAVINGS_TOPUP_EFFECT_URL = '/savings/{id}/topup/effect';
export const SAVINGS_WITHDRAW_URL = '/savings/{id}/withdraw';
export const SAVINGS_WITHDRAW_EFFECT_URL = '/savings/{id}/withdraw/effect';
export const SAVINGS_SUMMARY_URL = '/savings/summary';
export const SAVINGS_LIMITS_URL = '/savings/limits';

export const SAVINGS_QUERY_KEY = ['GET', SAVINGS_URL] as const;
export const SAVINGS_SUMMARY_QUERY_KEY = ['GET', SAVINGS_SUMMARY_URL] as const;
export const SAVINGS_LIMITS_QUERY_KEY = ['GET', SAVINGS_LIMITS_URL] as const;

export const POST_SAVINGS_QUERY_KEY = ['POST', SAVINGS_URL] as const;
export const POST_SAVINGS_EFFECT_QUERY_KEY = [
  'POST',
  SAVINGS_EFFECT_URL,
] as const;

export const POST_SAVING_WITHDRAW_QUERY_KEY = (id: number) =>
  ['POST', SAVINGS_WITHDRAW_URL, id] as const;

export const POST_SAVING_WITHDRAW_EFFECT_QUERY_KEY = (id: number) =>
  ['POST', SAVINGS_WITHDRAW_EFFECT_URL, id] as const;

export const POST_SAVING_TOPUP_QUERY_KEY = (id: number) =>
  ['POST', SAVINGS_TOPUP_URL, id] as const;

export const POST_SAVING_TOPUP_EFFECT_QUERY_KEY = (id: number) =>
  ['POST', SAVINGS_TOPUP_EFFECT_URL, id] as const;

export const SAVING_QUERY_KEY = (id: number) =>
  ['GET', SAVING_URL, id] as const;

export const HISTORICAL_SAVING_TRANSACTIONS_QUERY_KEY = (id: number) =>
  ['GET', 'HISTORICAL_SAVING_TRANSACTIONS', id] as const;

export const getSavings = () => get<Saving[]>(SAVINGS_URL);

const getSavingById = (id: number | string) =>
  get<Saving>(`${SAVING_URL.replace('{id}', id.toString())}`);

const getSavingsSummary = () => get<SavingSummary>(SAVINGS_SUMMARY_URL);

const getSavingsLimits = () => get<SavingsLimits[]>(SAVINGS_LIMITS_URL);

export const postSavings = (payload: PostSavingsPayload) =>
  post<PostSavingsResponseData, PostSavingsPayload>(SAVINGS_URL, payload);

export const postSavingsEffect = (payload: SavingEffectPayload) =>
  post<SavingEffectResponseData, SavingEffectPayload>(
    SAVINGS_EFFECT_URL,
    payload
  );

export const postTopup = (payload: TopupPayload, id: number) =>
  post<TopupResponseData, TopupPayload>(
    SAVINGS_TOPUP_URL.replace('{id}', `${id}`),
    payload
  );

export const postTopupEffect = (payload: TopupEffectPayload, id: number) =>
  post<TopupEffectResponseData, TopupEffectPayload>(
    SAVINGS_TOPUP_EFFECT_URL.replace('{id}', `${id}`),
    payload
  );

export const postWithdraw = (payload: SavingWithdrawPayload, id: number) =>
  post<SavingWithdrawResponseData, SavingWithdrawPayload>(
    SAVINGS_WITHDRAW_URL.replace('{id}', `${id}`),
    payload
  );

export const postWithdrawEffect = (
  payload: SavingWithdrawEffectPayload,
  id: number
) =>
  post<SavingWithdrawEffectResponseData, SavingWithdrawEffectPayload>(
    SAVINGS_WITHDRAW_EFFECT_URL.replace('{id}', `${id}`),
    payload
  );

export const getHistoricalSavingTransactions = (id: number) =>
  get<TransactionsResponse>(
    `${TRANSACTIONS_URL}?saving__id=${id}&state=COMPLETED&page_size=5`
  );

export const useSavingsQuery = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useQuery<
    AxiosResponse<Saving[]>,
    AxiosError<FeatureFlagDisabledError>
  >(SAVINGS_QUERY_KEY, getSavings, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useSavingByIdQuery = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useQuery<AxiosResponse<Saving>, AxiosError<FeatureFlagDisabledError>>(
    SAVING_QUERY_KEY(id),
    () => getSavingById(id),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const useSavingsLimitsQuery = (
  options?: UseQueryOptions<
    AxiosResponse<SavingsLimits[]>,
    AxiosError<FeatureFlagDisabledError>
  >
) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useQuery<
    AxiosResponse<SavingsLimits[]>,
    AxiosError<FeatureFlagDisabledError>
  >(SAVINGS_LIMITS_QUERY_KEY, getSavingsLimits, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
    ...options,
  });
};

export const useSavingsMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<PostSavingsResponseData>,
    AxiosError<PostSavingsResponseError>,
    PostSavingsPayload
  >(POST_SAVINGS_QUERY_KEY, postSavings, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useSavingsEffectMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<SavingEffectResponseData>,
    AxiosError<SavingEffectResponseError>,
    SavingEffectPayload
  >(POST_SAVINGS_EFFECT_QUERY_KEY, postSavingsEffect, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useTopupMutation = (id: number) => {
  const queryClient = useQueryClient();
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<TopupResponseData>,
    AxiosError<TopupResponseError>,
    TopupPayload
  >(POST_SAVING_TOPUP_QUERY_KEY(id), (payload) => postTopup(payload, id), {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
      queryClient.invalidateQueries(
        TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
      );
      queryClient.invalidateQueries(SAVING_QUERY_KEY(id));
      queryClient.invalidateQueries(SAVINGS_LIMITS_QUERY_KEY);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(CURRENCIES_QUERY_KEY);
    },
  });
};

export const useTopupEffectMutation = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<TopupEffectResponseData>,
    AxiosError<TopupEffectResponseError>,
    TopupEffectPayload
  >(
    POST_SAVING_TOPUP_EFFECT_QUERY_KEY(id),
    (payload) => postTopupEffect(payload, id),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const useWithdrawMutation = (id: number) => {
  const queryClient = useQueryClient();
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<SavingWithdrawResponseData>,
    AxiosError<SavingWithdrawResponseError>,
    SavingWithdrawPayload
  >(
    POST_SAVING_WITHDRAW_QUERY_KEY(id),
    (payload) => postWithdraw(payload, id),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
        queryClient.invalidateQueries(
          TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
        );
        queryClient.invalidateQueries(SAVING_QUERY_KEY(id));
        queryClient.invalidateQueries(SAVINGS_LIMITS_QUERY_KEY);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(CURRENCIES_QUERY_KEY);
      },
    }
  );
};

export const useWithdrawEffectMutation = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<SavingWithdrawEffectResponseData>,
    AxiosError<SavingWithdrawEffectResponseError>,
    SavingWithdrawEffectPayload
  >(
    POST_SAVING_WITHDRAW_EFFECT_QUERY_KEY(id),
    (payload) => postWithdrawEffect(payload, id),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const useSavingsSummaryQuery = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('SAVING');

  return useQuery<
    AxiosResponse<SavingSummary>,
    AxiosError<FeatureFlagDisabledError>
  >(SAVINGS_SUMMARY_QUERY_KEY, getSavingsSummary, {
    enabled: !!featureFlag.data?.enabled,
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useHistoricalSavingTransactionsQuery = (id: number) => {
  const query = useQuery<AxiosResponse<TransactionsResponse>>(
    HISTORICAL_SAVING_TRANSACTIONS_QUERY_KEY(id),
    () => getHistoricalSavingTransactions(id)
  );
  return query;
};

export const amountTooSmallError = (errorResp?: SavingEffectResponseError) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_AMOUNT_TOO_SMALL);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const amountTooBigError = (errorResp?: SavingEffectResponseError) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_AMOUNT_TOO_BIG);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const maximumSavingsReached = (errorResp?: PostSavingsResponseError) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(ERR_MAXIMUM_SAVINGS_REACHED);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const globalLimitReached = (errorResp?: PostSavingsResponseError) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_GLOBAL_LIMIT_REACHED);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const amountExceedsMaxToAllocate = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_AMOUNT_EXCEEDS_AMOUNT_TO_ALLOCATE);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const noPossibilityToAllocateMoreFunds = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(ERR_NO_POSIBILITY_TO_ALLOCATE_MORE_FUNDS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const noFundsInWallet = (errorResp?: PostSavingsResponseError) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(ERR_NO_FUNDS_IN_WALLET);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const savingIsNotActive = (errorResp?: PostSavingsResponseError) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(ERR_SAVING_IS_NOT_ACTIVE);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const notEnoughFunds = (errorResp?: PostSavingsResponseError) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_NOT_ENOUGH_FUNDS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const amountExceedsSavingBalance = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_AMOUNT_EXCEEDS_SAVING_BALANCE);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const amountTooBigDueToPendingTransactions = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_AMOUNT_TOO_BIG_DUE_TO_PENDING_TRANSACTIONS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const existPendingWithdrawalTransactionsAgainstFullBalance = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(
      ERR_EXIST_PENDING_WITHDRAWAL_TRANSACTION_AGAINST_FULL_BALANCE
    );
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const amountMustBeGreaterThanZero = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.amount || []) {
    const match = err.match(ERR_AMOUNT_MUST_BE_GREATER_THAN_0);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const savingHasWithdrawalTransactionAgainstFullBalance = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(
      ERR_SAVING_HAS_WITHDRAWAL_TRANSACTIONS_FULL_BALANCE
    );
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const savingHasTopupTransactionInPendingState = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(ERR_SAVING_HAS_TOPUP_TRANSACTION_IN_PENDING_STATE);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const savingHasWithdrawalTransactionInPendingState = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(
      ERR_SAVING_HAS_WITHDRAWAL_TRANSACTION_IN_PENDING_STATE
    );
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const interestRateDoesNotExist = (
  errorResp?: PostSavingsResponseError
) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(ERR_INTEREST_RATE_DOES_NOT_EXIST);
    if (match) {
      return match;
    }
  }
  return undefined;
};

const ERR_AMOUNT_TOO_SMALL = /Minimum amount for a saving is (\d+.\d+) (\w+)./;
const ERR_AMOUNT_TOO_BIG = /Maximum amount for a saving is (\d+.\d+) (\w+)./;
const ERR_MAXIMUM_SAVINGS_REACHED = /Maximum number of (\d+) savings reached./;
const ERR_GLOBAL_LIMIT_REACHED = /Maximum global limit reached./;
const ERR_AMOUNT_EXCEEDS_AMOUNT_TO_ALLOCATE =
  /Amount exceeds max to allocate \(must be less or equal to: (\d+.\d+)\)./;
const ERR_NO_FUNDS_IN_WALLET = /There are no funds in the wallet./;
const ERR_NO_POSIBILITY_TO_ALLOCATE_MORE_FUNDS =
  /There is no possibility to allocate more funds due to saving's limits \(transactions in pending state included\)./;
const ERR_SAVING_IS_NOT_ACTIVE = /Saving is not active./;
const ERR_NOT_ENOUGH_FUNDS = /Not enough funds./;
const ERR_AMOUNT_EXCEEDS_SAVING_BALANCE =
  /The amount exceeds the saving balance \(must be less or equal to: (\d+.\d+)\)./;
const ERR_AMOUNT_TOO_BIG_DUE_TO_PENDING_TRANSACTIONS =
  /Due to pending withdrawal transactions, amount must be less or equal to: (\d+.\d+)./;
const ERR_EXIST_PENDING_WITHDRAWAL_TRANSACTION_AGAINST_FULL_BALANCE =
  /There are pending withdrawal transactions against full balance./;
const ERR_AMOUNT_MUST_BE_GREATER_THAN_0 = /Must be greater than 0./;
const ERR_SAVING_HAS_WITHDRAWAL_TRANSACTIONS_FULL_BALANCE =
  /Saving has withdrawal transactions against full balance in pending state./;
const ERR_SAVING_HAS_TOPUP_TRANSACTION_IN_PENDING_STATE =
  /Saving has topup transaction in pending state./;
const ERR_SAVING_HAS_WITHDRAWAL_TRANSACTION_IN_PENDING_STATE =
  /Saving has withdrawal transaction in pending state./;
const ERR_INTEREST_RATE_DOES_NOT_EXIST =
  /Interest rate for provided currency does not exist. Please contact support./;
