import { useFormatMessage } from 'modules/messages';
import styles from './DonateWithCryptocurrencies.module.scss';
import { Text } from 'common/components/Text';
import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { currenciesByNetwork } from 'common/utils/currency-enum-utils';
import { useCharityFormPublicContext } from '../context';

export const DonateWithCryptocurrencies: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { form } = useCharityFormPublicContext();

  const currencies = form.networks.flatMap(currenciesByNetwork);

  return (
    <div className={styles.container}>
      <Text style='light1421'>
        {formatMessage('charityForms.donateWithCryptocurrencies') + ':'}
      </Text>
      <div className={styles.currenciesContainer}>
        {currencies.map((currency) => (
          <CurrencyIcon
            key={`${currency.network}-${currency.currency}`}
            currencyName={currency.currency}
          />
        ))}
      </div>
    </div>
  );
};
