import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  SettingEnum,
  SettingUpdatedEvent,
} from '../types/setting-updated-event';
import { log } from 'modules/logger';
import { v4 } from 'uuid';

type ListenerFn = () => void;

// tuple of listener function and listener id (id assigned at subscribe time, used at unsubscribe time)
type ListenerRecord = [ListenerFn, string];

const listeners: {
  [setting in SettingEnum]: ListenerRecord[];
} = {
  BORROW_LIQUIDATION_FEE_PCT: [],
  BORROW_MAX_AMOUNT_EUR: [],
  BORROW_MIN_AMOUNT_EUR: [],
  LEND_MAX_AMOUNT_EUR: [],
  LEND_MIN_AMOUNT_EUR: [],
  SAVING_MAX_AMOUNT_EUR: [],
};

export const subscribeToSettingUpdate = (
  setting: SettingEnum,
  listener: ListenerFn
) => {
  const listenerId = v4();
  listeners[setting].push([listener, listenerId]);
  return listenerId;
};

export const unsubscribeFromSettingUpdate = (
  setting: SettingEnum,
  listenerId: string
) => {
  const idx = listeners[setting].findIndex(
    (listenerRecord) => listenerRecord[1] === listenerId
  );
  if (idx === -1) {
    return;
  }
  listeners[setting].splice(idx, 1);
};

export const useHandleSettingUpdated = () => {
  const queryClient = useQueryClient();

  const handleSettingUpdated = useCallback(
    (event: SettingUpdatedEvent) => {
      log('handling SETTING_UPDATED WebSocket event', event);

      listeners[event.setting.name].forEach((listener) => listener[0]());
    },
    [queryClient]
  );

  return handleSettingUpdated;
};
