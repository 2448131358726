import { KyxWrapper } from 'modules/kyx';
import { Topbar, SidebarDesktop } from 'modules/navigation';
import { MontesauriLogotype } from './MontesauriLogotype';
import styles from './NavigationLayout.module.scss';

type Props = {
  children?: React.ReactNode;
};

export const NavigationLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className={styles.topContainer} data-test='topBanner'>
        <MontesauriLogotype path={'/dashboard'} />
        <Topbar />
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.sideContentContainer}>
          <SidebarDesktop />
        </div>
        <div className={styles.mainContentContainer}>
          <KyxWrapper>{children}</KyxWrapper>
        </div>
      </div>
    </>
  );
};
