import { LoaderBox } from 'common/components/loader';
import { useFiatCurrency } from 'modules/fiat';
import {
  OverviewColumn,
  OverviewField,
  OverviewGrid,
} from 'modules/financial-ops/common/components/overview-components';
import { useBorrowsSummaryQuery } from '../api';
import { AverageBorrowApr } from './AverageBorrowApr';
import { BorrowBubbleChart } from './BorrowBubbleChart';
import { CurrentBorrowedAmount } from './CurrentBorrowedAmount';
import { FundsLockedAsCollateral } from './FundsLockedAsCollateral';

type Props = {};

export const BorrowOverview: React.FC<Props> = () => {
  const { data: borrowsSummary, isLoading: isBorrowsSummaryLoading } =
    useBorrowsSummaryQuery();
  const { isLoading: isFiatCurrencyLoading } = useFiatCurrency();

  const isLoading = isBorrowsSummaryLoading || isFiatCurrencyLoading;

  if (isLoading) {
    return <LoaderBox />;
  }

  return (
    <OverviewGrid>
      <OverviewColumn>
        <OverviewField>
          <FundsLockedAsCollateral
            amount={borrowsSummary?.data.current_collateral_amount_fiat}
            isLoading={isLoading}
          />
        </OverviewField>
        <BorrowBubbleChart
          assets={borrowsSummary?.data.current_collateral_assets}
          id='borrow-overview-bubble-chart'
        />
      </OverviewColumn>
      <OverviewColumn>
        <OverviewField>
          <CurrentBorrowedAmount
            borrowsSummary={borrowsSummary?.data}
            isLoading={isLoading}
          />
        </OverviewField>
        <OverviewField>
          <AverageBorrowApr
            borrowsSummary={borrowsSummary?.data}
            isLoading={isLoading}
          />
        </OverviewField>
      </OverviewColumn>
    </OverviewGrid>
  );
};
