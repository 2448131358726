import D from 'decimal.js';
import { useFormatMessage } from 'modules/messages';
import { BaseDataField } from 'modules/common-data-fields';
import { CurrencyEnum } from 'common/types';

type Props = {
  interest_total: string;
  currency: CurrencyEnum;
};

export const InterestEarned: React.FC<Props> = ({
  interest_total,
  currency,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <BaseDataField
      label={formatMessage('saving.interestEarned')}
      value={`${new D(interest_total).toFixed()} ${currency}`}
      valueTextStyle='xxbold1619'
    />
  );
};
