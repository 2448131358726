import { useQueryClient } from '@tanstack/react-query';
import {
  PAGINATED_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  useTransactionDetailsQuery,
} from '../api';
import { CellActions } from './CellActions';

type Props = { transactionId?: string; isSmall?: boolean };

export const DetailsButtons: React.FC<Props> = ({ transactionId }) => {
  const { data, isLoading } = useTransactionDetailsQuery(transactionId);
  const queryClient = useQueryClient();
  const id = transactionId && parseInt(transactionId);

  if (id && isNaN(id)) {
    return <></>;
  }

  return (
    <>
      {!isLoading && (
        <>
          {id && data && data.requires_action ? (
            <CellActions
              onAction={() => {
                queryClient.invalidateQueries(PAGINATED_TRANSACTIONS_QUERY_KEY);
                queryClient.invalidateQueries(
                  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
                );
              }}
              transaction={data}
            />
          ) : (
            // <ExportButton isSmall={true} transactionId={transactionId} /> //hide for now - there is no api for this yet so it is supposed to be hidden
            <></>
          )}
        </>
      )}
    </>
  );
};
