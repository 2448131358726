import { ReactComponent as AssetsClearBWIcon } from 'common/assets/icons/assets-clear-bw.svg';
import { ReactComponent as AssetsThemeIcon } from 'common/assets/icons/assets-theme.svg';
import { ReactComponent as BorrowClearBWIcon } from 'common/assets/icons/borrow-clear-bw.svg';
import { ReactComponent as BorrowThemeIcon } from 'common/assets/icons/borrow-theme.svg';
import { ReactComponent as CompanyMembersClearBWIcon } from 'common/assets/icons/company-members-clear-bw.svg';
import { ReactComponent as CompanyMembersThemeIcon } from 'common/assets/icons/company-members-theme.svg';
import { ReactComponent as DashboardClearBWIcon } from 'common/assets/icons/dashboard-clear-bw.svg';
import { ReactComponent as DashboardThemeIcon } from 'common/assets/icons/dashboard-theme.svg';
import { ReactComponent as DepositClearBWIcon } from 'common/assets/icons/deposit-clear-bw.svg';
import { ReactComponent as DepositClearBWInactiveIcon } from 'common/assets/icons/deposit-clear-bw-inactive.svg';
import { ReactComponent as LendingClearBWIcon } from 'common/assets/icons/lending-clear-bw.svg';
import { ReactComponent as LendingThemeIcon } from 'common/assets/icons/lending-theme.svg';
import { ReactComponent as TransactionsClearBWIcon } from 'common/assets/icons/transactions-clear-bw.svg';
import { ReactComponent as TransactionsThemeIcon } from 'common/assets/icons/transactions-theme.svg';
import { ReactComponent as WhitelistClearBWIcon } from 'common/assets/icons/whitelist-clear-bw.svg';
import { ReactComponent as WhitelistThemeIcon } from 'common/assets/icons/whitelist-theme.svg';
import { ReactComponent as TradeClearBWIcon } from 'common/assets/icons/trade-clear-bw.svg';
import { ReactComponent as TradeThemeIcon } from 'common/assets/icons/trade-theme.svg';
import { ReactComponent as SavingClearBWIcon } from 'common/assets/icons/saving-clear-bw.svg';
import { ReactComponent as SavingThemeIcon } from 'common/assets/icons/saving-theme.svg';
import { ReactComponent as CharityFormsThemeIcon } from 'common/assets/icons/charity-forms-theme.svg';
import { ReactComponent as CharityFormsClearBWIcon } from 'common/assets/icons/charity-forms-clear-bw.svg';
import { useFeatureFlag } from 'modules/feature-flags';
import { useFormatMessage } from 'modules/messages';
import { ActiveTransactionsNotificationCircle } from 'modules/notifications/ActiveTransactionsNotificationCircle';
import { SidebarButton } from './components/SidebarButton';
import { useFeatureButtonsContext, useHamburgerMenuContext } from './context';
import styles from './Sidebar.module.scss';
import { KyxTooltip, useIsKyxCompleted } from 'modules/kyx';
import { FeatureSidebarButton } from './components/FeatureSidebarButton';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters/context';
import { useMatch } from 'react-router-dom';

type Props = {};

export const Sidebar: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();
  const { setIsDepositOpen, setIsWithdrawOpen } = useFeatureButtonsContext();
  const { closeHamburger } = useHamburgerMenuContext();
  const { resetFilters, setRefetchTransactionsFlag } =
    useInfiniteTransactionsContext();

  const isKyxCompleted = useIsKyxCompleted();

  const { data: borrowFeatureFlag } = useFeatureFlag('BORROW');
  const { data: lendFeatureFlag } = useFeatureFlag('LEND');
  const { data: tradeFeatureFlag } = useFeatureFlag('TRADE');
  const { data: savingFeatureFlag } = useFeatureFlag('SAVING');
  const { data: charityFormFeatureFlag } = useFeatureFlag('CHARITY_FORM');

  const onTransactionsPage = useMatch('/transactions');

  return (
    <div className={styles.container}>
      <SidebarButton
        to='/dashboard'
        text={formatMessage('dashboard.dashboard')}
        icon={<DashboardClearBWIcon />}
        iconActive={<DashboardThemeIcon />}
        dataTest='dashboardNav'
      />
      <div className={styles.featureButton}>
        <KyxTooltip>
          <div>
            <FeatureSidebarButton
              text={formatMessage('deposit')}
              onClick={() => {
                setIsDepositOpen(true);
                closeHamburger();
              }}
              icon={<DepositClearBWIcon />}
              disabledIcon={<DepositClearBWInactiveIcon />}
              disabled={!isKyxCompleted}
            />
          </div>
        </KyxTooltip>
      </div>
      <div className={styles.featureButton}>
        <FeatureSidebarButton
          text={formatMessage('common.withdraw')}
          onClick={() => {
            setIsWithdrawOpen(true);
            closeHamburger();
          }}
          icon={<DepositClearBWIcon />}
        />
      </div>
      {borrowFeatureFlag?.enabled && (
        <SidebarButton
          to='/borrows'
          text={formatMessage('borrow.borrow')}
          icon={<BorrowClearBWIcon />}
          iconActive={<BorrowThemeIcon />}
        />
      )}
      {lendFeatureFlag?.enabled && (
        <SidebarButton
          to='/lends'
          text={formatMessage('lend.lend')}
          icon={<LendingClearBWIcon />}
          iconActive={<LendingThemeIcon />}
        />
      )}
      {tradeFeatureFlag?.enabled && (
        <SidebarButton
          to='/trades'
          text={formatMessage('common.trade')}
          icon={<TradeClearBWIcon />}
          iconActive={<TradeThemeIcon />}
        />
      )}
      {savingFeatureFlag?.enabled && (
        <SidebarButton
          to='/savings-accounts'
          text={formatMessage('saving.savingsAccount')}
          icon={<SavingClearBWIcon />}
          iconActive={<SavingThemeIcon />}
        />
      )}
      <SidebarButton
        to='/wallets'
        text={formatMessage('wallets.header')}
        icon={<AssetsClearBWIcon />}
        iconActive={<AssetsThemeIcon />}
      />
      <SidebarButton
        to='/transactions'
        text={formatMessage('transactions.header')}
        icon={<TransactionsClearBWIcon />}
        iconActive={<TransactionsThemeIcon />}
        dataTest='transactionsNav'
        notification={<ActiveTransactionsNotificationCircle />}
        onClick={() => {
          if (!onTransactionsPage) {
            resetFilters();
            setRefetchTransactionsFlag(true);
          }
        }}
      />
      <SidebarButton
        to='/company-members'
        text={formatMessage('companyMembers.header.short')}
        icon={<CompanyMembersClearBWIcon />}
        iconActive={<CompanyMembersThemeIcon />}
        dataTest='companyMembersNav'
      />
      <SidebarButton
        to='/address-whitelist'
        text={formatMessage('addressWhitelist.title')}
        icon={<WhitelistClearBWIcon />}
        iconActive={<WhitelistThemeIcon />}
        dataTest='addressWhitelistNav'
      />
      {charityFormFeatureFlag?.enabled && (
        <SidebarButton
          to='/charity-forms'
          text={formatMessage('charityForms.charityForms')}
          icon={<CharityFormsClearBWIcon />}
          iconActive={<CharityFormsThemeIcon />}
          dataTest='charityFormsNav'
        />
      )}
    </div>
  );
};
