import { useBorrowsSummaryQuery } from 'modules/financial-ops/borrow/api';
import { useFormatMessage } from 'modules/messages';
import { ShowAllButton } from '../ShowAllButton';
import { SectionTemplate } from './SectionTemplate';
import { useFeatureFlag } from 'modules/feature-flags';
import clsx from 'clsx';
import styles from './SectionBorrowSummary.module.scss';
import { NewProductButton } from 'modules/financial-ops/common/components/NewProductButton';
import { useNavigate } from 'react-router-dom';
import { ActiveBorrowsCount } from 'modules/financial-ops/borrow/borrow-list/components/ActiveBorrowsCount';
import {
  AverageBorrowApr,
  BorrowBubbleChart,
  CurrentBorrowedAmount,
  FundsLockedAsCollateral,
} from 'modules/financial-ops/borrow/borrow-overview';
import { useAreNoWalletsCreated } from 'modules/assets-and-wallets';

export const SectionBorrowSummary = () => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();
  const { data: borrowsSummary, isLoading } = useBorrowsSummaryQuery();
  const { data: walletsFailed } = useAreNoWalletsCreated();

  const featureFlagLend = useFeatureFlag('LEND');
  const featureFlagNewBorrow = useFeatureFlag('CREATING_BORROW');

  return (
    <SectionTemplate
      header={formatMessage('dashboard.borrowSummary')}
      activeCount={<ActiveBorrowsCount />}
      isVerticalLayout={!featureFlagLend.data?.enabled}
      mainValue={
        <CurrentBorrowedAmount
          borrowsSummary={borrowsSummary?.data}
          isLoading={isLoading}
        />
      }
      subValues={
        <>
          <FundsLockedAsCollateral
            amount={borrowsSummary?.data.current_collateral_amount_fiat}
            isLoading={isLoading}
            textStyle='xxbold1619'
          />
          <AverageBorrowApr
            borrowsSummary={borrowsSummary?.data}
            isLoading={isLoading}
            textStyle='xxbold1619'
          />
        </>
      }
      chart={
        <div
          className={clsx(
            borrowsSummary?.data.current_collateral_assets && styles.chartMargin
          )}
        >
          <BorrowBubbleChart
            assets={borrowsSummary?.data.current_collateral_assets}
            id='dashboard-borrow-bubble-chart'
          />
        </div>
      }
      buttons={
        <>
          <ShowAllButton path='/borrows' isSmall={true} />
          <NewProductButton
            disabled={!featureFlagNewBorrow.data?.enabled || walletsFailed}
            tooltipTitle={
              !featureFlagNewBorrow.data?.enabled || walletsFailed
                ? formatMessage('common.featureCurrentlyUnavailableTryLater')
                : ''
            }
            text={formatMessage('borrow.newBorrow')}
            onClick={() => navigate('/borrows/new-borrow')}
            type='darkSmall'
          />
        </>
      }
    />
  );
};
