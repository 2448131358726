import { useActiveTransactionsDataQuery } from 'modules/transactions/hook';
import React from 'react';
import { NotificationCircle } from 'common/components/NotificationCircle';

export const ActiveTransactionsNotificationCircle: React.FC<{}> = ({}) => {
  const { data, isLoading } = useActiveTransactionsDataQuery();

  return (
    <>
      {!isLoading && !!data && (
        <NotificationCircle isLoading={isLoading} data={data} />
      )}
    </>
  );
};
