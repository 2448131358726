import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { ReactComponent as RocketIcon } from './assets/rocket.svg';
import styles from './PageNotFound.module.scss';
import { Button } from 'common/components/button';
import { ModalBody, ModalFooter, ModalHeader } from 'common/components/modal';
import { useNavigate } from 'react-router-dom';

export type Props = {};

export const PageNotFound: React.FC<Props> = ({}) => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('notFound.pageNotFound')}
      </ModalHeader>
      <ModalBody>
        <div className={styles.container}>
          <Text style='light1425' className={styles.desc}>
            {formatMessage('notFound.pageDoesNotExist')}
          </Text>
          <RocketIcon className={styles.icon} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          name={formatMessage('notFound.goToDashboard')}
          type='dark'
          className={styles.button}
          onClick={() => navigate('/dashboard')}
        />
      </ModalFooter>
    </>
  );
};
