import { NetworkEnum } from 'common/types';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { formatDate } from 'common/utils/utils';
import { useWallets } from 'modules/assets-and-wallets/api';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { useInfiniteTransactionsContext } from '../context';
import styles from './ActiveFilters.module.scss';
import { ActivePill } from './ActivePill';
import { ResetFiltersPill } from './ResetFiltersPill';
import { ShowMorePill } from './ShowMorePill';
import { getShortNameForTransactionType } from '../utils';

const MAX_ITEMS_TO_SHOW = 4;

export const ActiveFilters: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  const { data: wallets } = useWallets();
  const [showAll, setShowAll] = useState(false);

  const {
    filters,
    setFilters,
    setRefetchTransactionsFlag,
    areAnyFiltersSelected,
  } = useInfiniteTransactionsContext();

  const setSpecialName = (value: string) => {
    if (value === 'WITHDRAWAL') {
      return formatMessage('transactions.withdraw');
    }
    return value;
  };

  const getMultivalueFilterConfigForWallets = () =>
    filters.wallets
      ? Array.from(filters.wallets, (value, i) => {
          const wallet = wallets?.find((item) => item.id === value);
          return {
            header: formatMessage('common.wallet'),
            text: wallet?.name ?? '',
            onClick: () => {
              const items = (filters.wallets || []).filter(
                (_, index) => index !== i
              );
              setFilters({ ...filters, wallets: items });
              setRefetchTransactionsFlag(true);
            },
          };
        })
      : [];

  const getMultivalueFilterConfigForNetwork = () =>
    filters.networks
      ? Array.from(filters.networks, (value, i) => {
          const network = value as NetworkEnum;
          return {
            header: formatMessage('common.network'),
            text: displayNetwork(network),
            onClick: () => {
              const items = (filters.networks || []).filter(
                (_, index) => index !== i
              );
              setFilters({
                ...filters,
                networks: items.length ? items : undefined,
              });
              setRefetchTransactionsFlag(true);
            },
          };
        })
      : [];

  const getMultivalueFilterConfig = (
    header: string,
    array?: string[],
    toLowerCase?: boolean,
    onChange?: (value: string[] | undefined) => void
  ) =>
    array
      ? Array.from(array, (value, i) => {
          return {
            header: header,
            text:
              setSpecialName(value) !== value
                ? setSpecialName(value)
                : toLowerCase
                ? value.charAt(0) + value.slice(1).toLowerCase()
                : value,
            onClick: () => {
              const items = [...array].filter((_, index) => index !== i);
              if (onChange) {
                onChange(items.length ? items : undefined);
              }
              setRefetchTransactionsFlag(true);
            },
          };
        })
      : [];

  const allFilters = [
    ...getMultivalueFilterConfig(
      formatMessage('transactions.category'),
      filters.categories,
      true,
      (categories) => setFilters({ ...filters, categories })
    ),
    ...getMultivalueFilterConfig(
      formatMessage('transactions.status'),
      filters.states,
      true,
      (states) => setFilters({ ...filters, states })
    ),
    ...getMultivalueFilterConfig(
      formatMessage('transactions.type'),
      filters.types,
      true,
      (types) => setFilters({ ...filters, types })
    ),
    ...getMultivalueFilterConfig(
      formatMessage('common.currency'),
      filters.currencies,
      false,
      (currencies) => setFilters({ ...filters, currencies })
    ),
    ...getMultivalueFilterConfigForNetwork(),
    ...(filters.dateFrom
      ? [
          {
            header: formatMessage('transactions.dateFrom'),
            text: formatDate(filters.dateFrom),
            onClick: () => {
              setFilters({ ...filters, dateFrom: undefined });
              setRefetchTransactionsFlag(true);
            },
          },
        ]
      : []),
    ...(filters.dateTo
      ? [
          {
            header: formatMessage('transactions.dateTo'),
            text: formatDate(filters.dateTo),
            onClick: () => {
              setFilters({ ...filters, dateTo: undefined });
              setRefetchTransactionsFlag(true);
            },
          },
        ]
      : []),
    ...(filters.savings
      ? [
          {
            header: formatMessage('saving.savingId', {
              id: filters.savings[0],
            }),
            text: '',
            onClick: () => {
              setFilters({ ...filters, savings: undefined });
              setRefetchTransactionsFlag(true);
            },
          },
        ]
      : []),
    ...(filters.lastDays
      ? [
          {
            header: formatMessage('common.date'),
            text:
              filters.lastDays === 1
                ? formatMessage('transactions.last24Hours')
                : filters.lastDays === 7
                ? formatMessage('transactions.last7Days')
                : filters.lastDays === 30
                ? formatMessage('transactions.last30Days')
                : '',
            onClick: () => {
              setFilters({ ...filters, lastDays: undefined });
              setRefetchTransactionsFlag(true);
            },
          },
        ]
      : []),
    ...(filters.charityForms
      ? [
          {
            header: formatMessage('transactions.charityFormId', {
              id: filters.charityForms[0],
            }),
            text: '',
            onClick: () => {
              setFilters({ ...filters, charityForms: undefined });
              setRefetchTransactionsFlag(true);
            },
          },
        ]
      : []),
    ...(filters.transactionKinds
      ? [
          {
            header: formatMessage('transactions.kind', {
              kind: getShortNameForTransactionType(
                formatMessage,
                filters.transactionKinds[0]
              ),
            }),
            text: '',
            onClick: () => {
              setFilters({ ...filters, transactionKinds: undefined });
              setRefetchTransactionsFlag(true);
            },
          },
        ]
      : []),
    ...getMultivalueFilterConfigForWallets(),
  ];

  return (
    <div className={styles.container}>
      {areAnyFiltersSelected && (
        <>
          {allFilters
            .slice(0, showAll ? allFilters.length : MAX_ITEMS_TO_SHOW)
            .map((filter, i) => (
              <ActivePill
                key={i}
                header={filter.header}
                text={filter.text}
                onClick={filter.onClick}
              />
            ))}
          <ResetFiltersPill />
          {allFilters.length > MAX_ITEMS_TO_SHOW && (
            <ShowMorePill
              isMore={!showAll}
              onClick={() => setShowAll((prev) => !prev)}
            />
          )}
        </>
      )}
    </div>
  );
};
