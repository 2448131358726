import { Text } from 'common/components/Text';
import { Range } from 'react-range';
import styles from './Slider.module.scss';
import { toDecimal } from 'modules/input-amount';
import { theme } from 'common/consts/theme';

type Props = {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
};

export const Slider: React.FC<Props> = ({ min, max, value, onChange }) => {
  return (
    <Range
      min={min}
      max={max}
      values={[value]}
      onChange={(values) => onChange(values[0])}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
          }}
          className={styles.trackContainer}
        >
          <div
            style={{
              background: getLinearGradientTrackBackground({ value, max, min }),
            }}
            className={styles.track}
          ></div>
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <>
          {value > 0 && (
            <div {...props} style={{ ...props.style }} className={styles.thumb}>
              <Text style='xxbold1417' colorStyle='theme'>
                {value}
              </Text>
            </div>
          )}
        </>
      )}
    />
  );
};

export const getLinearGradientTrackBackground = ({
  value,
  max,
  min = 0,
  trailFillStart = theme.colors.rangeLightTheme,
  trailFillEnd = theme.colors.rangeDarkTheme,
  trailSecondaryBackground = theme.colors.backgroundDark,
}: {
  value: number;
  max: number;
  min?: number;
  trailFillStart?: string;
  trailFillEnd?: string;
  trailSecondaryBackground?: string;
}) => {
  const percentage = toDecimal(toDecimal(value - min).mul(100)).div(max - min);
  return `linear-gradient(to right, ${trailFillStart} 0%, ${trailFillEnd} ${percentage}%, ${trailSecondaryBackground} ${percentage}%, ${trailSecondaryBackground} 100%)`;
};
