import { AxiosError } from 'axios';
import { FormatMessageFn } from 'modules/messages';

const STATUS_REQUIRE_KEY_CONFIRMATION = 'REQUIRE_KEY_CONFIRMATION';
const STATUS_REQUIRE_OTP = 'REQUIRE_OTP';
export const STATUS_INVALID_OTP = 'INVALID_OTP';
export const ALREADY_USED_OTP = 'ALREADY_USED_OTP';

export const setup2FARequired = (status: string) =>
  status === STATUS_REQUIRE_KEY_CONFIRMATION;

export const code2FARequired = (status: string) =>
  status === STATUS_REQUIRE_OTP;

export const generateHeader2FA = (keys: string) => {
  return { 'Mfa-OTP': keys };
};

type GenericError = { status?: string };

export const setErrorOTP = (
  err: AxiosError<GenericError, any>,
  setError: (data: string | undefined) => void,
  formatMessage: FormatMessageFn
) => {
  if (err.response?.data?.status === STATUS_INVALID_OTP) {
    setError(formatMessage('2FA.incorrectGoogleAuthenticatorCode'));
    return;
  }
  if (err.response?.data?.status === ALREADY_USED_OTP) {
    setError(formatMessage('2FA.googleAuthenticatorCodeHasAlreadyBeenUsed'));
    return;
  }
};
