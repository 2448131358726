import { useSavingsLimitsQuery } from './api';

export const useAllCurrenciesAvailableForSaving = () => {
  const query = useSavingsLimitsQuery({ enabled: true });

  return {
    ...query,
    data: query.data?.data.filter(
      (data) =>
        !data.global_limit_exceeded && !data.number_of_saving_limit_exceeded
    ).length,
  };
};
