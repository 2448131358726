import { BaseDataField } from 'modules/common-data-fields';
import { useFormatMessage } from 'modules/messages';

type Props = {
  tolerance: string;
};

export const FinalPriceChange: React.FC<Props> = ({ tolerance }) => {
  const formatMessage = useFormatMessage();

  return (
    <BaseDataField
      label={formatMessage('trade.finalPriceChange')}
      value={tolerance + '%'}
    />
  );
};
