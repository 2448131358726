import { Text, TextColorStyle, TextStyle } from 'common/components/Text';
import { CSSProperties } from 'react';

type Props = {
  className?: string;
  label: React.ReactNode;
  labelTextStyle?: TextStyle;
  value: React.ReactNode;
  valueTextStyle?: TextStyle;
  secondaryValue?: React.ReactNode;
  secondaryValueTextStyle?: TextStyle;
  secondaryValueColorStyle?: TextColorStyle;
  inlineStyle?: CSSProperties;
};

export const BaseDataField: React.FC<Props> = ({
  className,
  label,
  labelTextStyle = 'light1215',
  value,
  valueTextStyle = 'bold1422',
  secondaryValue,
  secondaryValueTextStyle = 'light1215',
  secondaryValueColorStyle = 'secondary',
  inlineStyle,
}) => {
  return (
    <div className={className} style={inlineStyle}>
      {typeof label === 'string' ? (
        <Text style={labelTextStyle} inlineStyle={{ display: 'block' }}>
          {label + ':'}
        </Text>
      ) : (
        label
      )}
      <Text style={valueTextStyle} inlineStyle={{ display: 'block' }}>
        {value}
      </Text>
      {secondaryValue &&
        (typeof secondaryValue === 'string' ? (
          <Text
            style={secondaryValueTextStyle}
            colorStyle={secondaryValueColorStyle}
            inlineStyle={{ display: 'block' }}
          >
            {secondaryValue}
          </Text>
        ) : (
          secondaryValue
        ))}
    </div>
  );
};
