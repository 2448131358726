import { useAreNoWalletsCreated } from 'modules/assets-and-wallets';
import { useFeatureFlag } from 'modules/feature-flags';
import { useInterestRatesExistForKind } from 'modules/financial-ops/common';
import { NewProductButton } from 'modules/financial-ops/common/components/NewProductButton';
import { useFormatMessage } from 'modules/messages';
import { useNavigate } from 'react-router-dom';

export const NewBorrowButton: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const navigate = useNavigate();

  const { data: newBorrowFF, isLoading: isLoadingFF } =
    useFeatureFlag('CREATING_BORROW');
  const { data: walletsFailed, isLoading: isLoadingWallets } =
    useAreNoWalletsCreated();
  const { data: ratesExists, isLoading: isLoadingRates } =
    useInterestRatesExistForKind('BORROW');

  const disabled = !newBorrowFF?.enabled || walletsFailed || !ratesExists;

  return (
    <NewProductButton
      disabled={disabled}
      isLoading={isLoadingFF || isLoadingWallets || isLoadingRates}
      tooltipTitle={
        disabled
          ? formatMessage('common.featureCurrentlyUnavailableTryLater')
          : ''
      }
      text={formatMessage('borrow.newBorrow')}
      onClick={() => navigate('new-borrow')}
    />
  );
};
