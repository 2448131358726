import { Button, CancelButton } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { Member } from 'modules/company-members/api';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Alert } from 'common/components/Alert';

type Props = {
  onClose: () => void;
  newOwner?: Member;
  onClick: () => void;
  onGoBack: () => void;
  error?: string;
  isLoading: boolean;
};

export const StepSummary: React.FC<Props> = ({
  onClose,
  newOwner,
  onClick,
  onGoBack,
  error,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('settings.changeOwner')}
      </ModalHeader>
      <ModalBody>
        {error && <Alert text={error} severity='error' />}
        {newOwner && (
          <div>
            <Text style='light1417'>
              {formatMessage('settings.youTransferingOwnerRole')}
            </Text>
            <Text style='xbold1417'>{`${newOwner.name} ${newOwner.surname}. `}</Text>
            <Text style='light1417'>
              {formatMessage(
                'settings.youWillNoLongerHaveAccessToOwnerFeatures'
              )}
            </Text>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onClose} />
          <Button
            name={formatMessage('settings.changeOwner')}
            type='dark'
            onClick={onClick}
            isLoading={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
