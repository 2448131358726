import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { useSavingsQuery } from '../../api';

export const ActiveSavingsCount: React.FC<{ className?: string }> = ({
  className,
}) => {
  const formatMessage = useFormatMessage();

  const { data } = useSavingsQuery();

  const activeSavings = (data?.data ?? []).filter(
    (saving) => saving.state === 'ACTIVE'
  ).length;

  return (
    <Text
      style='xxbold1619'
      inlineStyle={{ display: 'block' }}
      className={className}
    >
      {formatMessage('finopsCommon.activeCount', {
        count: activeSavings,
      })}
    </Text>
  );
};
