import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useHamburgerMenuContext } from 'modules/navigation/context';
import { useIsOwner, useUserMenuContext } from 'modules/user';
import { useNavigate } from 'react-router-dom';

export const SettingsButton: React.FC = () => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();
  const { closeUserMenu } = useUserMenuContext();
  const { closeHamburger } = useHamburgerMenuContext();
  const { data: isOwner } = useIsOwner();

  return (
    <>
      {isOwner && (
        <div
          onClick={() => {
            navigate('/settings');
            closeUserMenu();
            closeHamburger();
          }}
          style={{ cursor: 'pointer' }}
        >
          <Text style='bold1417'>{formatMessage('common.settings')}</Text>
        </div>
      )}
    </>
  );
};
