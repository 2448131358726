import { ProgressBar } from 'common/components/ProgressBar';
import { useFormatMessage } from 'modules/messages';
import styles from './CharityFormProgress.module.scss';
import { Text } from 'common/components/Text';
import { CURRENCY_SYMBOLS } from 'modules/fiat';
import { modifyColorIntensity } from '../color-utils';
import { ReactComponent as HourglassIcon } from 'common/assets/icons/hourglass.svg';
import { PrimaryButton } from './Button';
import { useCharityFormPublicContext } from '../context';

type Props = {
  onDonate: () => void;
};

export const CharityFormProgress: React.FC<Props> = ({ onDonate }) => {
  const { form } = useCharityFormPublicContext();

  return (
    <div className={styles.container}>
      <CollectedFunds />
      <Duration />
      <Donators />
      {form.state === 'ACTIVE' && <CharityActionButtons onDonate={onDonate} />}
      {form.state === 'COMPLETED' && <CharityFormFinished />}
    </div>
  );
};

const CollectedFunds: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { form } = useCharityFormPublicContext();

  // if only one color is given, then make gradient on progress bar go from lighter on the left to darker on the right,
  // if both colors are given, then make gradient go from darker on the left to lighter on the right.
  // there is only one secondary color possible to choose from right now and custom user colors also are only primary colors without secondary one.
  const onlyPrimary = !form.secondary_color;

  return (
    <div className={styles.collectedFundsContainer}>
      <Text style='light1827' colorStyle='secondary'>
        {formatMessage('charityForms.collected')}
      </Text>
      <div>
        <Text style='light3248'>{CURRENCY_SYMBOLS[form.goal_fiat]}</Text>
        <Text style='xxbold3248'>{form.total_raised_fiat}</Text>
      </div>
      {form.goal_enabled && (
        <>
          <Text
            style='light1827'
            colorStyle='secondary'
            inlineStyle={{ marginBottom: '10px' }}
          >
            {formatMessage('charityForms.outOf', {
              amount: form.goal_amount_fiat,
              fiat: CURRENCY_SYMBOLS[form.goal_fiat],
            })}
          </Text>
          <ProgressBar
            valuePct={form.goal_raised_pct}
            trackEndColor={
              onlyPrimary
                ? form.primary_color
                : modifyColorIntensity(form.primary_color, 1.1)
            }
            trackStartColor={
              onlyPrimary
                ? modifyColorIntensity(form.primary_color, 1.2)
                : form.primary_color
            }
          />
        </>
      )}
    </div>
  );
};

const Duration: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { form } = useCharityFormPublicContext();

  const showDaysLeft = form.state === 'ACTIVE' && form.end_at_enabled;

  return (
    <div className={styles.durationContainer}>
      <Text style='xxbold2233'>
        {formatMessage('common.days', {
          days: showDaysLeft ? form.days_left : form.days_active,
        })}
      </Text>
      <Text style='light1421' colorStyle='secondary'>
        {formatMessage(
          showDaysLeft
            ? 'charityForms.untilTheEndOfTheCollection'
            : 'charityForms.duration'
        ).toLowerCase()}
      </Text>
    </div>
  );
};

const Donators: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { form } = useCharityFormPublicContext();

  return (
    <div className={styles.donatorsContainer}>
      <Text style='xxbold2233'>{form.donation_count}</Text>
      <Text style='light1421' colorStyle='secondary'>
        {formatMessage('charityForms.donators', {
          donators: form.donation_count,
        })}
      </Text>
    </div>
  );
};

const CharityActionButtons: React.FC<{
  onDonate: () => void;
}> = ({ onDonate }) => {
  const formatMessage = useFormatMessage();

  const { form } = useCharityFormPublicContext();

  return (
    <div className={styles.actionButtonsContainer}>
      <PrimaryButton
        name={formatMessage('charityForms.donateNow')}
        secondaryColor={form.secondary_color}
        primaryColor={form.primary_color}
        onClick={onDonate}
      />
    </div>
  );
};

const CharityFormFinished: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.finished}>
      <HourglassIcon className={styles.hourglass} />
      <Text style={'bold1422'} inlineStyle={{ color: '#D9D9D9' }}>
        {formatMessage('charityForms.fundraiserHasBeenFinished')}
      </Text>
    </div>
  );
};
