import { AxiosError } from 'axios';
import { NetworkEnum } from 'common/types';
import { networkKeys } from 'common/utils/network-enum-utils';
import {
  ERR_ADDRESS_USED,
  ERR_INVALID_ADDRESS,
  ERR_NAME_TOO_LONG,
  ERR_NAME_USED,
  WhitelistedAddressResponseError,
} from './api';

export const isNameUsedError = (
  error: AxiosError<WhitelistedAddressResponseError> | null
) => {
  if (!error) {
    return false;
  }
  return (
    typeof error.response?.data === 'object' &&
    error.response.data.name?.includes(ERR_NAME_USED)
  );
};

export const isNameToLongError = (
  error: AxiosError<WhitelistedAddressResponseError> | null
) => {
  if (!error) {
    return false;
  }
  return (
    typeof error.response?.data === 'object' &&
    error.response.data.name?.includes(ERR_NAME_TOO_LONG)
  );
};

export const isAddressUsedError = (
  error: AxiosError<WhitelistedAddressResponseError> | null
) => {
  if (!error) {
    return false;
  }
  return (
    typeof error.response?.data === 'object' &&
    error.response.data.address?.includes(ERR_ADDRESS_USED)
  );
};

export const isAddressInvalidError = (
  error: AxiosError<WhitelistedAddressResponseError> | null,
  n: NetworkEnum
) => {
  if (!error) {
    return false;
  }
  return (
    typeof error.response?.data === 'object' &&
    error.response.data.address?.includes(ERR_INVALID_ADDRESS(n))
  );
};

export const isAnyAddressError = (
  error: AxiosError<WhitelistedAddressResponseError> | null
) => {
  if (!error) {
    return false;
  }
  if (isAddressUsedError(error)) {
    return true;
  }
  for (const n of networkKeys) {
    if (isAddressInvalidError(error, n)) {
      return true;
    }
  }
  return false;
};
