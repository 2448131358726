import { LTV_CHART_HEIGHT } from '../consts';
import { ReactComponent as IndicatorIcon } from '../assets/arrowhead-left.svg';
import styles from './CurrentLTVIndicator.module.scss';

type Props = {
  currentLtv: number;
};

const indicatorIconHeight = 10;
const indicatorBarHeight = LTV_CHART_HEIGHT - 38;

export const CurrentLTVIndicator: React.FC<Props> = ({ currentLtv }) => {
  const cappedLtv = Math.max(0, Math.min(currentLtv, 100)); // make sure ltv is within [0, 100] range

  const indicatorFromTop = ((100 - cappedLtv) / 100) * indicatorBarHeight;

  return (
    <div className={styles.container}>
      <div
        style={{
          height: `${indicatorBarHeight}px`,
          marginTop: '4px',
          width: '6px',
          background:
            'linear-gradient(0deg, #97C160 0%, #B8CB49 51.04%, #F2DD21 70.31%, #F48F46 84.37%, #E77059 100%)',
          borderRadius: '12px',
          marginLeft: '5px',
        }}
      ></div>
      <IndicatorIcon
        style={{
          marginTop: `${indicatorFromTop - indicatorIconHeight / 2 + 3}px`,
          marginLeft: '2px',
        }}
      />
    </div>
  );
};
