import { PageLoader } from 'common/components/loader';
import { useParams } from 'react-router-dom';
import { CharityFormPublic } from './CharityFormPublic';
import { EmbeddedLayout } from './components';
import { useCharityFormPublic, useDonationsPublic } from './api';

export const CharityFormPublicEmbedded: React.FC<{}> = () => {
  const { token } = useParams();

  const { data: charityForm, isLoading: isLoadingCharityForm } =
    useCharityFormPublic(token);

  const { data: donations, isLoading: isLoadingDonations } =
    useDonationsPublic(token);

  if (isLoadingCharityForm || isLoadingDonations) {
    return <PageLoader />;
  }

  if (!charityForm || !donations) {
    return null;
  }

  return (
    <EmbeddedLayout>
      <CharityFormPublic donations={donations} form={charityForm} />
    </EmbeddedLayout>
  );
};
