import clsx from 'clsx';
import { useEffect } from 'react';
import styles from './ModalTemplate.module.scss';

export const ModalTemplate: React.FC<{
  open: boolean;
  preventClose: boolean;
  onChangeOpen: (value: boolean) => void;
  onChangePreventClose: (value: boolean) => void;
  children: React.ReactNode;
  contentClassName?: string;
}> = ({
  open,
  onChangeOpen,
  preventClose,
  onChangePreventClose,
  children,
  contentClassName,
}) => {
  useEffect(() => {
    const close = () => {
      if (preventClose) {
        onChangePreventClose(false);
        return;
      }
      onChangeOpen(false);
    };
    window.document.body.addEventListener('click', close);
    return () => {
      window.document.body.removeEventListener('click', close);
    };
  }, [open, preventClose]);

  return (
    <div className={styles.modal}>
      {open && (
        <div
          className={clsx(styles.menuContainer, contentClassName)}
          onClick={() => onChangePreventClose(true)}
        >
          {children}
        </div>
      )}
    </div>
  );
};
