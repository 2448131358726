import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { post } from 'modules/api';

export type RegisterEmailRequestPayload = {
  email?: string;
  uid?: string;
};

export type RegisterEmailRequestResponseError = {
  email?: string[];
  uid?: string[];
  detail?: string;
};

export const registerEmailRequest = (payload: RegisterEmailRequestPayload) =>
  post<void, RegisterEmailRequestPayload>('/register-email-request', payload);

export const useRegisterEmailRequestMutation = () =>
  useMutation<
    AxiosResponse<void>,
    AxiosError<RegisterEmailRequestResponseError>,
    RegisterEmailRequestPayload
  >(['POST', '/register-email-request'], registerEmailRequest);

export type RegisterEmailConfirmPayload = {
  uid: string;
  token: string;
};

export type RegisterEmailConfirmResponseData = {
  uid: string;
  token: string;
};

export type RegisterEmailConfirmResponseError = {
  uid?: string[];
  token?: string[];
  non_field_errors?: string[];
};

export const registerEmailConfirm = (payload: RegisterEmailConfirmPayload) =>
  post<RegisterEmailConfirmResponseData, RegisterEmailConfirmPayload>(
    '/register-email-confirm',
    payload
  );

export const useRegisterEmailConfirmMutation = () =>
  useMutation<
    AxiosResponse<RegisterEmailConfirmResponseData>,
    AxiosError<RegisterEmailConfirmResponseError>,
    RegisterEmailConfirmPayload
  >(['POST', '/register-email-confirm'], registerEmailConfirm);
