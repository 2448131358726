import clsx from 'clsx';
import { useFormatMessage } from 'modules/messages';
import styles from './StatePill.module.scss';
import { Text } from 'common/components/Text';
import { LendState } from '../types';

export const stateStyle: { readonly [key in LendState]: string } = {
  ACTIVE: styles.active,
  COMPLETED: styles.completed,
  CREATED: styles.created,
  TERMINATED: styles.terminated,
};

type Props = { state: LendState; dataTest?: string };

export const StatePill: React.FC<Props> = ({ state, dataTest }) => {
  const formatMessage = useFormatMessage();

  const stateText: { readonly [key in LendState]: string } = {
    ACTIVE: formatMessage('common.active'),
    COMPLETED: formatMessage('common.finished'),
    CREATED: formatMessage('common.created'),
    TERMINATED: formatMessage('lend.terminated'),
  };

  return (
    <div
      className={clsx(styles.container, stateStyle[state])}
      data-test={dataTest}
    >
      <Text
        style='light1215'
        colorStyle={state === 'TERMINATED' ? 'secondary' : undefined}
      >
        {stateText[state]}
      </Text>
    </div>
  );
};
