import { Text } from 'common/components/Text';
import { MessageKey, useFormatMessage } from 'modules/messages';
import { ReactComponent as CompletedIcon } from 'common/assets/dots/completed.svg';
import { ReactComponent as FailedIcon } from 'common/assets/dots/failed.svg';
import { TradeState as TradeStateType } from '../../api';
import { InfoTooltip } from 'common/components/InfoTooltip';
import styles from './TradeState.module.scss';

type Props = {
  state: TradeStateType;
  substate?: TradeSubstate;
};

type TradeSubstate = 'UNKNOWN_ERROR' | 'LIMIT_PRICE_NOT_MET';

export const substateToTextKey: { [key in TradeSubstate]: MessageKey } = {
  UNKNOWN_ERROR: 'trade.reasonForFailureIsUnknown',
  LIMIT_PRICE_NOT_MET: 'common.limitPriceNotMet',
};

export const TradeState: React.FC<Props> = ({ state, substate }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      {state === 'COMPLETED' && (
        <>
          <CompletedIcon />
          <Text style='light1425'>{formatMessage('common.completed')}</Text>
        </>
      )}
      {state === 'FAILED' && (
        <>
          <FailedIcon />
          <Text style='light1425' className={styles.text}>
            {formatMessage('common.failed')}
          </Text>
          {substate && (
            <InfoTooltip
              text={formatMessage(substateToTextKey[substate])}
              className={styles.tooltip}
            />
          )}
        </>
      )}
    </div>
  );
};
