import { createContext } from 'react';

type ModalContextValue = {
  onClose: () => void;
  smallModal: boolean;
};

export const ModalContext = createContext<ModalContextValue>({
  onClose: () => {},
  smallModal: false,
});
