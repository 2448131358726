import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Text } from 'common/components/Text';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { DATE_FORMAT, formatDate } from 'common/utils/utils';
import styles from './DatePicker.module.scss';
import { ClickAwayListener, DialogActions } from '@mui/material';
import { ReactComponent as CloseIcon } from 'common/assets/black-icons/close.svg';

type Props = {
  text: string;
  onChange: (value?: string | null) => void;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  value: Date | null;
};

export const DatePicker: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  text,
  onChange,
  value,
}) => {
  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={styles.container}>
        <div className={styles.box} onClick={() => setIsOpen(!isOpen)}>
          <Text
            style='light1417'
            inlineStyle={{ display: 'block', marginBottom: '5px' }}
            colorStyle='secondary'
          >
            {value ? formatDate(value.toISOString()) : text}
          </Text>
          <CalendarIcon className={styles.icon} />
        </div>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDatePicker
            open={isOpen}
            value={value}
            disableFuture={true}
            format={DATE_FORMAT}
            slots={{
              actionBar: () => ActionBar({ onClose: () => setIsOpen(false) }),
            }}
            closeOnSelect={true}
            onAccept={(newValue) => {
              onChange(newValue?.toString());
              setIsOpen(false);
            }}
            slotProps={{
              layout: {
                sx: {
                  [`.MuiDateCalendar-viewTransitionContainer`]: {
                    maxHeight: '280px',
                  },
                },
              },
            }}
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                padding: '0',
                height: ' 0',
                border: 'none',
              },
              '.MuiSvgIcon-root': {
                display: 'none',
              },
              '.MuiOutlinedInput-input': {
                display: 'none',
              },
            }}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
};

const ActionBar: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <DialogActions className={styles.actions}>
      <CloseIcon onClick={onClose} className={styles.close} />
    </DialogActions>
  );
};
