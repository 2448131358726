import { useQueryClient } from '@tanstack/react-query';
import { ReactComponent as CloseIcon } from 'common/assets/black-icons/close.svg';
import { LoaderBox } from 'common/components/loader';
import { Modal, ModalBody } from 'common/components/modal';
import { openSnackbar } from 'common/components/snackbar';
import throttle from 'lodash/throttle';
import { useFormatMessage } from 'modules/messages';
import { USER_QUERY_KEY } from 'modules/user';
import Persona from 'persona';
import { useCallback, useEffect, useState } from 'react';
import styles from './CompleteKycModal.module.scss';
import {
  ERR_ALREADY_COMPLETED,
  ERR_VERIFICATION_FAILED,
  useKycRequestInquiryMutation,
  useKycVerifyMutation,
} from './api';
import { useKyxContext } from './context';

type Props = {};

export const CompleteKycModal: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const { allowKyxBar, hideCompleteKycModal, isCompleteKycModalVisible } =
    useKyxContext();

  const queryClient = useQueryClient();

  const requestInquiryMutation = useKycRequestInquiryMutation();

  const verifyInquiryMutation = useKycVerifyMutation();

  const [isPersonaLoading, setIsPersonaLoading] = useState(true);
  const [inquiryId, setInquiryId] = useState<string>();

  useEffect(() => {
    // initialize
    setIsPersonaLoading(true);
    setInquiryId(undefined);
  }, [isCompleteKycModalVisible]);

  const close = useCallback(() => {
    hideCompleteKycModal();
    allowKyxBar();
  }, []);

  useEffect(() => {
    if (isCompleteKycModalVisible) {
      requestInquiryMutation.mutate(undefined, {
        onSuccess: (response) => {
          setInquiryId(response.data.persona_inquiry_id);
        },
        onError: () => {
          openSnackbar(
            formatMessage('kyx.failedToInitializeKycTryAgainLater'),
            'error'
          );
          hideCompleteKycModal();
        },
      });
    }
  }, [isCompleteKycModalVisible]);

  const verifyInquiry = useCallback(
    throttle(
      ({ inquiryId }: { inquiryId: string }) => {
        verifyInquiryMutation.mutate(
          { persona_inquiry_id: inquiryId },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(USER_QUERY_KEY);
              close();
              // reload to hide the camera access icon from browser interface
              window.location.reload();
            },
            onError: (error) => {
              const verificationFailed =
                error.response?.data.non_field_errors?.includes(
                  ERR_VERIFICATION_FAILED
                );
              const userAlreadyExists =
                error.response?.data.non_field_errors?.includes(
                  ERR_ALREADY_COMPLETED
                );
              if (verificationFailed) {
                openSnackbar(
                  formatMessage('kyx.verificationFailedTryAgainLater'),
                  'error'
                );
              } else if (userAlreadyExists) {
                // ignore this error, because if user is verified, nothing's really wrong
              } else {
                openSnackbar(error.message, 'error');
              }
              close();
            },
          }
        );
      },
      2000,
      { leading: true, trailing: false }
    ),
    []
  );

  return (
    <Modal
      className={styles.customizedModal}
      isOpen={isCompleteKycModalVisible}
      onClose={close}
    >
      <ModalBody>
        {(requestInquiryMutation.isLoading || isPersonaLoading) && (
          <LoaderBox />
        )}
        {inquiryId && (
          <div className={styles.iframeWrapper}>
            {!isPersonaLoading && (
              <div className={styles.closeButtonContainer}>
                <div className={styles.closeButton} onClick={close}>
                  <CloseIcon />
                </div>
              </div>
            )}
            <Persona.Inquiry
              environment={
                process.env.NODE_ENV === 'production' ? 'production' : 'sandbox'
              }
              inquiryId={inquiryId}
              onLoad={() => {
                setIsPersonaLoading(false);
              }}
              onComplete={verifyInquiry}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
