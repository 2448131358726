import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { Text } from 'common/components/Text';
import { Button, CancelButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { prettifyError } from 'common/utils/prettify-error';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import {
  AssetAmountReadOnly,
  SnapshotAlert,
  TokenExpiryTimer,
  WalletBox,
  tokenInvalidValue,
  tokenIsRequired,
} from 'modules/financial-ops/common';
import { useFormatMessage } from 'modules/messages';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { useContext, useState } from 'react';
import styles from './StepNewSavingSummary.module.scss';
import {
  PostSavingsResponseError,
  SavingEffectPayload,
  amountTooBigError,
  amountTooSmallError,
  globalLimitReached,
  interestRateDoesNotExist,
  maximumSavingsReached,
  notEnoughFunds,
  useSavingsEffectMutation,
  useSavingsMutation,
} from '../api';
import { getInterestAccrualDate } from '../helpers';
import { TermsAndConditions } from 'modules/documents';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { CURRENCIES_QUERY_KEY } from 'modules/select-currency-wallet/api';

type Props = {
  amount: string;
  currency: Currency | undefined;
  initialDailyEarn: string | undefined;
  initialInterestRatePct: string | undefined;
  initialToken: string | undefined;
  wallet: CurrencyWallet | undefined;
  onGoNext: (transactionId: number) => void;
};

export const StepNewSavingSummary: React.FC<Props> = ({
  amount,
  currency,
  initialDailyEarn,
  initialInterestRatePct,
  initialToken,
  wallet,
  onGoNext,
}) => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const { onClose } = useContext(ModalContext);

  const [checked, setChecked] = useState(false);
  const [termsAndConditionsError, setTermsAndConditionError] = useState(false);
  const [timeExpired, setTimeExpired] = useState(false);

  const savingsEffectMutation = useSavingsEffectMutation();
  const savingsMutation = useSavingsMutation();

  const refetchToken = () => {
    if (!wallet || !currency) {
      return;
    }

    const payload: SavingEffectPayload = {
      calculation_base: 'AMOUNT',
      currency: currency.currency,
      wallet_id: wallet.id,
      amount: amount,
      summary: true,
    };

    savingsEffectMutation.mutate(payload);
  };

  const getErrorText = (error: AxiosError<PostSavingsResponseError>) => {
    const maximumSavingsReachedMatch = maximumSavingsReached(
      error.response?.data
    );
    if (maximumSavingsReachedMatch) {
      return formatMessage('saving.maximumNumberOfSavingsReached', {
        count: maximumSavingsReachedMatch[1],
      });
    }

    const tooBigMatch = amountTooBigError(error.response?.data);
    if (tooBigMatch) {
      return formatMessage('saving.currentAmountMustBeLessOrEqual', {
        amount: toFixed(tooBigMatch[1]),
        currency: currency?.currency,
      });
    }

    if (globalLimitReached(error.response?.data)) {
      return formatMessage('saving.maximumGlobalLimitReached');
    }

    const tooSmallMatch = amountTooSmallError(error.response?.data);
    if (tooSmallMatch) {
      return formatMessage('saving.currentAmountMustBeGreaterOrEqual', {
        amount: toFixed(tooSmallMatch[1]),
        currency: currency?.currency,
      });
    }

    if (notEnoughFunds(error.response?.data)) {
      return formatMessage('saving.notEnoughFundsToCreateNewSaving');
    }

    if (tokenIsRequired(error.response?.data)) {
      return formatMessage('common.tokenIsRequired');
    }

    return prettifyError(error);
  };

  const getRefetchTokenError = (
    error: AxiosError<PostSavingsResponseError>
  ) => {
    const tooSmallMatch = amountTooSmallError(error.response?.data);
    if (tooSmallMatch) {
      return formatMessage('saving.minimumAmountForSaving', {
        amount: toFixed(tooSmallMatch[1]),
        currency: currency?.currency,
      });
    }
    const tooBigMatch = amountTooBigError(error.response?.data);
    if (tooBigMatch) {
      return formatMessage('saving.maximumAmountForSaving', {
        amount: toFixed(tooBigMatch[1]),
        currency: currency?.currency,
      });
    }

    if (interestRateDoesNotExist(error.response?.data)) {
      return formatMessage('saving.interestRateDoesNotExist');
    }

    if (globalLimitReached(error.response?.data)) {
      return formatMessage('saving.maximumGlobalLimitReached');
    }

    const maximumSavingsReachedMatch = maximumSavingsReached(
      error.response?.data
    );
    if (maximumSavingsReachedMatch) {
      return formatMessage('saving.maximumNumberOfSavingsReached', {
        count: maximumSavingsReachedMatch[1],
      });
    }

    return prettifyError(error);
  };

  const modalHeader = (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('finopsCommon.reviewConfirmTransaction')}
          </Text>
        }
      >
        {formatMessage('saving.savingSummary')}
      </ModalHeader>
    </>
  );

  const token = savingsEffectMutation.data?.data.token || initialToken;
  const dailyEarn =
    savingsEffectMutation.data?.data.interest_daily || initialDailyEarn;
  const interestRatePct =
    savingsEffectMutation.data?.data.rate_pct || initialInterestRatePct;

  return (
    <>
      {modalHeader}
      <ModalBody>
        {savingsMutation.isError &&
          !tokenInvalidValue(savingsMutation.error.response?.data) && (
            <Alert
              severity='error'
              text={getErrorText(savingsMutation.error)}
              className={styles.error}
            />
          )}
        {savingsEffectMutation.error && (
          <Alert
            severity='error'
            text={getRefetchTokenError(savingsEffectMutation.error)}
            className={styles.error}
          />
        )}
        <SectionSubheader>{formatMessage('common.amount')}</SectionSubheader>
        {currency && (
          <AssetAmountReadOnly
            amount={amount}
            currency={currency}
            className={styles.input}
          />
        )}
        {currency && wallet && (
          <WalletBox
            wallet={wallet}
            currency={currency.currency}
            className={styles.wallet}
          />
        )}
        <Summary className={styles.summary}>
          <SummaryLabel>
            {formatMessage('saving.annualInterestRate')}
          </SummaryLabel>
          <SummaryValue>{toFixed(interestRatePct) + '%'}</SummaryValue>
          <SummaryLabel>{formatMessage('saving.investedAmount')}</SummaryLabel>
          <SummaryValue>
            {currency
              ? `${toLimitedPrec(toFixed(amount))} ${currency.currency}`
              : '-'}
          </SummaryValue>
          <SummaryLabel>{formatMessage('saving.dailyEarn')}</SummaryLabel>
          <SummaryValue>
            {currency
              ? `${toLimitedPrec(toFixed(dailyEarn))} ${currency.currency}`
              : '-'}
          </SummaryValue>
          <SummaryLabel>
            <LabelWithInfoTooltip
              text={formatMessage('saving.expectedInterestAccrual')}
              textStyle={'light1215'}
              tooltipText={formatMessage('saving.interestIsCalculatedDaily')}
            />
          </SummaryLabel>
          <SummaryValue>{getInterestAccrualDate()}</SummaryValue>
        </Summary>
        <TermsAndConditions
          checked={checked}
          error={termsAndConditionsError}
          onToggle={(checked) => {
            setTermsAndConditionError(false);
            setChecked(checked);
          }}
        />
        {(tokenInvalidValue(savingsMutation.error?.response?.data) ||
          timeExpired) && (
          <SnapshotAlert
            onRefresh={() => {
              refetchToken();
              setTimeExpired(false);
              savingsMutation.reset();
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onClose} />
          <Button
            type='dark'
            name={
              <div className={styles.button}>
                <Text style='xbold1417'>{formatMessage('saving.save')}</Text>
                {!savingsEffectMutation.error &&
                  !savingsMutation.error &&
                  token && (
                    <TokenExpiryTimer
                      token={token}
                      onTimeExpired={() => setTimeExpired(true)}
                    />
                  )}
              </div>
            }
            isLoading={savingsMutation.isLoading}
            disabled={
              savingsMutation.isLoading ||
              !!savingsMutation.error ||
              timeExpired ||
              !!savingsEffectMutation.error
            }
            onClick={() => {
              if (savingsMutation.isLoading) {
                return;
              }
              if (!checked) {
                setTermsAndConditionError(true);
                return;
              }
              // There is no message for the user here, because this should be impossible situation.
              // Validation before summary modal is opened should check for currency, interest rate and wallet
              // and if one is missing, prevent modal from being opened.
              if (!currency || !wallet) {
                return;
              }
              savingsMutation.mutate(
                {
                  wallet_id: wallet.id,
                  currency: currency.currency,
                  amount: toFixed(amount),
                  token,
                },
                {
                  onSuccess: (res) => {
                    queryClient.invalidateQueries(
                      DASHBOARD_TRANSACTIONS_QUERY_KEY
                    );
                    queryClient.invalidateQueries(
                      TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
                    );
                    queryClient.invalidateQueries(CURRENCIES_QUERY_KEY);
                    onGoNext(res.data.transaction_id);
                  },
                }
              );
            }}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
