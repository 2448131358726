import { ModalLike } from 'common/components/modal';
import { ConfirmEmail as ConfirmEmailFeature } from 'modules/confirm-email';
import { UnauthLayout } from 'modules/layout';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const ConfirmEmail: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.confirmEmail')}</title>
      </Helmet>
      <UnauthLayout>
        <ModalLike>
          <ConfirmEmailFeature />
        </ModalLike>
      </UnauthLayout>
    </>
  );
};
