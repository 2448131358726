import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import styles from './SelectWalletStep.module.scss';
import { CurrencyItem } from './CurrencyItem';
import { WalletItem } from './WalletItem';
import { Currency, CurrencyWallet } from '../types';

type Props = {
  currency: Currency | undefined;
  onSelect: (wallet: CurrencyWallet) => void;
  onArrowClick: () => void;
  dataTest?: string;
};

export const SelectWalletStep: React.FC<Props> = ({
  onSelect,
  onArrowClick,
  currency,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader showArrow={true} onArrowClick={onArrowClick}>
        {formatMessage('selectCurrency.stepSelectWallet.header')}
      </ModalHeader>
      <ModalBody dataTest='selectWalletModal'>
        {currency && (
          <div className={styles.container}>
            <CurrencyItem selectedCurrency={currency} showArrow={false} />
            <div className={styles.divider}> </div>
            {currency.wallets.map((wallet, index) => (
              <WalletItem
                wallet={wallet}
                onSelect={onSelect}
                key={index}
                currency={currency.currency}
                dataTest='walletItem'
              />
            ))}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions> </ModalActions>
      </ModalFooter>
    </>
  );
};
