import { LoaderBox } from 'common/components/loader';
import { SectionHeader } from 'common/components/SectionHeader';
import {
  ConfigSection,
  SummarySection,
  NewOperationLayout,
} from 'modules/financial-ops/common';
import { useFormatMessage } from 'modules/messages';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { useTradesQuery } from '../api';
import { RecentTradeBox } from './components/RecentTradeBox';
import styles from './RecentTrades.module.scss';
import { TradeHistoryButton } from './TradeHistoryButton';

export const RecentTrades: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  const { data, isLoading } = useTradesQuery();

  return (
    <NewOperationLayout className={styles.container}>
      <ConfigSection className={styles.configSection}>
        <SectionHeader
          textStyle='xxbold1619'
          header={formatMessage('trade.recentTrades')}
          actions={<TradeHistoryButton />}
        />
        {isLoading && <LoaderBox />}
        {!isLoading && !data?.data.results.length && (
          <NothingToDisplay
            text={formatMessage('nothingToDisplay')}
            size={125}
            iconSize={60}
          />
        )}
        {data?.data.results.slice(0, 3).map((trade, i) => (
          <RecentTradeBox trade={trade} key={i} />
        ))}
      </ConfigSection>
      <SummarySection />
    </NewOperationLayout>
  );
};
