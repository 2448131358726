import { CharityFormPublicPreview } from 'modules/charity-forms-public';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const CharityFormPublicPreviewPage: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.charityFormPublic')}</title>
      </Helmet>
      <CharityFormPublicPreview />
    </>
  );
};
