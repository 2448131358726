import clsx from 'clsx';
import styles from './NewOperationLayout.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const NewOperationLayout: React.FC<Props> = ({
  children,
  className,
}) => <div className={clsx(styles.container, className)}>{children}</div>;
