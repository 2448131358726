import { useQueryClient } from '@tanstack/react-query';
import { LoaderBox } from 'common/components/loader';
import { Modal, ModalBody } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import {
  Multiapproval,
  MULTIAPPROVAL_URL,
  useMultiapprovalQuery,
} from 'modules/multiapproval/api';
import { useEffect, useState } from 'react';
import {
  MEMBERS_DELETED_QUERY_KEY,
  MEMBERS_QUERY_KEY,
  useDeleteMemberMutation,
} from '../api';
import { useDeleteMemberContext } from './context';
import { StepConfirm } from './StepConfirm';
import { StepSuccess } from './StepSuccess';
import { Cancel2FA, Module2FA, setErrorOTP } from 'modules/2FA';

type Props = {};

type Step = 'confirm' | '2fa' | 'success' | '2FAcancel';

export const DeleteMemberModal: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const { member, setMember } = useDeleteMemberContext();

  const isOpen = typeof member !== 'undefined';
  const onClose = () => setMember(undefined);

  const [step, setStep] = useState<Step>('confirm');

  // reinitialize modal state on open/close
  useEffect(() => {
    setStep('confirm');
    setError2faInput(undefined);
    deleteMemberMutation.reset();
  }, [member]);

  let { data: multiapprovalData, isLoading: isMultiapprovalLoading } =
    useMultiapprovalQuery();

  const [prevMultiapproval, setPrevMultiapproval] = useState<Multiapproval>();

  const [error2faInput, setError2faInput] = useState<string>();

  const deleteMemberMutation = useDeleteMemberMutation();

  const remove = (otp: string) => {
    setPrevMultiapproval(multiapprovalData?.data);
    deleteMemberMutation.mutate(
      { memberId: member?.id!, otp },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['GET', MEMBERS_QUERY_KEY]);
          queryClient.invalidateQueries(['GET', MULTIAPPROVAL_URL]);
          queryClient.invalidateQueries(MEMBERS_DELETED_QUERY_KEY);
          setStep('success');
        },
        onError: (error) => {
          setErrorOTP(error, setError2faInput, formatMessage);
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {isMultiapprovalLoading && (
        <ModalBody>
          <LoaderBox />
        </ModalBody>
      )}
      {!isMultiapprovalLoading && step === 'confirm' && (
        <StepConfirm
          member={member}
          onRemove={() => {
            setStep('2fa');
          }}
        />
      )}
      {!isMultiapprovalLoading && step === '2fa' && (
        <Module2FA
          title={formatMessage('companyMembers.removeMember')}
          onVerify={remove}
          onError={(err) => setError2faInput(err)}
          error={error2faInput}
          isLoading={deleteMemberMutation.isLoading}
          onCancel={() => setStep('2FAcancel')}
        />
      )}
      {!isMultiapprovalLoading && step === 'success' && (
        <StepSuccess
          deletedMember={member!}
          prevMultiapproval={prevMultiapproval!}
        />
      )}
      {step === '2FAcancel' && <Cancel2FA onClose={onClose} />}
    </Modal>
  );
};
