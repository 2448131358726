import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CurrencyEnum } from 'common/types';
import { get, post } from 'modules/api';
import {
  FeatureFlagDisabledError,
  useFeatureFlag,
  useFeatureFlagDisabledHandler,
} from 'modules/feature-flags';
import { SummaryAsset, TermEnum } from 'modules/financial-ops/common/types';
import { LendRead, Lend, LendDetailed } from './types';

export type LendCalculationBase = 'PRINCIPAL' | 'PRINCIPAL_MAX';

export type LendsEffectPayload = {
  calculation_base?: LendCalculationBase;
  principal_wallet_id: number;
  principal_currency: CurrencyEnum;
  principal_amount?: string;
  term: TermEnum;
  summary: boolean;
};

export type LendsEffectResponseData = {
  principal_currency: CurrencyEnum;
  principal_amount: string;
  term: TermEnum;
  interest_total: string;
  limit_exceeded: boolean;
  rate_pct: string;
  token: string;
};

export type LendsEffectResponseError = {
  detail?: string;
  non_field_errors?: string[];
  principal_wallet_id?: string[];
  principal_currency?: string[];
  principal_amount?: string[];
  term?: string[];
  token?: string[];
} & FeatureFlagDisabledError;

export type PostLendPayload = Lend;

export type PostLendResponseData = { transaction_id: number };

export type PostLendResponseError = {
  detail?: string;
  non_field_errors?: string[];
  principal_amount?: string[];
  principal_wallet_id?: string[];
  principal_currency?: string[];
  term?: string[];
  token?: string[];
} & FeatureFlagDisabledError;

export type LendsSummary = {
  current_profit_fiat: string;
  expected_profit_fiat: string;
  current_principal_amount_fiat: string;
  current_principal_assets: SummaryAsset[];
  current_avg_rate_pct: string;
};

export type PostLendTerminatePayload = {
  token: string;
};

export type PostLendTerminateResponseData = {
  transaction_id: number;
};

export type PostLendTerminateResponseError = {
  non_field_errors?: string[];
  token?: string[];
  detail?: string;
} & FeatureFlagDisabledError;

export type PostLendTerminateEffectPayload = {
  summary: boolean;
};

export type PostLendTerminateEffectResponseData = {
  token?: string;
  rate_pct: string;
  interest_total: string;
  payout_hours: number;
};

export type PostLendTerminateEffectResponseError = {
  non_field_errors?: string[];
  detail?: string;
} & FeatureFlagDisabledError;

// urls
const LENDS_URL = '/lends';
const LENDS_EFFECT_URL = '/lends/effect';
const LENDS_SUMMARY_URL = '/lends/summary';

// query keys
export const LENDS_QUERY_KEY = ['GET', LENDS_URL] as const;

export const lendDetailsQueryKey = (id: number) =>
  ['GET', LENDS_URL, id] as const;

export const LENDS_SUMMARY_QUERY_KEY = ['GET', LENDS_SUMMARY_URL] as const;

export const POST_LENDS_QUERY_KEY = ['POST', LENDS_URL] as const;
export const POST_LENDS_EFFECT_QUERY_KEY = ['POST', LENDS_EFFECT_URL] as const;

// api functions
export const postLendTerminateQueryKey = (id: number) =>
  ['POST', LENDS_URL, id, 'terminate'] as const;

export const postLendTerminateEffectQueryKey = (id: number) =>
  ['POST', LENDS_URL, id, 'terminate', 'effect'] as const;

const getLends = async () => {
  const response = await get<LendRead[]>(LENDS_URL);
  return response.data;
};

const getLendDetails = async (id: number) => {
  const response = await get<LendDetailed>(`${LENDS_URL}/${id}`);
  return response.data;
};

const getLendsSummary = async () => {
  const response = await get<LendsSummary>(LENDS_SUMMARY_URL);
  return response.data;
};

const postLend = (payload: PostLendPayload) =>
  post<PostLendResponseData, PostLendPayload>(LENDS_URL, payload);

const postLendsEffect = (payload: LendsEffectPayload) =>
  post<LendsEffectResponseData, LendsEffectPayload>(LENDS_EFFECT_URL, payload);

const postLendTerminate = async (
  id: number,
  payload: PostLendTerminatePayload
) => {
  const response = await post<
    PostLendTerminateResponseData,
    PostLendTerminatePayload
  >(`${LENDS_URL}/${id}/terminate`, payload);
  return response.data;
};

const postLendTerminateEffect = async (
  id: number,
  payload: PostLendTerminateEffectPayload
) => {
  const response = await post<
    PostLendTerminateEffectResponseData,
    PostLendTerminateEffectPayload
  >(`${LENDS_URL}/${id}/terminate/effect`, payload);
  return response.data;
};

// queries and mutations
export const useLendsQuery = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  const query = useQuery<LendRead[], AxiosError<FeatureFlagDisabledError>>(
    LENDS_QUERY_KEY,
    getLends,
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );

  return query;
};

export const useLendDetails = (id?: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('LEND');

  return useQuery<LendDetailed, AxiosError<FeatureFlagDisabledError>>(
    lendDetailsQueryKey(id!),
    () => getLendDetails(id!),
    {
      enabled: !isNaN(id!) && !!featureFlag.data?.enabled,
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const useLendsSummaryQuery = (options?: UseQueryOptions<LendsSummary>) =>
  useQuery<LendsSummary>(LENDS_SUMMARY_QUERY_KEY, getLendsSummary, options);

export const useLendsMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<PostLendResponseData>,
    AxiosError<PostLendResponseError>,
    PostLendPayload
  >(POST_LENDS_QUERY_KEY, postLend, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useLendsEffectMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<LendsEffectResponseData>,
    AxiosError<LendsEffectResponseError>,
    LendsEffectPayload
  >(POST_LENDS_EFFECT_QUERY_KEY, postLendsEffect, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useLendTerminateMutation = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    PostLendTerminateResponseData,
    AxiosError<PostLendTerminateResponseError>,
    PostLendTerminatePayload
  >(
    postLendTerminateQueryKey(id),
    (payload) => {
      return postLendTerminate(id, payload);
    },
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const useLendTerminateEffectMutation = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    PostLendTerminateEffectResponseData,
    AxiosError<PostLendTerminateEffectResponseError>,
    PostLendTerminateEffectPayload
  >(
    postLendTerminateEffectQueryKey(id),
    (payload) => {
      return postLendTerminateEffect(id, payload);
    },
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

// errors
export const principalMinError = (errorResp?: LendsEffectResponseError) => {
  for (let err of errorResp?.principal_amount || []) {
    const match = err.match(ERR_PRINCIPAL_AMOUNT_TOO_SMALL);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const principalMaxError = (errorResp?: LendsEffectResponseError) => {
  for (let err of errorResp?.principal_amount || []) {
    const match = err.match(ERR_PRINCIPAL_AMOUNT_TOO_BIG);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const interestRateDoesNotExist = (errorResp?: PostLendResponseError) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(ERR_INTEREST_RATE_DOES_NOT_EXIST);
    if (match) {
      return match;
    }
  }
  return undefined;
};

const ERR_INTEREST_RATE_DOES_NOT_EXIST =
  /Interest rate for provided term and currency does not exist. Please contact support./;

export const ERR_NOT_ENOUGH_FUNDS = 'Not enough funds to create lend.';

export const ERR_PRINCIPAL_AMOUNT_TOO_SMALL =
  /Principal amount must be greater than (\d+.\d+)/;
export const ERR_PRINCIPAL_AMOUNT_TOO_BIG =
  /Principal amount must be less than (\d+.\d+)/;
