import { Pill } from 'common/components/Pill';
import { useFormatMessage } from 'modules/messages';
import styles from './RepaymentPill.module.scss';

type Props = {};

export const RepaymentPill: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  return (
    <Pill
      className={styles.stateRepayment}
      text={formatMessage('borrow.repayment')}
      dataTest='repaymentPill'
    />
  );
};
