import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { CURRENCY_SYMBOLS, FiatAmount, FiatCurrencyEnum } from 'modules/fiat';

type Props = {
  collectedFunds: string;
  goalAmount?: string;
  fiatCurrency: FiatCurrencyEnum;
  verticalView?: boolean;
  className?: string;
};

export const CollectedFunds: React.FC<Props> = ({
  collectedFunds,
  goalAmount,
  fiatCurrency,
  verticalView,
  className,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={className}>
      <Text
        style='light1215'
        colorStyle='secondary'
        inlineStyle={{ display: 'block', marginBottom: '5px' }}
      >
        {formatMessage('charityForms.collectedFunds')}
      </Text>
      {!goalAmount && (
        <FiatAmount
          amount={collectedFunds}
          textStyle='xxbold2227'
          textColorStyle='primary'
        />
      )}
      {goalAmount && (
        <div>
          <FiatAmount
            amount={collectedFunds}
            textStyle='xxbold1619'
            textColorStyle='primary'
          />
          <Text
            style='light1215'
            colorStyle='secondary'
            inlineStyle={{
              marginTop: '5px',
              display: verticalView ? 'block' : '',
            }}
          >
            {' ' +
              formatMessage('charityForms.outOf', {
                amount: goalAmount,
                fiat: CURRENCY_SYMBOLS[fiatCurrency],
              })}
          </Text>
        </div>
      )}
    </div>
  );
};
