import { useFormatMessage } from 'modules/messages';
import { FilterBoxTemplate } from './FilterBoxTemplate';
import styles from './CurrencyFilter.module.scss';
import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { Text } from 'common/components/Text';
import { useCurrencies } from 'modules/select-currency-wallet/api';
import clsx from 'clsx';
import { toggleElement } from 'common/utils/toggle-element';

export const CurrencyFilter: React.FC<{
  currencies?: string[];
  onCurrenciesChange: (value?: string[]) => void;
}> = ({ onCurrenciesChange, currencies }) => {
  const formatMessage = useFormatMessage();
  const { data } = useCurrencies();

  return (
    <FilterBoxTemplate
      header={formatMessage('common.currency')}
      buttons={
        <>
          {data?.data.map((currency, i) => (
            <div
              className={clsx(
                styles.container,
                currencies?.includes(currency.currency) &&
                  styles.selectedCurrency
              )}
              key={i}
              onClick={() =>
                onCurrenciesChange(
                  toggleElement(currencies ?? [], currency.currency).sort()
                )
              }
            >
              <CurrencyIcon currencyName={currency.currency} />
              <Text style='bold1417'>{currency.currency}</Text>
            </div>
          ))}
        </>
      }
    />
  );
};
