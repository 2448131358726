import { CurrencyEnum } from 'common/types';
import { Text } from 'common/components/Text';
import styles from './Title.module.scss';
import { useFormatMessage } from 'modules/messages';
import { CurrencyIcon } from 'common/components/CurrencyIcon';

type Props = { outCurrency: CurrencyEnum; inCurrency: CurrencyEnum };

export const Title: React.FC<Props> = ({ outCurrency, inCurrency }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <div className={styles.icons}>
        <div className={styles.topIcon}>
          <CurrencyIcon currencyName={outCurrency} />
        </div>
        <CurrencyIcon currencyName={inCurrency} />
      </div>
      <Text style='bold1622'>
        {formatMessage('common.tradeOut', { outCurrency, inCurrency })}
      </Text>
    </div>
  );
};
