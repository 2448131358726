import styles from './StepDetails.module.scss';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { Input } from 'common/components/input/Input';
import { CurrencySelector } from 'modules/currency-selector';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import { useCharityFormPublicContext } from '../context';
import { RecaptchaInfotext } from 'modules/recaptcha';
import { Checkbox } from '../components/Checkbox';
import clsx from 'clsx';
import { currenciesByNetwork } from 'common/utils/currency-enum-utils';
import { useNewDonationContext } from './context';
import { Container, Field, FieldLabel } from './components';
import { Alert } from 'common/components/Alert';

type Props = {
  error?: string;
  onBack: () => void;
  onNext: () => void;
};

export const StepDetails: React.FC<Props> = ({ error, onBack, onNext }) => {
  const formatMessage = useFormatMessage();

  const { form } = useCharityFormPublicContext();
  const context = useNewDonationContext();

  const availableCurrencies = form.networks.flatMap(currenciesByNetwork);

  return (
    <Container>
      <Field>
        <Text style='xxbold3048'>
          {formatMessage('charityForms.donorsInformation')}
        </Text>
        <Text style='light1624'>
          {formatMessage('charityForms.completeTheRequiredPaymentDetails')}
        </Text>
      </Field>
      {error && <Alert severity='error' text={error} />}
      <Field>
        <Text style='xbold1417'>
          {formatMessage('charityForms.chooseCryptocurrency')}
        </Text>
        <CurrencySelector
          className={clsx(
            styles.currencySelector,
            context.currencyError && styles.currencySelectorError
          )}
          currency={context.currency}
          currencies={availableCurrencies}
          showApr={false}
          onChange={(currency) => {
            context.setCurrency(currency);
            context.setCurrencyError('');
          }}
        />
        {context.currencyError && (
          <Text style='regular1215' colorStyle={'error'} dataTest='errorText'>
            {context.currencyError}
          </Text>
        )}
      </Field>
      <Field>
        <Text style='xbold1417'>{formatMessage('common.name')}</Text>
        <Input
          errorText={context.nameError}
          value={context.name}
          onChange={(e) => {
            context.setName(e.currentTarget.value);
            context.setNameError('');
          }}
        />
      </Field>
      <Field>
        <Text style='xbold1417'>{formatMessage('common.surname')}</Text>
        <Input
          errorText={context.surnameError}
          value={context.surname}
          onChange={(e) => {
            context.setSurname(e.currentTarget.value);
            context.setSurnameError('');
          }}
        />
      </Field>
      <Field>
        <FieldLabel>
          <Text style='xbold1417'>{formatMessage('common.email')}</Text>
          <Text style='light1215' colorStyle='inactive'>
            {'(' + formatMessage('charityForms.optional') + ')'}
          </Text>
        </FieldLabel>
        <Input
          errorText={context.emailError}
          value={context.email}
          onChange={(e) => {
            context.setEmail(e.currentTarget.value);
            context.setEmailError('');
          }}
        />
      </Field>
      <Field>
        <FieldLabel>
          <Text style='xbold1417'>{formatMessage('common.company')}</Text>
          <Text style='light1215' colorStyle='inactive'>
            {'(' + formatMessage('charityForms.optional') + ')'}
          </Text>
        </FieldLabel>
        <Input
          errorText={context.companyError}
          value={context.company}
          onChange={(e) => {
            context.setCompany(e.currentTarget.value);
            context.setCompanyError('');
          }}
        />
      </Field>
      <Field extraGap>
        <Text style='xbold1417'>
          {formatMessage('charityForms.anonymousDonation')}
        </Text>
        <div className={styles.checkboxContainer}>
          <Checkbox
            checked={context.isAnonymousDonation}
            primaryColor={form.primary_color}
            secondaryColor={form.secondary_color}
            onChange={(e) => {
              context.setIsAnonymousDonation(e.currentTarget.checked);
            }}
          />
          <Text style='light1425'>
            {formatMessage(
              'charityForms.iWantToRemainFullyAnonymousAndNotHaveMyNameDisplayed'
            )}
          </Text>
        </div>
      </Field>
      <Field
        className={clsx(
          styles.walletAddressField,
          !context.isAnonymousDonation && styles.walletAddressFieldVisible
        )}
      >
        <Text style='xbold1417'>
          {formatMessage('charityForms.yourWalletAddress')}
        </Text>
        <Input
          errorText={context.walletAddressError}
          value={context.walletAddress}
          onChange={(e) => {
            context.setWalletAddress(e.currentTarget.value);
            context.setWalletAddressError('');
          }}
        />
        <Text style='light1215' colorStyle='inactive'>
          {formatMessage(
            'charityForms.provideWalletToLocateAndDisplayContribution'
          )}
        </Text>
      </Field>
      <RecaptchaInfotext className={styles.recaptcha} />
      <Field>
        <PrimaryButton
          name={formatMessage('common.button.next')}
          primaryColor={form.primary_color}
          secondaryColor={form.secondary_color}
          onClick={onNext}
        />
        <SecondaryButton
          name={formatMessage('common.back')}
          primaryColor={form.primary_color}
          secondaryColor={form.secondary_color}
          onClick={onBack}
        />
      </Field>
    </Container>
  );
};
