import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useNavigate } from 'react-router-dom';

export const TradeHistoryButton: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const navigate = useNavigate();

  return (
    <Button
      type='secondarySmall'
      name={formatMessage('trade.tradeHistory')}
      onClick={() => navigate('history')}
    />
  );
};
