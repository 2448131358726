import { useParams } from 'react-router-dom';
import { CharityFormPublic } from './CharityFormPublic';
import { CharityFormPublicRead, FormDonationPublicRead } from './types';
import { base64ToUtf16 } from './encode-utils';
import { FiatCurrencyEnum } from 'modules/fiat';
import { InappLayout, OrganizerLogo } from './components';

export const CharityFormPublicPreview: React.FC<{}> = () => {
  const { json } = useParams();

  if (!json) {
    return null;
  }

  const charityForm = JSON.parse(base64ToUtf16(json)) as CharityFormPublicRead;

  const donations = getMockDonations(charityForm.goal_fiat);

  const form = {
    ...charityForm,
    youtube_link: charityForm.youtube_link
      ? base64ToUtf16(charityForm.youtube_link)
      : null,
  };

  return (
    <InappLayout
      darkMode={form.dark_mode}
      logo={
        <OrganizerLogo
          url={form.pictures.find((picture) => picture.kind === 'LOGO')?.file}
        />
      }
      form={
        <CharityFormPublic donations={donations} form={form} isPreview={true} />
      }
    />
  );
};

const getMockDonations = (
  fiatCurrency: FiatCurrencyEnum
): FormDonationPublicRead[] => [
  {
    amount: '1',
    currency: 'ETH',
    created_at: '',
    network: 'ETHEREUM',
    anonymous: false,
    name: 'John',
    surname: 'K',
    amount_fiat: '1900',
    fiat: fiatCurrency,
  },
  {
    amount: '0.1',
    currency: 'BTC',
    created_at: '',
    network: 'BITCOIN',
    anonymous: false,
    name: 'Peter',
    surname: 'A',
    amount_fiat: '3400',
    fiat: fiatCurrency,
  },
  {
    amount: '1000',
    currency: 'USDC',
    created_at: '',
    network: 'ETHEREUM',
    anonymous: false,
    name: 'Andrew',
    surname: 'W',
    amount_fiat: '1000',
    fiat: fiatCurrency,
  },
  {
    amount: '1',
    currency: 'ETH',
    created_at: '',
    network: 'ETHEREUM',
    anonymous: true,
    name: '',
    surname: '',
    amount_fiat: '1900',
    fiat: fiatCurrency,
  },
  {
    amount: '0.1',
    currency: 'WBTC',
    created_at: '',
    network: 'ETHEREUM',
    anonymous: false,
    name: 'Roy',
    surname: 'F',
    amount_fiat: '3400',
    fiat: fiatCurrency,
  },
  {
    amount: '10.50',
    currency: 'USDT',
    created_at: '',
    network: 'ETHEREUM',
    anonymous: false,
    name: 'Paul',
    surname: 'C',
    amount_fiat: '10.50',
    fiat: fiatCurrency,
  },
];
