import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import React, { useState } from 'react';
import { NewCharityFormContextProvider } from './context';
import { NewCharityFormModal } from './components/NewCharityFormModal';
import { KyxTooltip, useIsKyxCompleted } from 'modules/kyx';
import { useFeatureFlag } from 'modules/feature-flags';
import { useCharityForms } from '../api';

export const NewCharityFormButton: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();
  const [openModal, setOpenModal] = useState(false);
  const isKyxCompleted = useIsKyxCompleted();

  const { data: charityForms = [], isLoading } = useCharityForms();
  const isMaximumCharityFormsReached =
    charityForms.filter((charity) => charity.state !== 'COMPLETED').length >= 5;

  const { data: charityFormFeatureFlag, isLoading: featureFlagIsLoading } =
    useFeatureFlag('CREATING_CHARITY_FORM');

  return (
    <NewCharityFormContextProvider>
      <KyxTooltip
        extraTitle={
          !charityFormFeatureFlag?.enabled
            ? formatMessage('common.featureCurrentlyUnavailableTryLater')
            : isMaximumCharityFormsReached
            ? formatMessage('charityForms.limitOfActiveCharityForms')
            : undefined
        }
      >
        <span>
          <Button
            name={formatMessage('charityForms.newForm')}
            onClick={() => setOpenModal(true)}
            type='dark'
            isLoading={isLoading || featureFlagIsLoading}
            disabled={
              !isKyxCompleted ||
              !charityFormFeatureFlag?.enabled ||
              isMaximumCharityFormsReached
            }
          />
        </span>
      </KyxTooltip>
      <NewCharityFormModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </NewCharityFormContextProvider>
  );
};
