import { default as MuiSwitch, SwitchProps } from '@mui/material/Switch';
import clsx from 'clsx';
import styles from './Switch.module.scss';

type Props = {
  checked: SwitchProps['checked'];
  onChange: SwitchProps['onChange'];
  dataTest?: string;
  error?: boolean;
};

export const Switch: React.FC<Props> = ({
  checked,
  onChange,
  dataTest,
  error,
}) => {
  return (
    <MuiSwitch
      checked={checked}
      onChange={onChange}
      data-test={dataTest}
      classes={{
        root: clsx(styles.customRoot, error && styles.customRootError),
        switchBase: styles.customSwitchBase,
        track: clsx(
          styles.customSwitchTrack,
          error && styles.customSwitchTrackError
        ),
        thumb: clsx(
          styles.customSwitchThumb,
          error && styles.customSwitchThumbError
        ),
      }}
    />
  );
};
