import { Text, TextStyle } from 'common/components/Text';
import { NetworkEnum } from 'common/types';
import { displayNetwork } from 'common/utils/network-enum-utils';
import styles from './NetworkBadge.module.scss';
import { NetworkIcon } from './NetworkIcon';

type Props = {
  network: NetworkEnum;
  inactive?: boolean;
  textStyle?: TextStyle;
};

export const NetworkBadge: React.FC<Props> = ({
  network,
  inactive = false,
  textStyle = 'light1622',
}) => {
  return (
    <div className={styles.container}>
      <NetworkIcon networkName={network} inactive={inactive} />
      <Text style={textStyle} colorStyle={inactive ? 'secondary' : 'primary'}>
        {displayNetwork(network)}
      </Text>
    </div>
  );
};
