import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { Transaction, TransactionDetails } from '../types';
import styles from './CellTransaction.module.scss';
import { IconByType } from './details/components/IconByType';
import { TransactionName } from './TransactionName';

type Props = {
  transaction: Transaction | TransactionDetails;
  dataTest?: string;
  showTypeIcon?: boolean;
};

export const CellTransaction: React.FC<Props> = ({
  transaction,
  dataTest,
  showTypeIcon = true, // if true, transaction must be of Transaction type
}) => {
  const formatMessage = useFormatMessage();
  const { id } = transaction;

  return (
    <div className={styles.breakText}>
      <div data-test={dataTest}>
        {showTypeIcon && (
          <IconByType
            type={(transaction as Transaction).type}
            className={styles.icon}
          />
        )}
        <TransactionName transaction={transaction} />
      </div>
      <div className={styles.subText}>
        <Text style='light1215' colorStyle='secondary'>
          {formatMessage('common.id', { id })}
        </Text>
      </div>
    </div>
  );
};
