import { LoaderBox } from 'common/components/loader';
import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { useFormatMessage } from 'modules/messages';
import { NewSavingButton } from './new-saving/NewSavingButton';
import { Savings } from './savings';
import { APRProducts } from '../common/components/APRProducts';
import { SavingOverview } from './saving-overview';

export const SavingsAccountDashboard: React.FC = () => {
  const formatMessage = useFormatMessage();

  const savingFeatureFlag = useRequireFeatureFlag('SAVING');

  if (savingFeatureFlag.isLoading) {
    return <LoaderBox />;
  }
  if (!savingFeatureFlag.data?.enabled) {
    return null;
  }

  return (
    <Section>
      <SectionHeader
        header={formatMessage('saving.savingOverview')}
        actions={<NewSavingButton />}
        subheader={formatMessage('saving.keepIdleFundsInSavingsAccounts')}
      />
      <SavingOverview />
      <APRProducts kind='SAVING' />
      <Savings />
    </Section>
  );
};
