import { useAreNoWalletsCreated } from 'modules/assets-and-wallets';
import { useFeatureFlag } from 'modules/feature-flags';
import { useInterestRatesExistForKind } from 'modules/financial-ops/common';
import { NewProductButton } from 'modules/financial-ops/common/components/NewProductButton';
import { useFormatMessage } from 'modules/messages';
import { useNavigate } from 'react-router-dom';

export const NewLendButton: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const navigate = useNavigate();
  const { data: walletsFailed, isLoading: isLoadingFF } =
    useAreNoWalletsCreated();

  const { data: newLendFF, isLoading: isLoadingWallets } =
    useFeatureFlag('CREATING_LEND');

  const { data: ratesExists, isLoading: isLoadingRates } =
    useInterestRatesExistForKind('LEND');

  const disabled = !newLendFF?.enabled || walletsFailed || !ratesExists;

  return (
    <NewProductButton
      disabled={disabled}
      isLoading={isLoadingFF || isLoadingWallets || isLoadingRates}
      tooltipTitle={
        disabled
          ? formatMessage('common.featureCurrentlyUnavailableTryLater')
          : ''
      }
      text={formatMessage('lend.newLend')}
      onClick={() => navigate('new-lend')}
    />
  );
};
