import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { get } from 'modules/api';
import { CurrencyRate } from './types';

export const CURRENCY_RATES_URL = '/currency-rates';

export const CURRENCY_RATES_QUERY_KEY = ['GET', CURRENCY_RATES_URL] as const;

const getCurrencyRates = () => get<CurrencyRate[]>(CURRENCY_RATES_URL);

export const useCurrencyRatesQuery = (
  options?: UseQueryOptions<AxiosResponse<CurrencyRate[]>>
) =>
  useQuery<AxiosResponse<CurrencyRate[]>>(
    CURRENCY_RATES_QUERY_KEY,
    getCurrencyRates,
    options
  );
