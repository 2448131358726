import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './Networks.module.scss';
import { NetworkEnum } from 'common/types';
import { NetworkIcon } from 'common/components/NetworkIcon';

type Props = {
  networks?: NetworkEnum[];
};

export const Networks: React.FC<Props> = ({ networks }) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text
        style='light1215'
        colorStyle='secondary'
        inlineStyle={{ display: 'block', marginBottom: '5px' }}
      >
        {formatMessage('charityForms.networks')}
      </Text>
      <div className={styles.container}>
        {networks?.map((network) => (
          <NetworkIcon networkName={network} smallIcon={true} key={network} />
        ))}
      </div>
    </div>
  );
};
