import { createContext, useContext, useState } from 'react';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';
import { SetState } from 'common/types';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { getHighestBalanceWallet } from 'modules/financial-ops/common';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';
import { BorrowDetails } from '../types';

type BorrowTopupContextValue = {
  amount: string;
  amountInfo: string;
  amountError: string;
  borrow: BorrowDetails | undefined;
  currency: Currency | undefined;
  wallet: CurrencyWallet | undefined;
  walletError: string;
  errorAlert: string;
  setAmount: SetState<string>;
  setAmountInfo: SetState<string>;
  setAmountError: SetState<string>;
  setErrorAlert: SetState<string>;
  setWalletError: SetState<string>;
};

const BorrowTopupContext = createContext<BorrowTopupContextValue>({
  amount: INITIAL_AMOUNT,
  amountInfo: '',
  amountError: '',
  currency: undefined,
  borrow: undefined,
  wallet: undefined,
  walletError: '',
  errorAlert: '',
  setAmount: () => {},
  setAmountInfo: () => {},
  setAmountError: () => {},
  setWalletError: () => {},
  setErrorAlert: () => {},
});

export const BorrowTopupContextProvider: React.FC<{
  children: React.ReactNode;
  borrow?: BorrowDetails;
}> = ({ children, borrow }) => {
  // base state
  const [amount, setAmount] = useState(INITIAL_AMOUNT);
  const { data: currencies } = useGetCurrenciesWithWallets();

  const currency = currencies?.find(
    (curr) => curr.currency === borrow?.collateral_currency
  );

  const wallet = borrow
    ? currency?.wallets?.find((item) => item.id === borrow.collateral_wallet.id)
    : getHighestBalanceWallet(currency?.wallets); // if walletId undefined, use wallet with highest balance

  // error state
  const [amountInfo, setAmountInfo] = useState('');
  const [amountError, setAmountError] = useState('');
  const [walletError, setWalletError] = useState('');
  const [errorAlert, setErrorAlert] = useState('');

  return (
    <BorrowTopupContext.Provider
      value={{
        amount,
        amountInfo,
        amountError,
        currency,
        borrow,
        wallet,
        walletError,
        errorAlert,
        setAmount,
        setAmountInfo,
        setAmountError,
        setWalletError,
        setErrorAlert,
      }}
    >
      {children}
    </BorrowTopupContext.Provider>
  );
};

export const useBorrowTopupContext = () => {
  return useContext(BorrowTopupContext);
};
