import { AxiosError } from 'axios';
import { ERR_EMAIL_EXISTS } from 'modules/api';
import { RegisterResponseError } from './api';

export const isEmailTakenError = (
  error: AxiosError<RegisterResponseError> | null
) => {
  if (!error) {
    return false;
  }
  if (typeof error.response?.data !== 'object') {
    return false;
  }
  return error.response.data.email?.includes(ERR_EMAIL_EXISTS);
};
