import { formatDatetime } from 'common/utils/utils';
import { useFormatMessage } from 'modules/messages';
import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';

type Props = { date: string; tooltipText?: string };

export const MaturityDate: React.FC<Props> = ({ date, tooltipText }) => {
  const formatMessage = useFormatMessage();

  return (
    <LabelWithInfoTooltip
      textStyle='light1215'
      text={formatMessage('common.maturityAndDate', {
        date: formatDatetime(date),
      })}
      tooltipText={tooltipText}
      colorStyle='secondary'
    />
  );
};
