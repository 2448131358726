import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { Section } from 'common/components/Section';
import { Text } from 'common/components/Text';
import { LoaderBox } from 'common/components/loader';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { StatePill } from 'modules/financial-ops/common/components/StatePill';
import { useFormatMessage } from 'modules/messages';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SAVINGS_LIMITS_QUERY_KEY,
  SAVING_QUERY_KEY,
  useSavingByIdQuery,
  useSavingsLimitsQuery,
} from '../api';
import styles from './SavingDetails.module.scss';
import { SavingDetailsHeader } from './components/SavingDetailsHeader';
import { DailyEarn } from './components/DailyEarn';
import { InterestEarned } from './components/InterestEarned';
import { InterestEarnedSoFar } from './components/InterestEarnedSoFar';
import { InterestRate } from './components/InterestRate';
import { FundsLocked } from './components/FundsLocked';
import { ID, OpenedDate } from 'modules/common-data-fields';
import { NetworkBox } from 'common/components/NetworkBox';
import { Button } from 'common/components/button';
import { useEffect, useState } from 'react';
import { SectionHeader } from 'common/components/SectionHeader';
import { TopupFunds } from '../topup';
import { toDecimal } from 'modules/input-amount';
import { FinishedDate } from './components/FinishedDate';
import { isGlobalLimitExceededForCurrency } from '../helpers';
import { useQueryClient } from '@tanstack/react-query';
import { SavingWithdraw } from '../saving-withdraw';
import { SavingHistory } from './components/SavingHistory';
import { KyxTooltip, useIsKyxCompleted } from 'modules/kyx';

export const SavingDetails: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [isOpenTopup, setIsOpenTopup] = useState(false);
  const [isOpenWithdraw, setIsOpenWithdraw] = useState(false);

  const featureFlag = useRequireFeatureFlag('SAVING');

  const isKyxCompleted = useIsKyxCompleted();

  useEffect(() => {
    if (!isKyxCompleted) {
      setIsOpenTopup(false);
      setIsOpenWithdraw(false);
    }
  }, [isKyxCompleted, setIsOpenTopup, setIsOpenWithdraw]);

  const { data, isLoading, error } = useSavingByIdQuery(parseInt(id!));
  const { data: savingLimits } = useSavingsLimitsQuery({ enabled: true });

  const saving = data?.data;

  if (featureFlag.isLoading) {
    return <LoaderBox />;
  }

  if (!featureFlag.data?.enabled) {
    return null;
  }

  if (!id) {
    return <NothingToDisplay />;
  }

  const idNum = parseInt(id);

  const globalLimitExceeded = isGlobalLimitExceededForCurrency(
    saving?.currency,
    savingLimits?.data
  );

  const hasPendingTransactions =
    saving?.has_pending_topup || saving?.has_pending_withdrawal;

  return (
    <Section>
      <SectionHeader
        header={
          <SavingDetailsHeader onBack={() => navigate('/savings-accounts')} />
        }
        actions={
          saving &&
          saving.state !== 'COMPLETED' && (
            <div className={styles.mainButtons}>
              <KyxTooltip
                extraTitle={
                  toDecimal(saving.current_max_to_withdraw).eq(0)
                    ? formatMessage(
                        'saving.withdrawalOfAllFundsHasBeenInitiated'
                      )
                    : hasPendingTransactions
                    ? formatMessage(
                        'saving.withdrawIsTemporarilyUnavailableDueToPendingTransactions'
                      )
                    : ''
                }
              >
                <span className={styles.maxWidth}>
                  <Button
                    type='secondary'
                    name={formatMessage('common.withdraw')}
                    onClick={() => setIsOpenWithdraw(true)}
                    isLoading={isLoading}
                    disabled={
                      !isKyxCompleted ||
                      toDecimal(saving.current_max_to_withdraw).eq(0) ||
                      hasPendingTransactions
                    }
                    className={styles.maxWidth}
                  />
                </span>
              </KyxTooltip>
              <KyxTooltip
                extraTitle={
                  globalLimitExceeded
                    ? formatMessage('saving.limitOfDepositedFundsReached')
                    : toDecimal(saving.to_allocate).eq(0)
                    ? formatMessage('saving.limitOfDepositedFundsReached')
                    : toDecimal(saving.current_max_to_withdraw).eq(0)
                    ? formatMessage(
                        'saving.withdrawalOfAllFundsHasBeenInitiated'
                      )
                    : hasPendingTransactions
                    ? formatMessage(
                        'saving.topupIsTemporarilyUnavailableDueToPendingTransactions'
                      )
                    : ''
                }
              >
                <span className={styles.maxWidth}>
                  <Button
                    type='secondary'
                    name={formatMessage('common.topUp')}
                    onClick={() => setIsOpenTopup(true)}
                    disabled={
                      !isKyxCompleted ||
                      toDecimal(saving.to_allocate).eq(0) ||
                      toDecimal(saving.current_max_to_withdraw).eq(0) ||
                      hasPendingTransactions ||
                      globalLimitExceeded
                    }
                    isLoading={isLoading}
                    className={styles.maxWidth}
                  />
                </span>
              </KyxTooltip>
            </div>
          )
        }
      />
      {saving && (
        <div>
          <div className={styles.header}>
            <div className={styles.name}>
              <CurrencyIcon currencyName={saving.currency} />
              <Text style='xxbold2024'>
                {formatMessage('saving.savingCurrency', {
                  currency: saving.currency,
                })}
              </Text>
            </div>
            <StatePill state={saving.state} />
          </div>
          <div className={styles.details}>
            <FundsLocked
              balance={saving.balance}
              currency={saving.currency}
              currency_rate={saving.currency_rate}
              isFinished={saving.state === 'COMPLETED'}
            />
            {saving.state === 'COMPLETED' ? (
              <InterestEarned
                interest_total={saving.interest_total}
                currency={saving.currency}
              />
            ) : (
              <DailyEarn
                expected_interest_daily={saving.expected_interest_daily}
                currency={saving.currency}
                currency_rate={saving.currency_rate}
                isFinished={false}
              />
            )}
            {saving.state !== 'COMPLETED' && (
              <InterestEarnedSoFar
                interest_total={saving.interest_total}
                currency={saving.currency}
                currency_rate={saving.currency_rate}
              />
            )}
            <InterestRate rate_pct={saving.rate_pct} />
          </div>
          <div className={styles.secondaryDetails}>
            <NetworkBox className={styles.network} network={saving.network} />
            <OpenedDate startAt={saving.activated_at} />
            {saving.state === 'COMPLETED' && saving.completed_at && (
              <FinishedDate completed_at={saving.completed_at} />
            )}
            <ID id={saving.id} />
          </div>
        </div>
      )}
      {isLoading && <LoaderBox />}
      {error && <NothingToDisplay />}
      {saving?.id && <SavingHistory id={saving?.id} />}
      <TopupFunds
        open={isOpenTopup}
        onClose={() => {
          queryClient.invalidateQueries(SAVINGS_LIMITS_QUERY_KEY);
          queryClient.invalidateQueries(SAVING_QUERY_KEY(idNum));
          setIsOpenTopup(false);
        }}
        id={idNum}
        saving={saving}
      />
      {saving && (
        <SavingWithdraw
          open={isOpenWithdraw}
          onClose={() => {
            queryClient.invalidateQueries(SAVINGS_LIMITS_QUERY_KEY);
            queryClient.invalidateQueries(SAVING_QUERY_KEY(idNum));
            setIsOpenWithdraw(false);
          }}
          id={idNum}
          saving={saving}
        />
      )}
    </Section>
  );
};
