import { useQueryClient } from '@tanstack/react-query';
import { Button, CancelButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { Text } from 'common/components/Text';
import {
  WalletBox,
  AssetAmountReadOnly,
} from 'modules/financial-ops/common/components';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { useContext, useEffect, useState } from 'react';

import styles from './StepNewTradeSummary.module.scss';
import { InstrumentCurrency, TradeOptions } from './types';
import {
  amountMustGreaterError,
  amountMustLessError,
  ensureMaxDecimalPlaces,
  ERR_NOT_ENOUGH_FUNDS,
  EXCHANGE_INSTRUMENT_LIMITS_KEY,
  PostTradeError,
  TradesCalculationBase,
  TradesEffectPayload,
  TradesEffectResponseError,
  useTradeEffectMutation,
  useTradeMutation,
} from '../api';
import { Alert } from 'common/components/Alert';
import { AxiosError } from 'axios';
import { prettifyError } from 'common/utils/prettify-error';
import { WALLETS_QUERY_KEY } from 'modules/assets-and-wallets/api';
import { TermsAndConditions } from 'modules/documents';

import { TokenExpiryTimer } from 'modules/financial-ops/common/components/TokenExpiryTimer';
import { SnapshotAlert } from 'modules/financial-ops/common/components';
import {
  tokenInvalidValue,
  tokenIsRequired,
} from 'modules/financial-ops/common';

type Props = {
  tolerance?: string;
  initialLimitPrice?: string;
  initialOrderPrice?: string;
  spendCurrency?: InstrumentCurrency;
  spendWallet?: CurrencyWallet;
  receiveCurrency?: InstrumentCurrency;
  receiveWallet?: CurrencyWallet;
  initialSpendAmount?: string;
  initialReceiveAmount?: string;
  isBuy: boolean;
  option: TradeOptions;
  calculationBase: TradesCalculationBase;
  pairId?: number;
  onSuccess: (transactionId: number) => void;
  onAmountError: () => void;
  onError: (error: boolean) => void;
  initialToken: string | undefined;
};

export const StepNewTradeSummary: React.FC<Props> = ({
  tolerance,
  initialLimitPrice,
  initialOrderPrice,
  spendCurrency,
  spendWallet,
  receiveCurrency,
  receiveWallet,
  initialSpendAmount,
  initialReceiveAmount,
  isBuy,
  option,
  calculationBase,
  pairId,
  onSuccess,
  onAmountError,
  onError,
  initialToken,
}) => {
  const formatMessage = useFormatMessage();

  const [checked, setChecked] = useState(false);
  const [timeExpired, setTimeExpired] = useState(false);
  const [termsAndConditionsError, setTermsAndConditionError] = useState(false);

  const { onClose } = useContext(ModalContext);

  const queryClient = useQueryClient();

  const tradeMutation = useTradeMutation();
  const tradeEffectMutation = useTradeEffectMutation();

  const isSpendAmountChanged =
    (calculationBase === 'CURRENCY_2' && isBuy) ||
    (calculationBase === 'CURRENCY_1' && !isBuy);

  const refetchToken = () => {
    if (!spendCurrency || !receiveCurrency || !pairId || !tolerance) {
      return;
    }

    let payload: TradesEffectPayload = {
      exchange_instrument_id: pairId,
      side: option,
      calculation_base: calculationBase,
      price_tolerance_pct: tolerance,
      summary: true,
      amount: isSpendAmountChanged ? spendAmount : receiveAmount,
    };

    tradeEffectMutation.mutate(payload, {
      onError: (error) => {
        queryClient.invalidateQueries(
          EXCHANGE_INSTRUMENT_LIMITS_KEY(pairId, tolerance)
        );
      },
    });
  };

  const getErrorText = (error: AxiosError<PostTradeError>) => {
    if (
      typeof error.response?.data === 'object' &&
      error.response.data.amount?.includes(ERR_NOT_ENOUGH_FUNDS)
    ) {
      return formatMessage('trade.insufficientSpendFunds');
    }

    let match: RegExpMatchArray | undefined;
    match = amountMustLessError(error.response?.data);
    if (match) {
      if (
        (calculationBase === 'CURRENCY_1' && isBuy) ||
        (calculationBase === 'CURRENCY_2' && !isBuy)
      ) {
        return formatMessage('trade.currentReceiveAmountMustBeLessOrEqual', {
          amount: toFixed(match[1]),
          currency: receiveCurrency?.currency,
        });
      }
      if (
        (calculationBase === 'CURRENCY_2' && isBuy) ||
        (calculationBase === 'CURRENCY_1' && !isBuy)
      ) {
        return formatMessage('trade.currentSpendAmountMustBeLessOrEqual', {
          amount: toFixed(match[1]),
          currency: spendCurrency?.currency,
        });
      }
    }
    match = amountMustGreaterError(error.response?.data);
    if (match) {
      if (
        (calculationBase === 'CURRENCY_1' && isBuy) ||
        (calculationBase === 'CURRENCY_2' && !isBuy)
      ) {
        return formatMessage('trade.currentReceiveAmountMustBeGreaterOrEqual', {
          amount: toFixed(match[1]),
          currency: receiveCurrency?.currency,
        });
      }
      if (
        (calculationBase === 'CURRENCY_2' && isBuy) ||
        (calculationBase === 'CURRENCY_1' && !isBuy)
      ) {
        return formatMessage('trade.currentSpendAmountMustBeGreaterOrEqual', {
          amount: toFixed(match[1]),
          currency: spendCurrency?.currency,
        });
      }
    }

    if (tokenIsRequired(error.response?.data)) {
      return formatMessage('common.tokenIsRequired');
    }

    return prettifyError(error);
  };

  const getRefetchTokenError = (
    error: AxiosError<TradesEffectResponseError>
  ) => {
    const amountMustLessMatch = amountMustLessError(error.response?.data);
    if (amountMustLessMatch) {
      if (!isSpendAmountChanged) {
        return formatMessage('common.amountMustBeLessOrEqual', {
          amount: toFixed(amountMustLessMatch[1]),
          currency: receiveCurrency?.currency,
        });
      }
      if (isSpendAmountChanged) {
        return formatMessage('common.amountMustBeLessOrEqual', {
          amount: toFixed(amountMustLessMatch[1]),
          currency: spendCurrency?.currency,
        });
      }
    }

    const amountMustGreaterMatch = amountMustGreaterError(error.response?.data);
    if (amountMustGreaterMatch) {
      if (!isSpendAmountChanged) {
        return formatMessage('trade.amountMustGreater', {
          amount: toFixed(amountMustGreaterMatch[1]),
          currency: receiveCurrency?.currency,
        });
      }
      if (isSpendAmountChanged) {
        return formatMessage('trade.amountMustGreater', {
          amount: toFixed(amountMustGreaterMatch[1]),
          currency: spendCurrency?.currency,
        });
      }
    }

    const ensureMaxDecimalPlacesMatch = ensureMaxDecimalPlaces(
      error.response?.data
    );
    if (ensureMaxDecimalPlacesMatch) {
      if (!isSpendAmountChanged) {
        return formatMessage('trade.ensureNoMoreThanXDecimalPlaces', {
          decimal: ensureMaxDecimalPlacesMatch[1],
        });
      }

      if (isSpendAmountChanged) {
        return formatMessage('trade.ensureNoMoreThanXDecimalPlaces', {
          decimal: ensureMaxDecimalPlacesMatch[1],
        });
      }
    }

    return prettifyError(error);
  };

  useEffect(() => {
    onError(!!tradeMutation.error);
  }, [tradeMutation.error]);

  const modalHeader = (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('finopsCommon.reviewConfirmTransaction')}
          </Text>
        }
      >
        {formatMessage('trade.tradeSummary')}
      </ModalHeader>
    </>
  );

  // this is just an extra safe guard, in reality this error should be handled earlier and the modal should not be opened at all
  const isSummaryDataComplete =
    initialToken &&
    spendCurrency &&
    receiveCurrency &&
    spendWallet &&
    receiveWallet;

  if (!isSummaryDataComplete) {
    return (
      <>
        {modalHeader}
        <ModalBody>
          <Alert
            severity='error'
            text={formatMessage('trade.summaryCannotBeDisplayed')}
          />
        </ModalBody>
      </>
    );
  }

  const token = tradeEffectMutation.data?.data.token || initialToken;
  const limitPrice =
    tradeEffectMutation.data?.data.limit_price || initialLimitPrice;
  const orderPrice =
    tradeEffectMutation.data?.data.order_price || initialOrderPrice;

  const spendAmount = tradeEffectMutation.data
    ? calculationBase === 'CURRENCY_1' && isBuy
      ? toLimitedPrec(toFixed(tradeEffectMutation.data?.data.currency_2_amount))
      : calculationBase === 'CURRENCY_2' && !isBuy
      ? toLimitedPrec(toFixed(tradeEffectMutation.data?.data.currency_1_amount))
      : initialSpendAmount
    : initialSpendAmount;

  const receiveAmount = tradeEffectMutation.data
    ? calculationBase === 'CURRENCY_2' && isBuy
      ? toLimitedPrec(toFixed(tradeEffectMutation.data?.data.currency_1_amount))
      : calculationBase === 'CURRENCY_1' && !isBuy
      ? toLimitedPrec(toFixed(tradeEffectMutation.data?.data.currency_2_amount))
      : initialReceiveAmount
    : initialReceiveAmount;

  return (
    <>
      {modalHeader}
      <ModalBody>
        {tradeMutation.isError &&
          !tokenInvalidValue(tradeMutation.error.response?.data) && (
            <Alert
              severity='error'
              text={getErrorText(tradeMutation.error)}
              className={styles.error}
            />
          )}
        {tradeEffectMutation.error && (
          <Alert
            severity='error'
            text={getRefetchTokenError(tradeEffectMutation.error)}
            className={styles.error}
          />
        )}
        <SectionSubheader
          tooltip={
            !isBuy ? undefined : formatMessage('trade.maximalSpendAmount')
          }
        >
          {isBuy
            ? formatMessage('trade.spendMaximum')
            : formatMessage('trade.spend')}
        </SectionSubheader>
        {spendCurrency && (
          <AssetAmountReadOnly
            amount={toDecimal(spendAmount)}
            currency={{
              currency: spendCurrency.currency,
              network: spendCurrency.network,
            }}
            className={styles.amountAssetBox}
            prefix={isBuy ? '~' : undefined}
          />
        )}
        {spendCurrency && spendWallet && (
          <WalletBox
            wallet={spendWallet}
            currency={spendCurrency.currency}
            className={styles.walletBox}
          />
        )}
        <SectionSubheader
          tooltip={
            isBuy ? undefined : formatMessage('trade.maximalReceivedAmount')
          }
        >
          {isBuy
            ? formatMessage('trade.receive')
            : formatMessage('trade.receiveMinimum')}
        </SectionSubheader>
        {receiveCurrency && (
          <AssetAmountReadOnly
            amount={toDecimal(receiveAmount)}
            currency={{
              currency: receiveCurrency.currency,
              network: receiveCurrency.network,
            }}
            className={styles.amountAssetBox}
            prefix={!isBuy ? '~' : undefined}
          />
        )}
        {receiveCurrency && receiveWallet && (
          <WalletBox
            wallet={receiveWallet}
            currency={receiveCurrency.currency}
            className={styles.walletBox}
          />
        )}
        <Summary className={styles.summary}>
          <SummaryLabel>
            {formatMessage('trade.priceChangeTolerance')}
          </SummaryLabel>
          <SummaryValue>{tolerance ? `${tolerance}%` : '0%'}</SummaryValue>
          <SummaryLabel>
            {isBuy
              ? formatMessage('trade.maximalPrice')
              : formatMessage('trade.minimalPrice')}
          </SummaryLabel>
          <SummaryValue>
            {spendCurrency && receiveCurrency
              ? formatMessage('trade.priceValues', {
                  currency1: isBuy
                    ? receiveCurrency?.currency
                    : spendCurrency?.currency,
                  currency2: isBuy
                    ? spendCurrency?.currency
                    : receiveCurrency?.currency,
                  currencyValue: toLimitedPrec(toFixed(limitPrice ?? 0)),
                })
              : '-'}
          </SummaryValue>
        </Summary>
        <TermsAndConditions
          checked={checked}
          error={termsAndConditionsError}
          onToggle={(checked) => {
            setTermsAndConditionError(false);
            setChecked(checked);
          }}
        />
        {(tokenInvalidValue(tradeMutation.error?.response?.data) ||
          timeExpired) && (
          <SnapshotAlert
            onRefresh={() => {
              refetchToken();
              setTimeExpired(false);
              tradeMutation.reset();
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton
            onClick={() => {
              if (tradeMutation.error) {
                onAmountError();
              }
              onClose();
            }}
          />
          <Button
            type='dark'
            name={
              <div className={styles.button}>
                <Text style='xbold1417'>{formatMessage('common.trade')}</Text>
                {!tradeEffectMutation.error && (
                  <TokenExpiryTimer
                    token={token}
                    onTimeExpired={() => setTimeExpired(true)}
                  />
                )}
              </div>
            }
            isLoading={tradeMutation.isLoading}
            disabled={
              tradeMutation.isLoading ||
              timeExpired ||
              !!tradeEffectMutation.error
            }
            onClick={() => {
              if (tradeMutation.isLoading) {
                return;
              }
              if (!checked) {
                setTermsAndConditionError(true);
                return;
              }

              if (
                pairId &&
                orderPrice &&
                tolerance &&
                spendWallet &&
                receiveWallet &&
                spendAmount &&
                receiveAmount
              ) {
                tradeMutation.mutate(
                  {
                    exchange_instrument_id: pairId,
                    side: option,
                    amount:
                      (isBuy && calculationBase === 'CURRENCY_1') ||
                      (!isBuy && calculationBase === 'CURRENCY_2')
                        ? receiveAmount
                        : spendAmount,
                    calculation_base: calculationBase,
                    price_tolerance_pct: tolerance,
                    currency_1_wallet_id: isBuy
                      ? receiveWallet?.id
                      : spendWallet?.id,
                    currency_2_wallet_id: isBuy
                      ? spendWallet?.id
                      : receiveWallet?.id,
                    order_price: orderPrice,
                    token,
                  },
                  {
                    onSuccess: (res) => {
                      queryClient.invalidateQueries(
                        DASHBOARD_TRANSACTIONS_QUERY_KEY
                      );
                      queryClient.invalidateQueries(
                        TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
                      );
                      queryClient.invalidateQueries(WALLETS_QUERY_KEY);
                      onSuccess(res.data.transaction_id);
                    },
                  }
                );
              }
            }}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
