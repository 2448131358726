import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { TextStyle, Text } from 'common/components/Text';
import { FiatAmount } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';

type Props = {
  amount?: string;
  isLoading?: boolean;
  textStyle?: TextStyle;
  showTooltip?: boolean;
  loaderSize?: number;
  loaderContainerSize?: number;
};

export const FundsLockedAsCollateral: React.FC<Props> = ({
  amount,
  isLoading,
  textStyle = 'xxbold3048',
  showTooltip = true,
  loaderSize = 30,
  loaderContainerSize = 48,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      {showTooltip ? (
        <LabelWithInfoTooltip
          text={formatMessage('borrow.fundsLockedAsCollateral')}
          textStyle={'light1215'}
          tooltipText={formatMessage('borrow.approximateValue')}
        />
      ) : (
        <Text style='light1215' inlineStyle={{ display: 'block' }}>
          {formatMessage('borrow.fundsLockedAsCollateral')}
        </Text>
      )}
      <FiatAmount
        amount={amount}
        isDataLoading={isLoading}
        loaderContainerSize={loaderContainerSize}
        loaderSize={loaderSize}
        textColorStyle='primary'
        textStyle={textStyle}
      />
    </div>
  );
};
