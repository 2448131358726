import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import styles from './MultiApprovalBox.module.scss';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { useMultiapprovalQuery } from 'modules/multiapproval/api';
import { Button } from 'common/components/button';
import { useSetMultiapprovalContext } from 'modules/multiapproval';
import { useIsOwner } from 'modules/user';
import { Tooltip } from 'common/components/Tooltip';
import { useMembersQuery } from 'modules/company-members/api';

export const MultiApprovalBox = () => {
  const formatMessage = useFormatMessage();
  const { data: multiApproval, isLoading } = useMultiapprovalQuery();
  const { setIsOpen } = useSetMultiapprovalContext();
  const { data: membersData } = useMembersQuery();
  const { data: isOwner } = useIsOwner();

  const isDataNotAvailable =
    typeof isOwner === 'undefined' || typeof membersData === 'undefined';

  const getTooltipText = () => {
    if (isDataNotAvailable) {
      return '';
    }
    if (membersData.data.length <= 1) {
      return formatMessage(
        'multiapproval.multiapprovalRequiresAtLeastTwoUsers'
      );
    }
    return '';
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text style='xxbold1619'>
          {formatMessage('common.multiapprovalThreshold')}
        </Text>
        {isOwner && (
          <Tooltip title={getTooltipText()}>
            <span>
              <Button
                name={formatMessage('common.button.change')}
                onClick={() => {
                  setIsOpen(true);
                }}
                type='secondarySmall'
                disabled={
                  isDataNotAvailable ||
                  !isOwner ||
                  membersData?.data.length <= 1
                }
              />
            </span>
          </Tooltip>
        )}
      </div>

      <div className={styles.subTitle}>
        {isLoading ? (
          <RotatingLoader size={16} type='secondary' />
        ) : (
          <Text
            style='xxbold3030'
            className={styles.textOutOf}
            dataTest='currentThreshold'
          >
            {formatMessage('common.threshold.outOf', {
              current: multiApproval?.data.threshold!,
              max: multiApproval?.data.signers_count!,
            })}
          </Text>
        )}
        <Text style='light1417' className={styles.subText}>
          {formatMessage('dashboard.sectionTransacions.multiapproval.subtext')}
        </Text>
      </div>
    </div>
  );
};
