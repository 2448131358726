import { Section } from 'common/components/Section';
import { useFormatMessage } from 'modules/messages';
import { SectionHeader } from 'common/components/SectionHeader';
import { Text } from 'common/components/Text';
import { SettingsMenu } from './components/SettingsMenu';
import { useEffect } from 'react';
import { useKyxContext, useRefreshKyxEffect } from 'modules/kyx';

type Props = {};

export const Settings: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const { allowKyxBar, blockKyxBar } = useKyxContext();

  useEffect(() => {
    blockKyxBar();
    return () => {
      allowKyxBar();
    };
  }, []);

  useRefreshKyxEffect();

  return (
    <Section>
      <SectionHeader
        header={
          <Text style='xxbold3644'>{formatMessage('common.settings')}</Text>
        }
      />
      <SettingsMenu />
    </Section>
  );
};
