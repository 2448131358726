import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { CODE_2FA_LENGTH, Inputs2FA } from './Inputs2FA';
import { Button, CancelButton } from 'common/components/button';
import { useState } from 'react';

type Props = {
  title?: string;
  subtitle?: string;
  onVerify: (keys: string) => void;
  onCancel: () => void;
  error?: string;
  onError: (err: string | undefined) => void;
  isLoading: boolean;
  approvalOfTransaction?: boolean;
  additionalDescription?: React.ReactNode;
};

export const Module2FA: React.FC<Props> = ({
  title,
  subtitle,
  onVerify,
  onCancel,
  error,
  onError,
  isLoading,
  approvalOfTransaction,
  additionalDescription,
}) => {
  const formatMessage = useFormatMessage();
  const [code, setCode] = useState('');

  return (
    <>
      <ModalHeader
        subtitle={
          subtitle ??
          formatMessage(
            '2FA.openGoogleAuthenticatorAppToRetrieveAutorizationCode'
          )
        }
        showClose={false}
      >
        {title ?? formatMessage('2FA.enterGoogleAuthenticatorCode')}
      </ModalHeader>
      <ModalBody>
        {additionalDescription}
        <Inputs2FA
          onCodeChange={(newCode: string) => {
            setCode(newCode);
            if (newCode.length === CODE_2FA_LENGTH) {
              onVerify(newCode);
            }
          }}
          error={error}
          onError={(err) => onError(err)}
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} disabled={isLoading} />
          <Button
            name={
              approvalOfTransaction
                ? formatMessage('common.button.approve')
                : formatMessage('common.button.confirm')
            }
            type='dark'
            onClick={() => onVerify(code)}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
