import { NetworkBadge } from 'common/components/NetworkBadge';
import { Text } from 'common/components/Text';
import { NetworkEnum } from 'common/types';
import styles from './WalletVisual.module.scss';

type Props = {
  walletName: string;
  network: NetworkEnum;
};

export const WalletVisual: React.FC<Props> = ({ walletName, network }) => {
  return (
    <div className={styles.wallet}>
      <Text style='light1622'>{walletName}</Text>
      <NetworkBadge network={network} />
    </div>
  );
};
