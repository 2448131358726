import { Button, ButtonType } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { CharityFormWithdrawModal } from './components/CharityFormWithdrawModal';
import { Tooltip } from 'common/components/Tooltip';
import { toDecimal } from 'modules/input-amount';
import { useCharityForm } from '../api';
import { LoaderBox } from 'common/components/loader';

type Props = { id: number; buttonType?: ButtonType; className?: string };

export const CharityFormWithdrawButton: React.FC<Props> = ({
  id,
  buttonType = 'secondary',
  className,
}) => {
  const formatMessage = useFormatMessage();
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading } = useCharityForm(id);

  if (isLoading) {
    return <LoaderBox />;
  }

  const charityForm = data?.data;

  if (!id || !charityForm) {
    return null;
  }

  const hasNonzeroBalance = Object.values(
    charityForm?.current_balance_by_currency
  )?.some((balance) => toDecimal(balance).gt(0));

  return (
    <>
      {(hasNonzeroBalance || charityForm.has_pending_withdrawal) && (
        <Tooltip
          title={
            charityForm.has_pending_withdrawal
              ? formatMessage(
                  'charityForms.withdrawTemporarilyUnavailableDueToPendingTransactions'
                )
              : ''
          }
        >
          <span className={className}>
            <Button
              type={buttonType}
              name={formatMessage('common.withdraw')}
              onClick={() => setIsOpen(true)}
              disabled={charityForm.has_pending_withdrawal}
              className={className}
            />
          </span>
        </Tooltip>
      )}
      <CharityFormWithdrawModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        charityForm={charityForm}
      />
    </>
  );
};
