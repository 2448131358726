import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { ReactComponent as Icon } from '../../../assets/creator.svg';
import { UserSimple } from 'modules/user';

type Props = {
  creator: UserSimple;
};

export const Creator: React.FC<Props> = ({ creator }) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      {creator && (
        <div>
          <Icon />{' '}
          <Text style='light1215' colorStyle='secondary'>
            {formatMessage('transactions.creator', {
              name: creator.name,
              surname: creator.surname,
            })}
          </Text>
        </div>
      )}
    </>
  );
};
