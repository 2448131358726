import styles from './InputWithHeader.module.scss';
import { Text } from '../Text';
import { Input } from './Input';
import { InputWithTitleProps } from './types';

export const InputWithHeader: React.FC<InputWithTitleProps> = ({
  header,
  onChange,
  onEnter,
  value,
  placeholder,
  errorText,
  dataTest,
  id,
  autofocus,
  type,
  disabled = false,
  readonly = false,
  className,
}) => {
  return (
    <>
      <div className={styles.headerText}>
        <Text style='xbold1417' colorStyle={'primary'}>
          {header}
        </Text>
      </div>
      <Input
        value={value}
        onChange={onChange}
        onEnter={onEnter}
        placeholder={placeholder}
        errorText={errorText}
        type={type}
        dataTest={dataTest}
        id={id}
        autofocus={autofocus}
        disabled={disabled}
        readonly={readonly}
        className={className}
      />
    </>
  );
};
