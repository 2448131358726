import { SectionSubheader } from 'common/components/SectionSubheader';
import { Text } from 'common/components/Text';
import { CSSProperties } from 'react';

type Props = {
  title: string;
  description?: string;
  tooltip?: string;
  inlineStyles?: CSSProperties;
};

export const HeaderWithDescription: React.FC<Props> = ({
  title,
  description,
  tooltip,
  inlineStyles,
}) => {
  return (
    <div style={inlineStyles}>
      <SectionSubheader tooltip={tooltip}>{title}</SectionSubheader>
      {description && (
        <Text
          style='light1215'
          colorStyle='inactive'
          inlineStyle={{ display: 'block', marginTop: '-5px' }}
        >
          {description}
        </Text>
      )}
    </div>
  );
};
