import { Loader, LoaderProps } from './Loader';
import styles from './LoaderBox.module.scss';

type Props = LoaderProps & {
  height?: string;
  margin?: string;
  width?: string;
};

export const LoaderBox: React.FC<Props> = ({
  height = '200px',
  margin = '0',
  width = '100%',
  ...loaderProps
}) => {
  return (
    <div
      style={{ height, margin, width }}
      className={styles.loaderContainer}
      data-test='loaderBox'
    >
      <Loader {...loaderProps} />
    </div>
  );
};
