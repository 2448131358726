import clsx from 'clsx';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProps,
} from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import styles from './ThumbnailsList.module.scss';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { ReactComponent as VideoIcon } from '../../assets/video.svg';
import { ReactComponent as YoutubeLogoIcon } from '../../assets/youtube_logo.svg';
import { ReactComponent as PictureIcon } from 'common/assets/grey-icons/picture-icon.svg';
import { ReactComponent as RemoveIcon } from '../assets/remove.svg';
import { FileRejection, useDropzone } from 'react-dropzone';
import { MultimediaProps } from 'modules/charity-forms/types';
import { LoaderBox } from 'common/components/loader';
import { reorderFiles } from '../utils';

type Props = {
  files?: MultimediaProps[];
  onFileChange: (files: MultimediaProps[]) => void;
  onLinkChange: (link: string | undefined) => void;
  youtubeLink?: string;
  errorLink: string;
  onDrop: (items: File[], rejectedFiles: FileRejection[]) => void;
  disabled: boolean;
  isPicturesLoading: boolean;
};

export const ThumbnailsList: React.FC<Props> = ({
  files,
  youtubeLink,
  onFileChange,
  onLinkChange,
  onDrop,
  errorLink,
  disabled,
  isPicturesLoading,
}) => {
  const formatMessage = useFormatMessage();

  const { open, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    noClick: true,
    noKeyboard: true,
    maxFiles: 4,
    maxSize: 2000000,
    disabled,
    onDrop,
  });

  const numberOfFiles = files ? files.length : 0;

  return (
    <div>
      <Text
        style='light1215'
        colorStyle='inactive'
        inlineStyle={{ display: 'block', margin: '10px 0' }}
      >
        {formatMessage('charityForms.rearrangePicturesToChooseFirstOne')}
      </Text>
      <div
        className={clsx(
          styles.container,
          files && files.length > 0 && styles.photoUploaded
        )}
      >
        <DragDropContext
          onDragStart={() => {
            document.getElementById('youtubeLink')?.blur();
          }}
          onDragEnd={(result) => {
            if (!result.destination || !files) {
              return;
            }

            const items = reorderFiles(
              files,
              result.source.index,
              result.destination.index
            );

            onFileChange(items);
          }}
        >
          <StrictModeDroppable droppableId='droppable' direction='horizontal'>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={styles.list}
              >
                {files?.map((file, i) => (
                  <Draggable key={i} draggableId={`${i}`} index={i}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={clsx(
                          styles.box,
                          i === 0 && styles.firstBox,
                          snapshot.isDragging && styles.isImageDropping
                        )}
                      >
                        <img
                          src={file.url}
                          alt={formatMessage('charityForms.uploadedFileNo', {
                            index: i,
                          })}
                          className={clsx(
                            styles.image,
                            i === 0 && styles.firstImage
                          )}
                        />
                        <div
                          className={styles.removeIcon}
                          onClick={() => {
                            const newList = [...files];
                            newList?.splice(i, 1);
                            if (file.kind === 'VIDEO') {
                              onLinkChange(undefined);
                            }
                            onFileChange(newList);
                          }}
                        >
                          <RemoveIcon />
                        </div>
                        {file.kind === 'VIDEO' && (
                          <YoutubeLogoIcon className={styles.youtubeLogoIcon} />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {[...new Array(5 - numberOfFiles)]?.map((_, i) => (
                  <div key={i} className={styles.box}>
                    {isPicturesLoading && i < 4 - numberOfFiles ? (
                      <LoaderBox />
                    ) : i < 4 - numberOfFiles || (youtubeLink && !errorLink) ? (
                      <PictureIcon />
                    ) : (
                      <VideoIcon />
                    )}
                  </div>
                ))}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};
