import { Text } from 'common/components/Text';
import { Range } from 'react-range';
import styles from './WithdrawSlider.module.scss';
import { toDecimal, toFixed } from 'modules/input-amount';
import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { CurrencyEnum } from 'common/types';
import { DECIMALS_MAP } from 'common/utils/currency-enum-utils';
import { getLinearGradientTrackBackground } from 'common/components/Slider';

type Props = {
  currentMaxToWithdraw: string;
  amount: string;
  currency: CurrencyEnum;
  balance: number;
  onChange: (value: string) => void;
};

export const WithdrawSlider: React.FC<Props> = ({
  currentMaxToWithdraw,
  amount,
  currency,
  balance,
  onChange,
}) => {
  const formatMessage = useFormatMessage();
  const decimals = DECIMALS_MAP[currency];

  const min = 0;

  const intDecimals = toDecimal(10).toPower(decimals);

  //change float incoming max to int
  const max = toDecimal(balance).mul(intDecimals);

  //check if it is possible to divide by 100, if the value is:
  //smaller - we default to smallest number that can be expressed with given currency's decimals places, because 1% of max would be smaller that this value
  //bigger or the same - take 1% of max as step
  const canUseOnePercentAsStep = max.div(100).gte(1);

  const step = canUseOnePercentAsStep ? max.div(100) : toDecimal(1);

  const value = toDecimal(amount).mul(intDecimals).gt(max)
    ? max
    : toDecimal(amount).mul(intDecimals);

  const percentage = toDecimal(value.mul(100)).div(max);

  //edge case when current_max_to_withdraw is equal to the decimal value for a given currency
  if (step.eq(max) || toDecimal(currentMaxToWithdraw).eq(0)) {
    return null;
  }

  const maxNumber = max.toNumber();
  const stepNumber = step.toNumber();
  const valueNumber = value.toNumber();

  return (
    <div className={styles.container}>
      {min !== maxNumber && (
        <>
          <Range
            min={min}
            max={maxNumber}
            step={stepNumber}
            values={[valueNumber]}
            onChange={(values) => {
              // step = max.div(100), so it can have 2 decimal places, and value is multiple of step, meaning value.div(intDecimals) can have up to decimals+2 decimal places.
              // first toFixed(x, decimals) reduces the value precision to decimals, so that it is valid amount of given currency
              // second toFixed(x) removes trailing zeroes from string representation of the amount
              onChange(
                toFixed(
                  toFixed(
                    toDecimal(values[0]).div(intDecimals),
                    DECIMALS_MAP[currency]
                  )
                )
              );
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{ ...props.style }}
                className={styles.trackContainer}
              >
                <div
                  style={{
                    background: getLinearGradientTrackBackground({
                      value: valueNumber,
                      max: maxNumber,
                      min,
                    }),
                  }}
                  className={styles.track}
                />
                {children}
              </div>
            )}
            renderThumb={({ props }) =>
              valueNumber >= 0 && (
                <div
                  {...props}
                  style={{ ...props.style }}
                  className={styles.thumb}
                >
                  <Text
                    style='xxbold1417'
                    colorStyle='inactive'
                    className={styles.text}
                  >
                    {toFixed(percentage, 0)}%
                  </Text>
                </div>
              )
            }
          />
          <Button
            className={styles.btnAll}
            type='secondarySmall'
            onClick={() => onChange(toFixed(currentMaxToWithdraw))}
            name={formatMessage('saving.all')}
          />
        </>
      )}
    </div>
  );
};
