import { useFormatMessage } from 'modules/messages';
import styles from './LendName.module.scss';
import { CurrencyEnum } from 'common/types';
import {
  LendState,
  TermEnum,
  termToMonths,
} from 'modules/financial-ops/common';
import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { Text, TextStyle } from 'common/components/Text';
import { StatePill } from 'modules/financial-ops/common/components/StatePill';

type Props = {
  principalCurrency: CurrencyEnum;
  state: LendState;
  term: TermEnum;
  textStyle?: TextStyle;
};

export const LendName: React.FC<Props> = ({
  principalCurrency,
  state,
  term,
  textStyle,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <div className={styles.name}>
        <CurrencyIcon currencyName={principalCurrency} />
        <Text style={textStyle || 'bold1622'}>
          {formatMessage('lend.lendName', {
            currency: principalCurrency,
            months: termToMonths(term),
          })}
        </Text>
      </div>
      <StatePill state={state} dataTest='lendStatus' />
    </div>
  );
};
