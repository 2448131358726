import clsx from 'clsx';
import { useFormatMessage } from 'modules/messages';
import styles from './BuySellButtons.module.scss';
import { Text } from 'common/components/Text';
import { TradeOptions } from './types';

export const BuySellButtons: React.FC<{
  className?: string;
  readOnly?: boolean;
  onChange: (option: TradeOptions) => void;
  option: TradeOptions;
}> = ({ className, readOnly = false, onChange, option }) => {
  const formatMessage = useFormatMessage();

  return (
    <div
      className={clsx(styles.container, className, readOnly && styles.readOnly)}
    >
      <div
        className={clsx(
          styles.button,
          option === 'BUY' && styles.buttonActive,
          readOnly && styles.readOnlyButton
        )}
        onClick={() => {
          if (!readOnly) onChange('BUY');
        }}
      >
        <Text style='xxbold1417'>{formatMessage('trade.buy')}</Text>
      </div>
      <div
        className={clsx(
          styles.button,
          option === 'SELL' && styles.buttonActive,
          readOnly && styles.readOnlyButton
        )}
        onClick={() => {
          if (!readOnly) onChange('SELL');
        }}
      >
        <Text style='xxbold1417'>{formatMessage('trade.sell')}</Text>
      </div>
    </div>
  );
};
