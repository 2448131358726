import { CurrencyEnum } from 'common/types';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { BaseDataField } from './BaseDataField';
import { useFormatMessage } from 'modules/messages';

type Props = {
  amount?: string;
  currency: CurrencyEnum;
  rate: string;
};

export const DailyEarn: React.FC<Props> = ({ amount, currency, rate }) => {
  const formatMessage = useFormatMessage();

  return (
    <BaseDataField
      label={formatMessage('saving.dailyEarn')}
      value={`${toLimitedPrec(toFixed(amount))} ${currency}`}
      secondaryValue={formatMessage('common.rateApr', {
        rate_pct: toFixed(rate),
      })}
      secondaryValueColorStyle={'primary'}
    />
  );
};
