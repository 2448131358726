import { SectionAssets } from './components/SectionAssets';
import { SectionTransactions } from './components/SectionTransactions';
import { SectionLendBorrowSummary } from './components/section-lend-borrow-summary';
import { SectionBalanceSummary } from './section-balance-summary';

type Props = {};

export const Dashboard: React.FC<Props> = () => {
  return (
    <>
      <SectionBalanceSummary />
      <SectionTransactions />
      <SectionLendBorrowSummary />
      <SectionAssets />
    </>
  );
};
