import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'common/components/modal';
import { InterestRate } from 'modules/financial-ops/common';
import { useFormatMessage } from 'modules/messages';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  TransactionActionPayload,
  useTransactionActionMutation,
} from 'modules/transactions/api';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepNewSavingSummary } from './StepNewSavingSummary';
import { StepNewSavingSuccess } from './StepNewSavingSuccess';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Step = 'summary' | 'success' | '2fa' | '2FAcancel';

type Props = {
  amount: string;
  currency: Currency | undefined;
  dailyEarn: string | undefined;
  interestRatePct: string | undefined;
  isOpen: boolean;
  token: string | undefined;
  wallet: CurrencyWallet | undefined;
  onClose: () => void;
};

export const NewSavingSummaryModal: React.FC<Props> = ({
  amount,
  currency,
  dailyEarn,
  interestRatePct,
  isOpen,
  token,
  wallet,
  onClose,
}) => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [step, setStep] = useState<Step>('summary');

  const transactionId = useRef<number>();

  const [error2fa, setError2fa] = useState<string>();

  const transactionActionMutation = useTransactionActionMutation();

  useEffect(() => {
    if (isOpen) {
      setStep('summary');
    }
  }, [isOpen]);

  const onVerify = (keys: string) => {
    if (!transactionId.current) {
      return;
    }
    const payload: TransactionActionPayload = {
      transaction_id: transactionId.current,
      action: 'APPROVE',
    };
    transactionActionMutation.mutate(
      { payload, headers: generateHeader2FA(keys) },
      {
        onSuccess: () => {
          navigate('/savings-accounts');
          queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
          queryClient.invalidateQueries(
            TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
          );
        },
        onError: (error) => {
          setErrorOTP(error, setError2fa, formatMessage);
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {step === 'summary' && (
        <StepNewSavingSummary
          amount={amount}
          currency={currency}
          initialDailyEarn={dailyEarn}
          initialInterestRatePct={interestRatePct}
          initialToken={token}
          wallet={wallet}
          onGoNext={(txId) => {
            transactionId.current = txId;
            setStep('success');
          }}
        />
      )}
      {step === 'success' && (
        <StepNewSavingSuccess
          onApprove={() => {
            setStep('2fa');
          }}
          onDone={() => {
            navigate('/savings-accounts');
          }}
        />
      )}
      {step === '2fa' && (
        <Module2FA
          onVerify={onVerify}
          onError={(err) => setError2fa(err)}
          error={error2fa}
          isLoading={transactionActionMutation.isLoading}
          onCancel={() => {
            setStep('2FAcancel');
          }}
          approvalOfTransaction={true}
          title={formatMessage('saving.approveSaving')}
        />
      )}
      {step === '2FAcancel' && (
        <Cancel2FA
          onClose={() => {
            onClose();
            navigate('/savings-accounts');
          }}
          approvalOfTransaction={true}
        />
      )}
    </Modal>
  );
};
