import { Button } from 'common/components/button';
import { Link } from 'common/components/Link';
import { useFormatMessage } from 'modules/messages';
import { useLocation, useMatch } from 'react-router-dom';
import styles from './UnauthTopbar.module.scss';

type Props = {};

export const UnauthTopbar: React.FC<Props> = () => {
  const location = useLocation();

  return (
    <div className={styles.container}>
      {useMatch('/create-company') && <LoginButton />}
      {useMatch('/login') && !location.state?.isCompanySuspended && (
        <CreateCompanyButton />
      )}
      {useMatch('/login') && location.state?.isCompanySuspended && (
        <LoginButton
          state={{ ...location.state, isCompanySuspended: undefined }}
        />
      )}
      {useMatch('/reset-password') && <LoginButton />}
      {useMatch('/set-new-password') && <LoginButton />}
      {useMatch('/confirm-email') && <LoginButton />}
    </div>
  );
};

const LoginButton: React.FC<{ state?: any }> = ({ state }) => {
  /**
   * state has to be of type `any`, because location.state from useLocation is of `any` type
   */
  const formatMessage = useFormatMessage();
  return (
    <Link to='/login' state={state}>
      <Button
        name={formatMessage('login')}
        type='secondaryResponsive'
        className={styles.button}
      />
    </Link>
  );
};

const CreateCompanyButton: React.FC = () => {
  const formatMessage = useFormatMessage();
  return (
    <Link to='/create-company'>
      <Button
        name={formatMessage('signup.createCompany')}
        type='secondaryResponsive'
        className={styles.button}
      />
    </Link>
  );
};
