import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { get } from 'modules/api';

const BALANCE_URL = '/balance';

export const BALANCE_QUERY_KEY = ['GET', BALANCE_URL] as const;

export type Balance = {
  available_balance: string;
  total_balance: string;
};

const getBalance = () => get<Balance>(BALANCE_URL);

export const useBalanceQuery = () => {
  return useQuery<AxiosResponse<Balance>, AxiosError<any>>(
    BALANCE_QUERY_KEY,
    getBalance
  );
};
