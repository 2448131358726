import { SectionDoubleBoxes } from 'common/components/SectionDoubleBoxes';
import { useFeatureFlag } from 'modules/feature-flags';
import { SectionBorrowSummary } from './SectionBorrowSummary';
import { SectionLendSummary } from './SectionLendSummary';

export const SectionLendBorrowSummary = () => {
  const featureFlagBorrow = useFeatureFlag('BORROW');
  const featureFlagLend = useFeatureFlag('LEND');

  return (
    <SectionDoubleBoxes
      child1={!!featureFlagBorrow.data?.enabled && <SectionBorrowSummary />}
      child2={!!featureFlagLend.data?.enabled && <SectionLendSummary />}
    />
  );
};
