import { useEffect, useRef, useState } from 'react';
import { Timer } from 'common/components/Timer';
import React from 'react';

const INITIAL_TIME = 60;

type Props = {
  token: string;
  onTimeExpired: () => void;
};

export const TokenExpiryTimer: React.FC<Props> = ({ token, onTimeExpired }) => {
  const [timeLeft, setTimeLeft] = useState(INITIAL_TIME);

  const handle = useRef<number>();

  useEffect(() => {
    if (timeLeft === 0) {
      onTimeExpired();
      return;
    }

    handle.current = window.setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => {
      clearTimeout(handle.current);
    };
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(INITIAL_TIME);
  }, [token]);

  return (
    <Timer
      value={timeLeft}
      text={`${timeLeft}s`}
      maxValue={INITIAL_TIME}
      darkMode={false}
    />
  );
};
