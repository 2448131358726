import { CancelButton, NextButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { FormTextarea } from './FormTextarea';
import { useState } from 'react';
import { Alert } from 'common/components/Alert';
import { toDecimal } from 'modules/input-amount';
import { useNewCharityFormContext } from '../context';

type Props = {
  onClose: () => void;
  onGoNext: () => void;
};

const areasSizes = {
  FUNDRISER_NAME: {
    decimalLimit: 60,
    rows: 1,
  },
  SHORT_OVERVIEW: {
    decimalLimit: 2500,
    rows: 3,
  },
  DETAILED_DESCRIPTION: {
    decimalLimit: 10000,
    rows: 6,
  },
};

export const StepSetDescriptions: React.FC<Props> = ({ onClose, onGoNext }) => {
  const formatMessage = useFormatMessage();
  const context = useNewCharityFormContext();

  const [errorName, setErrorName] = useState('');
  const [errorOverview, setErrorOverview] = useState('');
  const [errorDescription, setErrorDescription] = useState('');

  const validateAndContinue = () => {
    if (
      toDecimal(context.name.length).gt(
        toDecimal(areasSizes['FUNDRISER_NAME'].decimalLimit)
      )
    ) {
      setErrorName(
        formatMessage('common.error.characters.limit', {
          limit: areasSizes['FUNDRISER_NAME'].decimalLimit,
        })
      );
      return;
    }

    if (toDecimal(context.name.length).eq(0)) {
      setErrorName(formatMessage('common.fieldRequired'));
      return;
    }

    if (
      toDecimal(context.overview.length).gt(
        toDecimal(areasSizes['SHORT_OVERVIEW'].decimalLimit)
      )
    ) {
      setErrorOverview(
        formatMessage('common.error.characters.limit', {
          limit: areasSizes['SHORT_OVERVIEW'].decimalLimit,
        })
      );
      return;
    }

    if (toDecimal(context.overview.length).eq(0)) {
      setErrorOverview(formatMessage('common.fieldRequired'));
      return;
    }

    if (
      toDecimal(context.description.length).gt(
        toDecimal(areasSizes['DETAILED_DESCRIPTION'].decimalLimit)
      )
    ) {
      setErrorDescription(
        formatMessage('common.error.characters.limit', {
          limit: areasSizes['DETAILED_DESCRIPTION'].decimalLimit,
        })
      );
      return;
    }

    if (toDecimal(context.description.length).eq(0)) {
      setErrorDescription(formatMessage('common.fieldRequired'));
      return;
    }

    if (!errorOverview && !errorName && !errorDescription) onGoNext();
  };

  return (
    <>
      <ModalHeader showClose>
        {formatMessage('charityForms.newCharityForm')}
      </ModalHeader>
      <ModalBody>
        <Text
          style='light1425'
          inlineStyle={{ display: 'block', margin: '-10px 0 40px' }}
        >
          {formatMessage('charityForms.provideFundraiserName')}
        </Text>
        <FormTextarea
          value={context.name}
          onChange={(val) => {
            context.setName(val);
            setErrorName('');
          }}
          decimalLimit={areasSizes['FUNDRISER_NAME'].decimalLimit}
          title={formatMessage('charityForms.fundraiserName')}
          description={formatMessage('charityForms.thisIsThePrimaryTagline')}
          rows={areasSizes['FUNDRISER_NAME'].rows}
          error={errorName}
          inlineHeaderStyles={{ marginTop: '20px' }}
        />
        <FormTextarea
          value={context.overview}
          onChange={(val) => {
            context.setOverview(val);
            setErrorOverview('');
          }}
          decimalLimit={areasSizes['SHORT_OVERVIEW'].decimalLimit}
          title={formatMessage('charityForms.shortOverview')}
          description={formatMessage(
            'charityForms.keyInformationAboutFundraiser'
          )}
          rows={areasSizes['SHORT_OVERVIEW'].rows}
          error={errorOverview}
          inlineHeaderStyles={{ marginTop: '20px' }}
        />
        <FormTextarea
          value={context.description}
          onChange={(val) => {
            context.setDescription(val);
            setErrorDescription('');
          }}
          decimalLimit={areasSizes['DETAILED_DESCRIPTION'].decimalLimit}
          title={formatMessage('charityForms.detailedDescription')}
          description={formatMessage(
            'charityForms.indepthInformationAndBackstory'
          )}
          rows={areasSizes['DETAILED_DESCRIPTION'].rows}
          error={errorDescription}
          inlineHeaderStyles={{ marginTop: '20px' }}
        />
        <Alert
          text={formatMessage('charityForms.nameOverviewAndIndepthDescription')}
          severity='secondary'
          style={{ width: 'fit-content', marginTop: '40px' }}
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onClose} />
          <NextButton
            onClick={validateAndContinue}
            disabled={
              !context.name || !context.description || !context.overview
            }
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
