import { Alert } from 'common/components/Alert';
import { Button, BackButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import styles from './StepSummary.module.scss';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { AssetAmountReadOnly, WalletBox } from 'modules/financial-ops/common';
import { Text } from 'common/components/Text';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { AxiosError } from 'axios';
import { prettifyError } from 'common/utils/prettify-error';
import {
  BORROW_QUERY_KEY,
  BorrowTopupPayload,
  BorrowTopupResponseError,
  borrowHasPendingTopup,
  borrowIsNotActive,
  notEnoughFunds,
  useBorrowTopupMutation,
} from '../../api';
import { BorrowReadStateEnum } from '../../types';
import { BorrowStatePill } from '../../borrow-list/components/BorrowStatePill';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  id: number;
  amount: string;
  onGoBack: () => void;
  onGoNext: (transactionId: number) => void;
  currency?: Currency;
  wallet?: CurrencyWallet;
  newCollateralAmount?: string;
  newLtvRatio?: string;
  newState?: BorrowReadStateEnum;
};

export const StepSummary: React.FC<Props> = ({
  id,
  amount,
  onGoBack,
  onGoNext,
  currency,
  wallet,
  newCollateralAmount,
  newLtvRatio,
  newState,
}) => {
  const formatMessage = useFormatMessage();
  const topupMutation = useBorrowTopupMutation(id);
  const queryClient = useQueryClient();

  const getErrorText = (error: AxiosError<BorrowTopupResponseError>) => {
    if (notEnoughFunds(error.response?.data)) {
      return formatMessage('common.fundsAreInsufficientToFinishTopUp');
    }

    if (borrowHasPendingTopup(error.response?.data)) {
      return formatMessage('borrow.borrowHasTopupTransaction');
    }

    if (borrowIsNotActive(error.response?.data)) {
      return formatMessage('borrow.topupIsNotAvailableForBorrow');
    }

    return prettifyError(error);
  };

  return (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('borrow.reviewAndConfirmBorrowCollateralTopup')}
          </Text>
        }
      >
        {formatMessage('borrow.topupCollateralSummary')}
      </ModalHeader>
      <ModalBody>
        {topupMutation.isError && (
          <Alert severity='error' text={getErrorText(topupMutation.error)} />
        )}
        {amount && currency && (
          <>
            <SectionSubheader>
              {formatMessage('common.amount')}
            </SectionSubheader>
            <AssetAmountReadOnly
              amount={amount}
              currency={currency}
              className={styles.amountAssetBox}
            />
          </>
        )}
        {wallet && currency && (
          <WalletBox
            wallet={wallet}
            currency={currency.currency}
            className={styles.wallet}
          />
        )}
        <Summary>
          <SummaryLabel>
            {formatMessage('borrow.newCollateralAmount')}
          </SummaryLabel>
          <SummaryValue>{`${toLimitedPrec(toFixed(newCollateralAmount))} ${
            currency?.currency
          }`}</SummaryValue>
          <SummaryLabel>{formatMessage('borrow.newLtvRatio')}</SummaryLabel>
          <SummaryValue>{`${newLtvRatio}%`}</SummaryValue>
          <SummaryLabel>{formatMessage('borrow.newBorrowState')}</SummaryLabel>
          <SummaryValue>
            {newState ? <BorrowStatePill state={newState} /> : '-'}
          </SummaryValue>
        </Summary>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            type='dark'
            onClick={() => {
              if (!amount) {
                return;
              }

              const payload: BorrowTopupPayload = {
                amount,
              };

              topupMutation.mutate(payload, {
                onSuccess: (res) => {
                  onGoNext(res.data.transaction_id);
                },
                onSettled: () => {
                  queryClient.invalidateQueries(BORROW_QUERY_KEY(id));
                },
              });
            }}
            name={formatMessage('common.topUp')}
            isLoading={topupMutation.isLoading}
            disabled={topupMutation.isLoading || !!topupMutation.error}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
