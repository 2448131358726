import { ProgressBar } from 'common/components/ProgressBar';
import { Text } from 'common/components/Text';
import { StatePill } from 'modules/financial-ops/common/components/StatePill';
import { CharityFormRead } from '../../types';
import styles from './CharityFormBox.module.scss';
import { PictureBox } from './PictureBox';
import { ID } from 'modules/common-data-fields';
import { FiatCurrencyEnum } from 'modules/fiat';
import { DetailsButton } from 'modules/financial-ops/common/components/DetailsButton';
import { CollectedFunds } from 'modules/charity-forms/common/components/CollectedFunds';
import { Days } from 'modules/charity-forms/common/components/Days';
import { Donations } from 'modules/charity-forms/common/components/Donations';
import { CharityFormWithdrawButton } from 'modules/charity-forms/charity-form-withdraw';
import { toDecimal } from 'modules/input-amount';

type Props = { form: CharityFormRead; fiatCurrency: FiatCurrencyEnum };

export const CharityFormBox: React.FC<Props> = ({ form, fiatCurrency }) => {
  const {
    id,
    state,
    name,
    pictures,
    goal_amount_fiat,
    end_at_enabled,
    total_raised_fiat,
    donation_count,
    days_active,
    days_left,
    goal_raised_pct,
  } = form;

  const isFinished = state === 'COMPLETED';
  const heroPicture = pictures.find(
    (p) => p.kind === 'CONTENT' && p.order === 1
  );

  return (
    <div className={styles.container}>
      <div className={styles.boxName}>
        <div>
          <Text style='bold1622' className={styles.name}>
            {name}
          </Text>
          <StatePill state={state} />
        </div>
        <div className={styles.buttons}>
          {toDecimal(form.total_raised_fiat).gt(0) && isFinished && (
            <CharityFormWithdrawButton
              id={form.id}
              buttonType={'darkSmall'}
              className={styles.button}
            />
          )}
          <DetailsButton
            path={`/charity-forms/${id}`}
            className={styles.button}
          />
        </div>
      </div>
      <PictureBox picture={heroPicture} name={name} />
      <CollectedFunds
        collectedFunds={total_raised_fiat}
        goalAmount={goal_amount_fiat}
        fiatCurrency={fiatCurrency}
        className={styles.collectedFunds}
      />
      {goal_amount_fiat && (
        <div className={styles.progressBar}>
          <ProgressBar isFinished={isFinished} valuePct={goal_raised_pct} />
        </div>
      )}
      <div className={styles.flexBox}>
        <Days
          leftDays={days_left}
          activeDays={days_active}
          endGoalEnabled={end_at_enabled}
          isFinished={isFinished}
        />
        <Donations donations={donation_count} />
      </div>
      <ID id={id} />
    </div>
  );
};
