import { apiRaw } from 'modules/api';
import {
  CharityFormPublicRead,
  DonationIntentPayload,
  DonationIntentRequest,
  DonationIntentResponseData,
  DonationIntentResponseError,
  FormDonationPublicRead,
} from './types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { RecaptchaHeader } from 'modules/recaptcha';

// urls

const CHARITY_FORM_PUBLIC_URL = '/charity-forms-public';
const DONATIONS_URL = '/donations';
const DONATION_INTENTS_URL = '/donation-intents';

const charityFormPublicUrl = (token: string) =>
  CHARITY_FORM_PUBLIC_URL + '/' + token;

const donationsUrl = (token: string) =>
  charityFormPublicUrl(token) + DONATIONS_URL;

const donationIntentsUrl = (token: string) =>
  charityFormPublicUrl(token) + DONATION_INTENTS_URL;

// query keys

const charityFormPublicQueryKey = (token: string) =>
  ['GET', CHARITY_FORM_PUBLIC_URL, token] as const;

const donationsQueryKey = (token: string) =>
  ['GET', CHARITY_FORM_PUBLIC_URL, token, DONATIONS_URL] as const;

const donationIntentsQueryKey = (token: string) =>
  ['GET', CHARITY_FORM_PUBLIC_URL, token, DONATION_INTENTS_URL] as const;

// get/post functions

const getCharityFormPublic = async (token: string) => {
  const response = await apiRaw.get<CharityFormPublicRead>(
    charityFormPublicUrl(token)
  );
  return response.data;
};

const getDonations = async (token: string) => {
  const response = await apiRaw.get<FormDonationPublicRead[]>(
    donationsUrl(token)
  );
  return response.data;
};

const postDonationIntents = async (
  token: string,
  payload: DonationIntentPayload,
  headers: RecaptchaHeader
) => {
  const response = await apiRaw.post<
    DonationIntentResponseData,
    AxiosResponse<DonationIntentResponseData, DonationIntentPayload>,
    DonationIntentPayload
  >(donationIntentsUrl(token), payload, { headers });
  return response.data;
};

// queries and mutations

export const useCharityFormPublic = (token?: string) =>
  useQuery<CharityFormPublicRead>(
    charityFormPublicQueryKey(token!),
    () => getCharityFormPublic(token!),
    { enabled: !!token }
  );

export const useDonationsPublic = (token?: string) =>
  useQuery<FormDonationPublicRead[]>(
    donationsQueryKey(token!),
    () => getDonations(token!),
    { enabled: !!token }
  );

export const useDonationIntentsMutation = (token: string) =>
  useMutation<
    DonationIntentResponseData,
    AxiosError<DonationIntentResponseError>,
    DonationIntentRequest
  >(donationIntentsQueryKey(token), ({ payload, headers }) =>
    postDonationIntents(token, payload, headers)
  );

// errors

const INVALID_ADDRESS_ERR_REGEX = /Invalid (\w+) address./;

export const isAddressInvalidError = (
  error: AxiosError<DonationIntentResponseError>
) => {
  for (let e of error.response?.data.address || []) {
    const match = e.match(INVALID_ADDRESS_ERR_REGEX);
    if (match) {
      return match;
    }
  }
  return undefined;
};
