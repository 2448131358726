import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useEffect, useState } from 'react';
import { AddressItem } from '../AddressRow';
import {
  useWhitelistAddressDeleteMutation,
  WhitelistedAddressResponseError,
  WHITELISTED_ADDRESS_URL,
} from '../api';
import { RemoveStep } from './RemoveStep';
import { SuccessStep } from './SuccessStep';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Step = 'remove' | '2FA' | 'success' | '2FAcancel';

export const RemoveModal: React.FC<{
  open: boolean;
  onClose: () => void;
  item: AddressItem;
}> = ({ open, onClose, item }) => {
  const queryClient = useQueryClient();

  const formatMessage = useFormatMessage();
  const [step, setStep] = useState<Step>('remove');
  const [error, setError] =
    useState<AxiosError<WhitelistedAddressResponseError>>();

  const { mutate, isLoading } = useWhitelistAddressDeleteMutation();
  const [error2FA, setError2FA] = useState<string>();

  useEffect(() => {
    setStep('remove');
    setError2FA(undefined);
    setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onClick = () => {
    mutate(
      { id: item.id },
      {
        onSuccess: () => {
          setStep('2FA');
        },
        onError: (err) => setError(err),
      }
    );
  };

  const onVerify = (code: string) => {
    mutate(
      {
        id: item.id,
        headers: generateHeader2FA(code),
      },
      {
        onSuccess: () => {
          setStep('success');
        },
        onError: (err) => {
          setErrorOTP(err, setError2FA, formatMessage);
        },
      }
    );
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      {step === 'remove' && (
        <RemoveStep
          onClick={onClick}
          item={item}
          error={error}
          isLoading={isLoading}
          onCancel={onClose}
        />
      )}
      {step === '2FA' && (
        <Module2FA
          onVerify={onVerify}
          onError={(err) => setError2FA(err)}
          error={error2FA}
          isLoading={isLoading}
          onCancel={() => setStep('2FAcancel')}
          title={formatMessage('addressWhitelist.removeWhitelistedAddress')}
        />
      )}
      {step === 'success' && (
        <SuccessStep
          onDone={() => {
            queryClient.invalidateQueries(['GET', WHITELISTED_ADDRESS_URL]);
            onClose();
          }}
          title={formatMessage('addressWhitelist.whitelistedAddressRemoved')}
          subtitle={formatMessage(
            'addressWhitelist.addressWillNoLongerBeAvailableToSendFunds'
          )}
        />
      )}
      {step === '2FAcancel' && <Cancel2FA onClose={onClose} />}
    </Modal>
  );
};
