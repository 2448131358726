import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { NetworkEnum } from 'common/types';
import { get, LONG_STALE_TIME, post } from 'modules/api';
import { AvailableNetwork, Wallet } from './types';

export const WALLETS_URL = '/wallets';

export const WALLETS_QUERY_KEY = ['GET', WALLETS_URL] as const;
export const POST_WALLETS_QUERY_KEY = ['POST', WALLETS_URL] as const;

export type GetWalletsResponseData = Wallet[];

const getWallets = async () => {
  const response = await get<Wallet[], void>(WALLETS_URL);
  return response.data;
};

export const useWallets = () => {
  return useQuery<Wallet[], AxiosError<any>>(WALLETS_QUERY_KEY, getWallets);
};

export type CreateWalletPayload = {
  name: string;
  network: NetworkEnum;
  is_multi_approval?: boolean;
};

export type CreateWalletResponseData = Wallet;

export const ERR_WALLET_EXISTS_IN_NETWORK =
  'Wallet with this name already exists in this network.';

export type CreateWalletResponseError = {
  detail?: string;
  name?: string[];
  network?: string[];
  non_field_errors?: string[];
  status?: string;
};

const createWallet = (
  payload: CreateWalletPayload,
  headers?: AxiosRequestHeaders
) =>
  post<CreateWalletResponseData, CreateWalletPayload>(WALLETS_URL, payload, {
    headers,
  });

export type CreateWalletRequest = {
  payload: CreateWalletPayload;
  headers?: AxiosRequestHeaders;
};

export const useCreateWalletMutation = () => {
  return useMutation<
    AxiosResponse<CreateWalletResponseData>,
    AxiosError<CreateWalletResponseError>,
    CreateWalletRequest
  >(POST_WALLETS_QUERY_KEY, ({ payload, headers }) =>
    createWallet(payload, headers)
  );
};

export const AVAILABLE_NETWORKS_URL = '/available-networks';
export const AVAILABLE_NETWORKS_QUERY_KEY = '/available-networks';

export type GetAvailableNetworksResponseData = AvailableNetwork[];

const getAvailableNetworks = () =>
  get<GetAvailableNetworksResponseData, void>(AVAILABLE_NETWORKS_URL);

export const useGetAvailableNetworks = () => {
  return useQuery<
    AxiosResponse<GetAvailableNetworksResponseData>,
    AxiosError<any>
  >(['GET', AVAILABLE_NETWORKS_QUERY_KEY], getAvailableNetworks, {
    staleTime: LONG_STALE_TIME,
  });
};
