import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { BaseCurrency } from 'modules/select-currency-wallet/types';
import { InterestRate } from 'modules/financial-ops/common';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';

type Props = {
  amount?: string;
  currency: BaseCurrency;
  rate?: InterestRate;
  totalEarn?: string;
  isLoading: boolean;
};

export const LendEffectOnSaving: React.FC<Props> = ({
  amount,
  currency,
  rate,
  totalEarn,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <Summary>
        <SummaryLabel>{formatMessage('common.interestRate')}</SummaryLabel>
        <SummaryValue>{rate ? `${toFixed(rate.rate_pct)}%` : '-'}</SummaryValue>
        <SummaryLabel>{formatMessage('lend.lendingAmount')}</SummaryLabel>
        <SummaryValue>
          {toDecimal(amount).gt(0)
            ? `${toLimitedPrec(toFixed(amount))} ${currency?.currency}`
            : '-'}
        </SummaryValue>
        <SummaryLabel>{formatMessage('finopsCommon.totalEarn')}</SummaryLabel>
        <SummaryValue>
          {toDecimal(amount).gt(0) && totalEarn ? (
            `${toLimitedPrec(toFixed(totalEarn))} ${currency.currency}`
          ) : isLoading ? (
            <RotatingLoader size={10} type='secondary' />
          ) : (
            '-'
          )}
        </SummaryValue>
      </Summary>
    </>
  );
};
