import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';

type Props = {
  donations: number;
};

export const Donations: React.FC<Props> = ({ donations }) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text
        style='light1215'
        colorStyle='secondary'
        inlineStyle={{ display: 'block', marginBottom: '5px' }}
      >
        {formatMessage('charityForms.donations') + ':'}
      </Text>
      <Text
        style='xxbold1619'
        colorStyle='primary'
        inlineStyle={{ display: 'block' }}
      >
        {donations}
      </Text>
    </div>
  );
};
