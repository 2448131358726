import clsx from 'clsx';
import styles from './WithdrawAddressBox.module.scss';
import { textStyles, Text, textColorStyles } from 'common/components/Text';
import { ReactComponent as ArrowDown } from 'common/assets/black-icons/arrowhead-down-12px.svg';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { SelectWhitelistedAddress } from './SelectWhitelistedAddress';
import { WhitelistedAddress } from 'modules/address-whitelist/types';
import { Currency } from 'modules/select-currency-wallet/types';

type Props = {
  onChangeWithdrawAddress: (addr: WhitelistedAddress) => void;
  name?: string;
  address?: string;
  errorText: string;
  onError: (err: string) => void;
  className?: string;
  currency?: Currency;
};

export const WithdrawAddressBox: React.FC<Props> = ({
  onChangeWithdrawAddress,
  onError,
  name,
  address,
  errorText,
  className,
  currency,
}) => {
  const formatMessage = useFormatMessage();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setIsDropdownOpen(true);
        }}
        className={className}
      >
        <Text
          style='xbold1417'
          colorStyle={'primary'}
          className={styles.headerText}
        >
          {formatMessage('withdraw.withdrawalAddress')}
        </Text>
        <div
          className={clsx(
            textStyles.light1425,
            styles.box,
            errorText && styles.errorBox
          )}
          data-test='whitelistedAddressField'
        >
          <div className={styles.boxWithIconBox}>
            <div className={styles.nameBox}>
              <Text
                style={address ? 'light1622' : 'light1425'}
                colorStyle={address ? 'primary' : 'secondary'}
                className={clsx(address && styles.valueText)}
              >
                {name ? name : formatMessage('common.selectFromList')}
              </Text>
              <div
                className={clsx(
                  textStyles.light1215,
                  textColorStyles.secondary,
                  styles.address,
                  !address && styles.displayNone
                )}
              >
                {address}
              </div>
            </div>
            <ArrowDown className={styles.arrowDown} />
          </div>
        </div>
        {errorText && (
          <Text style='regular1215' colorStyle={'error'} dataTest='errorText'>
            {errorText}
          </Text>
        )}
      </div>
      <SelectWhitelistedAddress
        isOpen={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
        currency={currency}
        onSelect={(address) => {
          onChangeWithdrawAddress(address);
          onError('');
        }}
      />
    </>
  );
};
