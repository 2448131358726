import { Text, TextStyle } from 'common/components/Text';
import { FiatAmount } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';

type Props = {
  amount?: string;
  isLoading?: boolean;
  textStyle?: TextStyle;
  loaderSize?: number;
  loaderContainerSize?: number;
};

export const FundsLockedInLends: React.FC<Props> = ({
  amount,
  isLoading,
  textStyle = 'xxbold3048',
  loaderSize = 30,
  loaderContainerSize = 48,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text style='light1215' inlineStyle={{ display: 'block' }}>
        {formatMessage('lend.fundsLockedInLends')}
      </Text>
      <FiatAmount
        amount={amount}
        loaderContainerSize={loaderContainerSize}
        loaderSize={loaderSize}
        isDataLoading={isLoading}
        textColorStyle='primary'
        textStyle={textStyle}
      />
    </div>
  );
};
