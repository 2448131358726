import { useNavigate } from 'react-router-dom';
import { useNotificationsMenuContext } from '../context';
import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { useUnreadNotificationsCount } from '../api';

export type Props = { menuDotsCount: number };

export const ShowAllButton: React.FC<Props> = ({ menuDotsCount }) => {
  const formatMessage = useFormatMessage();
  const { closeNotifications } = useNotificationsMenuContext();
  const navigate = useNavigate();
  const { data } = useUnreadNotificationsCount();

  return (
    <Button
      type='secondarySmall'
      name={formatMessage('common.button.showAll')}
      hasNotificationDot={data && data.count > menuDotsCount ? true : false}
      onClick={() => {
        closeNotifications();
        navigate('/notifications');
      }}
    />
  );
};
