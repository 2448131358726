import { BackButton, NextButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { HeaderWithDescription } from './HeaderWithDescription';
import { DragAndDrop } from './DragAndDrop';
import styles from './StepSetLogoAndStyles.module.scss';
import { ReactComponent as RemoveIcon } from '../assets/remove.svg';
import { Themes } from './Themes';
import { TopbarSelector } from './TopbarSelector';
import { CharityFormPreviewButton } from './CharityFormPreviewButton';
import { useNewCharityFormContext } from '../context';
import {
  PostCharityFormPicturesPayload,
  useCharityFormsPicturesMutation,
} from 'modules/charity-forms/api';
import { useState } from 'react';
import { Alert } from 'common/components/Alert';
import { FileRejection } from 'react-dropzone';
import clsx from 'clsx';

type Props = {
  onGoNext: () => void;
  onGoBack: () => void;
};

export const StepSetLogoAndStyles: React.FC<Props> = ({
  onGoNext,
  onGoBack,
}) => {
  const formatMessage = useFormatMessage();
  const context = useNewCharityFormContext();
  const picturesMutation = useCharityFormsPicturesMutation();
  const [logoError, setLogoError] = useState('');
  const [alertError, setAlertError] = useState('');

  const validateAndContinue = () => {
    if (!context.logo) {
      setAlertError(formatMessage('charityForms.addYourCompanyLogo'));
      return;
    }

    if (!context.primaryColor) {
      setAlertError(formatMessage('charityForms.chooseThemeForTheCharity'));
      return;
    }
    onGoNext();
  };

  const postLogo = (items: File[], rejectedFiles: FileRejection[]) => {
    if (!items || !items.length || !!rejectedFiles.length || items.length > 1) {
      setLogoError(formatMessage('charityForms.addYourCompanyLogo'));
      return;
    }
    setLogoError('');

    const payload: PostCharityFormPicturesPayload[] = items.map((file) => {
      return { file, kind: 'LOGO' };
    });

    picturesMutation.mutate(payload, {
      onSuccess: (response) => {
        const newValues = response.map((item) => {
          return {
            id: item.data.id,
            url: item.data.file,
            kind: 'PHOTO' as const,
          };
        })[0];

        context.setLogo(newValues);
      },
    });

    setAlertError('');
    setLogoError('');
  };

  return (
    <>
      <ModalHeader showClose>
        {formatMessage('charityForms.newCharityForm')}
      </ModalHeader>
      <ModalBody>
        <Text
          style='light1425'
          inlineStyle={{ display: 'block', margin: '-10px 0 40px' }}
        >
          {formatMessage(
            'charityForms.addYourCompanyLogoAndOptionallyPersonalizeCharity'
          )}
        </Text>
        <HeaderWithDescription
          title={formatMessage('charityForms.uploadLogo')}
        />
        <DragAndDrop
          maxFilesSize={1}
          disabled={!!context.logo}
          onDrop={postLogo}
          btnText={formatMessage('charityForms.addFile')}
          description={formatMessage('charityForms.addYourCompanyLogo')}
          fileFormats={{
            'image/svg+xml': [],
            'image/png': [],
          }}
          isLoading={picturesMutation.isLoading}
          error={logoError}
        />
        {context.logo && (
          <div className={styles.logoBox}>
            <img
              src={context.logo.url}
              alt={formatMessage('charityForms.logo')}
              className={styles.image}
            />
            <RemoveIcon
              className={styles.removeIcon}
              onClick={() => context.setLogo(undefined)}
            />
          </div>
        )}
        <div className={styles.section}>
          <HeaderWithDescription title={formatMessage('charityForms.topBar')} />
          <TopbarSelector
            isDarkMode={context.darkMode}
            onChange={(value) => context.setDarkMode(value)}
          />
        </div>
        <div className={styles.section}>
          <HeaderWithDescription
            title={formatMessage('charityForms.themes')}
            description={formatMessage('charityForms.sugessted')}
          />
          <Themes
            selectedColor={context.selectedColorId}
            onChange={(id, { primary, secondary }) => {
              context.setSelectedColorId(id);
              context.setPrimaryColor(primary);
              context.setSecondaryColor(secondary);
              setAlertError('');
            }}
            customColors={context.customColors}
            onChangeCustomColors={(value) => {
              context.setCustomColors(value);
              setAlertError('');
            }}
          />
        </div>
        <div className={clsx(styles.section, styles.previewSection)}>
          <HeaderWithDescription
            title={formatMessage('charityForms.fundraiserPreview')}
          />
          <CharityFormPreviewButton />
        </div>
        {alertError && (
          <Alert
            text={alertError}
            severity={'error'}
            style={{ marginBottom: '40px' }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <NextButton onClick={validateAndContinue} disabled={!context.logo} />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
