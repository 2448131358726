export const theme = {
  colors: {
    backgroundLight: '#ffffff',
    backgroundLightHover: '#fafafa',
    backgroundLightInput: '#f9f9f9',
    backgroundMedium: '#fafafa',
    backgroundMediumHover: '#efefef',
    backgroundDark: '#f5f5f5',
    backgroundSliderThumb: '#000000',
    backgroundSidebarButton: 'rgba(220, 220, 220, 0.19)',
    backgroundOverlay: 'rgba(0, 0, 0, 0.07)',
    backgroundOverlay01: 'rgba(0, 0, 0, 0.1)',
    backgroundOverlay014: 'rgba(0, 0, 0, 0.15)',
    backgroundTheme: '#fffbd7',
    backgroundThemeDark: '#f1dc1f',
    backgroundError: '#ffeded',
    backgroundErrorDark: '#ffe7da',
    backgroundSuccess: '#d6fea3',
    backgroundWarning: '#fffbd7',
    backgroundSecondary: 'rgba(220, 220, 220, 0.19)',
    backgroundNavbarDark: '#3d3d3d',
    backgroundNavbarLight: '#fcfcfc',
    backgroundThemeGradient: 'linear-gradient(180deg,#ede300 0%,#ffd88d 100%)',
    backgroundThemeGradientLight:
      'linear-gradient(180deg,rgba(237, 227, 0, 0.4) 0%,rgba(255, 216, 141, 0.4) 100%)',
    backgroundThemeShadowOpacity3: 'rgba(237, 171, 0, 0.3)',
    backgroundThemeShadowOpacity2: 'rgba(237, 171, 0, 0.2)',
    backgroundCircleNumber: '#373737',
    backgroundNotifications: '#ff8946',
    backgroundApr: '#e0f6ff',
    backgroundTimerPath: '#FF8946',
    backgroundTimerPathError: '#D96262',
    backgroundTimerTrailTight: '#5b5b5b',
    backgroundFinishedSlider: '#fff7ad',
    textPrimary: '#000000',
    textPrimaryGreyout: '#b7b7b7',
    textSecondary: '#808080',
    textLight: '#d8d8d8',
    textIncative: '#b7b7b7',
    textTheme: '#fff37e',
    textThemeGreyout: '#fff9bf',
    textError: '#cc8989',
    textDark: '#121212',
    textNavbarLight: '#ffffff',
    btnLight: '#ffffff',
    btnDark: '#3d3d3d',
    btnDarkGreyout: '#9b9b9b',
    borderLight: '#eaeaea',
    borderPrimary: '#000',
    borderLightFocus: '#bbc0f2',
    borderMedium: '#d8d8d8',
    borderTheme: '#efd90f',
    borderThemeLight: '#fff37e',
    borderDark: '#212121',
    borderNavbarLight: '#ffffff',
    borderOverlay: 'rgba(0, 0, 0, 0.25)',
    borderError: '#f2bbbb',
    borderDivider: 'rgba(0, 0, 0, 0.1)',
    borderStatusPending: '#ff8946',
    borderConfirmationBoxShadow: 'rgba(237, 171, 0, 0.3)',
    borderCircleBoxShadow: 'rgba(223, 208, 131, 0.25)',
    borderRefreshBoxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    borderDropdownShadow: 'drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.15))',
    borderBoxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    borderAprShadow: ' 0px 0px 18px rgba(0, 0, 0, 0.15)',
    borderCircleRemoveShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.13)',
    commonShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
    borderApr: '#7eccf8',
    iconTheme: '#efd90f',
    iconAlert: '#ef6619',
    iconLoader: '#9d9d9d',
    borrowPillRepaymentBackground: '#d5dffa',
    borrowPillRepaymentBorder: '#6275d9',
    borrowPillHealthyBackground: '#eef8d2',
    borrowPillHealthyBorder: '#bfec3e',
    borrowPillNearMarginCallBackground: '#fffedc',
    borrowPillNearMarginCallBorder: '#efdb21',
    borrowPillMarginCallBackground: '#fde1d1',
    borrowPillMarginCallBorder: '#ff8946',
    borrowPillLiquidationBackground: '#fad5d5',
    borrowPillLiquidationBorder: '#d96262',
    borrowPillLiquidatedBackground: '#edb7b7',
    borrowPillLiquidatedBorder: '#9a0505',
    borrowPillCompletedBackground: '#dce7f2',
    borrowPillCompletedBorder: '#a9d4ee',
    borrowAlertOvercollateralizedBackground: '#f9ffe8',
    borrowAlertHealthyBackground: '#ffffff',
    borrowAlertNearMarginCallBackground: '#fffee6',
    borrowAlertMarginCallBackground: '#feede3',
    borrowAlertLiquidationBackground: '#ffe9e9',
    borrowAlertLiquidatedBackground: '#ffd5d5',
    borrowAlertCompletedBackground: '#e9f3fd',
    pillActiveBackground: '#eef8d2',
    pillActiveBorder: '#bfec3e',
    pillCompletedBackground: '#dce7f2',
    pillCompletedBorder: '#a9d4ee',
    pillTerminatedBackground: '#808080',
    pillTerminatedBorder: 'rgba(220, 220, 220, 0.19)',
    horizontalHealthGradient:
      'linear-gradient(90deg, #97C160 0%, #B8CB49 51.04%, #F2DD21 70.31%, #F48F46 84.37%, #E77059 100%)',
    kycaddressplaceholderBackground: 'rgba(220, 220, 220, 0.19)',
    kycaddressplaceholderBorder: '#808080',
    kycalertBackground: '#fffee6',
    rangeDarkTheme: '#ede300',
    rangeLightTheme: '#ffd88d',
  },
  bubbleChartTooltipShadow: '0px 6px 10px rgba(184, 184, 184, 0.25)',
  fontFamilies: {
    inter: 'Inter',
  },
  fontWeights: {
    light: 400,
    regular: 500,
    bold: 600,
    xbold: 700,
    xxbold: 800,
  },
  balanceChartTotal: '#9de6f5',
  balanceChartAvailable: '#3373ad',
  balanceChartReference: '#b7b7b7',
};
