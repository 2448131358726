import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useContext } from 'react';
import { Text } from 'common/components/Text';

type Props = {
  name: string;
  surname: string;
};

export const InviteMembersInvitationSent: React.FC<Props> = ({
  name,
  surname,
}) => {
  const { onClose } = useContext(ModalContext);

  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader>
        {formatMessage('inviteMembers.invitationSent.header')}
      </ModalHeader>
      <ModalBody>
        <Text style='light1425'>
          {formatMessage('inviteMembers.invitationSent.description', {
            name,
            surname,
          })}
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('common.button.close')}
            type='dark'
            onClick={onClose}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
