import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { InterestRateUpdatedEvent } from '../types/interest-rate-updated-event';
import {
  InterestRate,
  getInterestRatesQueryKey,
} from 'modules/financial-ops/common';
import { log } from 'modules/logger';

export const useHandleInterestRateUpdated = () => {
  const queryClient = useQueryClient();

  const handleInterestRateUpdated = useCallback(
    (event: InterestRateUpdatedEvent) => {
      log('handling INTEREST_RATE_UPDATED WebSocket event', event);

      const updatedInterestRate = event.interest_rate;

      queryClient.setQueryData(
        getInterestRatesQueryKey(updatedInterestRate.kind),
        (prevState: InterestRate[] | undefined) => {
          if (!prevState) {
            return undefined;
          }

          const idx = prevState.findIndex(
            (interestRate) =>
              interestRate.term === updatedInterestRate.term &&
              interestRate.currency === updatedInterestRate.currency &&
              interestRate.network === updatedInterestRate.network
          );

          // just for caution, but this should be impossible situation
          if (idx === -1) {
            return undefined;
          }

          return [
            ...prevState.slice(0, idx),
            updatedInterestRate,
            ...prevState.slice(idx + 1),
          ];
        }
      );
    },
    [queryClient]
  );

  return handleInterestRateUpdated;
};
