import { LoaderBox } from 'common/components/loader';
import { Section } from 'common/components/Section';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { useNavigate, useParams } from 'react-router-dom';
import { useCharityForm } from '../api';
import styles from './CharityFormDetails.module.scss';
import { CharityFormDetailsHeader } from './components/CharityFormDetailsHeader';
import { StatePill } from 'modules/financial-ops/common/components/StatePill';
import { CollectedFunds } from '../common/components/CollectedFunds';
import { Days } from '../common/components/Days';
import { Donations } from '../common/components/Donations';
import { Networks } from './components/Networks';
import { ID, OpenedDate } from 'modules/common-data-fields';
import { EndDate } from './components/EndDate';
import { Description } from './components/Description';
import { useFormatMessage } from 'modules/messages';
import { ImagesAndVideos } from './components/ImagesAndVideos';
import { useFiatCurrency } from 'modules/fiat';
import { DonationsTable } from './components/DonationsTable';
import { FundraisingBalanceTable } from './components/FundraisingBalanceTable';
import { InappLink } from './components/InappLink';
import { IframeCode } from './components/IframeCode';
import { CharityFormWithdrawButton } from '../charity-form-withdraw';
import { SectionHeader } from 'common/components/SectionHeader';
import { CharityFormFinishButton } from '../charity-forms-finish';
import { toDecimal } from 'modules/input-amount';
import { useIsOwner } from 'modules/user';

type Props = {};

export const CharityFormDetails: React.FC<Props> = () => {
  const navigate = useNavigate();
  const formatMessage = useFormatMessage();

  const { id } = useParams();

  const { data, isLoading, error } = useCharityForm(parseInt(id!));
  const { data: fiatCurrency, isLoading: isFiatCurrencyLoading } =
    useFiatCurrency();
  const { data: isOwner } = useIsOwner();

  const featureFlag = useRequireFeatureFlag('CHARITY_FORM');

  if (featureFlag.isLoading || isLoading || isFiatCurrencyLoading) {
    return <LoaderBox />;
  }

  if (!featureFlag.data?.enabled) {
    return null;
  }

  if (!id) {
    return <NothingToDisplay />;
  }

  const charityForm = data?.data;
  const isFinished = charityForm?.state === 'COMPLETED';

  return (
    <Section>
      {charityForm && fiatCurrency && (
        <>
          <SectionHeader
            header={
              <CharityFormDetailsHeader
                onArrowClick={() => navigate('/charity-forms')}
                name={charityForm.name}
              />
            }
            actions={
              <>
                <CharityFormWithdrawButton
                  id={parseInt(id!)}
                  buttonType={isFinished ? 'dark' : 'secondary'}
                  className={styles.button}
                />
                {!isFinished && isOwner && (
                  <CharityFormFinishButton
                    charityForm={charityForm}
                    className={styles.button}
                  />
                )}
              </>
            }
            actionsClassName={styles.buttons}
            className={styles.header}
          />
          <div className={styles.container}>
            <div>
              <StatePill state={charityForm.state} />
              <div className={styles.mainContainer}>
                <CollectedFunds
                  collectedFunds={charityForm.total_raised_fiat}
                  goalAmount={charityForm.goal_amount_fiat}
                  fiatCurrency={fiatCurrency}
                  verticalView={true}
                />
                <Donations donations={charityForm?.donation_count} />
                <Days
                  leftDays={charityForm.days_left}
                  activeDays={charityForm.days_active}
                  endGoalEnabled={charityForm.end_at_enabled}
                  isFinished={isFinished}
                />
                <Networks networks={charityForm.networks} />
              </div>
              <div className={styles.secondaryContainer}>
                <OpenedDate startAt={charityForm.created_at} />
                {charityForm.end_at && !charityForm.completed_at && (
                  <EndDate endAt={charityForm.end_at} />
                )}
                {charityForm.completed_at && (
                  <EndDate endAt={charityForm.completed_at} />
                )}
                <ID id={charityForm.id} />
              </div>
            </div>
            <div className={styles.tables}>
              <FundraisingBalanceTable
                id={charityForm.id}
                currentBalanceByCurrency={
                  charityForm.current_balance_by_currency
                }
                currentBalanceFiatByCurrency={
                  charityForm.current_balance_fiat_by_currency
                }
                totalRaisedByCurrency={charityForm.total_raised_by_currency}
                totalRaisedFiatByCurrency={
                  charityForm.total_raised_fiat_by_currency
                }
                isLoading={isLoading}
                networks={charityForm.networks}
              />
              <DonationsTable
                id={charityForm.id}
                networks={charityForm.networks}
              />
            </div>
            <div className={styles.links}>
              <InappLink token={charityForm.token} />
              <IframeCode token={charityForm.token} />
            </div>
            <ImagesAndVideos
              pictures={charityForm.pictures}
              youtubeLink={charityForm.youtube_link}
              youtubeOrder={charityForm.youtube_link_order}
            />
            <Description
              title={formatMessage('charityForms.shortOverview')}
              description={charityForm.overview}
            />
            <Description
              title={formatMessage('charityForms.detailedDescription')}
              description={charityForm.description}
            />
          </div>
        </>
      )}
      {error && (
        <>
          <CharityFormDetailsHeader
            onArrowClick={() => navigate('/charity-forms')}
          />
          <NothingToDisplay />
        </>
      )}
    </Section>
  );
};
