import { Text } from 'common/components/Text';
import styles from './FundraisingBalanceTable.module.scss';
import { useFormatMessage } from 'modules/messages';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { FullHistoryButton } from './FullHistoryButton';
import { CurrencyEnum, NetworkEnum } from 'common/types';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { AmountWithFiat } from './AmountWithFiat';
import {
  FundraisingBalanceFilterType,
  FundraisingBalanceFilters,
} from './FundraisingBalanceFilters';
import { useState } from 'react';
import { LoaderBox } from 'common/components/loader';

export type FundraisingBalance = {
  currency: CurrencyEnum;
  amount: string;
  fiatAmount: string;
  network: NetworkEnum;
};

type Props = {
  currentBalanceByCurrency: { [currency in CurrencyEnum]: string };
  currentBalanceFiatByCurrency: { [currency in CurrencyEnum]: string };
  totalRaisedByCurrency: { [currency in CurrencyEnum]: string };
  totalRaisedFiatByCurrency: { [currency in CurrencyEnum]: string };
  id: number;
  isLoading: boolean;
  networks: NetworkEnum[];
};

export const FundraisingBalanceTable: React.FC<Props> = ({
  currentBalanceByCurrency,
  currentBalanceFiatByCurrency,
  totalRaisedByCurrency,
  totalRaisedFiatByCurrency,
  isLoading,
  id,
  networks,
}) => {
  const formatMessage = useFormatMessage();
  const [selectedFilter, setSelectedFilter] =
    useState<FundraisingBalanceFilterType>('CURRENT');

  const isCurrent = selectedFilter === 'CURRENT';

  const balances = Object.entries(
    isCurrent ? currentBalanceByCurrency : totalRaisedByCurrency
  )
    .map((key) => {
      return {
        currency: key[0],
        amount: key[1],
        fiatAmount: isCurrent
          ? currentBalanceFiatByCurrency[key[0] as CurrencyEnum]
          : totalRaisedFiatByCurrency[key[0] as CurrencyEnum],
        network: (key[0] === 'BTC' ? 'BITCOIN' : 'ETHEREUM') as NetworkEnum,
      };
    })
    .filter((bal) => networks.includes(bal.network)) as FundraisingBalance[];

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          <Text style='xxbold1619' inlineStyle={{ display: 'block' }}>
            {formatMessage('charityForms.fundraisingBalance')}
          </Text>
          <FundraisingBalanceFilters
            selectedFilter={selectedFilter}
            onSelect={(type) => setSelectedFilter(type)}
          />
        </div>
        {!currentBalanceByCurrency && !isLoading && (
          <NothingToDisplay size={200} />
        )}
        {isLoading && <LoaderBox />}
        <div>
          {balances.map((balance, i) => (
            <div key={i} className={styles.row}>
              <CurrencyBox
                currency={balance.currency}
                network={balance.network}
              />
              <AmountWithFiat
                currency={balance.currency}
                amount={balance.amount}
                fiatAmount={balance.fiatAmount}
              />
            </div>
          ))}
        </div>
      </div>
      <FullHistoryButton
        filters={{ charityForms: [id] }}
        className={styles.btn}
        disabled={!currentBalanceByCurrency}
      />
    </div>
  );
};
