import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { Text, TextColorStyle, TextStyle } from 'common/components/Text';
import Decimal from 'decimal.js';
import { toFixed } from 'modules/input-amount';
import { useFiatCurrency } from './hooks';
import { CURRENCY_SYMBOLS } from './utils';

export type FiatAmountProps = {
  amount?: string | Decimal;
  isDataLoading?: boolean;
  className?: string;
  textStyle?: TextStyle;
  textColorStyle?: TextColorStyle;
  loaderSize?: number;
  loaderContainerSize?: number;
};

export const FiatAmount: React.FC<FiatAmountProps> = ({
  amount,
  isDataLoading = false,
  className,
  textStyle = 'light1215',
  textColorStyle = 'secondary',
  loaderSize = 12,
  loaderContainerSize,
}) => {
  const { data: fiatCurrency, isLoading: isFiatCurrencyLoading } =
    useFiatCurrency();

  const isLoading = isDataLoading || isFiatCurrencyLoading;

  return (
    <div
      style={{ display: 'inline-flex', gap: '5px', alignItems: 'center' }}
      className={className}
    >
      {isLoading && (
        <RotatingLoader
          size={loaderSize}
          type={'secondary'}
          containerSize={loaderContainerSize}
        />
      )}
      {amount && fiatCurrency && (
        <Text style={textStyle} colorStyle={textColorStyle}>
          {`${CURRENCY_SYMBOLS[fiatCurrency]}${toFixed(amount, 2)}`}
        </Text>
      )}
    </div>
  );
};
