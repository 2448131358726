import clsx from 'clsx';
import styles from './InappLayout.module.scss';

type Props = {
  darkMode: boolean;
  form: React.ReactNode;
  logo: React.ReactNode;
};
export const InappLayout: React.FC<Props> = ({ darkMode, form, logo }) => {
  return (
    <div className={styles.container}>
      <div className={clsx(styles.topContainer, darkMode && styles.dark)}>
        {logo}
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.content}>{form}</div>
      </div>
    </div>
  );
};
