import styles from './ColorPicker.module.scss';
import { CustomPicker, CustomPickerInjectedProps } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';
import { useState } from 'react';
import { Text } from 'common/components/Text';
import { theme } from 'common/consts/theme';

import { toDecimal } from 'modules/input-amount';
import { Input } from 'common/components/input/Input';
import {
  NATURAL_NUMBER_REGEX,
  HEX_LENGTH,
  RGB_MAX_VALUE,
  hexToRgb,
  hsl2rgb,
  hsv2rgb,
  isValidHex,
  rgbToHex,
} from './utils';
import { RgbType } from './types';
import { useFormatMessage } from 'modules/messages';
import { SaturationColorResult } from 'react-color/lib/components/common/Saturation';
import { HueColorResult } from 'react-color/lib/components/common/Hue';

type NewColorPickerProps = CustomPickerInjectedProps & {
  onColorChange: (color: string) => void;
  color: string;
};

const ColorPicker: React.FC<NewColorPickerProps> = ({
  onColorChange,
  color,
  ...props
}) => {
  const formatMessage = useFormatMessage();
  const [rgb, setRgb] = useState<RgbType>({ ...props.rgb } || {});

  return (
    <>
      <div className={styles.saturationBox}>
        <Saturation
          {...props}
          pointer={SaturationPointer}
          onChange={(data: SaturationColorResult) => {
            const rgb = hsv2rgb(data);
            setRgb(rgb);

            const hex = rgbToHex(rgb);
            if (hex) onColorChange(hex);
          }}
        />
      </div>
      <div className={styles.hueBox}>
        <Hue
          {...props}
          pointer={HuePointer}
          onChange={(data: HueColorResult) => {
            const rgb = hsl2rgb(data);
            setRgb(rgb);

            const hex = rgbToHex(rgb);
            if (hex) onColorChange(hex);
          }}
        />
      </div>
      <div
        className={styles.selectedColor}
        style={{
          backgroundColor: isValidHex(color)
            ? color
            : theme.colors.backgroundLight,
        }}
      >
        {!isValidHex(color) && (
          <Text style='light1421' colorStyle='inactive'>
            {formatMessage('charityForms.invalidHex')}
          </Text>
        )}
      </div>
      <div className={styles.titles}>
        <Text style='xbold1215' className={styles.title}>
          {'Hex'}
        </Text>
        <Text style='xbold1215' className={styles.title}>
          {'R'}
        </Text>
        <Text style='xbold1215' className={styles.title}>
          {'G'}
        </Text>
        <Text style='xbold1215' className={styles.title}>
          {'B'}
        </Text>
      </div>
      <div className={styles.inputs}>
        <Input
          className={styles.input}
          value={color}
          onChange={(data) => {
            const value = data.target.value;

            if (value.length > HEX_LENGTH) {
              return;
            }

            const result = hexToRgb(value);

            if (isValidHex(value) && result) {
              const rgb = result;
              setRgb(rgb);
            }

            onColorChange(value);
          }}
        />
        <Input
          className={styles.input}
          value={rgb.r}
          type='text'
          onChange={(data) => {
            const value = data.target.value;

            const match = value.match(NATURAL_NUMBER_REGEX);
            if (!match && value !== '') {
              return;
            }

            const r = toDecimal(value).gt(RGB_MAX_VALUE)
              ? RGB_MAX_VALUE
              : value;

            setRgb({ ...rgb, r });

            const hex = rgbToHex({ ...rgb, r });
            if (hex) onColorChange(hex);
          }}
          onBlur={() => {
            if (!rgb.r) {
              setRgb({ ...rgb, r: 0 });
              const hex = rgbToHex({ ...rgb, r: 0 });
              if (hex) onColorChange(hex);
            }
          }}
        />
        <Input
          className={styles.input}
          value={rgb.g}
          type='text'
          onBlur={() => {
            if (!rgb.g) {
              setRgb({ ...rgb, g: 0 });
              const hex = rgbToHex({ ...rgb, g: 0 });
              if (hex) onColorChange(hex);
            }
          }}
          onChange={(data) => {
            const value = data.target.value;

            const match = value.match(NATURAL_NUMBER_REGEX);
            if (!match && value !== '') {
              return;
            }

            const g = toDecimal(value).gt(RGB_MAX_VALUE)
              ? RGB_MAX_VALUE
              : value;

            setRgb({ ...rgb, g });

            const hex = rgbToHex({ ...rgb, g });
            if (hex) onColorChange(hex);
          }}
        />
        <Input
          className={styles.input}
          value={rgb.b}
          type='text'
          onBlur={() => {
            if (!rgb.b) {
              setRgb({ ...rgb, b: 0 });
              const hex = rgbToHex({ ...rgb, b: 0 });
              if (hex) onColorChange(hex);
            }
          }}
          onChange={(data) => {
            const value = data.target.value;

            const match = value.match(NATURAL_NUMBER_REGEX);
            if (!match && value !== '') {
              return;
            }

            const b = toDecimal(value).gt(RGB_MAX_VALUE)
              ? RGB_MAX_VALUE
              : value;

            setRgb({ ...rgb, b });

            const hex = rgbToHex({ ...rgb, b });
            if (hex) onColorChange(hex);
          }}
        />
      </div>
    </>
  );
};

export const ColorPickerWrapped = CustomPicker(ColorPicker);

const SaturationPointer = () => {
  return (
    <div
      style={{
        transform: 'translate(-7px, -2px)',
        cursor: 'pointer',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: theme.colors.backgroundLight,
        border: `1px solid ${theme.colors.borderMedium}`,
      }}
    />
  );
};

const HuePointer = () => {
  return (
    <div
      style={{
        marginTop: '-5px',
        marginLeft: '-8px',
        cursor: 'pointer',
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        backgroundColor: theme.colors.backgroundLight,
        border: `1px solid ${theme.colors.borderMedium}`,
      }}
    />
  );
};
