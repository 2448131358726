import { useState } from 'react';
import styles from './APRProducts.module.scss';
import { InterestRate, InterestRateKind } from '../types';
import { LoaderBox } from 'common/components/loader';
import { APRBox } from './APRBox';
import { ShowMoreLess } from './ShowMoreLess';
import { useNavigate } from 'react-router-dom';
import { useSortedInterestRates } from '../hooks';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';
import { getHighestBalanceWallet } from '../utils';

type Props = {
  kind: InterestRateKind;
};

const AMOUNT_VISIBLE_BOXES = 4;

const getOnlyBestRates = (arr?: InterestRate[]) => {
  if (arr) {
    const tmp = [] as InterestRate[];
    arr.forEach((item, i) => {
      const justAdded = tmp.find(
        (val) => val.currency === item.currency && val.network === item.network
      );
      if (!justAdded) {
        const apr = arr.filter(
          (rate) =>
            rate.currency === item.currency && rate.network === item.network
        );
        tmp.push(apr[0]);
      }
    });
    return tmp;
  }
  return [];
};

export const APRProducts: React.FC<Props> = ({ kind }) => {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const isBorrow = kind === 'BORROW';

  const { data: currencies, isLoading: isCurrenciesLoading } =
    useGetCurrenciesWithWallets();

  const { data, isLoading } = useSortedInterestRates({
    sortDirection: isBorrow ? 'asc' : 'desc',
    kind: kind,
  });

  const aprs = getOnlyBestRates(data);

  const onClick = (product: InterestRate) => {
    const currency = currencies?.find(
      (item) =>
        item.currency === product?.currency && item.network === product.network
    );

    return navigate(
      kind === 'LEND'
        ? '/lends/new-lend'
        : kind === 'BORROW'
        ? '/borrows/new-borrow'
        : kind === 'SAVING'
        ? '/savings-accounts/new-saving'
        : '',
      {
        state: {
          currency: product.currency,
          network: product.network,
          term: product.term,
          walletId: getHighestBalanceWallet(currency?.wallets)?.id,
        },
      }
    );
  };

  return (
    <div className={styles.container}>
      {isLoading || isCurrenciesLoading ? (
        <LoaderBox />
      ) : (
        <>
          {aprs && (
            <>
              <div className={styles.aprs}>
                {aprs.slice(0, AMOUNT_VISIBLE_BOXES).map((product, i) => (
                  <APRBox
                    currency={product.currency}
                    apr={product.rate_pct}
                    onClick={() => onClick(product)}
                    network={product.network}
                    kind={kind}
                    key={`${product.currency}-${product.network}-${product.term}`}
                  />
                ))}
              </div>
              <div className={styles.moreAprs}>
                {showMore &&
                  aprs
                    .slice(AMOUNT_VISIBLE_BOXES, aprs.length)
                    .map((product, i) => (
                      <APRBox
                        currency={product.currency}
                        apr={product.rate_pct}
                        onClick={() => onClick(product)}
                        network={product.network}
                        kind={kind}
                        isMainBox={false}
                        key={`${product.currency}-${product.network}-${product.term}`}
                      />
                    ))}
              </div>
              {aprs.length > 4 && (
                <div className={styles.showMoreLess}>
                  <ShowMoreLess
                    isMore={showMore}
                    onClick={() => setShowMore(!showMore)}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
