import { useQueryClient } from '@tanstack/react-query';
import { LoaderBox } from 'common/components/loader';
import { PAGE_SIZE } from 'common/consts/consts';
import { useInfiniteScrolling } from 'common/hooks';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { useEffect, useState } from 'react';
import {
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
  useTransactionsInfiniteQuery,
} from '../api';
import { useActiveTransactionsDataQuery } from '../hook';
import { useInfiniteTransactionsContext } from '../filters/context';
import { TransactionBox } from './TransactionBox';
import { TransactionsTableHeader } from './TransactionsTableHeader';

type Props = {};

export const TransactionsTable: React.FC<Props> = () => {
  const queryClient = useQueryClient();

  const [initialActiveTransactions, setInitialActiveTransactions] =
    useState<number>();

  const {
    refetchTransactionsFlag,
    setRefetchTransactionsFlag,
    filters,
    page,
    setPage,
  } = useInfiniteTransactionsContext();

  const { data: activeTransactions } = useActiveTransactionsDataQuery();

  const {
    isLoading,
    data,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    remove,
    error,
  } = useTransactionsInfiniteQuery(filters);

  useEffect(() => {
    if (!refetchTransactionsFlag) {
      return;
    }
    setRefetchTransactionsFlag(false);
    if (isRefetching) {
      return;
    }
    clearAllData();
  }, [refetchTransactionsFlag]);

  const total = data?.pages[0].count;

  useEffect(() => {
    if (initialActiveTransactions === undefined) {
      setInitialActiveTransactions(activeTransactions);
      return;
    }
  }, [activeTransactions]);

  const dataLength = data?.pages
    ? data.pages
        .map((page) => page.results.length)
        .reduce((num, current) => num + current, 0)
    : 0;

  const { lastItemRef } = useInfiniteScrolling(() => {
    if (
      total &&
      data?.pages &&
      dataLength < total &&
      !isRefetching &&
      !isFetchingNextPage &&
      !error
    ) {
      setPage(page + 1);
      fetchNextPage({ pageParam: page + 1 });
    }
  });

  const clearAllData = () => {
    setPage(1);
    remove();
  };

  const onAction = () => {
    queryClient.invalidateQueries(TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY);
  };

  return (
    <div>
      <TransactionsTableHeader />
      {data?.pages &&
        data?.pages.map((page, page_idx) =>
          page.results.map((item, idx) => (
            <div
              key={item.id}
              data-test={`row-${page_idx * PAGE_SIZE + idx}`}
              ref={
                (page_idx + 1) * (idx + 1) === dataLength
                  ? lastItemRef
                  : undefined
              }
            >
              <TransactionBox transaction={item} onAction={onAction} />
            </div>
          ))
        )}
      {(isLoading || isFetchingNextPage) && <LoaderBox />}
      {!isLoading && data?.pages && data?.pages[0].results.length === 0 && (
        <NothingToDisplay />
      )}
    </div>
  );
};
