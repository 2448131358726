import { ReactComponent as Info } from 'common/assets/icons/info.svg';
import styles from './InfoTooltip.module.scss';
import { Tooltip } from './Tooltip';
import clsx from 'clsx';

type Props = {
  text?: string;
  className?: string;
};

export const InfoTooltip: React.FC<Props> = ({ text, className }) => {
  if (!text) {
    return null;
  }
  return (
    <Tooltip title={text} tooltipClassName={clsx(styles.tooltip, className)}>
      <Info style={{ cursor: 'pointer' }} />
    </Tooltip>
  );
};
