import { formatDate } from 'common/utils/utils';
import { addDays } from 'date-fns';
import { CurrencyEnum } from 'common/types';
import { SavingsLimits } from './api';

export const getInterestAccrualDate = () =>
  formatDate(addDays(new Date(), 1).toISOString());

export const isSavingPossibleForCurrency = (
  currency?: CurrencyEnum,
  savingLimits?: SavingsLimits[]
) =>
  !savingLimits?.some(
    (saving) =>
      saving.currency === currency &&
      (saving.global_limit_exceeded || saving.number_of_saving_limit_exceeded)
  );

export const isGlobalLimitExceededForCurrency = (
  currency?: CurrencyEnum,
  savingLimits?: SavingsLimits[]
) =>
  savingLimits?.some(
    (saving) => saving.currency === currency && saving.global_limit_exceeded
  );
