import { useFormatMessage } from 'modules/messages';
import styles from './TopupBorrowState.module.scss';
import { BorrowReadStateEnum } from '../../types';
import { Text } from 'common/components/Text';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import {
  BorrowStatePill,
  stateToMessageKey,
} from '../../borrow-list/components/BorrowStatePill';

type Props = {
  newState?: BorrowReadStateEnum;
  currentState?: BorrowReadStateEnum;
  isLoading: boolean;
};

export const TopupBorrowState: React.FC<Props> = ({
  newState,
  currentState,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <div className={styles.row}>
        <Text style='light1421'>{formatMessage('borrow.newBorrowState')}</Text>
        &nbsp;
        <Text style='light1421'>
          {isLoading ? (
            <RotatingLoader size={12} type={'secondary'} />
          ) : newState ? (
            <BorrowStatePill state={newState} />
          ) : (
            '-'
          )}
        </Text>
      </div>
      <div className={styles.row}>
        <Text style='light1421' colorStyle='secondary'>
          {formatMessage('borrow.currentBorrowState')}
        </Text>
        <Text style='light1421' colorStyle='secondary'>
          {currentState ? formatMessage(stateToMessageKey(currentState)) : '-'}
        </Text>
      </div>
    </div>
  );
};
