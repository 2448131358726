import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'common/components/button';
import { Modal } from 'common/components/modal';
import { NetworkEnum } from 'common/types';
import { prettifyError } from 'common/utils/prettify-error';
import { KyxTooltip, useIsKyxCompleted } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { useCallback, useEffect, useState } from 'react';
import {
  CreateWalletResponseError,
  ERR_WALLET_EXISTS_IN_NETWORK,
  useCreateWalletMutation,
  WALLETS_QUERY_KEY,
} from './api';
import { StepNetwork } from './components/StepNetwork';
import { StepReview } from './components/StepReview';
import { StepSuccess } from './components/StepSuccess';
import { StepWalletName } from './components/StepWalletName';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Props = {};

type CreateWalletStep =
  | 'name'
  | 'network'
  | 'multiapproval'
  | 'review'
  | 'success'
  | '2FA'
  | '2FAcancel';

export const CreateWallet: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const isKyxCompleted = useIsKyxCompleted();

  const [isOpen, setIsOpen] = useState(false);

  const [step, setStep] = useState<CreateWalletStep>('name');

  const [name, setName] = useState('');
  const [network, setNetwork] = useState<NetworkEnum>();
  const [isMultiapproval, setIsMultiapproval] = useState(false);
  const [error2FA, setError2FA] = useState<string>();

  useEffect(() => {
    setStep('name');
    setName('');
    setNetwork(undefined);
    setIsMultiapproval(false);
    mutation.reset();
  }, [isOpen]);

  const mutation = useCreateWalletMutation();

  const isWalletExistsInNetworkError = (
    error: AxiosError<CreateWalletResponseError>
  ) => {
    return (
      typeof error.response?.data === 'object' &&
      error.response.data.non_field_errors?.includes(
        ERR_WALLET_EXISTS_IN_NETWORK
      )
    );
  };

  const getErrorText = (error: AxiosError<CreateWalletResponseError>) => {
    if (isWalletExistsInNetworkError(error)) {
      return formatMessage('wallets.error.nameNotUnique');
    }
    return prettifyError(error);
  };

  const queryClient = useQueryClient();

  const onVerify = (keys: string) => {
    mutation.mutate(
      {
        payload: {
          name: name.trim(),
          network: network!,
          is_multi_approval: isMultiapproval,
        },
        headers: generateHeader2FA(keys),
      },
      {
        onSuccess: () => {
          setStep('success');
          // Refresh wallets to include wallets with PENDING state. This is needed, because there is no websocket event with pending wallet.
          queryClient.invalidateQueries(WALLETS_QUERY_KEY);
        },
        onError: (err) => {
          setErrorOTP(err, setError2FA, formatMessage);
        },
      }
    );
  };

  const onSubmit = () => {
    mutation.mutate(
      {
        payload: {
          name: name.trim(),
          network: network!,
          is_multi_approval: isMultiapproval,
        },
      },
      {
        onSuccess: () => {
          setStep('2FA');
        },
      }
    );
  };

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (!isKyxCompleted) {
      closeModal();
    }
  }, [isKyxCompleted, closeModal]);

  return (
    <>
      <KyxTooltip>
        <span>
          <Button
            type='dark'
            name={formatMessage('wallets.createWallet')}
            onClick={() => setIsOpen(true)}
            disabled={!isKyxCompleted}
          />
        </span>
      </KyxTooltip>
      <Modal isOpen={isOpen} onClose={closeModal} dataTest='createWalletModal'>
        {step === 'name' && (
          <StepWalletName
            walletName={name}
            onWalletNameChange={(newName) => {
              setName(newName);
              mutation.reset();
            }}
            onNext={() => setStep('network')}
            onCancel={closeModal}
          />
        )}
        {step === 'network' && (
          <StepNetwork
            network={network}
            onNetworkChange={(newNetwork) => {
              setNetwork(newNetwork);
              mutation.reset();
            }}
            onGoBack={() => setStep('name')}
            onNext={() => {
              setStep('review');
            }}
          />
        )}
        {step === 'review' && (
          <StepReview
            network={network!}
            walletName={name.trim()}
            onGoBack={() => setStep('network')}
            onSubmit={onSubmit}
            isLoading={mutation.isLoading}
            error={mutation.error ? getErrorText(mutation.error) : undefined}
          />
        )}
        {step === '2FA' && (
          <Module2FA
            onVerify={onVerify}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={mutation.isLoading}
            onCancel={() => setStep('2FAcancel')}
            title={formatMessage('wallets.createWallet')}
          />
        )}
        {step === 'success' && <StepSuccess />}
        {step === '2FAcancel' && <Cancel2FA onClose={closeModal} />}
      </Modal>
    </>
  );
};
