import { ReactComponent as Logomark } from 'common/assets/logo/montesauri-logomark.svg';
import { useNavigate } from 'react-router-dom';

export const MontesauriLogomark: React.FC<{ className?: string }> = ({
  className,
}) => {
  const navigate = useNavigate();

  return (
    <Logomark
      style={{ cursor: 'pointer' }}
      onClick={() => navigate('/login')}
      className={className}
    />
  );
};
