import { Alert } from 'common/components/Alert';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { LoaderBox } from 'common/components/loader';
import { Modal, ModalBody, ModalHeader } from 'common/components/modal';
import { Text } from 'common/components/Text';
import { prettifyError } from 'common/utils/prettify-error';
import { useWhitelistedAddressQuery } from 'modules/address-whitelist/api';
import { WhitelistedAddress } from 'modules/address-whitelist/types';
import { useFormatMessage } from 'modules/messages';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { Currency } from 'modules/select-currency-wallet/types';
import styles from './SelectWhitelistedAddress.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currency: Currency | undefined;
  onSelect: (address: WhitelistedAddress) => void;
};

export const SelectWhitelistedAddress: React.FC<Props> = ({
  isOpen,
  onClose,
  currency,
  onSelect,
}) => {
  const formatMessage = useFormatMessage();

  const { data, isLoading, error } = useWhitelistedAddressQuery();

  if (!currency) {
    return null;
  }

  const addresses = data?.data.filter(
    (address) => address.network === currency.network
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} smallModal={true}>
      <ModalHeader>
        {formatMessage('withdraw.selectWhitelistedAddress')}
      </ModalHeader>
      <ModalBody>
        {isLoading && <LoaderBox />}
        {error && <Alert severity='error' text={prettifyError(error)} />}
        {addresses && (
          <>
            <div className={styles.header}>
              <CurrencyBox
                network={currency.network}
                currency={currency.currency}
              />
              <Text style='light1215'>
                {formatMessage('withdraw.addressCount', {
                  count: addresses.length,
                })}
              </Text>
            </div>
            {addresses.map((address) => (
              <div
                className={styles.address}
                key={address.id}
                onClick={() => {
                  onSelect(address);
                  onClose();
                }}
                data-test='whitelistedAddress'
              >
                <Text style='bold1622' dataTest='addressName'>
                  {address.name}
                </Text>
                <Text style='light1215' dataTest='address'>
                  {address.address}
                </Text>
              </div>
            ))}
            {addresses.length === 0 && (
              <NothingToDisplay
                text={formatMessage('withdraw.noWhitelistedAddresses')}
              />
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
