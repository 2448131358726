import { Text, TextStyle } from 'common/components/Text';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { toDecimal } from 'modules/input-amount';

type Props = {
  rate?: string;
  isLoading?: boolean;
  textStyle?: TextStyle;
  text: string;
};

export const AverageApr: React.FC<Props> = ({
  isLoading,
  textStyle = 'xxbold3048',
  text,
  rate,
}) => {
  return (
    <div>
      <Text style='light1215'>{text}</Text>
      <div style={{ display: 'flex', gap: '10px' }}>
        {isLoading ? (
          <RotatingLoader size={30} containerSize={48} type='secondary' />
        ) : (
          <Text style={textStyle}>
            {toDecimal(rate).equals(0) ? '-' : `${parseFloat(rate || '0')}%`}
          </Text>
        )}
      </div>
    </div>
  );
};
