import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';

export type Props = {
  onClick: () => void;
};

export const MarkAllAsRead: React.FC<Props> = ({ onClick }) => {
  const formatMessage = useFormatMessage();

  return (
    <Button
      type='secondarySmall'
      name={formatMessage('common.button.markAllAsRead')}
      onClick={onClick}
    />
  );
};
