import clsx from 'clsx';
import { SortValue } from '../HeaderCell';
import { Sorter } from './Sorter';
import styles from './PopupSorter.module.scss';
import { SortText } from './SortText';
import { useCallback, useEffect, useState } from 'react';

export type SortConfig = {
  onSort?: (next: SortValue) => void;
  sort?: SortValue;
  text: string;
  className?: string;
};

type Props = {
  config?: SortConfig[];
  className?: string;
  isOnLeft?: boolean;
};

export const PopupSorter: React.FC<Props> = ({
  config,
  className,
  isOnLeft = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.document.body.addEventListener('click', close);
      }, 0);
    } else {
      window.document.body.removeEventListener('click', close);
    }
    return () => {
      window.document.body.removeEventListener('click', close);
    };
  }, [isOpen]);

  return (
    <div className={className}>
      <Sorter
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <div className={styles.container}>
        {isOpen && (
          <div
            className={clsx(
              styles.menuContainer,
              isOnLeft ? styles.positionLeft : styles.positionRight
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {config?.map((item: SortConfig, index) => (
              <SortText
                sort={item.sort}
                onSort={item.onSort}
                key={index}
                className={styles.sortItems}
              >
                {item.text}
              </SortText>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
