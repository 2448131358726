import styles from './Search.module.scss';
import { ReactComponent as LoupeIcon } from './assets/loupe.svg';
import { useState } from 'react';
import { textStyles } from '../Text';
import clsx from 'clsx';

type Props = {
  onFilter: (text: string) => void;
  dataTest?: string;
};

export const Search: React.FC<Props> = ({ onFilter, dataTest }) => {
  const [value, setValue] = useState('');

  return (
    <div className={styles.container} data-test={dataTest}>
      <LoupeIcon />
      <input
        value={value}
        type='text'
        onChange={(e) => {
          const data = e.target.value;
          setValue(data);
          onFilter(data);
        }}
        placeholder='Search'
        className={clsx(textStyles.light1622, styles.input)}
        id='search-input'
        autoFocus={true}
      />
    </div>
  );
};
