import { createContext, useContext, useState } from 'react';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';
import { SetState } from 'common/types';
import {
  BaseCurrency,
  Currency,
  CurrencyWallet,
} from 'modules/select-currency-wallet/types';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';
import { CharityFormDetailed } from '../types';

type CharityFormWithdrawContextValue = {
  amount: string;
  amountInfo: string;
  amountError: string;
  charityForm: CharityFormDetailed | undefined;
  currency: Currency | undefined;
  wallet: CurrencyWallet | undefined;
  walletError: string;
  errorAlert: string;
  remainingFunds: string | undefined;
  currentFunds: string | undefined;
  setAmount: SetState<string>;
  setAmountInfo: SetState<string>;
  setAmountError: SetState<string>;
  setErrorAlert: SetState<string>;
  setWalletError: SetState<string>;
  setWalletId: SetState<number | undefined>;
  setRemainingFunds: SetState<string | undefined>;
  setCurrencyKey: SetState<BaseCurrency | undefined>;
  reset: () => void;
};

const CharityFormWithdrawContext =
  createContext<CharityFormWithdrawContextValue>({
    amount: INITIAL_AMOUNT,
    amountInfo: '',
    amountError: '',
    currency: undefined,
    charityForm: undefined,
    wallet: undefined,
    walletError: '',
    errorAlert: '',
    remainingFunds: undefined,
    currentFunds: undefined,
    setAmount: () => {},
    setAmountInfo: () => {},
    setAmountError: () => {},
    setWalletError: () => {},
    setWalletId: () => {},
    setErrorAlert: () => {},
    setRemainingFunds: () => {},
    setCurrencyKey: () => {},
    reset: () => {},
  });

export const CharityFormWithdrawContextProvider: React.FC<{
  children: React.ReactNode;
  charityForm: CharityFormDetailed;
}> = ({ children, charityForm }) => {
  const [amount, setAmount] = useState(INITIAL_AMOUNT);

  const [walletId, setWalletId] = useState<number>();

  const [currencyKey, setCurrencyKey] = useState<BaseCurrency>();

  const { data: currencies } = useGetCurrenciesWithWallets();

  const currency = currencies?.find(
    (item) =>
      item.currency === currencyKey?.currency &&
      item.network === currencyKey.network
  );

  const wallet = currency?.wallets?.find((item) => item.id === walletId);
  const currentFunds = currency?.currency
    ? charityForm.current_balance_by_currency[currency?.currency]
    : undefined;

  const [amountInfo, setAmountInfo] = useState('');
  const [amountError, setAmountError] = useState('');
  const [walletError, setWalletError] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const [remainingFunds, setRemainingFunds] = useState<string>();

  const reset = () => {
    setAmountInfo('');
    setAmountError('');
    setWalletError('');
    setErrorAlert('');
    setRemainingFunds(undefined);
    setCurrencyKey(undefined);
    setWalletId(undefined);
    setAmount(INITIAL_AMOUNT);
  };

  return (
    <CharityFormWithdrawContext.Provider
      value={{
        amount,
        amountInfo,
        amountError,
        currency,
        charityForm,
        wallet,
        walletError,
        errorAlert,
        remainingFunds,
        currentFunds,
        setCurrencyKey,
        setAmount,
        setAmountInfo,
        setAmountError,
        setWalletError,
        setWalletId,
        setErrorAlert,
        setRemainingFunds,
        reset,
      }}
    >
      {children}
    </CharityFormWithdrawContext.Provider>
  );
};

export const useCharityFormWithdrawContext = () => {
  return useContext(CharityFormWithdrawContext);
};
