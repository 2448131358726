import clsx from 'clsx';
import { ReactComponent as ArrowDownIcon } from 'common/assets/black-icons/arrowhead-down-12px-withpadding.svg';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { CurrencyEnum, NetworkEnum } from 'common/types';
import styles from './SelectCurrencyWalletsButton.module.scss';
import { SelectCurrencyModal } from './components/SelectCurrencyModal';
import { useSelectCurrencyWalletContext } from './context';

type Props = {
  currencyName?: CurrencyEnum;
  networkName?: NetworkEnum;
  dataTest?: string;
  className?: string;
};

export const SelectCurrencyWalletsButton: React.FC<Props> = ({
  currencyName,
  networkName,
  className,
}) => {
  const { isOpenModal, setIsOpenModal } = useSelectCurrencyWalletContext();

  return (
    <div
      className={clsx(styles.container, className)}
      onClick={() => setIsOpenModal(true)}
    >
      <CurrencyBox
        currency={currencyName}
        network={networkName}
        isPlaceholder={!currencyName}
        dataTest='currencyBtn'
      />
      <ArrowDownIcon />
      <SelectCurrencyModal
        onClose={() => setIsOpenModal(false)}
        open={isOpenModal}
      />
    </div>
  );
};
