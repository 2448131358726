import { Pill } from 'common/components/Pill';
import { MessageKey, useFormatMessage } from 'modules/messages';
import { BorrowReadStateEnum } from '../../types';
import styles from './BorrowStatePill.module.scss';

type Props = {
  state: BorrowReadStateEnum;
};

export const stateToStyle = (state: BorrowReadStateEnum): string => {
  switch (state) {
    case 'CREATED':
    case 'ACTIVE':
      return styles.stateHealthy;
    case 'NEAR_MARGIN_CALL':
      return styles.stateNearMarginCall;
    case 'MARGIN_CALL':
      return styles.stateMarginCall;
    case 'LIQUIDATING':
      return styles.stateLiquidation;
    case 'LIQUIDATED':
      return styles.stateLiquidated;
    case 'CANCELLED':
      return styles.stateCancelled;
    case 'COMPLETED':
      return styles.stateCompleted;
  }
};

export const stateToMessageKey = (state: BorrowReadStateEnum): MessageKey => {
  switch (state) {
    case 'CREATED':
    case 'ACTIVE':
      return 'borrow.healthy';
    case 'NEAR_MARGIN_CALL':
      return 'borrow.nearMarginCall';
    case 'MARGIN_CALL':
      return 'borrow.marginCall';
    case 'LIQUIDATING':
      return 'borrow.liquidation';
    case 'LIQUIDATED':
      return 'borrow.liquidated';
    case 'CANCELLED':
      return 'borrow.cancelled';
    case 'COMPLETED':
      return 'borrow.finished';
  }
};

export const BorrowStatePill: React.FC<Props> = ({ state }) => {
  const formatMessage = useFormatMessage();

  return (
    <Pill
      className={stateToStyle(state)}
      text={formatMessage(stateToMessageKey(state))}
      dataTest='borrowState'
    />
  );
};
