import { AxiosError, AxiosResponse } from 'axios';
import { post } from 'modules/api';
import { useMutation } from '@tanstack/react-query';

export const ERR_LIMIT_SENDING_EMAIL =
  'Password resetting link was already sent. You can request new link in';

export type ResetPasswordResponseError = {
  email?: string[];
  detail?: string;
};

export type ResetPasswordPayload = {
  email: string;
};

export const resetPassword = (payload: ResetPasswordPayload) =>
  post<void, ResetPasswordPayload>('/reset-password-request', payload);

export const useResetPasswordMutation = () => {
  const mutation = useMutation<
    AxiosResponse<void>,
    AxiosError<ResetPasswordResponseError>,
    ResetPasswordPayload
  >(resetPassword);
  return mutation;
};

export type SetNewPasswordResponseError = {
  new_password: string[];
  uid: string[];
  token: string[];
  non_field_errors?: string[];
};

export type SetNewPasswordPayload = {
  new_password: string;
  uid: string;
  token: string;
};

export const setPassword = (payload: SetNewPasswordPayload) =>
  post<void, SetNewPasswordPayload>('/reset-password-confirm', payload);

export const useSetNewPasswordMutation = () => {
  const mutation = useMutation<
    AxiosResponse<void>,
    AxiosError<SetNewPasswordResponseError>,
    SetNewPasswordPayload
  >(setPassword);
  return mutation;
};
