import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { Member } from 'modules/company-members/api';
import { ModalBody, ModalHeader } from 'common/components/modal';
import { ReactComponent as HourglassIcon } from 'common/assets/icons/hourglass.svg';
import styles from './StepSuccess.module.scss';
import { useLogoutMutation } from 'modules/user';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { TIME_TO_REDIRECT } from 'common/consts/consts';

type Props = { onClose: () => void; newOwner?: Member };

export const StepSuccess: React.FC<Props> = ({ onClose, newOwner }) => {
  const formatMessage = useFormatMessage();
  const { mutate } = useLogoutMutation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  //logout
  setTimeout(() => {
    mutate(undefined, {
      onSuccess: () => {
        queryClient.removeQueries();
        navigate('/login');
      },
    });
  }, TIME_TO_REDIRECT);

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('settings.changeCompanyOwner')}
      </ModalHeader>
      <ModalBody>
        {newOwner && (
          <div>
            <div>
              <Text style='xbold1417'>
                {newOwner.name} {newOwner.surname}
              </Text>
              <Text style='light1417'>
                {formatMessage('settings.isNowThe')}
              </Text>
              <Text style='xbold1417'>{formatMessage('settings.owner')}</Text>
              <Text style='light1417'>
                {formatMessage('settings.ofACompany')}
              </Text>
              <Text style='light1417'>
                {formatMessage('settings.yourRoleChangeToMember')}
              </Text>
              <Text style='xbold1417'>{formatMessage('settings.member')}</Text>
              <Text style='light1417'>{'.'}</Text>
            </div>
            <div className={styles.marginTop}>
              <Text style='xbold1417'>
                {formatMessage('settings.youWillBeLoggedOut')}
              </Text>
            </div>
            <div className={styles.icon}>
              <HourglassIcon />
            </div>
          </div>
        )}
      </ModalBody>
    </>
  );
};
