import { useFormatMessage } from 'modules/messages';
import { TransactionKind } from 'modules/transactions/types';

export const useGetTransactionLabel = () => {
  const formatMessage = useFormatMessage();

  const getTransactionLabel = (transaction_kind: TransactionKind) => {
    switch (transaction_kind) {
      case 'DEPOSIT':
        return formatMessage('balanceChart.transactionLabel.deposit');
      case 'WITHDRAWAL':
        return formatMessage('balanceChart.transactionLabel.withdrawal');
      case 'LOAN_LEND':
        return formatMessage('balanceChart.transactionLabel.lend');
      case 'LOAN_LEND_TERMINATION':
        return formatMessage('balanceChart.transactionLabel.lendTermination');
      case 'LOAN_INTEREST':
        return formatMessage(
          'balanceChart.transactionLabel.lendInterestPayout'
        );
      case 'LOAN_PRINCIPAL_RETURN':
        return formatMessage('balanceChart.transactionLabel.principalReturn');
      case 'LOAN_BORROW':
        return formatMessage('balanceChart.transactionLabel.borrow');
      case 'LOAN_BORROW_REPAY':
        return formatMessage('balanceChart.transactionLabel.borrowRepayment');
      case 'LOAN_BORROW_LIQUIDATION':
        return formatMessage('balanceChart.transactionLabel.borrowLiquidation');
      case 'LOAN_BORROW_CANCELLATION':
        return formatMessage(
          'balanceChart.transactionLabel.borrowCancellation'
        );
      case 'LOAN_COLLATERAL_TOPUP_AUTO':
        return formatMessage(
          'balanceChart.transactionLabel.marginCallTransfer'
        );
      case 'LOAN_COLLATERAL_TOPUP':
        return formatMessage('balanceChart.transactionLabel.collateralTopup');
      case 'LOAN_COLLATERAL_EXCESS_RETURN':
        return formatMessage(
          'balanceChart.transactionLabel.overcollateralizationTransfer'
        );
      case 'LOAN_COLLATERAL_FULL_RETURN':
        return formatMessage('balanceChart.transactionLabel.collateralReturn');
      case 'LOAN_PRINCIPAL_PAYOUT':
        return formatMessage('balanceChart.transactionLabel.borrowPrincipal');
      case 'SAVING': {
        return formatMessage('balanceChart.transactionLabel.saving');
      }
      case 'TRADE_OUT':
        return formatMessage('balanceChart.transactionLabel.trade');
      case 'TRADE_IN':
        return formatMessage(
          'balanceChart.transactionLabel.tradeIncomingFunds'
        );
      case 'TRADE_EXCESS_RETURN':
        return formatMessage('balanceChart.transactionLabel.tradeExcessReturn');
      case 'SAVING_TOPUP':
        return formatMessage('balanceChart.transactionLabel.savingTopUp');
      case 'SAVING_WITHDRAWAL':
        return formatMessage('balanceChart.transactionLabel.savingWithdrawal');
      case 'CHARITY_DONATION':
        return formatMessage('balanceChart.transactionLabel.charityDonation');
      case 'CHARITY_WITHDRAWAL_IN':
        return formatMessage(
          'balanceChart.transactionLabel.charityFormWithdrawal'
        );
    }
  };

  return getTransactionLabel;
};
