import { HelpCenterButton } from 'modules/settings/components/HelpCenterButton';
import { SettingsButton } from 'modules/settings/components/SettingsButton';
import { LogoutButton } from './LogoutButton';
import { UserInfo } from './UserInfo';
import styles from './UserMenuContent.module.scss';

export const UserMenuContent: React.FC<{}> = () => {
  return (
    <div className={styles.container}>
      <UserInfo />
      <SettingsButton />
      <HelpCenterButton />
      <LogoutButton />
    </div>
  );
};
