import { Alert } from 'common/components/Alert';
import { Button, BackButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import styles from './StepSummary.module.scss';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  AssetAmountReadOnly,
  SnapshotAlert,
  TokenExpiryTimer,
  WalletBox,
  tokenInvalidValue,
  tokenIsRequired,
} from 'modules/financial-ops/common';
import { getInterestAccrualDate } from '../../helpers';
import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';
import { Text } from 'common/components/Text';
import { SectionSubheader } from 'common/components/SectionSubheader';
import {
  SAVINGS_LIMITS_QUERY_KEY,
  TopupEffectPayload,
  TopupEffectResponseError,
  TopupPayload,
  TopupResponseError,
  amountExceedsMaxToAllocate,
  globalLimitReached,
  noFundsInWallet,
  noPossibilityToAllocateMoreFunds,
  notEnoughFunds,
  savingHasTopupTransactionInPendingState,
  savingHasWithdrawalTransactionAgainstFullBalance,
  savingHasWithdrawalTransactionInPendingState,
  savingIsNotActive,
  useTopupEffectMutation,
  useTopupMutation,
} from '../../api';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { prettifyError } from 'common/utils/prettify-error';
import { useState } from 'react';

type Props = {
  amount: string;
  onGoBack: () => void;
  currency?: Currency;
  wallet?: CurrencyWallet;
  initialNewLockedAmount?: string;
  initialNewDailyEarn?: string;
  id: number;
  initialToken: string | undefined;
  onGoNext: (transactionId: number) => void;
};

export const StepSummary: React.FC<Props> = ({
  amount,
  onGoBack,
  currency,
  wallet,
  initialNewDailyEarn,
  initialNewLockedAmount,
  onGoNext,
  id,
  initialToken,
}) => {
  const formatMessage = useFormatMessage();
  const topupEffectMutation = useTopupEffectMutation(id);
  const topupMutation = useTopupMutation(id);

  const queryClient = useQueryClient();
  const [timeExpired, setTimeExpired] = useState(false);

  const getErrorText = (error: AxiosError<TopupResponseError>) => {
    if (noPossibilityToAllocateMoreFunds(error.response?.data)) {
      return formatMessage('saving.limitOfDepositedFundsReached');
    }

    const amountExceedsMaxToAllocateMatch = amountExceedsMaxToAllocate(
      error.response?.data
    );
    if (amountExceedsMaxToAllocateMatch) {
      return formatMessage('saving.topUpAmountMustBeLessOrEqual', {
        amount: toFixed(amountExceedsMaxToAllocateMatch[1]),
        currency: currency?.currency,
      });
    }

    if (savingIsNotActive(error.response?.data)) {
      return formatMessage('saving.savingHasBeenClosedTopupIsNotAvailable');
    }

    if (notEnoughFunds(error.response?.data)) {
      return formatMessage('common.fundsAreInsufficientToFinishTopUp');
    }

    if (globalLimitReached(error.response?.data)) {
      return formatMessage('saving.maximumGlobalLimitReached');
    }

    if (
      savingHasWithdrawalTransactionAgainstFullBalance(error.response?.data)
    ) {
      return formatMessage('saving.thisSavingsAccountWasClosedByAnotherUser');
    }

    if (savingHasWithdrawalTransactionInPendingState(error.response?.data)) {
      return formatMessage(
        'saving.savingHasWithdrawalTransactionInPendingState'
      );
    }

    if (savingHasTopupTransactionInPendingState(error.response?.data)) {
      return formatMessage('saving.savingHasTopupTransactionInPendingState');
    }

    if (tokenIsRequired(error.response?.data)) {
      return formatMessage('common.tokenIsRequired');
    }

    return prettifyError(error);
  };

  const getRefetchTokenError = (
    error: AxiosError<TopupEffectResponseError>
  ) => {
    if (globalLimitReached(error.response?.data)) {
      return formatMessage('saving.maximumGlobalLimitReached');
    }

    const amountExceedsMaxToAllocateMatch = amountExceedsMaxToAllocate(
      error.response?.data
    );
    if (amountExceedsMaxToAllocateMatch) {
      return formatMessage('saving.topUpAmountMustBeLessOrEqual', {
        amount: toFixed(amountExceedsMaxToAllocateMatch[1]),
        currency: currency?.currency,
      });
    }

    if (noFundsInWallet(error.response?.data)) {
      return formatMessage('saving.noFundsInWallet');
    }

    if (noPossibilityToAllocateMoreFunds(error.response?.data)) {
      return formatMessage('saving.limitOfDepositedFundsReached');
    }

    if (savingHasWithdrawalTransactionInPendingState(error.response?.data)) {
      return formatMessage(
        'saving.savingHasWithdrawalTransactionInPendingState'
      );
    }

    if (savingHasTopupTransactionInPendingState(error.response?.data)) {
      return formatMessage('saving.savingHasTopupTransactionInPendingState');
    }

    if (savingIsNotActive(error.response?.data)) {
      return formatMessage('saving.savingHasBeenClosedTopupIsNotAvailable');
    }

    return prettifyError(error);
  };

  const refetchToken = () => {
    if (!wallet) {
      return;
    }

    const payload: TopupEffectPayload = {
      calculation_base: 'AMOUNT',
      wallet_id: wallet.id,
      amount: toFixed(amount),
      summary: true,
    };

    topupEffectMutation.mutate(payload, {
      onSettled: (res) => {
        queryClient.invalidateQueries(SAVINGS_LIMITS_QUERY_KEY);
      },
    });
  };

  const modalHeader = (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('saving.reviewAndConfirmSavingsAccountTopup')}
          </Text>
        }
      >
        {formatMessage('saving.topUpFundsSummary')}
      </ModalHeader>
    </>
  );

  const isSummaryDataComplete =
    currency &&
    wallet &&
    initialToken &&
    initialNewDailyEarn &&
    initialNewLockedAmount;
  if (!isSummaryDataComplete) {
    return (
      <>
        {modalHeader}
        <ModalBody>
          <Alert
            severity='error'
            text={formatMessage('saving.summaryCannotBeDisplayed')}
          />
        </ModalBody>
      </>
    );
  }

  const token = topupEffectMutation.data?.data.token || initialToken;
  const newDailyEarn =
    topupEffectMutation.data?.data.interest_daily || initialNewDailyEarn;
  const newLockedAmount =
    topupEffectMutation.data?.data.amount_target || initialNewLockedAmount;

  return (
    <>
      {modalHeader}
      <ModalBody>
        {topupMutation.isError &&
          !tokenInvalidValue(topupMutation.error!.response?.data) && (
            <Alert severity='error' text={getErrorText(topupMutation.error)} />
          )}
        {topupEffectMutation.error && (
          <Alert
            severity='error'
            text={getRefetchTokenError(topupEffectMutation.error)}
            className={styles.error}
          />
        )}
        {amount && currency && (
          <>
            <SectionSubheader>
              {formatMessage('common.amount')}
            </SectionSubheader>
            <AssetAmountReadOnly
              amount={amount}
              currency={currency}
              className={styles.amountAssetBox}
            />
          </>
        )}
        {wallet && currency && (
          <WalletBox
            wallet={wallet}
            currency={currency.currency}
            className={styles.wallet}
          />
        )}
        <Summary>
          <SummaryLabel>{formatMessage('saving.newLockedAmount')}</SummaryLabel>
          <SummaryValue>{`${toLimitedPrec(toFixed(newLockedAmount))} ${
            currency?.currency
          }`}</SummaryValue>
          <SummaryLabel>{formatMessage('saving.newDailyEarn')}</SummaryLabel>
          <SummaryValue>{`${toLimitedPrec(toFixed(newDailyEarn))} ${
            currency?.currency
          }`}</SummaryValue>
          <SummaryLabel>
            <LabelWithInfoTooltip
              text={formatMessage('saving.expectedInterestAccrual')}
              textStyle={'light1215'}
              tooltipText={formatMessage(
                'saving.interestsAreCalculatedEvery24HoursTopup'
              )}
            />
          </SummaryLabel>
          <SummaryValue>{getInterestAccrualDate()}</SummaryValue>
        </Summary>
        {(tokenInvalidValue(topupEffectMutation.error?.response?.data) ||
          timeExpired) && (
          <SnapshotAlert
            onRefresh={() => {
              refetchToken();
              setTimeExpired(false);
              topupMutation.reset();
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            type='dark'
            onClick={() => {
              if (!wallet) {
                return;
              }

              const payload: TopupPayload = {
                amount,
                wallet_id: wallet.id,
                token,
              };

              topupMutation.mutate(payload, {
                onSuccess: (res) => {
                  onGoNext(res.data.transaction_id);
                },
              });
            }}
            name={
              <div className={styles.button}>
                <Text style='xbold1417'>{formatMessage('common.topUp')}</Text>
                {!topupEffectMutation.error && !topupMutation.error && (
                  <TokenExpiryTimer
                    token={token}
                    onTimeExpired={() => setTimeExpired(true)}
                  />
                )}
              </div>
            }
            isLoading={topupMutation.isLoading}
            disabled={
              topupMutation.isLoading ||
              timeExpired ||
              !!topupEffectMutation.error ||
              !!topupMutation.error
            }
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
