import { createContext, useContext, useState } from 'react';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';
import { SetState } from 'common/types';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { getHighestBalanceWallet } from 'modules/financial-ops/common';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';
import { Saving } from '../types';

type SavingWithdrawContextValue = {
  amount: string;
  amountInfo: string;
  amountError: string;
  saving: Saving | undefined;
  currency: Currency | undefined;
  wallet: CurrencyWallet | undefined;
  walletError: string;
  errorAlert: string;
  remainingFundsInfo: string;
  disabledInput: boolean;
  setAmount: SetState<string>;
  setAmountInfo: SetState<string>;
  setAmountError: SetState<string>;
  setErrorAlert: SetState<string>;
  setWalletError: SetState<string>;
  setWalletId: SetState<number | undefined>;
  setRemainingFundsInfo: SetState<string>;
  setDisabledInput: SetState<boolean>;
};

const SavingWithdrawContext = createContext<SavingWithdrawContextValue>({
  amount: INITIAL_AMOUNT,
  amountInfo: '',
  amountError: '',
  currency: undefined,
  saving: undefined,
  wallet: undefined,
  walletError: '',
  errorAlert: '',
  remainingFundsInfo: '',
  disabledInput: false,
  setAmount: () => {},
  setAmountInfo: () => {},
  setAmountError: () => {},
  setWalletError: () => {},
  setWalletId: () => {},
  setErrorAlert: () => {},
  setRemainingFundsInfo: () => {},
  setDisabledInput: () => {},
});

export const SavingWithdrawContextProvider: React.FC<{
  children: React.ReactNode;
  saving?: Saving;
}> = ({ children, saving }) => {
  // base state
  const [amount, setAmount] = useState(INITIAL_AMOUNT);

  const [walletId, setWalletId] = useState<number>();

  const { data: currencies } = useGetCurrenciesWithWallets();

  const currency = currencies?.find(
    (curr) => curr.currency === saving?.currency
  );

  const wallet = walletId
    ? currency?.wallets?.find((item) => item.id === walletId)
    : getHighestBalanceWallet(currency?.wallets); // if walletId undefined, use wallet with highest balance

  // error state
  const [amountInfo, setAmountInfo] = useState('');
  const [amountError, setAmountError] = useState('');
  const [walletError, setWalletError] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const [remainingFundsInfo, setRemainingFundsInfo] = useState('');
  const [disabledInput, setDisabledInput] = useState(false);

  return (
    <SavingWithdrawContext.Provider
      value={{
        amount,
        amountInfo,
        amountError,
        currency,
        saving,
        wallet,
        walletError,
        errorAlert,
        remainingFundsInfo,
        disabledInput,
        setAmount,
        setAmountInfo,
        setAmountError,
        setWalletError,
        setWalletId,
        setErrorAlert,
        setRemainingFundsInfo,
        setDisabledInput,
      }}
    >
      {children}
    </SavingWithdrawContext.Provider>
  );
};

export const useSavingWithdrawContext = () => {
  return useContext(SavingWithdrawContext);
};
