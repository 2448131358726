import clsx from 'clsx';
import { textColorStyles, textStyles } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './RecaptchaInfotext.module.scss';

type Props = {
  className?: string;
};

const privacyPolicyUrl = 'https://policies.google.com/privacy';
const termsServiceUrl = 'https://policies.google.com/terms';

export const RecaptchaInfotext: React.FC<Props> = ({ className }) => {
  const formatMessage = useFormatMessage();

  return (
    <span
      className={clsx(
        textStyles.regular1215,
        textColorStyles.secondary,
        styles.default,
        className
      )}
      dangerouslySetInnerHTML={{
        __html: formatMessage('recaptcha.thisSiteIsProtectedByRecaptcha', {
          policyHref: privacyPolicyUrl,
          termsHref: termsServiceUrl,
          linkClassName: textColorStyles.secondary,
        }),
      }}
    />
  );
};
