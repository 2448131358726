import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { CurrencyEnum } from 'common/types';
import { get, post } from 'modules/api';
import {
  FeatureFlagDisabledError,
  useFeatureFlagDisabledHandler,
  useFeatureFlag,
} from 'modules/feature-flags';
import { SummaryAsset, TermEnum } from '../common';
import {
  BorrowCalculationBase,
  BorrowRead,
  BorrowDetails,
  BorrowMeasure,
  BorrowMeasuresPeriod,
  BorrowHistoryItem,
  BorrowReadStateEnum,
} from './types';

export type BorrowsResponseData = BorrowRead[];

export type BorrowsResponseError = FeatureFlagDisabledError;

export type BorrowsSummaryResponseData = {
  current_principal_amount_fiat: string;
  current_collateral_amount_fiat: string;
  current_collateral_assets: SummaryAsset[];
  current_avg_rate_pct: string;
};

export type BorrowDetailsResponseError = FeatureFlagDisabledError;

export type BorrowsEffectPayload = {
  term: TermEnum;
  principal_currency: CurrencyEnum;
  principal_amount?: string;
  principal_wallet_id: number;
  collateral_currency: CurrencyEnum;
  collateral_wallet_id: number;
  collateral_amount?: string;
  calculation_base: BorrowCalculationBase;
  summary: boolean;
  auto_topup: boolean;
};

export type PostBorrowPayload = { token: string };

export type PostBorrowResponseData = {
  transaction_id: number;
};

export type PostBorrowError = {
  term?: string[];
  principal_currency?: string[];
  principal_amount?: string[];
  principal_wallet_id?: string[];
  collateral_amount?: string[];
  collateral_currency?: string[];
  collateral_wallet_id?: string[];
  non_field_errors?: string[];
  detail?: string;
  token?: string[];
} & FeatureFlagDisabledError;

export type BorrowsEffectResponseData = {
  term: TermEnum;
  principal_currency: CurrencyEnum;
  principal_amount: string;
  collateral_currency: CurrencyEnum;
  rate_pct: string;
  ltv_pct: string;
  collateral_amount: string;
  interest: string;
  start_at: string;
  end_at: string;
  limit_exceeded: string;
  token: string;
};

export type BorrowsEffectError = {
  principal_amount?: string[];
  collateral_amount?: string[];
} & FeatureFlagDisabledError;

export type BorrowsRepayResponseData = {
  transaction_id: number;
};

export type BorrowTopupPayload = {
  amount: string;
};

export type BorrowTopupResponseData = { transaction_id: number };

export type BorrowTopupResponseError = {
  amount?: string[];
  non_field_errors?: string[];
} & FeatureFlagDisabledError;

export type BorrowTopupEffectPayload = {
  amount: string;
};

export type BorrowTopupEffectResponseData = {
  amount: string;
  collateral_amount_new: string;
  collateral_amount_current: string;
  ltv_pct_new: string;
  ltv_pct_current: string;
  state_new: BorrowReadStateEnum;
  state_current: BorrowReadStateEnum;
};

export type BorrowTopupEffectResponseError = {
  detail?: string;
  wallet_id?: string[];
  amount?: string[];
  non_field_errors?: string[];
} & FeatureFlagDisabledError;

export const BORROWS_URL = '/borrows';
export const BORROWS_SUMMARY_URL = '/borrows/summary';
export const BORROW_URL = '/borrows/{id}';
export const BORROW_HISTORY_URL = '/borrows/{id}/history';
export const BORROW_MEASURES_URL = '/borrows/{id}/measures?period={period}';
export const BORROW_REPAY_URL = '/borrows/{id}/repay';
export const BORROW_EFFECT_URL = '/borrows/effect';
export const BORROW_TOPUP_URL = '/borrows/{id}/topup';
export const BORROW_TOPUP_EFFECT_URL = '/borrows/{id}/topup/effect';

export const BORROWS_QUERY_KEY = ['GET', BORROWS_URL] as const;
export const BORROWS_SUMMARY_QUERY_KEY = ['GET', BORROWS_SUMMARY_URL] as const;

export const BORROW_QUERY_KEY = (id: number) =>
  ['GET', BORROW_URL, id] as const;

export const BORROW_MEASURES_QUERY_KEY = (
  id: number,
  period: BorrowMeasuresPeriod
) => ['GET', BORROW_MEASURES_URL, id, period] as const;

export const BORROW_HISTORY_QUERY_KEY = (id: number) =>
  ['GET', BORROW_HISTORY_URL, id] as const;

export const POST_BORROWS_QUERY_KEY = ['POST', BORROWS_URL] as const;
export const POST_BORROWS_EFFECT_QUERY_KEY = [
  'POST',
  BORROW_EFFECT_URL,
] as const;
export const POST_BORROWS_REPAY_QUERY_KEY = ['POST', BORROW_REPAY_URL] as const;

export const POST_BORROW_TOPUP_QUERY_KEY = (id: number) =>
  ['GET', BORROW_TOPUP_URL, id] as const;

export const POST_BORROW_TOPUP_EFFECT_QUERY_KEY = (id: number) =>
  ['GET', BORROW_TOPUP_EFFECT_URL, id] as const;

const getBorrows = () => get<BorrowsResponseData>(BORROWS_URL);

const getBorrowsSummary = () =>
  get<BorrowsSummaryResponseData>(BORROWS_SUMMARY_URL);

const getBorrowById = (id: number) =>
  get<BorrowDetails>(`${BORROW_URL.replace('{id}', id.toString())}`);

const getBorrowMeasuresById = (id: number, period: BorrowMeasuresPeriod) =>
  get<BorrowMeasure[]>(
    `${BORROW_MEASURES_URL.replace('{id}', id.toString()).replace(
      '{period}',
      period
    )}`
  );

const getBorrowHistoryById = (id: number) =>
  get<BorrowHistoryItem[]>(
    `${BORROW_HISTORY_URL.replace('{id}', id.toString())}`
  );

const postBorrows = (payload: PostBorrowPayload) =>
  post<PostBorrowResponseData, PostBorrowPayload>(BORROWS_URL, payload);

const postBorrowsEffect = (payload: BorrowsEffectPayload) =>
  post<BorrowsEffectResponseData, BorrowsEffectPayload>(
    BORROW_EFFECT_URL,
    payload
  );

const postBorrowRepay = (
  payload: PostBorrowRepayPayload,
  headers?: AxiosRequestHeaders
) =>
  post<BorrowsRepayResponseData, {}>(
    BORROW_REPAY_URL.replace('{id}', `${payload.id}`),
    {},
    {
      headers,
    }
  );

export const postBorrowTopup = (payload: BorrowTopupPayload, id: number) =>
  post<BorrowTopupResponseData, BorrowTopupPayload>(
    BORROW_TOPUP_URL.replace('{id}', `${id}`),
    payload
  );

export const postBorrowTopupEffect = (
  payload: BorrowTopupEffectPayload,
  id: number
) =>
  post<BorrowTopupEffectResponseData, BorrowTopupEffectPayload>(
    BORROW_TOPUP_EFFECT_URL.replace('{id}', `${id}`),
    payload
  );

export const useBorrows = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('BORROW');

  return useQuery<
    AxiosResponse<BorrowsResponseData>,
    AxiosError<BorrowsResponseError>
  >(BORROWS_QUERY_KEY, getBorrows, {
    enabled: !!featureFlag.data?.enabled,
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useBorrowsSummaryQuery = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('BORROW');

  return useQuery<
    AxiosResponse<BorrowsSummaryResponseData>,
    AxiosError<FeatureFlagDisabledError>
  >(BORROWS_SUMMARY_QUERY_KEY, getBorrowsSummary, {
    enabled: !!featureFlag.data?.enabled,
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useBorrowByIdQuery = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('BORROW');

  return useQuery<
    AxiosResponse<BorrowDetails>,
    AxiosError<BorrowDetailsResponseError>
  >(BORROW_QUERY_KEY(id), () => getBorrowById(id), {
    enabled: !!featureFlag.data?.enabled,
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useBorrowMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<PostBorrowResponseData>,
    AxiosError<PostBorrowError>,
    PostBorrowPayload
  >(POST_BORROWS_QUERY_KEY, postBorrows, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export const useBorrowMeasuresByIdQuery = (
  id: number,
  period: BorrowMeasuresPeriod
) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('BORROW');

  return useQuery<AxiosResponse<BorrowMeasure[]>, AxiosError<{}>>(
    BORROW_MEASURES_QUERY_KEY(id, period),
    () => getBorrowMeasuresById(id, period),
    {
      enabled: !!featureFlag.data?.enabled,
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const useBorrowHistoryByIdQuery = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();
  const featureFlag = useFeatureFlag('BORROW');

  return useQuery<AxiosResponse<BorrowHistoryItem[]>, AxiosError<{}>>(
    BORROW_HISTORY_QUERY_KEY(id),
    () => getBorrowHistoryById(id),
    {
      enabled: !!featureFlag.data?.enabled,
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const useBorrowsEffectMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<BorrowsEffectResponseData>,
    AxiosError<BorrowsEffectError>,
    BorrowsEffectPayload
  >(POST_BORROWS_EFFECT_QUERY_KEY, postBorrowsEffect, {
    onError: (error) => {
      handleFeatureFlagDisabled(error);
    },
  });
};

export type PostBorrowRepayPayload = {
  id: number;
};

export type PostBorrowRepayRequest = {
  payload: PostBorrowRepayPayload;
  headers?: AxiosRequestHeaders;
};

export type BorrowRepayError = {
  non_field_errors?: string[];
} & FeatureFlagDisabledError;

export const useBorrowRepayMutation = () => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<BorrowsRepayResponseData>,
    AxiosError<BorrowRepayError>,
    PostBorrowRepayRequest
  >(
    POST_BORROWS_REPAY_QUERY_KEY,
    ({ payload, headers }) => postBorrowRepay(payload, headers),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const useBorrowTopupMutation = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<BorrowTopupResponseData>,
    AxiosError<BorrowTopupResponseError>,
    BorrowTopupPayload
  >(
    POST_BORROW_TOPUP_QUERY_KEY(id),
    (payload) => postBorrowTopup(payload, id),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const useBorrowTopupEffectMutation = (id: number) => {
  const handleFeatureFlagDisabled = useFeatureFlagDisabledHandler();

  return useMutation<
    AxiosResponse<BorrowTopupEffectResponseData>,
    AxiosError<BorrowTopupEffectResponseError>,
    BorrowTopupEffectPayload
  >(
    POST_BORROW_TOPUP_EFFECT_QUERY_KEY(id),
    (payload) => postBorrowTopupEffect(payload, id),
    {
      onError: (error) => {
        handleFeatureFlagDisabled(error);
      },
    }
  );
};

export const collateralMinError = (errResponseData?: BorrowsEffectError) => {
  for (let err of errResponseData?.collateral_amount || []) {
    const match = err.match(COLLATERAL_MIN_ERROR_REGEX);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const collateralMaxError = (errResponseData?: BorrowsEffectError) => {
  for (let err of errResponseData?.collateral_amount || []) {
    const match = err.match(COLLATERAL_MAX_ERROR_REGEX);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const principalMinError = (errResponseData?: BorrowsEffectError) => {
  for (let err of errResponseData?.principal_amount || []) {
    const match = err.match(PRINCIPAL_MIN_ERROR_REGEX);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const principalMaxError = (errResponseData?: BorrowsEffectError) => {
  for (let err of errResponseData?.principal_amount || []) {
    const match = err.match(PRINCIPAL_MAX_ERROR_REGEX);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const principalInsufficientFundsError = (
  errResponseData: BorrowRepayError | undefined
) =>
  (errResponseData?.non_field_errors || []).some(
    (err) => err === PRINCIPAL_INSUFFICIENT_FUNDS
  );

export const transactionAlreadyExists = (
  errResponseData: BorrowRepayError | undefined
) =>
  (errResponseData?.non_field_errors || []).some(
    (err) => err === TRANSACTION_REPAY_ALREADY_EXISTS
  );

export const borrowCannotBeRepaidAtThisTime = (
  errResponseData: BorrowRepayError | undefined
) =>
  (errResponseData?.non_field_errors || []).some(
    (err) => err === BORROW_CANNOT_BE_REPAID_AT_THIS_TIME
  );

const COLLATERAL_MIN_ERROR_REGEX =
  /Collateral amount must be greater or equal to (\d+.\d+)/;

const COLLATERAL_MAX_ERROR_REGEX =
  /Collateral amount must be less or equal to (\d+.\d+)/;

const PRINCIPAL_MIN_ERROR_REGEX =
  /Principal amount must be greater or equal to (\d+.\d+)/;

const PRINCIPAL_MAX_ERROR_REGEX =
  /Principal amount must be less or equal to (\d+.\d+)/;

export const collateralInsufficientFundsError = (
  errResponseData: PostBorrowError
) => {
  for (let err of errResponseData?.collateral_amount || []) {
    const match = err.match(COLLATERAL_INSUFFICIENT_FUNDS);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const interestRateDoesNotExist = (errorResp?: PostBorrowError) => {
  for (let err of errorResp?.non_field_errors || []) {
    const match = err.match(ERR_INTEREST_RATE_DOES_NOT_EXIST);
    if (match) {
      return match;
    }
  }
  return undefined;
};

export const notEnoughFunds = (
  errorResp?: BorrowTopupEffectResponseError | BorrowTopupResponseError
) => {
  return errorResp?.amount?.includes('Not enough funds.');
};

export const borrowHasPendingTopup = (
  errorResp?: BorrowTopupEffectResponseError | BorrowTopupResponseError
) => {
  return errorResp?.non_field_errors?.includes('Borrow has pending topup.');
};

export const borrowIsNotActive = (
  errorResp?: BorrowTopupEffectResponseError | BorrowTopupResponseError
) => {
  return errorResp?.non_field_errors?.includes('Borrow is not active.');
};

const ERR_INTEREST_RATE_DOES_NOT_EXIST =
  /Interest rate for provided term and currency does not exist. Please contact support./;

const COLLATERAL_INSUFFICIENT_FUNDS =
  /Not enough funds in collateral wallet to create borrow. Required collateral amount is (\d+.\d+) (\w+)./;

const PRINCIPAL_INSUFFICIENT_FUNDS =
  'Not enough funds in principal wallet to repay this borrow.';

const TRANSACTION_REPAY_ALREADY_EXISTS =
  'There is already repay transaction created for this borrow.';

const BORROW_CANNOT_BE_REPAID_AT_THIS_TIME =
  'Borrow cannot be repaid at this time.';
