import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './SupportedCurrencies.module.scss';
import {
  AvailableCurrency,
  AvailableNetwork,
} from 'modules/assets-and-wallets/types';
import { SupportedCurrency } from 'modules/assets-and-wallets/components/SupportedCurrencies';

type Props = {
  networks: AvailableNetwork[];
};

export const SupportedCurrencies: React.FC<Props> = ({ networks }) => {
  const formatMessage = useFormatMessage();

  const getCurrenciesList = () => {
    const currenciesList: AvailableCurrency[] = [];

    networks.forEach((network) => {
      network.currencies.forEach((curr) => {
        currenciesList.push(curr);
      });
    });

    return currenciesList;
  };

  const currenciesList = getCurrenciesList();

  return (
    <>
      <Text style='xxbold1619' className={styles.header}>
        {formatMessage('charityForms.networkCurrencies')}
      </Text>
      {currenciesList.map((currency) => (
        <SupportedCurrency key={currency.unit_name} currency={currency} />
      ))}
    </>
  );
};
