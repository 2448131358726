import { Transaction, TransactionDetails } from '../types';
import styles from './TransactionApprovePreview.module.scss';
import { BoldAmount } from 'modules/common-data-fields';
import { IconByType } from './details/components/IconByType';
import { TransactionName } from './TransactionName';

type Props = {
  transaction: Transaction | TransactionDetails;
};

export const TransactionApprovePreview: React.FC<Props> = ({ transaction }) => {
  const { amount, currency } = transaction;

  return (
    <div className={styles.description}>
      <div>
        <IconByType
          type={(transaction as Transaction).type}
          className={styles.icon}
        />
        <TransactionName transaction={transaction} />
      </div>
      <BoldAmount amount={amount} currency={currency} />
    </div>
  );
};
