import clsx from 'clsx';
import { Initials } from 'common/components/Initials';
import { Text } from 'common/components/Text';
import styles from './MemberBadge.module.scss';

type Props = {
  className?: string;
  name: string;
  surname: string;
  email: string;
};

export const MemberBadge: React.FC<Props> = ({
  className,
  name,
  surname,
  email,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <Initials className={styles.initials} name={name} surname={surname} />
      <div className={styles.textContainer}>
        <Text
          className={styles.name}
          style={'bold1622'}
          dataTest='nameSurname'
        >{`${name} ${surname}`}</Text>
        <Text
          className={styles.email}
          style={'light1425'}
          colorStyle='secondary'
          dataTest='email'
        >
          {email}
        </Text>
      </div>
    </div>
  );
};
