import { ReactComponent as WalletSmallGrey } from 'common/assets/grey-icons/wallet-small-grey.svg';
import { Text } from 'common/components/Text';
import { toLimitedPrec, toFixed } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import styles from './BalanceBox.module.scss';

type Props = {
  wallet: CurrencyWallet | undefined;
  currency: Currency | undefined;
};

export const BalanceBox: React.FC<Props> = ({ currency, wallet }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <WalletSmallGrey />
      <Text style='light1215' colorStyle='secondary' className={styles.balance}>
        {formatMessage('common.balance')}
      </Text>
      <Text style='light1215' colorStyle='secondary' dataTest='walletBalance'>
        {`${toLimitedPrec(toFixed(wallet?.balance ?? '0'))} ${
          currency?.currency
        }`}
      </Text>
    </div>
  );
};
