import { useCallback } from 'react';
import {
  LendCalculationBase,
  LendsEffectPayload,
  LendsEffectResponseData,
  LendsEffectResponseError,
  interestRateDoesNotExist,
  principalMaxError,
  principalMinError,
  useLendsEffectMutation,
} from '../api';
import debounce from 'lodash/debounce';
import { TermEnum } from 'modules/financial-ops/common';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { useNewLendContext } from './context';
import { CurrencyEnum } from 'common/types';
import { useFormatMessage } from 'modules/messages';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';

type InputData = {
  amount?: string;
  calculationBase: LendCalculationBase;
  currency?: CurrencyEnum;
  term?: TermEnum;
  wallet?: number;
};

export const useCalculateEffect = () => {
  const formatMessage = useFormatMessage();

  const mutation = useLendsEffectMutation();

  const context = useNewLendContext();

  const onEffectCalculated = useCallback(
    (effect: LendsEffectResponseData, payload: LendsEffectPayload) => {
      context.setAssetAndAmountError('');
      if (payload.calculation_base === 'PRINCIPAL_MAX') {
        context.setAmount(toLimitedPrec(toFixed(effect.principal_amount)));
        if (effect.limit_exceeded) {
          context.setAmountInfo(
            formatMessage('common.reducedToMaximalAllowedAmount')
          );
        } else {
          context.setAmountInfo('');
        }
      }
    },
    []
  );

  const onEffectError = useCallback(
    (error: LendsEffectResponseError, payload: LendsEffectPayload) => {
      let match: RegExpMatchArray | undefined;
      match = principalMinError(error);
      if (match) {
        context.setAssetAndAmountError(
          formatMessage('lend.principalAmountMustBeGreater', {
            amount: toFixed(match[1]),
            currency: payload.principal_currency,
          })
        );
        if (payload.calculation_base === 'PRINCIPAL_MAX') {
          context.setAmount(INITIAL_AMOUNT);
        }
      }
      match = principalMaxError(error);
      if (match) {
        context.setAssetAndAmountError(
          formatMessage('lend.principalAmountMustBeLess', {
            amount: toFixed(match[1]),
            currency: payload.principal_currency,
          })
        );
      }

      if (interestRateDoesNotExist(error)) {
        context.setAssetAndAmountError(
          formatMessage('finopsCommon.interestRateDoesNotExist')
        );
      }
    },
    []
  );

  const mutateDebounced = useCallback(
    debounce((payload: LendsEffectPayload) => {
      mutation.mutate(payload, {
        onSuccess: (response) => {
          onEffectCalculated(response.data, payload);
        },
        onError: (err) => {
          if (err.response) {
            onEffectError(err.response?.data, payload);
          }
        },
      });
    }, 200),
    []
  );

  // take input from last user interaction, merge it with previous input stored in context, and call /effect endpoint
  const calculateEffect = (inputData: InputData) => {
    const data: InputData = {
      amount: context.amount,
      currency: context.currency?.currency,
      wallet: context.wallet?.id,
      term: context.rate?.term,
      ...inputData,
    };
    if (!data.currency || !data.term || !data.wallet) {
      return;
    }
    const commonPayload: LendsEffectPayload = {
      calculation_base: data.calculationBase,
      principal_currency: data.currency,
      principal_wallet_id: data.wallet,
      term: data.term,
      summary: false,
    };
    if (commonPayload.calculation_base === 'PRINCIPAL_MAX') {
      mutateDebounced(commonPayload);
    }
    if (
      commonPayload.calculation_base === 'PRINCIPAL' &&
      toDecimal(data.amount).gt(0)
    ) {
      mutateDebounced({
        ...commonPayload,
        principal_amount: toFixed(data.amount),
      });
    }
  };

  return { calculateEffect, mutation };
};
