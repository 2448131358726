import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';

type Props = {
  onClose: () => void;
};

export const StepSuccess: React.FC<Props> = ({ onClose }) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('charityForms.charityFormCreated')}
      </ModalHeader>
      <ModalBody>
        <Text style={'light1425'}>
          {formatMessage(
            'charityForms.yourCharityFormHasBeenSaccessfullyCreated'
          )}
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('common.button.done')}
            type='dark'
            onClick={onClose}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
