import { Text } from 'common/components/Text';
import styles from './ProgressBar.module.scss';
import { toDecimal } from 'modules/input-amount';
import { Range } from 'react-range';
import { getLinearGradientTrackBackground } from 'common/components/Slider';
import { theme } from 'common/consts/theme';

type ProgressBarProps = {
  valuePct: string;
  isFinished?: boolean;
  trackEndColor?: string;
  trackStartColor?: string;
};

/**
 * Percentage progress bar. Values over 100 are displayed as full bar with percentage label displaying actual value.
 */
export const ProgressBar: React.FC<ProgressBarProps> = ({
  valuePct,
  isFinished = false,
  trackEndColor,
  trackStartColor,
}) => {
  const valuePctNum = toDecimal(valuePct).toNumber();
  const valuePctBounded = Math.max(0, Math.min(valuePctNum, 100));

  return (
    <div className={styles.container}>
      <Range
        min={0}
        max={100}
        values={[valuePctBounded]}
        onChange={() => {}}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{ ...props.style }}
            className={styles.trackContainer}
          >
            <div
              style={{
                background: getLinearGradientTrackBackground({
                  value: valuePctBounded,
                  max: 100,
                  trailFillStart: isFinished
                    ? theme.colors.backgroundFinishedSlider
                    : trackStartColor
                    ? trackStartColor
                    : undefined,
                  trailFillEnd: isFinished
                    ? theme.colors.backgroundFinishedSlider
                    : trackEndColor
                    ? trackEndColor
                    : undefined,
                }),
              }}
              className={styles.track}
            />
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} style={{ ...props.style }} className={styles.thumb}>
            <Text
              style='light1215'
              colorStyle='secondary'
              className={styles.text}
            >
              {valuePctNum}%
            </Text>
          </div>
        )}
      />
    </div>
  );
};
