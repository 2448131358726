import { useState } from 'react';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { InputPassword } from 'common/components/input/InputPassword';
import { validatePassword } from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';
import { RecaptchaInfotext } from 'modules/recaptcha';
import { Button, BackButton } from 'common/components/button';

type Props = {
  password: string;
  onSubmit: (password: string) => void;
  onGoBack: () => void;
};

export const StepPassword: React.FC<Props> = ({
  password: passwordProp,
  onSubmit,
  onGoBack,
}) => {
  const formatMessage = useFormatMessage();

  const [password, setPassword] = useState(passwordProp);
  const [secondPassword, setSecondPassword] = useState(passwordProp);
  const [errorPassword, setErrorPassword] = useState('');
  const [errorSecondPassword, setErrorSecondPassword] = useState('');

  const validateAndSubmit = () => {
    if (password === '') {
      setErrorPassword(formatMessage('common.fieldRequired'));
      return;
    }
    if (!validatePassword(password)) {
      setErrorPassword(formatMessage('common.wrongPasswordFormat'));
      return;
    }
    setErrorPassword('');

    if (password !== secondPassword) {
      setErrorSecondPassword(formatMessage('common.passwordsNotIdentical'));
      return;
    }
    setErrorSecondPassword('');

    onSubmit(password);
  };

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('signup.setPassword')}
      </ModalHeader>
      <ModalBody>
        <>
          <InputPassword
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            header={formatMessage('common.password')}
            errorText={errorPassword}
            onEnter={() => {
              document
                .getElementById('CreateCompany-input-secondpassword')
                ?.focus();
            }}
            autofocus
            dataTest='passwordInput'
          />
          <InputPassword
            value={secondPassword}
            onChange={(e) => {
              setSecondPassword(e.target.value);
            }}
            header={formatMessage('common.repeatPassword')}
            errorText={errorSecondPassword}
            onEnter={() => {
              document.getElementById('CreateCompany-input-terms')?.focus();
            }}
            id={'CreateCompany-input-secondpassword'}
            dataTest='repeatPasswordInput'
          />
        </>
        <RecaptchaInfotext />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            name={formatMessage('signup.createAccount')}
            type='dark'
            onClick={validateAndSubmit}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
