import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { Button, CancelButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalContext,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { prettifyError } from 'common/utils/prettify-error';
import { addMonths, format } from 'date-fns';
import Decimal from 'decimal.js';
import { termToMonths } from 'modules/financial-ops/common/utils';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { CURRENCIES_QUERY_KEY } from 'modules/select-currency-wallet/api';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import {
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { useContext, useState } from 'react';
import {
  ERR_NOT_ENOUGH_FUNDS,
  LendsEffectPayload,
  LendsEffectResponseError,
  PostLendResponseError,
  interestRateDoesNotExist,
  principalMaxError,
  principalMinError,
  useLendsEffectMutation,
  useLendsMutation,
} from '../api';
import styles from './NewLendStepSummary.module.scss';
import {
  TermEnum,
  tokenInvalidValue,
  tokenIsRequired,
} from 'modules/financial-ops/common';
import {
  MaturitySummaryLabel,
  WalletBox,
  AssetAmountReadOnly,
  SnapshotAlert,
  TokenExpiryTimer,
} from 'modules/financial-ops/common/components';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { Text } from 'common/components/Text';
import { TermsAndConditions } from 'modules/documents';
import { CURRENCY_RATES_QUERY_KEY } from 'modules/fiat';

type Props = {
  amount: Decimal;
  currency: Currency | undefined;
  initialInterestAmount: string | undefined;
  initialInterestRatePct: string | undefined;
  interestRateTerm: TermEnum | undefined;
  wallet: CurrencyWallet | undefined;
  onGoNext: (transactionId: number) => void;
  initialToken: string | undefined;
};

export const NewLendStepSummary: React.FC<Props> = ({
  amount,
  currency,
  initialInterestAmount,
  initialInterestRatePct,
  interestRateTerm,
  wallet,
  onGoNext,
  initialToken,
}) => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const { onClose } = useContext(ModalContext);

  const [checked, setChecked] = useState(false);
  const [timeExpired, setTimeExpired] = useState(false);
  const [termsAndConditionsError, setTermsAndConditionError] = useState(false);

  const lendsMutation = useLendsMutation();
  const lendsEffectMutation = useLendsEffectMutation();

  const refetchToken = () => {
    if (!wallet || !currency || !initialInterestRatePct || !interestRateTerm) {
      return;
    }

    let payload: LendsEffectPayload = {
      calculation_base: 'PRINCIPAL',
      principal_wallet_id: wallet.id,
      principal_currency: currency.currency,
      term: interestRateTerm!,
      summary: true,
      principal_amount: amount.toFixed(),
    };

    lendsEffectMutation.mutate(payload, {
      onError: () => {
        queryClient.invalidateQueries(CURRENCY_RATES_QUERY_KEY);
      },
    });
  };

  const getErrorText = (error: AxiosError<PostLendResponseError>) => {
    if (
      typeof error.response?.data === 'object' &&
      error.response.data.principal_amount?.includes(ERR_NOT_ENOUGH_FUNDS)
    ) {
      return formatMessage('lend.yourFundsInChosenWalletAreInsufficient');
    }

    if (tokenIsRequired(error.response?.data)) {
      return formatMessage('common.tokenIsRequired');
    }

    return prettifyError(error);
  };

  const getRefetchTokenError = (
    error: AxiosError<LendsEffectResponseError>
  ) => {
    const principalMaxMatch = principalMaxError(error.response?.data);
    if (principalMaxMatch) {
      return formatMessage(
        'lend.currentPrincipalAmountMustBeLessOrEqualGoBackAndUpdate',
        {
          amount: toFixed(principalMaxMatch[1]),
          currency: currency?.currency,
        }
      );
    }

    const principalMinMatch = principalMinError(error.response?.data);
    if (principalMinMatch) {
      return formatMessage(
        'lend.currentPrincipalAmountMustBeGreaterOrEqualGoBackAndUpdate',
        {
          amount: toFixed(principalMinMatch[1]),
          currency: currency!.currency,
        }
      );
    }

    if (interestRateDoesNotExist(error.response?.data)) {
      return formatMessage('finopsCommon.noInterestRateWasFound');
    }

    return prettifyError(error);
  };

  const modalHeader = (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('finopsCommon.reviewConfirmTransaction')}
          </Text>
        }
      >
        {formatMessage('lend.lendSummary')}
      </ModalHeader>
    </>
  );

  // use locally refetched token if initial token expired
  const token = lendsEffectMutation.data?.data.token || initialToken;
  const interestAmount =
    lendsEffectMutation.data?.data.interest_total || initialInterestAmount;
  const rate =
    lendsEffectMutation.data?.data.rate_pct || initialInterestRatePct;

  return (
    <>
      {modalHeader}
      <ModalBody dataTest='lendSummaryModal'>
        {lendsMutation.isError &&
          !tokenInvalidValue(lendsMutation.error.response?.data) && (
            <Alert
              severity='error'
              text={getErrorText(lendsMutation.error)}
              className={styles.error}
            />
          )}
        {lendsEffectMutation.error && (
          <Alert
            severity='error'
            text={getRefetchTokenError(lendsEffectMutation.error)}
            className={styles.error}
          />
        )}
        <SectionSubheader>{formatMessage('common.amount')}</SectionSubheader>
        {currency && (
          <AssetAmountReadOnly
            amount={amount}
            currency={currency}
            className={styles.input}
          />
        )}
        {currency && wallet && (
          <WalletBox
            wallet={wallet}
            currency={currency.currency}
            className={styles.wallet}
          />
        )}
        <Summary className={styles.summary}>
          <SummaryLabel>{formatMessage('finopsCommon.term')}</SummaryLabel>
          <SummaryValue dataTest='termInSummary'>
            {interestRateTerm
              ? formatMessage('termAndRate.term', {
                  months: termToMonths(interestRateTerm),
                })
              : ''}
          </SummaryValue>
          <SummaryLabel>{formatMessage('lend.rate')}</SummaryLabel>
          <SummaryValue dataTest='rateInSummary'>{`${toFixed(
            rate
          )}%`}</SummaryValue>
          <SummaryLabel>{formatMessage('finopsCommon.totalEarn')}</SummaryLabel>
          <SummaryValue dataTest='totalEarnInSummary'>
            {currency
              ? `${toLimitedPrec(toFixed(interestAmount))} ${currency.currency}`
              : ''}
          </SummaryValue>
          <MaturitySummaryLabel />
          <SummaryValue dataTest='maturityInSummary'>
            {interestRateTerm
              ? format(
                  addMonths(new Date(), termToMonths(interestRateTerm)),
                  'dd MMM yyyy'
                )
              : ''}
          </SummaryValue>
        </Summary>
        <TermsAndConditions
          checked={checked}
          error={termsAndConditionsError}
          onToggle={(checked) => {
            setTermsAndConditionError(false);
            setChecked(checked);
          }}
        />
        {(tokenInvalidValue(lendsMutation.error?.response?.data) ||
          timeExpired) && (
          <SnapshotAlert
            onRefresh={() => {
              refetchToken();
              setTimeExpired(false);
              lendsMutation.reset();
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onClose} />
          <Button
            type='dark'
            name={
              <div className={styles.button}>
                <Text style='xbold1417'>{formatMessage('lend.lend')}</Text>
                {!lendsEffectMutation.error && token && (
                  <TokenExpiryTimer
                    token={token}
                    onTimeExpired={() => setTimeExpired(true)}
                  />
                )}
              </div>
            }
            isLoading={lendsMutation.isLoading}
            disabled={
              lendsMutation.isLoading ||
              timeExpired ||
              !!lendsEffectMutation.error
            }
            onClick={() => {
              if (lendsMutation.isLoading) {
                return;
              }
              if (!checked) {
                setTermsAndConditionError(true);
                return;
              }
              // There is no message for the user here, because this should be impossible situation.
              // Validation before summary modal is opened should check for currency, interest rate and wallet
              // and if one is missing, prevent modal from being opened.
              if (!currency! || !interestRateTerm || !wallet) {
                return;
              }
              lendsMutation.mutate(
                {
                  principal_wallet_id: wallet.id,
                  principal_currency: currency.currency,
                  principal_amount: amount.toFixed(),
                  term: interestRateTerm,
                  token,
                },
                {
                  onSuccess: (res) => {
                    queryClient.invalidateQueries(CURRENCIES_QUERY_KEY);
                    queryClient.invalidateQueries(
                      DASHBOARD_TRANSACTIONS_QUERY_KEY
                    );
                    queryClient.invalidateQueries(
                      TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
                    );
                    onGoNext(res.data.transaction_id);
                  },
                }
              );
            }}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
