import { useState } from 'react';

import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { useQueryClient } from '@tanstack/react-query';
import { toDecimal } from 'modules/input-amount';
import {
  TransactionActionPayload,
  useTransactionActionMutation,
  DASHBOARD_TRANSACTIONS_QUERY_KEY,
  TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
} from 'modules/transactions/api';
import { openSnackbar } from 'common/components/snackbar';
import { StepSuccess } from './StepSuccess';
import { StepSummary } from './StepSummary';
import { StepWithdraw } from './StepWithdraw';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
  STATUS_INVALID_OTP,
  ALREADY_USED_OTP,
} from 'modules/2FA';
import {
  CharityFormWithdrawContextProvider,
  useCharityFormWithdrawContext,
} from '../context';
import { CharityFormDetailed } from 'modules/charity-forms/types';

type WithdrawSteps = 'withdraw' | 'summary' | '2FA' | 'success' | '2FAcancel';

type Props = {
  open: boolean;
  onClose: () => void;
  charityForm: CharityFormDetailed;
};

const CharityFormWithdrawModalWrapped: React.FC<Props> = ({
  open,
  onClose,
  charityForm,
}) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();
  const context = useCharityFormWithdrawContext();

  const [step, setStep] = useState<WithdrawSteps>('withdraw');

  const [transactionId, setTransactionId] = useState<number>();
  const [error2FA, setError2FA] = useState<string>();

  const { mutate: transactionActionMutation, isLoading: isLoadingTransaction } =
    useTransactionActionMutation();

  const continueToSummary = () => {
    if (context.amountError || context.walletError) {
      return;
    }

    if (!context.wallet) {
      context.setWalletError(formatMessage('common.walletIsNotSelected'));
      return;
    }

    if (toDecimal(context.amount).eq(0)) {
      context.setAmountError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }

    context.setWalletError('');
    context.setAmountError('');
    setStep('summary');
  };

  const onVerify = (keys: string) => {
    if (!transactionId) {
      return;
    }

    const payload: TransactionActionPayload = {
      transaction_id: transactionId,
      action: 'APPROVE',
    };

    transactionActionMutation(
      { payload, headers: generateHeader2FA(keys) },
      {
        onSuccess: () => {
          openSnackbar(
            formatMessage('transactions.approved.message'),
            'success'
          );
          queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS_QUERY_KEY);
          queryClient.invalidateQueries(
            TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY
          );
          closeModal();
        },
        onError: (err) => {
          if (
            err.response?.data?.status === STATUS_INVALID_OTP ||
            err.response?.data?.status === ALREADY_USED_OTP
          ) {
            setErrorOTP(err, setError2FA, formatMessage);
          } else {
            const text = Object.values(err.response!.data)[0] as string;
            openSnackbar(text, 'error');
          }
        },
      }
    );
  };

  const closeModal = () => {
    onClose();
    setStep('withdraw');
    context.reset();
  };

  return (
    <>
      <Modal isOpen={open} onClose={closeModal}>
        {step === 'withdraw' && (
          <StepWithdraw
            onSummary={continueToSummary}
            onCancel={closeModal}
            charityForm={charityForm}
          />
        )}
        {step === 'summary' && (
          <StepSummary
            amount={context.amount}
            onGoBack={() => {
              setStep('withdraw');
            }}
            currency={context.currency}
            wallet={context.wallet}
            remainingFunds={context.remainingFunds}
            id={charityForm.id}
            onGoNext={(txId) => {
              setTransactionId(txId);
              setStep('success');
            }}
          />
        )}
        {step === 'success' && (
          <StepSuccess onClose={closeModal} onApprove={() => setStep('2FA')} />
        )}
        {step === '2FA' && (
          <Module2FA
            onVerify={onVerify}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={isLoadingTransaction}
            onCancel={() => setStep('2FAcancel')}
            approvalOfTransaction={true}
            title={formatMessage('common.approveWithdraw')}
          />
        )}
        {step === '2FAcancel' && (
          <Cancel2FA onClose={closeModal} approvalOfTransaction={true} />
        )}
      </Modal>
    </>
  );
};

export const CharityFormWithdrawModal: React.FC<Props> = ({
  open,
  onClose,
  charityForm,
}) => {
  return (
    <CharityFormWithdrawContextProvider charityForm={charityForm}>
      <CharityFormWithdrawModalWrapped
        open={open}
        onClose={onClose}
        charityForm={charityForm}
      />
    </CharityFormWithdrawContextProvider>
  );
};
