import { InitialsButton } from 'common/components/Initials';
import { useNotificationsMenuContext } from 'modules/notifications/context';
import { useUserQuery } from './api';
import { useUserMenuContext } from './context';
import styles from './UserMenu.module.scss';
import { UserMenuContent } from './UserMenuContent';

type Props = {};

export const UserMenu: React.FC<Props> = () => {
  const { isOpen, setIsOpen } = useUserMenuContext();
  const { closeNotifications } = useNotificationsMenuContext();

  const { data: userData } = useUserQuery();

  return (
    <div className={styles.container} data-test='userMenuButton'>
      <InitialsButton
        name={userData?.data.name || ''}
        surname={userData?.data.surname || ''}
        onClick={() => {
          setIsOpen(true);
          closeNotifications();
        }}
      />
      {isOpen && (
        <>
          <div
            className={styles.catchClickContainer}
            onClick={() => setIsOpen(false)}
          />
          <div className={styles.menuContainer}>
            <UserMenuContent />
          </div>
        </>
      )}
    </div>
  );
};
