import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { formatDatetime } from 'common/utils/utils';

type Props = { endAt: string };

export const EndDate: React.FC<Props> = ({ endAt }) => {
  const formatMessage = useFormatMessage();

  return (
    <Text
      style='light1215'
      colorStyle='secondary'
      inlineStyle={{ display: 'block' }}
    >
      {formatMessage('charityForms.endDateWithDate', {
        date: formatDatetime(endAt),
      })}
    </Text>
  );
};
