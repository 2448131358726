type Props = {
  children: React.ReactNode;
  className?: string;
  dataTest?: string;
};

export const ModalBody: React.FC<Props> = ({
  children,
  className,
  dataTest,
}) => {
  return (
    <div className={className} data-test={dataTest}>
      {children}
    </div>
  );
};
