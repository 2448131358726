import { Button, ButtonType } from 'common/components/button';
import { Tooltip } from 'common/components/Tooltip';
import styles from './NewProductButton.module.scss';

type Props = {
  disabled: boolean;
  text: string;
  onClick: () => void;
  type?: ButtonType;
  tooltipTitle: string;
  isLoading?: boolean;
};

export const NewProductButton: React.FC<Props> = ({
  disabled,
  text,
  onClick,
  type = 'dark',
  tooltipTitle,
  isLoading = false,
}) => {
  return (
    <Tooltip title={tooltipTitle} tooltipClassName={styles.tooltip}>
      <div>
        <Button
          type={type}
          name={text}
          onClick={onClick}
          disabled={disabled}
          isLoading={isLoading}
        />
      </div>
    </Tooltip>
  );
};
