import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';

type Props = { rate: string };

export const InterestRate: React.FC<Props> = ({ rate }) => {
  const formatMessage = useFormatMessage();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text style='light1215'>
        {formatMessage('common.interestRate') + ':'}
      </Text>
      <Text style='bold1422'>{rate + '%'}</Text>
    </div>
  );
};
