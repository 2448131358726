import { Currency } from 'modules/select-currency-wallet/types';
import { BorrowReadStateEnum } from '../../types';
import { CollateralAmount } from './CollateralAmount';
import { LtvRatio } from './LtvRatio';
import { TopupBorrowState } from './TopupBorrowState';

type Props = {
  currency: Currency;
  newCollateralAmount?: string;
  currentCollateralAmount?: string;
  newLtvRatio?: string;
  currentLtvRatio?: string;
  newState?: BorrowReadStateEnum;
  currentState?: BorrowReadStateEnum;
  isLoading: boolean;
};

export const BorrowTopupEffect: React.FC<Props> = ({
  currency,
  newCollateralAmount,
  currentCollateralAmount,
  newLtvRatio,
  currentLtvRatio,
  newState,
  currentState,
  isLoading,
}) => {
  return (
    <div>
      <CollateralAmount
        amount={newCollateralAmount}
        subamount={currentCollateralAmount}
        currency={currency.currency}
        isLoading={isLoading}
      />
      <LtvRatio
        newRatio={newLtvRatio}
        currentRatio={currentLtvRatio}
        isLoading={isLoading}
      />
      <TopupBorrowState
        newState={newState}
        currentState={currentState}
        isLoading={isLoading}
      />
    </div>
  );
};
