import { ReactComponent as LogotypeLight } from 'common/assets/logo/montesauri-logotype-light.svg';
import { ReactComponent as LogotypeDark } from 'common/assets/logo/montesauri-logotype-dark.svg';
import { useNavigate } from 'react-router-dom';

export const MontesauriLogotype: React.FC<{
  className?: string;
  isDark?: boolean;
  path?: string;
}> = ({ className, isDark = false, path = '/login' }) => {
  const navigate = useNavigate();

  return (
    <>
      {isDark ? (
        <LogotypeDark
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(path)}
          className={className}
        />
      ) : (
        <LogotypeLight
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(path)}
          className={className}
        />
      )}
    </>
  );
};
