import { useState } from 'react';
import styles from './CategoryFilter.module.scss';
import { useFormatMessage } from 'modules/messages';
import { ModalTemplate } from './components/ModalTemplate';
import { useFeatureFlag } from 'modules/feature-flags';
import { FilterButton } from './components/FilterButton';
import { MainFilterButton } from './components/MainFilterButton';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters/context';
import { toggleElement } from 'common/utils/toggle-element';

type Props = {};

export const CategoryFilter: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const [open, setOpen] = useState(false);
  const [preventClose, setPreventClose] = useState(false);

  const { filters, setFilters, setRefetchTransactionsFlag } =
    useInfiniteTransactionsContext();

  const featureFlagBorrow = useFeatureFlag('BORROW');
  const featureFlagLend = useFeatureFlag('LEND');
  const featureFlagSaving = useFeatureFlag('SAVING');
  const featureFlagTrade = useFeatureFlag('TRADE');
  const featureFlagCharityForm = useFeatureFlag('CHARITY_FORM');

  const categoriesList = [
    {
      text: formatMessage('common.trade'),
      value: 'TRADE',
      enabled: !!featureFlagTrade.data?.enabled,
    },
    {
      text: formatMessage('transactions.lend'),
      value: 'LEND',
      enabled: !!featureFlagLend.data?.enabled,
    },
    {
      text: formatMessage('transactions.borrow'),
      value: 'BORROW',
      enabled: !!featureFlagBorrow.data?.enabled,
    },
    {
      text: formatMessage('transactions.deposit'),
      value: 'DEPOSIT',
      enabled: true,
    },
    {
      text: formatMessage('transactions.withdraw'),
      value: 'WITHDRAWAL',
      enabled: true,
    },
    {
      text: formatMessage('saving.saving'),
      value: 'SAVING',
      enabled: !!featureFlagSaving.data?.enabled,
    },
    {
      text: formatMessage('charityForms.charity'),
      value: 'CHARITY',
      enabled: !!featureFlagCharityForm.data?.enabled,
    },
  ];

  const buttonText =
    formatMessage('transactions.category') +
    (filters.categories?.length
      ? ': ' +
        filters.categories
          ?.map(
            (state) =>
              categoriesList.find((value) => value.value === state)?.text
          )
          .join(', ')
      : '');

  return (
    <div>
      <MainFilterButton
        onClick={() => {
          setPreventClose(true);
          setOpen((prevIsOpen) => !prevIsOpen);
        }}
        text={buttonText}
      />
      <ModalTemplate
        open={open}
        onChangeOpen={(value) => setOpen(value)}
        onChangePreventClose={(value) => setPreventClose(value)}
        preventClose={preventClose}
        children={
          <div className={styles.modalContent}>
            {categoriesList.map(
              (category, i) =>
                category.enabled && (
                  <FilterButton
                    key={i}
                    onClick={() => {
                      setFilters({
                        ...filters,
                        categories: toggleElement(
                          filters.categories || [],
                          category.value
                        ).sort(),
                      });
                      setRefetchTransactionsFlag(true);
                    }}
                    text={category.text}
                    isSelected={filters.categories?.includes(category.value)}
                  />
                )
            )}
          </div>
        }
      />
    </div>
  );
};
