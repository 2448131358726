import { SectionHeader } from 'common/components/SectionHeader';
import { LoaderBox } from 'common/components/loader';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import {
  TermAndRateSelector,
  findCurrency,
  getHighestBalanceWallet,
  useInterestRatesExistForKind,
  useSortedInterestRates,
} from 'modules/financial-ops/common';
import { AssetAmountInput } from 'modules/financial-ops/common/components/AssetAmountInput';
import {
  ConfigSection,
  NewOperationLayout,
  NavigationHeader,
  SummarySection,
} from 'modules/financial-ops/common/components';
import { KyxAlert, useIsKyxCompleted } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';
import { useCallback, useEffect, useState } from 'react';
import { AlternativeOffer } from './AlternativeOffer';
import styles from './NewSaving.module.scss';
import { SavingEffect } from './SavingEffect';
import { NewSavingSummaryModal } from './NewSavingSummaryModal';
import { toDecimal, toFixed } from 'modules/input-amount';
import { openSnackbar } from 'common/components/snackbar';
import {
  SavingEffectPayload,
  amountTooBigError,
  amountTooSmallError,
  globalLimitReached,
  interestRateDoesNotExist,
  maximumSavingsReached,
  useSavingsEffectMutation,
} from '../api';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';
import { NewSavingContextProvider, useNewSavingContext } from './context';
import { useCalculateEffect } from './use-calculate-effect';
import {
  subscribeToSettingUpdate,
  unsubscribeFromSettingUpdate,
} from 'modules/websocket';

const NewSavingWrapped: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const savingsEffectMutation = useSavingsEffectMutation();

  const context = useNewSavingContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const isKyxCompleted = useIsKyxCompleted();

  useEffect(() => {
    if (!isKyxCompleted) {
      closeModal();
    }
  }, [isKyxCompleted, closeModal]);

  const { data: currencies, isLoading: isLoadingCurrencies } =
    useGetCurrenciesWithWallets();

  const { data: lendRates } = useSortedInterestRates({
    sortDirection: 'desc',
    kind: 'LEND',
  });

  const bestLendRate = lendRates?.filter(
    (rate) =>
      rate.currency === context.currency?.currency &&
      rate.network === context.currency?.network
  )[0];

  const continueToSummary = () => {
    if (!context.currency) {
      context.setAssetAndAmountError(
        formatMessage('finopsCommon.currencyNotSelected')
      );
      return;
    }
    if (!context.wallet) {
      context.setWalletError(formatMessage('finopsCommon.walletNotSelected'));
      return;
    }
    if (toDecimal(context.amount).eq(0)) {
      context.setAssetAndAmountError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }

    if (!context.rate) {
      context.setRateError(formatMessage('finopsCommon.rateNotSelected'));
      return;
    }

    // error from /effect endpoint
    if (context.assetAndAmountError) {
      return;
    }

    if (toDecimal(context.amount).gt(context.wallet.balance)) {
      openSnackbar(
        formatMessage('saving.yourFundsInChosenWalletAreInsufficient'),
        'warning'
      );
      return;
    }

    getInitialToken();
  };

  const getInitialToken = () => {
    if (!context.wallet || !context.currency) {
      return;
    }

    const payload: SavingEffectPayload = {
      calculation_base: 'AMOUNT',
      currency: context.currency.currency,
      wallet_id: context.wallet.id,
      amount: context.amount,
      summary: true,
    };

    savingsEffectMutation.mutate(payload, {
      onSuccess: () => {
        setIsModalOpen(true);
      },
      onError: (error) => {
        const tooSmallMatch = amountTooSmallError(error.response?.data);
        if (tooSmallMatch) {
          context.setAssetAndAmountError(
            formatMessage('saving.minimumAmountForSaving', {
              amount: toFixed(tooSmallMatch[1]),
              currency: payload.currency,
            })
          );
          if (payload.calculation_base === 'MAX') {
            context.setAmount(INITIAL_AMOUNT);
          }
        }
        const tooBigMatch = amountTooBigError(error.response?.data);
        if (tooBigMatch) {
          context.setAssetAndAmountError(
            formatMessage('saving.maximumAmountForSaving', {
              amount: toFixed(tooBigMatch[1]),
              currency: payload.currency,
            })
          );
        }

        if (interestRateDoesNotExist(error.response?.data)) {
          context.setAssetAndAmountError(
            formatMessage('saving.interestRateDoesNotExist')
          );
        }

        if (globalLimitReached(error.response?.data)) {
          context.setAssetAndAmountError(
            formatMessage('saving.maximumGlobalLimitReached')
          );
        }

        const maximumSavingsReachedMatch = maximumSavingsReached(
          error.response?.data
        );
        if (maximumSavingsReachedMatch) {
          context.setAssetAndAmountError(
            formatMessage('saving.maximumNumberOfSavingsReached', {
              count: maximumSavingsReachedMatch[1],
            })
          );
        }
      },
    });
  };

  const {
    calculateEffect,
    mutation: { isLoading: isLoadingEffect, data: effectData },
  } = useCalculateEffect();

  // Calculate effect when interest rate is changed outside of this component, for example when INTEREST_RATE_UPDATED WebSocket event is handled.
  // By using useEffect for this task, it is possible that under certain conditions, /api/effect will be called twice, but this is a deliberate trade-off.
  useEffect(() => {
    calculateEffect({ calculationBase: 'AMOUNT' });
  }, [context.rate]);

  useEffect(() => {
    const handle = subscribeToSettingUpdate('SAVING_MAX_AMOUNT_EUR', () => {
      calculateEffect({ calculationBase: 'AMOUNT' });
    });
    return () => {
      unsubscribeFromSettingUpdate('SAVING_MAX_AMOUNT_EUR', handle);
    };
  }, [calculateEffect]);

  const saveFeatureFlag = useRequireFeatureFlag('SAVING');
  const newSaveFeatureFlag = useRequireFeatureFlag('CREATING_SAVING');
  const ratesExists = useInterestRatesExistForKind('SAVING', {
    redirect: true,
  });

  if (
    saveFeatureFlag.isLoading ||
    newSaveFeatureFlag.isLoading ||
    ratesExists.isLoading
  ) {
    return <LoaderBox />;
  }

  if (
    !saveFeatureFlag.data?.enabled ||
    !newSaveFeatureFlag.data?.enabled ||
    !ratesExists.data
  ) {
    return null;
  }

  return (
    <>
      <NewOperationLayout className={styles.layout}>
        <ConfigSection>
          <NavigationHeader to='/savings-accounts'>
            {formatMessage('common.back')}
          </NavigationHeader>
          <SectionHeader header={formatMessage('saving.newSaving')} />
          <KyxAlert className={styles.kycAlert} />
          <AssetAmountInput
            amount={context.amount}
            currency={context.currency}
            currencies={currencies}
            desktopHeader={formatMessage('common.selectAssetAndAmount')}
            error={context.assetAndAmountError}
            info={context.amountInfo}
            interestRateKind='SAVING'
            isLoadingCurrencies={isLoadingCurrencies}
            mobileAssetHeader={formatMessage('common.currency')}
            mobileAmountHeader={formatMessage('common.amount')}
            showApr={true}
            wallet={context.wallet}
            wallets={context.currency?.wallets}
            walletError={context.walletError}
            onAmountChange={(amount) => {
              context.setAmount(amount);
              context.setAssetAndAmountError('');
              context.setAmountInfo('');
              calculateEffect({
                amount,
                calculationBase: 'AMOUNT',
              });
            }}
            onCurrencyChange={(currency, rate) => {
              context.setAssetAndAmountError('');
              context.setAmountInfo('');
              context.setWalletError('');
              context.setRateError('');
              context.setAmount(INITIAL_AMOUNT);

              const foundCurrency = findCurrency(currency, currencies);
              context.setCurrencyKey(foundCurrency);

              const foundWallet = getHighestBalanceWallet(
                foundCurrency?.wallets
              );
              if (foundWallet) context.setWalletId(foundWallet.id);

              calculateEffect({
                calculationBase: 'AMOUNT',
                currency: foundCurrency?.currency,
                wallet: foundWallet?.id,
              });
            }}
            onMax={() => {
              calculateEffect({
                calculationBase: 'MAX',
              });
            }}
            onWalletChange={(wallet) => {
              calculateEffect({
                calculationBase: 'AMOUNT',
                wallet: wallet?.id,
              });
              context.setWalletId(wallet.id);
              context.setWalletError('');
              context.setAmountInfo('');
            }}
          />
          <TermAndRateSelector
            className={styles.rate}
            currency={context.currency}
            error={context.rateError}
            headerText={formatMessage('saving.termAndRate')}
            interestRateKind={'SAVING'}
            noDataText={formatMessage(
              'saving.thereIsNoRateAvailableForThisCurrencyAndNetwork'
            )}
            selectedRate={context.rate}
            onSelectRate={() => {}}
          />
        </ConfigSection>
        <SummarySection>
          <SavingEffect
            amount={context.amount}
            currency={context.currency}
            rate={context.rate}
            wallet={context.wallet}
            onContinueToSummary={continueToSummary}
            isLoading={isLoadingEffect}
            dailyEarn={effectData?.data.interest_daily}
          />
        </SummarySection>
      </NewOperationLayout>
      <NewSavingSummaryModal
        amount={context.amount}
        currency={context.currency}
        dailyEarn={savingsEffectMutation.data?.data.interest_daily}
        interestRatePct={savingsEffectMutation.data?.data.rate_pct}
        isOpen={isModalOpen}
        token={savingsEffectMutation.data?.data.token}
        wallet={context.wallet}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
      {bestLendRate && context.currency && context.wallet && (
        <AlternativeOffer
          amount={context.amount}
          currency={context.currency}
          rate={bestLendRate}
          walletId={context.wallet.id}
          disabled={
            toDecimal(context.amount).eq(0) || !!context.assetAndAmountError
          }
        />
      )}
    </>
  );
};

export const NewSaving: React.FC<{}> = () => {
  return (
    <NewSavingContextProvider>
      <NewSavingWrapped />
    </NewSavingContextProvider>
  );
};
