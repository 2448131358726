import clsx from 'clsx';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { LoaderBox } from 'common/components/loader';
import { Text, textStyles } from 'common/components/Text';
import { ReactComponent as ArrowDownIcon } from 'common/assets/black-icons/arrowhead-down-12px.svg';
import { useFormatMessage } from 'modules/messages';
import { Member, useMembersQuery } from 'modules/company-members/api';
import styles from './SelectOwner.module.scss';

type SelectOwnerProps = {
  selectedMember?: Member;
  onSelect: (owner: Member) => void;
  checked: boolean;
  onError: (err: boolean) => void;
};

export const SelectOwner: React.FC<SelectOwnerProps> = ({
  selectedMember,
  onSelect,
  checked,
  onError,
}) => {
  const formatMessage = useFormatMessage();
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useMembersQuery();

  const members = data?.data.filter(
    (member) => member.company_role !== 'OWNER'
  );

  return (
    <div
      className={clsx(
        styles.container,
        selectedMember && styles.withoutPaddingLeft
      )}
      onClick={() => {
        if (checked) {
          onError(false);
          setOpen((prev) => !prev);
        } else {
          onError(true);
        }
        return;
      }}
    >
      <FormControl
        fullWidth
        sx={{
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `none`,
          },
          '*.Mui-focused': {
            borderColor: 'transparent',
            outline: 'none',
          },
        }}
      >
        {!selectedMember && (
          <InputLabel
            disableAnimation
            shrink={false}
            focused={false}
            className={clsx(textStyles.light1425, styles.inputLabel)}
          >
            <Text style='light1425'>
              {formatMessage('common.selectFromList')}
            </Text>
          </InputLabel>
        )}
        <Select
          IconComponent={() => <ArrowDownIcon className={styles.icon} />}
          readOnly={!checked}
          variant='outlined'
          id='selectOwner'
          open={open}
          renderValue={(val) =>
            selectedMember ? (
              <Text style='light1425'>
                {selectedMember?.name} {selectedMember?.surname}
              </Text>
            ) : null
          }
          inputProps={{
            'aria-label': 'Without label',
          }}
          sx={{
            '.Mui-focused': {
              borderColor: 'transparent',
              outline: 'none',
            },
          }}
          MenuProps={{
            classes: {
              paper: clsx(styles.select, selectedMember && styles.marginLeft),
            },
            PaperProps: {
              sx: {
                '.MuiMenuItem-root': {
                  padding: 0,
                },
                '.MuiPaper-rounded': { minWidth: '1000px' },
                '& .MuiMenuItem-root.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '& .MuiMenuItem-root:hover': {
                  backgroundColor: 'transparent',
                },
                '& .MuiMenuItem-root.Mui-selected:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
          }}
        >
          {members &&
            members.map((member, i) => (
              <MenuItem
                key={i}
                value={member.id}
                onClick={() => onSelect(member)}
              >
                <MemberRow member={member} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {isLoading && <LoaderBox />}
    </div>
  );
};

export const MemberRow: React.FC<{
  member: Member;
}> = ({ member }) => {
  return (
    <Text style='light1622' className={styles.memberRow}>
      {member.name} {member.surname}
    </Text>
  );
};
