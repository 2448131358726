import { useState } from 'react';
import * as R from 'ramda';
import { useFormatMessage } from 'modules/messages';
import styles from './Lends.module.scss';
import { Text } from 'common/components/Text';
import { LendBox } from './components/LendBox';
import { useLendsQuery } from '../api';
import { LoaderBox } from 'common/components/loader';
import {
  PopupSorter,
  SortConfig,
} from 'common/components/table-subcomponents/sort/PopupSorter';
import {
  ascCmpTerms,
  ascComp,
  descCmpTerms,
  descComp,
} from 'common/utils/comparators';
import { SortValue } from 'common/components/table-subcomponents';
import { PopupFilter } from 'common/components/table-subcomponents/filter/PopupFilter';
import { NothingToDisplay } from 'modules/nothing-to-display';
import { LendRead } from '../types';
import { BoxLayout } from 'modules/financial-ops/common/components';
import { useFilters } from 'modules/financial-ops/common/hooks';
import { useFiatComparators } from 'modules/fiat';
import { ActiveLendsCount } from './components/ActiveLendsCount';

type SortModel = {
  principal_amount?: SortValue;
  principal_currency?: SortValue;
  term?: SortValue;
  start_at?: SortValue;
  end_at?: SortValue;
};

export const Lends: React.FC = () => {
  const formatMessage = useFormatMessage();

  const { data: lends = [], isLoading } = useLendsQuery();

  const [sort, setSort] = useState<SortModel>({});

  const [filter, filterConfig] = useFilters();

  const { ascFiatCmp, descFiatCmp } = useFiatComparators();

  const getSortedAndFilteredData = () => {
    const filteredLends = filter.active
      ? R.filter((item: LendRead) => item.state === 'ACTIVE', lends)
      : filter.finished
      ? R.filter(
          (item: LendRead) =>
            item.state === 'COMPLETED' || item.state === 'TERMINATED',
          lends
        )
      : lends;

    if (sort.principal_amount) {
      return R.sort(
        (item1, item2) =>
          (sort.principal_amount === 'asc' ? ascFiatCmp : descFiatCmp)(
            {
              amount: item1.principal_amount,
              currency: item1.principal_currency,
            },
            {
              amount: item2.principal_amount,
              currency: item2.principal_currency,
            }
          ),
        filteredLends
      );
    }
    if (sort.principal_currency) {
      return R.sort(
        (item1, item2) =>
          (sort.principal_currency === 'asc' ? ascComp : descComp)(
            item1.principal_currency,
            item2.principal_currency
          ),
        filteredLends
      );
    }
    if (sort.term) {
      return R.sort(
        (item1, item2) =>
          (sort.term === 'asc' ? ascCmpTerms : descCmpTerms)(
            item1.term,
            item2.term
          ),
        filteredLends
      );
    }
    if (sort.start_at) {
      return R.sort(
        (item1, item2) =>
          (sort.start_at === 'asc' ? ascComp : descComp)(
            item1.start_at,
            item2.start_at
          ),
        filteredLends
      );
    }

    if (sort.end_at) {
      return R.sort(
        (item1, item2) =>
          (sort.end_at === 'asc' ? ascComp : descComp)(
            item1.end_at,
            item2.end_at
          ),
        filteredLends
      );
    }

    return filteredLends;
  };

  const sortList: SortConfig[] = [
    {
      sort: sort.principal_amount,
      onSort: (next: SortValue) => {
        setSort({ principal_amount: next });
      },
      text: formatMessage('finopsCommon.lockedAmount'),
    },
    {
      sort: sort.principal_currency,
      onSort: (next: SortValue) => {
        setSort({ principal_currency: next });
      },
      text: formatMessage('finopsCommon.currencyAZ'),
    },
    {
      sort: sort.term,
      onSort: (next: SortValue) => {
        setSort({ term: next });
      },
      text: formatMessage('finopsCommon.term'),
    },
    {
      sort: sort.start_at,
      onSort: (next: SortValue) => {
        setSort({ start_at: next });
      },
      text: formatMessage('common.openingDate'),
    },
    {
      sort: sort.end_at,
      onSort: (next: SortValue) => {
        setSort({ end_at: next });
      },
      text: formatMessage('finopsCommon.maturityDate'),
    },
  ];

  const sortedAndFilteredData = getSortedAndFilteredData();

  return (
    <div>
      <Text style='xxbold3048' className={styles.header}>
        {formatMessage('lend.lends')}
      </Text>
      {!isLoading ? (
        <>
          <div className={styles.subHeader}>
            <ActiveLendsCount />
            <div className={styles.sortFilter}>
              <PopupFilter filters={filterConfig} />
              <PopupSorter config={sortList} />
            </div>
          </div>

          <BoxLayout>
            {sortedAndFilteredData.map((lend) => (
              <LendBox lend={lend} key={lend.id} />
            ))}
          </BoxLayout>
          {sortedAndFilteredData.length === 0 && <NothingToDisplay />}
        </>
      ) : (
        <LoaderBox />
      )}
    </div>
  );
};
