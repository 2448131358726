import { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { get, LONG_STALE_TIME, patch, post } from 'modules/api';
import { User } from './types';
import { FiatCurrencyEnum } from 'modules/fiat';

export const USER_URL = '/user';

export const USER_QUERY_KEY = ['GET', USER_URL] as const;

export type GetUserResponseData = User;

const getUser = () => {
  return get<GetUserResponseData>(USER_URL);
};

export const useUserQuery = (
  options?: UseQueryOptions<AxiosResponse<GetUserResponseData>, AxiosError<any>>
) => {
  const query = useQuery<AxiosResponse<GetUserResponseData>, AxiosError<any>>(
    USER_QUERY_KEY,
    getUser,
    {
      staleTime: LONG_STALE_TIME,
      ...options,
    }
  );
  return query;
};

export const logout = () => post<void, void>('/logout');

export const useLogoutMutation = () => {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<{ detail?: string }>,
    void
  >(['POST', '/logout'], logout);
};

export type PatchUserPayload = {
  name?: string;
  surname?: string;
  fiat_currency?: FiatCurrencyEnum;
};

export const patchUser = (payload: PatchUserPayload) => {
  return patch<User, PatchUserPayload>(USER_URL, payload);
};

export const useUserMutation = (
  options?: UseMutationOptions<
    AxiosResponse<User>,
    AxiosError<{ detail?: string }>,
    PatchUserPayload
  >
) =>
  useMutation<
    AxiosResponse<User>,
    AxiosError<{ detail?: string }>,
    PatchUserPayload
  >(['PATCH', USER_URL], patchUser, options);
