import clsx from 'clsx';
import styles from './OverviewLayout.module.scss';

type Props = { children?: React.ReactNode; className?: string };

export const OverviewGrid: React.FC<Props> = ({ children, className }) => {
  return <div className={clsx(styles.grid, className)}>{children}</div>;
};

export const OverviewColumn: React.FC<Props> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};
