import { BackButton, Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { useNewCharityFormContext } from '../context';
import styles from './StepSummary.module.scss';

import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { formatDate } from 'common/utils/utils';
import { SelectNetworks } from './SelectNetworks';
import {
  PostCharityFormsError,
  PostCharityFormsPayload,
  companyCanHaveFiveActiveForms,
  endDateMustBeInFuture,
  fromWithThisNameAlreadyExists,
  useCharityFormsMutation,
} from 'modules/charity-forms/api';
import { Alert } from 'common/components/Alert';
import { AxiosError } from 'axios';
import { prettifyError } from 'common/utils/prettify-error';

type Props = {
  onGoNext: () => void;
  onGoBack: () => void;
};

export const StepSummary: React.FC<Props> = ({ onGoNext, onGoBack }) => {
  const formatMessage = useFormatMessage();

  const context = useNewCharityFormContext();
  const charityFormsMutation = useCharityFormsMutation();

  const create = () => {
    if (
      !context.logo ||
      !context.files ||
      !context.networkList ||
      !context.picturesIds ||
      !context.fiatCurrency
    ) {
      return;
    }

    const payload: PostCharityFormsPayload = {
      name: context.name,
      overview: context.overview,
      description: context.description,
      youtube_link: context.youtubeLink,
      youtube_link_order: context.youtubeLinkIndex,
      dark_mode: context.darkMode,
      primary_color: context.primaryColor,
      secondary_color: context.secondaryColor,
      goal_enabled: context.goalEnabled,
      end_at_enabled: context.endAtEnabled,
      goal_fiat: context.fiatCurrency,
      goal_amount: context.goalEnabled ? context.goalAmount : undefined,
      end_at: context.endAtEnabled ? context.endAt : undefined,
      pictures_logo_id: context.logo?.id,
      pictures_content_ids: context.picturesIds,
      networks_list: context.networkList,
    };

    charityFormsMutation.mutate(
      { payload },
      {
        onSuccess: () => {
          onGoNext();
        },
      }
    );
  };

  const getErrorText = (error: AxiosError<PostCharityFormsError>) => {
    if (endDateMustBeInFuture(error.response?.data)) {
      return formatMessage('charityForms.endDateMustBeInFuture');
    }

    if (fromWithThisNameAlreadyExists(error.response?.data)) {
      return formatMessage('charityForms.fromWithThisNameAlreadyExists');
    }

    if (companyCanHaveFiveActiveForms(error.response?.data)) {
      return formatMessage('charityForms.limitOfActiveCharityForms');
    }

    return prettifyError(error);
  };

  return (
    <>
      <ModalHeader showClose>
        {formatMessage('charityForms.newCharityFormSummary')}
      </ModalHeader>
      <ModalBody>
        <Text
          style='bold1422'
          inlineStyle={{ display: 'block', marginBottom: '5px' }}
        >
          {formatMessage('common.details')}
        </Text>
        {charityFormsMutation.isError && (
          <Alert
            text={getErrorText(charityFormsMutation.error)}
            style={{ marginBottom: '40px' }}
            severity={'error'}
          />
        )}
        <div className={styles.summary}>
          <Summary>
            <SummaryLabel>
              {formatMessage('charityForms.fundraiserName')}
            </SummaryLabel>
            <SummaryValue dataTest='name'>{context.name}</SummaryValue>
            {context.goalEnabled && context?.goalAmount && (
              <>
                <SummaryLabel>
                  {formatMessage('charityForms.financialFundraisingGoal')}
                </SummaryLabel>
                <SummaryValue>
                  {context.goalAmount} {context.fiatCurrency}
                </SummaryValue>
              </>
            )}
            {context.endAtEnabled && context?.endAt && (
              <>
                <SummaryLabel>
                  {formatMessage('charityForms.fundraiserEndDate')}
                </SummaryLabel>
                <SummaryValue>{formatDate(context?.endAt)}</SummaryValue>
              </>
            )}
          </Summary>
          {context.endAtEnabled && context.goalEnabled && (
            <Text style='light1215' className={styles.description}>
              {formatMessage(
                'charityForms.theEndOfTheFundraiserIsDefinedByTheEndDate'
              )}
            </Text>
          )}
        </div>
        <SelectNetworks selectable={false} networks={context.networkList} />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <Button
            name={formatMessage('common.create')}
            type='dark'
            onClick={create}
            isLoading={charityFormsMutation.isLoading}
            disabled={
              charityFormsMutation.isLoading || charityFormsMutation.isError
            }
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
