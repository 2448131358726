import {
  WalletsCreationFailedTooltip,
  useAreNoWalletsCreated,
} from 'modules/assets-and-wallets';
import { Deposit } from 'modules/deposit';
import { KyxTooltip, useIsKyxCompleted } from 'modules/kyx';
import { useFormatMessage } from 'modules/messages';
import { SetMultiapproval } from 'modules/multiapproval';
import { NotificationsMenu } from 'modules/notifications/NotificationsMenu';
import { UserMenu } from 'modules/user';
import { Withdraw } from 'modules/withdraw';
import { FeatureButton } from './components/FeatureButton';
import { useFeatureButtonsContext, useHamburgerMenuContext } from './context';
import { HamburgerMenu } from './HamburgerMenu';
import styles from './Topbar.module.scss';

type Props = {
  showFeatureButtons?: boolean; // only affects desktop, mobile (hamburger menu) will show feature buttons regardless of this value
};

export const Topbar: React.FC<Props> = ({ showFeatureButtons = true }) => {
  const formatMessage = useFormatMessage();
  const { isOpen: isHamburgerOpen, closeHamburger } = useHamburgerMenuContext();
  const { isDepositOpen, setIsDepositOpen, setIsWithdrawOpen, isWithdrawOpen } =
    useFeatureButtonsContext();

  const { data: walletsFailed } = useAreNoWalletsCreated();

  const isKyxCompleted = useIsKyxCompleted();

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {showFeatureButtons && (
          <>
            <KyxTooltip
              extraTitle={
                walletsFailed
                  ? formatMessage('common.featureCurrentlyUnavailableTryLater')
                  : ''
              }
            >
              <div>
                <FeatureButton
                  text={formatMessage('deposit')}
                  onClick={() => {
                    setIsDepositOpen(true);
                    if (isHamburgerOpen) closeHamburger();
                  }}
                  active={isDepositOpen}
                  hasBorder={true}
                  dataTest='depositBtn'
                  disabled={!isKyxCompleted || walletsFailed}
                />
              </div>
            </KyxTooltip>
            <WalletsCreationFailedTooltip>
              <div>
                <FeatureButton
                  text={formatMessage('common.withdraw')}
                  onClick={() => {
                    setIsWithdrawOpen(true);
                    if (isHamburgerOpen) closeHamburger();
                  }}
                  active={isWithdrawOpen}
                  hasBorder={false}
                  dataTest='withdrawBtn'
                  disabled={walletsFailed}
                />
              </div>
            </WalletsCreationFailedTooltip>
          </>
        )}
      </div>
      <NotificationsMenu />
      <div className={styles.userMenuWrapper}>
        <UserMenu />
      </div>
      <HamburgerMenu />
      <Deposit onClose={() => setIsDepositOpen(false)} open={isDepositOpen} />
      <Withdraw
        onClose={() => setIsWithdrawOpen(false)}
        open={isWithdrawOpen}
      />
      <SetMultiapproval />
    </div>
  );
};
