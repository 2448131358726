import clsx from 'clsx';
import styles from './SectionDoubleBoxes.module.scss';

type Props = {
  child1?: React.ReactNode;
  child2?: React.ReactNode;
};

export const SectionDoubleBoxes: React.FC<Props> = ({ child1, child2 }) => {
  return (
    <div
      className={clsx(
        styles.outerContainer,
        (!child2 || !child1) && styles.onlyOneChild
      )}
    >
      {child1 && <div className={styles.container}>{child1}</div>}
      {child2 && <div className={styles.container}>{child2}</div>}
    </div>
  );
};
