import D from 'decimal.js';
import { useFormatMessage } from 'modules/messages';
import styles from './BorrowDetailsMainBox.module.scss';
import { Text } from 'common/components/Text';
import { NetworkBox } from 'common/components/NetworkBox';
import { ReactComponent as MonayWalletIcon } from 'common/assets/grey-icons/wallet-small-grey.svg';
import { NetworkEnum } from 'common/types';
import { formatDate, formatDatetime } from 'common/utils/utils';
import { BorrowName } from '../borrow-list/components/BorrowName';
import { PrincipalAmount } from '../borrow-list/components/PrincipalAmount';
import { CollateralAmount } from '../borrow-list/components/CollateralAmount';
import { BorrowDetails } from '../types';
import { AmountBox } from '../borrow-list/components/AmountBox';
import { OpenedDate } from 'modules/common-data-fields';
import { ReactComponent as AutomaticTopupIcon } from '../assets/automatic-topup.svg';
import { Tooltip } from 'common/components/Tooltip';

type Props = { borrow: BorrowDetails };

export const BorrowDetailsMainBox: React.FC<Props> = ({ borrow }) => {
  const formatMessage = useFormatMessage();

  const {
    principal_wallet,
    principal_amount,
    principal_currency_rate,
    collateral_wallet,
    collateral_amount,
    collateral_currency_rate,
    principal_currency,
    collateral_currency,
    end_at,
    start_at,
    state,
    rate_pct,
    id,
    interest,
    cancel_at,
    auto_topup,
  } = borrow;

  const isFinished =
    state === 'LIQUIDATED' || state === 'CANCELLED' || state === 'COMPLETED';

  const isCancelled = state === 'CANCELLED';

  return (
    <div>
      <div className={styles.title}>
        <BorrowName borrow={borrow} isSmall={false} />
        {auto_topup && (
          <Tooltip title={formatMessage('borrow.automaticTopupHasBeenEnabled')}>
            <AutomaticTopupIcon style={{ cursor: 'pointer' }} />
          </Tooltip>
        )}
      </div>
      <div className={styles.detailsContainer}>
        <div>
          <PrincipalAmount
            amount={principal_amount}
            currency={principal_currency}
            currencyRate={principal_currency_rate}
            showFiat={!isFinished}
          />
          <NetworkAndWalletBox
            network={principal_wallet.network}
            name={principal_wallet.name}
          />
        </div>
        <div>
          <div>
            <Text style='light1215'>
              {`${formatMessage('borrow.amountToRepay')}:`}
            </Text>
          </div>
          <AmountBox
            amount={new D(principal_amount).add(interest).toString()}
            currency={principal_currency}
            currencyRate={principal_currency_rate}
            showFiat={!isFinished}
          />
        </div>
        <div>
          <CollateralAmount
            amount={collateral_amount}
            currency={collateral_currency}
            currencyRate={collateral_currency_rate}
            showFiat={!isFinished}
          />
          <NetworkAndWalletBox
            network={collateral_wallet.network}
            name={collateral_wallet.name}
          />
        </div>
        {isFinished ? (
          <div>
            <div>
              <Text style='light1215'>
                {`${formatMessage('common.interestRate')}:`}
              </Text>
            </div>
            <div>
              <Text style='xxbold1619' letterSpacingStyle='small'>
                {rate_pct}%
              </Text>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Text style='light1215'>
                {`${formatMessage('finopsCommon.maturity')}:`}
              </Text>
            </div>
            <Text style='xxbold1619' letterSpacingStyle='small'>
              {formatDate(end_at)}
            </Text>
          </div>
        )}
      </div>
      <div className={styles.interestContainer}>
        {!isFinished && (
          <div>
            <Text style='light1215' colorStyle='secondary'>
              {formatMessage('finopsCommon.interestRate%', { rate: rate_pct })}
            </Text>
          </div>
        )}
        <OpenedDate startAt={start_at} />
        {isFinished && !isCancelled && (
          <div>
            <Text style='light1215' colorStyle='secondary'>
              {formatMessage('common.maturityAndDate', {
                date: formatDatetime(end_at),
              })}
            </Text>
          </div>
        )}
        {isCancelled && (
          <div>
            <Text style='light1215' colorStyle='secondary'>
              {formatMessage('common.cancelledAndDate', {
                date: formatDatetime(cancel_at),
              })}
            </Text>
          </div>
        )}
        <div>
          <Text style='light1215' colorStyle='secondary'>
            {formatMessage('common.id', { id })}
          </Text>
        </div>
      </div>
    </div>
  );
};

export const NetworkAndWalletBox: React.FC<{
  network: NetworkEnum;
  name: string;
}> = ({ network, name }) => {
  return (
    <div className={styles.networkAndWallet}>
      <NetworkBox network={network} />
      <div>
        <MonayWalletIcon style={{ marginRight: '5px' }} />
        <Text style='light1215' colorStyle='secondary'>
          {name}
        </Text>
      </div>
    </div>
  );
};
