import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'common/components/button';
import { Modal } from 'common/components/modal';
import { NetworkEnum } from 'common/types';
import { displayNetwork, networkKeys } from 'common/utils/network-enum-utils';
import { prettifyError } from 'common/utils/prettify-error';
import { useFormatMessage } from 'modules/messages';
import { useIsOwner } from 'modules/user';
import { useEffect, useState } from 'react';
import {
  WhitelistedAddressResponseError,
  useWhitelistAddressPostMutation,
  WHITELISTED_ADDRESS_URL,
} from '../api';
import {
  isAddressInvalidError,
  isAddressUsedError,
  isAnyAddressError,
  isNameUsedError,
} from '../utils';
import { StepAddress } from './StepAddress';
import { StepName } from './StepName';
import { StepReview } from './StepReview';
import { StepSuccess } from './StepSuccess';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Step = 'name' | 'address' | 'review' | '2FA' | 'success' | '2FAcancel';

export const CreateWhitelistedAddress: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const [isOpen, setIsOpen] = useState(false);

  const [step, setStep] = useState<Step>('name');

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [network, setNetwork] = useState<NetworkEnum>();

  const { data: isOwner } = useIsOwner();

  const queryClient = useQueryClient();
  const whitelistedAddressMutation = useWhitelistAddressPostMutation();

  const [error2FA, setError2FA] = useState<string>();

  useEffect(() => {
    setStep('name');
    setName('');
    setAddress('');
    setNetwork(undefined);
    setError2FA(undefined);
    whitelistedAddressMutation.reset();
  }, [isOpen]);

  const getErrorText = (error: AxiosError<WhitelistedAddressResponseError>) => {
    if (isNameUsedError(error)) {
      return formatMessage('addressWhitelist.errors.nameUsed');
    }
    if (isAddressUsedError(error)) {
      return formatMessage('addressWhitelist.errors.addressUsed');
    }
    for (const n of networkKeys) {
      if (isAddressInvalidError(error, n)) {
        return formatMessage('common.invalidAddress', {
          network: displayNetwork(n),
        });
      }
    }
    return prettifyError(error);
  };

  const onVerify = (keys: string) => {
    whitelistedAddressMutation.mutate(
      {
        payload: {
          name: name.trim(),
          address: address.trim(),
          network: network!,
        },
        headers: generateHeader2FA(keys),
      },
      {
        onSuccess: () => {
          setStep('success');
          queryClient.invalidateQueries(['GET', WHITELISTED_ADDRESS_URL]);
        },
        onError: (err) => {
          setErrorOTP(err, setError2FA, formatMessage);
        },
      }
    );
  };

  const onCreate = () => {
    whitelistedAddressMutation.mutate(
      {
        payload: {
          name: name.trim(),
          address: address.trim(),
          network: network!,
        },
      },
      {
        onSuccess: () => {
          setStep('2FA');
        },
      }
    );
  };

  return (
    <>
      {isOwner && (
        <Button
          type='dark'
          name={formatMessage('common.button.add')}
          onClick={() => setIsOpen(true)}
        />
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        dataTest='addWhitelistedAddressModal'
      >
        {step === 'name' && (
          <StepName
            name={name}
            onNameChange={(value) => {
              if (
                isNameUsedError(whitelistedAddressMutation.error) &&
                value !== name
              ) {
                whitelistedAddressMutation.reset();
              }
              setName(value);
            }}
            onGoNext={() => setStep('address')}
            onCancel={() => setIsOpen(false)}
          />
        )}
        {step === 'address' && (
          <StepAddress
            address={address}
            onAddressChange={(value) => {
              if (
                isAnyAddressError(whitelistedAddressMutation.error) &&
                value !== address
              ) {
                whitelistedAddressMutation.reset();
              }
              setAddress(value);
            }}
            network={network}
            onNetworkChange={(value) => {
              if (
                isAnyAddressError(whitelistedAddressMutation.error) &&
                value !== network
              ) {
                whitelistedAddressMutation.reset();
              }
              setNetwork(value);
            }}
            onGoBack={() => setStep('name')}
            onGoNext={() => setStep('review')}
          />
        )}
        {step === 'review' && (
          <StepReview
            name={name}
            network={network!}
            address={address}
            onGoBack={() => setStep('address')}
            onCreate={onCreate}
            isLoading={whitelistedAddressMutation.isLoading}
            error={
              whitelistedAddressMutation.error
                ? getErrorText(whitelistedAddressMutation.error)
                : undefined
            }
          />
        )}
        {step === '2FA' && (
          <Module2FA
            onVerify={onVerify}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={whitelistedAddressMutation.isLoading}
            onCancel={() => setStep('2FAcancel')}
            title={formatMessage('addressWhitelist.addWhitelistedAddress')}
          />
        )}
        {step === 'success' && <StepSuccess />}
        {step === '2FAcancel' && <Cancel2FA onClose={() => setIsOpen(false)} />}
      </Modal>
    </>
  );
};
