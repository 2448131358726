import styles from './StepWithdraw.module.scss';
import { useFormatMessage } from 'modules/messages';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Currency, CurrencyWallet } from 'modules/select-currency-wallet/types';
import { WithdrawEffect } from './WithdrawEffect';
import { Saving } from '../../types';
import { Loader } from 'common/components/loader';
import { toDecimal, toFixed } from 'modules/input-amount';
import { Alert } from 'common/components/Alert';
import { SectionSubheader } from 'common/components/SectionSubheader';
import { WithdrawSlider } from './WithdrawSlider';
import { AmountInput } from '../../common';
import { Text } from 'common/components/Text';
import { AmountReadOnly } from '../../common/AmountReadOnly';
import { CancelButton, GoToSummaryButton } from 'common/components/button';
import { useSavingWithdrawContext } from '../context';

type Props = {
  amount: string;
  errorAmount: string;
  onSummary: () => void;
  currency?: Currency;
  wallets?: CurrencyWallet[];
  wallet?: CurrencyWallet;
  errorWallet: string;
  id: number;
  saving?: Saving;
  remainingFunds?: string;
  newDailyEarn?: string;
  amountInfo: string;
  remainingFundsInfo: string;
  isLoading: boolean;
  onCancel: () => void;
  onAmountChange: (amount: string) => void;
  onWalletChange: (wallet: CurrencyWallet) => void;
};

export const StepWithdraw: React.FC<Props> = ({
  amount,
  errorAmount,
  onSummary,
  wallets,
  currency,
  wallet,
  errorWallet,
  id,
  saving,
  remainingFunds,
  newDailyEarn,
  amountInfo,
  remainingFundsInfo,
  isLoading,
  onCancel,
  onAmountChange,
  onWalletChange,
}) => {
  const formatMessage = useFormatMessage();
  const context = useSavingWithdrawContext();

  return (
    <>
      <ModalHeader
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('saving.withdrawFundsFromSavingsAccount')}
          </Text>
        }
      >
        {formatMessage('common.withdrawFunds')}
      </ModalHeader>
      <ModalBody>
        {currency && wallet && saving && (
          <>
            {context.errorAlert && (
              <Alert
                text={context.errorAlert}
                severity='error'
                className={styles.alert}
              />
            )}
            <SectionSubheader>
              {formatMessage('saving.chooseWithdrawalAmount')}
            </SectionSubheader>
            <WithdrawSlider
              currentMaxToWithdraw={saving.current_max_to_withdraw}
              amount={amount}
              onChange={(value) => {
                if (
                  toDecimal(value).eq(saving.current_max_to_withdraw) &&
                  toDecimal(saving.balance).gt(saving.current_max_to_withdraw)
                ) {
                  context.setAmountInfo(
                    formatMessage(
                      'saving.dueToPendingWithdrawalTransactionsReducedMaximumAmount'
                    )
                  );
                  context.setAmount(value);
                  onAmountChange(value);
                  return;
                }

                if (toDecimal(value).gt(saving.current_max_to_withdraw)) {
                  context.setAmount(toFixed(saving.current_max_to_withdraw));
                  onAmountChange(toFixed(saving.current_max_to_withdraw));

                  context.setAmountInfo(
                    formatMessage(
                      'saving.dueToPendingWithdrawalTransactionsMaximumAmount',
                      {
                        amount: toFixed(saving.current_max_to_withdraw),
                        currency: currency?.currency,
                      }
                    )
                  );
                  return;
                }

                context.setAmountInfo('');
                context.setAmount(value);
                onAmountChange(value);
              }}
              currency={currency.currency}
              balance={saving.balance}
            />
            {!context.disabledInput ? (
              <AmountInput
                amount={amount}
                currency={currency}
                wallets={wallets}
                error={errorAmount}
                wallet={wallet}
                walletError={errorWallet}
                onAmountChange={(value) => {
                  context.setErrorAlert('');
                  context.setAmountInfo('');

                  context.setAmount(value);
                  onAmountChange(value);
                }}
                onWalletChange={(value) => {
                  context.setErrorAlert('');
                  context.setWalletId(value.id);
                  onWalletChange(value);
                }}
                onError={(error) => context.setAmountError(error)}
                info={amountInfo}
              />
            ) : (
              <AmountReadOnly
                amount={toDecimal(amount)}
                currency={currency}
                className={styles.amountAssetBox}
              />
            )}
            <WithdrawEffect
              currency={currency}
              id={id}
              wallet={wallet}
              amount={amount}
              saving={saving}
              newDailyEarn={newDailyEarn}
              remainingFunds={remainingFunds}
              remainingFundsInfo={remainingFundsInfo}
              isLoading={isLoading}
            />
          </>
        )}
        {!currency && <Loader />}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <GoToSummaryButton
            disabled={
              context.disabledInput || !!context.errorAlert || isLoading
            }
            onClick={onSummary}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
