import { createContext, useContext, useState } from 'react';
import { TransactionFilters } from '../api';

type InfiniteTransactionsContextValue = {
  page: number;
  setPage: (page: number) => void;
  filters: TransactionFilters;
  setFilters: (filters: TransactionFilters) => void;
  refetchTransactionsFlag: boolean;
  setRefetchTransactionsFlag: (value: boolean) => void;
  resetFilters: () => void;
  areAnyFiltersSelected: boolean;
};

const InfiniteTransactionsContext =
  createContext<InfiniteTransactionsContextValue>({
    page: 1,
    setPage: () => {},
    filters: {},
    setFilters: () => {},
    refetchTransactionsFlag: false,
    setRefetchTransactionsFlag: () => {},
    resetFilters: () => {},
    areAnyFiltersSelected: false,
  });

export const TransactionFiltersContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState<TransactionFilters>({});

  const [refetchTransactionsFlag, setRefetchTransactionsFlag] = useState(false);

  const areAnyFiltersSelected = Boolean(
    (filters.categories && filters.categories?.length > 0) ||
      (filters.currencies && filters.currencies?.length > 0) ||
      filters.dateFrom ||
      filters.dateTo ||
      filters.lastDays ||
      (filters.networks && filters.networks?.length > 0) ||
      (filters.savings && filters.savings?.length > 0) ||
      (filters.states && filters.states?.length > 0) ||
      (filters.types && filters.types?.length > 0) ||
      (filters.wallets && filters.wallets?.length > 0) ||
      (filters.charityForms && filters.charityForms?.length > 0) ||
      (filters.transactionKinds && filters.transactionKinds?.length > 0)
  );

  return (
    <InfiniteTransactionsContext.Provider
      value={{
        page,
        setPage,
        filters,
        setFilters,
        refetchTransactionsFlag,
        setRefetchTransactionsFlag,
        resetFilters: () => {
          setFilters({});
        },
        areAnyFiltersSelected,
      }}
    >
      {children}
    </InfiniteTransactionsContext.Provider>
  );
};

export const useInfiniteTransactionsContext = () => {
  return useContext(InfiniteTransactionsContext);
};
