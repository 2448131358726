import { Alert } from 'common/components/Alert';
import { LoaderBox } from 'common/components/loader';
import { PopupFilter } from 'common/components/table-subcomponents/filter/PopupFilter';
import { PopupSorter } from 'common/components/table-subcomponents/sort/PopupSorter';
import {
  ascCmpTerms,
  ascComp,
  descCmpTerms,
  descComp,
} from 'common/utils/comparators';
import { prettifyError } from 'common/utils/prettify-error';
import { useFiatComparators } from 'modules/fiat';
import { BoxLayout } from 'modules/financial-ops/common/components';
import { useFilters } from 'modules/financial-ops/common/hooks';
import { NothingToDisplay } from 'modules/nothing-to-display';
import * as R from 'ramda';
import { useBorrows } from '../api';
import { ActiveBorrowsCount } from './components/ActiveBorrowsCount';
import { BorrowBox } from './components/BorrowBox';
import { BorrowsHeader } from './components/BorrowsHeader';
import { useSort } from '../hooks';
import { BorrowRead } from '../types';

type Props = {};

export const Borrows: React.FC<Props> = () => {
  const { data, isLoading, error } = useBorrows();
  const borrows = data?.data || [];

  const [filter, filterConfig] = useFilters();
  const [sort, sortConfig] = useSort();

  const isCompleted = (borrow: BorrowRead) =>
    borrow.state === 'COMPLETED' ||
    borrow.state === 'LIQUIDATED' ||
    borrow.state === 'CANCELLED';

  const { ascFiatCmp, descFiatCmp } = useFiatComparators();

  const getFilteredAndSortedData = () => {
    const filteredBorrows = filter.active
      ? R.filter((item: BorrowRead) => !isCompleted(item), borrows)
      : filter.finished
      ? R.filter(isCompleted, borrows)
      : borrows;

    if (sort.principal_amount) {
      return R.sort(
        (item1, item2) =>
          (sort.principal_amount === 'asc' ? ascFiatCmp : descFiatCmp)(
            {
              amount: item1.principal_amount,
              currency: item1.principal_currency,
            },
            {
              amount: item2.principal_amount,
              currency: item2.principal_currency,
            }
          ),
        filteredBorrows
      );
    }
    if (sort.principal_currency) {
      return R.sort(
        (item1, item2) =>
          (sort.principal_currency === 'asc' ? ascComp : descComp)(
            item1.principal_currency,
            item2.principal_currency
          ),
        filteredBorrows
      );
    }
    if (sort.collateral_amount) {
      return R.sort(
        (item1, item2) =>
          (sort.collateral_amount === 'asc' ? ascFiatCmp : descFiatCmp)(
            {
              amount: item1.collateral_amount,
              currency: item1.collateral_currency,
            },
            {
              amount: item2.collateral_amount,
              currency: item2.collateral_currency,
            }
          ),
        filteredBorrows
      );
    }
    if (sort.term) {
      return R.sort(
        (item1, item2) =>
          (sort.term === 'asc' ? ascCmpTerms : descCmpTerms)(
            item1.term,
            item2.term
          ),
        filteredBorrows
      );
    }
    if (sort.start_at) {
      return R.sort(
        (item1, item2) =>
          (sort.start_at === 'asc' ? ascComp : descComp)(
            item1.start_at,
            item2.start_at
          ),
        filteredBorrows
      );
    }

    if (sort.end_at) {
      return R.sort(
        (item1, item2) =>
          (sort.end_at === 'asc' ? ascComp : descComp)(
            item1.end_at,
            item2.end_at
          ),
        filteredBorrows
      );
    }

    return filteredBorrows;
  };

  const filteredAndSortedData = getFilteredAndSortedData();

  return (
    <>
      <BorrowsHeader />
      {isLoading && <LoaderBox />}
      {error && (
        <Alert
          style={{ marginBottom: '20px' }}
          severity='error'
          text={prettifyError(error)}
        />
      )}
      {!isLoading && data && (
        <>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
              marginBottom: '40px',
            }}
          >
            <ActiveBorrowsCount />
            <PopupFilter filters={filterConfig} />
            <PopupSorter config={sortConfig} />
          </div>
          {filteredAndSortedData.length === 0 ? (
            <NothingToDisplay />
          ) : (
            <BoxLayout>
              {filteredAndSortedData.map((borrow) => (
                <BorrowBox key={borrow.id} borrow={borrow} />
              ))}
            </BoxLayout>
          )}
        </>
      )}
    </>
  );
};
