import { get } from 'modules/api';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

export const DOCUMENTS_URL = '/documents';
export const DOCUMENTS_QUERY_KEY = ['GET', DOCUMENTS_URL] as const;

export type Document = {
  kind: DocumentKind;
  url: string;
};

export type DocumentKind =
  | 'TERMS_AND_CONDITIONS'
  | 'TERMS_AND_CONDITIONS_WITH_PRIVACY_POLICY';

export const getDocuments = () => get<Document[]>(DOCUMENTS_URL);

export const useDocumentsQuery = <TData = AxiosResponse<Document[]>>(
  options: UseQueryOptions<AxiosResponse<Document[]>, AxiosError<any>, TData>
) =>
  useQuery<AxiosResponse<Document[]>, AxiosError<any>, TData>(
    DOCUMENTS_QUERY_KEY,
    getDocuments,
    options
  );

export const useDocument = (kind: DocumentKind) =>
  useDocumentsQuery({
    select: (data) =>
      data.data.filter((document) => document.kind === kind)[0].url,
  });
