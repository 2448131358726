import { PageLoader } from 'common/components/loader';
import { LendDetails } from 'modules/financial-ops/lend';
import { NavigationLayout } from 'modules/layout';
import { useLoginRequired } from 'modules/login';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const LendDetailsPage: React.FC<{}> = () => {
  const { isLoading } = useLoginRequired();

  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.lendDetails')}</title>
      </Helmet>
      {isLoading ? (
        <PageLoader />
      ) : (
        <NavigationLayout>
          <LendDetails />
        </NavigationLayout>
      )}
    </>
  );
};
