import { useState } from 'react';
import { NextButton, BackButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { MAX_INPUT_CHARACTERS_LIMIT_LONG } from 'common/consts/consts';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import { validateLengthLong } from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';

type Props = {
  onSubmit: (companyName: string) => void;
  onGoBack: () => void;
  companyName: string;
};

export const StepCompanyName: React.FC<Props> = ({
  onSubmit,
  onGoBack,
  companyName: companyNameProp,
}) => {
  const formatMessage = useFormatMessage();

  const [companyName, setCompanyName] = useState(companyNameProp);
  const [error, setError] = useState('');

  const validateAndSubmit = () => {
    const companyNameTrim = companyName.trim();
    if (!companyNameTrim) {
      setError(formatMessage('common.fieldRequired'));
      return;
    }
    if (!error) onSubmit(companyNameTrim);
  };

  return (
    <>
      <ModalHeader
        showClose={false}
        subtitle={formatMessage('signup.companyNameShouldBeRecognizable')}
      >
        {formatMessage('signup.enterCompanyName')}
      </ModalHeader>
      <ModalBody>
        <InputWithHeader
          header={formatMessage('signup.companyName')}
          value={companyName}
          onChange={(e) => {
            const data = e.target.value;
            if (!validateLengthLong(data)) {
              setError(
                formatMessage('common.error.characters.limit', {
                  limit: MAX_INPUT_CHARACTERS_LIMIT_LONG,
                })
              );
            } else {
              setError('');
            }
            setCompanyName(data);
          }}
          onEnter={validateAndSubmit}
          errorText={error}
          autofocus
          dataTest='companyNameInput'
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <NextButton onClick={validateAndSubmit} />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
