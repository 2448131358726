import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './SavingHistoryBox.module.scss';
import { Transaction } from 'modules/transactions/types';
import { BoldAmount, Date } from 'modules/common-data-fields';
import { CryptoToFiatAmount } from 'modules/fiat';
import { IconByType } from 'modules/transactions/components/details/components/IconByType';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { getSavingTransactionName } from '../ulits';

type Props = { transaction: Transaction };

export const SavingHistoryBox: React.FC<Props> = ({ transaction }) => {
  const formatMessage = useFormatMessage();
  const { amount, currency, currency_rate, kind, extra_content, type } =
    transaction;

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.header}>
          <IconByType type={type} />
          <Text style='xbold1215'>
            {getSavingTransactionName(kind, formatMessage)}
          </Text>
        </div>
        <Text style='light1215' colorStyle='secondary'>
          {formatMessage('saving.totalBalance', {
            amount: toLimitedPrec(toFixed(extra_content.balance)),
            currency,
          })}
        </Text>
      </div>
      <div className={styles.amount}>
        <BoldAmount
          amount={amount}
          currency={currency}
          textStyles={'xbold1215'}
        />
        <CryptoToFiatAmount
          amount={amount}
          currency={currency}
          currencyRate={currency_rate}
        />
      </div>
      <div>
        <Date date={transaction.created_at} withoutSec={true} />
      </div>
    </div>
  );
};
