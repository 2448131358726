import { TextStyle } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { LendsSummary } from '../../api';
import { AverageApr } from 'modules/financial-ops/common/components/overview-components';

type Props = {
  lendSummary?: LendsSummary;
  isLoading?: boolean;
  textStyle?: TextStyle;
};

export const AverageLendingApr: React.FC<Props> = ({
  lendSummary,
  isLoading,
  textStyle = 'xxbold3048',
}) => {
  const formatMessage = useFormatMessage();

  return (
    <AverageApr
      rate={lendSummary?.current_avg_rate_pct}
      isLoading={isLoading}
      text={formatMessage('lend.averageLendingApr')}
      textStyle={textStyle}
    />
  );
};
