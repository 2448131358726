import clsx from 'clsx';
import { textColorStyles, textStyles } from 'common/components/Text';
import { LoaderBox } from 'common/components/loader';
import { useFormatMessage } from 'modules/messages';
import { PDFDocumentProxy, getDocument } from 'pdfjs-dist';
import { useEffect, useRef, useState } from 'react';
import styles from './SignupTermsConditionsViewer.module.scss';
import { debounce } from 'lodash';
import { useDocument } from '../api';
import { NothingToDisplay } from 'modules/nothing-to-display';

type Props = {
  onFinishedReading: () => void;
};

export const SignupTermsConditionsViewer: React.FC<Props> = ({
  onFinishedReading,
}) => {
  const canvasContainer = useRef<HTMLDivElement>(null);
  const lastItem = useRef<HTMLDivElement>(null);
  const intersectionObserver = useRef<IntersectionObserver>();
  const resizeObserver = useRef<ResizeObserver>();
  const isRendering = useRef<boolean>(false);
  const prevCanvasContainerWidth = useRef<number>();

  const [isLoading, setIsLoading] = useState(false);

  const { data: url, isLoading: isLoadingDocumentUrl } = useDocument(
    'TERMS_AND_CONDITIONS_WITH_PRIVACY_POLICY'
  );

  const renderDocument = async () => {
    if (!url) {
      return;
    }

    setIsLoading(true);
    isRendering.current = true;

    const container = canvasContainer.current!;
    container.replaceChildren();

    const document = await getDocument(url).promise;
    for (let pageNum = 1; pageNum <= document.numPages; pageNum++) {
      await renderPage(document, pageNum);
    }

    setIsLoading(false);
    isRendering.current = false;
  };

  const renderPage = async (doc: PDFDocumentProxy, pageNum: number) => {
    const page = await doc.getPage(pageNum);
    const viewport = page.getViewport({ scale: 2 });

    const container = canvasContainer.current!;
    const canvas = document.createElement('canvas');

    canvas.width = viewport.width;
    canvas.height = viewport.height;
    container.appendChild(canvas);

    const context = canvas.getContext('2d')!;

    var renderContext = {
      canvasContext: context,
      viewport: viewport,
    };
    await page.render(renderContext).promise;
    canvas.style.width = `${Math.min(window.innerWidth - 40, 635)}px`;
  };

  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current || !url) {
      return;
    }
    initialized.current = true;
    initialize();
  }, [url]);

  const initialize = async () => {
    await renderDocument();

    intersectionObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isRendering.current) {
        onFinishedReading();
      }
    });
    intersectionObserver.current.observe(lastItem.current!);

    resizeObserver.current = new ResizeObserver(
      debounce((entries) => {
        const cr = entries[0].contentRect;
        if (cr.width === 0 || cr.width === prevCanvasContainerWidth.current) {
          return;
        }
        if (prevCanvasContainerWidth.current === undefined) {
          prevCanvasContainerWidth.current = cr.width;
          return;
        }
        prevCanvasContainerWidth.current = cr.width;
        renderDocument();
      }, 200)
    );
    resizeObserver.current.observe(canvasContainer.current!);
  };

  return (
    <>
      {(isLoading || isLoadingDocumentUrl) && (
        <div className={styles.loaderContainer}>
          <LoaderBox height='100%' />
        </div>
      )}
      <div className={clsx(styles.container, isLoading && styles.hidden)}>
        <div
          ref={canvasContainer}
          className={clsx(isLoading && styles.hidden)}
        />
        {url && <SignupTermsConditionsLink url={url} />}
        <div className={styles.lastItem} ref={lastItem} data-test='endOfFile' />
      </div>
      {!url && !isLoadingDocumentUrl && <NothingToDisplay />}
    </>
  );
};

const SignupTermsConditionsLink: React.FC<{ url: string }> = ({ url }) => {
  const formatMessage = useFormatMessage();

  return (
    <span
      className={textStyles.light1425}
      dangerouslySetInnerHTML={{
        __html: formatMessage('signup.downloadPdfTermsConditions', {
          termsAndConditions: `<a class="${clsx(
            styles.termsConditionsLink,
            textStyles.light1425,
            textColorStyles.primary
          )}" href=${url} download target="_blank">${formatMessage(
            'common.termsAndConditions'
          )}</a>`,
        }),
      }}
    />
  );
};
