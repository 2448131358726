import { useFeatureFlag } from 'modules/feature-flags';
import { useBorrowsSummaryQuery } from 'modules/financial-ops/borrow/api';
import { useLendsSummaryQuery } from 'modules/financial-ops/lend/api';
import { useBalanceQuery } from './api';
import { useSavingsSummaryQuery } from 'modules/financial-ops/savings-account/api';

export const useBalanceSummaryData = () => {
  const {
    data: lendFeatureFlag,
    isLoading: isLendFeatureFlagLoading,
    isError: isLendFeatureFlagError,
  } = useFeatureFlag('LEND');

  const {
    data: borrowFeatureFlag,
    isLoading: isBorrowFeatureFlagLoading,
    isError: isBorrowFeatureFlagError,
  } = useFeatureFlag('BORROW');

  const {
    data: savingFeatureFlag,
    isLoading: isSavingFeatureFlagLoading,
    isError: isSavingFeatureFlagError,
  } = useFeatureFlag('SAVING');

  const {
    data: lendsSummary,
    isLoading: isLendsSummaryLoading,
    isError: isLendsSummaryError,
  } = useLendsSummaryQuery({ enabled: !!lendFeatureFlag?.enabled });

  const {
    data: borrowsSummary,
    isLoading: isBorrowsSummaryLoading,
    isError: isBorrowsSummaryError,
  } = useBorrowsSummaryQuery();

  const {
    data: savingsSummary,
    isLoading: isSavingsSummaryLoading,
    isError: isSavingsSummaryError,
  } = useSavingsSummaryQuery();

  const {
    data: balance,
    isLoading: isBalanceLoading,
    isError: isBalanceError,
  } = useBalanceQuery();

  const isFeatureFlagsLoading =
    isLendFeatureFlagLoading ||
    isBorrowFeatureFlagLoading ||
    isSavingFeatureFlagLoading;

  const isError =
    isLendFeatureFlagError ||
    isBorrowFeatureFlagError ||
    isSavingFeatureFlagError ||
    isLendsSummaryError ||
    isSavingsSummaryError ||
    isBorrowsSummaryError ||
    isBalanceError;

  return {
    // data
    lendFeatureFlag,
    borrowFeatureFlag,
    savingFeatureFlag,
    lendsSummary,
    borrowsSummary,
    savingsSummary,
    balance,
    // loading flags
    isSavingFeatureFlagLoading,
    isLendFeatureFlagLoading,
    isBorrowFeatureFlagLoading,
    isLendsSummaryLoading,
    isBorrowsSummaryLoading,
    isSavingsSummaryLoading,
    isBalanceLoading,
    isFeatureFlagsLoading,
    // error flags
    isError,
  };
};
