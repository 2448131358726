import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { get, post } from 'modules/api';
import {
  Transaction,
  TransactionDetails,
  TransactionActionRead,
  TransactionUserAction,
  TransactionActionEnum,
  TransactionKind,
} from './types';
import { PAGE_SIZE } from 'common/consts/consts';
import { getDateByDays } from './helpers';

// URLs
export const TRANSACTIONS_URL = '/transactions';

export const TRANSACTION_ACTION_URL = '/transaction-action'; // perfom action URL

export const TRANSACTION_REQUIRING_ACTION_COUNT_URL =
  '/transactions/require-action-count';

export const TRANSACTION_DETAILS_URL = '/transactions/{id}';

export const TRANSACTION_ACTIONS_URL = '/transactions/{id}/actions'; // get list of actions URL

export const TRANSACTION_EXPORT_URL = '/transactions/export';

// query keys
export const TRANSACTION_ACTION_QUERY_KEY = ['GET', TRANSACTION_ACTION_URL];

export const DASHBOARD_TRANSACTIONS_QUERY_KEY = [
  'GET',
  TRANSACTIONS_URL,
  'DASHBOARD',
] as const;

export const TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY = [
  'GET',
  TRANSACTION_REQUIRING_ACTION_COUNT_URL,
] as const;

export const PAGINATED_TRANSACTIONS_QUERY_KEY = [
  'GET_PAGINATE',
  TRANSACTIONS_URL,
] as const;

export const TRANSACTION_EXPORT_KEY = ['GET', TRANSACTION_EXPORT_URL] as const;

export const getTransactionDetailsQueryKey = (id?: number | string) =>
  ['GET', TRANSACTION_DETAILS_URL, `${id}`] as const;

export const getTransactionActionsQueryKey = (id?: number | string) =>
  ['GET', TRANSACTION_ACTIONS_URL, `${id}`] as const;

export type TransactionsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Transaction[];
};

export const getDashboardTransactions = async () => {
  const response = await get<TransactionsResponse>(
    `${TRANSACTIONS_URL}?page=1&page_size=${PAGE_SIZE}&sort=-requires_action,-created_at`
  );
  return response.data.results;
};

export const useDashboardTransactionsQuery = () => {
  const query = useQuery<Transaction[]>(
    DASHBOARD_TRANSACTIONS_QUERY_KEY,
    getDashboardTransactions
  );
  return query;
};

export type TransactionFilters = {
  categories?: string[];
  currencies?: string[];
  dateFrom?: string;
  dateTo?: string;
  lastDays?: number;
  networks?: string[];
  savings?: number[];
  states?: string[];
  types?: string[];
  wallets?: number[];
  charityForms?: number[];
  transactionKinds?: TransactionKind[];
};

export const getTransactionsInfinite = async (
  pageParam = 1,
  filters: TransactionFilters
) => {
  const params = buildTransactionsInfiniteParams(pageParam, filters);
  const response = await get<TransactionsResponse>(
    `${TRANSACTIONS_URL}?${params}`
  );
  return response.data;
};

export const useTransactionsInfiniteQuery = (filters: TransactionFilters) =>
  useInfiniteQuery<TransactionsResponse, AxiosError<any>>(
    PAGINATED_TRANSACTIONS_QUERY_KEY,
    ({ pageParam }) => getTransactionsInfinite(pageParam, filters)
  );

export type TransactionsRequiringAction = {
  count: number;
};

export const getTransactionsRequiringActionCount = async () => {
  const response = await get<TransactionsRequiringAction>(
    TRANSACTION_REQUIRING_ACTION_COUNT_URL
  );
  return response.data;
};

export const useTransactionsRequiringActionCountQuery = (
  options?: UseQueryOptions<TransactionsRequiringAction>
) => {
  const query = useQuery<TransactionsRequiringAction>(
    TRANSACTION_REQUIRING_ACTION_COUNT_QUERY_KEY,
    getTransactionsRequiringActionCount,
    {
      ...options,
    }
  );
  return query;
};

export const getTransactionDetails = async (id?: number | string) => {
  const response = await get<TransactionDetails>(
    TRANSACTION_DETAILS_URL.replace('{id}', `${id}`)
  );
  return response.data;
};

export const useTransactionDetailsQuery = (
  id?: number | string,
  options?: UseQueryOptions<TransactionDetails>
) =>
  useQuery<TransactionDetails>(
    getTransactionDetailsQueryKey(id),
    () => getTransactionDetails(id),
    options
  );

export const getTransactionActions = async (id?: number | string) => {
  const response = await get<TransactionActionRead[]>(
    TRANSACTION_ACTIONS_URL.replace('{id}', `${id}`)
  );
  return response.data;
};

export const useTransactionActionsQuery = (
  id?: number | string,
  options?: UseQueryOptions<TransactionActionRead[]>
) =>
  useQuery<TransactionActionRead[]>(
    getTransactionActionsQueryKey(id),
    () => getTransactionActions(id),
    options
  );

export type TransactionActionPayload = {
  transaction_id: number;
  action: TransactionActionEnum;
};

export type TransactionActionResponse = {
  detail: string;
};

export type TransactionActionError = {
  detail?: string;
  transaction_id?: number[];
  action?: TransactionUserAction[];
  non_field_errors?: string[];
  status?: string;
};

export const transactionAction = (
  payload: TransactionActionPayload,
  headers?: AxiosRequestHeaders
) =>
  post<TransactionActionResponse, TransactionActionPayload>(
    TRANSACTION_ACTION_URL,
    payload,
    { headers }
  );

type TransactionActionRequest = {
  payload: TransactionActionPayload;
  headers?: AxiosRequestHeaders;
};

export const useTransactionActionMutation = () => {
  const mutation = useMutation<
    AxiosResponse<TransactionActionResponse>,
    AxiosError<TransactionActionError>,
    TransactionActionRequest
  >(TRANSACTION_ACTION_QUERY_KEY, ({ payload, headers }) => {
    return transactionAction(payload, headers);
  });
  return mutation;
};

export const getTransactionExport = (filters: TransactionFilters) => {
  const params = buildFilterParams(filters);
  return get<string>(`${TRANSACTION_EXPORT_URL}?${params}`);
};

// not really mutation, because it's not a POST request
export const useTransactionExportMutation = () =>
  useMutation<AxiosResponse<string>, AxiosError<any>, TransactionFilters>(
    TRANSACTION_EXPORT_KEY,
    (filters) => getTransactionExport(filters)
  );

export const buildTransactionsInfiniteParams = (
  page: number,
  filters: TransactionFilters
) => {
  const params = buildFilterParams(filters);
  params.append('page', `${page}`);
  params.append('page_size', `${PAGE_SIZE}`);
  params.append('sort', '-created_at');
  return params;
};

const buildFilterParams = (filters: TransactionFilters) => {
  const params = new URLSearchParams();

  if (filters?.lastDays) {
    params.append('created_at__gte', getDateByDays(filters.lastDays) ?? '');
  }

  if (filters?.dateFrom) {
    params.append('created_at__gte', filters.dateFrom);
  }

  if (filters?.dateTo) {
    const to = new Date(new Date(filters?.dateTo).setHours(24));
    params.append('created_at__lte', to.toISOString());
  }

  if (filters?.categories) {
    params.append('category__in', filters.categories.toString());
  }

  if (filters?.currencies) {
    params.append('currency__in', filters.currencies.toString());
  }

  if (filters?.states) {
    params.append('state__in', filters?.states.toString());
  }

  if (filters?.networks) {
    params.append('wallet__network__in', filters.networks.toString());
  }

  if (filters?.wallets) {
    params.append('wallet__id__in', filters.wallets.toString());
  }

  if (filters?.types) {
    params.append('type__in', filters.types.toString());
  }

  if (filters?.savings) {
    params.append('saving__id__in', filters.savings.toString());
  }

  if (filters?.charityForms) {
    params.append('charity_form__id__in', filters.charityForms.toString());
  }

  if (filters?.transactionKinds) {
    params.append('kind__in', filters.transactionKinds.toString());
  }

  return params;
};
