import { Text } from 'common/components/Text';
import { CurrencyEnum } from 'common/types';
import { CurrencyRate } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import { AmountBox } from './AmountBox';

type Props = {
  amount: string;
  currency: CurrencyEnum;
  currencyRate: CurrencyRate;
  showFiat: boolean;
};

export const CollateralAmount: React.FC<Props> = ({
  amount,
  currency,
  currencyRate,
  showFiat = true,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <div>
        <Text style='light1215'>
          {formatMessage('common.collateral') + ':'}
        </Text>
      </div>
      <AmountBox
        amount={amount}
        currency={currency}
        currencyRate={currencyRate}
        showFiat={showFiat}
        dataTest='collateralAmount'
      />
    </>
  );
};
