import { useCallback, useEffect, useState } from 'react';

import { generateLimitSendingEmailError } from 'common/utils/utils';
import { Button } from 'common/components/button';
import { Modal } from 'common/components/modal';
import { InviteMembersUserData } from './InviteMembersUserData';
import { InviteMembersInvitationSent } from './InviteMembersInvitationSent';
import { useFormatMessage } from 'modules/messages';
import { useIsOwner } from 'modules/user';
import {
  ERR_LIMIT_SENDING_INVITATION_AGAIN,
  INVITATIONS_QUERY_KEY,
  InvitePayload,
  InviteResponseError,
  useDeletedMembersQuery,
  useInvitationsQuery,
  useInviteMutation,
} from '../api';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { AccountRecovery } from './AccountRecovery';
import { KyxTooltip, useIsKyxCompleted } from 'modules/kyx';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';

type Steps = 'setData' | 'recover' | '2FA' | '2FAcancel';

export const InviteMembers = () => {
  const formatMessage = useFormatMessage();

  const isKyxCompleted = useIsKyxCompleted();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useInviteMutation();

  const { data: isOwner } = useIsOwner();
  const { data: invitations } = useInvitationsQuery();

  const [isInvitationSentModalOpen, setIsInvitationSentModalOpen] =
    useState(false);

  // name and surname here is only for the purpose of displaying them on Invitation sent confirmation modal
  const [name, setName] = useState('');
  const [surname, setSurame] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [prevName, setPrevName] = useState('');
  const [prevSurname, setPrevSurname] = useState('');

  const [error2FA, setError2FA] = useState<string>();
  const [step, setStep] = useState<Steps>('setData');

  const [isOpen, setIsOpen] = useState(false);

  const { data: deletedMembers } = useDeletedMembersQuery();

  const [error, setError] = useState<
    AxiosError<InviteResponseError> | string
  >();

  useEffect(() => {
    setStep('setData');
    setError(undefined);
    setError2FA(undefined);
  }, [isOpen]);

  const onInvite = ({
    name,
    surname,
    email,
    message,
  }: {
    name: string;
    surname: string;
    email: string;
    message: string;
  }) => {
    setName(name);
    setSurame(surname);
    setEmail(email);
    setMessage(message);

    const memberAlreadyInvited = (invitations?.data ?? []).find(
      (member) => member.email === email
    );

    if (memberAlreadyInvited) {
      setError(formatMessage('common.userAlreadyInvited'));
      return;
    }

    const payload: InvitePayload = {
      email,
      name,
      surname,
      message:
        message || formatMessage('inviteMembers.input.message.placeholder'),
    };

    mutate(
      { payload },
      {
        onSuccess: () => {
          const foundDeletedMember = (deletedMembers?.data ?? []).filter(
            (member) => member.email === email
          );
          if (deletedMembers && foundDeletedMember.length > 0) {
            setPrevName(foundDeletedMember[0].name);
            setPrevSurname(foundDeletedMember[0].surname);
            setStep('recover');
          } else {
            setStep('2FA');
          }
        },
        onError: (err) => {
          if (
            err.response?.data.detail?.startsWith(
              ERR_LIMIT_SENDING_INVITATION_AGAIN
            )
          ) {
            setError(
              generateLimitSendingEmailError(
                err.response?.data.detail,
                formatMessage('companyMembers.errorLimitSendingEmails'),
                formatMessage
              )
            );
            return;
          }
          setError(err);
        },
      }
    );
  };

  const onVerify = (keys: string) => {
    const payload: InvitePayload = {
      email,
      name,
      surname,
      message:
        message || formatMessage('inviteMembers.input.message.placeholder'),
    };

    mutate(
      { payload, headers: generateHeader2FA(keys) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['GET', INVITATIONS_QUERY_KEY]);
          setIsOpen(false);
          setIsInvitationSentModalOpen(true);
        },
        onError: (err) => {
          setErrorOTP(err, setError2FA, formatMessage);
        },
      }
    );
  };

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (!isKyxCompleted) {
      closeModal();
    }
  }, [isKyxCompleted, closeModal]);

  return (
    <>
      {isOwner && (
        <KyxTooltip>
          <span>
            <Button
              name={formatMessage('companyMembers.invite')}
              onClick={() => setIsOpen(true)}
              type={'dark'}
              disabled={!isKyxCompleted}
            />
          </span>
        </KyxTooltip>
      )}
      <Modal isOpen={isOpen} onClose={closeModal} dataTest='inviteMemberModal'>
        {step === 'setData' && (
          <InviteMembersUserData
            onClick={onInvite}
            error={error}
            isLoading={isLoading}
            onCancel={closeModal}
          />
        )}
        {step === 'recover' && (
          <AccountRecovery
            newName={name}
            newSurname={surname}
            email={email}
            onRecover={() => setStep('2FA')}
            prevName={prevName}
            prevSurname={prevSurname}
          />
        )}
        {step === '2FA' && (
          <Module2FA
            onVerify={onVerify}
            onError={(err) => setError2FA(err)}
            error={error2FA}
            isLoading={isLoading}
            onCancel={() => setStep('2FAcancel')}
            title={formatMessage('inviteMember')}
          />
        )}
        {step === '2FAcancel' && <Cancel2FA onClose={closeModal} />}
      </Modal>
      <Modal
        isOpen={isInvitationSentModalOpen}
        onClose={() => setIsInvitationSentModalOpen(false)}
      >
        <InviteMembersInvitationSent name={name} surname={surname} />
      </Modal>
    </>
  );
};
