import clsx from 'clsx';
import { Topbar, SidebarDesktop } from 'modules/navigation';
import { useState } from 'react';
import styles from './ExpandableSidebarNavigationLayout.module.scss';
import { MontesauriLogotype } from './MontesauriLogotype';
import { ReactComponent as ArrowIcon } from './assets/double-arrow.svg';

type Props = {
  children?: React.ReactNode;
};

export const ExpandableSidebarNavigationLayout: React.FC<Props> = ({
  children,
}) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  return (
    <>
      <div className={styles.topContainer} data-test='topBanner'>
        <MontesauriLogotype path={'/dashboard'} />
        <Topbar />
      </div>
      <div className={styles.bottomContainer}>
        <div
          className={clsx(
            styles.sidebarContainer,
            sidebarExpanded && styles.sidebarExpanded
          )}
        >
          <SidebarDesktop />
        </div>
        <div
          className={styles.sidebarArrowContainer}
          onClick={() => setSidebarExpanded((prev) => !prev)}
        >
          <ArrowIcon className={clsx(sidebarExpanded && styles.sidebarArrow)} />
        </div>
        <div
          className={clsx(
            styles.mainContainer,
            sidebarExpanded && styles.mainContainerSidebarExpanded
          )}
          onClick={() => {
            const sidebarArrow = document.getElementsByClassName(
              styles.sidebarArrowContainer
            )[0];
            if (!sidebarArrow) {
              // this should not happen.
              // if it happens, possibly HTML/CSS details of this component changed and this handler should also be adjusted.
              return;
            }
            const isSidebarArrowVisible =
              globalThis.getComputedStyle(sidebarArrow).display !== 'none';
            if (!isSidebarArrowVisible) {
              return;
            }
            setSidebarExpanded(false);
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};
