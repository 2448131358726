import { ReactComponent as BookOpen } from 'common/assets/book-open.svg';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import styles from './NothingToDisplay.module.scss';

type Props = {
  text?: string;
  size?: number;
  iconSize?: number;
};

export const NothingToDisplay: React.FC<Props> = ({
  text,
  size = 300,
  iconSize = 90,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div
      className={styles.container}
      style={{
        height: `${size}px`,
      }}
      data-test='nothingToDisplay'
    >
      <BookOpen height={`${iconSize}px`} width={`${iconSize}px`} />
      <Text style='bold1622' colorStyle='light' className={styles.text}>
        {typeof text === 'string' ? text : formatMessage('nothingToDisplay')}
      </Text>
    </div>
  );
};
