import { LoaderBox } from 'common/components/loader';
import { SectionHeader } from 'common/components/SectionHeader';
import { useWallets } from 'modules/assets-and-wallets/api';
import { useFeatureFlag } from 'modules/feature-flags';
import {
  ConfigSection,
  NewOperationLayout,
  SummarySection,
} from 'modules/financial-ops/common/components';
import { AssetAmountInput } from 'modules/financial-ops/common/components/AssetAmountInput';
import { useFormatMessage } from 'modules/messages';
import { BaseCurrency } from 'modules/select-currency-wallet/types';
import { useCallback, useEffect, useState } from 'react';
import { BuySellButtons } from './BuySellButtons';
import styles from './NewTrade.module.scss';
import {
  amountMustLessError,
  amountMustGreaterError,
  ensureMaxDecimalPlaces,
  TradesCalculationBase,
  useGetExchangeInstrumentLimitQuery,
  useGetExchangeInstrumentsQuery,
  EXCHANGE_INSTRUMENT_LIMITS_KEY,
  useTradeEffectMutation,
  TradesEffectPayload,
} from '../api';
import { TradeOptions } from './types';
import {
  getLimitsForOption,
  getUniqueMainNetworks,
  getUniquePairNetworks,
} from './utils';
import { AssetAmountInputReadOnly } from 'modules/financial-ops/common/components/AssetAmountInputReadOnly';
import {
  findInstrumentCurrency,
  getHighestBalanceWallet,
} from 'modules/financial-ops/common';
import { TradeEffectCalculator } from './TradeEffectCalculator';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { NewTradeModal } from './NewTradeModal';
import { openSnackbar } from 'common/components/snackbar';
import { TOLERANCE_INITIAL_VALUE } from './PriceToleranceBox';
import { useQueryClient } from '@tanstack/react-query';
import { KyxAlert, useIsKyxCompleted } from 'modules/kyx';
import { useAreNoWalletsCreated } from 'modules/assets-and-wallets';
import Decimal from 'decimal.js';
import { prettifyError } from 'common/utils/prettify-error';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';

export const NewTrade: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const queryClient = useQueryClient();

  const newTradeFeatureFlag = useFeatureFlag('CREATING_TRADE');
  const tradeEffectMutation = useTradeEffectMutation();

  const [option, setOption] = useState<TradeOptions>('SELL');

  const [spendAmount, setSpendAmount] = useState(INITIAL_AMOUNT);
  const [spendError, setSpendError] = useState('');
  const [spendWalletError, setSpendWalletError] = useState('');
  const [spendInfo, setSpendInfo] = useState('');

  const [receiveAmount, setReceiveAmount] = useState(INITIAL_AMOUNT);
  const [receiveError, setReceiveError] = useState('');
  const [receiveWalletError, setReceiveWalletError] = useState('');
  const [limitPrice, setLimitPrice] = useState('');
  const [orderPrice, setOrderPrice] = useState('');
  const [tolerance, setTolerance] = useState(TOLERANCE_INITIAL_VALUE);
  const [calculationBase, setCalculationBase] =
    useState<TradesCalculationBase>('CURRENCY_1');

  // UGLY HACK WARNING!!! Count how many times Max button was clicked.
  // Use it to make sure new request to /effect api is send on every click
  const [maxCount, setMaxCount] = useState<number>(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [receiveWalletKey, setReceiveWalletKey] = useState<
    number | undefined
  >();
  const [spendWalletKey, setSpendWalletKey] = useState<number | undefined>();
  const [spendCurrencyKey, setSpendCurrencyKey] = useState<
    BaseCurrency | undefined
  >();
  const [receiveCurrencyKey, setReceiveCurrencyKey] = useState<
    BaseCurrency | undefined
  >();

  const isBuy = option === 'BUY';
  const isSpendAmountChanged =
    (calculationBase === 'CURRENCY_2' && isBuy) ||
    (calculationBase === 'CURRENCY_1' && !isBuy);

  const { data: wallets, isLoading: isWalletsLoading } = useWallets();
  const { data: instruments, isLoading } = useGetExchangeInstrumentsQuery();
  const { data: walletsFailed } = useAreNoWalletsCreated();

  const featureUnavailable =
    walletsFailed || !newTradeFeatureFlag.data?.enabled;

  const spendCurrencies = !isBuy
    ? getUniqueMainNetworks(instruments?.data, wallets).filter(
        (curr) => curr.wallets.length > 0
      )
    : getUniquePairNetworks(
        instruments?.data,
        receiveCurrencyKey?.network,
        wallets
      ).filter((curr) => curr.wallets.length > 0);

  const receiveCurrencies = isBuy
    ? getUniqueMainNetworks(instruments?.data, wallets).filter(
        (curr) => curr.wallets.length > 0
      )
    : getUniquePairNetworks(
        instruments?.data,
        spendCurrencyKey?.network,
        wallets
      ).filter((curr) => curr.wallets.length > 0);

  const receiveCurrency = receiveCurrencies?.find(
    (curr) =>
      curr.currency === receiveCurrencyKey?.currency &&
      curr.network === receiveCurrencyKey?.network
  );

  const spendCurrency = spendCurrencies?.find(
    (curr) =>
      curr.currency === spendCurrencyKey?.currency &&
      curr.network === spendCurrencyKey?.network
  );

  const pairId = !isBuy
    ? spendCurrency?.pairs.find(
        (pair) => pair.currency === receiveCurrencyKey?.currency
      )?.id
    : receiveCurrency?.pairs.find(
        (pair) => pair.currency === spendCurrencyKey?.currency
      )?.id;

  const { isLoading: isLoadingLimits, refetch: refetchInstrumentLimits } =
    useGetExchangeInstrumentLimitQuery(pairId, tolerance);

  const receiveWallet = receiveWalletKey
    ? (receiveCurrency?.wallets || []).find(
        (val) => val.id === receiveWalletKey
      )
    : getHighestBalanceWallet(receiveCurrency?.wallets);

  const spendWallet = spendWalletKey
    ? (spendCurrency?.wallets || []).find((val) => val.id === spendWalletKey)
    : getHighestBalanceWallet(spendCurrency?.wallets);

  const findPair = (
    isBuy ? receiveCurrency?.pairs ?? [] : spendCurrency?.pairs ?? []
  ).find((pair) =>
    isBuy
      ? pair.currency === spendCurrencyKey?.currency
      : pair.currency === receiveCurrencyKey?.currency
  );

  const lotSize = toDecimal(findPair?.lot_size ?? 0)
    .toFixed()
    .split('.')[1]?.length;

  useEffect(() => {
    if (lotSize) {
      if (isBuy && receiveAmount) {
        setReceiveAmount((prev) =>
          toDecimal(toLimitedPrec(toFixed(prev, lotSize))).toFixed()
        );
      }

      if (!isBuy && spendAmount) {
        setSpendAmount((prev) =>
          toDecimal(toLimitedPrec(toFixed(prev, lotSize))).toFixed()
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotSize]);

  const continueToSummary = () => {
    if (!spendCurrency) {
      setSpendError(formatMessage('trade.spendCurrencyIsNotSelected'));
      return;
    }

    if (!receiveCurrency) {
      setReceiveError(formatMessage('trade.receiveCurrencyIsNotSelected'));
      return;
    }

    if (!spendWallet) {
      setSpendWalletError(formatMessage('trade.spendWalletNotSelected'));
      return;
    }

    if (!receiveWallet) {
      setReceiveWalletError(formatMessage('trade.receiveWalletNotSelected'));
      return;
    }

    if (toDecimal(spendAmount).eq(0)) {
      setSpendError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }
    if (toDecimal(receiveAmount).eq(0)) {
      setReceiveError(
        formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
      );
      return;
    }

    if (spendError || receiveError) {
      return;
    }

    if (toDecimal(spendAmount).gt(spendWallet.balance)) {
      openSnackbar(formatMessage('trade.insufficientSpendFunds'), 'warning');
      return;
    }

    getInitialToken();
  };

  const getInitialToken = () => {
    if (!spendCurrency || !receiveCurrency || !pairId) {
      return;
    }

    let payload: TradesEffectPayload = {
      exchange_instrument_id: pairId,
      side: option,
      calculation_base: calculationBase,
      price_tolerance_pct: tolerance,
      summary: true,
      amount: isSpendAmountChanged ? spendAmount : receiveAmount,
    };

    tradeEffectMutation.mutate(payload, {
      onSuccess: (response) => {
        setLimitPrice(response.data.limit_price);
        setReceiveError('');
        setSpendError('');

        setIsModalOpen(true);
      },
      onError: (error) => {
        queryClient.invalidateQueries(
          EXCHANGE_INSTRUMENT_LIMITS_KEY(pairId, tolerance)
        );

        const amountMustLessMatch = amountMustLessError(error.response?.data);
        if (amountMustLessMatch) {
          if (!isSpendAmountChanged) {
            setReceiveError(
              formatMessage('common.amountMustBeLessOrEqual', {
                amount: toFixed(amountMustLessMatch[1]),
                currency: receiveCurrency?.currency,
              })
            );
          }
          if (isSpendAmountChanged) {
            setSpendError(
              formatMessage('common.amountMustBeLessOrEqual', {
                amount: toFixed(amountMustLessMatch[1]),
                currency: spendCurrency?.currency,
              })
            );
          }
          return;
        }

        const amountMustGreaterMatch = amountMustGreaterError(
          error.response?.data
        );
        if (amountMustGreaterMatch) {
          if (!isSpendAmountChanged) {
            setReceiveError(
              formatMessage('trade.amountMustGreater', {
                amount: toFixed(amountMustGreaterMatch[1]),
                currency: receiveCurrency?.currency,
              })
            );
          }
          if (isSpendAmountChanged) {
            setSpendError(
              formatMessage('trade.amountMustGreater', {
                amount: toFixed(amountMustGreaterMatch[1]),
                currency: spendCurrency?.currency,
              })
            );
          }
          return;
        }

        const ensureMaxDecimalPlacesMatch = ensureMaxDecimalPlaces(
          error.response?.data
        );
        if (ensureMaxDecimalPlacesMatch) {
          if (!isSpendAmountChanged) {
            setReceiveError(
              formatMessage('trade.ensureNoMoreThanXDecimalPlaces', {
                decimal: ensureMaxDecimalPlacesMatch[1],
              })
            );
          }

          if (isSpendAmountChanged) {
            setSpendError(
              formatMessage('trade.ensureNoMoreThanXDecimalPlaces', {
                decimal: ensureMaxDecimalPlacesMatch[1],
              })
            );
          }
          return;
        }

        setSpendError(prettifyError(error));
      },
    });
  };

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const isKyxCompleted = useIsKyxCompleted();

  useEffect(() => {
    if (!isKyxCompleted) {
      closeModal();
    }
  }, [isKyxCompleted, closeModal]);

  useEffect(() => {
    if (!newTradeFeatureFlag.data?.enabled) {
      closeModal();
    }
  }, [newTradeFeatureFlag.data?.enabled, closeModal]);

  if (newTradeFeatureFlag.isLoading) {
    return <LoaderBox />;
  }

  return (
    <NewOperationLayout>
      <ConfigSection className={styles.configSection}>
        <SectionHeader header={formatMessage('trade.newTrade')} />
        <BuySellButtons
          className={styles.optionBtns}
          readOnly={featureUnavailable}
          onChange={(option) => {
            setOption(option);
            setSpendAmount(INITIAL_AMOUNT);
            setReceiveAmount(INITIAL_AMOUNT);
            setLimitPrice(INITIAL_AMOUNT);
            setOrderPrice(INITIAL_AMOUNT);
            setSpendWalletKey(undefined);
            setReceiveWalletKey(undefined);

            const spendCurrencyTmp = spendCurrency;
            setSpendCurrencyKey(receiveCurrency);
            setReceiveCurrencyKey(spendCurrencyTmp);
            setSpendInfo('');
            setSpendError('');
            setReceiveError('');
          }}
          option={option}
        />
        <KyxAlert className={styles.kycAlert} />
        {(isBuy && !receiveCurrency) || featureUnavailable ? (
          <AssetAmountInputReadOnly
            desktopHeader={
              isBuy
                ? formatMessage('trade.spendMaximum')
                : formatMessage('trade.spend')
            }
            mobileAssetHeader={formatMessage('trade.spendMaximumCurrency')}
            mobileAmountHeader={
              !isBuy
                ? formatMessage('trade.spendAmount')
                : formatMessage('trade.spendMaximumAmount')
            }
            textTooltip={
              !isBuy ? undefined : formatMessage('trade.maximalSpendAmount')
            }
          />
        ) : (
          <AssetAmountInput
            amount={spendAmount}
            currency={spendCurrency}
            desktopHeader={
              isBuy
                ? formatMessage('trade.spendMaximum')
                : formatMessage('trade.spend')
            }
            error={spendError}
            mobileAssetHeader={formatMessage('trade.spendMaximumCurrency')}
            info={spendInfo}
            mobileAmountHeader={
              !isBuy
                ? formatMessage('trade.spendAmount')
                : formatMessage('trade.spendMaximumAmount')
            }
            showApr={false}
            wallet={spendWallet}
            walletError={spendWalletError}
            onAmountChange={(value) => {
              setSpendAmount(value);
              setSpendInfo('');
              setSpendError('');
              setReceiveError('');
              if (isBuy) {
                setCalculationBase('CURRENCY_2');
              } else {
                setCalculationBase('CURRENCY_1');
              }
            }}
            onCurrencyChange={(currency) => {
              setSpendAmount(INITIAL_AMOUNT);
              setSpendError('');
              setSpendInfo('');
              setReceiveError('');

              const foundCurrency = findInstrumentCurrency(
                currency,
                spendCurrencies
              );
              if (foundCurrency)
                setSpendCurrencyKey({
                  currency: foundCurrency.currency,
                  network: foundCurrency.network,
                });

              const foundWallet = getHighestBalanceWallet(
                foundCurrency?.wallets
              );
              if (foundWallet) setSpendWalletKey(foundWallet.id);

              if (!isBuy) {
                setReceiveCurrencyKey(undefined);
                setTolerance(TOLERANCE_INITIAL_VALUE);
              }
            }}
            onWalletChange={(wallet) => {
              setSpendWalletKey(wallet.id);
              setSpendInfo('');
              setSpendError('');
              setReceiveError('');
            }}
            onMax={async () => {
              if (!spendCurrency || !receiveCurrency) {
                return;
              }

              const res = await refetchInstrumentLimits();
              const limits = getLimitsForOption(option, res.data?.data);

              setReceiveError('');
              setCalculationBase(isBuy ? 'CURRENCY_2' : 'CURRENCY_1');
              setMaxCount(maxCount + 1); // UGLY HACK WARNING!!!

              const limitedMaxValue = isBuy
                ? limits?.currency_2_max
                : limits?.currency_1_max;

              const limitedMinValue = isBuy
                ? limits?.currency_2_min
                : limits?.currency_1_min;

              if (
                limitedMaxValue &&
                spendWallet &&
                toDecimal(spendWallet?.balance).lt(limitedMaxValue)
              ) {
                if (
                  limitedMinValue &&
                  toDecimal(spendWallet?.balance).gt(limitedMinValue)
                ) {
                  const maxValue = Math.max(
                    toDecimal(spendWallet?.balance ?? 0).toNumber(),
                    toDecimal(limitedMinValue ?? 0).toNumber()
                  );

                  setSpendAmount(
                    toLimitedPrec(
                      toFixed(
                        maxValue,
                        isBuy ? undefined : lotSize,
                        Decimal.ROUND_DOWN
                      )
                    )
                  );
                } else {
                  setSpendAmount(INITIAL_AMOUNT);
                  setReceiveAmount(INITIAL_AMOUNT);
                  setSpendError(
                    formatMessage('trade.amountMustGreater', {
                      amount: toFixed(limitedMinValue),
                      currency: spendCurrency?.currency,
                    })
                  );
                }
                setSpendInfo('');
              } else {
                setSpendAmount(
                  toLimitedPrec(
                    toFixed(
                      limitedMaxValue,
                      isBuy ? undefined : lotSize,
                      Decimal.ROUND_DOWN
                    )
                  )
                );
                setSpendInfo(
                  formatMessage('common.reducedToMaximalAllowedAmount')
                );
              }
            }}
            onError={(error) => {
              if ((!isBuy && spendCurrency) || (isBuy && receiveCurrency))
                setSpendError(error);
            }}
            currencies={spendCurrencies}
            isLoadingCurrencies={isLoading || isWalletsLoading}
            textTooltip={
              !isBuy ? undefined : formatMessage('trade.maximalSpendAmount')
            }
            wallets={spendCurrency?.wallets}
            decimals={!isBuy ? lotSize : undefined}
            showFiat={false}
          />
        )}
        {(!isBuy && !spendCurrency) || featureUnavailable ? (
          <AssetAmountInputReadOnly
            desktopHeader={
              isBuy
                ? formatMessage('trade.receive')
                : formatMessage('trade.receiveMinimum')
            }
            mobileAssetHeader={formatMessage('trade.receiveCurrency')}
            mobileAmountHeader={
              isBuy
                ? formatMessage('trade.receiveAmount')
                : formatMessage('trade.receiveMinimumAmount')
            }
            textTooltip={
              isBuy ? undefined : formatMessage('trade.maximalReceivedAmount')
            }
            currencies={spendCurrencies}
          />
        ) : (
          <AssetAmountInput
            amount={receiveAmount}
            currency={receiveCurrency}
            desktopHeader={
              isBuy
                ? formatMessage('trade.receive')
                : formatMessage('trade.receiveMinimum')
            }
            error={receiveError}
            textTooltip={
              isBuy ? undefined : formatMessage('trade.maximalReceivedAmount')
            }
            mobileAssetHeader={formatMessage('trade.receiveCurrency')}
            mobileAmountHeader={
              isBuy
                ? formatMessage('trade.receiveAmount')
                : formatMessage('trade.receiveMinimumAmount')
            }
            wallet={receiveWallet}
            walletError={receiveWalletError}
            onAmountChange={(value) => {
              setReceiveAmount(value);
              setSpendError('');
              setReceiveError('');
              setSpendInfo('');
              if (isBuy) {
                setCalculationBase('CURRENCY_1');
              } else {
                setCalculationBase('CURRENCY_2');
              }
            }}
            onCurrencyChange={(currency) => {
              setSpendInfo('');
              setSpendError('');
              setReceiveAmount(INITIAL_AMOUNT);
              setReceiveError('');

              const foundCurrency = findInstrumentCurrency(
                currency,
                receiveCurrencies
              );
              if (foundCurrency)
                setReceiveCurrencyKey({
                  currency: foundCurrency.currency,
                  network: foundCurrency.network,
                });
              const foundWallet = getHighestBalanceWallet(
                foundCurrency?.wallets
              );
              if (foundWallet) setReceiveWalletKey(foundWallet.id);

              if (isBuy) {
                setSpendCurrencyKey(undefined);
                setTolerance(TOLERANCE_INITIAL_VALUE);
              }
            }}
            onWalletChange={(wallet) => setReceiveWalletKey(wallet.id)}
            onError={(error) => {
              if ((isBuy && receiveCurrency) || (!isBuy && spendCurrency))
                setReceiveError(error);
            }}
            currencies={receiveCurrencies}
            isLoadingCurrencies={isLoading || isWalletsLoading}
            wallets={receiveCurrency?.wallets}
            decimals={isBuy ? lotSize : undefined}
            showApr={false}
            showFiat={false}
          />
        )}
      </ConfigSection>
      <SummarySection>
        <TradeEffectCalculator
          receiveAmount={receiveAmount}
          spendAmount={spendAmount}
          calculationBase={calculationBase}
          maxCount={maxCount} // UGLY HACK WARNING!!!
          spendCurrency={spendCurrency}
          receiveCurrency={receiveCurrency}
          pairId={pairId}
          option={option}
          isBuy={option === 'BUY'}
          spendWallet={spendWallet}
          tolerance={tolerance}
          onToleranceChange={(value: string) => setTolerance(value)}
          limitPrice={limitPrice}
          orderPrice={orderPrice}
          onContinueToSummary={continueToSummary}
          isLoading={isLoadingLimits || isLoading || isWalletsLoading}
          onEffectCalculated={(effect, payload) => {
            setOrderPrice(effect.order_price);
            setLimitPrice(effect.limit_price);

            if (payload.calculation_base === 'CURRENCY_1' && isBuy) {
              setSpendAmount(toLimitedPrec(toFixed(effect.currency_2_amount)));
              setReceiveError('');
            }
            if (payload.calculation_base === 'CURRENCY_2' && isBuy) {
              setReceiveAmount(
                toLimitedPrec(toFixed(effect.currency_1_amount))
              );
              setSpendError('');
            }
            if (payload.calculation_base === 'CURRENCY_1' && !isBuy) {
              setReceiveAmount(
                toLimitedPrec(toFixed(effect.currency_2_amount))
              );
              setSpendError('');
            }
            if (payload.calculation_base === 'CURRENCY_2' && !isBuy) {
              setSpendAmount(toLimitedPrec(toFixed(effect.currency_1_amount)));
              setReceiveError('');
            }
          }}
          onEffectError={(error, payload) => {
            if (!isSpendAmountChanged) {
              setSpendAmount(INITIAL_AMOUNT);
              setSpendError('');
            }
            if (isSpendAmountChanged) {
              setReceiveAmount(INITIAL_AMOUNT);
              setReceiveError('');
            }

            const amountMustLessMatch = amountMustLessError(error);
            if (amountMustLessMatch) {
              if (!isSpendAmountChanged) {
                setReceiveError(
                  formatMessage('common.amountMustBeLessOrEqual', {
                    amount: toFixed(amountMustLessMatch[1]),
                    currency: receiveCurrency?.currency,
                  })
                );
              }
              if (isSpendAmountChanged) {
                setSpendError(
                  formatMessage('common.amountMustBeLessOrEqual', {
                    amount: toFixed(amountMustLessMatch[1]),
                    currency: spendCurrency?.currency,
                  })
                );
              }
            }

            const amountMustGreaterMatch = amountMustGreaterError(error);
            if (amountMustGreaterMatch) {
              if (!isSpendAmountChanged) {
                setReceiveError(
                  formatMessage('trade.amountMustGreater', {
                    amount: toFixed(amountMustGreaterMatch[1]),
                    currency: receiveCurrency?.currency,
                  })
                );
              }
              if (isSpendAmountChanged) {
                setSpendError(
                  formatMessage('trade.amountMustGreater', {
                    amount: toFixed(amountMustGreaterMatch[1]),
                    currency: spendCurrency?.currency,
                  })
                );
              }
            }

            const ensureMaxDecimalPlacesMatch = ensureMaxDecimalPlaces(error);
            if (ensureMaxDecimalPlacesMatch) {
              if (!isSpendAmountChanged) {
                setReceiveError(
                  formatMessage('trade.ensureNoMoreThanXDecimalPlaces', {
                    decimal: ensureMaxDecimalPlacesMatch[1],
                  })
                );
              }

              if (isSpendAmountChanged) {
                setSpendError(
                  formatMessage('trade.ensureNoMoreThanXDecimalPlaces', {
                    decimal: ensureMaxDecimalPlacesMatch[1],
                  })
                );
              }
            }
          }}
        />
      </SummarySection>
      <NewTradeModal
        isOpen={isModalOpen}
        onClose={closeModal}
        isBuy={isBuy}
        tolerance={tolerance}
        limitPrice={tradeEffectMutation.data?.data.limit_price}
        orderPrice={tradeEffectMutation.data?.data.order_price}
        spendCurrency={spendCurrency}
        spendWallet={spendWallet}
        receiveCurrency={receiveCurrency}
        receiveWallet={receiveWallet}
        spendAmount={
          calculationBase === 'CURRENCY_1' && isBuy
            ? toLimitedPrec(
                toFixed(tradeEffectMutation.data?.data.currency_2_amount)
              )
            : calculationBase === 'CURRENCY_2' && !isBuy
            ? toLimitedPrec(
                toFixed(tradeEffectMutation.data?.data.currency_1_amount)
              )
            : spendAmount
        }
        receiveAmount={
          calculationBase === 'CURRENCY_2' && isBuy
            ? toLimitedPrec(
                toFixed(tradeEffectMutation.data?.data.currency_1_amount)
              )
            : calculationBase === 'CURRENCY_1' && !isBuy
            ? toLimitedPrec(
                toFixed(tradeEffectMutation.data?.data.currency_2_amount)
              )
            : receiveAmount
        }
        option={option}
        pairId={pairId}
        calculationBase={calculationBase}
        onAmountError={() => {
          setSpendAmount(INITIAL_AMOUNT);
          setReceiveAmount(INITIAL_AMOUNT);
          setSpendError('');
          setReceiveError('');
          setSpendInfo('');
          setLimitPrice(INITIAL_AMOUNT);
          setOrderPrice(INITIAL_AMOUNT);
          if (pairId) {
            queryClient.invalidateQueries(
              EXCHANGE_INSTRUMENT_LIMITS_KEY(pairId, tolerance)
            );
          }
        }}
        onSuccessTrade={() => {
          setSpendAmount(INITIAL_AMOUNT);
          setReceiveAmount(INITIAL_AMOUNT);
          setLimitPrice(INITIAL_AMOUNT);
          setOrderPrice(INITIAL_AMOUNT);
          setSpendCurrencyKey(undefined);
          setReceiveCurrencyKey(undefined);
          setReceiveWalletKey(undefined);
          setSpendWalletKey(undefined);
          setSpendError('');
          setReceiveError('');
          setSpendInfo('');
          setTolerance(TOLERANCE_INITIAL_VALUE);
          setOption('SELL');
        }}
        token={tradeEffectMutation.data?.data.token}
      />
    </NewOperationLayout>
  );
};
