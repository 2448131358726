import styles from './TradeHistoryBox.module.scss';
import { ReactComponent as ArrowheadDown } from 'common/assets/black-icons/arrowhead-down-14px.svg';
import { ReactComponent as ArrowheadUp } from 'common/assets/black-icons/arrowhead-up-14px.svg';
import { useState } from 'react';
import { Trade } from '../../api';
import { Title } from './Title';
import {
  Date,
  ExternalWallet,
  ID,
  InternalWallet,
  Price,
} from 'modules/common-data-fields';
import { NetworkBox } from 'common/components/NetworkBox';
import { useFormatMessage } from 'modules/messages';
import { TradeState } from './TradeState';
import clsx from 'clsx';
import { Tolerance } from './Tolerance';
import { TradePrice } from './TradePrice';

type Props = {
  trade: Trade;
};

export const TradeHistoryBox: React.FC<Props> = ({ trade }) => {
  const formatMessage = useFormatMessage();

  const {
    id,
    currency_1,
    currency_2,
    side,
    executed_at,
    currency_1_wallet,
    currency_2_wallet,
    currency_1_amount,
    currency_2_amount,
    state,
    price_tolerance_pct,
    final_price,
    limit_price,
    final_price_change_pct,
    currency_2_rate,
    currency_1_rate,
    substate,
  } = trade;
  const [expanded, setExpaned] = useState(false);

  const spendCurrency = side === 'BUY' ? currency_2 : currency_1;
  const receiveCurrency = side === 'BUY' ? currency_1 : currency_2;

  const spendWallet = side === 'BUY' ? currency_2_wallet : currency_1_wallet;
  const receiveWallet = side === 'BUY' ? currency_1_wallet : currency_2_wallet;

  const spendAmount = side === 'BUY' ? currency_2_amount : currency_1_amount;
  const receiveAmount = side === 'BUY' ? currency_1_amount : currency_2_amount;

  const spendRate = side === 'BUY' ? currency_2_rate : currency_1_rate;
  const receiveRate = side === 'BUY' ? currency_1_rate : currency_2_rate;

  return (
    <div className={styles.container}>
      <div
        className={styles.tableItem}
        data-test='rowInTxnTable'
        onClick={() => {
          setExpaned((expanded) => !expanded);
        }}
      >
        <div className={styles.expand} data-test='expandRow'>
          {expanded ? (
            <ArrowheadUp data-test='arrowUp' />
          ) : (
            <ArrowheadDown data-test='arrowDwon' />
          )}
        </div>
        <div className={styles.name}>
          <Title outCurrency={spendCurrency} inCurrency={receiveCurrency} />
          <Date date={executed_at} />
        </div>
        <div className={styles.spend}>
          <Price
            title={formatMessage('transactions.spend')}
            amount={spendAmount}
            currency={spendCurrency}
            currencyRate={spendRate}
            showFiat={false}
          />
        </div>
        <div className={styles.receive}>
          <Price
            title={formatMessage('transactions.receive')}
            amount={receiveAmount}
            currency={receiveCurrency}
            currencyRate={receiveRate}
            showFiat={false}
          />
        </div>
        <div className={styles.status}>
          <TradeState state={state} substate={substate} />
        </div>
      </div>
      {expanded && (
        <div className={clsx(styles.tableItem, styles.moreInfo)}>
          <div className={styles.expand}> </div>
          <div className={styles.wallets}>
            <div className={styles.flexGap10}>
              <ExternalWallet name={spendWallet.name} />
              <NetworkBox network={spendWallet.network} />
            </div>
            <div className={styles.flexGap10}>
              <InternalWallet name={receiveWallet.name} />
              <NetworkBox network={receiveWallet.network} />
            </div>
            <ID id={id} />
          </div>
          <div className={styles.fee}>
            <TradePrice
              side={side}
              spendCurrency={spendCurrency}
              receiveCurrency={receiveCurrency}
              final_price={final_price}
              limit_price={limit_price}
            />
          </div>
          <div className={styles.tolerance}>
            <Tolerance
              price_tolerance_pct={price_tolerance_pct}
              final_price_change_pct={final_price_change_pct}
            />
          </div>
        </div>
      )}
    </div>
  );
};
