import { ReactComponent as CharityOutgoingIcon } from 'common/assets/grey-icons/charity-outgoing.svg';
import { ReactComponent as CharityIncomingIcon } from 'common/assets/grey-icons/charity-incoming.svg';
import styles from './CharityFormName.module.scss';
import { Text } from 'common/components/Text';

type Directions = 'OUTGOING' | 'INCOMING';

type Props = { direction: Directions; name: string };

export const CharityFormName: React.FC<Props> = ({ direction, name }) => {
  return (
    <div className={styles.container}>
      {direction === 'OUTGOING' && (
        <CharityOutgoingIcon className={styles.icon} />
      )}
      {direction === 'INCOMING' && (
        <CharityIncomingIcon className={styles.icon} />
      )}
      <Text className={styles.name} style='light1215' colorStyle='secondary'>
        {name}
      </Text>
    </div>
  );
};
