import { useMembersQuery } from 'modules/company-members/api';
import { useUserQuery } from './api';

export const useIsOwner = () => {
  const { data, isLoading } = useUserQuery();

  return {
    data: data ? data.data.company_role === 'OWNER' : undefined,
    isLoading,
  };
};

export const useIsSigner = ({ enabled }: { enabled?: boolean } = {}) => {
  const { data: user, isLoading: isLoadingUser } = useUserQuery({
    enabled,
  });
  const { data: members, isLoading: isLoadingMembers } = useMembersQuery({
    enabled,
  });

  return {
    data:
      members && user
        ? members.data.find((member) => member.email === user.data.email)
            ?.is_signer
        : undefined,
    isLoading: isLoadingMembers || isLoadingUser,
  };
};
