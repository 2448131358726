import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { useInfiniteTransactionsContext } from '../context';
import styles from './ResetFiltersPill.module.scss';

export const ResetFiltersPill: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { resetFilters, setRefetchTransactionsFlag } =
    useInfiniteTransactionsContext();

  return (
    <div
      className={styles.container}
      onClick={() => {
        resetFilters();
        setRefetchTransactionsFlag(true);
      }}
    >
      <Text style='light1425' colorStyle='secondary'>
        {formatMessage('transactions.resetAllFilters')}
      </Text>
    </div>
  );
};
