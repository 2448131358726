import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { Text } from 'common/components/Text';
import { termToMonths } from 'modules/financial-ops/common/utils';
import { useFormatMessage } from 'modules/messages';
import { BorrowDetails, BorrowRead } from '../../types';
import styles from './BorrowName.module.scss';

type Props = {
  borrow: BorrowRead | BorrowDetails;
  isSmall?: boolean;
  dataTest?: string;
};

export const BorrowName: React.FC<Props> = ({
  borrow,
  isSmall = true,
  dataTest,
}) => {
  const formatMessage = useFormatMessage();

  const { principal_currency, term } = borrow;

  return (
    <div className={styles.container} data-test={dataTest}>
      <CurrencyIcon currencyName={borrow.principal_currency} />
      <Text style={isSmall ? 'bold1622' : 'xxbold2024'}>
        {formatMessage('borrow.borrowName', {
          currency: principal_currency,
          months: termToMonths(term),
        })}
      </Text>
    </div>
  );
};
