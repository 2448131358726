import clsx from 'clsx';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { Text } from 'common/components/Text';
import Decimal from 'decimal.js';
import { CryptoToFiatAmount } from 'modules/fiat';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { BaseCurrency } from 'modules/select-currency-wallet/types';
import styles from './AmountReadOnly.module.scss';

export const AmountReadOnly: React.FC<{
  amount: Decimal | string;
  currency: BaseCurrency;
  className?: string;
  prefix?: string;
}> = ({ amount, currency, className, prefix = '' }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.amountContainer}>
        <div>
          <Text style='light1622'>
            {prefix + toLimitedPrec(toFixed(amount))}
          </Text>
          <Text style='light1622' className={styles.currency}>
            &nbsp;{currency.currency}
          </Text>
        </div>
        <CryptoToFiatAmount amount={amount} currency={currency.currency} />
      </div>
      <CurrencyBox
        currency={currency.currency}
        network={currency.network}
        className={styles.currencyBox}
        dataTest='currencyBox'
      />
    </div>
  );
};
