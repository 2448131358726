import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { post } from 'modules/api';
import { RecaptchaErrorResponse, RecaptchaHeader } from 'modules/recaptcha';
import { FeatureFlagDisabledError } from 'modules/feature-flags';

export const REGISTER_URL = '/register';
export const POST_REGISTER_QUERY_KEY = ['POST', REGISTER_URL] as const;

export type RegisterPayload = {
  email: string;
  name: string;
  surname: string;
  company_name: string;
  password: string;
};

export type RegisterRequest = {
  payload: RegisterPayload;
  headers: RecaptchaHeader;
};

export type RegisterResponseData = {
  email: string;
  name: string;
  surname: string;
};

export type RegisterResponseError = {
  detail?: string;
  company_name?: string[];
  email?: string[];
  status?: string;
} & FeatureFlagDisabledError &
  RecaptchaErrorResponse;

export const register = (payload: RegisterPayload, headers?: RecaptchaHeader) =>
  post<RegisterResponseData, RegisterPayload>(REGISTER_URL, payload, {
    headers,
  });

export const useRegisterMutation = () =>
  useMutation<
    AxiosResponse<RegisterResponseData>,
    AxiosError<RegisterResponseError>,
    RegisterRequest
  >(POST_REGISTER_QUERY_KEY, ({ payload, headers }) =>
    register(payload, headers)
  );

export const ERR_LIMIT_SENDING_EMAIL =
  'Email verification link was already sent. You can request a new link in';
