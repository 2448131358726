import { useEffect, useState } from 'react';
import styles from './DateFilter.module.scss';
import { useFormatMessage } from 'modules/messages';
import { ModalTemplate } from './components/ModalTemplate';
import { FilterButton } from './components/FilterButton';
import { DatePicker } from './components/DatePicker';
import { MainFilterButton } from './components/MainFilterButton';
import { useInfiniteTransactionsContext } from 'modules/transactions/filters/context';
import { formatDate } from 'common/utils/utils';

type DateFilterProps = {};

export const DateFilter: React.FC<DateFilterProps> = () => {
  const formatMessage = useFormatMessage();
  const [dateFrom, setDateFrom] = useState<string | null | undefined>();
  const [dateTo, setDateTo] = useState<string | null | undefined>();
  const [isOpenFrom, setIsOpenFrom] = useState(false);
  const [isOpenTo, setIsOpenTo] = useState(false);

  const [preventClose, setPreventClose] = useState(true);
  const [open, setOpen] = useState(false);

  const { filters, setFilters, setRefetchTransactionsFlag } =
    useInfiniteTransactionsContext();

  useEffect(() => {
    if (!filters.dateFrom) setDateFrom(filters.dateFrom);
    if (!filters.dateTo) setDateTo(filters.dateTo);
  }, [filters.dateFrom, filters.dateTo]);

  const buttons = [
    {
      text: formatMessage('transactions.last24Hours'),
      days: 1,
      isSelected: false,
    },
    {
      text: formatMessage('transactions.last7Days'),
      days: 7,
      isSelected: false,
    },
    {
      text: formatMessage('transactions.last30Days'),
      days: 30,
      isSelected: false,
    },
  ];

  const getText = () => {
    if (filters.lastDays) {
      if (filters.lastDays === 1) {
        return formatMessage('transactions.dateLast24Hours');
      }
      if (filters.lastDays === 7) {
        return formatMessage('transactions.dateLast7Days');
      }
      if (filters.lastDays === 30) {
        return formatMessage('transactions.dateLast30Days');
      }
    }

    if (filters.dateFrom && filters.dateTo) {
      return formatMessage('transactions.dateFromTo', {
        from: formatDate(filters.dateFrom),
        to: formatDate(filters.dateTo),
      });
    }

    if (filters.dateFrom) {
      return `${formatMessage('transactions.dateFrom')}: ${formatDate(
        filters.dateFrom
      )}`;
    }

    if (filters.dateTo) {
      return `${formatMessage('transactions.dateTo')}: ${formatDate(
        filters.dateTo
      )}`;
    }

    return formatMessage('common.date');
  };

  useEffect(() => {
    if (isOpenFrom || isOpenTo) {
      setPreventClose(true);
    } else {
      setPreventClose(false);
    }
  }, [isOpenFrom, isOpenTo]);

  return (
    <div>
      <MainFilterButton
        onClick={() => {
          setPreventClose(true);
          setOpen(true);
        }}
        text={getText()}
      />
      <ModalTemplate
        open={open}
        onChangeOpen={(value) => {
          if (!isOpenFrom && !isOpenTo) {
            setOpen(value);
          }
        }}
        onChangePreventClose={(value) => {
          if (!isOpenFrom && !isOpenTo) {
            setPreventClose(value);
          }
        }}
        preventClose={preventClose}
        contentClassName={styles.modal}
        children={
          <>
            <div className={styles.buttons}>
              {buttons.map((button, i) => (
                <FilterButton
                  onClick={() => {
                    const nextFilters = {
                      ...filters,
                      dateFrom: undefined,
                      dateTo: undefined,
                    };
                    if (filters.lastDays !== button.days) {
                      nextFilters.lastDays = button.days;
                    } else {
                      nextFilters.lastDays = undefined;
                    }
                    setFilters(nextFilters);
                    setDateTo(null);
                    setDateFrom(null);
                    setRefetchTransactionsFlag(true);
                  }}
                  text={button.text}
                  key={i}
                  className={styles.dateButton}
                  isSelected={filters.lastDays === button.days}
                />
              ))}
            </div>
            <div className={styles.pickers}>
              <DatePicker
                isOpen={isOpenFrom}
                setIsOpen={setIsOpenFrom}
                text='from'
                value={dateFrom ? new Date(dateFrom) : null}
                onChange={(value) => {
                  const nextFilters = { ...filters, lastDays: undefined };
                  if (value) {
                    const from = new Date(value);
                    if (from) {
                      nextFilters.dateFrom = from.toISOString();
                    }
                  } else {
                    nextFilters.dateFrom = undefined;
                  }
                  setDateFrom(value);
                  setFilters(nextFilters);
                  setRefetchTransactionsFlag(true);
                }}
              />
              <DatePicker
                isOpen={isOpenTo}
                setIsOpen={setIsOpenTo}
                text='to'
                value={dateTo ? new Date(dateTo) : null}
                onChange={(value) => {
                  const nextFilters = { ...filters, lastDays: undefined };
                  if (value) {
                    const to = new Date(value);
                    if (to) {
                      nextFilters.dateTo = to.toISOString();
                    }
                  } else {
                    nextFilters.dateTo = undefined;
                  }
                  setDateTo(value);
                  setFilters(nextFilters);
                  setRefetchTransactionsFlag(true);
                }}
              />
            </div>
          </>
        }
      />
    </div>
  );
};
