import clsx from 'clsx';
import { NetworkBadge } from 'common/components/NetworkBadge';
import { NetworkEnum } from 'common/types';
import styles from './NetworkSelector.module.scss';

type Props = {
  network: NetworkEnum;
  selected: boolean;
  onSelect: () => void;
  error?: boolean;
};

export const NetworkSelector: React.FC<Props> = ({
  network,
  selected,
  onSelect,
  error,
}) => {
  return (
    <div
      className={clsx(
        styles.container,
        selected && styles.selected,
        error && styles.error
      )}
      onClick={onSelect}
      data-test='networkSelector'
    >
      <NetworkBadge network={network} />
    </div>
  );
};
