import clsx from 'clsx';
import { ReactComponent as ErrorIcon } from 'common/assets/black-icons/error.svg';
import { ReactComponent as SuccessIcon } from 'common/assets/black-icons/ok.svg';
import { ReactComponent as WarningIcon } from 'common/assets/black-icons/warning.svg';
import { Text } from 'common/components/Text';
import D from 'decimal.js';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';
import { stateToMessageKey } from '../borrow-list/components/BorrowStatePill';
import { BorrowDetails, BorrowReadStateEnum } from '../types';
import styles from './BorrowStateAlert.module.scss';

export const stateToStyle = (state: BorrowReadStateEnum): string => {
  switch (state) {
    case 'CREATED':
    case 'ACTIVE':
      return styles.stateHealthy;
    case 'NEAR_MARGIN_CALL':
      return styles.stateNearMarginCall;
    case 'MARGIN_CALL':
      return styles.stateMarginCall;
    case 'LIQUIDATING':
      return styles.stateLiquidation;
    case 'LIQUIDATED':
      return styles.stateLiquidated;
    case 'CANCELLED':
      return styles.stateCancelled;
    case 'COMPLETED':
      return styles.stateCompleted;
  }
};

const stateToIcon = (state: BorrowReadStateEnum) => {
  switch (state) {
    case 'CREATED':
    case 'ACTIVE':
    case 'NEAR_MARGIN_CALL':
      return <WarningIcon />;
    case 'MARGIN_CALL':
    case 'LIQUIDATING':
      return <WarningIcon />;
    case 'LIQUIDATED':
      return <ErrorIcon />;
    case 'CANCELLED':
      return <ErrorIcon />;
    case 'COMPLETED':
      return <SuccessIcon />;
  }
};

type TextProps = {
  borrow: BorrowDetails;
};

const HealthyText = () => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text style='light1215'>
        {formatMessage('borrow.isHealthyNoActionRequired')}
      </Text>
    </div>
  );
};

const NearMarginCallText = () => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.text}>
      <div>
        <Text style='light1215'>
          {formatMessage('borrow.isCloseToMarginCall')}
        </Text>
      </div>
      <div>
        <Text style='light1215'>{formatMessage('borrow.toReduceRisk')}</Text>
      </div>
    </div>
  );
};

const MarginCallText: React.FC<TextProps> = ({ borrow }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.text}>
      <div>
        <Text style='light1215'>
          {formatMessage('borrow.borrowReachedMarginCall')}
        </Text>
      </div>
      <div>
        <Text style='light1215'>
          {formatMessage(
            borrow.auto_topup
              ? 'borrow.fundsAutomaticallyTransferred'
              : 'borrow.topUpCollateralForBorrow'
          )}
        </Text>
      </div>
      <div>
        <Text style='xbold1215'>{formatMessage('borrow.requiredAmount')}</Text>
        <div>
          <Text style='xbold1215'>{`${toLimitedPrec(
            toFixed(borrow.expected_collateral_amount_diff)
          )} ${borrow.collateral_currency}`}</Text>
        </div>
      </div>
    </div>
  );
};

const LiquidationText: React.FC<TextProps> = ({ borrow }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.text}>
      <div>
        <div>
          <Text style='light1215'>
            {formatMessage('borrow.collateralNoCoversPrincipalValue')}
          </Text>
        </div>
        <div>
          <Text style='light1215'>
            {`- ${formatMessage('borrow.interestValue', {
              amount: toFixed(borrow.frozen_interest),
              currency: borrow.principal_currency,
            })},`}
          </Text>
        </div>
        <div>
          <Text style='light1215'>
            {`- ${formatMessage('borrow.liquidationFee', {
              fee: toLimitedPrec(toFixed(borrow.liquidation_fee)),
              currency: borrow.collateral_currency,
            })},`}
          </Text>
        </div>
        <div>
          <Text style='light1215'>
            {`- ${formatMessage('borrow.principalValue', {
              value: new D(borrow.principal_amount).toFixed(),
              currency: borrow.principal_currency,
            })}.`}
          </Text>
        </div>
      </div>
      <div>
        <Text style='light1215'>
          {formatMessage('borrow.remainingCollateralReleasedAutomatically')}
        </Text>
      </div>
    </div>
  );
};

const LiquidatedText: React.FC<TextProps> = ({ borrow }) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text style='light1215'>
        {formatMessage('borrow.borrowHasBeenLiquidated', {
          amount: toLimitedPrec(toFixed(borrow.collateral_returned_amount)),
          currency: borrow.collateral_currency,
        })}
      </Text>
    </div>
  );
};

const CancelledText: React.FC<TextProps> = ({ borrow }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.text}>
      <div>
        <div>
          <Text style='light1215'>
            {formatMessage('borrow.borrowHadToBeCancelled')}
          </Text>
        </div>
        <div>
          <Text style='light1215'>
            {`- ${formatMessage('borrow.interestValue', {
              amount: toLimitedPrec(toFixed(borrow.interest)),
              currency: borrow.principal_currency,
            })},`}
          </Text>
        </div>
        <div>
          <Text style='light1215'>
            {`- ${formatMessage('borrow.principalValue', {
              value: toLimitedPrec(toFixed(borrow.principal_amount)),
              currency: borrow.principal_currency,
            })}.`}
          </Text>
        </div>
      </div>
    </div>
  );
};

const CompletedText = () => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <Text style='light1215'>
        {formatMessage('borrow.completedThanksForRepaying')}
      </Text>
    </div>
  );
};

const StateText: React.FC<{
  borrow: BorrowDetails;
}> = ({ borrow }) => {
  const { state } = borrow;

  switch (state) {
    case 'CREATED':
    case 'ACTIVE':
      return <HealthyText />;
    case 'NEAR_MARGIN_CALL':
      return <NearMarginCallText />;
    case 'MARGIN_CALL':
      return <MarginCallText borrow={borrow} />;
    case 'LIQUIDATING':
      return <LiquidationText borrow={borrow} />;
    case 'LIQUIDATED':
      return <LiquidatedText borrow={borrow} />;
    case 'CANCELLED':
      return <CancelledText borrow={borrow} />;
    case 'COMPLETED':
      return <CompletedText />;
  }
};

type Props = { borrow: BorrowDetails };

export const BorrowStateAlert: React.FC<Props> = ({ borrow }) => {
  const formatMessage = useFormatMessage();

  const { state } = borrow;

  return (
    <div className={clsx(styles.container, stateToStyle(state))}>
      <div className={styles.header}>
        {stateToIcon(state)}
        <div>
          <Text style='bold1425'>{`${formatMessage(
            'borrow.borrowState'
          )} ${formatMessage(stateToMessageKey(state))}`}</Text>
        </div>
      </div>
      <StateText borrow={borrow} />
    </div>
  );
};
