import clsx from 'clsx';
import styles from './Themes.module.scss';
import { ThemeDot } from './ThemeDot';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';
import { ColorPicker } from './ColorPicker';
import { CustomColor, Theme } from 'modules/charity-forms/types';
import { useEffect } from 'react';
import { useNewCharityFormContext } from '../context';
import { theme } from 'common/consts/theme';

const NUMBER_OF_MAX_CUSTOM_COLORS = 9;
const NUMBER_OF_DEFAULT_COLORS = 10;

const themesColors = [
  {
    value:
      'linear-gradient( -46deg, black 0%, black 52%, #FFD88D 52%, #EDE300 100%)',
    secondary: theme.colors.btnDark,
    primary: '#EDE300',
  },
  {
    value:
      'linear-gradient( -46deg, black 0%, black 52%, #CCC0FF 52%, #DCD6F7 100%)',
    secondary: theme.colors.btnDark,
    primary: '#DCD6F7',
  },
  {
    value:
      'linear-gradient( -46deg, black 0%, black 52%, #8DBBFF 52%, #BFD9FF 100%)',
    secondary: theme.colors.btnDark,
    primary: '#BFD9FF',
  },
  {
    value:
      'linear-gradient( -46deg, black 0%, black 52%, #E98989 52%, #F7D6D6 100%)',
    secondary: theme.colors.btnDark,
    primary: '#F7D6D6',
  },
  {
    value:
      'linear-gradient( -46deg, black 0%, black 52%, #AAEA8D 52%, #D3FFBF 100%)',
    secondary: theme.colors.btnDark,
    primary: '#D3FFBF',
  },
  {
    value: 'linear-gradient(315deg, #F4A259 0%, #FFC795 100%)',
    primary: '#FFC795',
  },
  {
    value: 'linear-gradient(315deg, #AA6DA3 -0.11%, #DC8DD3 100.11%)',
    primary: '#DC8DD3',
  },
  {
    value: 'linear-gradient(315deg, #6A79FF 0.11%, #4C5BDF 100.32%)',
    primary: '#4C5BDF',
  },
  {
    value: 'linear-gradient(315deg, #6B3939 0%, #A46565 100%)',
    primary: '#A46565',
  },
  {
    value: 'linear-gradient(315deg, #395C6B 0%, #77A1B2 100.21%)',
    primary: '#77A1B2',
  },
];

export const Themes: React.FC<{
  selectedColor: number;
  onChange: (id: number, value: Theme) => void;
  customColors?: CustomColor[];
  onChangeCustomColors: (value: CustomColor[]) => void;
}> = ({ selectedColor, onChange, onChangeCustomColors, customColors }) => {
  const formatMessage = useFormatMessage();
  const context = useNewCharityFormContext();

  useEffect(() => {
    if (!context.primaryColor)
      onChange(selectedColor, {
        primary: themesColors[0].primary,
        secondary: themesColors[0].secondary,
      });
  }, []);

  return (
    <>
      <div className={clsx(styles.container)}>
        {themesColors.map((color, i) => (
          <ThemeDot
            color={color.value}
            onClick={() =>
              onChange(i, {
                primary: color.primary,
                secondary: color.secondary,
              })
            }
            isSelected={i === selectedColor}
            key={i}
          />
        ))}
      </div>
      <Text
        style='light1215'
        colorStyle='inactive'
        inlineStyle={{ display: 'block' }}
      >
        {formatMessage('charityForms.customThemes')}
      </Text>
      <div className={clsx(styles.container)}>
        <ColorPicker
          onClick={(color) => {
            if (customColors) {
              if (customColors.length === NUMBER_OF_MAX_CUSTOM_COLORS) {
                const oldestDate = [...customColors].sort((a, b) =>
                  Date.parse(a.date) > Date.parse(b.date) ? 1 : -1
                )[0];

                const found = customColors.findIndex(
                  (item) =>
                    oldestDate.primary === item.primary &&
                    oldestDate.secondary === item.secondary &&
                    item.date === oldestDate.date
                );

                if (found !== -1) {
                  onChangeCustomColors([
                    ...customColors.slice(0, found),
                    {
                      primary: color,
                      date: new Date().toISOString(),
                    },
                    ...customColors.slice(
                      found + 1,
                      NUMBER_OF_MAX_CUSTOM_COLORS
                    ),
                  ]);

                  onChange(found + NUMBER_OF_DEFAULT_COLORS, {
                    primary: color,
                  });
                }

                return;
              }
              onChangeCustomColors([
                ...customColors,
                {
                  primary: color,
                  date: new Date().toISOString(),
                },
              ]);

              onChange(customColors.length + NUMBER_OF_DEFAULT_COLORS, {
                primary: color,
              });
            } else {
              onChangeCustomColors([
                {
                  primary: color,
                  date: new Date().toISOString(),
                },
              ]);
              onChange(NUMBER_OF_DEFAULT_COLORS, {
                primary: color,
              });
            }
          }}
        />
        {customColors?.map((color, i) => (
          <ThemeDot
            color={color.primary}
            key={i}
            onClick={() =>
              onChange(i + NUMBER_OF_DEFAULT_COLORS, {
                primary: color.primary,
                secondary: color.secondary,
              })
            }
            isSelected={selectedColor === i + NUMBER_OF_DEFAULT_COLORS}
          />
        ))}
      </div>
    </>
  );
};
