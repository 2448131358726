import { createContext, useContext, useState } from 'react';
import { SetState } from 'common/types';
import { BaseCurrency } from 'modules/select-currency-wallet/types';

type NewDonationContextValue = {
  company: string;
  companyError: string;
  currency: BaseCurrency | undefined;
  currencyError: string;
  email: string;
  emailError: string;
  isAnonymousDonation: boolean;
  name: string;
  nameError: string;
  surname: string;
  surnameError: string;
  walletAddress: string;
  walletAddressError: string;
  setCompany: SetState<string>;
  setCompanyError: SetState<string>;
  setCurrency: SetState<BaseCurrency | undefined>;
  setCurrencyError: SetState<string>;
  setEmail: SetState<string>;
  setEmailError: SetState<string>;
  setIsAnonymousDonation: SetState<boolean>;
  setName: SetState<string>;
  setNameError: SetState<string>;
  setSurname: SetState<string>;
  setSurnameError: SetState<string>;
  setWalletAddress: SetState<string>;
  setWalletAddressError: SetState<string>;
};

const NewDonationContext = createContext<NewDonationContextValue>(
  {} as NewDonationContextValue
);

export const NewDonationContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [company, setCompany] = useState<string>('');
  const [companyError, setCompanyError] = useState<string>('');
  const [currency, setCurrency] = useState<BaseCurrency>();
  const [currencyError, setCurrencyError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isAnonymousDonation, setIsAnonymousDonation] =
    useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [surnameError, setSurnameError] = useState<string>('');
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [walletAddressError, setWalletAddressError] = useState<string>('');

  return (
    <NewDonationContext.Provider
      value={{
        company,
        companyError,
        currency,
        currencyError,
        email,
        emailError,
        isAnonymousDonation,
        name,
        nameError,
        surname,
        surnameError,
        walletAddress,
        walletAddressError,
        setCompany,
        setCompanyError,
        setCurrency,
        setCurrencyError,
        setEmail,
        setEmailError,
        setIsAnonymousDonation,
        setName,
        setNameError,
        setSurname,
        setSurnameError,
        setWalletAddress,
        setWalletAddressError,
      }}
    >
      {children}
    </NewDonationContext.Provider>
  );
};

export const useNewDonationContext = () => {
  return useContext(NewDonationContext);
};
