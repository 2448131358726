import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { formatDatetime } from 'common/utils/utils';

type Props = { completed_at: string };

export const FinishedDate: React.FC<Props> = ({ completed_at }) => {
  const formatMessage = useFormatMessage();

  return (
    <Text
      style='light1215'
      colorStyle='secondary'
      dataTest='finishedDate'
      inlineStyle={{ display: 'block' }}
    >
      {formatMessage('saving.finished', {
        date: formatDatetime(completed_at),
      })}
    </Text>
  );
};
