import { createContext, useContext, useState } from 'react';

type KyxContextValue = {
  isKyxBarAllowed: boolean;
  isFirstLoginModalVisible: boolean;
  isCompleteKycModalVisible: boolean;
  isCompleteKybModalVisible: boolean;
  allowKyxBar: () => void;
  blockKyxBar: () => void;
  showFirstLoginModal: () => void;
  hideFirstLoginModal: () => void;
  showCompleteKycModal: () => void;
  hideCompleteKycModal: () => void;
  showCompleteKybModal: () => void;
  hideCompleteKybModal: () => void;
};

const KyxContext = createContext<KyxContextValue>({
  isKyxBarAllowed: true,
  isFirstLoginModalVisible: false,
  isCompleteKycModalVisible: false,
  isCompleteKybModalVisible: false,
  allowKyxBar: () => {},
  blockKyxBar: () => {},
  showFirstLoginModal: () => {},
  hideFirstLoginModal: () => {},
  showCompleteKycModal: () => {},
  hideCompleteKycModal: () => {},
  showCompleteKybModal: () => {},
  hideCompleteKybModal: () => {},
});

export const KyxContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isKyxBarAllowed, setIsKyxBarAllowed] = useState(true);

  const [isFirstLoginModalVisible, setIsKycFirsLoginModalVisible] =
    useState(false);
  const [isCompleteKycModalVisible, setIsCompleteKycModalVisible] =
    useState(false);
  const [isCompleteKybModalVisible, setIsCompleteKybModalVisible] =
    useState(false);

  return (
    <KyxContext.Provider
      value={{
        isKyxBarAllowed,
        isFirstLoginModalVisible,
        isCompleteKycModalVisible,
        isCompleteKybModalVisible,
        allowKyxBar: () => setIsKyxBarAllowed(true),
        blockKyxBar: () => setIsKyxBarAllowed(false),
        showFirstLoginModal: () => setIsKycFirsLoginModalVisible(true),
        hideFirstLoginModal: () => setIsKycFirsLoginModalVisible(false),
        showCompleteKycModal: () => setIsCompleteKycModalVisible(true),
        hideCompleteKycModal: () => setIsCompleteKycModalVisible(false),
        showCompleteKybModal: () => setIsCompleteKybModalVisible(true),
        hideCompleteKybModal: () => setIsCompleteKybModalVisible(false),
      }}
    >
      {children}
    </KyxContext.Provider>
  );
};

export const useKyxContext = () => {
  return useContext(KyxContext);
};
