import { get } from 'modules/api';
import { BalanceMeasure, BalanceMeasurePeriod } from './types';

export const BALANCE_MEASURES_URL = '/balance-measures';

export const getBalanceMeasures = (period: BalanceMeasurePeriod) => {
  const params = new URLSearchParams({ period });
  const url = `${BALANCE_MEASURES_URL}?${params}`;
  return get<BalanceMeasure[]>(url);
};
