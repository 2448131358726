import { createContext, useContext, useState } from 'react';

type HelpCenterSSOContextValue = {
  returnTo?: string;
  setReturnTo: (returnTo: string | undefined) => void;
};

const HelpCenterSSOContext = createContext<HelpCenterSSOContextValue>({
  setReturnTo: () => {},
});

export const HelpCenterSSOContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [returnTo, setReturnTo] = useState<string>();

  return (
    <HelpCenterSSOContext.Provider
      value={{
        returnTo,
        setReturnTo,
      }}
    >
      {children}
    </HelpCenterSSOContext.Provider>
  );
};

export const useHelpCenterSSOContext = () => {
  return useContext(HelpCenterSSOContext);
};
