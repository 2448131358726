import clsx from 'clsx';
import styles from './TopbarSelector.module.scss';
import { Text } from 'common/components/Text';
import React from 'react';
import { useFormatMessage } from 'modules/messages';

export type TopBar = 'dark' | 'light';

type Props = {
  isDarkMode: boolean;
  onChange: (value: boolean) => void;
};

export const TopbarSelector: React.FC<Props> = ({ isDarkMode, onChange }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.topbar, isDarkMode && styles.selectedTopBar)}
        onClick={() => onChange(true)}
      >
        <Text style='xxbold1220'>{formatMessage('charityForms.dark')}</Text>
      </div>
      <div
        className={clsx(styles.topbar, !isDarkMode && styles.selectedTopBar)}
        onClick={() => onChange(false)}
      >
        <Text style='xxbold1220'>{formatMessage('charityForms.light')}</Text>
      </div>
    </div>
  );
};
