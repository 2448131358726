import { CurrencyEnum } from 'common/types';
import { useFormatMessage } from 'modules/messages';
import { PriceBase } from 'modules/common-data-fields';

type PriceProps = {
  amount: string;
  currency1: CurrencyEnum;
  currency2: CurrencyEnum;
};

export const FinalPrice: React.FC<PriceProps> = ({
  amount,
  currency1,
  currency2,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <PriceBase
      title={formatMessage('trade.finalPrice')}
      currency1={currency1}
      currency2={currency2}
      currencyValue={amount}
    />
  );
};
