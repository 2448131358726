import { CurrencyEnum } from 'common/types';
import styles from './DailyEarn.module.scss';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { Text } from 'common/components/Text';
import { Pill } from 'common/components/Pill';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { InfoTooltip } from 'common/components/InfoTooltip';
import { useFormatMessage } from 'modules/messages';

type Props = {
  currency?: CurrencyEnum;
  dailyEarn?: string;
  isLoading: boolean;
  className?: string;
};

export const DailyEarn: React.FC<Props> = ({
  currency,
  dailyEarn,
  isLoading,
  className,
}) => {
  const formatMessage = useFormatMessage();

  if (isLoading) {
    return <RotatingLoader size={16} type={'secondary'} />;
  }
  if (typeof dailyEarn === 'undefined') {
    return <Text style={'light1425'}>{'-'}</Text>;
  }
  if (toDecimal(dailyEarn).eq(0)) {
    return (
      <div className={styles.zeroEarnPillContainer}>
        <InfoTooltip
          text={formatMessage('saving.investedAmountTooLowForEarning')}
        />
        <Pill text={`0 ${currency}`} className={styles.zeroEarnPill} />
      </div>
    );
  }
  return (
    <Text style='xxbold1619' className={className}>{`${toLimitedPrec(
      toFixed(dailyEarn)
    )} ${currency}`}</Text>
  );
};
