import { CellTextBold } from 'common/components/table-subcomponents';
import { useFormatMessage } from 'modules/messages';
import styles from './TransactionsTableHeader.module.scss';

type Props = {};

export const TransactionsTableHeader: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.header}>
      <div> </div>
      <CellTextBold>{formatMessage('transactions.transaction')}</CellTextBold>
      <CellTextBold>{formatMessage('common.amount')}</CellTextBold>
      <CellTextBold>{formatMessage('transactions.status')}</CellTextBold>
      <div> </div>
    </div>
  );
};
