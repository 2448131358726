import { Section } from 'common/components/Section';
import { SectionHeader } from 'common/components/SectionHeader';
import { useFormatMessage } from 'modules/messages';
import { ConfirmationBox } from './section-transactions/ConfirmationBox';
import { MultiApprovalBox } from './section-transactions/MultiapprovalBox';
import { ShowAllButton } from './ShowAllButton';
import styles from './SectionTransactions.module.scss';
import { Text } from 'common/components/Text';
import { ActiveTransactionsNotificationCircle } from 'modules/notifications/ActiveTransactionsNotificationCircle';
import { ShortTransactionsTable } from 'modules/transactions/components/ShortTransactionsTable';

export const SectionTransactions = () => {
  const formatMessage = useFormatMessage();

  return (
    <Section>
      <SectionHeader
        header={
          <div className={styles.header}>
            <Text style='xxbold3644'>
              {formatMessage('transactions.header')}
            </Text>
            <ActiveTransactionsNotificationCircle />
          </div>
        }
        actions={<ShowAllButton path='/transactions' />}
      />
      <div className={styles.boxes}>
        <ConfirmationBox />
        <MultiApprovalBox />
      </div>
      <ShortTransactionsTable />
    </Section>
  );
};
