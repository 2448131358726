import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { apiRaw } from 'modules/api';

const HELP_CENTER_SSO_URL = '/help-center-sso';

const HELP_CENTER_SSO_QUERY_KEY = ['GET', HELP_CENTER_SSO_URL] as const;

type HelpCenterSSOResponseData = {
  jwt: string;
};

type HelpCenterSSOResponseError = {
  detail?: string;
};

const getHelpCenterSSO = () => {
  return apiRaw.get<HelpCenterSSOResponseData>(HELP_CENTER_SSO_URL);
};

export const useHelpCenterSSOQuery = (
  options?: UseQueryOptions<
    AxiosResponse<HelpCenterSSOResponseData>,
    AxiosError<HelpCenterSSOResponseError>
  >
) =>
  useQuery<
    AxiosResponse<HelpCenterSSOResponseData>,
    AxiosError<HelpCenterSSOResponseError>
  >(HELP_CENTER_SSO_QUERY_KEY, getHelpCenterSSO, options);
