import { useEffect } from 'react';
import { Alert, Severity } from 'common/components/Alert';
import { Text } from 'common/components/Text';
import styles from './Snackbar.module.scss';

const DEFAULT_SNACKBAR_OPEN_TIME = 6000;

type Props = {
  message: string;
  severity: Severity;
  onClose: () => void;
};

export const Snackbar: React.FC<Props> = ({ message, severity, onClose }) => {
  useEffect(() => {
    const handle = window.setTimeout(onClose, DEFAULT_SNACKBAR_OPEN_TIME);
    return () => {
      window.clearTimeout(handle);
    };
  }, []);

  return (
    <Alert
      className={styles.container}
      text={
        <Text style='xbold1417' dataTest='snackbarText'>
          {message}
        </Text>
      }
      severity={severity}
      onClose={onClose}
    />
  );
};
