import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { Button, CancelButton } from 'common/components/button';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { MAX_INPUT_CHARACTERS_LIMIT_LONG } from 'common/consts/consts';
import { prettifyError } from 'common/utils/prettify-error';
import { validateLengthLong } from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { WhitelistedAddressResponseError } from '../api';
import { isNameToLongError, isNameUsedError } from '../utils';

export const EditStep: React.FC<{
  onClick: (name: string) => void;
  oldName: string;
  error?: AxiosError<WhitelistedAddressResponseError>;
  isLoading: boolean;
  onCancel: () => void;
}> = ({ onClick, oldName, error, isLoading, onCancel }) => {
  const formatMessage = useFormatMessage();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const validateAndSubmit = () => {
    if (!name.trim()) {
      setNameError(formatMessage('addressWhitelist.errors.nameRequired'));
      return;
    }
    if (!validateLengthLong(name.trim())) {
      setNameError(
        formatMessage('common.error.characters.limit', {
          limit: MAX_INPUT_CHARACTERS_LIMIT_LONG,
        })
      );
      return;
    }
    setNameError('');
    onClick(name);
  };

  const validateAndChange = (value: string) => {
    if (!value.trim()) {
      setNameError(formatMessage('addressWhitelist.errors.nameRequired'));
    } else if (!validateLengthLong(value.trim())) {
      setNameError(
        formatMessage('common.error.characters.limit', {
          limit: MAX_INPUT_CHARACTERS_LIMIT_LONG,
        })
      );
    } else {
      setNameError('');
    }
    setName(value);
  };

  const getErrorText = (error: AxiosError<WhitelistedAddressResponseError>) => {
    if (isNameToLongError(error)) {
      return formatMessage('addressWhitelist.errors.nameToLong');
    }
    if (isNameUsedError(error)) {
      return formatMessage('addressWhitelist.errors.nameUsed');
    }
    return prettifyError(error);
  };

  return (
    <>
      <ModalHeader>
        {formatMessage('addressWhitelist.editWhitelistedAddress')}
      </ModalHeader>
      <ModalBody>
        {error && !error?.response?.data?.status && (
          <Alert severity='error' text={getErrorText(error)} />
        )}
        <InputWithHeader
          header={formatMessage('common.addressName')}
          value={name}
          onChange={(e) => validateAndChange(e.target.value)}
          autofocus={true}
          placeholder={oldName}
          onEnter={() => {
            document.getElementById(`btn-edit-whitelist-address`)?.click();
          }}
          errorText={nameError}
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <Button
            id={`btn-edit-whitelist-address`}
            type='dark'
            name={formatMessage('common.button.confirm')}
            onClick={validateAndSubmit}
            isLoading={isLoading}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
