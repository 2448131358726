import { useFormatMessage } from 'modules/messages';
import styles from './StepThankYou.module.scss';
import { Container, Field } from './components';
import { PrimaryButton } from '../components/Button';
import { useCharityFormPublicContext } from '../context';
import { Text } from 'common/components/Text';
import { useNewDonationContext } from './context';
import { ReactComponent as ThankYouImage } from './assets/thanks.svg';

type Props = {
  onFinish: () => void;
};

export const StepThankYou: React.FC<Props> = ({ onFinish }) => {
  const formatMessage = useFormatMessage();

  const { form } = useCharityFormPublicContext();

  const context = useNewDonationContext();

  return (
    <Container>
      <Field>
        <Text style='xxbold3048'>
          {formatMessage('charityForms.youreGreatHearted', {
            name: context.name,
          })}
        </Text>
        <Text style='light1624'>
          {formatMessage('charityForms.thankYouForDonation')}
        </Text>
      </Field>
      <ThankYouImage className={styles.thankYouImage} />
      <PrimaryButton
        name={formatMessage('charityForms.mainPage')}
        primaryColor={form.primary_color}
        secondaryColor={form.secondary_color}
        onClick={onFinish}
      />
    </Container>
  );
};
