import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  SAVING_QUERY_KEY,
  amountExceedsSavingBalance,
  amountMustBeGreaterThanZero,
  amountTooBigDueToPendingTransactions,
  existPendingWithdrawalTransactionsAgainstFullBalance,
  savingHasTopupTransactionInPendingState,
  savingHasWithdrawalTransactionInPendingState,
  savingIsNotActive,
  SavingWithdrawEffectResponseError,
  SavingWithdrawEffectResponseData,
  useWithdrawEffectMutation,
  SavingWithdrawEffectPayload,
} from 'modules/financial-ops/savings-account/api';
import debounce from 'lodash/debounce';
import { useFormatMessage } from 'modules/messages';
import { toDecimal, toFixed } from 'modules/input-amount';
import { useSavingWithdrawContext } from './context';

type InputData = {
  amount?: string;
  wallet?: number;
};

export const useCalculateEffect = () => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const context = useSavingWithdrawContext();
  const mutation = useWithdrawEffectMutation(context.saving?.id!);

  const onEffectCalculated = useCallback(
    (
      effect: SavingWithdrawEffectResponseData,
      payload: SavingWithdrawEffectPayload
    ) => {
      context.setErrorAlert('');
      context.setAmountError('');

      if (
        context.saving &&
        !toDecimal(context.saving.current_max_to_withdraw).eq(
          context.saving.balance
        )
      ) {
        context.setRemainingFundsInfo(
          formatMessage('saving.balanceIncludesPendingTransactions')
        );
        return;
      }

      context.setRemainingFundsInfo('');
    },
    []
  );

  const onEffectError = useCallback(
    (
      error: SavingWithdrawEffectResponseError,
      payload: SavingWithdrawEffectPayload
    ) => {
      const amountExceedsSavingBalanceMatch = amountExceedsSavingBalance(error);

      if (amountExceedsSavingBalanceMatch) {
        context.setAmountError(
          formatMessage('saving.withdrawAmountMustBeLessOrEqual', {
            amount: toFixed(amountExceedsSavingBalanceMatch[1]),
            currency: context.currency?.currency,
          })
        );
      }

      const amountTooBigDueToPendingTransactionsMatch =
        amountTooBigDueToPendingTransactions(error);

      if (amountTooBigDueToPendingTransactionsMatch) {
        context.setAmountError(
          formatMessage(
            'saving.dueToPendingWithdrawalTransactionsMaximumAmount',
            {
              amount: toFixed(amountTooBigDueToPendingTransactionsMatch[1]),
              currency: context.currency?.currency,
            }
          )
        );
        if (context.saving)
          queryClient.invalidateQueries(SAVING_QUERY_KEY(context.saving.id));
      }

      if (amountMustBeGreaterThanZero(error)) {
        context.setAmountError(
          formatMessage('inputAmount.error.amountMustBeGreaterThanZero')
        );
      }

      if (existPendingWithdrawalTransactionsAgainstFullBalance(error)) {
        context.setErrorAlert(
          formatMessage(
            'saving.transactionForClosingSavingsAccountAlreadyInitiated'
          )
        );

        if (context.saving)
          queryClient.invalidateQueries(SAVING_QUERY_KEY(context.saving.id));
        context.setDisabledInput(true);
      } else {
        context.setDisabledInput(false);
      }

      if (savingHasWithdrawalTransactionInPendingState(error)) {
        context.setErrorAlert(
          formatMessage('saving.savingHasWithdrawalTransactionInPendingState')
        );
      }

      if (savingHasTopupTransactionInPendingState(error)) {
        context.setErrorAlert(
          formatMessage('saving.savingHasTopupTransactionInPendingState')
        );
      }

      if (savingIsNotActive(error)) {
        context.setErrorAlert(
          formatMessage('saving.savingHasBeenClosedWithdrawIsNotAvailable')
        );
      }
    },
    []
  );

  const mutateDebounced = useCallback(
    debounce((payload: SavingWithdrawEffectPayload) => {
      mutation.mutate(payload, {
        onSuccess: (response) => {
          onEffectCalculated(response.data, payload);
        },
        onError: (err) => {
          if (err.response) {
            onEffectError(err.response?.data, payload);
          }
        },
      });
    }, 200),
    []
  );

  // take input from last user interaction, merge it with previous input stored in context, and call /effect endpoint
  const calculateEffect = (inputData: InputData) => {
    const data: InputData = {
      amount: context.amount,
      wallet: context.wallet?.id,
      ...inputData,
    };

    if (!data.wallet) {
      return;
    }

    const commonPayload: SavingWithdrawEffectPayload = {
      wallet_id: data.wallet,
      summary: false,
    };

    if (toDecimal(data.amount).gt(0)) {
      mutateDebounced({
        ...commonPayload,
        amount: toFixed(data.amount),
      });
    }
  };

  return { calculateEffect, mutation };
};
