import { LoaderBox } from 'common/components/loader';
import { Text } from 'common/components/Text';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { useParams } from 'react-router-dom';
import { useBorrowByIdQuery } from '../api';
import { useFormatMessage } from 'modules/messages';
import { useIsKyxCompleted } from 'modules/kyx';
import styles from './BorrowTopupBar.module.scss';
import { Button } from 'common/components/button/Button';
import { useState } from 'react';
import { BorrowTopup } from '../borrow-topup';
import clsx from 'clsx';

export const BorrowTopupBar: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { id } = useParams();
  const { data, isLoading, error } = useBorrowByIdQuery(parseInt(id!));

  const [isOpenTopup, setIsOpenTopup] = useState(false);

  const featureFlag = useRequireFeatureFlag('BORROW');
  const isKyxCompleted = useIsKyxCompleted();

  if (featureFlag.isLoading) {
    return <LoaderBox />;
  }

  if (
    !featureFlag.data?.enabled ||
    !id ||
    !data?.data ||
    error ||
    !isKyxCompleted ||
    data.data.auto_topup ||
    data.data.state !== 'MARGIN_CALL'
  ) {
    return null;
  }

  return (
    <>
      <div
        className={clsx(
          styles.container,
          data?.data.has_pending_topup_transaction && styles.hideBar
        )}
      >
        <Text style='xbold1425'>
          {formatMessage('borrow.topupCollateralToReduceLtvLevel')}
        </Text>
        <Button
          type='darkSmall'
          name={formatMessage('common.topUp')}
          onClick={() => setIsOpenTopup(true)}
          isLoading={isLoading}
          className={styles.button}
        />
      </div>
      <BorrowTopup
        open={isOpenTopup}
        onClose={() => {
          setIsOpenTopup(false);
        }}
        id={parseInt(id!)}
        borrow={data?.data}
      />
    </>
  );
};
