import { createContext, useContext, useState } from 'react';

type NotificationsMenuContextValue = {
  isOpen: boolean;
  closeNotifications: () => void;
  openNotifications: () => void;
  toggleNotifications: () => void;
};

const NotificationsMenuContext = createContext<NotificationsMenuContextValue>({
  isOpen: false,
  closeNotifications: () => {},
  openNotifications: () => {},
  toggleNotifications: () => {},
});

export const NotificationsMenuContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NotificationsMenuContext.Provider
      value={{
        isOpen,
        closeNotifications: () => setIsOpen(false),
        openNotifications: () => setIsOpen(true),
        toggleNotifications: () => setIsOpen((prevIsOpen) => !prevIsOpen),
      }}
    >
      {children}
    </NotificationsMenuContext.Provider>
  );
};

export const useNotificationsMenuContext = () => {
  return useContext(NotificationsMenuContext);
};
