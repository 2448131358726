import { SummaryAsset } from 'modules/financial-ops/common';
import { BubbleChart } from 'modules/financial-ops/common/components/overview-components';
import { BubbleChartPlaceholder } from 'modules/financial-ops/common/components/overview-components/BubbleChartPlaceholder';
import { toDecimal } from 'modules/input-amount';
import { useFormatMessage } from 'modules/messages';

type Props = { assets?: SummaryAsset[]; id: string };

export const BorrowBubbleChart: React.FC<Props> = ({ assets, id }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      {assets?.some((item) => toDecimal(item.share_pct).greaterThan(0)) ? (
        <BubbleChart items={assets} id={id} />
      ) : (
        <BubbleChartPlaceholder
          text={formatMessage('borrow.noFundsLockedAsCollateral')}
        />
      )}
    </>
  );
};
