import { PageLoader } from 'common/components/loader';
import { TradeDashboard } from 'modules/financial-ops/trade';
import { ExpandableSidebarNavigationLayout } from 'modules/layout';
import { useLoginRequired } from 'modules/login';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const Trade: React.FC<{}> = () => {
  const { isLoading } = useLoginRequired();

  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.trade')}</title>
      </Helmet>
      {isLoading ? (
        <PageLoader />
      ) : (
        <ExpandableSidebarNavigationLayout>
          <TradeDashboard />
        </ExpandableSidebarNavigationLayout>
      )}
    </>
  );
};
