import { ReactComponent as ArrowIcon } from 'common/assets/black-icons/arrowhead-down-12px-withpadding.svg';
import { LoaderBox } from 'common/components/loader';
import { Text } from 'common/components/Text';
import { formatDatetimeWithoutSec } from 'common/utils/utils';
import { MessageKey, useFormatMessage } from 'modules/messages';
import { useState } from 'react';
import { useBorrowHistoryByIdQuery } from '../api';
import { BorrowHistoryEventKind } from '../types';
import styles from './BorrowHistory.module.scss';

const MAX_FOLDED = 10;

type Props = {
  borrowId: number;
};

export const BorrowHistory: React.FC<Props> = ({ borrowId }) => {
  const formatMessage = useFormatMessage();

  const [expanded, setExpanded] = useState(false);

  const { data, isLoading } = useBorrowHistoryByIdQuery(borrowId);

  return (
    <div className={styles.container}>
      <Text style='xxbold2024' className={styles.header}>
        {formatMessage('borrow.borrowHistory')}
      </Text>
      {isLoading && <LoaderBox />}
      {data &&
        data.data
          .slice(0, expanded ? data.data.length : MAX_FOLDED)
          .map((historyItem) => (
            <div className={styles.historyItem} key={historyItem.created_at}>
              <Text style='light1215'>
                {formatMessage(eventNames[historyItem.kind])}
              </Text>
              <Text
                style='light1215'
                colorStyle='secondary'
                className={styles.datetime}
              >
                {formatDatetimeWithoutSec(historyItem.created_at)}
              </Text>
            </div>
          ))}
      {!expanded && data && data.data.length > MAX_FOLDED && (
        <ShowMore
          onClick={() => {
            setExpanded(true);
          }}
        />
      )}
    </div>
  );
};

const eventNames: { [key in BorrowHistoryEventKind]: MessageKey } = {
  BORROW_ACTIVE: 'borrow.borrowStarted',
  BORROW_OVERCOLLATERALIZED: 'borrow.borrowOvercollateralizedState',
  BORROW_COLLATERAL_EXCESS_RETURN: 'borrow.borrowOvercollaterizationTransfer',
  BORROW_NEAR_MARGIN_CALL: 'borrow.nearMarginCallState',
  BORROW_NEAR_MARGIN_CALL_CANCELLED: 'borrow.nearMarginCallCancelled',
  BORROW_MARGIN_CALL: 'borrow.marginCallState',
  BORROW_MARGIN_CALL_CANCELLED: 'borrow.marginCallStateCancelled',
  BORROW_COLLATERAL_TOPUP_AUTO: 'borrow.marginCallTransfer',
  BORROW_COLLATERAL_TOPUP: 'borrow.collateralTopup',
  BORROW_REPAID: 'borrow.borrowRepaid',
  BORROW_LIQUIDATION: 'borrow.borrowLiquidation',
  BORROW_LIQUIDATED: 'borrow.borrowLiquidated',
  BORROW_CANCELLED: 'borrow.borrowCancelled',
};

const ShowMore: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.showMore} onClick={onClick}>
      <Text style='xxbold1417'>{formatMessage('common.showMore')}</Text>
      <ArrowIcon />
    </div>
  );
};
