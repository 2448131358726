import { useFormatMessage } from 'modules/messages';
import { Text, textStyles } from 'common/components/Text';
import styles from './Inputs2FA.module.scss';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { SectionSubheader } from 'common/components/SectionSubheader';

export const CODE_2FA_LENGTH = 6;

type Props = {
  onCodeChange: (code: string) => void;
  error?: string;
  onError: (err: string | undefined) => void;
};

export const Inputs2FA: React.FC<Props> = ({
  onCodeChange,
  error,
  onError,
}) => {
  const formatMessage = useFormatMessage();
  const [values, setValues] = useState(Array(CODE_2FA_LENGTH).fill(''));

  useEffect(() => {
    onError(undefined);
    document.getElementById('2fa-input-0')?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaste = (data: string) => {
    const dataArray = data.substring(0, CODE_2FA_LENGTH);
    setValues(dataArray.split(''));
    onCodeChange(dataArray);

    document
      .getElementById(
        `2fa-input-${
          data.length <= values.length ? data.length - 1 : values.length - 1
        }`
      )
      ?.focus();
  };

  const onKeyDown = (data: string, i: number) => {
    onError(undefined);
    const last = values[i];

    const newValues = [...values];
    newValues[i] = data;

    setValues(newValues);
    onCodeChange(newValues.join(''));

    if (i > 0 && !data && !last) {
      document.getElementById(`2fa-input-${i - 1}`)?.focus();
      return;
    }

    if (i !== CODE_2FA_LENGTH - 1 && data) {
      document.getElementById(`2fa-input-${i + 1}`)?.focus();
    }
  };

  return (
    <div>
      <SectionSubheader>
        {formatMessage('2FA.googleAuthenticatorCode')}
      </SectionSubheader>
      <div className={styles.container}>
        {values.map((_, i) => (
          <input
            key={i}
            value={values[i]}
            id={`2fa-input-${i}`}
            type='number'
            autoComplete='off'
            className={clsx(
              textStyles.bold3048,
              styles.input,
              error && styles.errorInput,
              error && styles.containerError
            )}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let data = e.target.value ?? '';
              if (
                (data.length > 1 && data.length < CODE_2FA_LENGTH) ||
                data.length > CODE_2FA_LENGTH
              ) {
                e.preventDefault();
              } else if (data.length === CODE_2FA_LENGTH) {
                onPaste(data);
              } else {
                onKeyDown(data, i);
              }
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (
                e.code === 'Minus' ||
                e.code === 'KeyE' ||
                e.code === 'Equal'
              ) {
                e.preventDefault();
              }
              if (e.key === 'Backspace') {
                if (i > 0 && !values[i]) {
                  document.getElementById(`2fa-input-${i - 1}`)?.focus();
                }
              }
            }}
          />
        ))}
      </div>
      {error && (
        <div className={styles.bottomText}>
          <Text
            style='regular1215'
            colorStyle={'error'}
            dataTest='errorText'
            className={styles.errorText}
          >
            {error}
          </Text>
          <Text
            style='regular1215'
            colorStyle={'secondary'}
            className={styles.clear}
            onClick={() => {
              setValues(Array(CODE_2FA_LENGTH).fill(''));
              onCodeChange('');
              onError(undefined);
              document.getElementById('2fa-input-0')?.focus();
            }}
          >
            {formatMessage('2FA.clear')}
          </Text>
        </div>
      )}
    </div>
  );
};
