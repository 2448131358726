import { NothingToDisplay } from 'modules/nothing-to-display';
import styles from './LendDetails.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequireFeatureFlag } from 'modules/feature-flags';
import { LoaderBox } from 'common/components/loader';
import { Section } from 'common/components/Section';
import { Button } from 'common/components/button';
import { DetailsPageSectionHeader } from './components';
import { useFormatMessage } from 'modules/messages';
import { LendName } from '../common';
import { useLendDetails } from '../api';
import { LendData } from './components/LendData';
import { LendTerminationModal } from '../lend-termination';
import { useState } from 'react';
import { Tooltip } from 'common/components/Tooltip';

export const LendDetails: React.FC<{}> = () => {
  const formatMessage = useFormatMessage();

  const { id } = useParams();

  const featureFlag = useRequireFeatureFlag('LEND');

  const navigate = useNavigate();

  const [isLendTerminationModalOpen, setIsLendTerminationModalOpen] =
    useState(false);

  const {
    data: lend,
    isLoading: isLoadingLend,
    error: lendError,
  } = useLendDetails(parseInt(id ?? ''));

  if (!id) {
    return <NothingToDisplay />;
  }

  if (featureFlag.isLoading) {
    return <LoaderBox />;
  }

  if (!featureFlag.data?.enabled) {
    return null;
  }

  return (
    <Section>
      <DetailsPageSectionHeader
        actions={
          lend && lend.state === 'ACTIVE' ? (
            <Tooltip
              title={
                lend.has_pending_termination
                  ? formatMessage('lend.terminationHasBeenInitiatedAlready')
                  : ''
              }
            >
              <span>
                <Button
                  disabled={lend.has_pending_termination}
                  name={formatMessage('lend.terminate')}
                  type='secondary'
                  onClick={() => {
                    setIsLendTerminationModalOpen(true);
                  }}
                />
              </span>
            </Tooltip>
          ) : null
        }
        header={formatMessage('lend.lendDetails')}
        onBack={() => {
          navigate('/lends');
        }}
      />
      {lend && (
        <div className={styles.contentContainer}>
          <LendName
            principalCurrency={lend.principal_currency}
            term={lend.term}
            state={lend.state}
            textStyle={'xxbold2024'}
          />
          <LendData lend={lend} />
        </div>
      )}
      {isLoadingLend && <LoaderBox />}
      {lendError && <NothingToDisplay />}
      {isLendTerminationModalOpen && (
        <LendTerminationModal
          lendId={lend!.id}
          onClose={() => {
            setIsLendTerminationModalOpen(false);
          }}
        />
      )}
    </Section>
  );
};
