import { useState } from 'react';
import { NextButton, BackButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import { MAX_INPUT_CHARACTERS_LIMIT_SHORT } from 'common/consts/consts';
import { validateLengthShort } from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';

type Props = {
  onSubmit: (firstName: string, surname: string) => void;
  onGoBack: () => void;
  userFirstName: string;
  userSurname: string;
};

export const StepUsername: React.FC<Props> = ({
  onSubmit,
  onGoBack,
  userFirstName: userFirstNameProp,
  userSurname: userSurnameProp,
}) => {
  const formatMessage = useFormatMessage();

  const [userFirstName, setUserFirstName] = useState(userFirstNameProp);
  const [userSurname, setUserSurname] = useState(userSurnameProp);
  const [errorName, setErrorName] = useState('');
  const [errorSurname, setErrorSurname] = useState('');

  const validateAndSubmit = () => {
    const userFirstNameTrim = userFirstName.trim();
    const userSurnameTrim = userSurname.trim();
    if (!userFirstNameTrim) {
      setErrorName(formatMessage('common.fieldRequired'));
      return;
    }
    if (!userSurnameTrim) {
      setErrorSurname(formatMessage('common.fieldRequired'));
      return;
    }
    if (!errorName && !errorSurname)
      onSubmit(userFirstNameTrim, userSurnameTrim);
  };

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('signup.enterNameSurname')}
      </ModalHeader>
      <ModalBody>
        <>
          <InputWithHeader
            header={formatMessage('common.name')}
            value={userFirstName}
            onChange={(e) => {
              const data = e.target.value;
              if (!validateLengthShort(data)) {
                setErrorName(
                  formatMessage('common.error.characters.limit', {
                    limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
                  })
                );
              } else {
                setErrorName('');
              }
              setUserFirstName(data);
            }}
            onEnter={() => {
              document.getElementById('CreateCompany-input-surname')?.focus();
            }}
            errorText={errorName}
            autofocus
            dataTest='nameInput'
          />
          <InputWithHeader
            header={formatMessage('common.surname')}
            value={userSurname}
            onChange={(e) => {
              const data = e.target.value;
              if (!validateLengthShort(data)) {
                setErrorSurname(
                  formatMessage('common.error.characters.limit', {
                    limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
                  })
                );
              } else {
                setErrorSurname('');
              }
              setUserSurname(data);
            }}
            onEnter={validateAndSubmit}
            errorText={errorSurname}
            id={'CreateCompany-input-surname'}
            dataTest='surnameInput'
          />
        </>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <NextButton onClick={validateAndSubmit} />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
