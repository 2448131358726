import { BackButton, NextButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { FormTextarea } from './FormTextarea';
import { useState } from 'react';
import { ThumbnailsList } from './ThumbnailsList';
import { HeaderWithDescription } from './HeaderWithDescription';
import { DragAndDrop } from './DragAndDrop';
import { useNewCharityFormContext } from '../context';
import {
  PostCharityFormPicturesPayload,
  useCharityFormsPicturesMutation,
} from 'modules/charity-forms/api';
import styles from './StepSetMultimedia.module.scss';
import { Alert } from 'common/components/Alert';
import { MultimediaProps } from 'modules/charity-forms/types';
import {
  HTTP_HTTPS_REGEX,
  YOUTUBE_ID_REGEX,
  matchYoutubeUrl,
  reorderFiles,
} from '../utils';
import { FileRejection } from 'react-dropzone';

type Props = {
  onGoNext: () => void;
  onGoBack: () => void;
};

export const StepSetMultimedia: React.FC<Props> = ({ onGoNext, onGoBack }) => {
  const formatMessage = useFormatMessage();
  const [linkError, setLinkError] = useState('');
  const [filesError, setFilesError] = useState('');
  const [alertError, setAlertError] = useState('');
  const context = useNewCharityFormContext();
  const picturesMutation = useCharityFormsPicturesMutation();

  const validateAndContinue = () => {
    if (!context.files?.filter((item) => item.kind === 'PHOTO').length) {
      setAlertError(
        formatMessage('charityForms.addFromOneToFourPhotosMaximumSize2MBEach')
      );
      return;
    }

    if (linkError) {
      return;
    }

    setAlertError('');
    setFilesError('');
    onGoNext();
  };

  const postPictures = (items: File[], rejectedFiles: FileRejection[]) => {
    const photosCount = (context.files || []).filter(
      (file) => file.kind === 'PHOTO'
    ).length;
    const availableNumberOfItems = 4 - photosCount;
    const availableItems = items.slice(0, availableNumberOfItems);

    if (
      !items ||
      !items.length ||
      !!rejectedFiles.length ||
      !availableNumberOfItems
    ) {
      setFilesError(
        formatMessage('charityForms.addFromOneToFourPhotosMaximumSize2MBEach')
      );
      return;
    }

    if (items.length > availableNumberOfItems) {
      setFilesError(
        formatMessage('charityForms.addFromOneToFourPhotosMaximumSize2MBEach')
      );
    } else {
      setFilesError('');
    }

    const payload: PostCharityFormPicturesPayload[] = availableItems.map(
      (file) => {
        return { file, kind: 'CONTENT' };
      }
    );

    picturesMutation.mutate(payload, {
      onSuccess: (response) => {
        const newValues = response.map((item) => {
          return {
            id: item.data.id,
            url: item.data.file,
            kind: 'PHOTO' as const,
          };
        });

        if (context.files && context.files.length > 0) {
          const newFiles = [...context.files, ...newValues].slice(0, 5);
          context.setFiles(newFiles);

          if (context.files[0].kind === 'VIDEO')
            context.setFiles(reorderFiles(newFiles, 0, 1));
        } else {
          context.setFiles(newValues);
        }
      },
    });
  };

  return (
    <>
      <ModalHeader showClose>
        {formatMessage('charityForms.newCharityForm')}
      </ModalHeader>
      <ModalBody>
        <Text
          style='light1425'
          inlineStyle={{ display: 'block', margin: '-10px 0 40px' }}
        >
          {formatMessage('charityForms.uploadPhotosAndShareOptionalLink')}
        </Text>

        <HeaderWithDescription
          title={formatMessage('charityForms.uploadPictures')}
          tooltip={formatMessage(
            'charityForms.addFromOneToFourPhotosMaximumSize2MBEachRecommendedMinimum'
          )}
        />
        <DragAndDrop
          maxFilesSize={4}
          disabled={
            context.files?.filter((item) => item.kind === 'PHOTO').length === 4
          }
          onDrop={postPictures}
          btnText={formatMessage('charityForms.addPictures')}
          description={formatMessage(
            'charityForms.addFromOneToFourPhotosMaximumSize2MBEach'
          )}
          fileFormats={{
            'image/jpeg': [],
            'image/png': [],
          }}
          isLoading={picturesMutation.isLoading}
          error={filesError}
        />
        <ThumbnailsList
          files={context.files}
          youtubeLink={context.youtubeLink}
          errorLink={linkError}
          onFileChange={(value) => {
            context.setFiles(value);
            setAlertError('');
            setFilesError('');
          }}
          onLinkChange={(link) => context.setYoutubeLink(link)}
          disabled={
            context.files?.filter((item) => item.kind === 'PHOTO').length === 4
          }
          onDrop={postPictures}
          isPicturesLoading={picturesMutation.isLoading}
        />
        <FormTextarea
          value={context.youtubeLink ?? ''}
          id='youtubeLink'
          containerClassName={styles.linkContainer}
          textareaClassName={styles.linkTextarea}
          onChange={(val) => {
            context.setYoutubeLink(val !== '' ? val : undefined);

            if (!matchYoutubeUrl(val)) {
              setLinkError(formatMessage('charityForms.linkIsNotValid'));
              if (context.files) {
                const newList = [...context.files].filter(
                  (item) => item.kind === 'PHOTO'
                );
                context.setFiles(newList);
              }
              if (val === '') {
                setLinkError('');
                return;
              }
            } else {
              setLinkError('');

              const containHttps = val.match(HTTP_HTTPS_REGEX);
              if (!containHttps) {
                val = `https://${val}`;
                context.setYoutubeLink(val);
              }

              const matchVideoId = val.match(YOUTUBE_ID_REGEX);
              if (matchVideoId) {
                const newLink = `https://img.youtube.com/vi/${matchVideoId[1]}/hqdefault.jpg`;

                const newFile: MultimediaProps = {
                  id: -1,
                  url: newLink,
                  kind: 'VIDEO',
                };

                if (newFile) {
                  if (context.files) {
                    const filteredFiles = context.files.filter(
                      (file) => file.kind === 'PHOTO'
                    );
                    context.setFiles([...filteredFiles, newFile]);
                    return;
                  }
                  context.setFiles([newFile]);
                }
              }
            }
          }}
          title={
            <div>
              <Text style='bold1422' colorStyle='primary'>
                {formatMessage('charityForms.youTubeLink')}
              </Text>
              <Text style='light1215' colorStyle='inactive'>
                {` (${formatMessage('charityForms.optional')})`}
              </Text>
            </div>
          }
          rows={1}
          inlineHeaderStyles={{ marginTop: '20px' }}
          error={linkError}
        />
        {alertError && (
          <Alert
            text={alertError}
            severity={'error'}
            style={{ marginBottom: '40px' }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <NextButton
            onClick={validateAndContinue}
            disabled={
              picturesMutation.isLoading ||
              !!linkError ||
              !context.picturesIds?.length
            }
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
