import { AxiosError } from 'axios';
import { Alert } from 'common/components/Alert';
import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import { LoaderBox } from 'common/components/loader';
import { MAX_INPUT_CHARACTERS_LIMIT_SHORT } from 'common/consts/consts';
import { prettifyError } from 'common/utils/prettify-error';
import { validateLengthShort } from 'common/utils/validators';
import { useFormatMessage } from 'modules/messages';
import {
  ERR_INVITATION_CODE_EXPIRED,
  ERR_INVITATION_CODE_USED,
  GetInviteeDataError,
} from '../api';

type Props = {
  name: string;
  surname: string;
  email: string;
  company_name: string;
  nameError: string;
  surnameError: string;
  apiError: AxiosError<GetInviteeDataError> | null;
  isLoading: boolean;
  onSubmit: () => void;
  onNameChange: (value: string) => void;
  onSurnameChange: (value: string) => void;
  onNameError: (value: string) => void;
  onSurnameError: (value: string) => void;
};

export const StepUserData: React.FC<Props> = ({
  name,
  surname,
  email,
  company_name,
  nameError,
  surnameError,
  apiError,
  isLoading,
  onSubmit,
  onNameChange,
  onSurnameChange,
  onNameError,
  onSurnameError,
}) => {
  const formatMessage = useFormatMessage();

  const validateAndSubmit = () => {
    if (!name.trim()) {
      onNameError(formatMessage('common.fieldRequired'));
      return;
    } else if (!validateLengthShort(name)) {
      onNameError(
        formatMessage('common.error.characters.limit', {
          limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
        })
      );
      return;
    } else {
      onNameError('');
    }
    if (!surname.trim()) {
      onSurnameError(formatMessage('common.fieldRequired'));
      return;
    } else if (!validateLengthShort(surname)) {
      onSurnameError(
        formatMessage('common.error.characters.limit', {
          limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
        })
      );
      return;
    } else {
      onSurnameError('');
    }

    onSubmit();
  };

  const getErrorText = (apiError: AxiosError<GetInviteeDataError>) => {
    if (!apiError) {
      return '';
    }
    if (apiError.response?.status === 404) {
      return formatMessage('signup.invitationCodeInvalid');
    }
    if (
      apiError.response?.data?.invitation_code?.includes(
        ERR_INVITATION_CODE_EXPIRED
      )
    ) {
      return formatMessage('signup.invitationCodeExpired');
    }
    if (
      apiError.response?.data?.invitation_code?.includes(
        ERR_INVITATION_CODE_USED
      )
    ) {
      return formatMessage('signup.invitationCodeUsed');
    }
    return prettifyError(apiError);
  };

  if (isLoading) {
    return <LoaderBox height='500px' />;
  }

  if (apiError) {
    return <Alert severity='error' text={getErrorText(apiError)} />;
  }

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('signup.welcomeTo', {
          company_name,
        })}
      </ModalHeader>
      <ModalBody>
        <>
          <InputWithHeader
            header={formatMessage('common.email')}
            value={email}
            onChange={() => {}}
            disabled
          />
          <InputWithHeader
            header={formatMessage('common.name')}
            value={name}
            onChange={(e) => {
              const data = e.target.value;
              if (!validateLengthShort(data)) {
                onNameError(
                  formatMessage('common.error.characters.limit', {
                    limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
                  })
                );
              } else {
                onNameError('');
              }
              onNameChange(data);
            }}
            autofocus
            onEnter={() => {
              document
                .getElementById('SignupFromInvitation-input-surname')
                ?.focus();
            }}
            errorText={nameError}
          />
          <InputWithHeader
            header={formatMessage('common.surname')}
            value={surname}
            onChange={(e) => {
              const data = e.target.value;
              if (!validateLengthShort(data)) {
                onSurnameError(
                  formatMessage('common.error.characters.limit', {
                    limit: MAX_INPUT_CHARACTERS_LIMIT_SHORT,
                  })
                );
              } else {
                onSurnameError('');
              }
              onSurnameChange(data);
            }}
            id='SignupFromInvitation-input-surname'
            onEnter={validateAndSubmit}
            errorText={surnameError}
          />
        </>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('common.button.next')}
            type='dark'
            onClick={validateAndSubmit}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
