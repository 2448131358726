import styles from './StepWithdraw.module.scss';
import { useFormatMessage } from 'modules/messages';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Alert } from 'common/components/Alert';
import { Text } from 'common/components/Text';
import { CancelButton, GoToSummaryButton } from 'common/components/button';
import { useCharityFormWithdrawContext } from '../context';
import { AssetAmountInput } from 'modules/financial-ops/common/components/AssetAmountInput';
import { useGetCurrenciesWithWallets } from 'modules/select-currency-wallet/hooks';
import { CharityFormWithdrawEffect } from './CharityFormWithdrawEffect';
import { CharityFormDetailed } from 'modules/charity-forms/types';
import { useCalculateEffect } from '../use-calculate-effect';
import { toDecimal, toFixed } from 'modules/input-amount';
import {
  findCurrency,
  getHighestBalanceWallet,
} from 'modules/financial-ops/common';
import { INITIAL_AMOUNT } from 'modules/financial-ops/common/consts';

type Props = {
  onSummary: () => void;
  onCancel: () => void;
  charityForm: CharityFormDetailed;
};

export const StepWithdraw: React.FC<Props> = ({
  onSummary,
  onCancel,
  charityForm,
}) => {
  const formatMessage = useFormatMessage();
  const context = useCharityFormWithdrawContext();

  const { data: currencies, isLoading: isLoadingCurrencies } =
    useGetCurrenciesWithWallets();

  const filteredCurrencies = currencies?.filter(
    (curr) =>
      charityForm.networks.includes(curr.network) &&
      toDecimal(charityForm.current_balance_by_currency[curr.currency]).gt(0)
  );

  const {
    calculateEffect,
    mutation: { isLoading: isLoadingEffect },
  } = useCalculateEffect();

  return (
    <>
      <ModalHeader
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('charityForms.chooseCurrencyAndWalletToWithdraw')}
          </Text>
        }
      >
        {formatMessage('common.withdrawFunds')}
      </ModalHeader>
      <ModalBody>
        {charityForm && (
          <>
            {context.errorAlert && (
              <Alert
                text={context.errorAlert}
                severity='error'
                className={styles.alert}
              />
            )}
            <AssetAmountInput
              amount={context.amount}
              currency={context.currency}
              wallets={context.currency?.wallets}
              desktopHeader={formatMessage('common.amount')}
              error={context.amountError}
              mobileAssetHeader={formatMessage('common.currency')}
              mobileAmountHeader={formatMessage('common.amount')}
              showApr={false}
              wallet={context.wallet}
              walletError={context.walletError}
              onAmountChange={(value) => {
                context.setAmount(value);
                calculateEffect({
                  amount: value,
                });
              }}
              onCurrencyChange={(currency) => {
                context.setCurrencyKey(currency);
                context.setAmountError('');
                context.setAmount(INITIAL_AMOUNT);
                context.setRemainingFunds(undefined);
                const foundCurrency = findCurrency(currency, currencies);
                const foundWallet = getHighestBalanceWallet(
                  foundCurrency?.wallets
                );
                if (foundWallet) context.setWalletId(foundWallet.id);
              }}
              onWalletChange={(value) => {
                context.setWalletId(value.id);
                context.setWalletError('');
                calculateEffect({
                  wallet: value.id,
                });
              }}
              onMax={() => {
                if (context.currency) {
                  const max =
                    charityForm.current_balance_by_currency[
                      context.currency?.currency
                    ];
                  context.setAmount(toFixed(max));
                  calculateEffect({
                    amount: max,
                  });
                }
              }}
              onError={(error) => context.setAmountError(error)}
              currencies={filteredCurrencies}
              isLoadingCurrencies={isLoadingCurrencies}
            />
            <CharityFormWithdrawEffect
              currency={context.currency}
              remainingFunds={context.remainingFunds}
              isLoading={isLoadingEffect}
              currentFunds={context.currentFunds}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <GoToSummaryButton
            disabled={
              !!context.errorAlert ||
              isLoadingEffect ||
              !context.currency ||
              !!context.amountError
            }
            onClick={onSummary}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
