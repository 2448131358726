import clsx from 'clsx';
import * as R from 'ramda';
import { Alert } from 'common/components/Alert';
import { Initials } from 'common/components/Initials';
import { LoaderBox } from 'common/components/loader';
import {
  CellTextBold,
  CellTextLight,
  HeaderCell,
  SortValue,
} from 'common/components/table-subcomponents';
import {
  SortConfig,
  PopupSorter,
} from 'common/components/table-subcomponents/sort/PopupSorter';
import { prettifyError } from 'common/utils/prettify-error';
import { useFormatMessage } from 'modules/messages';
import { CompanyRolePlus, displayRole, useUserQuery } from 'modules/user';
import { useState } from 'react';
import styles from './MembersTable.module.scss';
import { Member, useInvitationsQuery, useMembersQuery } from '../api';
import { ResendInvitationButton } from './ResendInvitationButton';
import { ascComp, descComp } from 'common/utils/comparators';
import { DeleteMemberButton } from '../delete-member/DeleteMemberButton';

type DataItem = {
  id?: number; // invitation data items have undefined id
  email: string;
  name: string;
  surname: string;
  company_role: CompanyRolePlus;
  is_signer?: boolean; // invitation data items have undefined is_signer
};

type SortModel = {
  name?: SortValue;
  surname?: SortValue;
  email?: SortValue;
  company_role?: SortValue;
};

type Props = {};

export const MembersTable: React.FC<Props> = () => {
  const formatMessage = useFormatMessage();

  const { data: user } = useUserQuery();

  const isOwner = user?.data?.company_role === 'OWNER';

  const {
    data: invitations,
    isLoading: isLoadingInvitations,
    error: invitationsError,
  } = useInvitationsQuery();

  const {
    data: members,
    isLoading: isLoadingMembers,
    error: membersError,
  } = useMembersQuery();

  const data: DataItem[] = [
    ...(members?.data || []),
    ...(invitations?.data || []).map((inv) => ({
      ...inv,
      company_role: 'INVITED' as const,
      is_signer: false,
    })),
  ];

  const [sort, setSort] = useState<SortModel>({});

  const getSortedData = () => {
    if (sort.name) {
      return R.sort(
        (item1, item2) =>
          (sort.name === 'asc' ? ascComp : descComp)(item1.name, item2.name),
        data
      );
    }
    if (sort.surname) {
      return R.sort(
        (item1, item2) =>
          (sort.surname === 'asc' ? ascComp : descComp)(
            item1.surname,
            item2.surname
          ),
        data
      );
    }
    if (sort.email) {
      return R.sort(
        (item1, item2) =>
          (sort.email === 'asc' ? ascComp : descComp)(item1.email, item2.email),
        data
      );
    }
    if (sort.company_role) {
      return R.sort(
        (item1, item2) =>
          (sort.company_role === 'asc' ? ascComp : descComp)(
            item1.company_role,
            item2.company_role
          ),
        data
      );
    }

    // if not sorted, fix owner as the first item
    const ownerIdx = data.findIndex(
      (dataItem) => dataItem.company_role === 'OWNER'
    );
    if (ownerIdx === -1) {
      return data;
    }
    return [
      data[ownerIdx],
      ...data.slice(0, ownerIdx),
      ...data.slice(ownerIdx + 1),
    ];
  };

  const headers: SortConfig[] = [
    {
      sort: sort.name,
      onSort: (next: SortValue) => {
        setSort({ name: next });
      },
      text: formatMessage('common.name'),
      className: styles.cellName,
    },
    {
      sort: sort.surname,
      onSort: (next: SortValue) => {
        setSort({ surname: next });
      },
      text: formatMessage('common.surname'),
      className: styles.cellSurname,
    },
    {
      sort: sort.email,
      onSort: (next: SortValue) => {
        setSort({ email: next });
      },
      text: formatMessage('common.email'),
      className: styles.cellEmail,
    },
    {
      sort: sort.company_role,
      onSort: (next: SortValue) => {
        setSort({ company_role: next });
      },
      text: formatMessage('companyMembers.theader.role'),
      className: styles.cellRole,
    },
  ];

  return (
    <div>
      {invitationsError && (
        <Alert text={prettifyError(invitationsError)} severity='error' />
      )}
      {membersError && (
        <Alert text={prettifyError(membersError)} severity='error' />
      )}
      <div className={clsx(styles.tableItem, styles.headerRow)}>
        {headers.map((item, index) => (
          <HeaderCell
            sort={item.sort}
            onSort={item.onSort}
            className={item.className}
            key={index}
          >
            {item.text}
          </HeaderCell>
        ))}
      </div>
      <div className={styles.mobileHeaderRow}>
        <PopupSorter config={headers} isOnLeft={true} />
      </div>
      {isLoadingInvitations || isLoadingMembers ? (
        <LoaderBox />
      ) : (
        <>
          {getSortedData().map((dataItem, i) => (
            <div
              className={styles.tableItem}
              key={dataItem.email}
              data-test={`row-${i}`}
            >
              <Initials
                name={dataItem.name}
                surname={dataItem.surname}
                className={styles.cellInitials}
              />
              <CellTextBold dataTest='membersName' className={styles.cellName}>
                {dataItem.name}
              </CellTextBold>
              <CellTextBold
                dataTest='membersSurname'
                className={styles.cellSurname}
              >
                {dataItem.surname}
              </CellTextBold>
              <CellTextLight
                dataTest='membersEmail'
                className={styles.cellEmail}
              >
                {dataItem.email}
              </CellTextLight>
              <CellTextLight dataTest='membersRole' className={styles.role}>
                {displayRole(formatMessage, dataItem.company_role)}
              </CellTextLight>
              {isOwner && dataItem.company_role !== 'OWNER' && (
                <div className={styles.cellActions}>
                  {dataItem.company_role === 'INVITED' ? (
                    <ResendInvitationButton invitation={dataItem} />
                  ) : (
                    <DeleteMemberButton member={dataItem as Member} />
                  )}
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};
