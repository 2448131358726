import { useQueryClient } from '@tanstack/react-query';
import { useFeatureFlag } from 'modules/feature-flags';
import { USER_QUERY_KEY, useUserQuery } from 'modules/user';
import { useCallback, useEffect } from 'react';

export const useCompanyKyxState = () => {
  const { data, isLoading } = useUserQuery();

  return {
    data: data
      ? {
          kyc: data.data.company.kyc_state,
          kyb: data.data.company.kyb_state,
        }
      : undefined,
    isLoading,
  };
};

export const useIsKyxCompleted = () => {
  const kybFeatureFlag = useFeatureFlag('KYB');

  const { data } = useCompanyKyxState();

  return (
    (kybFeatureFlag.data?.enabled ? data?.kyb === 'COMPLETED' : true) &&
    data?.kyc === 'COMPLETED'
  );
};

export const useRefreshKyx = () => {
  const queryClient = useQueryClient();

  const refreshKyx = useCallback(() => {
    queryClient.invalidateQueries(USER_QUERY_KEY);
  }, [queryClient]);

  return refreshKyx;
};

export const useRefreshKyxEffect = () => {
  const refreshKyx = useRefreshKyx();

  useEffect(() => {
    refreshKyx();
  }, []);
};
