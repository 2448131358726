import { useFormatMessage } from 'modules/messages';
import { Button } from 'common/components/button';
import { BorrowDetails } from '../types';
import { useState } from 'react';
import { BorrowRepay } from '../borrow-repay/BorrowRepay';
import { Tooltip } from 'common/components/Tooltip';

type Props = { borrow?: BorrowDetails; className?: string };

export const ButtonBorrowRepay: React.FC<Props> = ({ borrow, className }) => {
  const formatMessage = useFormatMessage();
  const [openRepay, setOpenRepay] = useState(false);

  return (
    <>
      {borrow?.can_be_repaid && (
        <Tooltip
          title={
            borrow.has_pending_repay_transaction
              ? formatMessage('borrow.borrowRepaymentTransactionIsPending')
              : ''
          }
        >
          <span className={className}>
            <Button
              name={formatMessage('borrow.repay')}
              type='dark'
              onClick={() => setOpenRepay(true)}
              disabled={borrow.has_pending_repay_transaction}
              className={className}
            />
          </span>
        </Tooltip>
      )}
      {borrow && (
        <BorrowRepay
          open={openRepay}
          onClose={() => setOpenRepay(false)}
          id={borrow.id}
        />
      )}
    </>
  );
};
