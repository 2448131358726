import { Text } from 'common/components/Text';
import styles from './ImagesAndVideos.module.scss';
import { useFormatMessage } from 'modules/messages';
import { DetailsMultimediaProps } from 'modules/charity-forms/types';
import { ReactComponent as YoutubeLogoIcon } from '../../assets/youtube_logo.svg';
import { YOUTUBE_ID_REGEX } from 'modules/charity-forms/new-form/utils';
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import { CharityFormPicture } from 'modules/charity-forms-public/types';

type Props = {
  pictures: CharityFormPicture[];
  youtubeLink: string | null;
  youtubeOrder: number | null;
};

export const ImagesAndVideos: React.FC<Props> = ({
  pictures,
  youtubeLink,
  youtubeOrder,
}) => {
  const formatMessage = useFormatMessage();

  const getMultimediaList = () => {
    const picturesList: DetailsMultimediaProps[] = sortBy(
      pictures.filter((p) => p.kind === 'CONTENT'),
      'order'
    ).map((p) => {
      return { url: p.file, kind: 'PHOTO' };
    });

    const matchVideoId = youtubeLink?.match(YOUTUBE_ID_REGEX);

    if (youtubeOrder === null || !matchVideoId) {
      return picturesList;
    }

    const newLink = `https://img.youtube.com/vi/${matchVideoId[1]}/hqdefault.jpg`;

    const youtubeItem: DetailsMultimediaProps = {
      url: newLink,
      kind: 'VIDEO',
    };

    return [
      ...picturesList.slice(0, youtubeOrder - 1),
      youtubeItem,
      ...picturesList.slice(youtubeOrder - 1),
    ];
  };

  return (
    <div>
      <Text
        style='xxbold1619'
        inlineStyle={{ display: 'block', marginBottom: '10px' }}
      >
        {formatMessage('charityForms.imagesAndVideo')}
      </Text>
      <div className={styles.list}>
        {getMultimediaList()?.map((item, index) => (
          <div
            className={clsx(
              styles.box,
              item.kind === 'VIDEO' && styles.videoBox
            )}
            onClick={() => {
              if (item.kind === 'VIDEO' && youtubeLink) {
                window.open(youtubeLink, '_blank');
              }
            }}
            key={index}
          >
            <img
              src={item.url}
              alt={formatMessage('charityForms.uploadedFileNo', {
                index,
              })}
              className={styles.image}
            />
            {item.kind === 'VIDEO' && (
              <YoutubeLogoIcon className={styles.youtubeLogoIcon} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
