import clsx from 'clsx';
import { Button } from 'common/components/button';
import { Slider } from 'common/components/Slider';
import styles from './ThresholdSlider.module.scss';

type Props = {
  className?: string;
  maxValue: number;
  value: number;
  onChange: (value: number) => void;
};

export const ThresholdSlider: React.FC<Props> = ({
  className,
  maxValue,
  value,
  onChange,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <Button
        className={styles.button}
        type='secondarySmall'
        name={'-'}
        onClick={() => {
          if (value > 1) onChange(value - 1);
        }}
      />
      <Slider min={1} max={maxValue} value={value} onChange={onChange} />
      <Button
        className={styles.button}
        type='secondarySmall'
        name={'+'}
        onClick={() => {
          if (value < maxValue) onChange(value + 1);
        }}
      />
    </div>
  );
};
