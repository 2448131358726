import styles from './CurrencyWalletBox.module.scss';
import { ReactComponent as ArrowRightIcon } from 'common/assets/black-icons/arrow-right-8px.svg';
import { ReactComponent as WalletIcon } from 'common/assets/icons/wallet-clear-bw.svg';
import { Text } from 'common/components/Text';
import { useFormatMessage } from 'modules/messages';

type Props = {
  walletsCount: number;
  showArrow: boolean;
  dataTest?: string;
};

export const CurrencyWalletBox: React.FC<Props> = ({
  walletsCount,
  showArrow,
  dataTest,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.container} data-test={dataTest}>
      <WalletIcon height={10} width={10} />
      <Text style='light1215'>{formatMessage('common.wallets')}:</Text>{' '}
      <Text style='light1215'>{walletsCount}</Text>
      {showArrow && <ArrowRightIcon />}
    </div>
  );
};
