import D from 'decimal.js';
import { TransactionDetails } from 'modules/transactions/types';
import { WithdrawalFee } from './components/WithdrawalFee';
import { WithdrawalAmount } from './components/WithdrawalAmount';
import { TotalInterest } from './components/TotalInterest';
import { Collateral } from './components/Collateral';
import { TotalAmountToRepay } from './components/TotalAmountToRepay';
import { InterestRate } from './components/InterestRate';
import { LTV } from './components/LTV';
import { PrincipalAmount } from './components/PrincipalAmount';
import { useFormatMessage } from 'modules/messages';
import { LiquidationFee } from './components/LiquidationFee';
import { InterestValue } from './components/InterestValue';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import {
  Price,
  PriceChangeTolerance,
  InvertedMinimalPrice,
  InvertedMaximalPrice,
  MinimalPrice,
  MaximalPrice,
} from 'modules/common-data-fields';
import { DailyEarn } from 'modules/common-data-fields/DailyEarn';
import { SavingBalanceLeft } from './components/SavingBalanceLeft';
import { Text } from 'common/components/Text';

type Props = { transaction: TransactionDetails; className?: string };

export const AmountsPercentageBox: React.FC<Props> = ({
  transaction,
  className,
}) => {
  const formatMessage = useFormatMessage();

  const {
    kind,
    fee,
    amount,
    currency,
    lend,
    borrow,
    extra_content,
    trade,
    saving,
  } = transaction;

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
      className={className}
    >
      {kind === 'WITHDRAWAL' && (
        <>
          <WithdrawalFee fee={fee} currency={currency} />
          <WithdrawalAmount
            amount={toLimitedPrec(toFixed(new D(amount).minus(fee)))}
            currency={currency}
          />
        </>
      )}
      {kind === 'LOAN_LEND' && (
        <>
          <TotalInterest
            amount={lend.interest}
            currency={currency}
            apr={lend.rate_pct}
          />
        </>
      )}
      {kind === 'LOAN_LEND_TERMINATION' && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style='light1215'>
              {formatMessage('transactions.initialInterest') + ':'}
            </Text>
            <Text style='bold1422'>{`${toFixed(
              transaction.extra_content.interest
            )} ${transaction.extra_content.principal_currency}`}</Text>
            <Text style='light1215'>{`${toFixed(
              transaction.extra_content.rate_pct,
              2
            )}${formatMessage('common.percentApr')}`}</Text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style='light1215'>
              {formatMessage('transactions.newInterest') + ':'}
            </Text>
            <Text style='bold1422'>{`${toFixed(
              transaction.extra_content.new_interest
            )} ${transaction.extra_content.principal_currency}`}</Text>
            <Text style='light1215'>{`${toFixed(
              transaction.extra_content.new_rate_pct,
              2
            )}${formatMessage('common.percentApr')}`}</Text>
          </div>
        </>
      )}
      {kind === 'LOAN_COLLATERAL_TOPUP_AUTO' && (
        <LTV ltv={extra_content.ltv_pct} />
      )}
      {kind === 'LOAN_COLLATERAL_TOPUP' && (
        <>
          <LTV
            ltv={extra_content.ltv_pct_new}
            title={formatMessage('transactions.newltv')}
          />
          <LTV
            ltv={extra_content.ltv_pct}
            title={formatMessage('transactions.startingltv')}
          />
        </>
      )}
      {kind === 'LOAN_COLLATERAL_EXCESS_RETURN' && (
        <LTV ltv={extra_content.ltv_pct} />
      )}
      {kind === 'LOAN_COLLATERAL_FULL_RETURN' && (
        <LTV ltv={extra_content.ltv_pct} />
      )}
      {kind === 'LOAN_BORROW_REPAY' && (
        <>
          {extra_content.collateral_amount && (
            <Collateral
              amount={extra_content.collateral_amount}
              currency={borrow.collateral_currency}
            />
          )}
          <TotalAmountToRepay
            amount={toLimitedPrec(
              toFixed(new D(borrow.principal_amount).add(borrow.interest))
            )}
            currency={borrow.principal_currency}
          />
          <InterestRate rate={borrow.rate_pct} />
        </>
      )}
      {kind === 'LOAN_BORROW' && (
        <>
          {extra_content.collateral_amount && (
            <Collateral
              amount={extra_content.collateral_amount}
              currency={borrow.collateral_currency}
            />
          )}
          <PrincipalAmount
            amount={borrow.principal_amount}
            currency={borrow.principal_currency}
          />
          <TotalAmountToRepay
            amount={toLimitedPrec(
              toFixed(new D(borrow.principal_amount).add(borrow.interest))
            )}
            currency={borrow.principal_currency}
          />
          <InterestRate rate={borrow.rate_pct} />
          <LTV ltv={extra_content.ltv_pct} />
        </>
      )}
      {kind === 'LOAN_BORROW_LIQUIDATION' && (
        <>
          <InterestValue
            amount={borrow.frozen_interest}
            currency={borrow.principal_currency}
          />
          <LiquidationFee fee={fee} currency={borrow.principal_currency} />
          <PrincipalAmount
            amount={borrow.principal_amount}
            currency={borrow.principal_currency}
          />
          <LTV ltv={extra_content.ltv_pct} />
        </>
      )}
      {kind === 'LOAN_BORROW_CANCELLATION' && (
        <>
          <InterestValue
            amount={borrow.interest}
            currency={borrow.principal_currency}
          />
          <PrincipalAmount
            amount={borrow.principal_amount}
            currency={borrow.principal_currency}
          />
        </>
      )}
      {kind === 'TRADE_OUT' && (
        <>
          <Price
            title={formatMessage('transactions.spend')}
            amount={
              trade.side === 'BUY'
                ? trade.currency_2_amount
                : trade.currency_1_amount
            }
            currency={
              trade.side === 'BUY' ? trade.currency_2 : trade.currency_1
            }
            currencyRate={
              trade.side === 'BUY'
                ? trade.currency_2_rate
                : trade.currency_1_rate
            }
            showFiat={false}
          />
          <Price
            title={formatMessage('transactions.receive')}
            amount={
              trade.side === 'BUY'
                ? trade.currency_1_amount
                : trade.currency_2_amount
            }
            currency={
              trade.side === 'BUY' ? trade.currency_1 : trade.currency_2
            }
            currencyRate={
              trade.side === 'BUY'
                ? trade.currency_1_rate
                : trade.currency_2_rate
            }
            showFiat={false}
          />
          {trade.side === 'BUY' ? (
            <>
              <MaximalPrice
                currency1={trade.currency_1}
                currency2={trade.currency_2}
                amount={trade.limit_price}
              />
              <InvertedMaximalPrice
                currency1={trade.currency_2}
                currency2={trade.currency_1}
                amount={trade.limit_price}
              />
            </>
          ) : (
            <>
              <MinimalPrice
                currency1={trade.currency_1}
                currency2={trade.currency_2}
                amount={trade.limit_price}
              />
              <InvertedMinimalPrice
                currency1={trade.currency_2}
                currency2={trade.currency_1}
                amount={trade.limit_price}
              />
            </>
          )}
          <PriceChangeTolerance tolerance={trade.price_tolerance_pct} />
        </>
      )}
      {(kind === 'SAVING' || kind === 'SAVING_TOPUP') && (
        <DailyEarn
          rate={saving.rate_pct}
          currency={currency}
          amount={extra_content.interest_daily}
        />
      )}
      {kind === 'SAVING_WITHDRAWAL' && extra_content.amount_target && (
        <SavingBalanceLeft
          apr={saving.rate_pct}
          currency={currency}
          amount={extra_content.amount_target}
        />
      )}
    </div>
  );
};
