import { Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';

type Props = {
  onSubmit: () => void;
};

export const StepSuccess: React.FC<Props> = ({ onSubmit, ...data }) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader showClose={false}>
        {formatMessage('signup.yourAccountHasBeenCreated')}
      </ModalHeader>
      <ModalBody>
        <Text style={'light1425'}>
          {formatMessage('signup.youCanNowProceedToLogin')}
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            name={formatMessage('login')}
            type='dark'
            onClick={onSubmit}
          />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
