import { SortValue } from 'common/components/table-subcomponents';
import { FilterConfig } from 'common/components/table-subcomponents/filter/PopupFilter';
import { SortConfig } from 'common/components/table-subcomponents/sort/PopupSorter';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';

export type TradeFilterModel = {
  failed?: boolean;
  completed?: boolean;
  all?: boolean;
};

export const useFilters = () => {
  const formatMessage = useFormatMessage();

  const [filter, setFilter] = useState<TradeFilterModel>({ all: true });

  const filterConfig: FilterConfig[] = [
    {
      filter: filter.all,
      onFilter: () => {
        setFilter({ all: true });
      },
      text: formatMessage('finopsCommon.showAll'),
    },
    {
      filter: filter.completed,
      onFilter: () => {
        setFilter({ completed: true });
      },
      text: formatMessage('trade.showCompleted'),
    },
    {
      filter: filter.failed,
      onFilter: () => {
        setFilter({ failed: true });
      },
      text: formatMessage('trade.showFailed'),
    },
  ];

  return [filter, filterConfig] as const;
};

export type TradeSortModel = {
  executedAt?: SortValue;
  inAmountFiat?: SortValue;
  outAmountFiat?: SortValue;
};

export const useSort = () => {
  const formatMessage = useFormatMessage();

  const [sort, setSort] = useState<TradeSortModel>({});

  const sortConfig: SortConfig[] = [
    {
      sort: sort.executedAt,
      onSort: (next: SortValue) => {
        setSort({ executedAt: next });
      },
      text: formatMessage('common.date'),
    },
    {
      sort: sort.outAmountFiat,
      onSort: (next: SortValue) => {
        setSort({ outAmountFiat: next });
      },
      text: formatMessage('trade.spend'),
    },
    {
      sort: sort.inAmountFiat,
      onSort: (next: SortValue) => {
        setSort({ inAmountFiat: next });
      },
      text: formatMessage('trade.receive'),
    },
  ];

  return [sort, sortConfig] as const;
};
