import styles from './MembersList.module.scss';
import { Member, useMembersQuery } from 'modules/company-members/api';
import { MemberBox } from './MemberBox';
import clsx from 'clsx';

type Props = {
  selectedMembers: Member[];
  onSelectedMember: (member: Member[]) => void;
};

export const MembersList: React.FC<Props> = ({
  selectedMembers,
  onSelectedMember,
}) => {
  const { data: members } = useMembersQuery();

  const sortedMembers = members?.data.sort((a, b) =>
    a.company_role < b.company_role
      ? 1
      : a.company_role > b.company_role
      ? -1
      : 0
  );

  const onMemberClick = (member: Member) => {
    if (selectedMembers.length > 0) {
      let tmpList = [...selectedMembers];
      const foundMemberIndex = tmpList.findIndex(
        (data: Member) => data.id === member.id
      );
      if (foundMemberIndex === -1) {
        tmpList.push(member);
      } else {
        tmpList.splice(foundMemberIndex, 1);
      }
      onSelectedMember(tmpList);
      return;
    }
    onSelectedMember([member]);
  };

  const isChecked = (id: number) =>
    !!selectedMembers.find((data: Member) => data.id === id);

  return (
    <div
      className={clsx(
        styles.container,
        sortedMembers!.length > 4 && styles.grid4to16
      )}
    >
      {selectedMembers.length > 0 &&
        sortedMembers?.map((member: Member, index: number) => (
          <MemberBox
            name={member.name}
            surname={member.surname}
            checked={isChecked(member.id)}
            onClick={() => {
              if (member.company_role !== 'OWNER') onMemberClick(member);
            }}
            isOwner={member.company_role === 'OWNER'}
            key={index}
          />
        ))}
    </div>
  );
};
