import { useEffect, useRef, useState } from 'react';
import { Modal } from 'common/components/modal';
import { useFormatMessage } from 'modules/messages';
import { StepSetDescriptions } from './StepSetDescriptions';
import { StepSetMultimedia } from './StepSetMultimedia';
import { StepSetFundraisingGoal } from './StepSetFundraisingGoal';
import { StepSetLogoAndStyles } from './StepSetLogoAndStyles';
import { StepSummary } from './StepSummary';
import { openSnackbar } from 'common/components/snackbar';
import {
  Cancel2FA,
  Module2FA,
  generateHeader2FA,
  setErrorOTP,
} from 'modules/2FA';
import {
  CHARITY_FORMS_QUERY_KEY,
  PostCharityFormsPayload,
  useCharityFormsMutation,
} from 'modules/charity-forms/api';
import { useNewCharityFormContext } from '../context';
import { StepSuccess } from './StepSuccess';
import { useFeatureFlag } from 'modules/feature-flags';
import { LoaderBox } from 'common/components/loader';
import { useQueryClient } from '@tanstack/react-query';

type Steps =
  | 'descriptions'
  | 'photos'
  | 'goal'
  | 'styles'
  | 'summary'
  | '2FA'
  | 'success'
  | '2FAcancel';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const NewCharityFormModal: React.FC<Props> = ({ open, onClose }) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const [step, setStep] = useState<Steps>('descriptions');
  const [error2FA, setError2FA] = useState<string>();

  const contentRef = useRef<HTMLDivElement>(null);

  const context = useNewCharityFormContext();
  const charityFormsMutation = useCharityFormsMutation();

  const creatingCharityFormFeatureFlag = useFeatureFlag(
    'CREATING_CHARITY_FORM'
  );

  const closeModal = () => {
    setStep('descriptions');
    context.reset();
    onClose();
  };

  useEffect(() => {
    if (
      !creatingCharityFormFeatureFlag.data?.enabled &&
      !creatingCharityFormFeatureFlag.isLoading &&
      open
    ) {
      openSnackbar(
        formatMessage('common.featureCurrentlyUnavailableTryLater'),
        'warning'
      );
      closeModal();
    }
  }, [creatingCharityFormFeatureFlag.data?.enabled]);

  if (creatingCharityFormFeatureFlag.isLoading) {
    return <LoaderBox />;
  }

  if (!creatingCharityFormFeatureFlag.data?.enabled) {
    return null;
  }

  const createCharityForm = (twoFaCode: string) => {
    if (
      !context.logo ||
      !context.files ||
      !context.networkList ||
      !context.picturesIds ||
      !context.fiatCurrency
    ) {
      return;
    }

    const payload: PostCharityFormsPayload = {
      name: context.name,
      overview: context.overview,
      description: context.description,
      youtube_link: context.youtubeLink,
      youtube_link_order: context.youtubeLinkIndex,
      dark_mode: context.darkMode,
      primary_color: context.primaryColor,
      secondary_color: context.secondaryColor,
      goal_enabled: context.goalEnabled,
      end_at_enabled: context.endAtEnabled,
      goal_amount: context.goalEnabled ? context.goalAmount : undefined,
      goal_fiat: context.fiatCurrency,
      end_at: context.endAtEnabled ? context.endAt : undefined,
      pictures_logo_id: context.logo?.id,
      pictures_content_ids: context.picturesIds,
      networks_list: context.networkList,
    };

    charityFormsMutation.mutate(
      { payload, headers: generateHeader2FA(twoFaCode) },
      {
        onSuccess: () => {
          setStep('success');
          queryClient.invalidateQueries(CHARITY_FORMS_QUERY_KEY);
        },
        onError: (err) => {
          setErrorOTP(err, setError2FA, formatMessage);
        },
      }
    );
  };

  const goOnAnotherPage = (newStep: Steps) => {
    setStep(newStep);
    setTimeout(() => {
      contentRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 100);
  };

  return (
    <>
      <Modal isOpen={open} onClose={closeModal}>
        <div ref={contentRef}>
          {step === 'descriptions' && (
            <StepSetDescriptions
              onGoNext={() => goOnAnotherPage('photos')}
              onClose={closeModal}
            />
          )}
          {step === 'photos' && (
            <StepSetMultimedia
              onGoNext={() => goOnAnotherPage('goal')}
              onGoBack={() => {
                goOnAnotherPage('descriptions');
              }}
            />
          )}
          {step === 'goal' && (
            <StepSetFundraisingGoal
              onGoNext={() => goOnAnotherPage('styles')}
              onGoBack={() => {
                goOnAnotherPage('photos');
              }}
            />
          )}
          {step === 'styles' && (
            <StepSetLogoAndStyles
              onGoNext={() => goOnAnotherPage('summary')}
              onGoBack={() => {
                goOnAnotherPage('goal');
              }}
            />
          )}
          {step === 'summary' && (
            <StepSummary
              onGoNext={() => goOnAnotherPage('2FA')}
              onGoBack={() => {
                goOnAnotherPage('styles');
              }}
            />
          )}
          {step === '2FA' && (
            <Module2FA
              onVerify={createCharityForm}
              onError={(err) => setError2FA(err)}
              error={error2FA}
              isLoading={charityFormsMutation.isLoading}
              onCancel={() => goOnAnotherPage('2FAcancel')}
              approvalOfTransaction={false}
              title={formatMessage('charityForms.createCharityForm')}
            />
          )}
          {step === 'success' && <StepSuccess onClose={closeModal} />}
          {step === '2FAcancel' && <Cancel2FA onClose={closeModal} />}
        </div>
      </Modal>
    </>
  );
};
