import D from 'decimal.js';
import { CryptoToFiatAmount, CurrencyRate } from 'modules/fiat';
import { useFormatMessage } from 'modules/messages';
import { BaseDataField } from 'modules/common-data-fields';
import { CurrencyEnum } from 'common/types';
import { LabelWithInfoTooltip } from 'common/components/LabelWithInfoTooltip';

type Props = {
  balance: number;
  currency: CurrencyEnum;
  currency_rate: CurrencyRate;
  isFinished: boolean;
};

export const FundsLocked: React.FC<Props> = ({
  balance,
  currency,
  currency_rate,
  isFinished,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <BaseDataField
      label={
        <LabelWithInfoTooltip
          text={`${formatMessage('finopsCommon.fundsLocked')}:`}
          textStyle={'light1215'}
          tooltipText={
            !isFinished
              ? formatMessage('saving.amountDoesNotIncludePendingTransactions')
              : ''
          }
        />
      }
      value={`${new D(balance).toFixed()} ${currency}`}
      valueTextStyle='xxbold1619'
      secondaryValue={
        !isFinished && (
          <CryptoToFiatAmount
            amount={`${balance}`}
            currency={currency_rate.currency}
            currencyRate={currency_rate}
          />
        )
      }
    />
  );
};
