import clsx from 'clsx';
import { Text, textColorStyles, textStyles } from 'common/components/Text';
import styles from './FeatureSidebarButton.module.scss';

export type FeatureButtonProps = {
  text: string;
  onClick: () => void;
  icon: React.ReactNode;
  disabledIcon?: React.ReactNode;
  dataTest?: string;
  disabled?: boolean;
};

export const FeatureSidebarButton: React.FC<FeatureButtonProps> = ({
  text,
  onClick,
  icon,
  disabledIcon,
  dataTest,
  disabled = false,
}) => {
  return (
    <div
      className={styles.container}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      data-test={dataTest}
    >
      {disabled && disabledIcon ? disabledIcon : icon}
      <Text style={'bold1417'} colorStyle={disabled ? 'inactive' : 'primary'}>
        {text}
      </Text>
    </div>
  );
};
