import { Text } from 'common/components/Text';
import styles from './APRBox.module.scss';
import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { CurrencyBox } from 'common/components/currency/CurrencyBox';
import { CurrencyEnum, NetworkEnum } from 'common/types';
import { InterestRateKind } from '../types';
import clsx from 'clsx';
import { Tooltip } from 'common/components/Tooltip';
import { useFeatureFlag } from 'modules/feature-flags';
import { useAreNoWalletsCreated } from 'modules/assets-and-wallets';
import { useSavingsLimitsQuery } from 'modules/financial-ops/savings-account/api';
import { isSavingPossibleForCurrency } from 'modules/financial-ops/savings-account/helpers';

type Props = {
  currency: CurrencyEnum;
  network: NetworkEnum;
  apr: string;
  onClick: () => void;
  kind: InterestRateKind;
  isMainBox?: boolean;
};

export const APRBox: React.FC<Props> = ({
  currency,
  network,
  apr,
  onClick,
  kind,
  isMainBox = true,
}) => {
  const formatMessage = useFormatMessage();

  const { data: walletsFailed } = useAreNoWalletsCreated();
  const { data: savingLimits } = useSavingsLimitsQuery({
    enabled: kind === 'SAVING',
  });

  const featureFlags = {
    LEND: 'CREATING_LEND' as const,
    BORROW: 'CREATING_BORROW' as const,
    SAVING: 'CREATING_SAVING' as const,
  };

  const featureFlag = useFeatureFlag(featureFlags[kind]);

  const text = formatMessage(
    kind === 'LEND' ? 'finopsCommon.upToAPR' : 'finopsCommon.apr',
    {
      rate_pct: apr,
    }
  );

  const currencyIsNotAvailableForSaving =
    kind === 'SAVING' &&
    !isSavingPossibleForCurrency(currency, savingLimits?.data);

  const disabled =
    !featureFlag.data?.enabled ||
    walletsFailed ||
    currencyIsNotAvailableForSaving;

  return (
    <div
      className={clsx(isMainBox ? styles.containerBox : styles.containerRow)}
      data-test='aprBox'
    >
      <div className={clsx(isMainBox ? styles.nameBox : styles.nameRow)}>
        <CurrencyBox
          currency={currency}
          network={network}
          iconSize={'size34'}
          textStyle={'bold1622'}
        />
        {isMainBox && (
          <>
            <Text
              style='regular2032'
              className={styles.text}
              dataTest='bestAPR'
            >
              {text}
            </Text>
            <Text style='regular1622' className={styles.smallText}>
              {text}
            </Text>
          </>
        )}
        {!isMainBox && <Text style='regular1622'>{text}</Text>}
      </div>
      <Tooltip
        title={
          currencyIsNotAvailableForSaving
            ? formatMessage('saving.limitOfDepositedFunds')
            : !featureFlag.data?.enabled || walletsFailed
            ? formatMessage('common.featureCurrentlyUnavailableTryLater')
            : ''
        }
      >
        <span>
          <Button
            type={isMainBox ? 'darkSmall' : 'secondarySmall'}
            onClick={onClick}
            name={formatMessage(
              kind === 'LEND'
                ? 'finopsCommon.lend'
                : kind === 'BORROW'
                ? 'finopsCommon.borrow'
                : 'saving.savingCurrency',
              {
                currency,
              }
            )}
            className={clsx(styles.btn, isMainBox && styles.btnBox)}
            disabled={disabled}
          />
        </span>
      </Tooltip>
    </div>
  );
};
