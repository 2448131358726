import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { BALANCE_MEASURES_URL, getBalanceMeasures } from './api';
import { BalanceMeasure, BalanceMeasurePeriod } from './types';

type BalanceMeasureResponseData = BalanceMeasure[];

type BalanceMeasureResponseError = {
  detail: string;
};

const getBalanceMeasuresQueryKey = (period: BalanceMeasurePeriod) =>
  ['GET', BALANCE_MEASURES_URL, period] as const;

export const useBalanceMeasuresQuery = (period: BalanceMeasurePeriod) => {
  const queryKey = getBalanceMeasuresQueryKey(period);

  return useQuery<
    AxiosResponse<BalanceMeasureResponseData>,
    AxiosError<BalanceMeasureResponseError>
  >(queryKey, () => getBalanceMeasures(period));
};
