import { Text } from 'common/components/Text';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { CurrencyRate, CryptoToFiatAmount } from 'modules/fiat';
import { CurrencyEnum } from 'common/types';

type Props = {
  amount?: string;
  currency?: CurrencyEnum;
  currencyRate?: CurrencyRate;
  showFiat: boolean;
  dataTest?: string;
};

export const AmountBox: React.FC<Props> = ({
  amount,
  currency,
  currencyRate,
  showFiat,
  dataTest,
}) => {
  return (
    <div>
      <div>
        <Text
          style='xxbold1619'
          letterSpacingStyle='small'
          dataTest={dataTest}
        >{`${toLimitedPrec(toFixed(amount))} ${currency}`}</Text>
      </div>
      {showFiat && (
        <CryptoToFiatAmount
          amount={amount}
          currency={currency}
          currencyRate={currencyRate}
        />
      )}
    </div>
  );
};
