import styles from './SettingsSection.module.scss';
import { Text } from 'common/components/Text';
import clsx from 'clsx';

type Props = {
  header: string;
  actions?: React.ReactNode;
  content: React.ReactNode;
};

export const SettingsSection: React.FC<Props> = ({
  header,
  actions,
  content,
}) => {
  return (
    <div className={styles.container}>
      <div className={clsx(styles.header, !actions && styles.headerMargin)}>
        <div>
          <Text style='xxbold1619'>{header}</Text>
        </div>
        <div className={styles.actions}>{actions}</div>
      </div>
      <div>{content}</div>
    </div>
  );
};
