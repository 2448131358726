import { CurrencyIcon } from 'common/components/CurrencyIcon';
import { Text } from 'common/components/Text';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { useFormatMessage } from 'modules/messages';
import styles from './SupportedCurrencies.module.scss';
import {
  AvailableCurrency,
  AvailableNetwork,
} from 'modules/assets-and-wallets/types';

type Props = {
  network: AvailableNetwork;
};

export const SupportedCurrencies: React.FC<Props> = ({ network }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Text style='xxbold1619' className={styles.header}>
        {formatMessage('wallets.networkSupportedCurrencies', {
          network: displayNetwork(network.network),
        })}
      </Text>
      {network.currencies.map((currency) => (
        <SupportedCurrency key={currency.unit_name} currency={currency} />
      ))}
    </>
  );
};

export const SupportedCurrency: React.FC<{
  currency: AvailableCurrency;
}> = ({ currency }) => {
  return (
    <div className={styles.container}>
      <CurrencyIcon currencyName={currency.unit_name} />
      <Text style='light1622'>{`${currency.unit_name} (${currency.name})`}</Text>
    </div>
  );
};
