import { SortValue } from 'common/components/table-subcomponents';
import { SortConfig } from 'common/components/table-subcomponents/sort/PopupSorter';
import { useFormatMessage } from 'modules/messages';
import { useState } from 'react';

type SortModel = {
  principal_amount?: SortValue;
  principal_currency?: SortValue;
  collateral_amount?: SortValue;
  term?: SortValue;
  start_at?: SortValue;
  end_at?: SortValue;
};

export const useSort = () => {
  const formatMessage = useFormatMessage();

  const [sort, setSort] = useState<SortModel>({});

  const sortConfig: SortConfig[] = [
    {
      sort: sort.principal_amount,
      onSort: (next: SortValue) => {
        setSort({ principal_amount: next });
      },
      text: formatMessage('finopsCommon.principalAmount'),
    },
    {
      sort: sort.principal_currency,
      onSort: (next: SortValue) => {
        setSort({ principal_currency: next });
      },
      text: formatMessage('finopsCommon.principalCurrencyAZ'),
    },
    {
      sort: sort.collateral_amount,
      onSort: (next: SortValue) => {
        setSort({ collateral_amount: next });
      },
      text: formatMessage('finopsCommon.lockedCollateralAmount'),
    },
    {
      sort: sort.term,
      onSort: (next: SortValue) => {
        setSort({ term: next });
      },
      text: formatMessage('finopsCommon.term'),
    },
    {
      sort: sort.start_at,
      onSort: (next: SortValue) => {
        setSort({ start_at: next });
      },
      text: formatMessage('common.openingDate'),
    },
    {
      sort: sort.end_at,
      onSort: (next: SortValue) => {
        setSort({ end_at: next });
      },
      text: formatMessage('finopsCommon.maturityDate'),
    },
  ];

  return [sort, sortConfig] as const;
};
