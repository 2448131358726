import { Text, TextStyle } from 'common/components/Text';
import styles from './SummaryAmount.module.scss';
import { CurrencyEnum } from 'common/types';
import { toFixed, toLimitedPrec } from 'modules/input-amount';
import { InfoTooltip } from 'common/components/InfoTooltip';
import { RotatingLoader } from 'common/components/loader/RotatingLoader';
import { FiatCurrencyEnum } from 'modules/fiat';
import clsx from 'clsx';

type Props = {
  title: React.ReactNode;
  subtitle?: string;
  tooltipText?: string;
  amount?: string | number;
  currency?: CurrencyEnum | FiatCurrencyEnum;
  subamount?: string | number;
  isLoading: boolean;
  className?: string;
  textStyle?: TextStyle;
  hideSubtitle?: boolean;
};

export const SummaryAmount: React.FC<Props> = ({
  title,
  subamount,
  subtitle,
  amount,
  tooltipText,
  currency,
  isLoading,
  className,
  textStyle = 'light1421',
  hideSubtitle = false,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.row}>
        <div>
          <Text style={textStyle}>{title}</Text>&nbsp;
          {tooltipText && <InfoTooltip text={tooltipText} />}
        </div>

        <Text className={styles.amount} style={textStyle}>
          {isLoading ? (
            <RotatingLoader size={12} type={'secondary'} />
          ) : amount ? (
            `${toLimitedPrec(toFixed(amount))} ${currency}`
          ) : (
            '-'
          )}
        </Text>
      </div>
      <div className={clsx(styles.row, hideSubtitle && styles.hideSubtitle)}>
        <Text style={textStyle} colorStyle='secondary'>
          {subtitle}
        </Text>
        <Text
          style={textStyle}
          colorStyle='secondary'
          className={styles.amount}
        >
          {subamount ? `${toLimitedPrec(toFixed(subamount))} ${currency}` : '-'}
        </Text>
      </div>
    </div>
  );
};
