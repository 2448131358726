import { useCallback, useRef, useState } from 'react';
import { ReactComponent as IndicatorIcon } from '../assets/indicator-up.svg';

type Props = {
  pct: number;
};

export const HorizontalHealthIndicator: React.FC<Props> = ({ pct }) => {
  const boundedPct = Math.max(0, Math.min(100, pct));

  const [indicatorBarWidth, setIndicatorBarWidth] = useState(0);

  const resizeObserver = useRef<ResizeObserver>(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries[0]) {
        const entry = entries[0];
        setIndicatorBarWidth(entry.contentRect.width);
      }
    })
  );

  const resizedContainerRef = useCallback(
    (container: HTMLDivElement) => {
      if (container !== null) {
        resizeObserver.current.observe(container);
      } else {
        if (resizeObserver.current) resizeObserver.current.disconnect();
      }
    },
    [resizeObserver.current]
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        height: '13px',
      }}
    >
      <div
        ref={resizedContainerRef}
        style={{
          height: `6px`,
          background: 'var(--horizontal-health-gradient)',
          borderRadius: '12px',
        }}
      />
      <IndicatorIcon
        style={{
          top: `7px`,
          position: 'absolute',
          left: `${(boundedPct * indicatorBarWidth) / 100 - 6}px`,
        }}
      />
    </div>
  );
};
