import { ReactComponent as ExternalWalletIcon } from 'common/assets/grey-icons/wallet-arrow-up.svg';
import { Text } from 'common/components/Text';

type ExternalProps = { name: string };

export const ExternalWallet: React.FC<ExternalProps> = ({ name }) => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', gap: '5px' }}>
      <ExternalWalletIcon style={{ width: '15px' }} />
      <Text
        inlineStyle={{ overflowWrap: 'break-word', width: 'calc(100% - 20px)' }}
        style='light1215'
        colorStyle='secondary'
      >
        {name}
      </Text>
    </div>
  );
};
