import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { post } from 'modules/api';
import { useMutation } from '@tanstack/react-query';

export const ERROR_MEMBER_DOESNOT_EXIST = 'Member with this ID does not exist.';

export const CHANGE_OWNER_URL = '/members/owner-change';
export const CHANGE_OWNER_KEY = ['POST', CHANGE_OWNER_URL] as const;

export type ChangeOwnerResponseError = {
  member_id?: string[];
  status?: string;
};

export type ChangeOwnerPayload = {
  member_id: number;
};

export const changeOwner = (
  payload: ChangeOwnerPayload,
  headers?: AxiosRequestHeaders
) => post<void, ChangeOwnerPayload>(CHANGE_OWNER_URL, payload, { headers });

export type ChangeOwnerRequest = {
  payload: ChangeOwnerPayload;
  headers?: AxiosRequestHeaders;
};

export const useChangeOwnerMutation = () => {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<ChangeOwnerResponseError>,
    ChangeOwnerRequest
  >(CHANGE_OWNER_KEY, ({ payload, headers }) => {
    return changeOwner(payload, headers);
  });
};
