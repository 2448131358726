import { useState } from 'react';
import { NextButton, Button } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { InputWithHeader } from 'common/components/input/InputWithHeader';
import { MAX_INPUT_CHARACTERS_LIMIT_LONG } from 'common/consts/consts';
import { useFormatMessage } from 'modules/messages';
import { validateEmail, validateLengthLong } from 'common/utils/validators';
import { AlreadyUserLink } from './AlreadyUserLink';

type Props = {
  onSubmit: (email: string) => void;
  email?: string;
  emailTakenError?: boolean;
  header: string;
  btnName?: string;
};

export const StepEmail: React.FC<Props> = ({
  onSubmit,
  email: emailProp,
  emailTakenError,
  header,
  btnName,
}) => {
  const formatMessage = useFormatMessage();
  const [email, setEmail] = useState(emailProp ?? '');
  const [error, setError] = useState(
    emailTakenError
      ? formatMessage('common.error.input.validation.email.exists')
      : ''
  );

  const validateAndSubmit = () => {
    const emailTrimmed = email.trim();
    if (!emailTrimmed) {
      setError(formatMessage('common.fieldRequired'));
      return;
    }

    if (!validateEmail(emailTrimmed)) {
      setError(formatMessage('common.error.input.validation.email'));
      return;
    }

    if (emailTakenError && emailTrimmed === emailProp) {
      setError(formatMessage('common.error.input.validation.email.exists'));
      return;
    }

    if (!error) onSubmit(emailTrimmed);
  };

  return (
    <>
      <ModalHeader showClose={false}>{header}</ModalHeader>
      <ModalBody>
        <InputWithHeader
          header={formatMessage('common.email')}
          value={email}
          onChange={(e) => {
            const data = e.target.value;
            if (!validateLengthLong(data)) {
              setError(
                formatMessage('common.error.characters.limit', {
                  limit: MAX_INPUT_CHARACTERS_LIMIT_LONG,
                })
              );
            } else {
              setError('');
            }

            setEmail(data);
          }}
          placeholder={formatMessage('signup.emailPlaceholder')}
          onEnter={validateAndSubmit}
          errorText={error}
          autofocus={true}
          dataTest='emailInput'
        />
      </ModalBody>
      <ModalFooter>
        {btnName ? (
          <ModalActions>
            <Button name={btnName} type='dark' onClick={validateAndSubmit} />
          </ModalActions>
        ) : (
          <ModalActions extraLink={<AlreadyUserLink />}>
            <NextButton onClick={validateAndSubmit} />
          </ModalActions>
        )}
      </ModalFooter>
    </>
  );
};
