import { useFormatMessage } from 'modules/messages';
import { BaseDataField } from 'modules/common-data-fields';

type Props = { rate_pct: string };

export const InterestRate: React.FC<Props> = ({ rate_pct }) => {
  const formatMessage = useFormatMessage();

  return (
    <BaseDataField
      label={formatMessage('common.interestRate')}
      value={formatMessage('common.rateApr', { rate_pct })}
      valueTextStyle='xxbold1619'
    />
  );
};
