import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useFormatMessage } from 'modules/messages';
import { toDecimal, toFixed } from 'modules/input-amount';
import { useCharityFormWithdrawContext } from './context';
import {
  CHARITY_FORM_QUERY_KEY,
  CharityFormWithdrawEffectPayload,
  CharityFormWithdrawEffectResponseData,
  CharityFormWithdrawEffectResponseError,
  charityFormHasPandingWithdrawalTransaction,
  notEnoughFunds,
  useCharityFormWithdrawEffectMutation,
} from '../api';
import { NetworkEnum, CurrencyEnum } from 'common/types';

type InputData = {
  amount?: string;
  wallet?: number;
  currency?: CurrencyEnum;
  network?: NetworkEnum;
};

export const useCalculateEffect = () => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();

  const context = useCharityFormWithdrawContext();
  const mutation = useCharityFormWithdrawEffectMutation(
    context.charityForm?.id!
  );

  const onEffectCalculated = useCallback(
    (
      effect: CharityFormWithdrawEffectResponseData,
      payload: CharityFormWithdrawEffectPayload
    ) => {
      context.setErrorAlert('');
      context.setAmountError('');
      context.setRemainingFunds(toFixed(effect.balance_remaining));
    },
    []
  );

  const onEffectError = useCallback(
    (
      error: CharityFormWithdrawEffectResponseError,
      payload: CharityFormWithdrawEffectPayload
    ) => {
      if (notEnoughFunds(error)) {
        context.setAmountError(
          formatMessage('charityForms.notEnoughFundsToWithdraw')
        );

        if (context.charityForm?.id) {
          queryClient.invalidateQueries(
            CHARITY_FORM_QUERY_KEY(context.charityForm.id)
          );
        }
      }

      if (charityFormHasPandingWithdrawalTransaction(error)) {
        context.setErrorAlert(
          formatMessage(
            'charityForms.charityFormHasWithdrawalTransactionInPendingState'
          )
        );
      }

      context.setRemainingFunds(undefined);
    },
    []
  );

  const mutateDebounced = useCallback(
    debounce((payload: CharityFormWithdrawEffectPayload) => {
      mutation.mutate(payload, {
        onSuccess: (response) => {
          onEffectCalculated(response.data, payload);
        },
        onError: (err) => {
          if (err.response) {
            onEffectError(err.response.data, payload);
          }
        },
      });
    }, 200),
    []
  );

  // take input from last user interaction, merge it with previous input stored in context, and call /effect endpoint
  const calculateEffect = (inputData: InputData) => {
    const data: InputData = {
      amount: context.amount,
      wallet: context.wallet?.id,
      currency: context.currency?.currency,
      network: context.currency?.network,
      ...inputData,
    };

    if (!data.wallet || !data.amount || !data.currency || !data.network) {
      return;
    }

    const commonPayload: CharityFormWithdrawEffectPayload = {
      wallet_id: data.wallet,
      amount: data.amount,
      currency: data.currency,
      network: data.network,
    };

    if (toDecimal(data.amount).gt(0)) {
      mutateDebounced({
        ...commonPayload,
        amount: toFixed(data.amount),
      });
    }
  };

  return { calculateEffect, mutation };
};
