import { createContext, useContext, useState } from 'react';

type UserMenuContextValue = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  closeUserMenu: () => void;
  openUserMenu: () => void;
};

const UserMenuContext = createContext<UserMenuContextValue>({
  isOpen: false,
  setIsOpen: () => {},
  closeUserMenu: () => {},
  openUserMenu: () => {},
});

export const UserMenuContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <UserMenuContext.Provider
      value={{
        isOpen,
        setIsOpen,
        closeUserMenu: () => setIsOpen(false),
        openUserMenu: () => setIsOpen(true),
      }}
    >
      {children}
    </UserMenuContext.Provider>
  );
};

export const useUserMenuContext = () => {
  return useContext(UserMenuContext);
};
