import { Alert } from 'common/components/Alert';
import { LoaderBox } from 'common/components/loader';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import { Text } from 'common/components/Text';
import { NetworkEnum } from 'common/types';
import { useFormatMessage } from 'modules/messages';
import { useGetAvailableNetworks, useWallets } from '../api';
import styles from './StepNetwork.module.scss';
import { prettifyError } from 'common/utils/prettify-error';
import { useState } from 'react';
import { displayNetwork } from 'common/utils/network-enum-utils';
import { BackButton, GoToSummaryButton } from 'common/components/button';
import { NetworkSelector } from 'modules/assets-and-wallets/components/NetworkSelector';
import { SupportedCurrencies } from 'modules/assets-and-wallets/components/SupportedCurrencies';

const WALLETS_LIMIT = 5;

type Props = {
  onGoBack: () => void;
  onNext: () => void;
  network: NetworkEnum | undefined;
  onNetworkChange: (network: NetworkEnum) => void;
};

export const StepNetwork: React.FC<Props> = ({
  network,
  onNetworkChange,
  onGoBack,
  onNext,
}) => {
  const formatMessage = useFormatMessage();

  const [error, setError] = useState('');

  const {
    data: availableNetworksData,
    isLoading: isAvailableNetworksLoading,
    error: availableNetworksDataError,
  } = useGetAvailableNetworks();

  const {
    data: wallets,
    isLoading: isWalletsLoading,
    error: walletsError,
  } = useWallets();

  const validateAndSubmit = () => {
    if (!network) {
      setError(formatMessage('wallets.error.networkRequired'));
      return;
    }
    if (
      wallets!.filter((wallet) => wallet.network === network).length >=
      WALLETS_LIMIT
    ) {
      setError(
        formatMessage('wallets.error.walletLimitExceeded', {
          network: displayNetwork(network),
        })
      );
      return;
    }
    setError('');
    onNext();
  };

  return (
    <>
      <ModalHeader subtitle={formatMessage('wallets.supportedCurrencies')}>
        {formatMessage('wallets.createWallet')}
      </ModalHeader>
      <ModalBody>
        {availableNetworksDataError && (
          <Alert
            severity='error'
            text={prettifyError(availableNetworksDataError)}
            className={styles.errorAlert}
          />
        )}
        {!availableNetworksDataError && walletsError && (
          <Alert
            severity='error'
            text={prettifyError(walletsError)}
            className={styles.errorAlert}
          />
        )}
        {!availableNetworksDataError && !walletsError && error && (
          <Alert severity='error' text={error} className={styles.errorAlert} />
        )}
        {(isAvailableNetworksLoading || isWalletsLoading) && <LoaderBox />}
        {availableNetworksData && (
          <div className={styles.container}>
            <div className={styles.networkSelectors}>
              <Text style='xxbold1619' className={styles.subheader}>
                {formatMessage('common.selectNetwork')}
              </Text>
              {availableNetworksData.data.map((avaiableNetwork) => (
                <NetworkSelector
                  key={avaiableNetwork.network}
                  network={avaiableNetwork}
                  isSelected={network === avaiableNetwork.network}
                  onSelect={() => onNetworkChange(avaiableNetwork.network)}
                />
              ))}
            </div>
            <div className={styles.networkSupportedCurrencies}>
              {network && (
                <SupportedCurrencies
                  network={
                    availableNetworksData.data.find(
                      (availableNetwork) => availableNetwork.network === network
                    )!
                  }
                />
              )}
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <BackButton onClick={onGoBack} />
          <GoToSummaryButton onClick={validateAndSubmit} />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
