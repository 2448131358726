import { Button } from 'common/components/button';
import { useFormatMessage } from 'modules/messages';
import { SettingsSection } from './SettingsSection';
import { Text } from 'common/components/Text';
import styles from './MultiapprovalSection.module.scss';
import { useMultiapprovalQuery } from 'modules/multiapproval/api';
import { Member, useMembersQuery } from 'modules/company-members/api';
import { Threshold } from 'modules/multiapproval/components/Threshold';
import { useSetMultiapprovalContext } from 'modules/multiapproval';
import clsx from 'clsx';
import { Tooltip } from 'common/components/Tooltip';

export const MultiapprovalSection: React.FC = () => {
  const formatMessage = useFormatMessage();

  const { setIsOpen } = useSetMultiapprovalContext();
  const { data: thresholdData } = useMultiapprovalQuery();
  const { data: members } = useMembersQuery();

  const isDataNotAvailable = typeof members === 'undefined';

  const signers = (members?.data ?? []).filter(
    (data: Member) => data.is_signer
  );

  const getTooltipText = () => {
    if (isDataNotAvailable) {
      return '';
    }

    if (members.data.length <= 1) {
      return formatMessage(
        'multiapproval.multiapprovalRequiresAtLeastTwoUsers'
      );
    }
    return '';
  };

  return (
    <SettingsSection
      header={formatMessage('common.multiapprovalThreshold')}
      content={
        <>
          {thresholdData && (
            <>
              <div className={styles.threshold}>
                <Threshold
                  current={thresholdData.data.threshold}
                  max={thresholdData.data.signers_count}
                />
              </div>
              <div>
                <Text style='xxbold1417'>
                  {formatMessage('common.multiapprovalChosenMembers', {
                    chosenMembers: thresholdData.data.signers_count,
                  }) + ':'}
                </Text>
              </div>
              <div
                className={clsx(
                  styles.signersBox,
                  signers.length >= 10 && styles.twoColumns
                )}
              >
                {signers.map((signer) => (
                  <div key={signer.id} className={styles.signer}>
                    <Text style='light1417'>
                      {signer.name} {signer.surname}
                    </Text>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      }
      actions={
        <Tooltip title={getTooltipText()}>
          <span>
            <Button
              type='secondarySmall'
              name={formatMessage('common.button.change')}
              onClick={() => {
                setIsOpen(true);
              }}
              disabled={isDataNotAvailable || members?.data.length <= 1}
            />
          </span>
        </Tooltip>
      }
    />
  );
};
