import { BeatLoader } from 'react-spinners';
import { theme } from 'common/consts/theme';

export type LoaderProps = { size?: number; color?: string };

// base for more complex loader components
export const Loader: React.FC<LoaderProps> = ({
  size = 10,
  color = theme.colors.iconLoader,
}) => <BeatLoader size={size} color={color} speedMultiplier={0.5} />;
