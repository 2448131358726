import clsx from 'clsx';
import { Text } from 'common/components/Text';
import { ReactComponent as ArrowheadIcon } from '../assets/arrowhead-right-12px-withpadding.svg';
import { ReactComponent as ErrorIcon } from '../assets/error.svg';
import { ReactComponent as TickNextIcon } from '../assets/tick-next.svg';
import { ReactComponent as TickPrevIcon } from '../assets/tick-prev.svg';
import styles from './StateTile.module.scss';

type Props = {
  arrowhead: boolean;
  badge: 'tick' | number;
  state: 'prev' | 'current' | 'next' | 'error';
  title: string;
};

export const StateTile: React.FC<Props> = ({
  arrowhead,
  badge,
  state,
  title,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.baseTile,
          state === 'current' && styles.currentTile,
          state === 'next' && styles.nextTile,
          state === 'error' && styles.errorTile
        )}
      >
        {state === 'prev' && <TickPrevIcon />}
        {state === 'error' && <ErrorIcon />}
        {state === 'current' && badge === 'tick' && <TickPrevIcon />}
        {state === 'current' && typeof badge === 'number' && (
          <NumberBadge ordinal={badge} mode='active' />
        )}
        {state === 'next' && badge === 'tick' && <TickNextIcon />}
        {state === 'next' && typeof badge === 'number' && (
          <NumberBadge ordinal={badge} mode='inactive' />
        )}
        <Text
          style='light1425'
          colorStyle={state === 'error' ? 'error' : 'primary'}
        >
          {title}
        </Text>
      </div>
      {arrowhead && (
        <div className={styles.arrowheadContainer}>
          <ArrowheadIcon />
        </div>
      )}
    </div>
  );
};

const NumberBadge: React.FC<{
  mode: 'active' | 'inactive';
  ordinal: number;
}> = ({ mode, ordinal }) => (
  <div
    className={clsx(
      styles.numberBadge,
      mode === 'active' ? styles.numberBadgeActive : styles.numberBadgeInactive
    )}
  >
    <Text
      style='light1425'
      colorStyle={mode === 'active' ? 'theme' : 'primary'}
    >
      {ordinal}
    </Text>
  </div>
);
