import { MaximalPrice, MinimalPrice } from 'modules/common-data-fields';
import { FinalPrice } from './FinalPrice';
import { toDecimal, toFixed, toLimitedPrec } from 'modules/input-amount';
import { DECIMALS_MAP } from 'common/utils/currency-enum-utils';
import { TradeOptions } from '../../new-trade/types';
import { CurrencyEnum } from 'common/types';

type Props = {
  side: TradeOptions;
  spendCurrency: CurrencyEnum;
  receiveCurrency: CurrencyEnum;
  final_price?: string;
  limit_price: string;
};

export const TradePrice: React.FC<Props> = ({
  side,
  spendCurrency,
  receiveCurrency,
  final_price,
  limit_price,
}) => {
  return (
    <>
      {final_price ? (
        side === 'BUY' ? (
          <FinalPrice
            amount={toLimitedPrec(
              toFixed(
                toDecimal(1).div(final_price).toString(),
                DECIMALS_MAP[receiveCurrency]
              )
            )}
            currency1={spendCurrency}
            currency2={receiveCurrency}
          />
        ) : (
          <FinalPrice
            amount={toLimitedPrec(toFixed(final_price))}
            currency1={spendCurrency}
            currency2={receiveCurrency}
          />
        )
      ) : side === 'BUY' ? (
        <MaximalPrice
          currency1={receiveCurrency}
          currency2={spendCurrency}
          amount={limit_price}
        />
      ) : (
        <MinimalPrice
          currency1={spendCurrency}
          currency2={receiveCurrency}
          amount={limit_price}
        />
      )}
    </>
  );
};
