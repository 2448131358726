import { PageLoader } from 'common/components/loader';
import { CharityFormDetails as CharityFormDetailsFeature } from 'modules/charity-forms/charity-form-details';
import { NavigationLayout } from 'modules/layout';
import { useLoginRequired } from 'modules/login';
import { useFormatMessage } from 'modules/messages';
import { Helmet } from 'react-helmet-async';

export const CharityFormDetails: React.FC<{}> = () => {
  const { isLoading } = useLoginRequired();

  const formatMessage = useFormatMessage();

  return (
    <>
      <Helmet>
        <title>{formatMessage('pageTitle.charityForms')}</title>
      </Helmet>
      {isLoading ? (
        <PageLoader />
      ) : (
        <NavigationLayout>
          <CharityFormDetailsFeature />
        </NavigationLayout>
      )}
    </>
  );
};
