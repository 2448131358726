import styles from './TransactionBox.module.scss';
import { ReactComponent as ArrowheadDown } from 'common/assets/black-icons/arrowhead-down-14px.svg';
import { ReactComponent as ArrowheadUp } from 'common/assets/black-icons/arrowhead-up-14px.svg';

import { CellTransaction } from './CellTransaction';
import { CellStatus } from './CellStatus';
import { CellAmount } from './CellAmount';

import { CellActions } from './CellActions';
import { Transaction } from '../types';
import { useState } from 'react';
import { TransactionMoreInformation } from './details/TransactionMoreInformation';
import { LinkExpandedView } from './LinkExpandedView';

type Props = {
  transaction: Transaction;
  onAction?: () => void;
};

export const TransactionBox: React.FC<Props> = ({ transaction, onAction }) => {
  const { id, state } = transaction;
  const [expanded, setExpaned] = useState(false);

  return (
    <div className={styles.container}>
      <div
        className={styles.tableItem}
        data-test='rowInTxnTable'
        onClick={() => {
          setExpaned((expanded) => !expanded);
        }}
      >
        <div className={styles.cellExpand} data-test='expandRow'>
          {expanded ? (
            <ArrowheadUp data-test='arrowUp' />
          ) : (
            <ArrowheadDown data-test='arrowDwon' />
          )}
        </div>
        <div className={styles.name}>
          <CellTransaction
            transaction={transaction}
            dataTest='transactionName'
          />
        </div>
        <div className={styles.amount}>
          <CellAmount transaction={transaction} dataTest='totalAmount' />
        </div>
        <div className={styles.status}>
          <CellStatus dataTest='status' status={state} />
        </div>
        <div className={styles.suffix}>
          <CellActions
            onAction={() => {
              if (onAction) {
                onAction();
              }
              setExpaned(false);
            }}
            dataTest='action'
            transaction={transaction}
          />
        </div>
      </div>
      {expanded && (
        <TransactionMoreInformation id={id} className={styles.moreInfo} />
      )}
      <div className={styles.suffixMobile}>
        <CellActions
          onAction={() => {
            if (onAction) {
              onAction();
            }
            setExpaned(false);
          }}
          transaction={transaction}
        />
      </div>
      {expanded && (
        <div className={styles.link}>
          <LinkExpandedView id={id} />
        </div>
      )}
    </div>
  );
};
