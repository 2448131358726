import { useFormatMessage } from 'modules/messages';
import { Trade } from '../../api';
import styles from './RecentTradeBox.module.scss';
import { Title } from './Title';
import { Date, Price } from 'modules/common-data-fields';
import { TradeState } from './TradeState';
import { Tolerance } from './Tolerance';
import { TradePrice } from './TradePrice';

export const RecentTradeBox: React.FC<{ trade: Trade }> = ({ trade }) => {
  const formatMessage = useFormatMessage();

  const {
    currency_1,
    currency_2,
    side,
    executed_at,
    currency_1_amount,
    currency_2_amount,
    state,
    substate,
    price_tolerance_pct,
    final_price,
    limit_price,
    final_price_change_pct,
    currency_1_rate,
    currency_2_rate,
  } = trade;

  const spendCurrency = side === 'BUY' ? currency_2 : currency_1;
  const receiveCurrency = side === 'BUY' ? currency_1 : currency_2;

  const spendAmount = side === 'BUY' ? currency_2_amount : currency_1_amount;
  const receiveAmount = side === 'BUY' ? currency_1_amount : currency_2_amount;

  const spendRate = side === 'BUY' ? currency_2_rate : currency_1_rate;
  const receiveRate = side === 'BUY' ? currency_1_rate : currency_2_rate;

  return (
    <div className={styles.container}>
      <div>
        <Title outCurrency={spendCurrency} inCurrency={receiveCurrency} />
        <Date date={executed_at} />
      </div>
      <TradeState state={state} substate={substate} />
      <Price
        title={formatMessage('transactions.spend')}
        amount={spendAmount}
        currency={spendCurrency}
        currencyRate={spendRate}
        showFiat={false}
      />
      <Price
        title={formatMessage('transactions.receive')}
        amount={receiveAmount}
        currency={receiveCurrency}
        currencyRate={receiveRate}
        showFiat={false}
      />
      <Tolerance
        price_tolerance_pct={price_tolerance_pct}
        final_price_change_pct={final_price_change_pct}
      />
      <TradePrice
        side={side}
        spendCurrency={spendCurrency}
        receiveCurrency={receiveCurrency}
        final_price={final_price}
        limit_price={limit_price}
      />
    </div>
  );
};
