import { CancelButton, GoToSummaryButton } from 'common/components/button';
import {
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'common/components/modal';
import styles from './StepInfoFinish.module.scss';
import { Summary, SummaryLabel, SummaryValue } from 'common/components/Summary';
import { useFormatMessage } from 'modules/messages';
import { Text } from 'common/components/Text';
import { formatDate } from 'common/utils/utils';

type Props = {
  endDate: string;
  onCancel: () => void;
  onGoNext: () => void;
};

export const StepInfoFinish: React.FC<Props> = ({
  endDate,
  onCancel,
  onGoNext,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ModalHeader
        showClose
        subtitle={
          <Text style='xxbold1619'>
            {formatMessage('charityForms.charityFormWillEndEarlier')}
          </Text>
        }
      >
        {formatMessage('charityForms.finishCharityForm')}
      </ModalHeader>
      <ModalBody>
        <div className={styles.container}>
          <Text style='xbold1417' className={styles.info}>
            {formatMessage('charityForms.pleaseConfirmIfYouWishToProceed')}
          </Text>
          <Summary className={styles.summary}>
            <SummaryLabel>
              {formatMessage('charityForms.fundraiserEndDate')}:
            </SummaryLabel>
            <SummaryValue>{formatDate(endDate)}</SummaryValue>
          </Summary>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <CancelButton onClick={onCancel} />
          <GoToSummaryButton onClick={onGoNext} />
        </ModalActions>
      </ModalFooter>
    </>
  );
};
